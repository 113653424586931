import { AvatarGroup, Box, Flex, PopoverBody, Text } from "@chakra-ui/react";
import React from "react";
import { TokenLogo } from "src/atoms";
import { useAppColor } from "src/hooks";
import { IDonutChartData } from "./DonutChart";

interface DonutTooltipProps {
  holdingIcon: string[];
  percentage: string;
  chartData: IDonutChartData;
}

const DonutTooltip: React.FC<DonutTooltipProps> = ({
  holdingIcon,
  percentage,
  chartData,
}) => {
  const { text5 } = useAppColor();
  return (
    <PopoverBody px="4" py="3" w="sm">
      <Flex h="min-content" alignItems="center">
        <Box w="2px" h="10" bg={chartData.color} borderRadius="full" />
        <Box ml="3">
          <Flex alignItems="center">
            <Text mr="3" fontSize="md" color={text5}>
              {chartData.name}
            </Text>
            <Text fontSize="md" color="blue.600" fontWeight="semibold">
              {percentage}%
            </Text>
          </Flex>
          <AvatarGroup size="xs" spacing={2} pt="2" max={3} fontSize="xs">
            {holdingIcon.length !== 0 ? (
              holdingIcon.map((icon, index) => (
                <TokenLogo key={index} src={icon} />
              ))
            ) : (
              <TokenLogo visibility="hidden" />
            )}
          </AvatarGroup>
        </Box>
      </Flex>
    </PopoverBody>
  );
};

export default DonutTooltip;
