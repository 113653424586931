import React, { memo } from "react";
import { PageLayout } from "src/templates";
import { Box, Flex, Text } from "@chakra-ui/layout";
import { PricingProps, SettingProps } from "src/constants/type";
import {
  IconEmail,
  IconLock,
  IconMoon,
  IconNotification,
} from "src/assets/icons";

import { useBoolean } from "@chakra-ui/hooks";
import { useAppColor, useRouter } from "src/hooks";
import Setting from "./components/Setting";
import { useTranslation } from "react-i18next";
import { Button } from "@chakra-ui/react";
import Pricing from "./components/Pricing";
interface SettingsPageProps {
  title: string;
}

export const SettingsPage: React.FC<SettingsPageProps> = memo(({ title }) => {
  const { t } = useTranslation(["settings", "common"]);
  const { brand, theme, text4, main1, text2, toggleColorMode } = useAppColor();
  const [notification, setNotification] = useBoolean(false);
  const [email, setEmail] = useBoolean(false);
  const [lock, setLock] = useBoolean(false);
  const router = useRouter();

  const data_settings: SettingProps[] = [
    {
      title: t("darkMode"),
      icon: <IconMoon />,
      isChecked: theme === "dark" ? true : false,
      onChange: toggleColorMode,
    },
    {
      title: t("turnOnNotifications"),
      icon: <IconNotification />,
      isChecked: notification,
      onChange: setNotification.toggle,
    },
    {
      title: t("sendNotifications"),
      icon: <IconEmail />,
      isChecked: email,
      onChange: setEmail.toggle,
    },
    {
      title: t("autoLock"),
      icon: <IconLock />,
      isChecked: lock,
      onChange: setLock.toggle,
    },
  ];

  const data_pricing: PricingProps[] = [
    {
      bg: "gray.100",
      data: {
        name: t("free"),
        description: t("description1"),
        price: 0,
        duration: "monthly",
        benefits: [t("content1"), t("content2")],
      },
    },
    {
      bg: brand,
      data: {
        name: t("premium"),
        description: t("description2"),
        price: 29,
        duration: "monthly",
        benefits: [t("content3"), t("content4"), t("content5")],
      },
      button: (
        <Button onClick={() => router.push("/membership")}>
          {t("accessNow")}
        </Button>
      ),
    },
  ];

  return (
    <PageLayout title={title}>
      <Text
        display={{ base: "block", lg: "none" }}
        fontSize="2xl"
        lineHeight="8"
        fontWeight="semibold"
        color={text2}
      >
        {t("common:settings")}
      </Text>
      <Flex
        w="full"
        maxW={{ base: "unset", lg: "100%" }}
        flexDirection={{ base: "column", lg: "row" }}
        spacing="24px"
      >
        <Box
          mt={{ base: "16px", lg: "0px" }}
          w={{ base: "full", xl: "full" }}
          bg={main1}
          borderRadius="16px"
          pt={{ base: "2", lg: "4" }}
          px="24px"
          mb={{ base: "16px", lg: "0px" }}
          h="fit-content"
          mr="16px"
        >
          <Text
            fontSize="2xl"
            lineHeight="6"
            fontWeight="semibold"
            color={text4}
            mb="4px"
            display={{ base: "none", lg: "block" }}
          >
            {t("general")}
          </Text>

          {data_settings.map((item, index) => {
            return (
              <Setting
                key={index}
                last={index === data_settings.length - 1}
                {...item}
              />
            );
          })}
        </Box>
        <Flex flexDir={{ base: "column", sm: "row" }} justify={"space-between"}>
          {data_pricing.map((item, index) => {
            return (
              <Box
                key={index}
                w="100%"
                mb={{ base: "16px", lg: "0px" }}
                ml={{ base: "0", sm: index === 1 ? "4" : "0" }}
              >
                <Pricing {...item} key={index} />
              </Box>
            );
          })}
        </Flex>
      </Flex>
    </PageLayout>
  );
});

export default SettingsPage;
