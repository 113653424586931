import React from "react";
import {
  Alert,
  AlertIcon,
  Box,
  CloseButton,
  Flex,
  Link,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { TransactionResultType } from "src/constants/enum";

export type BoxTransactionProps = {
  TranID?: string;
  type?: TransactionResultType;
  onClose?(): void;
};
export const BoxTransaction: React.FC<BoxTransactionProps> = ({
  TranID,
  type,
  onClose,
}) => {
  let title = "";
  let status: "success" | "info" | "warning" | "error" | undefined = "success";
  switch (type) {
    case TransactionResultType.inprogress:
      title = "Pending Transaction";
      status = "warning";
      break;
    case TransactionResultType.success:
      title = "Transaction Successful";
      status = "success";
      break;
    case TransactionResultType.failed:
      title = "Transaction Unsuccessful";
      status = "error";
      break;
    case TransactionResultType.sent:
    default:
      title = "Transaction Sent";
      status = "success";
      break;
  }

  return (
    <Alert status={status} p={3} borderRadius="8" variant={"solid"} pr={8}>
      <CloseButton
        size="sm"
        position={"absolute"}
        right="1"
        top="1"
        onClick={onClose}
      />
      <Flex>
        <AlertIcon mt="1" />
        <Box>
          <Text fontWeight="bold">{title}. </Text>
          <Link
            isExternal
            textDecorationLine="underline"
            href={`https://viewblock.io/zilliqa/tx/${TranID}`}
          >
            View transaction on explorer.
          </Link>
        </Box>
      </Flex>
      {type === "inprogress" ? <Spinner ml="6" /> : <Box w="24px" ml="6" />}
    </Alert>
  );
};

export default BoxTransaction;
