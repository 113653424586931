import React from "react";
import {
  Box,
  HStack,
  Icon,
  Text,
  useBoolean,
  useToast,
} from "@chakra-ui/react";
import { CopyIcon } from "@chakra-ui/icons";
import { t } from "i18next";
import { IconExpand, IconMinify, IconTrash } from "src/assets/icons";
import { useAppColor, useCopy } from "src/hooks";
import {
  IWallet,
  loginSelector,
  logoutWallet,
  removeWallet,
} from "src/redux/slices/loginSlice";
import { useAppSelector } from "src/redux/hooks";
import { useDispatch } from "react-redux";
import { isConnectZilpay } from "src/api/zilpayApi";
import { FaCrown } from "react-icons/fa";

interface HeaderWalletProps {
  savedWallet: IWallet;
  children?: React.ReactElement;
  minifyChild: React.ReactElement;
}

const HeaderWallet: React.FC<HeaderWalletProps> = ({
  savedWallet,
  children,
  minifyChild,
}) => {
  const dispatch = useDispatch();
  const { text2, text3, main4, brand } = useAppColor();

  const { isLoadingWallets, savedWallets, currentWallet, mainWallet } =
    useAppSelector(loginSelector);
  const { zilAddress, name } = savedWallet;
  const connected = isConnectZilpay(zilAddress);

  const [show, setShow] = useBoolean(currentWallet?.zilAddress === zilAddress);

  const onToggleShow = () => {
    setShow.toggle();
  };

  const toast = useToast();
  const [copy] = useCopy();

  const handleCopy = (text: string) => {
    copy(text);
    if (!toast.isActive("copied"))
      toast({
        id: "copied",
        description: t("common:addressCopied"),
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
  };
  const onClickTrash = React.useCallback(
    (item: IWallet) => {
      if (isLoadingWallets) {
        return;
      }
      dispatch(removeWallet(item));
      if (savedWallets.length === 1) {
        dispatch(logoutWallet());
      }
    },
    [dispatch, isLoadingWallets, savedWallets]
  );

  return (
    <Box pb="24px">
      <HStack justify="space-between" pb="18px">
        <HStack>
          <Icon
            as={show ? IconMinify : IconExpand}
            fill={text3}
            size="4"
            mr="1"
            onClick={() => setShow.toggle()}
            cursor="pointer"
          />
          <Text
            display={{ base: "block", md: "none" }}
            fontWeight="semibold"
            color={text2}
          >
            {name.length > 13
              ? name.substring(0, 6) + "..." + name.substring(name.length - 6)
              : name}
            {/* {zilAddress.length > 13
              ? zilAddress.substring(0, 6) +
                "..." +
                zilAddress.substring(zilAddress.length - 6)
              : zilAddress} */}
          </Text>
          <Text
            display={{ base: "none", md: "block" }}
            fontWeight="semibold"
            color={text2}
          >
            {/* {zilAddress} */}
            {name}
          </Text>
          {zilAddress === mainWallet && isConnectZilpay(zilAddress) && (
            <Icon as={FaCrown} />
          )}
        </HStack>
        <HStack>
          <CopyIcon
            color={brand}
            size="5"
            mr="1"
            onClick={() => handleCopy(zilAddress)}
            cursor="pointer"
          />
          <IconTrash
            fill={brand}
            size="5"
            cursor={isLoadingWallets ? "wait" : "pointer"}
            onClick={() => onClickTrash(savedWallet)}
          />
          <Box
            display="flex"
            alignItems="center"
            border="1px"
            py="4px"
            pl="12px"
            pr="8px"
            borderRadius="6px"
            borderColor={main4}
          >
            <Box
              w="4px"
              h="4px"
              rounded="4px"
              bg={connected ? "green.600" : "teal.50"}
              mr="12px"
            />
            <Text
              fontSize="xs"
              lineHeight="4"
              fontWeight="semibold"
              color={connected ? "green.600" : "teal.50"}
            >
              {connected ? t("common:connected") : t("common:viewOnly")}
            </Text>
          </Box>
        </HStack>
      </HStack>
      {show ? children : React.cloneElement(minifyChild, { onToggleShow })}
    </Box>
  );
};

export default HeaderWallet;
