import React from "react";
import {
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/modal";
import { useAppColor } from "src/hooks";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Divider,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { IconArrowDown } from "src/assets/icons";
import { Images } from "src/assets/images";
import { TokenLogo } from "src/atoms";
import { useTranslation } from "react-i18next";

interface ModalAddStakeProps {
  onClose: () => void;
  data?: any;
  initStake?: any;
}

const ModalAddStake: React.FC<ModalAddStakeProps> = ({ data, initStake }) => {
  const { t } = useTranslation(["overview", "common"]);
  const { text2, text3, text4 } = useAppColor();
  const [stakeToken, setStakeToken] = React.useState(initStake);

  return (
    <>
      <ModalHeader textTransform="capitalize" color={text3}>
        {t("addStake")}
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Flex justifyContent="space-between" align="center">
          <Text fontSize="xs" fontWeight="bold" color={text4}>
            ZIL
          </Text>
          <Menu gutter={0} matchWidth placement="bottom-end">
            <MenuButton>
              <Flex alignItems="center">
                <Text
                  color={text3}
                  mx="3"
                  textTransform="uppercase"
                  fontSize="sm"
                >
                  {stakeToken.name}
                </Text>
                <IconArrowDown />
              </Flex>
            </MenuButton>
            <MenuList zIndex="10" minW="lg">
              {data.ssnList.map((item: any, index: number) => (
                <MenuItem
                  key={index}
                  py="3"
                  onClick={() => setStakeToken(item)}
                >
                  <Flex alignItems="center" w="full">
                    <TokenLogo src={Images.zil} size="sm" mr="2" />
                    <Text fontSize="sm" fontWeight="bold" color={text4}>
                      {item.name}
                    </Text>
                  </Flex>
                  <Flex alignItems="center" justifyContent="flex-end" w="full">
                    <Text fontSize="sm" fontWeight="semibold" color={text2}>
                      {item.unclaimed}
                    </Text>
                    <TokenLogo src={Images.zil} size="2xs" ml="2" />
                  </Flex>
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </Flex>
        <Divider my="4" />
        <InputGroup>
          <Input placeholder={t("common:amount")} size="lg" />
          <InputRightElement w="max-content" px="4" cursor="pointer">
            <Flex alignItems="center" mt="2">
              <TokenLogo src={Images.zil} size="xs" />
              <Text
                fontSize="sm"
                color={text3}
                mx="3"
                textTransform="uppercase"
              >
                {"ZIL"}
              </Text>
              <IconArrowDown />
            </Flex>
          </InputRightElement>
        </InputGroup>
        <Flex alignItems="center" mt="2">
          <Text fontSize="xs" fontWeight="bold" color={text4}>
            {t("walletBalance")}:
          </Text>
          <Text mx="3" fontSize="xs" fontWeight="bold" color={text2}>
            {stakeToken.unclaimed}
          </Text>
        </Flex>
      </ModalBody>
      <ModalFooter flexDirection="column" alignItems="flex-end">
        <Alert status="success" borderRadius="lg" alignItems="flex-start">
          <AlertIcon />
          <Box flex="1">
            <AlertTitle fontWeight="bold" color={text3}>
              {t("notes")}
            </AlertTitle>
            <AlertDescription display="block">
              <UnorderedList>
                <ListItem color={text3}>{t("minimumStaking")}</ListItem>
                <ListItem color={text3}>{t("pleaseEnsure")}</ListItem>
              </UnorderedList>
            </AlertDescription>
          </Box>
        </Alert>
        <Button mt="4" children="Stake Now" />
      </ModalFooter>
    </>
  );
};

export default ModalAddStake;
