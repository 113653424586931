import axios from "axios";

export enum ChartDay {
  D1 = "1",
  D7 = "7",
  D30 = "30",
  D90 = "90",
  MAX = "max",
}

export const apiGetZilPriceInUsd = async (): Promise<number> => {
  try {
    const url =
      "https://api.coingecko.com/api/v3/simple/price?ids=zilliqa&vs_currencies=usd";
    const { data } = await axios.get<any>(url);
    return parseFloat(`${data.zilliqa.usd}`);
  } catch (error) {
    return 0.0;
  }
};

export const apiGetZilPriceChartInUsd = async (day: ChartDay) => {
  try {
    const url = `https://api.coingecko.com/api/v3/coins/zilliqa/market_chart?vs_currency=usd&days=${day}`;
    const { data } = await axios.get<any>(url);
    return data.prices;
  } catch (error) {
    return [];
  }
};

export const apiGetBtcPriceInUsd = async (): Promise<number> => {
  try {
    const url =
      "https://api.coingecko.com/api/v3/simple/price?ids=bitcoin&vs_currencies=usd";
    const { data } = await axios.get<any>(url);
    return parseFloat(`${data.bitcoin.usd}`);
  } catch (error) {
    return 0.0;
  }
};

export const apiGetEthPriceInUsd = async (): Promise<number> => {
  try {
    const url =
      "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd";
    const { data } = await axios.get<any>(url);
    return parseFloat(`${data.ethereum.usd}`);
  } catch (error) {
    return 0.0;
  }
};
