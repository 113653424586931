import React from "react";
import { IconBase, IconBaseProps } from "react-icons";

const SvgComponent: React.FC<IconBaseProps> = (props: IconBaseProps) => {
  return (
    <IconBase {...props}>
      <svg viewBox="0 0 16 16">
        <path d="M8.66667 6H14L7.33334 16V10H2.66667L8.66667 0V6ZM7.33334 7.33333V4.81333L5.02134 8.66667H8.66667V11.596L11.5087 7.33333H7.33334Z" fill="#01FFB0"/>
      </svg>
    </IconBase>
  );
};
const IconSettings = React.memo(SvgComponent);
export default IconSettings;
