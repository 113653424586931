import React from "react";
import { Flex, Text } from "@chakra-ui/react";
import { useAppColor } from "src/hooks";

import { FeatureHistorySpec } from "./HistoryPage";

interface FeatureHistoryProps {
  item: FeatureHistorySpec;
}

const FeatureHistory: React.FC<FeatureHistoryProps> = ({ item }) => {
  const { title, balance } = item;
  const { text2, text4, text6, main1, main3 } = useAppColor();

  return (
    <Flex
      flexDirection="column"
      flex="1"
      bg={main1}
      rounded="12"
      boxShadow="xs"
      borderWidth="1px"
      borderColor={main3}
      pt="20px"
      px="4"
      pb="4"
      h="116px"
    >
      <Text fontSize="xs" lineHeight="4" fontWeight="bold" color={text4}>
        {title}
      </Text>
      <Flex flex="1" />
      <Text
        fontSize={{ base: "lg", xl: "xl" }}
        lineHeight="7"
        fontWeight="bold"
        color={text2}
      >
        {balance}
      </Text>
      <Text fontSize="xs" lineHeight="4" mt="1" color={text6}>
        {item.value}
      </Text>
    </Flex>
  );
};

export default FeatureHistory;
