import React, { useEffect, useState } from "react";
import { ModalHeader, ModalCloseButton, ModalBody } from "@chakra-ui/modal";
import { useAppColor } from "src/hooks";
import { Table, Thead, Tr, Th, Tbody, Td } from "@chakra-ui/table";
import { Box, Button, Checkbox, Flex, Text, useToast } from "@chakra-ui/react";
import { TokenLogo } from "src/atoms";
import { ISsnInfo } from "src/constants/interfaces";
import {
  getGasLimitForClaim,
  getGasPriceForClaim,
  isConnectZilpay,
} from "src/api/zilpayApi";
import { BN, bytes, toChecksumAddress } from "@zilliqa-js/zilliqa";
import Long from "long";
import { useAppSelector } from "src/redux/hooks";
import { loginSelector } from "src/redux/slices/loginSlice";
import { overviewSelector } from "src/redux/slices/overviewSlice";
import { dayjs } from "src/utils";
import { formatToken, formatUsdt } from "src/lib/formatCurrency";
import { useTranslation } from "react-i18next";
import BoxTransaction from "src/molecules/BoxTransaction";
import { Images } from "src/assets/images";

export interface IModalClaimStakingTx {
  ssn: ISsnInfo;
  TranID: string;
}

export interface IModalClaimStakingItem {
  stakingWallet: ISsnInfo;
  reward1: number;
  reward2: number;
}

const DATE_FORMAT = "YYYYMMDD";
const today = dayjs().format(DATE_FORMAT);

interface ContractParams {
  vname: string;
  type: string;
  value: string;
}

interface ModalClaimStakingProps {
  onClose: () => void;
  claimStakingItems: IModalClaimStakingItem[];
}

const ModalClaimStaking: React.FC<ModalClaimStakingProps> = ({
  claimStakingItems,
}) => {
  const toast = useToast();
  const { t } = useTranslation(["overview", "common"]);
  const { text2, text3, text6 } = useAppColor();
  const [tokenList, setTokenList] = useState<IModalClaimStakingItem[]>([]);

  const fullArr = (bool: boolean) => new Array(tokenList.length).fill(bool);
  const [checkList, setCheckList] = useState(fullArr(true));
  const checkAll = checkList.every((i) => i === true);

  const [claimTxs, setClaimTxs] = useState<IModalClaimStakingTx[] | []>([]);

  const { walletStakingList, zilPriceInUsd } = useAppSelector(overviewSelector);

  const { currentWallet, isHidden, exchangeRate } =
    useAppSelector(loginSelector);

  React.useEffect(() => {
    let tmpTokenList = claimStakingItems.filter(
      (token: IModalClaimStakingItem) =>
        token.stakingWallet.stakingBalance &&
        token.stakingWallet.stakingBalance > 0
    );
    setTokenList(tmpTokenList);
  }, [claimStakingItems]);

  useEffect(() => {
    let claimTxs = Array<IModalClaimStakingTx>();
    for (let i = 0; i < walletStakingList.length; i++) {
      const walletStaking = walletStakingList[i];
      const TranID = localStorage.getItem(
        `claimStaingTxs-${today}-${walletStaking.address}`
      );
      if (TranID && TranID.length > 0) {
        claimTxs.push({
          ssn: walletStaking,
          TranID: TranID,
        });
      }
    }
    setClaimTxs(claimTxs);
  }, [walletStakingList]);

  const zilpayContractTx = async (
    contractMethod: string,
    contractParams: ContractParams[],
    txParams:
      | { version: number; amount: BN; gasPrice: BN; gasLimit: Long.Long }
      | undefined,
    ssn: IModalClaimStakingItem
  ) => {
    if (!currentWallet) {
      return;
    }

    if (!isConnectZilpay(currentWallet.zilAddress)) {
      return;
    }

    try {
      // Seed Node Staking Phase 1.1 Proxy
      const ftAddr = "zil1v25at4s3eh9w34uqqhe3vdvfsvcwq6un3fupc2";
      const contract = window.zilPay.contracts.at(ftAddr);
      const sentTx = await contract.call(
        contractMethod,
        contractParams,
        txParams
      );
      setClaimTxs([{ ssn: ssn.stakingWallet, TranID: sentTx.TranID }]);
      localStorage.setItem(
        `claimStaingTxs-${today}-${ssn.stakingWallet.address}`,
        `${sentTx.TranID}`
      );

      // console.log("sentTx", sentTx);

      toast({
        render: () => <BoxTransaction TranID={`0x${sentTx.ID}`} />,
      });
    } catch (error) {
      console.log("ERROR: zilpay call contract", error);
    }
  };

  const createTxn = async () => {
    const CHAIN_ID = 1;
    const MSG_VERSION = 1;

    const version = bytes.pack(CHAIN_ID, MSG_VERSION);

    if (
      currentWallet?.zilAddress !== window.zilPay.wallet.defaultAccount.bech32
    ) {
      return;
    }

    // let currentBalance = 0;
    // try {
    //   const { result } = await window.zilPay.blockchain.getBalance(
    //     currentWallet?.zilAddress
    //   );
    //   currentBalance = new BigNumber(result.balance)
    //     .div(Math.pow(10, 12))
    //     .toNumber();
    //   console.log("balance", currentBalance);
    // } catch (error) {
    //   console.log("createTxn error", error);
    // }

    const gasPrice = getGasPriceForClaim();
    const gasLimit = getGasLimitForClaim();

    const txParams = {
      version: version,
      amount: new BN(0),
      gasPrice: new BN(gasPrice),
      gasLimit: gasLimit,
    };

    return txParams;
  };

  const handleOnClickClaim = async (
    ssn: IModalClaimStakingItem,
    ownerZilAddress: string
  ) => {
    if (!isConnectZilpay(ownerZilAddress)) {
      toast({
        id: "pleaseConnectWallet",
        description: t("pleaseConnectWallet"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    let contractParams: ContractParams[] = [];
    if (ssn && ssn.reward1 && ssn.reward1 > 0) {
      contractParams.push({
        vname: "ssnaddr",
        type: "ByStr20",
        value: `${toChecksumAddress(ssn.stakingWallet.address)}`,
      });
    } else {
      toast({
        id: "notHaveReward",
        description: t("notHaveReward"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const txParams = await createTxn();
      const contractMethod = "WithdrawStakeRewards";
      await zilpayContractTx(contractMethod, contractParams, txParams, ssn);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <ModalHeader textTransform="capitalize" color={text3}>
        {t("claimStaking")}
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>
                {false && (
                  <Checkbox
                    isChecked={checkAll}
                    defaultIsChecked
                    onChange={() => {
                      setCheckList(fullArr(!checkAll));
                    }}
                  />
                )}
              </Th>
              <Th>{t("seedNode")}</Th>
              <Th isNumeric>{t("unclaimedReward")}</Th>
              <Th isNumeric>
                {false && (
                  <Button children={t("claimAll")} onClick={() => {}} />
                )}
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {tokenList.map((ssn: IModalClaimStakingItem, index: number) => {
              const { name, logo } = ssn.stakingWallet;
              let checked = checkList[index];

              let claimStakingItem = claimStakingItems.find(
                (claimStakingItem: any) =>
                  claimStakingItem.stakingWallet.name === name
              );
              if (!claimStakingItem) {
                return <Tr key={index} />;
              }

              const claimTx = claimTxs?.find(
                (claimTx) =>
                  claimTx.ssn.address ===
                  claimStakingItem?.stakingWallet.address
              );

              let currentAddress = currentWallet?.zilAddress || " ";

              return (
                <Tr key={index}>
                  <Td>
                    {false && (
                      <Checkbox
                        defaultIsChecked
                        isChecked={checked}
                        onChange={() => {
                          let a = [...checkList];
                          a[index] = !checkList[index];
                          setCheckList(a);
                        }}
                      />
                    )}
                  </Td>
                  <Td>
                    <Flex alignItems="center">
                      <TokenLogo src={logo} size="sm" mr="2" />
                      <Box>
                        <Text color={text2} fontSize="sm" fontWeight="semibold">
                          {name}
                        </Text>
                        <Text color={text6} fontSize="xs">
                          {dayjs(new Date()).format("MMM DD")}
                        </Text>
                      </Box>
                    </Flex>
                  </Td>
                  <Td isNumeric>
                    <Flex justifyContent="flex-end" alignItems="center">
                      <Text fontSize="sm" fontWeight="semibold" mr="1">
                        {formatToken(
                          claimStakingItem ? claimStakingItem.reward1 : 0,
                          "ZIL",
                          isHidden
                        )}
                      </Text>
                      <TokenLogo w="4" h="4" src={Images.zil} />
                    </Flex>
                    <Text fontSize="xs" color={text6}>
                      ~{" "}
                      {formatUsdt(
                        claimStakingItem
                          ? claimStakingItem.reward1 * zilPriceInUsd
                          : 0,
                        exchangeRate,
                        isHidden
                      )}
                    </Text>
                  </Td>
                  <Td isNumeric>
                    {!claimTx && (
                      <Button
                        children={t("common:claim")}
                        onClick={() =>
                          handleOnClickClaim(
                            claimStakingItems[index],
                            currentAddress
                          )
                        }
                      />
                    )}
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </ModalBody>
    </>
  );
};

export default ModalClaimStaking;
