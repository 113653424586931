import { useBoolean } from "@chakra-ui/hooks";
import React from "react";

type ModalContextType = {
  show: boolean;
  onOpenModalPremium: () => void;
  onCloseModalPremium: () => void;
};

export const ModalContext = React.createContext<ModalContextType>({
  show: false,
  onOpenModalPremium: () => null,
  onCloseModalPremium: () => null,
});

type ModalProviderProps = {
  children: React.ReactNode;
};

const ModalProvider = ({ children }: ModalProviderProps) => {
  const [show, setShow] = useBoolean(false);
  return (
    <ModalContext.Provider
      value={{
        show: show,
        onOpenModalPremium: setShow.on,
        onCloseModalPremium: setShow.off,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
