import React from "react";
import { Box, Flex, Text } from "@chakra-ui/layout";
import { Directions_Enum, HistorySpec, Tags_Enum } from "src/constants/type";
import { useAppColor } from "src/hooks";
import {
  IconAddLiquidity,
  IconClaim,
  IconExchange,
  IconMint,
  IconReceive,
  IconRemoveLiquidity,
  IconSend,
  IconStake,
  IconWithDraw,
} from "src/assets/icons";
import { dayjs } from "src/utils";
import { Avatar } from "@chakra-ui/avatar";
import { Images } from "src/assets/images";
import { BN } from "@zilliqa-js/zilliqa";
import { formatToken, formatUsdt } from "src/lib/formatCurrency";
import { useAppSelector } from "src/redux/hooks";
import { overviewSelector } from "src/redux/slices/overviewSlice";
import { IExchangeRate } from "src/constants/exchangeRates";
import { MotionBox } from "src/molecules";
import { useBoolean } from "@chakra-ui/hooks";
import { useTranslation } from "react-i18next";

interface TransactionItemProps {
  item: HistorySpec;
  exchangeRate: IExchangeRate;
  onClick?(): void;
}

const TransactionItem: React.FC<TransactionItemProps> = ({
  item,
  exchangeRate,
  onClick,
}) => {
  const { t } = useTranslation(["history", "common"]);
  const { text2, text4, text6, main3, main4, main6 } = useAppColor();
  const { tag, direction, timestamp, to, from, value, fee } = item;
  const { zilPriceInUsd } = useAppSelector(overviewSelector);

  const [show, setShow] = useBoolean(false);

  const QaRate = new BN("1000000000");

  const QaToZil = (amount?: string) => {
    return amount
      ? new BN(amount).div(QaRate).toNumber()
      : new BN("0").toNumber();
  };

  const getIcon = () => {
    switch (tag) {
      case Tags_Enum.DelegateStake:
        return <IconStake />;
      case Tags_Enum.WithdrawStakeRewards:
      case Tags_Enum.Claim:
      case Tags_Enum.ClaimMulti:
        return <IconClaim />;
      case Tags_Enum.AddLiquidity:
        return <IconAddLiquidity />;
      case Tags_Enum.RemoveLiquidity:
        return <IconRemoveLiquidity />;
      case Tags_Enum.withdrawPending:
        return <IconWithDraw />;
      case Tags_Enum.SwapExactZILForTokens:
      case Tags_Enum.SwapExactTokensForZIL:
      case Tags_Enum.SwapZILForExactTokens:
      case Tags_Enum.SwapExactTokensForTokens:
      case Tags_Enum.SwapTokensForExactTokens:
      case Tags_Enum.SwapExactTokensForExactTokens:
        return <IconExchange />;
      case Tags_Enum.Mint:
        return <IconMint />;
      case "":
      case Tags_Enum.Transfer:
        if (direction === "out") {
          return <IconSend />;
        }
        if (direction === "in") {
          return <IconReceive />;
        }
        return <IconReceive />;
      default:
        return <IconExchange />;
    }
  };

  const getTagName = () => {
    switch (tag) {
      case Tags_Enum.DelegateStake:
        return t("stake");
      case Tags_Enum.CompleteWithdrawal:
        return t("completeWithdrawal");
      case Tags_Enum.WithdrawStakeRewards:
        return t("claimRewards");
      case Tags_Enum.AddLiquidity:
        return t("addLiquidity");
      case Tags_Enum.withdrawPending:
        return t("withdrawPending");
      case Tags_Enum.RemoveLiquidity:
        return t("removeLiquidity");
      case Tags_Enum.IncreaseAllowance:
        return t("increaseAllowance");
      case Tags_Enum.WithdrawStakeAmt:
        return t("withdrawStake");
      case Tags_Enum.Claim:
        return t("claim");
      case Tags_Enum.ClaimMulti:
        return t("claimMulti");
      case Tags_Enum.SwapExactZILForTokens:
      case Tags_Enum.SwapExactTokensForZIL:
      case Tags_Enum.SwapZILForExactTokens:
      case Tags_Enum.SwapExactTokensForTokens:
      case Tags_Enum.SwapTokensForExactTokens:
      case Tags_Enum.SwapExactTokensForExactTokens:
        return t("swap");
      case "":
      case Tags_Enum.Transfer:
        if (direction === "out") {
          return t("send");
        }
        if (direction === "in") {
          return t("receive");
        }
        return t("transfers");
      default:
        return tag;
    }
  };

  return (
    <Box _active={{ opacity: 0.7 }}>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        py="4"
        ml="4"
        pr="4"
        borderBottomWidth="1px"
        borderBottomColor={main4}
      >
        <Flex alignItems="center" flex="1" onClick={onClick}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            w="32px"
            h="32px"
            rounded="16px"
            borderWidth="1px"
            borderColor={main4}
            fill={text2}
            mr="8px"
          >
            {getIcon()}
          </Box>
          <Box mr="4">
            <Text
              fontSize="sm"
              lineHeight="5"
              fontWeight="semibold"
              color={text2}
              isTruncated
              maxW={{ base: "160px", sm: "unset" }}
            >
              {getTagName()}
            </Text>
            <Text
              fontSize="xs"
              lineHeight="4"
              fontWeight="normal"
              color={text6}
            >
              {dayjs(timestamp).format("h:mm A")}
            </Text>
          </Box>
        </Flex>
        <Flex
          alignItems="center"
          justifyContent="flex-end"
          onClick={setShow.toggle}
        >
          <Avatar src={Images.zil} w="14px" h="14px" bg="transparent" />
          <Text
            fontSize="sm"
            lineHeight="5"
            fontWeight="semibold"
            ml="4px"
            color={direction === Directions_Enum.In ? "blue.600" : "red.600"}
          >
            {formatToken(QaToZil(value) / 1000)}
          </Text>
        </Flex>
      </Flex>
      {!!show && (
        <MotionBox
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            transition: { duration: 0.5 },
          }}
          borderBottomWidth="1px"
          borderBottomColor={main4}
        >
          <Flex borderBottomWidth="1px" borderBottomColor={main3} ml="4" pr="4">
            {[t("common:from"), t("common:to"), t("common:fee")].map(
              (item, key) => {
                return (
                  <Flex
                    key={key}
                    flex="1"
                    justifyContent={key === 2 ? "flex-end" : "flex-start"}
                    py="3"
                    alignItems="center"
                    fontSize="xs"
                    fontWeight="bold"
                    lineHeight="4"
                    letterSpacing="wider"
                    color={main6}
                  >
                    {item}
                  </Flex>
                );
              }
            )}
          </Flex>
          <Flex ml="4" pr="4" py="20px">
            <Flex flex="1">
              <Box
                display="flex"
                alignItems="center"
                border="1px"
                py="4px"
                pl="12px"
                pr="8px"
                borderRadius="6px"
                borderColor={main4}
              >
                <Box w="4px" h="4px" rounded="4px" mr="12px" />
                <Text
                  fontSize="xs"
                  lineHeight="4"
                  fontWeight="semibold"
                  color={text4}
                >
                  {!!from &&
                    from.substr(0, 4) + "..." + from.substr(from.length - 2)}
                </Text>
              </Box>
            </Flex>
            <Flex flex="1">
              <Box
                display="flex"
                alignItems="center"
                border="1px"
                py="4px"
                pl="12px"
                pr="8px"
                borderRadius="6px"
                borderColor={main4}
              >
                <Box w="4px" h="4px" rounded="4px" bg="green.600" mr="12px" />
                <Text
                  fontSize="xs"
                  lineHeight="4"
                  fontWeight="semibold"
                  color={text4}
                >
                  {!!to && to.substr(0, 4) + "..." + to.substr(to.length - 2)}
                </Text>
              </Box>
            </Flex>
            <Flex flex="1" flexDirection="column" alignItems="flex-end">
              <Flex alignItems="center">
                <Text
                  fontSize="sm"
                  lineHeight="5"
                  fontWeight="semibold"
                  color={text2}
                  mr="4px"
                >
                  {formatToken(QaToZil(fee) / 1000)}
                </Text>
                <Avatar src={Images.zil} w="14px" h="14px" bg="transparent" />
              </Flex>
              <Text
                fontSize="xs"
                lineHeight="4"
                fontWeight="normal"
                color={text6}
              >
                {formatUsdt(QaToZil(fee) * zilPriceInUsd, exchangeRate)}
              </Text>
            </Flex>
          </Flex>
        </MotionBox>
      )}
    </Box>
  );
};

export default TransactionItem;
