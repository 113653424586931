import { combineReducers } from "@reduxjs/toolkit";
import overview from "./slices/overviewSlice";
import login from "./slices/loginSlice";
import history from "./slices/historySlice";
import nft from "./slices/nftSlice";
import viewProfile from "./slices/viewProfileSlice";

const rootReducer = combineReducers({
  overview,
  login,
  history,
  nft,
  viewProfile,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
