import React from "react";
import Icon from "@chakra-ui/icon";
import { SvgIconProps } from "src/constants/type";

const SvgComponent: React.FC<SvgIconProps> = ({
  size,
  ...props
}: SvgIconProps) => {
  return (
    <Icon {...props} w={size} h={size} viewBox="0 0 16 16">
      <path d="M8.66645 5.21802V13.3327H7.33312V5.21802L3.75712 8.79402L2.81445 7.85135L7.99979 2.66602L13.1851 7.85135L12.2425 8.79402L8.66645 5.21802Z" />
    </Icon>
  );
};

const IconSend = React.memo(SvgComponent);
export default IconSend;
