import React from "react";
import {
  Box,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Flex,
  Center,
  Text,
  useBoolean,
  InputGroup,
  Input,
  InputRightElement,
  Checkbox,
} from "@chakra-ui/react";
import { BsSearch } from "react-icons/bs";
import { IconFilledArrow } from "src/assets/icons";
import { Images } from "src/assets/images";
import { TokenLogo } from "src/atoms";
import { Format_Types } from "src/constants/type";
import { useAppColor } from "src/hooks";
import { TextCurrency } from "src/molecules";
import WalletItem from "./WalletItem";
import { useTranslation } from "react-i18next";

const token_list = [
  {
    id: 0,
    subName: "Zilliqa",
    networkName: "Zil",
    networkLogo: Images.zil,
    logo: Images.zil,
    total: 20,
    price: 0.09229,
    amount: 70.5,
    worth: 45.7,
  },
  {
    id: 1,
    subName: "Package Portal",
    logo: Images.xsgd,
    networkName: "PORT",
    networkLogo: Images.zil,
    total: 30,
    price: 0.09229,
    amount: 50.6,
    worth: 2.6,
  },
  {
    id: 2,
    subName: "Carbon",
    logo: Images.carbon,
    networkName: "CARB",
    networkLogo: Images.zil,
    total: 10,
    price: 0.09229,
    amount: 20.33,
    worth: 20.33,
  },
  {
    id: 3,
    subName: "Zilswap",
    logo: Images.zilswap,
    networkName: "ZWAP",
    networkLogo: Images.zil,
    total: 20,
    price: 0.09229,
    amount: 30.4,
    worth: 10.5,
  },
];

interface OtherWalletProps {}

const OtherWallet: React.FC<OtherWalletProps> = () => {
  const { t } = useTranslation(["totalWalletAccount", "common"]);
  const { text2, text3, text5, text6, main1, main3, main6, main8 } =
    useAppColor();

  const [show, setShow] = useBoolean(true);
  const [hide, setHide] = useBoolean(true);
  const [filteredList] = React.useState(token_list);

  return (
    <Box>
      <Flex
        flexDirection={{ base: "column", lg: "row" }}
        justifyContent="space-between"
        alignItems={{ base: "unset", lg: "center" }}
        mt="6"
        mb="4"
      >
        <Text fontSize="xl" lineHeight="7" fontWeight="bold" color={text2}>
          {t("otherWallet")}
        </Text>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          mt={{ base: "2", lg: 0 }}
        >
          <Checkbox
            onChange={setHide.toggle}
            color={text6}
            flex="1"
            minW="140px"
          >
            <Text fontSize="xs" lineHeight="4" color={main6}>
              {t("hideBalance")}
            </Text>
          </Checkbox>
          <InputGroup maxW="320px">
            <InputRightElement color="gray.400" pointerEvents="none">
              <BsSearch />
            </InputRightElement>
            <Input bg={main8} placeholder={t("searchCoin")} />
          </InputGroup>
        </Flex>
      </Flex>
      <Box
        overflow="auto"
        bg={main1}
        borderRadius="lg"
        pb={filteredList.length >= 5 ? 0 : "2"}
      >
        <Box display={{ base: "none", lg: "block" }} pt="2">
          <Table variant="simple" borderRadius="lg">
            <Thead>
              <Tr>
                <Th>{t("common:asset")}</Th>
                <Th isNumeric>{t("common:price")}</Th>
                <Th isNumeric>{t("common:amount")}</Th>
                <Th isNumeric>{t("common:worth")}</Th>
                <Th isNumeric>{t("common:share")}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {filteredList.map((token, index) => {
                const {
                  networkName,
                  subName,
                  logo,
                  total,
                  price,
                  amount,
                  worth,
                } = token;

                if (!show && index >= 3) {
                  return <Tr key={index} />;
                }
                return (
                  <Tr key={index} cursor="pointer" _hover={{ bg: main3 }}>
                    <Td>
                      <Flex alignItems="center">
                        <TokenLogo size="sm" mr="2" src={logo} />
                        <Box justifyContent="flex-end">
                          <Text fontSize="sm" fontWeight="semibold">
                            {networkName}
                          </Text>
                          <Text fontSize="xs" color={text6}>
                            {subName}
                          </Text>
                        </Box>
                      </Flex>
                    </Td>
                    <Td isNumeric>
                      <Flex justifyContent="flex-end" alignItems="center">
                        <Text
                          fontWeight="600"
                          color={text6}
                          fontSize="12px"
                          justify-content="flex-end"
                        >
                          {"~"}${price}
                        </Text>
                      </Flex>
                    </Td>
                    <Td isNumeric>
                      <Flex justifyContent="flex-end" alignItems="center">
                        <Text fontSize="sm" fontWeight="semibold" mr="1">
                          {amount}
                        </Text>
                        <TokenLogo w="4" h="4" src={logo} />
                      </Flex>
                    </Td>
                    <Td isNumeric>
                      <TextCurrency
                        fontWeight="600"
                        formatType={
                          hide ? Format_Types.Default : Format_Types.Secure
                        }
                      >
                        {worth}
                      </TextCurrency>
                    </Td>
                    <Td isNumeric>
                      <Text fontWeight="600" color="blue.400">
                        {total}
                        {"%"}
                      </Text>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Box>
        <Box display={{ base: "block", lg: "none" }}>
          {filteredList.map((item, index) => {
            if (!show && index >= 4) {
              return <div key={index} />;
            }
            return <WalletItem key={index} item={item} />;
          })}
        </Box>
        {filteredList.length >= 5 && (
          <Center
            role="group"
            py="2"
            onClick={setShow.toggle}
            cursor="pointer"
            _hover={{ bg: main3 }}
          >
            <IconFilledArrow
              _groupHover={{ fill: text3 }}
              size="6"
              fill={text5}
              style={{ transform: `rotate(${show ? 0 : 180}deg)` }}
            />
          </Center>
        )}
      </Box>
    </Box>
  );
};

export default OtherWallet;
