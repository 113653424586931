import React from "react";
import ReactGA from "react-ga";
import { Provider } from "react-redux";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import store from "src/redux/store";
import "src/i18n";
import OverviewPage from "./pages/Overview";
import HistoryPage from "./pages/History";
import SwapPage from "./pages/Swap";
//import BorrowPage from "./pages/Borrow";
import NFTPage from "./pages/NFT";
import NFTDetailsPage from "./pages/NFTDetails";
import appTheme from "./theme";
import LoginPage from "./pages/Login";
import SettingsPage from "./pages/Settings";
import SendPage from "./pages/Send";
import ExchangeAccountPage from "./pages/ExchangeAccounts";
import TotalWalletPage from "./pages/TotalWallet";
import { useTranslation } from "react-i18next";
import MembershipPage from "./pages/Membership";
import NFTsPage from "./pages/NFTs";
import ProfilePage from "./pages/Profile";
import ReefPage from "./pages/Reef";
import ModalProvider from "./ModalContext";
import ViewProfilePage from "./pages/ViewProfile";

const TRACKING_ID = "G-K5GMBJZFNV";
ReactGA.initialize(TRACKING_ID);

const App = () => {
  const { t } = useTranslation("common");

  return (
    <Provider store={store}>
      <ChakraProvider theme={appTheme}>
        <ModalProvider>
          <Router>
            <Switch>
              <Route exact path="/total-wallet">
                <TotalWalletPage title={t("manageAccounts")} />
              </Route>
              <Route exact path="/history">
                <HistoryPage title={t("history")} />
              </Route>
              <Route exact path="/swap">
                <SwapPage title={t("swap")} />
              </Route>
              {/* <Route exact path="/borrow">
              <BorrowPage title={t("borrow")} />
            </Route> */}
              <Route exact path="/nft">
                <NFTPage title={t("collection")} />
              </Route>
              <Route exact path="/nfts">
                <NFTsPage title={t("NFTs")} />
              </Route>
              <Route exact path="/nft-details">
                <NFTDetailsPage title={t("nft")} />
              </Route>
              <Route exact path="/setting">
                <SettingsPage title={t("settings")} />
              </Route>
              <Route path="/profile">
                <ProfilePage title={"Profile"} />
              </Route>
              <Route path="/overview">
                <OverviewPage title={t("overview")} />
              </Route>
              <Route exact path="/exchange-account">
                <ExchangeAccountPage title={t("binanceAccounts")} />
              </Route>
              <Route path="/send">
                <SendPage title={t("send")} />
              </Route>
              <Route path="/membership">
                <MembershipPage title={t("membership")} />
              </Route>
              <Route exact path="/reef">
                <ReefPage title={t("reef")} />
              </Route>
              <Route path="/view-profile/:uuid">
                <ViewProfilePage title={t("viewProfile")} />
              </Route>
              <Route path="/">
                <LoginPage />
              </Route>
            </Switch>
          </Router>
        </ModalProvider>
      </ChakraProvider>
    </Provider>
  );
};

export default App;
