import React from "react";
import {
  Box,
  Button,
  Divider,
  Flex,
  Input,
  Text,
  Checkbox,
} from "@chakra-ui/react";
import { IconInformation } from "src/assets/icons";
import { useAppColor } from "src/hooks";
import { useTranslation } from "react-i18next";

interface DexRightProps {}

const DexRight: React.FC<DexRightProps> = () => {
  const { t } = useTranslation("swap");
  const { main2, main4, main6 } = useAppColor();

  return (
    <Box>
      <Text fontSize="md" lineHeight="6" fontWeight="semibold">
        {t("title3")}
      </Text>
      <Divider mt="10px" orientation="horizontal" bg={main4} />
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        h="48px"
        bg="blue.100"
        borderRadius="6px"
        mt="55px"
        mb="10px"
        p="10px"
      >
        <IconInformation fill="blue.500" size="16px" mt="5px" mx="10px" />
        <Text fontSize="md" lineHeight="6" color={main4}>
          {t("somethingHappened")}
        </Text>
      </Box>
      <Input
        placeholder={t("addressReceive")}
        size="lg"
        bgColor={main2}
        my="10px"
      />
      <Input placeholder={t("paymentID")} size="lg" bgColor={main2} my="10px" />
      <Input
        placeholder={t("addressToRefund")}
        size="lg"
        bgColor={main2}
        my="10px"
      />
      <Input placeholder={t("email")} size="lg" bgColor={main2} my="10px" />
      <Flex alignItems="center" justifyContent="space-between" my="10px">
        <Checkbox p="10px" />
        <Text fontSize="xs" lineHeight="4" color={main6}>
          {t("confirm")}
        </Text>
      </Flex>
      <Box display="flex" justifyContent="flex-end" my="10px">
        <Button>{t("proceedExchange")}</Button>
      </Box>
    </Box>
  );
};

export default DexRight;
