import React from "react";
import { Box, Flex, Text } from "@chakra-ui/layout";
import { Directions_Enum, HistorySpec, Tags_Enum } from "src/constants/type";
import { Td, Tr } from "@chakra-ui/table";
import { useAppColor } from "src/hooks";
import {
  IconAddLiquidity,
  IconClaim,
  IconExchange,
  IconMint,
  IconReceive,
  IconRemoveLiquidity,
  IconSend,
  IconStake,
  IconWithDraw,
} from "src/assets/icons";
import { dayjs } from "src/utils";
import { Avatar } from "@chakra-ui/avatar";
import { Images } from "src/assets/images";
import { BN } from "@zilliqa-js/zilliqa";
import { formatToken, formatUsdt } from "src/lib/formatCurrency";
import { useAppSelector } from "src/redux/hooks";
import { overviewSelector } from "src/redux/slices/overviewSlice";
import { IExchangeRate } from "src/constants/exchangeRates";
import { useTranslation } from "react-i18next";
import { TokenLogo } from "src/atoms";
import BigNumber from "bignumber.js";
import { getToken } from "src/utils/transaction";

interface TransactionProps {
  item: HistorySpec;
  exchangeRate: IExchangeRate;
  onClick?(): void;
}

const Transaction: React.FC<TransactionProps> = ({
  item,
  exchangeRate,
  onClick,
  ...props
}) => {
  const { t } = useTranslation(["history", "common"]);
  const { text2, text4, text6, main3, main4 } = useAppColor();
  const { tag, direction, timestamp, to, from, value, fee, events } = item;
  const { zilPriceInUsd, tokens } = useAppSelector(overviewSelector);

  const QaRate = new BN("1000000000");

  const QaToZil = (amount?: string) => {
    return amount
      ? new BN(amount).div(QaRate).toNumber()
      : new BN("0").toNumber();
  };

  const getIcon = () => {
    switch (tag) {
      case Tags_Enum.DelegateStake:
        return <IconStake />;
      case Tags_Enum.WithdrawStakeRewards:
      case Tags_Enum.Claim:
      case Tags_Enum.ClaimMulti:
        return <IconClaim />;
      case Tags_Enum.AddLiquidity:
        return <IconAddLiquidity />;
      case Tags_Enum.RemoveLiquidity:
        return <IconRemoveLiquidity />;
      case Tags_Enum.withdrawPending:
        return <IconWithDraw />;
      case Tags_Enum.SwapExactZILForTokens:
      case Tags_Enum.SwapExactTokensForZIL:
      case Tags_Enum.SwapZILForExactTokens:
      case Tags_Enum.SwapExactTokensForTokens:
      case Tags_Enum.SwapTokensForExactTokens:
      case Tags_Enum.SwapExactTokensForExactTokens:
        return <IconExchange />;
      case Tags_Enum.Mint:
        return <IconMint />;
      case "":
      case Tags_Enum.Transfer:
        if (direction === "out") {
          return <IconSend />;
        }
        if (direction === "in") {
          return <IconReceive />;
        }
        return <IconReceive />;
      case Tags_Enum.deposit:
      case Tags_Enum.Deposit:
        return <IconSend />;
      default:
        return <IconExchange />;
    }
  };

  const getTagName = () => {
    switch (tag) {
      case Tags_Enum.DelegateStake:
        return t("stake");
      case Tags_Enum.CompleteWithdrawal:
        return t("completeWithdrawal");
      case Tags_Enum.WithdrawStakeRewards:
        return t("claimRewards");
      case Tags_Enum.AddLiquidity:
        return t("addLiquidity");
      case Tags_Enum.withdrawPending:
        return t("withdrawPending");
      case Tags_Enum.RemoveLiquidity:
        return t("removeLiquidity");
      case Tags_Enum.IncreaseAllowance:
        return t("increaseAllowance");
      case Tags_Enum.WithdrawStakeAmt:
        return t("withdrawStake");
      case Tags_Enum.Claim:
        return t("claim");
      case Tags_Enum.ClaimMulti:
        return t("claimMulti");
      case Tags_Enum.SwapExactZILForTokens:
      case Tags_Enum.SwapExactTokensForZIL:
      case Tags_Enum.SwapZILForExactTokens:
      case Tags_Enum.SwapExactTokensForTokens:
      case Tags_Enum.SwapTokensForExactTokens:
      case Tags_Enum.SwapExactTokensForExactTokens:
        return t("swap");
      case "":
      case Tags_Enum.Transfer:
        if (direction === "out") {
          return t("send");
        }
        if (direction === "in") {
          return t("receive");
        }
        return t("transfers");
      case Tags_Enum.deposit:
      case Tags_Enum.Deposit:
        return t("deposit");
      default:
        return tag;
    }
  };
  const { tokenReceive, tokenSend } = getToken(
    tokens,
    events,
    value,
    tag,
    direction
  );
  const renderTokenRecieve = (tokenReceive: any[]) => {
    return tokenReceive.map((item, index) => {
      return (
        item && (
          <Flex key={index} display="flex">
            <TokenLogo src={item.token.icon} size="2xs" />
            <Text
              fontSize="sm"
              lineHeight="5"
              fontWeight="semibold"
              ml="4px"
              color={"blue.600"}
            >
              {formatToken(
                new BigNumber(item.amount)
                  .div(Math.pow(10, item.token.decimals))
                  .toNumber(),
                item.token.symbol
              )}
            </Text>
          </Flex>
        )
      );
    });
  };
  const renderTokenSend = (tokenSend: any[]) => {
    return tokenSend.map((item, index) => {
      return (
        item && (
          <Flex key={index} display="flex">
            <TokenLogo src={item?.token.icon} size="2xs" />
            <Text
              fontSize="sm"
              lineHeight="5"
              fontWeight="semibold"
              ml="4px"
              color={"red.600"}
            >
              {formatToken(
                new BigNumber(item.amount)
                  .div(Math.pow(10, item.token.decimals))
                  .toNumber(),
                item.token.symbol
              )}
            </Text>
          </Flex>
        )
      );
    });
  };
  return (
    <Tr
      {...props}
      cursor="pointer"
      _hover={{
        bg: main3,
      }}
      onClick={onClick}
    >
      <Td>
        <Flex alignItems="center">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            w="32px"
            h="32px"
            rounded="16px"
            borderWidth="1px"
            borderColor={main4}
            fill="gray.900"
            mr="8px"
            bg="white"
          >
            {getIcon()}
          </Box>
          <Box>
            <Text
              fontSize="sm"
              lineHeight="5"
              fontWeight="semibold"
              color={text2}
            >
              {getTagName()}
            </Text>
            <Text
              fontSize="xs"
              lineHeight="4"
              fontWeight="normal"
              color={text6}
            >
              {dayjs(timestamp).format("h:mm A")}
            </Text>
          </Box>
        </Flex>
      </Td>
      <Td isNumeric>
        <Flex>
          <Box
            display="flex"
            alignItems="center"
            border="1px"
            py="4px"
            pl="12px"
            pr="8px"
            borderRadius="6px"
            borderColor={main4}
          >
            <Box w="4px" h="4px" rounded="4px" bg="red.600" mr="12px" />
            <Text
              fontSize="xs"
              lineHeight="4"
              fontWeight="semibold"
              color={text4}
            >
              {!!from &&
                from.substr(0, 7) + "..." + from.substr(from.length - 8)}
            </Text>
          </Box>
        </Flex>
      </Td>
      <Td isNumeric>
        <Flex>
          <Box
            display="flex"
            alignItems="center"
            border="1px"
            py="4px"
            pl="12px"
            pr="8px"
            borderRadius="6px"
            borderColor={main4}
          >
            <Box w="4px" h="4px" rounded="4px" bg="green.600" mr="12px" />
            <Text
              fontSize="xs"
              lineHeight="4"
              fontWeight="semibold"
              color={text4}
            >
              {!!to && to.substr(0, 7) + "..." + to.substr(to.length - 8)}
            </Text>
          </Box>
        </Flex>
      </Td>
      <Td isNumeric>
        {tokenSend.length <= 0 && tokenReceive.length <= 0 && value !== "0" && (
          <Flex alignItems="center" display="block" justifyContent="flex-end">
            <Flex display="flex">
              <TokenLogo src={Images.zil} size="2xs" />
              <Text
                fontSize="sm"
                lineHeight="5"
                fontWeight="semibold"
                ml="4px"
                color={
                  direction === Directions_Enum.In ? "blue.600" : "red.600"
                }
              >
                {formatToken(QaToZil(value) / 1000)}
              </Text>
            </Flex>
          </Flex>
        )}
        <Flex alignItems="flex-end" display="block" justifyContent="flex-end">
          {tokenSend.length > 0 && renderTokenSend(tokenSend)}
        </Flex>
        <Flex
          alignItems="flex-end"
          display="block"
          justifyContent="flex-end"
          mt="2"
        >
          {tokenReceive.length > 0 && renderTokenRecieve(tokenReceive)}
        </Flex>
      </Td>
      <Td isNumeric>
        <Flex alignItems="center" justifyContent="flex-end">
          <Text
            fontSize="sm"
            lineHeight="5"
            fontWeight="semibold"
            color={text2}
            mr="4px"
          >
            {formatToken(QaToZil(fee) / 1000)}
          </Text>
          <Avatar src={Images.zil} w="14px" h="14px" bg="transparent" />
        </Flex>
        <Text fontSize="xs" lineHeight="4" fontWeight="normal" color={text6}>
          {formatUsdt((QaToZil(fee) * zilPriceInUsd) / 1000, exchangeRate)}
        </Text>
      </Td>
    </Tr>
  );
};

export default Transaction;
