import React, { memo } from "react";
import {
  Box,
  Grid,
  Link,
  Text,
  Image,
  Avatar,
  Flex,
  useToast,
  Button,
  useBoolean,
  AspectRatio,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { PageLayout } from "src/templates";
import { useTranslation } from "react-i18next";
import { useAppColor, useRouter } from "src/hooks";

import Attribute from "./components/Attribute";
import { IconArrowLeft } from "src/assets/icons";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { INft } from "src/constants/interfaces";
import { ButtonPin } from "../NFTs/components";
import { AddressInput, BoxTransaction } from "src/molecules";
import { isConnectZilpay } from "src/api/zilpayApi";
import { loginSelector, togglePinNft } from "src/redux/slices/loginSlice";
import { BN, fromBech32Address, validation } from "@zilliqa-js/zilliqa";

interface NFTDetailsPageProps {
  title: string;
}

export const NFTDetailsPage: React.FC<NFTDetailsPageProps> = memo(
  ({ title }) => {
    const toast = useToast();
    const { t } = useTranslation(["nftDetails", "common"]);
    const router = useRouter();
    const { text2, text4, text5, text6 } = useAppColor();
    const [nftItem] = React.useState<any>(router.location.state);
    const { logo, description, image, name, video } = nftItem;
    const attributes = nftItem?.attributes || [];
    const dispatch = useAppDispatch();
    const [targetAddress, setTargetAddress] = React.useState<string>("");
    const { currentWallet } = useAppSelector(loginSelector);
    const [isZRC1, toggle] = useBoolean(true);

    const getCodeContract = async () => {
      if (!nftItem && !nftItem.contractAddress) {
        return;
      }
      const contract = window.zilPay.contracts.at(nftItem.contractAddress);
      const smartContractCode =
        await window.zilPay.blockchain.getSmartContractCode(
          contract.address.slice(2)
        );
      if (smartContractCode.result.code.includes("AddOperator")) {
        toggle.off();
      } else {
        toggle.on();
      }
    };

    const onTransfer = React.useCallback(async () => {
      if (!targetAddress && targetAddress.length <= 0) {
        return;
      }
      if (!validation.isBech32(targetAddress)) {
        return;
      }
      if (isZRC1) {
        return;
      }
      if (!currentWallet || !isConnectZilpay(currentWallet.zilAddress)) {
        toast({
          id: "connect",
          description: t("common:pleaseConnectWallet"),
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }
      const contract = window.zilPay.contracts.at(nftItem.contractAddress);

      let contractParams = [
        {
          vname: "to",
          value: fromBech32Address(targetAddress),
          type: "ByStr20",
        },
        {
          vname: "token_id",
          value: nftItem.id,
          type: "Uint256",
        },
      ];
      let contractMethod = "TransferFrom";
      let txParams = {
        amount: new BN(0),
        gasPrice: "2000",
        gasLimit: "25000",
      };

      try {
        const sentTx = await contract.call(
          contractMethod,
          contractParams,
          txParams
        );
        toast({
          render: () => <BoxTransaction TranID={`0x${sentTx.ID}`} />,
        });
      } catch (error) {}
    }, [t, toast, currentWallet, targetAddress, nftItem, isZRC1]);

    const isPinned =
      !!currentWallet &&
      !!currentWallet.pinNfts &&
      currentWallet.pinNfts.findIndex(
        (pinNft) =>
          pinNft.image === nftItem.image || pinNft.video === nftItem.video
      ) !== -1;

    const errorPin = () => {
      if (!toast.isActive("over")) {
        toast({
          id: "over",
          description: t("overPin"),
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    };

    const togglePin = (nft: INft) => {
      try {
        dispatch(togglePinNft(nft, errorPin));
      } catch (error) {}
    };

    const data_list_on = [
      { title: "opensea", href: "" },
      { title: "mochi", href: "" },
      { title: "opensea", href: "" },
    ];
    React.useEffect(() => {
      getCodeContract();
    });
    return (
      <PageLayout title={title} isGoBack>
        <Box display="flex" alignItems="center">
          <Box
            cursor="pointer"
            display={{ base: "block", lg: "none" }}
            w="24px"
            h="24px"
            aria-label="back"
            justifyContent="flex-start"
            alignItems="center"
            onClick={router.history.goBack}
          >
            <IconArrowLeft fill={text2} />
          </Box>
          <Avatar w="40px" h="40px" src={logo} mr="8px" />
          <Text fontSize="xl" fontWeight="bold" color={text2}>
            {name}
          </Text>
        </Box>
        <Box
          mt="32px"
          display="flex"
          flexDirection={{ base: "column", md: "row" }}
        >
          <Box
            w="full"
            mb={{ base: "24px", md: "0px" }}
            mr={{ base: "0px", md: "24px" }}
          >
            {video && video !== "" ? (
              <AspectRatio ratio={1}>
                <video controls autoPlay loop>
                  <source src={video} type="video/mp4" />
                </video>
              </AspectRatio>
            ) : (
              <Image src={image} />
            )}
          </Box>
          <Box w={{ base: "full", md: "125%", lg: "150%" }}>
            <Flex justifyContent="space-between">
              <Text fontSize="lg" lineHeight="none" fontWeight="bold">
                {name} {nftItem.title}
              </Text>
              <ButtonPin
                active
                isPinned={isPinned}
                onClick={() => togglePin(nftItem)}
              />
            </Flex>
            <Text
              fontSize="xl"
              lineHeight="7"
              fontWeight="bold"
              color={text2}
              mt="16px"
            >
              {t("description")}
            </Text>
            <Text
              fontSize="md"
              lineHeight="6"
              fontWeight="normal"
              color={text5}
              mt="8px"
            >
              {description}
            </Text>
            <Box display="none">
              <Box mt="16px" display="flex" alignItems="center">
                <Text
                  fontSize="xl"
                  lineHeight="7"
                  fontWeight="bold"
                  mr="16px"
                  color={text2}
                >
                  {t("listOn")}
                </Text>
                {data_list_on.map((item, index) => {
                  const { title, href } = item;
                  return (
                    <Link
                      fontSize="xs"
                      lineHeight="4"
                      fontWeight="semibold"
                      color={text4}
                      key={index}
                      href={href}
                      isExternal
                      mr="24px"
                      display="flex"
                      alignItems="center"
                    >
                      {title} <ExternalLinkIcon ml="8px" />
                    </Link>
                  );
                })}
              </Box>
            </Box>
            {attributes.length > 0 && (
              <>
                <Text
                  fontSize="xl"
                  lineHeight="7"
                  fontWeight="bold"
                  mt="24px"
                  color={text2}
                >
                  {t("metadata")}
                </Text>
                <Grid mt="16px" templateColumns="repeat(4, 1fr)" gap="12px">
                  {attributes.map(
                    (item: any, index: React.Key | null | undefined) => {
                      return (
                        <Attribute key={index} item={item} onClick={() => {}} />
                      );
                    }
                  )}
                </Grid>
              </>
            )}
            {!isZRC1 && (
              <Text
                fontSize="md"
                lineHeight="7"
                fontWeight="medium"
                mt="6"
                color={text6}
              >
                {t("transfer")}
              </Text>
            )}
            {!isZRC1 && (
              <Flex alignItems="center" justifyContent="space-between" mt="2">
                <AddressInput
                  targetAddress={targetAddress}
                  setTargetAddress={setTargetAddress}
                />
                <Button children={t("transfer")} ml="4" onClick={onTransfer} />
              </Flex>
            )}
          </Box>
        </Box>
      </PageLayout>
    );
  }
);

export default NFTDetailsPage;
