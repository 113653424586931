import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  Flex,
  Box,
  VStack,
} from "@chakra-ui/react";
import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { useAppColor } from "src/hooks";

interface PopupLearnMoreProps {
  isOpen: boolean;
  onClose(): void;
}

export const PopupLearnMore = memo(
  ({ isOpen, onClose }: PopupLearnMoreProps) => {
    const { t } = useTranslation(["learnMorePremium"]);
    const { text5 } = useAppColor();

    const data_popup = [
      {
        title: t("content1"),
        description: t("desContent1"),
      },
      {
        title: t("content2"),
        description: t("desContent2"),
      },
      {
        title: t("content3"),
        description: t("desContent3"),
      },
      {
        title: t("content4"),
        description: t("desContent4"),
      },
    ];

    return (
      <Modal size="2xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent h="full" maxH="70%">
          <Flex alignItems="center" justifyContent="space-between" p="6" pb="4">
            <Text fontSize="18px" lineHeight={"28px"} fontWeight={"bold"}>
              {t("learnMorePremium:title")}
            </Text>
            <ModalCloseButton position="unset" />
          </Flex>
          <ModalBody overflowY="auto">
            <Text fontSize={"16px"} fontWeight={"24px"} mt={"4"}>
              {t("learnMorePremium:description1")}
            </Text>
            <Text fontSize={"16px"} fontWeight={"24px"} mt={"8"} mb="6">
              {t("learnMorePremium:description2")}
            </Text>
            <VStack spacing="8" alignItems="unset" fontSize="md" color={text5}>
              {data_popup.map((item, index) => {
                return (
                  <Box key={index}>
                    <Text
                      fontWeight={"700"}
                      fontSize={"16px"}
                      lineHeight={"24px"}
                      mb={"6"}
                    >
                      {item.title}
                    </Text>
                    <Text
                      fontWeight={"400"}
                      fontSize={"16px"}
                      lineHeight={"24px"}
                    >
                      {item.description}
                    </Text>
                  </Box>
                );
              })}
            </VStack>
            <Text
              fontWeight={"700"}
              fontSize={"16px"}
              lineHeight={"24px"}
              my={"6"}
            >
              {t("gotFeedBack")}
            </Text>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }
);

export default PopupLearnMore;
