import axios from "axios";
import { IApiGetAvatarResponse as IApiGetAvatarAddressFromNameResponse } from "./apiInterfaces";

export const apiGetAvatarAddressFromName = async (avatarName?: string) => {
  try {
    const url = `https://api.carbontoken.info/api/v1/avatar/${avatarName}`;
    const { data } = await axios.get<IApiGetAvatarAddressFromNameResponse>(url, {timeout: 1000 * 120});
    // const { data } = await axios.get<IApiGetAvatarAddressFromNameResponse>(url);
    return data.address;
  } catch (error) {
    return undefined;
  }
};

export const apiGetAvatarFromAddress = async (address: string) => {
  try {
    const url = `https://api.carbontoken.info/api/v1/address/${address}`;
    const { data } = await axios.get<string[]>(url);
    return data;
  } catch (error) {}
};
