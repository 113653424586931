import React, { useEffect } from "react";
import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Image,
  Text,
  Avatar,
  Button,
  useToast,
  Center,
  Box,
  Input,
  Flex,
  HStack,
  Icon,
  IconButton,
} from "@chakra-ui/react";
import { useBreakpointValue } from "@chakra-ui/media-query";
import { PageLayout } from "src/templates";
// Constants
import { IPoolInfo, IStakingInfo, ITokenPool } from "src/constants/interfaces";
// API
// import { getZilUnstakingPending } from "src/api/zilApi";
import { isConnectedZilpay, isConnectZilpay } from "src/api/zilpayApi";
// REDUX
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import {
  loginSelector,
  setCurrentTab,
  setIsMember,
  setMessage,
  setSavedWalletBanner,
  setSavedWalletFollowings,
} from "src/redux/slices/loginSlice";
import {
  IZilpayWallet,
  overviewSelector,
  syncZilPayWallet,
} from "src/redux/slices/overviewSlice";
// Components
import { DistributionWithStatus, Distributor } from "src/api/zilswapApi";
import ModalAddLP from "../Overview/components/ModalAddLP";
import ModalAddStake from "../Overview/components/ModalAddStake";
import ModalClaim from "../Overview/components/ModalClaim";
import ModalClaimStaking, {
  IModalClaimStakingItem,
} from "../Overview/components/ModalClaimStaking";
import ModalClaimZilSwap from "../Overview/components/ModalClaimZilSwap";
import { useAppColor, useCopy, useDisplayImage } from "src/hooks";
import { Images } from "src/assets/images";
import EditName from "./components/EditName";
import { AddIcon } from "@chakra-ui/icons";
import { NFTs } from "./components/NFT/NFTs";
import HistoryPage from "./components/History/HistoryPage";
import { useTranslation } from "react-i18next";
import ModalEditAvatar from "src/molecules/ModalEditAvatar";
import { LoginMessageEnum } from "src/constants/enum";
import Portfolio from "./components/Portfolio/Portfolio";
import { IconChecked, IconAdd } from "src/assets/icons";
import { followWallet, unFollowWallet } from "src/api/heyAlfieApi";
import useModal from "src/hooks/useModal";
import IconLink from "src/assets/icons/IconLink";
import DialogPremium from "./components/DialogPremium";

export enum OverviewModalStakingEnum {
  ClaimZil = 0,
  ClaimStaking = 1,
  ClaimSco = 2,
  ClaimPort = 3,
  ClaimZilSwap = 4,
  AddStaking = 5,
  AddLp = 6,
  Avatar = 7,
}

export type ProfilePageProps = { title: string };

export const ProfilePage: React.FC<ProfilePageProps> = ({ title }) => {
  const toast = useToast();
  const [copy] = useCopy();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { onOpenModalPremium } = useModal();

  const inputRef = React.useRef<HTMLInputElement>(null);

  const { t } = useTranslation(["profile"]);
  const { text2, text4, text6, main3, main4, main6, brand } = useAppColor();

  const dispatch = useAppDispatch();

  const {
    currentWallet: _currentWallet,
    savedWallets,
    message,
    currentTab,
    isMember,
  } = useAppSelector(loginSelector);

  const { heyAlfieNfts } = useAppSelector(overviewSelector);

  const currentWallet = savedWallets.find(
    (savedWallet) => savedWallet.zilAddress === _currentWallet?.zilAddress
  );

  const [modal, setModal] = React.useState<OverviewModalStakingEnum>();
  const [selectedStake] = React.useState<IModalClaimStakingItem[]>([]);
  const [selectedEpoch] = React.useState();
  const [addStakeData] = React.useState();
  const [addLPData] = React.useState();
  const [followings, setFollowings] = React.useState(0);
  const [followers, setFollowers] = React.useState(0);
  const [stakeToken] = React.useState<IStakingInfo>({
    name: "",
    link: "",
    stakeLogo: "",
    stakeList: [],
    apy: 0,
    symbol: "ZIL",
    pendingRewards: [],
    subName: "",
    unstakingPending: [],
  });

  const [lpPool] = React.useState<IPoolInfo>({
    name: "",
    link: "",
    logo: "",
    tokenPool: [] as ITokenPool[],
    distributions: [] as DistributionWithStatus[],
    distributors: [] as Distributor[],
    symbol: "",
    subName: "",
  });

  useEffect(() => {
    const interval = setInterval(() => {
      if (!currentWallet) {
        return;
      }
      const isConnect = isConnectZilpay(currentWallet.zilAddress);
      if (heyAlfieNfts.length > 0 && isConnect) {
        dispatch(setIsMember(true));
      } else {
        dispatch(setIsMember(false));
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [dispatch, heyAlfieNfts, currentWallet]);

  useEffect(() => {
    if (!currentWallet) {
      return;
    }
    if (currentWallet?.followings.length > 0) {
      const followingsAddress = JSON.parse(
        "[" + currentWallet?.followings[0].otherBech32 + "]"
      )[0] as string[];
      setFollowings(followingsAddress.length);
    }

    if (currentWallet?.followers.length > 0) {
      const followersAddress = JSON.parse(
        "[" + currentWallet?.followers[0].otherBech32 + "]"
      )[0] as string[];
      setFollowers(followersAddress.length);
    }

    if (!isConnectedZilpay()) {
      return;
    }

    // Subscribe to Zilpay change wallet event
    const accountStreamChanged = window.zilPay.wallet.observableAccount();
    accountStreamChanged.subscribe((account: any) => {
      if (!account) {
        return;
      }
      const _zilpayWallet: IZilpayWallet = {
        base16: account.base16,
        bech32: account.bech32,
      };
      if (_zilpayWallet.bech32 === currentWallet?.zilAddress) {
        return;
      }
      const existWallet = savedWallets.find(
        (wallet) => wallet.zilAddress === _zilpayWallet.bech32
      );
      if (existWallet) {
        return;
      }
      dispatch(syncZilPayWallet(_zilpayWallet));
    });
    return () => {
      accountStreamChanged &&
        accountStreamChanged.unsubscribe &&
        accountStreamChanged.unsubscribe();
    };
  }, [dispatch, currentWallet, savedWallets]);

  const onClickAvatar = () => {
    if (!currentWallet) {
      return;
    }
    if (!isConnectZilpay(currentWallet.zilAddress)) {
      toast({
        id: "pleaseConnectWallet",
        description: "Please connect ZilPay wallet",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (!isMember) {
      onOpenModalPremium();
      return;
    }
    setModal(7);
    onOpen();
  };

  const onChangeBanner = (event: any) => {
    event.stopPropagation();
    if (!currentWallet) {
      return;
    }
    if (!isConnectZilpay(currentWallet.zilAddress)) {
      if (!toast.isActive("pleaseConnectWallet")) {
        toast({
          id: "pleaseConnectWallet",
          description: "Please connect ZilPay wallet",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
      return;
    }
    if (!isMember) {
      onOpenModalPremium();
      return;
    }
    if (inputRef && inputRef.current) {
      inputRef.current.click();
    }
  };

  const { onUploader } = useDisplayImage((image) => {
    try {
      if (image) {
        dispatch(setSavedWalletBanner(image));
      }
      if (inputRef && inputRef.current) {
        inputRef.current.value = "";
      }
    } catch (error) {}
  });

  const onFollow = (zilAddress: string, follow: boolean) => {
    if (!currentWallet || !window.zilPay.wallet.defaultAccount) {
      toast({
        id: "sent",
        description: t("pleaseConnectWallet"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    setSavedWalletFollowings(zilAddress);
    follow
      ? followWallet(window.zilPay.wallet.defaultAccount.bech32, zilAddress)
      : unFollowWallet(window.zilPay.wallet.defaultAccount.bech32, zilAddress);
  };

  const buttonFollow = (zilAddress: string) => {
    const followingsAddress = JSON.parse(
      currentWallet?.followings && currentWallet?.followings.length > 0
        ? currentWallet?.followings[0].otherBech32
        : "[]"
    ) as string[];

    const ind = followingsAddress.findIndex((e) => e === zilAddress);
    return (
      <Button
        size="sm"
        leftIcon={
          <Icon as={ind !== -1 ? IconChecked : IconAdd} boxSize="16px" />
        }
        color={ind !== -1 ? text6 : "gray.900"}
        bg={ind !== -1 ? main3 : brand}
        visibility={
          !currentWallet || !isConnectZilpay(currentWallet.zilAddress)
            ? "visible"
            : "hidden"
        }
        onClick={() =>
          ind !== -1 ? onFollow(zilAddress, false) : onFollow(zilAddress, true)
        }
        children={ind !== -1 ? "Following" : "Follow"}
      />
    );
  };

  React.useEffect(() => {
    const emptyMessage = { id: "", message: "" };
    if (
      message?.id === LoginMessageEnum.UpdateBannerSuccess ||
      message?.id === LoginMessageEnum.UpdateBannerFailed ||
      message?.id === LoginMessageEnum.UpdateAvatarFailed ||
      message?.id === LoginMessageEnum.UpdateAvatarSuccess
    ) {
      dispatch(setMessage(emptyMessage));
      if (!toast.isActive(message.id)) {
        toast({
          id: message.id,
          description: message.message,
          status: message.status,
          duration: 3000,
          isClosable: true,
        });
      }
    }
  }, [message, dispatch, toast]);

  const onLink = () => {
    copy(
      window.location.href.replace(
        "profile",
        `view-profile/${currentWallet?.uuid}`
      )
    );

    if (!toast.isActive("copied"))
      toast({
        id: "copied",
        description: t("common:copied"),
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
  };

  return (
    <PageLayout title={title}>
      <Input
        type="file"
        accept="image/*"
        display="none"
        ref={inputRef}
        onChange={onUploader}
      />
      <Text
        display={{ base: "block", xl: "none" }}
        mb={{ base: "16px", xl: "0" }}
        fontSize="3xl"
        lineHeight="9"
        fontWeight="bold"
      >
        {title}
      </Text>
      <Stack>
        <Box
          cursor="pointer"
          position="relative"
          transition="all 0.3s ease-in-out"
          borderRadius="16px"
          borderWidth="1px"
          h="180px"
          overflow={"hidden"}
          role="group"
          mb={{ base: "-20px", md: "-60px" }}
          onClick={onChangeBanner}
        >
          <Image
            transition="all 0.3s ease-in-out"
            objectFit="cover"
            src={currentWallet?.banner || Images.defaultBanner}
            w="full"
            h="180px"
            _groupHover={{
              opacity: 0,
            }}
          />
          <Center
            position="absolute"
            bg="transparent"
            w="full"
            h="full"
            display="none"
            opacity="0"
            top="0"
            _groupHover={{ display: "flex", opacity: 1 }}
          >
            <Button
              variant="outline"
              leftIcon={<AddIcon w={3} h={3} color={main6} />}
              onClick={onChangeBanner}
              color={main6}
              size="sm"
              _hover={{
                bg: brand,
                color: "black",
              }}
            >
              {t("Add Banner")}
            </Button>
          </Center>
        </Box>
        <Stack
          direction={{ base: "column", lg: "row" }}
          position="relative"
          top={{ base: "-20px", md: "20px" }}
          justify="space-between"
        >
          <Stack
            direction={{ base: "column", md: "row" }}
            mb={{ base: "16px", md: "0" }}
          >
            <Avatar
              ml="30px"
              onClick={onClickAvatar}
              cursor="pointer"
              size={useBreakpointValue({ base: "xl", md: "2xl" })}
              position="relative"
              top={{ base: "-16px", md: "-30px" }}
              src={currentWallet?.avatar}
              alt="avatar"
            />
            <Flex>
              <EditName />
              {currentWallet?.uuid && (
                <IconButton
                  ml={{ base: "12px", md: "0" }}
                  display={{ base: "block", md: "none" }}
                  aria-label="link"
                  size="sm"
                  borderColor={main6}
                  color={main6}
                  borderRadius="8px"
                  variant="outline"
                  _hover={{
                    bg: brand,
                    color: "black",
                  }}
                  onClick={onLink}
                  icon={<IconLink />}
                />
              )}
            </Flex>
          </Stack>

          <HStack justifyContent={{ base: "space-between", md: "start" }}>
            <HStack>
              <Text color={text2} fontSize="14px" fontWeight="700">
                {followings}
              </Text>
              <Text color={main6} fontSize="14px" fontWeight="700">
                {t("common:following")}
              </Text>
            </HStack>
            <HStack pl="8px" pr="8px">
              <Text color={text2} fontSize="14px" fontWeight="700">
                {followers}
              </Text>
              <Text color={main6} fontSize="14px" fontWeight="700">
                {t("common:followers")}
              </Text>
            </HStack>
            {currentWallet?.zilAddress &&
            isConnectZilpay(currentWallet?.zilAddress) ? (
              <></>
            ) : (
              currentWallet?.zilAddress &&
              buttonFollow(currentWallet?.zilAddress)
            )}
            {currentWallet?.uuid && (
              <IconButton
                display={{ base: "none", md: "block" }}
                aria-label="link"
                size="sm"
                borderColor={main6}
                color={main6}
                borderRadius="8px"
                variant="outline"
                _hover={{
                  bg: brand,
                  color: "black",
                }}
                onClick={onLink}
                icon={<IconLink />}
              />
            )}
          </HStack>
        </Stack>

        <Tabs
          defaultIndex={currentTab}
          borderColor={main4}
          isLazy
          onChange={(index) => {
            dispatch(setCurrentTab(index));
          }}
        >
          <Flex
            borderBottom="1px"
            borderColor={main4}
            justifyContent="space-between"
            alignItems="center"
          >
            <TabList textColor={text6} borderBottom="0px">
              {["Portfolios", "NFTs", "History" /*"Feeds"*/].map(
                (value, index) => {
                  return (
                    <Tab
                      key={index}
                      pb="20px"
                      pl={{ base: index === 0 ? "0" : "24px", lg: "24px" }}
                      _selected={{
                        boxShadow: "none",
                        borderColor: brand,
                        color: brand,
                      }}
                    >
                      {value}
                    </Tab>
                  );
                }
              )}
            </TabList>
            {heyAlfieNfts.length > 0 && (
              <Button
                variant="outline"
                leftIcon={<Image src={Images.king} boxSize="4" />}
                onClick={() => {}}
                color={text4}
                size="sm"
                _hover={{
                  bg: brand,
                  color: "black",
                }}
              >
                {t("profile:Premium")}
              </Button>
            )}
          </Flex>

          <TabPanels>
            <TabPanel px="0">
              <Portfolio />
            </TabPanel>
            <TabPanel px="0">
              <NFTs />
            </TabPanel>
            <TabPanel px="0">
              {isMember ? <HistoryPage /> : <DialogPremium />}
            </TabPanel>
            <TabPanel px="0"></TabPanel>
          </TabPanels>
        </Tabs>
      </Stack>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={"3xl"}
        scrollBehavior="outside"
      >
        <ModalOverlay />
        <ModalContent>
          {modal === OverviewModalStakingEnum.Avatar ? (
            <ModalEditAvatar onClose={onClose} />
          ) : modal === OverviewModalStakingEnum.ClaimZil ? (
            <ModalClaimStaking
              claimStakingItems={selectedStake}
              onClose={onClose}
            />
          ) : modal === OverviewModalStakingEnum.AddStaking ? (
            <ModalAddStake
              onClose={onClose}
              data={selectedStake}
              initStake={addStakeData}
            />
          ) : modal === OverviewModalStakingEnum.ClaimZilSwap ? (
            <ModalClaimZilSwap onClose={onClose} lpPool={lpPool} />
          ) : modal === OverviewModalStakingEnum.AddLp ? (
            <ModalAddLP
              data={selectedEpoch}
              initLp={addLPData}
              onClose={onClose}
            />
          ) : modal === OverviewModalStakingEnum.ClaimStaking ? (
            <ModalClaim stakeToken={stakeToken} />
          ) : (
            <div />
          )}
        </ModalContent>
      </Modal>
    </PageLayout>
  );
};

export default ProfilePage;
