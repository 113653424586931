import React, { memo } from "react";
import { Text, TextProps } from "@chakra-ui/layout";

import numeral from "numeral";
import { Format_Types } from "src/constants/type";

export interface TextCurrencyProps extends TextProps {
  children?: any;
  formatType?: Format_Types;
}

const TextCurrency = memo(
  ({
    children,
    formatType = Format_Types.Default,
    ...props
  }: TextCurrencyProps) => {
    const currency = "$";

    const formatDefault = (amount: string, currency = "$") => {
      let textResult = `${currency}`;
      try {
        if (isNaN(parseFloat(amount))) {
          textResult += numeral(parseFloat(amount.replace(",", ""))).format(
            "0,0.00"
          );
        } else {
          textResult += numeral(parseFloat(amount)).format("0,0.00");
        }
      } catch (e) {
        console.log(e);
      }
      return textResult;
    };

    const formatSecure = (currency = "$") => {
      return currency + "****";
    };

    const formatTokenName = (text: string) => {
      return text.substr(0, 8) + "..." + text.substr(text.length - 6);
    };

    return (
      <Text {...props}>
        {formatType === Format_Types.Secure
          ? formatSecure(currency)
          : formatType === Format_Types.TokenName
          ? formatTokenName(children)
          : formatDefault(children, currency)}
      </Text>
    );
  }
);

export default TextCurrency;
