import React from "react";
import { useBoolean, Box, Center, Flex, Text } from "@chakra-ui/react";
import { IconFilledArrow } from "src/assets/icons";
import { useAppSelector } from "src/redux/hooks";
import { loginSelector } from "src/redux/slices/loginSlice";
import { overviewSelector } from "src/redux/slices/overviewSlice";
import { useAppColor } from "src/hooks";
import { fetchReward } from "src/api/stakingRewardApi";
import { IStakingInfo } from "src/constants/interfaces";
import { IModalClaimStakingItem } from "../ModalClaimStaking";
import StakingHeaderMb from "./StakingHeaderMb";
import StakingItemMb from "./StackingItemMb";
import { formatToken } from "src/lib/formatCurrency";
import { TokenLogo } from "src/atoms";
import { useTranslation } from "react-i18next";

interface StakingZilProps {
  stakeToken: IStakingInfo;
  claimStaking?: (stakeToken: IModalClaimStakingItem[]) => void;
}

const StakingZilMb: React.FC<StakingZilProps> = ({
  stakeToken,
  claimStaking,
}) => {
  const [show, setShow] = useBoolean(false);
  const [showAll, setShowAll] = useBoolean(false);
  const { brand, text3, text5, main3 } = useAppColor();

  const { walletStakingList } = useAppSelector(overviewSelector);

  const { currentWallet, isHidden } = useAppSelector(loginSelector);

  const { stakeList, symbol, stakeLogo, unstakingPending } = stakeToken;

  const { t } = useTranslation("overview");

  const [claimStakingItems, setClaimStakingItems] = React.useState<
    IModalClaimStakingItem[] | []
  >([]);

  React.useEffect(() => {
    const syncStakingReward = async () => {
      if (!currentWallet || !walletStakingList) {
        return;
      }

      let claimStakingItems = Array<IModalClaimStakingItem>();

      for (let i = 0; i < walletStakingList.length; i++) {
        const stakingWallet = walletStakingList[i];
        const { address, stakingBalance } = stakingWallet;
        if (stakingBalance && stakingBalance > 0) {
          const rewardResult = await fetchReward(
            address,
            currentWallet.zilAddress
          );
          const rewardNumber = parseFloat(rewardResult.toString());
          if (!isNaN(rewardNumber) && rewardNumber > 0) {
            claimStakingItems.push({
              stakingWallet: stakingWallet,
              reward1: rewardNumber * Math.pow(10, -12),
              reward2: rewardNumber * Math.pow(10, -12) * Math.pow(10, -3),
            });
          }
        }
      }
      setClaimStakingItems(claimStakingItems);
    };
    syncStakingReward();
  }, [walletStakingList, currentWallet]);

  const claimZil = React.useCallback(() => {
    claimStaking && claimStaking(claimStakingItems);
  }, [claimStaking, claimStakingItems]);

  const toggleShow = () => {
    setShow.toggle();
    setShowAll.off();
  };

  return (
    <React.Fragment>
      <StakingHeaderMb
        stakeToken={stakeToken}
        claimToken={claimZil}
        toggleShow={toggleShow}
      />

      {!!show && (
        <>
          <Box borderBottomWidth="1px" borderBottomColor={main3}>
            <Flex
              alignItems="center"
              flex="1"
              justifyContent="space-between"
              py="3"
              bg={brand}
              px="12"
            >
              <Flex direction="column">
                <Flex
                  w="full"
                  alignItems="center"
                  justifyContent="flex-end"
                  fontSize="sm"
                  fontWeight="semibold"
                >
                  {unstakingPending[0].unstaking > 0 && (
                    <React.Fragment>
                      <Flex
                        w="full"
                        alignItems="center"
                        justifyContent="flex-end"
                        color="black"
                        fontSize="sm"
                        fontWeight="semibold"
                      >
                        <Text>
                          {formatToken(
                            unstakingPending[0].unstaking,
                            symbol,
                            isHidden
                          )}
                        </Text>
                        <TokenLogo size="2xs" src={stakeLogo} ml="1" />
                      </Flex>
                    </React.Fragment>
                  )}
                </Flex>
                <Text color="blackAlpha.900" fontSize="xs">
                  {t("currentUnstaking")}
                </Text>
              </Flex>
              <Box>
                {/* <Flex
                  w="full"
                  alignItems="center"
                  justifyContent="flex-end"
                  fontSize="sm"
                  fontWeight="semibold"
                >
                  {unstakingPending > 0 && (
                    <React.Fragment>
                      <Flex
                        w="full"
                        alignItems="center"
                        justifyContent="flex-end"
                        color="black"
                        fontSize="sm"
                        fontWeight="semibold"
                      >
                        <Text>
                          {formatToken(unstakingPending, symbol, isHidden)}
                        </Text>
                        <TokenLogo size="2xs" src={stakeLogo} ml="1" />
                      </Flex>
                    </React.Fragment>
                  )}
                </Flex>
                <Text color="blackAlpha.900" fontSize="xs">
                  {t("availableWithdraw")}
                </Text> */}
              </Box>
            </Flex>
          </Box>

          {stakeList &&
            stakeList.map((token, index) => {
              const { name, stakingBalance } = token;
              let reward = 0;
              let rate = 1;
              if (
                (!stakingBalance && !showAll) ||
                (stakingBalance && stakingBalance <= 0 && !showAll)
              ) {
                return <Box key={index} />;
              }

              let claimStakingItem = claimStakingItems.find(
                (claimStakingItem) =>
                  claimStakingItem.stakingWallet.name === name
              );

              reward = claimStakingItem ? claimStakingItem.reward1 : 0;

              return (
                <StakingItemMb
                  key={index}
                  {...stakeToken}
                  {...token}
                  reward={reward}
                  rate={rate}
                />
              );
            })}
          <Center>
            <IconFilledArrow
              position="absolute"
              _groupHover={{
                fill: text3,
              }}
              onClick={() => {
                setShowAll.toggle();
              }}
              cursor="pointer"
              size="6"
              fill={text5}
              style={{
                transform: `rotate(${showAll ? 0 : 180}deg)`,
              }}
            />
          </Center>
        </>
      )}
    </React.Fragment>
  );
};

export default StakingZilMb;
