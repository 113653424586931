import React from "react";
import { Box, Center, Flex } from "@chakra-ui/layout";
import {
  Thead,
  Tr,
  Td,
  AvatarGroup,
  Button,
  Tbody,
  Th,
  Text,
  useBoolean,
} from "@chakra-ui/react";
import BigNumber from "bignumber.js";
import { isEmpty } from "lodash";
import { IconFilledArrow } from "src/assets/icons";
import { TokenLogo, ButtonUnlock } from "src/atoms";
import { ITokenPool } from "src/constants/interfaces";
import { formatToken, formatUsdt } from "src/lib/formatCurrency";
import { useAppSelector } from "src/redux/hooks";
import { overviewSelector } from "src/redux/slices/overviewSlice";
import { handleGoLink, sortLiquidityPool } from "src/utils";
import { useAppColor } from "src/hooks";
import { loginSelector } from "src/redux/slices/loginSlice";
import { useTranslation } from "react-i18next";

interface LPXcadProps {
  onClaim: (lpPool: any) => void;
}

const LPXcad: React.FC<LPXcadProps> = ({ onClaim }) => {
  const { t } = useTranslation(["overview", "common"]);
  const {
    tokenRates,
    zilPriceInUsd,
    tokens,
    xcadPools,
    xcadClaimableData,
    xcadDistributions,
    xcadDistributors,
  } = useAppSelector(overviewSelector);
  const { exchangeRate, isHidden } = useAppSelector(loginSelector);

  const [show, setShow] = useBoolean(false);
  const [showAll, setShowAll] = useBoolean(false);

  const { text1, text2, text3, text5, text6, main2, main3 } = useAppColor();

  const [totalReward, setTotalReward] = React.useState<number>(0);

  const availableToken = (tokenPool: ITokenPool) => {
    const { userContribution, totalContribution } = tokenPool;
    return (
      !userContribution ||
      userContribution.toNumber() <= 0 ||
      totalContribution.toNumber() <= 0
    );
  };

  React.useEffect(() => {
    let total: number = 0;
    const dXCAD = tokens.find((token) => token.symbol === "dXCAD");
    if (dXCAD) {
      const dXCADDecimal = dXCAD.decimals;
      xcadClaimableData &&
        xcadClaimableData.forEach((claimable) => {
          total += new BigNumber(claimable.amount)
            .div(Math.pow(10, dXCADDecimal))
            .toNumber();
        });
    }
    setTotalReward(total);
  }, [xcadClaimableData, tokens]);

  const xcad = {
    name: "XCAD Network",
    link: "swap.xcadnetwork.com/pool-overview/",
    logo: "https://meta.viewblock.io/ZIL.zil1xfcg9hfpdlmz2aytz0s4dww35hfa6s0jnjut5f/logo",
    tokenPool: sortLiquidityPool(xcadPools),
    distributions: xcadDistributions,
    distributors: xcadDistributors,
    symbol: "XCAD",
    subName: "https://swap.xcadnetwork.com/",
  };

  const { name, logo, link, tokenPool, symbol, subName } = xcad;

  if (isEmpty(tokenPool)) {
    return <Thead />;
  }

  let tokenList = [] as string[];

  tokenPool.forEach((tokenPool) => {
    const { token } = tokenPool;
    if (availableToken(tokenPool)) {
      return;
    }
    tokenList.push(token.icon);
  });

  return (
    <React.Fragment>
      <Thead>
        <Tr>
          <Td>
            <Flex alignItems="center">
              <TokenLogo src={logo} size="sm" mr="2" />
              <Box>
                <Text color={text2} fontSize="sm" fontWeight="semibold">
                  {name}
                </Text>
                <Text
                  color={text6}
                  fontSize="xs"
                  cursor="pointer"
                  onClick={() => handleGoLink(link)}
                >
                  {subName}
                </Text>
              </Box>
            </Flex>
          </Td>
          <Td>
            <AvatarGroup
              size="xs"
              spacing={2}
              max={3}
              fontSize="xx-small"
              justifyContent="end"
            >
              {tokenList.map((token, index) => (
                <TokenLogo src={token} key={index} />
              ))}
            </AvatarGroup>
          </Td>
          <Td isNumeric>
            <Flex
              alignItems="center"
              justifyContent="flex-end"
              color={text1}
              fontSize="sm"
              fontWeight="semibold"
            >
              <Text>{formatToken(totalReward, "dXCAD", isHidden)}</Text>
              <TokenLogo size="2xs" src={logo} ml="1" />
            </Flex>
            <Text color={text3} fontSize="xs">
              {t("unclaimedReward")}
            </Text>
          </Td>
          <Td>
            <Flex justifyContent="flex-end" alignItems="center">
              {isHidden ? (
                <ButtonUnlock mr="4" />
              ) : (
                <Button
                  variant="outline"
                  children={t("Claim")}
                  mr="4"
                  // onClick={() => handleGoLink(link)}
                  onClick={() => onClaim(xcad)}
                />
              )}
              <IconFilledArrow
                fill={text1}
                cursor="pointer"
                onClick={() => {
                  setShowAll.off();

                  if (show || !show) {
                    setShow.toggle();
                  }
                }}
                _hover={{
                  fill: text3,
                }}
                size="6"
                style={{
                  transform: `rotate(${show ? 0 : 180}deg)`,
                }}
              />
            </Flex>
          </Td>
        </Tr>
      </Thead>
      {show && (
        <>
          <Tbody bg={main3}>
            <Tr bg={main2}>
              <Th>{t("pool")}</Th>
              <Th isNumeric>{t("deposit")}</Th>
              <Th isNumeric>{t("rewardDistributed")}</Th>
              <Th isNumeric>{t("action")}</Th>
            </Tr>
            {tokenPool.map((tokenPool: ITokenPool, i) => {
              const {
                token,
                userContribution,
                zilReserve,
                tokenReserve,
                totalContribution,
                isSpecial,
              } = tokenPool;

              let _symbol = isSpecial ? "ZIL" : symbol;
              const poolSymbol = tokens.find(
                (token) => token.symbol === _symbol
              );

              if (availableToken(tokenPool) && !showAll) {
                return <Tr key={i} />;
              }

              const tokenRate = tokenRates.find(
                (tokenRate) =>
                  tokenRate.token.address_bech32 === token.address_bech32
              );

              const poolTokenRate = tokenRates.find(
                (tokenRate) => tokenRate.token.symbol === _symbol
              );

              let contributionPercentage = new BigNumber(0);
              if (totalContribution.toNumber() > 0) {
                contributionPercentage = userContribution!
                  .dividedBy(totalContribution)
                  .times(100);
              }

              let contributionShare = contributionPercentage.shiftedBy(-2);

              let tokenAmount = contributionShare
                .times(tokenReserve)
                .div(Math.pow(10, token.decimals))
                .toNumber();

              let poolTokenAmount = contributionShare
                .times(zilReserve)
                .div(Math.pow(10, poolSymbol?.decimals || 12))
                .toNumber();

              return (
                <Tr key={i}>
                  <Td>
                    <Flex alignItems="center">
                      <Box position="relative" mr="2" w="9" h="9">
                        <TokenLogo size="xs" src={token.icon} />
                        <TokenLogo
                          position="absolute"
                          size="xs"
                          src={poolSymbol?.icon || logo}
                          right="0"
                          bottom="0"
                          p="3px"
                        />
                      </Box>
                      <Box justifyContent="flex-end">
                        <Text fontSize="sm" fontWeight="semibold">
                          {token.symbol} {"<> "} {_symbol}
                        </Text>
                        <Text fontSize="xs" color={text6}>
                          {t("poolShare")} |{" "}
                          {(contributionShare.toNumber() * 100).toFixed(3)}%
                        </Text>
                      </Box>
                    </Flex>
                  </Td>
                  <Td isNumeric>
                    <Flex justifyContent="flex-end" alignItems="center">
                      <Text fontSize="sm" fontWeight="semibold" mr="1">
                        {formatToken(tokenAmount, token.symbol, isHidden)}
                      </Text>
                      <TokenLogo w="4" h="4" src={token.icon} />
                      <Text mx="2">+</Text>
                      <Text fontSize="sm" fontWeight="semibold" mr="1">
                        {formatToken(poolTokenAmount, _symbol, isHidden)}
                      </Text>
                      <TokenLogo w="4" h="4" src={poolSymbol?.icon || logo} />
                    </Flex>
                    <Text fontSize="xs" color={text6}>
                      ~{" "}
                      {formatUsdt(
                        (tokenAmount * (tokenRate?.rate || 1) +
                          poolTokenAmount * (poolTokenRate?.rate || 1)) *
                          zilPriceInUsd,
                        exchangeRate,
                        isHidden
                      )}
                    </Text>
                  </Td>
                  <Td isNumeric>
                    <AvatarGroup
                      size="sm"
                      spacing={2}
                      max={6}
                      fontSize="sm"
                      justifyContent="end"
                    >
                      {[0].map((i, index) => (
                        <TokenLogo src={logo} key={index} />
                      ))}
                    </AvatarGroup>
                    {/* <Flex justifyContent="flex-end" alignItems="center">
                      <Text
                        fontSize="sm"
                        fontWeight="semibold"
                        mr="1"
                        alignItems="center"
                        display="flex"
                      >
                        {formatToken(0, "dXCAD", isHidden)}
                        <TokenLogo w="4" h="4" ml="1" src={logo} />
                      </Text>
                    </Flex> */}
                    <Text fontSize="xs" color={text6}>
                      {/* ~${unclaimed} */}
                    </Text>
                  </Td>
                  <Td>
                    <Flex justifyContent="flex-end" alignItems="center">
                      {isHidden ? (
                        <ButtonUnlock size="sm" />
                      ) : (
                        <>
                          {/* Hide Remove Button
                          <Button
                            variant="ghost"
                            children={t("common:remove")}
                            mr="4"
                            size="sm"
                          /> */}
                          <Button
                            children={t("common:add")}
                            size="sm"
                            onClick={() => handleGoLink(link)}
                          />
                        </>
                      )}
                    </Flex>
                  </Td>
                </Tr>
              );
            })}
            <Tr>
              <Td p="0" position="relative" colSpan={4}>
                <Center>
                  <IconFilledArrow
                    position="absolute"
                    _groupHover={{
                      fill: text3,
                    }}
                    onClick={setShowAll.toggle}
                    cursor="pointer"
                    size="6"
                    fill={text5}
                    style={{
                      transform: `rotate(${showAll ? 0 : 180}deg)`,
                    }}
                  />
                </Center>
              </Td>
            </Tr>
          </Tbody>
        </>
      )}
    </React.Fragment>
  );
};

export default LPXcad;
