import React, { useEffect } from "react";
import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  Stack,
} from "@chakra-ui/react";
import { PageLayout } from "src/templates";
// Constants
import { IPoolInfo, IStakingInfo, ITokenPool } from "src/constants/interfaces";
// API
// import { getZilUnstakingPending } from "src/api/zilApi";
import { isConnectZilpay } from "src/api/zilpayApi";
// REDUX
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { loginSelector } from "src/redux/slices/loginSlice";
import {
  IZilpayWallet,
  syncZilPayWallet,
} from "src/redux/slices/overviewSlice";
// Components
import ModalAddLP from "./components/ModalAddLP";
import ModalAddStake from "./components/ModalAddStake";
import EditAddress from "./EditAddress";
import Portfolio from "./Portfolio";
import WalletBalance from "./WalletBalance";
import StakingBalance from "./StakingBalance";
import LiquidityPoolBalance from "./LiquidityPoolBalance";
import ModalClaimZilSwap from "./components/ModalClaimZilSwap";
import ModalClaimStaking, {
  IModalClaimStakingItem,
} from "./components/ModalClaimStaking";
import ModalClaim from "./components/ModalClaim";
import { DistributionWithStatus, Distributor } from "src/api/zilswapApi";

export enum OverviewModalStakingEnum {
  ClaimZil = 0,
  ClaimStaking = 1,
  ClaimSco = 2,
  ClaimPort = 3,
  ClaimZilSwap = 4,
  AddStaking = 5,
  AddLp = 6,
}

export type OverviewPageProps = { title: string };

export const OverviewPage: React.FC<OverviewPageProps> = ({ title }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const dispatch = useAppDispatch();
  const { currentWallet, savedWallets } = useAppSelector(loginSelector);

  const [modal, setModal] = React.useState<OverviewModalStakingEnum>();
  const [selectedStake, setSelectedStake] = React.useState<
    IModalClaimStakingItem[]
  >([]);
  const [selectedEpoch, setSelectedEpoch] = React.useState();
  const [addStakeData, setAddStakeData] = React.useState();
  const [addLPData, setAddLPData] = React.useState();
  const [stakeToken, setStakeToken] = React.useState<IStakingInfo>({
    name: "",
    link: "",
    stakeLogo: "",
    stakeList: [],
    apy: 0,
    symbol: "ZIL",
    pendingRewards: [],
    subName: "",
    unstakingPending: [],
  });

  const [lpPool, setLpPool] = React.useState<IPoolInfo>({
    name: "",
    link: "",
    logo: "",
    tokenPool: [] as ITokenPool[],
    distributions: [] as DistributionWithStatus[],
    distributors: [] as Distributor[],
    symbol: "",
    subName: "",
  });

  useEffect(() => {
    if (!currentWallet) {
      return;
    }

    if (!isConnectZilpay(currentWallet.zilAddress)) {
      return;
    }

    // Subscribe to Zilpay change wallet event
    const accountStreamChanged = window.zilPay.wallet.observableAccount();
    accountStreamChanged.subscribe((account: any) => {
      if (!account) {
        return;
      }
      const _zilpayWallet: IZilpayWallet = {
        base16: account.base16,
        bech32: account.bech32,
      };
      if (_zilpayWallet.bech32 === currentWallet?.zilAddress) {
        return;
      }
      const existWallet = savedWallets.find(
        (wallet) => wallet.zilAddress === _zilpayWallet.bech32
      );
      if (existWallet) {
        return;
      }
      dispatch(syncZilPayWallet(_zilpayWallet));
    });
    return () => {
      accountStreamChanged &&
        accountStreamChanged.unsubscribe &&
        accountStreamChanged.unsubscribe();
    };
  }, [dispatch, currentWallet, savedWallets]);

  const claimStaking = (stakeToken: IModalClaimStakingItem[]) => {
    setSelectedStake(stakeToken);
    setModal(OverviewModalStakingEnum.ClaimZil);
    onOpen();
  };

  const addStaking = (stakeToken: any, token: any) => {
    setSelectedStake(stakeToken);
    setAddStakeData(token);
    setModal(OverviewModalStakingEnum.AddStaking);
    onOpen();
  };

  const claimEpoch = (lpToken: any) => {
    setModal(OverviewModalStakingEnum.ClaimZilSwap);
    setLpPool(lpToken);
    onOpen();
  };

  const addLP = (lpToken: any, token: any) => {
    setSelectedEpoch(lpToken);
    setAddLPData(token);
    setModal(OverviewModalStakingEnum.AddLp);
    onOpen();
  };

  const onClaimStaking = (stakingList: IStakingInfo) => {
    setModal(OverviewModalStakingEnum.ClaimStaking);
    setStakeToken(stakingList);
    onOpen();
  };

  return (
    <PageLayout title={title}>
      <Stack>
        <EditAddress />
        <Portfolio />
        <WalletBalance />
        <StakingBalance
          claimStaking={claimStaking}
          addStaking={addStaking}
          onClaimStaking={onClaimStaking}
        />
        <LiquidityPoolBalance claimEpoch={claimEpoch} addLP={addLP} />
      </Stack>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="3xl"
        scrollBehavior="outside"
      >
        <ModalOverlay />
        <ModalContent>
          {modal === OverviewModalStakingEnum.ClaimZil ? (
            <ModalClaimStaking
              claimStakingItems={selectedStake}
              onClose={onClose}
            />
          ) : modal === OverviewModalStakingEnum.AddStaking ? (
            <ModalAddStake
              onClose={onClose}
              data={selectedStake}
              initStake={addStakeData}
            />
          ) : modal === OverviewModalStakingEnum.ClaimZilSwap ? (
            <ModalClaimZilSwap onClose={onClose} lpPool={lpPool} />
          ) : modal === OverviewModalStakingEnum.AddLp ? (
            <ModalAddLP
              data={selectedEpoch}
              initLp={addLPData}
              onClose={onClose}
            />
          ) : modal === OverviewModalStakingEnum.ClaimStaking ? (
            <ModalClaim stakeToken={stakeToken} />
          ) : (
            <div />
          )}
        </ModalContent>
      </Modal>
    </PageLayout>
  );
};

export default OverviewPage;
