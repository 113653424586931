import React from "react";
import { BoxProps } from "@chakra-ui/layout";
import { INft, ISmartContract } from "src/constants/interfaces";
import {
  fetchNftFromDatabase,
  fetchNftFromSmartContract,
} from "src/api/nftApi";
import axios from "axios";
import { useAppSelector } from "src/redux/hooks";
import { overviewSelector } from "src/redux/slices/overviewSlice";
import { Avatar } from "@chakra-ui/react";
import { useAppColor } from "src/hooks";

interface NFTImageProps extends BoxProps {
  smartContract?: ISmartContract;
  initNft?: INft;
  tokenUri?: string;
  onItemClick: (image: string) => void;
  selected?: string;
}

const NFTImage: React.FC<NFTImageProps> = ({
  smartContract,
  tokenUri,
  initNft,
  onItemClick,
  selected,
}) => {
  const { brand, main3 } = useAppColor();
  const [nft, setNft] = React.useState<INft | undefined>(initNft);

  const { collections } = useAppSelector(overviewSelector);

  const [updatingNft] = React.useState<INft>({
    description: "Updating",
    image:
      "https://user-images.githubusercontent.com/47516405/153706762-3539bdf5-597b-4d3b-8e73-3d237a887c66.png",
    title: "Alfie Members Club",
  });

  React.useEffect(() => {
    (async () => {
      try {
        const cancelToken = axios.CancelToken.source();
        if (initNft) {
          return;
        }
        if (smartContract) {
          const collection = collections.find(
            (item) => item.contractAddress === smartContract.contractAddress
          );
          let nft: INft = updatingNft;
          try {
            nft = await fetchNftFromDatabase(
              smartContract,
              cancelToken.token,
              collection
            );
          } catch (error) {}
          if (!nft.image) {
            nft = await fetchNftFromSmartContract(
              smartContract,
              cancelToken.token,
              collection
            );
          }
          setNft(nft);
        } else if (tokenUri === "Updating") {
          setNft(updatingNft);
        }
        return () => cancelToken.cancel();
      } catch (error) {}
    })();
  }, [smartContract, tokenUri, initNft, collections, updatingNft]);

  if (!nft) {
    return <></>;
  }

  const { image } = nft;

  return (
    <React.Fragment>
      {image && (
        <Avatar
          border="2px"
          bg="transparent"
          onClick={() => onItemClick(image)}
          cursor="pointer"
          src={image}
          color={selected === image ? brand : main3}
        />
      )}
    </React.Fragment>
  );
};

export default NFTImage;
