import React from "react";
import { useTranslation } from "react-i18next";
import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  TextProps,
  useToast,
} from "@chakra-ui/react";
import { useAppColor } from "src/hooks";
import { formatUsdt } from "src/lib/formatCurrency";
import { useAppSelector } from "src/redux/hooks";
import { loginSelector } from "src/redux/slices/loginSlice";
import { handleGoLink } from "src/utils";
import { isConnectZilpay } from "src/api/zilpayApi";

interface ModalTweetProps {
  onClose: () => void;
  totalTransactions: number;
  firstDate: string;
  gasFeeSpent: number;
}

const ModalTweet: React.FC<ModalTweetProps> = ({
  onClose,
  totalTransactions,
  firstDate,
  gasFeeSpent,
}) => {
  const { t } = useTranslation(["history", "common"]);
  const { text6, main4, brand } = useAppColor();

  const { exchangeRate, currentWallet } = useAppSelector(loginSelector);

  const toast = useToast();

  const shareDetails = t("shareDetail").split("{input}");

  const onTweetNow = async () => {
    if (!currentWallet) {
      return;
    }

    if (!isConnectZilpay(currentWallet.zilAddress)) {
      toast({
        id: "pleaseConnectWallet",
        description: t("common:pleaseConnectWallet"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const text =
      shareDetails[0] +
      totalTransactions +
      shareDetails[1] +
      firstDate +
      "%0A" +
      shareDetails[2] +
      formatUsdt(gasFeeSpent, exchangeRate) +
      "%0A" +
      shareDetails[3];

    handleGoLink(
      `twitter.com/intent/tweet?text=${text}&url=${"https://heyalfie.io"}%0A%0A&hashtags=Zilliqa,${t(
        "alfieMembersClub"
      )}%20%40heyalfie_io`
    );
  };

  const TextSpan = (props: TextProps) => (
    <Text as="span" fontWeight={"bold"} {...props} />
  );

  return (
    <>
      <ModalHeader>{t("shareTitle")}</ModalHeader>
      <ModalBody>
        <Text color={brand} whiteSpace="pre-wrap">
          {shareDetails[0]}
          <TextSpan>{totalTransactions}</TextSpan>
          {shareDetails[1]}
          <TextSpan>{firstDate}</TextSpan>
          {shareDetails[2]}
          <TextSpan>{formatUsdt(gasFeeSpent, exchangeRate)}</TextSpan>
          {"\n\n#"}
          {t("alfieMembersClub")}
        </Text>
      </ModalBody>

      <ModalFooter>
        <Button w="full" mr={3} onClick={onClose} bg={main4} color={text6}>
          {t("common:cancel")}
        </Button>
        <Button w="full" onClick={onTweetNow}>
          {t("common:tweetNow")}
        </Button>
      </ModalFooter>
    </>
  );
};

export default ModalTweet;
