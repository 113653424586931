import React from "react";
import Icon from "@chakra-ui/icon";
import { SvgIconProps } from "src/constants/type";

const SvgComponent: React.FC<SvgIconProps> = ({
  size,
  ...props
}: SvgIconProps) => {
  return (
    <Icon {...props} w={size} h={size} viewBox="0 0 16 16">
      <path d="M5.40274 1.03907L4.04233 3.7974L0.998578 4.24115C0.452744 4.32032 0.233994 4.99323 0.629828 5.37865L2.83191 7.52448L2.31108 10.5557C2.21733 11.1037 2.79441 11.5141 3.27774 11.2578L6.00066 9.82657L8.72358 11.2578C9.20691 11.512 9.78399 11.1037 9.69024 10.5557L9.16941 7.52448L11.3715 5.37865C11.7673 4.99323 11.5486 4.32032 11.0027 4.24115L7.95899 3.7974L6.59858 1.03907C6.35483 0.5474 5.64858 0.54115 5.40274 1.03907Z" 
      fill="#ECC94B"
      />  
    </Icon>
  );
};

const IconStart = React.memo(SvgComponent);
export default IconStart;
