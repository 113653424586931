const globalStyles = {
  global: {
    "*": {
      transition: "background 0.75s ,border 0.75s , color 0.1s ",
    },
    body: {},
    a: {
      color: "blue",
    },
  },
};

export default globalStyles;
