import React from "react";
import { Text, VStack } from "@chakra-ui/react";

import { TCSpec } from "src/constants/type";

interface TCItemProps {
  item: TCSpec;
  index: number;
}

const TCItem: React.FC<TCItemProps> = ({ item, index }) => {
  const { header, content } = item;
  return (
    <VStack spacing="6" alignItems="unset">
      <Text>
        <Text as="span" fontWeight="semibold">
          {`${index + 1}. ${header.title}. `}
        </Text>
        <Text as="span">{!!header.sub_title && `${header.sub_title}.`}</Text>
      </Text>
      {!!content && (
        <VStack spacing="6" alignItems="unset" pl="8">
          {content.map((item, idx) => {
            const { title_description, description } = item;
            return (
              <Text>
                {index + 1}.{idx + 1}.{" "}
                <Text as="span" fontWeight="semibold">
                  {title_description !== "" ? `${title_description}. ` : ""}
                </Text>
                {description.map((i, key) => {
                  return (
                    <Text mt="6" as={key === 0 ? "span" : undefined} key={key}>
                      {i}.
                    </Text>
                  );
                })}
              </Text>
            );
          })}
        </VStack>
      )}
    </VStack>
  );
};

export default TCItem;
