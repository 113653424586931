import React from "react";
import { Box } from "@chakra-ui/layout";
import { Flex } from "@chakra-ui/react";
import OtherLeft from "./OtherLeft";
import OtherRight from "./OtherRight";

interface SwapOtherProps {
  openAlertModal: (type: number, title: string, content: string) => void;
}
const SwapOther: React.FC<SwapOtherProps> = ({ openAlertModal }) => {
  return (
    <Flex
      flexDirection={{ base: "column", lg: "row" }}
      justifyContent="space-between"
      spacing="4"
      borderRadius="16px"
    >
      <Box w={{ base: "full", lg: "70%" }}>
        <OtherLeft openAlertModal={openAlertModal} />
      </Box>
      <Box w={{ base: "full", lg: "30%" }} px="10px">
        <OtherRight />
      </Box>
    </Flex>
  );
};

export default SwapOther;
