import React from "react";
import { chakra, HTMLChakraProps } from "@chakra-ui/react";
import { useAppDispatch } from "src/redux/hooks";
import { setIsMember, viewWallet } from "src/redux/slices/loginSlice";
import { PasswordField } from "./PasswordField";
import { isConnectZilpay } from "src/api/zilpayApi";
import { getHeyAlfieNfts } from "src/api/membershipApi";

const LoginForm = (props: HTMLChakraProps<"form">) => {
  const dispatch = useAppDispatch();
  const handleConnectWallet = async (
    formEvent: React.FormEvent<HTMLFormElement>
  ) => {
    try {
      formEvent.preventDefault();
      //@ts-ignore
      let zilAddress = formEvent.target.password.value;
      const isConnect = isConnectZilpay(zilAddress);
      if (isConnect) {
        const defaultAccount = window.zilPay.wallet.defaultAccount;
        if (
          !defaultAccount ||
          !defaultAccount.bech32 ||
          defaultAccount.bech32.length === 0
        ) {
          throw new Error("Connect failed: Connection refused.");
        }
        const heyAlfieNfts = await getHeyAlfieNfts(defaultAccount.base16);
        if (heyAlfieNfts.length > 0 && isConnectZilpay(defaultAccount.bech32)) {
          dispatch(setIsMember(true));
        } else {
          dispatch(setIsMember(false));
        }
      }

      dispatch(viewWallet(zilAddress));
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <chakra.form
      onSubmit={(formEvent) => handleConnectWallet(formEvent)}
      {...props}
    >
      <PasswordField />
    </chakra.form>
  );
};

export default LoginForm;
