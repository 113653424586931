import React from "react";
import { Avatar, BoxProps, Spinner } from "@chakra-ui/react";
import {
  Box,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  Image,
  ButtonGroup,
  Flex,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  useDisclosure,
} from "@chakra-ui/react";
import useRouter from "src/hooks/useRouter";
import { useAppColor } from "src/hooks";
import {
  IconArrowDown,
  IconArrowLeft,
  IconLock,
  IconMenu,
} from "src/assets/icons";
import { HiOutlineRefresh } from "react-icons/all";
import { Images } from "src/assets/images";
import { Sidebar } from ".";
import { IExchangeRate } from "src/constants/exchangeRates";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { changeCurrency, loginSelector } from "src/redux/slices/loginSlice";
import ScrollBox from "src/atoms/ScrollBox";
import ButtonTransak from "src/atoms/ButtonTransak";
import useModal from "src/hooks/useModal";
export interface HeaderProps extends BoxProps {
  title?: string;
  isGoBack?: boolean;
  onLock?(): void;
  onAddWallet?(): void;
  onRefresh?(): void;
}
interface FeatureProps {
  icon?: React.ReactElement<any, any>;
  aria_label: string;
  onClick?(): void;
}

const Header: React.FC<HeaderProps> = ({
  title,
  isGoBack,
  onLock,
  onAddWallet,
  onRefresh,
}: HeaderProps) => {
  const router = useRouter();
  const { text2, main1, main4, ThemeIcon, toggleColorMode } = useAppColor();
  const { exchangeRates, exchangeRate, isLoadingWallets, isMember } =
    useAppSelector(loginSelector);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { onOpenModalPremium } = useModal();
  const btnRef = React.useRef(null);

  const dispatch = useAppDispatch();

  const onChangeCurrency = (currency: IExchangeRate) => {
    try {
      if (!isMember) {
        onOpenModalPremium();
        return;
      }
      dispatch(changeCurrency(currency));
    } catch (error) {}
  };

  const features: FeatureProps[] = [
    {
      icon: <IconLock />,
      aria_label: "Lock",
      onClick: onLock,
    },
    {
      icon: <ThemeIcon />,
      aria_label: "Theme",
      onClick: toggleColorMode,
    },
    {
      icon: <HiOutlineRefresh />,
      aria_label: "Refresh",
      onClick: onRefresh,
    },
  ];

  return (
    <Box minH="88px" py={{ base: "4", md: "8" }}>
      <Box maxW="6xl" mx="auto">
        <Box display={{ base: "none", xl: "block" }}>
          <Stack
            spacing="5"
            direction="row"
            justify="space-between"
            align="center"
          >
            <Box display="flex" alignItems="center" fill={text2}>
              {isGoBack && (
                <Box
                  cursor="pointer"
                  display="flex"
                  w="24px"
                  h="24px"
                  aria-label="back"
                  justifyContent="flex-start"
                  alignItems="center"
                  onClick={router.history.goBack}
                >
                  <IconArrowLeft />
                </Box>
              )}
              <Text fontSize="3xl" lineHeight="9" fontWeight="bold">
                {title}
              </Text>
            </Box>
            <Stack
              direction={{ base: "column", md: "row" }}
              spacing="4"
              justify="flex-end"
              flex="1"
              w={{ base: "full", md: "auto" }}
            >
              <Stack display="inline-flex" direction="row" spacing="4">
                <Menu placement="bottom-start">
                  <MenuButton
                    pl="12px"
                    pr="16px"
                    px="8px"
                    rounded="24px"
                    variant="transparent"
                  >
                    <Box display="flex" alignItems="center" color={text2}>
                      <Avatar
                        bg={main1}
                        src={exchangeRate.image}
                        size="sm"
                        icon={<Box fontSize="xx-small">No flag</Box>}
                      />
                      <Text
                        mx="8px"
                        fontSize="md"
                        lineHeight="6"
                        fontWeight="semibold"
                        textTransform="uppercase"
                      >
                        {exchangeRate.key}
                      </Text>
                      <IconArrowDown size="24" />
                    </Box>
                  </MenuButton>
                  <MenuList
                    py="0"
                    rounded={16}
                    bg="transparent"
                    border="none"
                    shadow="none"
                  >
                    <ScrollBox
                      h="md"
                      w="165px"
                      borderRadius="16px"
                      shadow="ism"
                      bg={main1}
                    >
                      {exchangeRates.map((item, index) => {
                        const { key, image } = item;
                        if (!image) {
                          return null;
                        }
                        return (
                          <MenuItem
                            key={index}
                            onClick={() => onChangeCurrency(item)}
                            pl="32px"
                          >
                            <Avatar
                              bg={"transparent"}
                              src={image}
                              size="sm"
                              icon={
                                <Box fontSize="xx-small" color={text2}>
                                  No flag
                                </Box>
                              }
                            />
                            <Text
                              ml="8px"
                              fontSize="md"
                              lineHeight="6"
                              fontWeight="semibold"
                              textTransform="uppercase"
                            >
                              {key}
                            </Text>
                          </MenuItem>
                        );
                      })}
                    </ScrollBox>
                  </MenuList>
                </Menu>
                <ButtonGroup variant="outline">
                  {features.map((i, idx) => {
                    const isSpinning =
                      i.aria_label === "Refresh" && isLoadingWallets;
                    return (
                      <Box
                        cursor="pointer"
                        display="flex"
                        aria-label={i.aria_label}
                        key={idx}
                        w="40px"
                        h="40px"
                        justifyContent="center"
                        alignItems="center"
                        rounded="20px"
                        border="1px"
                        borderColor={main4}
                        _hover={{
                          bg: main1,
                        }}
                        onClick={() => {
                          !isSpinning && i.onClick && i.onClick();
                        }}
                      >
                        {isSpinning ? <Spinner size="xs" /> : i.icon}
                      </Box>
                    );
                  })}
                </ButtonGroup>
                <ButtonTransak isButton />
              </Stack>
            </Stack>
          </Stack>
        </Box>
        <Box display={{ base: "block", xl: "none" }}>
          <Flex justify="space-between" alignItems="center">
            <Box
              display="flex"
              alignItems="center"
              cursor="pointer"
              onClick={() => router.push("/")}
            >
              <Image w="32px" h="32px" rounded="8px" src={Images.logo} />
              <Text
                color={text2}
                ml="16px"
                fontWeight="semibold"
                fontSize="3xl"
              >
                heyalfie
              </Text>
            </Box>
            <Box
              ref={btnRef}
              w="40px"
              h="40px"
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              label="Log Out"
              cursor="pointer"
              onClick={onOpen}
            >
              <IconMenu size="24px" />
            </Box>
          </Flex>
        </Box>
      </Box>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
        isFullHeight={false}
      >
        <DrawerOverlay bg="blackAlpha.900" />
        <DrawerContent bg="transparent" shadow="none">
          <Box display="flex" border="none">
            <Box w="full" minH="100vh" onClick={onClose} />
            <Sidebar
              onAddWallet={onAddWallet}
              onLock={() => {
                onClose();
                onLock && onLock();
              }}
              onChangeTheme={() => {
                onClose();
                toggleColorMode();
              }}
            />
          </Box>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default Header;
