import React from "react";
import { Box } from "@chakra-ui/layout";
import { Button, Divider, Flex, Text } from "@chakra-ui/react";
import TokenLogo from "src/atoms/TokenLogo";
import { Images } from "src/assets/images";
import { useTranslation } from "react-i18next";

interface OtherRightProps {}

const OtherRight: React.FC<OtherRightProps> = () => {
  const { t } = useTranslation(["swap", "common"]);

  const handleLearnMore = React.useCallback(() => {}, []);

  return (
    <Box bg="blue.600" borderRadius="16px" color="white">
      <Box p="24px">
        <Text fontSize="2xl" lineHeight="8" fontWeight="semibold" mb="3">
          {t("common:exchange")}
        </Text>
        <Text fontSize="16px" lineHeight="24px" fontWeight="400">
          {t("description")}
        </Text>
        {/* Hide Learn More */}
        {false && (
          <Button
            w="116px"
            mt="24px"
            bg="white"
            color="black"
            onClick={handleLearnMore}
          >
            {t("common:learnMore")}
          </Button>
        )}
      </Box>
      <Divider my="10px" orientation="horizontal" />
      <Box p="24px">
        <Text
          fontSize="2xl"
          lineHeight="8"
          fontWeight="semibold"
          color="white"
          mb="3"
        >
          {t("poweredBy")}
        </Text>
        <Flex justifyContent="space-between">
          <Box display="flex">
            <TokenLogo src={Images.zilswap} mx="10px" />
            <Box>
              <Text
                fontSize="s"
                lineHeight="4"
                fontWeight="normal"
                color="white"
              >
                Zilswap
              </Text>
              <Text
                fontSize="s"
                lineHeight="4"
                fontWeight="normal"
                color="gray.300"
                mt="10px"
              >
                ZIL
              </Text>
            </Box>
          </Box>
          <Text justifyContent="flex-end">Beta</Text>
        </Flex>
      </Box>
    </Box>
  );
};

export default OtherRight;
