import React from "react";
import { Box, BoxProps } from "@chakra-ui/layout";
import {
  Pagination,
  PaginationPage,
  PaginationNext,
  PaginationPrevious,
  PaginationPageGroup,
  PaginationContainer,
  PaginationSeparator,
} from "@ajna/pagination";
import { useAppColor } from "src/hooks";

import { IconLeftArrow, IconRightArrow } from "src/assets/icons";

interface PaginationPanelProps extends BoxProps {
  onPageChange: (page: number) => void;
  currentPage: number;
  pagesCount: number;
  isDisabled?: boolean;
  pages: number[];
}

const PaginationPanel: React.FC<PaginationPanelProps> = ({
  pagesCount,
  currentPage,
  isDisabled,
  pages,
  onPageChange,
  ...rest
}) => {
  const { brand, text2, text5 } = useAppColor();

  return (
    <Box {...rest}>
      <Pagination
        pagesCount={pagesCount}
        currentPage={currentPage}
        isDisabled={isDisabled}
        onPageChange={onPageChange}
      >
        <PaginationContainer align="center" justify="center" w="full">
          <PaginationPrevious
            fill={text2}
            _hover={{
              bg: brand,
              opacity: 1,
            }}
            bg="transparent"
            mr="12px"
            w="40px"
            h="40px"
            borderRadius="0px"
          >
            <IconLeftArrow />
          </PaginationPrevious>
          <PaginationPageGroup
            isInline
            align="center"
            separator={
              <PaginationSeparator
                w="40px"
                h="40px"
                bg="transparent"
                fontSize="md"
                jumpSize={11}
              />
            }
          >
            {pages.map((page: number) => (
              <PaginationPage
                w="40px"
                h="40px"
                key={`pagination_page_${page}`}
                page={page}
                borderRadius="0px"
                bg="transparent"
                onClick={() => {}}
                fontSize="md"
                color={text5}
                _focus={{
                  borderBottom: "2px",
                  borderBottomColor: brand,
                }}
                _hover={{
                  opacity: 1,
                  color: "black",
                  bg: brand,
                  borderBottomColor: brand,
                }}
                _current={{
                  borderBottomWidth: "2px",
                  fontSize: "md",
                  borderBottomColor: brand,
                  bg: "transparent",
                }}
              />
            ))}
          </PaginationPageGroup>
          <PaginationNext
            fill={text2}
            _hover={{
              opacity: 1,
              bg: brand,
            }}
            bg="transparent"
            ml="12px"
            w="40px"
            h="40px"
            borderRadius="0px"
          >
            <IconRightArrow />
          </PaginationNext>
        </PaginationContainer>
      </Pagination>
    </Box>
  );
};

export default PaginationPanel;
