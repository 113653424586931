import React from "react";
//@ts-ignore
import transakSDK from "@transak/transak-sdk";
import { useToast, Icon, Button, Text, HStack, Link } from "@chakra-ui/react";
import { useAppSelector } from "src/redux/hooks";
import { loginSelector } from "src/redux/slices/loginSlice";
import { useTranslation } from "react-i18next";
import IconBuy from "src/assets/icons/IconBuy";
import { useAppColor } from "src/hooks";

interface ButtonTransakProps {
  isButton?: boolean;
}

const ButtonTransak: React.FC<ButtonTransakProps> = ({ isButton }) => {
  const { currentWallet } = useAppSelector(loginSelector);
  const { t } = useTranslation(["overview"]);

  const { brand } = useAppColor();

  const toast = useToast();

  let transak = new transakSDK({
    apiKey: "f6988319-0928-4da3-b111-6d6d1a21bcf0",
    environment: "PRODUCTION",
    defaultCryptoCurrency: "ZIL",
    walletAddress: currentWallet?.zilAddress || "",
    themeColor: "000000", // App theme color
    fiatCurrency: "", // INR/GBP
    email: "", // Your customer's email address
    redirectURL: "",
    hostURL: window.location.origin,
    widgetHeight: "550px",
    widgetWidth: "450px",
  });

  // To get all the events
  // transak.on(transak.ALL_EVENTS, (data: any) => {
  //   console.log(data);
  // });

  // This will trigger when the user marks payment is made.
  transak.on(transak.EVENTS.TRANSAK_ORDER_SUCCESSFUL, (orderData: any) => {
    toast({
      id: "transakSuccess",
      description: orderData,
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    transak.close();
  });

  return (
    <>
      {isButton ? (
        <Button
          variant="outline"
          leftIcon={<Icon as={IconBuy} boxSize="20px" />}
          onClick={() => transak.init()}
          _hover={{
            bg: brand,
            color: "black",
          }}
        >
          {t("buy")}
        </Button>
      ) : (
        <Link
          display="block"
          py={2}
          px={3}
          borderRadius="md"
          noOfLines={1}
          isTruncated
          transition="all 0.3s"
          fontWeight="medium"
          lineHeight="1.5rem"
          color="white"
          _hover={{
            bg: "gray.600",
          }}
          _activeLink={{
            bg: "gray.700",
          }}
          onClick={() => transak.init()}
        >
          <HStack spacing="2">
            <Icon as={IconBuy} boxSize="20px" />
            <Text mr="4" as="span">
              {t("buy")}
            </Text>
          </HStack>
        </Link>
      )}
    </>
  );
};

export default ButtonTransak;
