import React, { useEffect, useState } from "react";
import groupBy from "lodash/groupBy";
import BigNumber from "bignumber.js";
import dayjs from "dayjs";
import { fromBech32Address } from "@zilliqa-js/zilliqa";
import { ModalHeader, ModalCloseButton, ModalBody } from "@chakra-ui/modal";
import { useAppColor } from "src/hooks";
import { Table, Thead, Tr, Th, Tbody, Td } from "@chakra-ui/table";
import { Box, Button, Checkbox, Flex, Text, useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "src/redux/hooks";
import { overviewSelector } from "src/redux/slices/overviewSlice";
import {
  ClaimableRewards,
  claimMulti,
  ClaimMultiOpts,
  Distribution,
  DistributionWithStatus,
  Distributor,
} from "src/api/zilswapApi";
import { TokenLogo } from "src/atoms";
import { formatToken, formatUsdt } from "src/lib/formatCurrency";
import { loginSelector } from "src/redux/slices/loginSlice";
import { isConnectZilpay } from "src/api/zilpayApi";
import { Network } from "zilswap-sdk/lib/constants";
import { Zilswap } from "zilswap-sdk";
import { IPoolInfo, IToken } from "src/constants/interfaces";
import BoxTransaction from "src/molecules/BoxTransaction";
import { handleGoLink } from "src/utils";
interface ModalClaimZilSwapProps {
  onClose?: () => void;
  claimZilswapItems?: any;
  lpPool: IPoolInfo;
}

const ModalClaimZilSwap: React.FC<ModalClaimZilSwapProps> = ({
  onClose,
  claimZilswapItems,
  lpPool,
}) => {
  const toast = useToast();

  const { text2, text3, text6 } = useAppColor();
  const { t } = useTranslation(["overview", "common"]);

  const { tokens, tokenRates, zilPriceInUsd } =
    useAppSelector(overviewSelector);

  const { distributions, distributors, link, symbol } = lpPool;

  const { exchangeRate, currentWallet } = useAppSelector(loginSelector);

  const [rewardsByDate, setRewardsByDate] = useState(claimZilswapItems);

  const [checkedIds, setCheckedIds] = useState<string[]>([]);
  const [checkedAllIds, setCheckedAllIds] = useState<string[]>([]);

  useEffect(() => {
    if (!distributions || !distributors || !tokens) {
      return;
    }

    const claimableRewards: ClaimableRewards[] = distributions
      .filter((distribution) => distribution.readyToClaim)
      .flatMap((d: DistributionWithStatus) => {
        const rewardDistributor: Distributor | undefined = distributors.find(
          (distributor) => {
            return distributor.distributor_address_hex === d.info.distrAddr;
          }
        );

        if (!rewardDistributor) {
          return [];
        }

        const rewardToken: IToken | undefined = tokens.find((token) => {
          return (
            fromBech32Address(token.address_bech32).toLowerCase() ===
            rewardDistributor.reward_token_address_hex.toLowerCase()
          );
        });

        if (!rewardToken) {
          return [];
        }

        return [{ rewardToken, rewardDistributor, ...d }];
      })
      .filter((r) => !!r);

    const rewardsByDate = groupBy(claimableRewards, (reward) => {
      const {
        distribution_start_time,
        epoch_period,
        initial_epoch_number,
        retroactive_distribution_cutoff_time,
      } = reward.rewardDistributor.emission_info;

      return dayjs
        .unix(
          distribution_start_time +
            epoch_period *
              (reward.info.epochNumber -
                initial_epoch_number +
                (!!retroactive_distribution_cutoff_time ? 0 : 1))
        )
        .format("DD MMM YY");
    });
    setRewardsByDate(rewardsByDate);
  }, [distributions, distributors, tokens]);

  useEffect(() => {
    setCheckedAllIds(distributions.map((distribution) => distribution.info.id));
  }, [distributions]);

  const checkAll = React.useCallback(() => {
    setCheckedIds(checkedAllIds);
  }, [checkedAllIds]);

  const uncheckAll = React.useCallback(() => {
    setCheckedIds([]);
  }, []);

  const onClaimZilswapSingle = async () => {
    if (!currentWallet || !isConnectZilpay(currentWallet.zilAddress)) {
      toast({
        id: "sent",
        description: t("pleaseConnectWallet"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const zilswap = new Zilswap(Network.MainNet, (window as any).zilPay);
    const selectedDistributionsWithStatus: DistributionWithStatus[] =
      distributions.filter((distribution) => {
        if (checkedIds.find((id) => id === distribution.info.id)) {
          return true;
        }
        return false;
      });
    const selectedDistributions: Distribution[] =
      selectedDistributionsWithStatus.map((item) => {
        const proof = item.info.proof.slice(1, item.info.proof.length - 1);
        return {
          id: item.info.id,
          distrAddr: item.info.distrAddr,
          epochNumber: item.info.epochNumber,
          amount: item.info.amount,
          proof: proof,
        };
      });
    const claimMultiOpts: ClaimMultiOpts = {
      distributions: selectedDistributions,
    };
    if (currentWallet) {
      const tranID = await claimMulti(zilswap, currentWallet, claimMultiOpts);
      if (tranID) {
        toast({
          render: () => <BoxTransaction TranID={`${tranID}`} />,
        });
      }
    }
  };

  const onClaimZilswap = async () => {
    if (!currentWallet || !isConnectZilpay(currentWallet.zilAddress)) {
      toast({
        id: "connect",
        description: t("pleaseConnectWallet"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (checkedIds.length === 0) {
      toast({
        id: "select",
        description: t("selectOne"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    const zilswap = new Zilswap(Network.MainNet, (window as any).zilPay);
    const selectedDistributionsWithStatus: DistributionWithStatus[] =
      distributions.filter((distribution) => {
        if (checkedIds.find((id) => id === distribution.info.id)) {
          return true;
        }
        return false;
      });
    const selectedDistributions: Distribution[] =
      selectedDistributionsWithStatus.map((item) => {
        const proof = item.info.proof.slice(1, item.info.proof.length - 1);
        return {
          id: item.info.id,
          distrAddr: item.info.distrAddr,
          epochNumber: item.info.epochNumber,
          amount: item.info.amount,
          proof: proof,
        };
      });
    const claimMultiOpts: ClaimMultiOpts = {
      distributions: selectedDistributions,
    };
    if (!selectedDistributions.length) {
      toast({
        id: "sent",
        description: t("pleaseConnectWallet"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    const tranID = await claimMulti(zilswap, currentWallet, claimMultiOpts);
    if (tranID) {
      toast({
        render: () => <BoxTransaction TranID={`${tranID}`} />,
      });
    }
  };

  const onClaimXcad = () => handleGoLink(link);

  const renderRewardRow = (reward: any, date: any, index: number) => {
    let checked = checkedIds.find((id) => id === reward.info.id) ? true : false;

    const amount = new BigNumber(reward.info.amount)
      .div(Math.pow(10, reward.rewardToken.decimals))
      .toNumber();

    const tokenRate = tokenRates.find(
      (tokenRate) =>
        tokenRate.token.address_bech32 === reward.rewardToken.address_bech32
    );

    return (
      <Tr key={`${date}-${index}`}>
        <Td>
          <Checkbox
            isChecked={checked}
            onChange={() => {
              if (checked) {
                // remove
                setCheckedIds(checkedIds.filter((id) => id !== reward.info.id));
              } else {
                // add
                setCheckedIds([...checkedIds, reward.info.id]);
              }
            }}
          />
        </Td>
        <Td>
          <Flex alignItems="center">
            <TokenLogo src={reward.rewardToken.icon} size="sm" mr="2" />
            <Box>
              <Text color={text2} fontSize="sm" fontWeight="semibold">
                {reward.rewardToken.name}
              </Text>
              <Text color={text6} fontSize="xs">
                {dayjs(date).format("MMM DD")}
              </Text>
            </Box>
          </Flex>
        </Td>
        <Td isNumeric>
          <Flex justifyContent="flex-end" alignItems="center">
            <Text fontSize="sm" fontWeight="semibold" mr="1">
              {formatToken(amount, reward.rewardToken.symbol)}
            </Text>
            <TokenLogo w="4" h="4" src={reward.rewardToken.icon} />
          </Flex>
          <Text fontSize="xs" color={text6}>
            ~
            {formatUsdt(
              tokenRate ? amount * zilPriceInUsd * tokenRate.rate : 0,
              exchangeRate
            )}
          </Text>
        </Td>
        <Td isNumeric display={{ base: "none", md: "block" }}>
          {false && (
            <Button
              children={t("common:claim")}
              onClick={symbol === "dXCAD" ? onClaimXcad : onClaimZilswapSingle}
            />
          )}
        </Td>
      </Tr>
    );
  };

  return (
    <>
      <ModalHeader textTransform="capitalize" color={text3}>
        {t("common:claim")}
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Box
          display={{ base: "flex", md: "none" }}
          justifyContent="flex-end"
          p="6"
          pt="0"
        >
          <Button
            children={t("claimAll")}
            onClick={symbol === "dXCAD" ? onClaimXcad : onClaimZilswap}
          />
        </Box>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>
                <Checkbox
                  isChecked={checkedIds.length === checkedAllIds.length}
                  onChange={() => {
                    if (checkedIds.length === checkedAllIds.length) {
                      uncheckAll();
                    } else {
                      checkAll();
                    }
                  }}
                />
              </Th>
              <Th>{t("Tokens")}</Th>
              <Th isNumeric>{t("unclaimedReward")}</Th>
              <Th isNumeric display={{ base: "none", md: "block" }}>
                <Button
                  children={t("claimAll")}
                  onClick={symbol === "dXCAD" ? onClaimXcad : onClaimZilswap}
                />
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {rewardsByDate &&
              Object.keys(rewardsByDate)
                .sort((a, b) => (a === b ? 0 : dayjs(a) > dayjs(b) ? -1 : 1))
                .map((date) => {
                  return rewardsByDate[date].map(
                    (reward: any, index: number) => {
                      return renderRewardRow(reward, date, index);
                    }
                  );
                })}
          </Tbody>
        </Table>
      </ModalBody>
    </>
  );
};

export default ModalClaimZilSwap;
