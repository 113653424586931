import React from "react";
import { AspectRatio, Box, BoxProps, Text } from "@chakra-ui/layout";
import { Image } from "@chakra-ui/image";
import { useAppColor, useRouter } from "src/hooks";
import { INft, ISmartContract } from "src/constants/interfaces";
import {
  fetchNftFromDatabase,
  fetchNftFromSmartContract,
} from "src/api/nftApi";
import { ButtonPin } from "src/pages/NFTs/components";
import axios from "axios";
import { useAppSelector } from "src/redux/hooks";
import { overviewSelector } from "src/redux/slices/overviewSlice";

interface NFTItemProps extends BoxProps {
  smartContract?: ISmartContract;
  initNft?: INft;
  tokenUri?: string;
  isPinned?: boolean;
}

const NFTItem: React.FC<NFTItemProps> = ({
  smartContract,
  tokenUri,
  initNft,
  isPinned,
  ...rest
}) => {
  const { text2, main1, text6 } = useAppColor();
  const [nft, setNft] = React.useState<INft | undefined>(initNft);
  const router = useRouter();

  const { collections } = useAppSelector(overviewSelector);

  const [updatingNft] = React.useState<INft>({
    description: "Updating",
    image:
      "https://user-images.githubusercontent.com/47516405/153706762-3539bdf5-597b-4d3b-8e73-3d237a887c66.png",
    title: "Alfie Members Club",
  });

  React.useEffect(() => {
    (async () => {
      const cancelToken = axios.CancelToken.source();
      if (initNft) {
        return;
      }
      if (smartContract) {
        const collection = collections.find(
          (item) => item.contractAddress === smartContract.contractAddress
        );
        let nft: INft = updatingNft;
        try {
          nft = await fetchNftFromDatabase(
            smartContract,
            cancelToken.token,
            collection
          );
        } catch (error) {}
        if (!nft.image) {
          nft = await fetchNftFromSmartContract(
            smartContract,
            cancelToken.token,
            collection
          );
        }
        setNft(nft);
      } else if (tokenUri === "Updating") {
        setNft(updatingNft);
      }

      return () => cancelToken.cancel();
    })();
  }, [smartContract, tokenUri, initNft, collections, updatingNft]);

  if (!nft) {
    return <></>;
  }

  const { description, image, title, video } = nft;

  const onClick = () => {
    router.push({
      pathname: "/nft-details",
      state: { ...nft, ...smartContract },
    });
  };
  return video !== "https://ipfs.io/ipfs/undefined" ? (
    <Box
      bg={main1}
      cursor="pointer"
      overflow="hidden"
      borderRadius="8px"
      p="12px"
      shadow="md"
      {...rest}
      onClick={onClick}
    >
      <Box position="relative">
        {isPinned && (
          <Box position="absolute" top="-1" left="-1">
            <ButtonPin isPinned={true} />
          </Box>
        )}
        {video && video !== "" ? (
          <AspectRatio ratio={1}>
            <video controls autoPlay loop>
              <source src={video} type="video/mp4" />
            </video>
          </AspectRatio>
        ) : (
          <Image w="full" h="auto" src={image} borderRadius="6px" />
        )}
      </Box>
      <Text
        fontSize="md"
        lineHeight="6"
        fontWeight="semibold"
        mt="12px"
        color={text2}
      >
        {title}
      </Text>
      <Text
        fontSize="xs"
        lineHeight="4"
        fontWeight="normal"
        color={text6}
        textOverflow="ellipsis"
        noOfLines={2}
        mt="12px"
      >
        {description}
      </Text>
    </Box>
  ) : (
    <></>
  );
};

export default NFTItem;
