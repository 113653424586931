import { ReactElement } from "react";
import { LinkProps, SwitchProps } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/icon";
import { IconBaseProps, IconType } from "react-icons";
import { IWallet } from "src/redux/slices/loginSlice";
export interface SvgIconProps extends IconProps {
  size?: string;
}
export interface NavLinkProps extends LinkProps {
  isActive?: boolean;
  label: string;
  pathname?: string;
  icon: IconType | React.NamedExoticComponent<IconBaseProps>;
  onClick?(): void;
}
export interface WalletProps {
  item: IWallet;
  onClick?(): void;
}
export interface SettingProps {
  title?: string;
  icon?: ReactElement<any, any>;
  last?: boolean;
  isChecked?: SwitchProps["isChecked"];
  onChange?(): void;
}
export interface PricingProps {
  button?: React.ReactElement;
  data: {
    name: string;
    description: string;
    price: number;
    amountSaved?: number;
    duration: "monthly" | "yearly";
    benefits: string[];
  };
  bg: string;
}

export interface HistorySpec {
  events?: any;
  tag?: string;
  hash?: string;
  blockHeight?: number;
  from: string;
  to: string;
  value: string;
  fee: string;
  timestamp: number;
  signature?: string;
  direction?: Directions_Enum;
  nonce?: number;
  receiptSuccess?: boolean;
  data?: null;
}

export interface lHistory {
  title?: string;
  content?: HistorySpec[];
}
export interface AttributeSpec {
  id?: string;
  traitType?: string;
  value?: string | number;
  rarity?: number;
}

export interface HistoryStatistic {
  first_transaction_created_at: string;
  most_call_contract_address: string;
  most_call_contract_times: number;
  total_fee: number;
  total_txs: number;
}
export interface TCSpec {
  header: {
    title: string;
    sub_title?: string;
  };
  content?: ContentSpec[];
}
interface ContentSpec {
  title_description?: string;
  description: string[];
}

export enum Format_Types {
  Limit,
  Default,
  Secure,
  TokenName,
}

export enum Directions_Enum {
  In = "in",
  Out = "out",
}

export enum Tags_Enum {
  WithdrawStakeAmt = "WithdrawStakeAmt", //swap
  SwapExactZILForTokens = "SwapExactZILForTokens", //swap
  SwapExactTokensForZIL = "SwapExactTokensForZIL", //swap
  SwapTokensForExactZIL = "SwapTokensForExactZIL", //swap
  SwapZILForExactTokens = "SwapZILForExactTokens", //swap
  SwapExactTokensForTokens = "SwapExactTokensForTokens", //swap
  SwapTokensForExactTokens = "SwapTokensForExactTokens", //swap
  SwapExactTokensForExactTokens = "SwapExactTokensForExactTokens", //swap
  DirectSwapExactTokens0ToTokens1 = "DirectSwapExactTokens0ToTokens1", //swap
  Transfer = "Transfer", //swap
  DelegateStake = "DelegateStake", //stake
  WithdrawStakeRewards = "WithdrawStakeRewards", //reward
  CompleteWithdrawal = "CompleteWithdrawal", //reward
  Completewithdrawal = "Complete withdrawal",
  Claim = "Claim", //reward
  ClaimMulti = "ClaimMulti", //reward
  ClaimAirdrop = "ClaimAirdrop",
  Contribute = "Contribute",
  IncreaseAllowance = "IncreaseAllowance", //
  RemoveLiquidity = "RemoveLiquidity", //remove
  RemoveTokenLiquidity = "RemoveTokenLiquidity",
  AddTokenLiquidity = "AddTokenLiquidity",
  removeStake = "removeStake",
  withdrawPending = "withdrawPending", //remove
  AddLiquidity = "AddLiquidity", //remove
  Mint = "Mint", // mint
  Deposit = "Deposit",
  deposit = "deposit",
  Empty = "",
  withdraw = "withdraw",
  Withdraw = "Withdraw",
  withdrawStake = "withdrawStake",
  WithdrawRewards = "WithdrawRewards",
  claimTransferReward = "ClaimTransferRewards",
  ExistingZRC2ToBridgedZRC2 = "ExistingZRC2ToBridgedZRC2"

}
