enum TransactionResultType {
  inprogress = "inprogress",
  success = "success",
  failed = "failed",
  confirmed = "confirmed",
  rejected = "rejected",
  expired = "expired",
  sent = "sent",
}

enum ProfileModalEnum {
  ClaimZil = "claimZil",
  ClaimStaking = "claimStaking",
  ClaimSco = "claimSco",
  ClaimPort = "claimPort",
  ClaimZilSwap = "claimZilSwap",
  AddStaking = "addStaking",
  AddLp = "addLp",
}

enum LoginMessageEnum {
  UpdateNameFailed = "updateNameFailed",
  UpdateNameSuccess = "updateNameSuccess",
  UpdateAvatarFailed = "updateAvatarFailed",
  UpdateAvatarSuccess = "updateAvatarSuccess",
  UpdateBannerFailed = "updateBannerFailed",
  UpdateBannerSuccess = "updateBannerSuccess",
}

enum CategoriesEnum {
  Wallet = "wallet",
  Staking = "staking",
  Liquidity = "liquidity",
  UnclaimedReward = "unclaimedReward",
}

export {
  TransactionResultType,
  ProfileModalEnum,
  LoginMessageEnum,
  CategoriesEnum,
};
