import React from "react";
import { Box, Center, Flex } from "@chakra-ui/layout";
import { Thead, AvatarGroup, Button, Text, useBoolean } from "@chakra-ui/react";
import BigNumber from "bignumber.js";
import { isEmpty } from "lodash";
import { IconFilledArrow } from "src/assets/icons";
import { TokenLogo, ButtonUnlock } from "src/atoms";
import { ITokenPool } from "src/constants/interfaces";
import { formatToken } from "src/lib/formatCurrency";
import { useAppSelector } from "src/redux/hooks";
import { overviewSelector } from "src/redux/slices/overviewSlice";
import { handleGoLink, sortLiquidityPool } from "src/utils";
import { useAppColor } from "src/hooks";
import { loginSelector } from "src/redux/slices/loginSlice";
import { useTranslation } from "react-i18next";
import { MotionBox } from "src/molecules";
import LPCarbSwapDetails from "./LPCarbSwapDetails";
import { Images } from "src/assets/images";

interface LPCarbSwapMbProps {
  onClaim: (lpPool: any) => void;
}

const LPCarbSwapMb: React.FC<LPCarbSwapMbProps> = ({ onClaim }) => {
  const { t } = useTranslation(["overview", "common"]);
  const { tokens, carbSwapPools, carbSwapClaimable } =
    useAppSelector(overviewSelector);
  const { isHidden } = useAppSelector(loginSelector);

  const [show, setShow] = useBoolean(false);
  const [showAll, setShowAll] = useBoolean(false);

  const { text1, text2, text3, text5, text6, main3 } = useAppColor();

  const [totalReward, setTotalReward] = React.useState<number>(0);

  const availableToken = (tokenPool: ITokenPool) => {
    const { userContribution, totalContribution } = tokenPool;
    return (
      !userContribution ||
      userContribution.toNumber() <= 0 ||
      totalContribution.toNumber() <= 0
    );
  };

  React.useEffect(() => {
    let total: number = 0;
    const graph = tokens.find((token) => token.symbol === "GRPH");
    if (graph) {
      const graphDecimal = graph.decimals;
      carbSwapClaimable &&
        carbSwapClaimable.forEach((claimable) => {
          total += new BigNumber(claimable.amount)
            .div(Math.pow(10, graphDecimal))
            .toNumber();
        });
    }
    setTotalReward(total);
  }, [carbSwapClaimable, tokens]);

  const carbSwap = {
    name: "CarbSwap",
    link: "https://carbswap.app/#/pool",
    logo: Images.carbon,
    tokenPool: sortLiquidityPool(carbSwapPools),
    distributors: [],
    distributions: [],
    symbol: "CARB",
    subName: "carbswap.app",
  };

  const { name, logo, link, tokenPool, symbol, subName } = carbSwap;

  if (isEmpty(tokenPool)) {
    return <Thead />;
  }

  let tokenList = [] as string[];

  tokenPool.forEach((tokenPool) => {
    const { token } = tokenPool;
    if (availableToken(tokenPool)) {
      return;
    }
    tokenList.push(token.icon);
  });

  return (
    <React.Fragment>
      <Box>
        <Flex alignItems="center" px="4">
          <TokenLogo src={logo} size="sm" mr="2" />
          <Flex
            alignItems="center"
            flex="1"
            justifyContent="space-between"
            py="3"
            borderBottomWidth="1px"
            borderBottomColor={main3}
          >
            <Box>
              <Text color={text2} fontSize="sm" fontWeight="semibold">
                {name}
              </Text>
              <Text
                color={text6}
                fontSize="xs"
                cursor="pointer"
                onClick={() => handleGoLink(link)}
              >
                {subName}
              </Text>
            </Box>
            <Flex alignItems="center">
              {isHidden ? (
                <ButtonUnlock mr="4" />
              ) : (
                <Button
                  variant="outline"
                  children={t("Claim")}
                  mr="4"
                  onClick={() => onClaim(carbSwap)}
                />
              )}
              <IconFilledArrow
                fill={text1}
                cursor="pointer"
                onClick={() => {
                  setShowAll.off();
                  setShow.toggle();
                }}
                _hover={{
                  fill: text3,
                }}
                size="6"
                style={{
                  transform: `rotate(${show ? 0 : 180}deg)`,
                }}
              />
            </Flex>
          </Flex>
        </Flex>
      </Box>
      <Box px="4" pt="4">
        <AvatarGroup
          size="xs"
          spacing={2}
          max={3}
          fontSize="xx-small"
          justifyContent="end"
        >
          {tokenList.map((token, index) => (
            <TokenLogo src={token} key={index} />
          ))}
        </AvatarGroup>
      </Box>
      <Flex alignItems="flex-end" flexDirection="column" p="4" pr="14">
        <Flex
          alignItems="center"
          justifyContent="flex-end"
          color={text1}
          fontSize="sm"
          fontWeight="semibold"
        >
          <Text>{formatToken(totalReward, "GRPH", isHidden)}</Text>
          <TokenLogo size="2xs" src={Images.graph} ml="1" />
        </Flex>
        <Text color={text3} fontSize="xs">
          {t("rewardDistributed")}
        </Text>
      </Flex>
      {show && (
        <MotionBox
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            transition: { duration: 0.5 },
          }}
        >
          {tokenPool.map((item: ITokenPool, i) => {
            if (availableToken(item) && !showAll) {
              return <Box key={i} />;
            }
            const poolSymbol = tokens.find((token) => token.symbol === symbol);

            return (
              <LPCarbSwapDetails
                key={i}
                item={item}
                poolSymbol={poolSymbol}
                poolItem={carbSwap}
              />
            );
          })}
          <Box>
            <Box p="0" position="relative" colSpan={4} bg="red.600">
              <Center>
                <IconFilledArrow
                  position="absolute"
                  _groupHover={{
                    fill: text3,
                  }}
                  onClick={() => {
                    setShowAll.toggle();
                  }}
                  cursor="pointer"
                  size="6"
                  fill={text5}
                  style={{
                    transform: `rotate(${showAll ? 0 : 180}deg)`,
                  }}
                />
              </Center>
            </Box>
          </Box>
        </MotionBox>
      )}
    </React.Fragment>
  );
};

export default LPCarbSwapMb;
