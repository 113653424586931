import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { INft } from "src/constants/interfaces";
import { RootState } from "../rootReducer";
import { AppThunk } from "../store";
import { get, set, remove } from "local-storage";

const MAX_PIN_NFTS = 100;

export interface NftError {
  message: string;
}

export interface NftState {
  error?: NftError;
  pinNfts: INft[];
}

export const initialState: NftState = {
  pinNfts: [],
};

const savePinnedNftsToLocalStorage = (pinNfts: INft[]) => {
  set("pinNfts", pinNfts);
};

const loadPinNftsFromLocalStorage = (): INft[] => {
  return get("pinNfts");
};

const removePinNftsFromLocalStorage = () => {
  remove("pinNfts");
};

export const nftSlice = createSlice({
  name: "nft",
  initialState,
  reducers: {
    updatePinNfts: (state, { payload }: PayloadAction<INft[]>) => {
      state.pinNfts = payload;
    },
  },
});

export const { updatePinNfts } = nftSlice.actions;

export const loadPinnedNfts = (): AppThunk => async (dispatch, getState) => {
  try {
    const pinNfts = loadPinNftsFromLocalStorage();
    if (pinNfts) {
      dispatch(updatePinNfts(pinNfts));
      savePinnedNftsToLocalStorage(pinNfts);
    }
  } catch (error) {}
};

export const togglePinNft =
  (nft: INft, errorCallback?: () => void): AppThunk =>
  async (dispatch, getState) => {
    const { pinNfts } = nftSelector(getState());
    try {
      const index = pinNfts.findIndex((pinNft) => pinNft.image === nft.image);
      let temp = [...pinNfts];
      if (index === -1) {
        if (pinNfts.length >= MAX_PIN_NFTS) {
          errorCallback && errorCallback();
          return;
        }
        temp.push(nft);
      } else {
        temp.splice(index, 1);
      }
      dispatch(updatePinNfts([...temp]));
    } catch (error) {}
  };

export const removePinNfts = (): AppThunk => async (dispatch) => {
  try {
    dispatch(updatePinNfts([]));
    removePinNftsFromLocalStorage();
  } catch (error) {}
};

export const nftSelector = (state: RootState) => state.nft;

export default nftSlice.reducer;
