import React from "react";
import {
  Button,
  Text,
  HStack,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  ModalFooter,
  SimpleGrid,
} from "@chakra-ui/react";
import { useAppColor } from "src/hooks";
import { NFTImage, ScrollBox } from "src/atoms";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { overviewSelector } from "src/redux/slices/overviewSlice";
import {
  loginSelector,
  setSavedWalletAvatar,
} from "src/redux/slices/loginSlice";

interface ModalEditAvatarProps {
  onClose: () => void;
}

const ModalEditAvatar: React.FC<ModalEditAvatarProps> = ({ onClose }) => {
  const { currentWallet: _currentWallet, savedWallets } =
    useAppSelector(loginSelector);

  const currentWallet = savedWallets.find(
    (wallet) => _currentWallet?.zilAddress === wallet.zilAddress
  );

  const [selected, setSelected] = React.useState<string>(
    currentWallet?.avatar || ""
  );
  const dispatch = useAppDispatch();
  const { collections } = useAppSelector(overviewSelector);

  const { brand, text4, main6, main2, main4, text6 } = useAppColor();

  const onSave = React.useCallback(() => {
    try {
      if (selected !== currentWallet?.avatar) {
        dispatch(setSavedWalletAvatar(selected));
      }
      onClose();
    } catch (error) {}
  }, [dispatch, onClose, selected, currentWallet]);

  return (
    <ModalContent>
      <ModalCloseButton />
      <ModalHeader />
      <ModalBody>
        <Text color={text4} fontSize="18px" lineHeight="28px" fontWeight="700">
          Choose Avatar
        </Text>
        <Text color={main6} fontSize="14px" pt="16px" pb="24px">
          Choose from your avatar from your NFT collection.
        </Text>
        <ScrollBox maxH="lg">
          <SimpleGrid columns={8} spacing={2}>
            {collections &&
              collections.map((collection, colIndex) => (
                <React.Fragment key={colIndex}>
                  {collection.smartContracts.map((smartContract, smIndex) => (
                    <NFTImage
                      key={smIndex}
                      smartContract={smartContract}
                      onItemClick={setSelected}
                      selected={selected}
                    />
                  ))}
                </React.Fragment>
              ))}
          </SimpleGrid>
        </ScrollBox>
      </ModalBody>
      <ModalFooter justifyContent="end">
        <HStack>
          <Button
            fontSize="sm"
            color={text6}
            fontWeight="semibold"
            bg={main4}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            fontSize="sm"
            color={main2}
            fontWeight="semibold"
            bg={brand}
            onClick={onSave}
          >
            Save
          </Button>
        </HStack>
      </ModalFooter>
    </ModalContent>
  );
};

export default ModalEditAvatar;
