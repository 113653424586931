import React from "react";
import { useBoolean, Box, Flex, Text } from "@chakra-ui/react";
import { useAppSelector } from "src/redux/hooks";
import { loginSelector } from "src/redux/slices/loginSlice";
import { overviewSelector } from "src/redux/slices/overviewSlice";
import { fetchReward } from "src/api/stakingRewardApi";
import { IStakingInfo, IUnstaking } from "src/constants/interfaces";
import StakingHeaderMb from "./StakingHeaderMb";
import StakingItemMb from "./StakingItemMb";
import { IModalClaimStakingItem } from "./ModalClaimStaking";
import { Swiper, SwiperSlide } from "swiper/react";
import { useTranslation } from "react-i18next";
import { useAppColor } from "src/hooks";
import { Images } from "src/assets/images";
import { TokenLogo } from "src/atoms";
import { formatToken } from "src/lib/formatCurrency";

interface StakingZilProps {
  stakeToken: IStakingInfo;
  claimStaking?: (stakeToken: IModalClaimStakingItem[]) => void;
  active?: boolean;
}

const StakingZilMb: React.FC<StakingZilProps> = ({
  stakeToken,
  claimStaking,
  active,
}) => {
  const { t } = useTranslation(["overview", "common"]);
  const [show, setShow] = useBoolean(false);
  const { text2, text6, main4 } = useAppColor();

  const { tokens } = useAppSelector(overviewSelector);

  const { walletStakingList } = useAppSelector(overviewSelector);

  const { currentWallet, isHidden } = useAppSelector(loginSelector);

  const { stakeList, unstakingPending, symbol } = stakeToken;

  const [claimStakingItems, setClaimStakingItems] = React.useState<
    IModalClaimStakingItem[] | []
  >([]);
  const [unstakingList, setUnstakingList] = React.useState<IUnstaking[]>([]);

  React.useEffect(() => {
    const syncStakingReward = async () => {
      if (!currentWallet || !walletStakingList) {
        return;
      }

      let claimStakingItems = Array<IModalClaimStakingItem>();

      for (let i = 0; i < walletStakingList.length; i++) {
        const stakingWallet = walletStakingList[i];
        const { address, stakingBalance } = stakingWallet;
        if (stakingBalance && stakingBalance > 0) {
          const rewardResult = await fetchReward(
            address,
            currentWallet.zilAddress
          );
          const rewardNumber = parseFloat(rewardResult.toString());
          if (!isNaN(rewardNumber) && rewardNumber > 0) {
            claimStakingItems.push({
              stakingWallet: stakingWallet,
              reward1: rewardNumber * Math.pow(10, -12),
              reward2: rewardNumber * Math.pow(10, -12) * Math.pow(10, -3),
            });
          }
        }
      }
      setClaimStakingItems(claimStakingItems);
    };
    syncStakingReward();
  }, [walletStakingList, currentWallet]);

  React.useEffect(() => {
    let unstakingListTmp = [] as IUnstaking[];
    let unstaking = unstakingPending || [];
    unstaking.forEach((item) => {
      const stakingSymbol = item.unstakingSymbol || symbol;
      const curIndex = unstakingListTmp.findIndex(
        (temp) => temp.unstakingSymbol === stakingSymbol
      );
      if (curIndex !== -1) {
        if (item.unstakingSymbol) {
          unstakingListTmp[curIndex].unstaking += item.unstaking;
        }
      } else {
        unstakingListTmp.push({
          unstakingSymbol: stakingSymbol,
          unstaking: item.unstaking,
        });
      }
    });
    setUnstakingList(
      unstakingListTmp.filter((staking) => staking.unstaking > 0)
    );
  }, [symbol, unstakingPending]);

  const claimZil = React.useCallback(() => {
    claimStaking && claimStaking(claimStakingItems);
  }, [claimStaking, claimStakingItems]);

  const toggleShow = () => {
    setShow.toggle();
  };

  return (
    <React.Fragment>
      <StakingHeaderMb
        stakeToken={stakeToken}
        claimToken={claimZil}
        toggleShow={toggleShow}
      />

      {!!show && (
        <Box>
          <Box display={{ base: "none", lg: "block" }}>
            {stakeList &&
              stakeList.map((token, index) => {
                const { name, stakingBalance } = token;
                let reward = 0;
                let rate = 1;
                if (
                  (!stakingBalance && !!active) ||
                  (stakingBalance && stakingBalance <= 0 && !!active)
                ) {
                  return <Box key={index} />;
                }

                let claimStakingItem = claimStakingItems.find(
                  (claimStakingItem) =>
                    claimStakingItem.stakingWallet.name === name
                );

                reward = claimStakingItem ? claimStakingItem.reward1 : 0;

                return (
                  <StakingItemMb
                    key={index}
                    {...stakeToken}
                    {...token}
                    reward={reward}
                    rate={rate}
                  />
                );
              })}
          </Box>
          <Box py="20px" px="14" bg={main4}>
            {unstakingList.map((item, index) => {
              const stakeIcon =
                tokens.find((token) => token.symbol === item.unstakingSymbol)
                  ?.icon || Images.zil;
              return (
                <Flex key={index} alignItems="center">
                  <Text
                    fontSize="14px"
                    lineHeight="20px"
                    fontWeight="600"
                    color={text2}
                    marginRight="1"
                  >
                    {item.unstaking > 0
                      ? formatToken(
                          item.unstaking,
                          item.unstakingSymbol,
                          isHidden
                        )
                      : "0"}
                  </Text>
                  <TokenLogo size="2xs" src={stakeIcon} />
                </Flex>
              );
            })}

            <Text fontSize="12px" lineHeight="16px" color={text6}>
              {t("currentUnstaking")}
            </Text>
          </Box>
          <Box m="4" display={{ base: "block", lg: "none" }}>
            <Swiper slidesPerView={1} spaceBetween={20} width={220}>
              {stakeList &&
                stakeList.map((token, index) => {
                  const { name } = token;
                  let reward = 0;
                  let rate = 1;

                  let claimStakingItem = claimStakingItems.find(
                    (claimStakingItem) =>
                      claimStakingItem.stakingWallet.name === name
                  );

                  reward = claimStakingItem ? claimStakingItem.reward1 : 0;

                  return (
                    <SwiperSlide key={index}>
                      <StakingItemMb
                        key={index}
                        {...stakeToken}
                        {...token}
                        reward={reward}
                        rate={rate}
                      />
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </Box>
        </Box>
      )}
    </React.Fragment>
  );
};

export default StakingZilMb;
