import { Flex } from "@chakra-ui/react";
import { fromBech32Address } from "@zilliqa-js/zilliqa";
import BigNumber from "bignumber.js";
import React, { useEffect, useReducer, useState } from "react";
import DonutChart from "src/pages/Overview/components/Chart/DonutChart";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { saveWalletBalance } from "src/redux/slices/loginSlice";
import { overviewSelector } from "src/redux/slices/overviewSlice";
import LineChart from "./components/Chart/LineChart";

export type PortfolioProps = {};
export const Portfolio: React.FC<PortfolioProps> = () => {
  const dispatch = useAppDispatch();
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [totalZil, setTotalZil] = useState(0);
  const [totalWallet, setTotalWallet] = useState(0);
  const [totalStaking, setTotalStaking] = useState(0);
  const [totalUnstaking, setTotalUnstaking] = useState(0);
  const [totalLP, setTotalLP] = useState(0);
  const [totalUnclaimed, setTotalUnClaimed] = useState(0);

  const {
    walletTokens,
    tokenRates,
    pools,
    allStakingList,
    xcadPools,
    xcadClaimableData,
    tokens,
    distributions,
    carbSwapPools,
    carbSwapClaimable,
  } = useAppSelector(overviewSelector);

  useEffect(() => {
    let tmpTotalWallet = 0;
    let tmpTotalStaking = 0;
    let tmpTotalLP = 0;
    let tmpTotalUnstaking = 0;
    let tmpTotalUnclaimed = 0;

    walletTokens.forEach((walletToken) => {
      const tokenRate = tokenRates.find(
        (tokenRate) =>
          tokenRate.token.address_bech32 === walletToken.token.address_bech32
      );
      if (tokenRate && walletToken.balance) {
        if (
          walletToken.token.address_bech32 ===
          "zil1qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq9yf6pz"
        ) {
          tmpTotalWallet += walletToken.balance;
        } else {
          tmpTotalWallet += walletToken.balance * tokenRate.rate;
        }
      }
    });

    allStakingList.forEach((stakingList) => {
      stakingList.stakeList.forEach((stake) => {
        const stakeRate =
          tokenRates.find((rate) => rate.token.symbol === stake.stakeSymbol)
            ?.rate || 1;
        let balance = stake.stakingBalance || 0;
        tmpTotalStaking += balance * stakeRate;
      });

      stakingList.pendingRewards &&
        stakingList.pendingRewards.forEach((pendingReward) => {
          const rewardRate =
            tokenRates.find(
              (rate) => rate.token.symbol === pendingReward.pendingRewardSymbol
            )?.rate || 1;
          let reward = pendingReward.pendingReward || 0;
          tmpTotalUnclaimed += reward * rewardRate;
        });

      if (stakingList.unstakingPending.length > 0) {
        for (const item of stakingList.unstakingPending) {
          const rewardRate =
            tokenRates.find(
              (rate) => rate.token.symbol === item.unstakingSymbol
            )?.rate || 1;
          tmpTotalUnstaking += item.unstaking * rewardRate;
        }
      }
    });

    [pools, xcadPools, carbSwapPools].forEach((pools, index) => {
      // const poolToken = index === 0 ? 12 : 18;
      pools.forEach((pool) => {
        const {
          token,
          userContribution,
          // zilReserve,
          tokenReserve,
          totalContribution,
        } = pool;

        const tokenRate = tokenRates.find(
          (tokenRate) => tokenRate.token.address_bech32 === token.address_bech32
        );

        let contributionPercentage = userContribution!
          .dividedBy(totalContribution)
          .times(100);
        let contributionShare = contributionPercentage.shiftedBy(-2);
        let tokenAmount = contributionShare
          .times(tokenReserve)
          .div(Math.pow(10, token.decimals))
          .toNumber();

        // let zilAmount = contributionShare
        //   .times(zilReserve)
        //   .div(Math.pow(10, poolToken))
        //   .toNumber();

        if (
          !isNaN(tokenAmount) && // !isNaN(zilAmount) &&
          tokenRate &&
          tokenRate.rate
        ) {
          tmpTotalLP += 2 * tokenAmount * tokenRate.rate;
        }
      });
    });

    const dXCAD = tokens.find((token) => token.symbol === "dXCAD");
    if (dXCAD) {
      const dXCADDecimal = dXCAD.decimals;
      xcadClaimableData &&
        xcadClaimableData.forEach((claimable) => {
          tmpTotalUnclaimed += new BigNumber(claimable.amount)
            .div(Math.pow(10, dXCADDecimal))
            .toNumber();
        });
    }
    const graph = tokens.find((token) => token.symbol === "GRPH");
    if (graph) {
      const graphDecimal = graph.decimals;
      carbSwapClaimable &&
        carbSwapClaimable.forEach((claimable) => {
          tmpTotalUnclaimed += new BigNumber(claimable.amount)
            .div(Math.pow(10, graphDecimal))
            .toNumber();
        });
    }

    distributions.forEach((distribution) => {
      const rewardToken = tokens.find(
        (token) =>
          fromBech32Address(token.address_bech32).toLowerCase() ===
          distribution.info.distrAddr.toLowerCase()
      );
      if (rewardToken) {
        tmpTotalUnclaimed += new BigNumber(distribution.info.amount)
          .div(Math.pow(10, rewardToken.decimals))
          .toNumber();
      }
    });

    let total =
      tmpTotalWallet +
      tmpTotalStaking +
      tmpTotalLP +
      tmpTotalUnstaking +
      tmpTotalUnclaimed;

    setTotalLP(tmpTotalLP);
    setTotalWallet(tmpTotalWallet);
    setTotalStaking(tmpTotalStaking);
    setTotalUnstaking(tmpTotalUnstaking);
    setTotalUnClaimed(tmpTotalUnclaimed);
    setTotalZil(total);
    dispatch(saveWalletBalance(total));
  }, [
    dispatch,
    walletTokens,
    tokenRates,
    pools,
    allStakingList,
    totalZil,
    xcadPools,
    tokens,
    xcadClaimableData,
    distributions,
    carbSwapPools,
    carbSwapClaimable,
  ]);

  useEffect(() => {
    const resize = () => forceUpdate();

    // initiate the event handler
    window.addEventListener("resize", resize, false);

    return () => {
      // this will clean up the event every time the component is re-rendered
      window.removeEventListener("resize", resize, false);
    };
  }, []);

  return (
    <Flex direction={{ base: "column", xl: "row" }}>
      <LineChart
        totalZil={totalZil}
        w={{ base: "full", xl: "65%" }}
        mr={{ base: 0, xl: 8 }}
        mb={{ base: 8, xl: 0 }}
        overflow="hidden"
      />
      <DonutChart
        w={{ base: "full", xl: "35%" }}
        totalWallet={totalWallet}
        totalStaking={totalStaking}
        totalLP={totalLP}
        totalZil={totalZil}
        totalUnstaking={totalUnstaking}
        totalUnclaimed={totalUnclaimed}
      />
    </Flex>
  );
};

export default Portfolio;
