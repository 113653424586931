import {
  Box,
  Flex,
  Text,
  Image,
  BoxProps,
  Button,
  useNumberInput,
  Input,
  InputGroup,
  InputRightElement,
  InputLeftElement,
  VStack,
  useToast,
} from "@chakra-ui/react";

import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import {
  batchMintNFT,
  mintNFT,
  getHeyAlfieNftContractState,
} from "src/api/membershipApi";
import { isConnectZilpay } from "src/api/zilpayApi";
import { Images } from "src/assets/images";
import { BoxTransaction } from "src/molecules";
import { isEmptyStaking } from "src/utils";
import { useAppSelector } from "src/redux/hooks";
import { loginSelector } from "src/redux/slices/loginSlice";
import { overviewSelector } from "src/redux/slices/overviewSlice";
import ProgressBar from "./ProgressBar";
import { useAppColor } from "src/hooks";

interface PremiumContentProps extends BoxProps {
  progressValue: number;
  _onClickReadyPremium?(): void;
  _onClickGetOneNow?(): void;
  isMemberPremium?: boolean;
  _onLearnMore?(): void;
}

export const PremiumContent = memo((props: PremiumContentProps) => {
  const toast = useToast();
  const {
    progressValue,
    _onClickGetOneNow,
    _onClickReadyPremium,
    _onLearnMore,
    isMemberPremium,
    ...rest
  } = props;
  const { t } = useTranslation(["membership", "common"]);
  const [totalSupply, setTotalSupply] = React.useState<number>(0);
  const [price, setPrice] = React.useState<number>(0);
  const [amountNfts, setAmountNfts] = React.useState<number>(1);
  const [distance, setDistance] = React.useState<number>(0);
  const [saleTimeText, setSaleTimeText] = React.useState<string>("Private");
  const { currentWallet } = useAppSelector(loginSelector);
  const { allStakingList } = useAppSelector(overviewSelector);
  const maxSupply = 10000;

  const { brand } = useAppColor();

  React.useEffect(() => {
    getHeyAlfieState();

    // Set the date we're counting down to
    const countDownDateForAllUser = new Date(
      Date.UTC(2022, 1, 15, 12, 0, 0)
    ).getTime();
    const countDownDateForStakingUser = new Date(
      Date.UTC(2022, 1, 14, 12, 0, 0)
    ).getTime();

    const isAllEmpty =
      allStakingList.filter((stakingList) => !isEmptyStaking(stakingList))
        .length === 0;

    let countDownDate = countDownDateForStakingUser;
    if (isAllEmpty) {
      countDownDate = countDownDateForAllUser;
      setSaleTimeText("Public");
    } else {
      setSaleTimeText("Private");
    }

    // Update the count down every 1 second
    const intervalId = setInterval(() => {
      // Get today's date and time
      const now = new Date().getTime();
      // Find the distance between now and the count down date
      const distance = countDownDate - now;

      setDistance(distance);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [allStakingList]);

  const getCountDownText = (distance: number) => {
    // Time calculations for days, hours, minutes and seconds
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    // Display the result in the element with id="demo"
    return days * 24 + hours + "h : " + minutes + "m : " + seconds + "s";
  };

  const getHeyAlfieState = async () => {
    const { total_supply } = await getHeyAlfieNftContractState();
    const current = parseInt(`${total_supply}`);
    const price =
      current >= 1000 && current < 3250
        ? 1299
        : current >= 3250 && current < 5500
        ? 1999
        : current >= 5500 && current < 7750
        ? 2999
        : current >= 7750 && current < 10000
        ? 3999
        : 1299;
    setTotalSupply(total_supply);
    setPrice(price);
  };

  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput({
      onChange: (value: string) => setAmountNfts(parseInt(value)),
      step: 1,
      defaultValue: 1,
      min: 1,
      max: 5,
      precision: 0,
    });

  const increase = getIncrementButtonProps();
  const decrease = getDecrementButtonProps();
  const input = getInputProps();

  const handleMint = async () => {
    if (!currentWallet) {
      return;
    }

    if (!isConnectZilpay(currentWallet.zilAddress)) {
      toast({
        id: "pleaseConnectWallet",
        description: "Please connect ZilPay wallet",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    let result: any = false;
    if (amountNfts === 1) {
      result = await mintNFT(currentWallet.zilAddressBase16, price);
    } else {
      result = await batchMintNFT(
        currentWallet.zilAddressBase16,
        amountNfts,
        price
      );
    }

    if (!result || result === false) {
      toast({
        id: "pleaseConnectWallet",
        description:
          "Please check your wallet balance and refresh the page, then try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (result.ID) {
      toast({
        render: () => <BoxTransaction TranID={`0x${result.ID}`} />,
      });
      return;
    }

    toast({
      id: "mintSuccess",
      description:
        "Mint NFT successfully, please wait for the transaction to be confirmed.",
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <Box {...rest}>
      <Image
        src={Images.membershipGif}
        w="96"
        maxW={{ base: "unset", xs: "298px" }}
        h="auto"
      />
      <Flex
        fontSize="xx-small"
        fontWeight="bold"
        justify={"space-between"}
        mt={"4"}
      >
        <Text>Current price : {parseInt(`${price}`).toLocaleString()} ZIL</Text>
        <Text>
          {parseInt(`${totalSupply}`).toLocaleString()}/
          {maxSupply.toLocaleString()} minted
        </Text>
      </Flex>
      <ProgressBar value={(totalSupply * 100) / maxSupply} />
      {distance < 0 && (
        <VStack w="full">
          <InputGroup>
            <InputLeftElement>
              <Button bg={brand} {...decrease}>
                -
              </Button>
            </InputLeftElement>
            <Input
              {...input}
              readOnly
              bg={"white"}
              color="black"
              textAlign="center"
            />
            <InputRightElement>
              <Button bg={brand} {...increase}>
                +
              </Button>
            </InputRightElement>
          </InputGroup>
          <Text fontSize="xs" fontWeight="bold">
            You can mint up to 5 per transaction.
          </Text>
          <Button w="full" bg={brand} onClick={handleMint} color="black">
            {t("mint")}
          </Button>
        </VStack>
      )}
      {distance > 0 && (
        <VStack w="full" bg={brand} padding={8} borderRadius={8}>
          <Text fontSize="xs" fontWeight="bold" color="black">
            {`${saleTimeText} Sale Starts In:`}
          </Text>
          <Text fontSize="xs" fontWeight="bold" color="black">
            {getCountDownText(distance)}
          </Text>
        </VStack>
      )}
      <VStack w="full">
        <Button onClick={_onLearnMore} variant="ghost">
          {t("common:learnMore")}
        </Button>
      </VStack>
    </Box>
  );
});

export default PremiumContent;
