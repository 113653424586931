import React from "react";
import {
  Box,
  Flex,
  useBoolean,
} from "@chakra-ui/react";
import DexLeft from "./DexLeft";
import DexMiddle from "./DexMiddle";
import DexRight from "./DexRight";

interface SwapDexProps {}

const SwapDex: React.FC<SwapDexProps> = () => {
  const [choiceBtn, setChoiceBtn] = useBoolean(true);

  return (
    <Box>
      <Flex
        flexDirection={{ base: "column", lg: "row" }}
        justifyContent="space-between"
        spacing="4"
        px="15px"
      >
        <Box w={{base:"full", md:"30%"}} px="10px" hidden={!choiceBtn}>
          <DexLeft />
        </Box>
        <Box w={{base:"full", md:"70%"}} px="10px" pt={{base:"30px", md:"0px"}} >
          <DexMiddle onClick={setChoiceBtn.toggle} choiceBtn={choiceBtn} />
        </Box>
        <Box w={{base:"full", md:"30%"}} px="10px" hidden={choiceBtn}>
          <DexRight />
        </Box>
      </Flex>
    </Box>
  );
};

export default SwapDex;
