import React from "react";
import { Box, Flex, Spacer, Text } from "@chakra-ui/layout";
import { TokenLogo } from "src/atoms";
import { useAppColor } from "src/hooks";
import { Images } from "src/assets/images";
import { useBoolean } from "@chakra-ui/hooks";
import { MotionBox } from "src/molecules";
import {
  loginSelector,
  overviewSelector,
} from "src/redux/slices/overviewSlice";
import { IWalletTokenRow } from "./WalletToken";
import { formatToken, formatUsdt } from "src/lib/formatCurrency";
import { useAppSelector } from "src/redux/hooks";
import { useTranslation } from "react-i18next";

interface WalletProps {
  item: IWalletTokenRow;
  totalBalance: number;
  is_show: boolean;
}

const Wallet: React.FC<WalletProps> = ({ item, is_show, totalBalance }) => {
  const { t } = useTranslation(["overview", "common"]);
  const { text6, main3, main4 } = useAppColor();
  const [show, setShow] = useBoolean(false);
  const { zilPriceInUsd } = useAppSelector(overviewSelector);
  const { exchangeRate, isHidden } = useAppSelector(loginSelector);

  const { walletToken, tokenRate } = item;

  if (!walletToken || !tokenRate) {
    return <div />;
  }
  if (walletToken.balance === 0 && !is_show) {
    return <div />;
  }

  const { token, balance } = walletToken;
  const { rate } = tokenRate;

  return (
    <Box borderBottom="1px" borderBottomColor={main4}>
      <Box display="flex" p="16px" onClick={setShow.toggle}>
        <Flex flex="1" alignItems="center">
          <TokenLogo size="sm" mr="2" src={token.icon} />
          <Box justifyContent="flex-end">
            <Text fontSize="sm" fontWeight="semibold">
              {token.name}
            </Text>
            <Text fontSize="xs" color={text6}>
              {token.name}
            </Text>
          </Box>
        </Flex>
        <Flex
          flex="1"
          flexDirection="column"
          alignItems="flex-end"
          justifyContent="center"
        >
          <Flex justifyContent="flex-end" alignItems="center">
            <Text fontSize="sm" fontWeight="semibold" mr="1">
              {zilPriceInUsd
                ? formatToken(balance * rate, "ZIL", isHidden)
                : t("common:..loading")}
            </Text>
            <TokenLogo w="4" h="4" src={Images.zil} />
          </Flex>
          <Text fontSize="xs" color={text6}>
            ~
            {zilPriceInUsd
              ? formatUsdt(
                  balance * zilPriceInUsd * rate,
                  exchangeRate,
                  isHidden
                )
              : t("common:..loading")}
          </Text>
        </Flex>
        <Flex
          flex="1"
          flexDirection="column"
          alignItems="flex-end"
          justifyContent="center"
        >
          <Text fontSize="sm" fontWeight="semibold" lineHeight="5">
            {t("common:share")}
          </Text>
          <Text fontSize="xs" color="blue.600" lineHeight="4">
            {totalBalance && totalBalance > 0
              ? `${((balance * rate * 100) / totalBalance).toFixed(2)}%`
              : t("common:..loading")}
          </Text>
        </Flex>
      </Box>
      {show && (
        <MotionBox
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            transition: { duration: 0.5 },
          }}
        >
          <Box
            display="flex"
            py="12px"
            px="16px"
            ml="16px"
            borderTopWidth="1px"
            borderBottomWidth="1px"
            borderTopColor={main4}
            borderBottomColor={main3}
          >
            <Spacer />
            <Flex
              flex="1"
              flexDirection="column"
              alignItems="flex-end"
              justifyContent="center"
            >
              <Text
                fontSize="xs"
                fontWeight="bold"
                letterSpacing="wider"
                lineHeight="4"
                color={text6}
              >
                {t("common:price")}
              </Text>
            </Flex>
            <Flex
              flex="1"
              flexDirection="column"
              alignItems="flex-end"
              justifyContent="center"
            >
              <Text
                fontSize="xs"
                letterSpacing="wider"
                fontWeight="bold"
                lineHeight="4"
                color={text6}
              >
                {t("common:amount")}
              </Text>
            </Flex>
          </Box>
          <Box display="flex" p="16px">
            <Spacer />
            <Flex
              flex="1"
              flexDirection="column"
              alignItems="flex-end"
              justifyContent="center"
            >
              <Flex justifyContent="flex-end" alignItems="center">
                <Text fontSize="sm" fontWeight="semibold" mr="1">
                  {formatToken(rate)}
                </Text>
                <TokenLogo w="4" h="4" src={Images.zil} />
              </Flex>
              <Text fontSize="xs" color={text6}>
                ~
                {zilPriceInUsd
                  ? formatUsdt(rate * zilPriceInUsd, exchangeRate)
                  : t("common:..loading")}
              </Text>
            </Flex>
            <Flex
              flex="1"
              flexDirection="column"
              alignItems="flex-end"
              justifyContent="center"
            >
              <Flex justifyContent="flex-end" alignItems="center">
                <Text fontSize="sm" fontWeight="semibold" mr="1">
                  {formatToken(balance, token.symbol, isHidden)}
                </Text>
                <TokenLogo w="4" h="4" src={token.icon} />
              </Flex>
              <Text fontSize="xs" color={text6}>
                ~{" "}
                {zilPriceInUsd
                  ? formatToken(balance * rate, "ZIL", isHidden)
                  : t("common:..loading")}
              </Text>
            </Flex>
          </Box>
        </MotionBox>
      )}
    </Box>
  );
};

export default Wallet;
