import React from "react";
import { Box, Text } from "@chakra-ui/layout";
import { PricingProps } from "src/constants/type";

const Pricing: React.FC<PricingProps> = ({
  button,
  data,
  bg,
  ...rest
}: PricingProps) => {
  const { name, description, price, benefits } = data;
  return (
    <Box
      p="6"
      rounded="lg"
      bg={bg}
      w={"100%"}
      minW={{ base: "unset", lg: "200px", xl: "220px" }}
      {...rest}
    >
      <Text
        fontSize="2xl"
        lineHeight="8"
        fontWeight="extrabold"
        color="gray.800"
      >
        {name}
      </Text>
      <Text
        fontSize="6xl"
        lineHeight="100%"
        fontWeight="extrabold"
        color="gray.800"
        mt="4"
      >
        ${price}
        {price !== 0 && (
          <Text
            fontSize="xl"
            lineHeight="7"
            fontWeight="extrabold"
            color="gray.800"
            as="span"
          >
            /yr
          </Text>
        )}
      </Text>
      <Text fontSize="sm" lineHeight="5" color="gray.800" mt="4" mb="6">
        {description}
      </Text>
      {benefits.map((item, index) => (
        <Text
          key={index}
          fontSize="md"
          mb="4"
          fontWeight="medium"
          lineHeight="6"
          color="gray.800"
        >
          {item}
        </Text>
      ))}
      <Box mt="10" align="center">
        {button}
      </Box>
    </Box>
  );
};

export default Pricing;
