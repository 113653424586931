import React from "react";
import { useBoolean, Center, Box } from "@chakra-ui/react";
import { useAppSelector } from "src/redux/hooks";
import { overviewSelector } from "src/redux/slices/overviewSlice";
import { useAppColor } from "src/hooks";
import { IStakingInfo } from "src/constants/interfaces";
import { IconFilledArrow } from "src/assets/icons";
import StakingItemMb from "./StackingItemMb";
import StakingHeaderMb from "./StakingHeaderMb";

interface StakingTableMbProps {
  claimToken: () => void;
  stakeToken: IStakingInfo;
}

const StakingTableMb: React.FC<StakingTableMbProps> = ({
  stakeToken,
  claimToken,
}) => {
  const [show, setShow] = useBoolean(false);
  const [showAll, setShowAll] = useBoolean(false);
  const { text3, text5 } = useAppColor();

  const { tokenRates } = useAppSelector(overviewSelector);

  const { stakeList, symbol, pendingRewards } = stakeToken;

  const toggleShow = () => {
    setShow.toggle();
    setShowAll.off();
  };

  return (
    <React.Fragment>
      <StakingHeaderMb
        toggleShow={toggleShow}
        stakeToken={stakeToken}
        claimToken={claimToken}
      />
      {!!show && (
        <>
          {stakeList &&
            stakeList.map((token, index) => {
              const { stakingBalance } = token;
              let rate = 1;
              let reward = 0;
              if (pendingRewards) {
                reward =
                  pendingRewards.find(
                    (pendingReward) => pendingReward.address === token.address
                  )?.pendingReward || 0;
              }
              if (
                (!stakingBalance && reward <= 0 && !showAll) ||
                (stakingBalance &&
                  stakingBalance <= 0 &&
                  reward <= 0 &&
                  !showAll)
              ) {
                return <Box key={index} />;
              }
              const tokenRate = tokenRates.find(
                (tokenRate) => tokenRate.token.symbol === symbol
              );

              if (tokenRate) {
                rate = tokenRate.rate;
              }

              return (
                <StakingItemMb
                  key={index}
                  {...stakeToken}
                  {...token}
                  reward={reward}
                  rate={rate}
                />
              );
            })}
          {stakeList.filter(
            (stake) => stake.stakingBalance && stake.stakingBalance > 0
          ).length !== stakeList.length && (
            <Center>
              <IconFilledArrow
                position="absolute"
                _groupHover={{
                  fill: text3,
                }}
                onClick={() => {
                  setShowAll.toggle();
                }}
                cursor="pointer"
                size="6"
                fill={text5}
                style={{
                  transform: `rotate(${showAll ? 0 : 180}deg)`,
                }}
              />
            </Center>
          )}
        </>
      )}
    </React.Fragment>
  );
};

export default StakingTableMb;
