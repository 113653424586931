import React from "react";
import { BoxProps, Center } from "@chakra-ui/layout";
import { useAppColor } from "src/hooks";
import { useTranslation } from "react-i18next";

interface ViewCollectionProps extends BoxProps {}

const ViewCollection: React.FC<ViewCollectionProps> = ({ ...props }) => {
  const { main1 } = useAppColor();
  const { t } = useTranslation("nfts");
  return (
    <Center
      textAlign="center"
      w="full"
      p="4"
      bg={main1}
      cursor="pointer"
      borderRadius="8px"
      shadow="md"
      fontSize="lg"
      textDecorationLine="underline"
      {...props}
    >
      {t("viewCollection")}
    </Center>
  );
};

export default ViewCollection;
