import React from "react";
import {
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/modal";
import { useAppColor } from "src/hooks";
import {
  Box,
  Button,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useBoolean,
} from "@chakra-ui/react";
import { IconArrowDown } from "src/assets/icons";
import { Images } from "src/assets/images";
import { modal_details } from "./sample";
import { TokenLogo } from "src/atoms";
import { useTranslation } from "react-i18next";

interface ModalAddLPProps {
  onClose: () => void;
  data?: any;
  initLp?: any;
}

const ModalAddLP: React.FC<ModalAddLPProps> = ({ data, initLp }) => {
  const { t } = useTranslation(["overview", "common"]);
  const { text2, text3, text4 } = useAppColor();
  const [show, setShow] = useBoolean(false);
  const [lpToken] = React.useState(initLp);
  return (
    <>
      <ModalHeader textTransform="capitalize" color={text3}>
        {t("addLP")}
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        {[lpToken, {}].map((item, index) => {
          return (
            <React.Fragment key={index}>
              <InputGroup>
                <Input placeholder={t("common:amount")} size="lg" />
                <InputRightElement w="max-content" px="4" cursor="pointer">
                  <Flex alignItems="center" mt="2">
                    <TokenLogo src={item.logo || Images.zil} size="xs" />
                    <Text
                      fontSize="sm"
                      color={text3}
                      mx="3"
                      textTransform="uppercase"
                    >
                      {item.name || "ZIL"}
                    </Text>
                    <IconArrowDown />
                  </Flex>
                </InputRightElement>
              </InputGroup>
              <Flex justifyContent="flex-end" alignItems="center" mt="2">
                <Text fontSize="xs" fontWeight="bold" color={text4}>
                  {t("common:balance")}:
                </Text>
                <Text mx="3" fontSize="xs" fontWeight="bold" color={text2}>
                  {item.deposit || lpToken.unclaimed}
                </Text>
              </Flex>
              {item.logo && (
                <Text align="center" fontSize="28" color={text3} my="2">
                  +
                </Text>
              )}
            </React.Fragment>
          );
        })}
      </ModalBody>
      <ModalFooter flexDirection="column">
        <Flex
          w="full"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Flex alignItems="center" cursor="pointer" onClick={setShow.toggle}>
            <Text fontSize="xs" fontWeight="semibold" color={text3} mr="2">
              {t("moreDetails")}
            </Text>
            <IconArrowDown
              style={{ transform: `rotate(${show ? 180 : 0}deg)` }}
            />
          </Flex>
          <Button children={t("addLP")} mb="3" />
        </Flex>
        {show && (
          <HStack w="full" p="3">
            {modal_details.map((detail, index) => (
              <Box w="full" key={index}>
                <Text
                  fontWeight="medium"
                  align="center"
                  fontSize="md"
                  color={text2}
                >
                  {detail.value}
                </Text>
                <Text align="center" fontSize="sm" color={text4}>
                  {index === 0
                    ? detail.keyName + lpToken.name.toString()
                    : detail.keyName}
                </Text>
              </Box>
            ))}
          </HStack>
        )}
      </ModalFooter>
    </>
  );
};

export default ModalAddLP;
