import React from "react";
import {
  Box,
  BoxProps,
  Flex,
  Stat,
  StatArrow,
  StatHelpText,
  StatNumber,
  Text,
} from "@chakra-ui/react";
import {
  BarPrice,
  ChartOptions,
  createChart,
  DeepPartial,
  IChartApi,
  ISeriesApi,
  TickMarkType,
  UTCTimestamp,
} from "lightweight-charts";
import { apiGetZilPriceChartInUsd, ChartDay } from "src/api/coingeckoApi";
import { useAppColor } from "src/hooks";
import { formatToken, formatUsdt } from "src/lib/formatCurrency";
import { useAppSelector } from "src/redux/hooks";
import { overviewSelector } from "src/redux/slices/overviewSlice";
import { loginSelector } from "src/redux/slices/loginSlice";
import { dayjs } from "src/utils";
import exchangeRates from "src/constants/exchangeRates";

enum Range {
  D1 = "24h",
  D7 = "7d",
  D30 = "30d",
  D90 = "90d",
  MAX = "Max",
}

export interface LineChartProps extends BoxProps {
  totalZil: number;
}

interface ITooltip {
  value?: string | BarPrice;
  time?: string;
  left?: number;
  top?: number;
}

let chart: IChartApi;
let lineSeries: ISeriesApi<"Baseline">;

const rangeSwitcher = [Range.D1, Range.D7, Range.D30, Range.D90, Range.MAX];

const LineChart: React.FC<LineChartProps> = ({ totalZil, ...props }) => {
  const { text2, text6, main1, main2, main3, theme } = useAppColor();
  const [width, setWidth] = React.useState<number>(0);

  const [selectedRange, setSelectedRange] = React.useState<Range>(Range.D1);
  const [rangeData, setRangeData] = React.useState<any>([]);
  const { zilPriceInUsd } = useAppSelector(overviewSelector);
  const { exchangeRate, isHidden } = useAppSelector(loginSelector);

  const [rate, setRate] = React.useState<number>(0);
  // const [showTooltip, setShowTooltip] = React.useState(false);

  const chartRef = React.useRef<any>(null);
  const [tooltipValue] = React.useState<ITooltip>();
  const [initTheme] = React.useState(theme);

  const [isCancelled, setIsCancelled] = React.useState<boolean>();

  const rangeSwitch = (range: Range) => {
    setSelectedRange(range);
    switch (range) {
      case Range.D1:
        fetchData(ChartDay.D1);
        break;
      case Range.D7:
        fetchData(ChartDay.D7);
        break;
      case Range.D30:
        fetchData(ChartDay.D30);
        break;
      case Range.D90:
        fetchData(ChartDay.D90);
        break;
      case Range.MAX:
      default:
        fetchData(ChartDay.MAX);
        break;
    }
  };

  const fetchData = React.useCallback(
    async (day: ChartDay) => {
      try {
        const prices = await apiGetZilPriceChartInUsd(day);
        const rate = exchangeRate.value / exchangeRates[0].value;
        if (!isCancelled) {
          let price = prices.map((e: any) => {
            return { time: e[0] / 1000, value: e[1] * totalZil * rate };
          });
          setRangeData(price);
        }
      } catch (e) {
        console.log(e);
      }
    },
    [isCancelled, totalZil, exchangeRate]
  );

  React.useEffect(() => {
    setIsCancelled(false);
    fetchData(ChartDay.D1);
    return () => {
      setIsCancelled(true);
    };
  }, [fetchData]);

  React.useEffect(() => {
    if (rangeData && rangeData.length > 1) {
      const prev = rangeData[0].value;
      const cur = rangeData[rangeData.length - 1].value;
      setRate(prev !== 0 ? (cur - prev) / prev : 0);
    }
  }, [rangeData]);

  React.useEffect(() => {
    if (chart) {
      chart.remove();
    }
    const chartOptions: DeepPartial<ChartOptions> = {
      width: width,
      height: 280,
      layout: {
        backgroundColor: "transparent",
        textColor: initTheme === "light" ? "#1A202C" : "#EDF2F7",
      },
      leftPriceScale: {
        visible: false,
      },
      timeScale: {
        borderVisible: false,
        tickMarkFormatter: (time: any, tickMarkType: TickMarkType) => {
          let formatOption;
          switch (tickMarkType) {
            case TickMarkType.Year:
              formatOption = "YYYY";
              break;
            case TickMarkType.Month:
              formatOption = "MMM";
              break;
            case TickMarkType.Time:
              formatOption = "HH:00";
              break;
            default:
              switch (selectedRange) {
                case Range.D1:
                  formatOption = "HH:00";
                  break;
                case Range.MAX:
                  formatOption = "MMM-YYYY";
                  break;
                case Range.D7:
                case Range.D30:
                case Range.D90:
                default:
                  formatOption = "DD";
                  break;
              }
              break;
          }
          return dayjs(time * 1000).format(formatOption);
        },
      },
      grid: {
        vertLines: {
          visible: false,
        },
        horzLines: {
          visible: false,
        },
      },
      rightPriceScale: {
        borderVisible: false,
      },
      localization: {
        timeFormatter: (time: any) => {
          return dayjs(time * 1000).format("YYYY-MMM-DD - HH:mm");
        },
      },
    };
    chart = createChart(chartRef?.current, chartOptions);

    lineSeries = chart.addBaselineSeries({
      topFillColor1:
        rate === 0 ? "#2B6CB070" : rate > 0 ? "#16c78470" : "#ea394370",
      topLineColor: rate === 0 ? "#2B6CB0" : rate > 0 ? "#16c784" : "#ea3943",
      lineWidth: 2,
      // baseValue: { type: "price", price: 0.088 }, // TODO
    });

    lineSeries.setData(rangeData);
    chart.timeScale().fitContent();
  }, [rangeData, width, initTheme, rate, selectedRange]);

  React.useEffect(() => {
    if (chart) {
      chart.applyOptions({
        layout: {
          textColor: theme === "light" ? "#1A202C" : "#EDF2F7",
        },
      });
    }
  }, [theme]);

  React.useEffect(() => {
    if (chart) {
      chart.applyOptions({
        timeScale: {
          tickMarkFormatter: (time: UTCTimestamp) => {
            return dayjs(time).format("DD");
          },
        },
        localization: {
          timeFormatter: (time: UTCTimestamp) => dayjs(time).format("YYYY"),
        },
      });
    }
  }, [selectedRange]);

  // React.useEffect(() => {
  //   if (chart) {
  //     chart.subscribeCrosshairMove((params) => {
  //       if (!params.time || !params.point) {
  //         setShowTooltip(false);
  //         return;
  //       }
  //       setShowTooltip(true);
  //       let value = params.seriesPrices.get(lineSeries);
  //       //@ts-ignore
  //       let coordinate = lineSeries.priceToCoordinate(value);
  //       setTooltipValue({
  //         //@ts-ignore
  //         time: dayjs(params.time * 1000).format("DD MMM YYYY"),
  //         //@ts-ignore
  //         value: (totalZil * value).toFixed(2),
  //         top: coordinate ? coordinate - 96 : undefined,
  //         left:
  //           params.point.x > 64 && params.point.x < 566
  //             ? params.point.x - 64
  //             : params.point.x > 500
  //             ? params.point.x - 128
  //             : 0,
  //       });
  //     });
  //   }
  // }, [rangeData, totalZil]);

  return (
    <Flex
      {...props}
      direction="column"
      borderRadius="lg"
      border="2px"
      borderColor={main3}
      py="6"
      bg={main1}
      ref={(el) => {
        if (!el) return;
        setWidth(el.getBoundingClientRect().width);
      }}
    >
      <Stat mx="6">
        <StatNumber fontSize="3xl" fontWeight="extrabold">
          <Text>
            {formatUsdt(totalZil * zilPriceInUsd, exchangeRate, isHidden)}
          </Text>
        </StatNumber>
        {!isHidden && (
          <StatHelpText
            fontSize="md"
            color={rate === 0 ? "blue.600" : rate > 0 ? "green.600" : "red.600"}
            fontWeight="400"
            display="flex"
            alignItems="center"
          >
            {rate !== 0 && (
              <StatArrow type={rate > 0 ? "increase" : "decrease"} />
            )}
            {Math.abs(rate * 100).toFixed(2)}% (
            <Text>
              {formatUsdt(
                Math.abs(rate) * totalZil * zilPriceInUsd,
                exchangeRate
              )}
            </Text>
            )
          </StatHelpText>
        )}
      </Stat>
      <Box ref={chartRef} position="relative" my="6">
        <Box
          h="20"
          p="4"
          borderRadius="2xl"
          bg={main2}
          position="absolute"
          // display={showTooltip ? "block" : "none"}
          display={"none"}
          style={{
            top: tooltipValue?.top,
            left: tooltipValue?.left,
          }}
          zIndex="10"
          pointerEvents="none"
        >
          <Text fontSize="xs" color={text6}>
            {tooltipValue?.time}
          </Text>
          <Text color={text2}>
            {formatUsdt(
              //@ts-ignore
              tooltipValue?.value,
              exchangeRate
            )}
          </Text>
          <Text fontSize="xs" color={text2}>
            {formatToken(totalZil, "ZIL")}
          </Text>
        </Box>
      </Box>
      <Flex>
        {rangeSwitcher.map((range, index) => (
          <Text
            key={index}
            fontWeight="semibold"
            fontSize="xs"
            cursor="pointer"
            ml="6"
            onClick={() => rangeSwitch(range)}
            color={range === selectedRange ? text2 : text6}
          >
            {range}
          </Text>
        ))}
      </Flex>
    </Flex>
  );
};

export default LineChart;
