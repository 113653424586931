import BigNumber from "bignumber.js";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { apiGetZilPriceInUsd } from "src/api/coingeckoApi";
import {
  apiGetZilSwapClaimableData,
  apiGetDistributionInfo,
  Distributor,
  DistributionWithStatus,
  apiGetDistributionsWithStatus,
  apiGetZilswapEstimateReward,
} from "src/api/zilswapApi";
import {
  ITokenRate,
  IToken,
  ITokenPool,
  IClaimableData,
  ICollection,
  ITokenAllowance,
  ISsnInfo,
  IStakingInfo,
  IPendingReward,
  IEstimateReward,
} from "src/constants/interfaces";
import { fromBech32Address, toBech32Address } from "@zilliqa-js/crypto";
import {
  balanceBatchRequest,
  carbonStakersBatchRequest,
  carbonPendingCarbsBatchRequest,
  EnumBatchRequestType,
  poolsBatchRequest,
  sendBatchRequest,
  stakingDelegatorsBatchRequest,
  stakingOperatorsBatchRequest,
  tokenBalanceBatchRequest,
  tokenPoolBalanceBatchRequest,
  totalContributionsBatchRequest,
  tokenAllowancesBatchRequest,
  scoStakersBatchRequest,
  portStakingHashes,
  portStakersBatchRequest,
  portPendingRewardsBatchRequest,
  scoStakingHash,
  xcadStakingHashes,
  xcadStakersBatchRequest,
  xcadPendingRewardsBatchRequest,
  xcadPoolsBatchRequest,
  xcadTotalContributionsBatchRequest,
  xcadTokenPoolBalanceBatchRequest,
  okiStakersBatchRequest,
  okiPendingRewardsBatchRequest,
  unifeeStakingHashes,
  unifeeStakersBatchRequest,
  unifeePendingRewardsBatchRequest,
  bloxStakersBatchRequest,
  bloxPendingBloxsBatchRequest,
  xcadZilPoolsBatchRequest,
  xcadZilPoolBalanceBatchRequest,
  xcadZilTotalContributionsBatchRequest,
  demonzStakersBatchRequest,
  grphStakersBatchRequest,
  grphPendingRewardsBatchRequest,
  carbSwapPoolsBatchRequest,
  carbSwapTotalContributionsBatchRequest,
  carbSwapPoolBalanceBatchRequest,
  lunarStakingHashes,
  lunarStakersBatchRequest,
  hunyVaultPoolBalanceBatchRequest,
  hunyVaultTotalContributionsBatchRequest,
} from "src/api/zilApi";
import { apiGetStakingRewardState } from "src/api/zilletApi";
import { bnOrZero } from "src/lib/strings";
import { EnumNetwork } from "src/api/network";
import { apiGetTokenPricesInZil } from "src/api/zilStreamApi";
import { AppThunk } from "../store";
import { addZilpayWallet, ILoginState } from "./loginSlice";
import { RootState } from "../rootReducer";
import { Images } from "src/assets/images";
import { apiGetScoRewards } from "src/api/heyAlfieApi";
import ssnListMap from "src/constants/ssnListMap";
import { getCollections } from "src/api/nftApi";
import {
  apiGetXcadClaimable,
  apiGetXcadDistributor,
  apiGetXcadEstimatedReward,
} from "src/api/xcadApi";
import { generateAvatarUrl, sortStakingList } from "src/utils";
import { fetchReward } from "src/api/stakingRewardApi";
import { getHeyAlfieNfts } from "src/api/membershipApi";
import { carbSwapClaimable, carbSwapTotalReward } from "src/api/carbSwapApi";
export interface IStakingResult {
  address: string;
  stakingBalance?: number;
  stakingRewards?: number;
}

export interface IStakingRewardStateResult {
  apy: number;
  blocksLeft: number;
  totalStaked: number;
  nextRewardTime: number;
  gZilLeft: number;
  currHeight: number;
  totalStakedPer: number;
}

export interface IWalletBalanceResult {
  balance: number;
}

export interface IClaimStakingTxResult {
  ssn: ISsnInfo;
  claimRewardTx?: any;
}

export interface ITokenBalanceResult {
  token: IToken;
  balance: number;
}

export interface ITokenLPRewardResult {
  tokenAddress: string;
  lpZWAPReward?: number;
}

export interface ITokenLPResult {
  token: IToken;
  lpShareRatio: number;
  lpZilAmount: number;
  lpTokenAmount: number;
}

export interface ITokenPriceInZilResult {
  token: IToken;
  priceInZil: number;
}

export interface IOverviewError {
  message: string;
}

export interface IWalletToken {
  token: IToken;
  balance: number;
  priceInZil?: number;
  lpZilAmount?: number;
  lpZrcTokenAmount?: number;
}

export interface IZilpayWallet {
  base16: string;
  bech32: string;
}

export interface IOverviewState {
  isLoading: boolean;
  error: IOverviewError;
  zilPriceInUsd: number;
  tokens: IToken[];
  tokenRates: ITokenRate[];
  tokenAllowances: ITokenAllowance[];
  walletTokens: IWalletToken[];
  collections: ICollection[];
  walletStakingList: ISsnInfo[];
  allStakingList: IStakingInfo[];
  pools: ITokenPool[];
  xcadPools: ITokenPool[];
  xcadDistributors: Distributor[];
  xcadDistributions: DistributionWithStatus[];
  xcadClaimableData: IClaimableData[];
  carbSwapPools: ITokenPool[];
  carbSwapClaimable: IClaimableData[];
  hunyPools: ITokenPool[];
  claimableData: IClaimableData[];
  distributors: Distributor[];
  distributions: DistributionWithStatus[];
  heyAlfieNfts: string[];
  xcadEstimatedReward: IEstimateReward[];
  zwapEstimatedReward: IEstimateReward[];
  carbSwapEstimatedReward: IEstimateReward[];
}

// Init data
export const initialState: IOverviewState = {
  isLoading: false,
  error: { message: "" },
  zilPriceInUsd: 0,
  tokens: Array<IToken>(),
  tokenRates: Array<ITokenRate>(),
  tokenAllowances: Array<ITokenAllowance>(),
  walletTokens: Array<IWalletToken>(),
  collections: Array<ICollection>(),
  heyAlfieNfts: Array<string>(),
  pools: Array<ITokenPool>(),
  xcadPools: Array<ITokenPool>(),
  xcadDistributors: Array<Distributor>(),
  xcadDistributions: Array<DistributionWithStatus>(),
  xcadClaimableData: Array<IClaimableData>(),
  carbSwapPools: Array<ITokenPool>(),
  carbSwapClaimable: Array<IClaimableData>(),
  hunyPools: Array<ITokenPool>(),
  walletStakingList: Array<ISsnInfo>(),
  claimableData: Array<IClaimableData>(),
  allStakingList: Array<IStakingInfo>(),
  distributors: Array<Distributor>(),
  distributions: Array<DistributionWithStatus>(),
  xcadEstimatedReward: Array<IEstimateReward>(),
  zwapEstimatedReward: Array<IEstimateReward>(),
  carbSwapEstimatedReward: Array<IEstimateReward>(),
};

export const overviewSlice = createSlice({
  name: "overview",
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    updateTokens: (state, { payload }: PayloadAction<IToken[]>) => {
      state.tokens = payload;
    },
    updatePools: (state, { payload }: PayloadAction<ITokenPool[]>) => {
      state.pools = payload;
    },
    updateXcadPool: (state, { payload }: PayloadAction<ITokenPool[]>) => {
      state.xcadPools = payload;
    },
    updateXcadDistributors: (
      state,
      { payload }: PayloadAction<Distributor[]>
    ) => {
      state.xcadDistributors = payload;
    },
    updateXcadDistributions: (
      state,
      { payload }: PayloadAction<DistributionWithStatus[]>
    ) => {
      state.xcadDistributions = payload;
    },
    updateCarbSwapPool: (state, { payload }: PayloadAction<ITokenPool[]>) => {
      state.carbSwapPools = payload;
    },
    updateCarbSwapClaimableData: (
      state,
      { payload }: PayloadAction<IClaimableData[]>
    ) => {
      state.carbSwapClaimable = payload;
    },
    updateHunyPool: (state, { payload }: PayloadAction<ITokenPool[]>) => {
      state.hunyPools = payload;
    },
    updateXcadClaimableData: (
      state,
      { payload }: PayloadAction<IClaimableData[]>
    ) => {
      state.xcadClaimableData = payload;
    },
    updateZilPriceInUsd: (state, { payload }: PayloadAction<number>) => {
      state.zilPriceInUsd = payload;
    },
    updateWalletTokens: (state, { payload }: PayloadAction<IWalletToken[]>) => {
      state.walletTokens = payload;
    },
    updateTokenRates: (state, { payload }: PayloadAction<ITokenRate[]>) => {
      state.tokenRates = payload;
    },
    updateTokenAllowances: (
      state,
      { payload }: PayloadAction<ITokenAllowance[]>
    ) => {
      state.tokenAllowances = payload;
    },
    updateDistributors: (state, { payload }: PayloadAction<Distributor[]>) => {
      state.distributors = payload;
    },
    updateDistributions: (
      state,
      { payload }: PayloadAction<DistributionWithStatus[]>
    ) => {
      state.distributions = payload;
    },
    updateClaimableData: (
      state,
      { payload }: PayloadAction<IClaimableData[]>
    ) => {
      state.claimableData = payload;
    },
    updateWalletStakingList: (
      state,
      { payload }: PayloadAction<ISsnInfo[]>
    ) => {
      state.walletStakingList = payload;
    },
    updateCollections: (state, { payload }: PayloadAction<ICollection[]>) => {
      state.collections = payload;
    },
    updateStakingList: (state, { payload }: PayloadAction<IStakingInfo[]>) => {
      state.allStakingList = payload;
    },
    addStakingList: (state, { payload }: PayloadAction<IStakingInfo>) => {
      state.allStakingList.push(payload);
    },
    updateHeyAlfieNfts: (state, { payload }: PayloadAction<string[]>) => {
      state.heyAlfieNfts = payload;
    },
    updateXcadEstimatedReward: (
      state,
      { payload }: PayloadAction<IEstimateReward[]>
    ) => {
      state.xcadEstimatedReward = payload;
    },
    updateZwapEstimatedReward: (
      state,
      { payload }: PayloadAction<IEstimateReward[]>
    ) => {
      state.zwapEstimatedReward = payload;
    },
    updateCarbSwapEstimatedReward: (
      state,
      { payload }: PayloadAction<IEstimateReward[]>
    ) => {
      state.carbSwapEstimatedReward = payload;
    },
  },
});

export const {
  setLoading,
  updateTokenRates,
  updateTokenAllowances,
  updateWalletTokens,
  updateZilPriceInUsd,
  updateWalletStakingList,
  updateClaimableData,
  updateDistributors,
  updateDistributions,
  updatePools,
  updateXcadPool,
  updateXcadDistributors,
  updateXcadClaimableData,
  updateXcadDistributions,
  updateCarbSwapPool,
  updateHunyPool,
  updateTokens,
  updateCollections,
  updateHeyAlfieNfts,
  updateStakingList,
  addStakingList,
  updateCarbSwapClaimableData,
  updateXcadEstimatedReward,
  updateZwapEstimatedReward,
  updateCarbSwapEstimatedReward,
} = overviewSlice.actions;

export const syncTokenRates = (): AppThunk => async (dispatch, getState) => {
  try {
    const overviewState: IOverviewState = overviewSelector(getState());
    const { tokens } = overviewState;
    const tokenRates: ITokenRate[] = await apiGetTokenPricesInZil(tokens);
    dispatch(updateTokenRates(tokenRates));
  } catch (error) {
    console.log("Fetch token rate error", error);
  }
};

export const syncZilPayWallet =
  (zilpayWallet: IZilpayWallet): AppThunk =>
  async (dispatch) => {
    try {
      const avatar = await generateAvatarUrl(zilpayWallet.base16);
      dispatch(
        addZilpayWallet({
          isZilPay: false,
          name: zilpayWallet.bech32,
          avatar: avatar,
          zilAddress: zilpayWallet.bech32,
          zilAddressBase16: zilpayWallet.base16,
          balance: 0,
          holdings: [],
          pinNfts: [],
          followers: [],
          followings: [],
        })
      );
      // dispatch(viewWallet(zilpayWallet.bech32, false, true));
    } catch (error) {
      console.log("Fetch token rate error", error);
    }
  };

export const syncWalletTokens = (): AppThunk => async (dispatch, getState) => {
  try {
    const loginState: ILoginState = loginSelector(getState());
    const { currentWallet } = loginState;
    if (!currentWallet) {
      return;
    }

    dispatch(setLoading(true));

    const walletAddress: string = currentWallet.zilAddress;
    const walletBase16Address: string =
      fromBech32Address(walletAddress).toLowerCase();
    const operators: ISsnInfo[] = Object.values(ssnListMap);
    const batchRequests: any[] = [];

    const overviewState: IOverviewState = overviewSelector(getState());
    const { tokens } = overviewState;
    tokens.forEach((token) => {
      if (
        token.address_bech32 === "zil1qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq9yf6pz"
      ) {
        batchRequests.push(balanceBatchRequest(token, walletAddress));
      } else {
        batchRequests.push(tokenBalanceBatchRequest(token, walletAddress));

        batchRequests.push(tokenAllowancesBatchRequest(token, walletAddress));
      }
    });
    // Zilswap Pool
    batchRequests.push(poolsBatchRequest());
    batchRequests.push(tokenPoolBalanceBatchRequest());
    batchRequests.push(totalContributionsBatchRequest());

    // Carb Swap Pool
    batchRequests.push(carbSwapPoolsBatchRequest());
    batchRequests.push(carbSwapTotalContributionsBatchRequest());
    batchRequests.push(carbSwapPoolBalanceBatchRequest());

    // Huny Vault
    batchRequests.push(hunyVaultPoolBalanceBatchRequest());
    batchRequests.push(hunyVaultTotalContributionsBatchRequest());

    // XCAD DEX
    batchRequests.push(xcadPoolsBatchRequest());
    batchRequests.push(xcadTokenPoolBalanceBatchRequest());
    batchRequests.push(xcadTotalContributionsBatchRequest());

    batchRequests.push(xcadZilPoolsBatchRequest());
    batchRequests.push(xcadZilPoolBalanceBatchRequest());
    batchRequests.push(xcadZilTotalContributionsBatchRequest());

    // ZIL Staking
    batchRequests.push(stakingOperatorsBatchRequest());

    if (operators.length > 0) {
      operators.forEach((operator) => {
        batchRequests.push(
          stakingDelegatorsBatchRequest(operator, walletAddress)
        );
      });
    }

    // XCAD Staking
    xcadStakingHashes.forEach((xcadStakingHash: any) => {
      batchRequests.push(
        xcadStakersBatchRequest(
          fromBech32Address(xcadStakingHash.address),
          xcadStakingHash.name,
          xcadStakingHash.stakeSymbol,
          xcadStakingHash.rewardSymbol
        )
      );
      batchRequests.push(
        xcadPendingRewardsBatchRequest(
          fromBech32Address(xcadStakingHash.address),
          xcadStakingHash.name,
          xcadStakingHash.stakeSymbol,
          xcadStakingHash.rewardSymbol
        )
      );
    });

    // PORT staking
    portStakingHashes.forEach((portStaking: any) => {
      batchRequests.push(
        portStakersBatchRequest(
          walletAddress,
          portStaking.hash,
          portStaking.name,
          portStaking.seedNode
        )
      );
      batchRequests.push(
        portPendingRewardsBatchRequest(walletAddress, portStaking.hash)
      );
    });

    // Carbon staking
    batchRequests.push(carbonStakersBatchRequest(walletAddress));
    batchRequests.push(carbonPendingCarbsBatchRequest(walletAddress));

    // BLOX Staking
    batchRequests.push(bloxStakersBatchRequest(walletAddress));
    batchRequests.push(bloxPendingBloxsBatchRequest(walletAddress));

    // Score staking
    batchRequests.push(scoStakersBatchRequest(walletAddress));

    // Unifees staking
    unifeeStakingHashes.forEach((unifeeStaking: any) => {
      batchRequests.push(
        unifeeStakersBatchRequest(
          walletAddress,
          unifeeStaking.hash,
          unifeeStaking.name,
          unifeeStaking.seedNode
        )
      );
      batchRequests.push(
        unifeePendingRewardsBatchRequest(walletAddress, unifeeStaking.hash)
      );
    });

    // Lunar staking
    lunarStakingHashes.forEach((lunarStaking: any) => {
      batchRequests.push(
        lunarStakersBatchRequest(
          walletAddress,
          lunarStaking.hash,
          lunarStaking.name,
          lunarStaking.seedNode
        )
      );
    });

    // Demonz Staking
    batchRequests.push(demonzStakersBatchRequest());

    // GRPH Staking
    batchRequests.push(grphStakersBatchRequest(walletAddress));
    batchRequests.push(grphPendingRewardsBatchRequest(walletAddress));

    // Oki Staking
    batchRequests.push(okiStakersBatchRequest());
    batchRequests.push(okiPendingRewardsBatchRequest());

    const batchResults = await sendBatchRequest(
      EnumNetwork.MainNet,
      batchRequests
    );

    let walletTokens = Array<IWalletToken>();
    let tokenAllowances = Array<ITokenAllowance>();

    let responsePools = Array<ITokenPool>();
    let xcadResponsePools = Array<ITokenPool>();
    let carbSwapResponsePools = Array<ITokenPool>();
    let hunyResponsePools = Array<ITokenPool>();

    let walletStakingList = Object.values(ssnListMap);
    let carbonStakers = Array<ISsnInfo>();
    let carbonPendingCarbs = Array<IPendingReward>();
    let scoStakers = Array<ISsnInfo>();
    let portStakers = Array<ISsnInfo>();
    let portPendingRewards = Array<IPendingReward>();
    let xcadStakers = Array<ISsnInfo>();
    let xcadPendingRewards = Array<IPendingReward>();
    let okiStakers = Array<ISsnInfo>();
    let okiPendingRewards = Array<IPendingReward>();
    let unifeeStakers = Array<ISsnInfo>();
    let unifeePendingRewards = Array<IPendingReward>();
    let bloxStakers = Array<ISsnInfo>();
    let bloxPendingRewards = Array<IPendingReward>();
    let demonzStakers = Array<ISsnInfo>();
    let grphStakers = Array<ISsnInfo>();
    let grphPendingRewards = Array<IPendingReward>();
    let lunarStakers = Array<ISsnInfo>();

    const hunyToken = tokens.find((token) => token.symbol === "Huny");
    if (hunyToken) {
      const hunyZilReserve = new BigNumber("7611160899266698235");
      const hunyTokenReserve = new BigNumber("2755516462385389138");
      const hunyExchangeRate = hunyZilReserve.dividedBy(hunyTokenReserve);
      hunyResponsePools.push({
        token: hunyToken,
        zilReserve: hunyZilReserve,
        tokenReserve: hunyTokenReserve,
        exchangeRate: hunyExchangeRate,
        totalContribution: new BigNumber(0),
        userContribution: new BigNumber(0),
      });
    }

    batchResults.forEach((result) => {
      if (result.request.type === EnumBatchRequestType.Pools) {
        let pools = result.result.pools;
        Object.keys(pools).forEach((address) => {
          let pool = pools[address];

          const [x, y] = pool.arguments;
          const zilReserve = new BigNumber(x);
          const tokenReserve = new BigNumber(y);
          const exchangeRate = zilReserve.dividedBy(tokenReserve);
          const token = tokens.find(
            (token) => token.address_bech32 === toBech32Address(address)
          );
          if (token) {
            responsePools.push({
              token: token,
              zilReserve: zilReserve,
              tokenReserve,
              exchangeRate,
              totalContribution: new BigNumber(0),
              userContribution: new BigNumber(0),
            });
          }
        });
      } else if (
        result.request.type === EnumBatchRequestType.TotalContributions
      ) {
        let totalContributions = result.result.total_contributions;
        Object.keys(totalContributions).forEach((address) => {
          responsePools = responsePools.map((responsePool) => {
            if (
              responsePool.token.address_bech32 !== toBech32Address(address)
            ) {
              return responsePool;
            }
            return {
              ...responsePool,
              totalContribution: new BigNumber(totalContributions[address]),
            };
          });
        });
        return;
      } else if (result.request.type === EnumBatchRequestType.PoolBalance) {
        if (result.result !== null) {
          let balances = result.result.balances;
          Object.keys(balances).forEach((address) => {
            const walletHash = fromBech32Address(walletAddress).toLowerCase();
            const userDistribution = new BigNumber(
              balances[address][walletHash] || 0
            );
            const poolIndex = responsePools.findIndex(
              (pool) => pool.token.address_bech32 === toBech32Address(address)
            );
            if (poolIndex !== -1) {
              responsePools[poolIndex].userContribution = userDistribution;
            }
          });
        }
      } else if (
        result.request.type === EnumBatchRequestType.StakingDelegators
      ) {
        if (result.result !== null) {
          let ssnDelegators: any[] = result.result.ssn_deleg_amt;
          Object.keys(ssnDelegators).forEach((ssnAddress) => {
            let address: any = ssnAddress;
            let amount: any =
              ssnDelegators[address][
                fromBech32Address(walletAddress).toLowerCase()
              ];
            let staked = new BigNumber(amount);
            walletStakingList = walletStakingList.map((ssn) => {
              if (ssn.address !== address) {
                return { ...ssn };
              }
              return {
                ...ssn,
                stakingBalance: staked.div(Math.pow(10, 12)).toNumber(),
              };
            });
          });
        }
        return;
      } else if (result.request.type === EnumBatchRequestType.PortStakers) {
        const token = tokens.find((token) => token.symbol === "PORT");
        let amount = 0;
        if (result.result !== null) {
          let stakers = result.result.stakers;
          for (const address in stakers) {
            amount = stakers[address];
          }
        }
        let staked = new BigNumber(amount);
        portStakers.push({
          name: result.request.name || "Port",
          logo: Images.port,
          ratio: 0,
          seedNode: result.request.seedNode || 0,
          address: result.request.contractAddress || "",
          stakingBalance: staked
            .div(Math.pow(10, token?.decimals || 4))
            .toNumber(),
          stakeSymbol: "PORT",
        });
      } else if (
        result.request.type === EnumBatchRequestType.PortPendingRewards
      ) {
        if (result.result !== null) {
          let pendingRewards: { [key: string]: string } =
            result.result.pendingRewards;
          const token = tokens.find((token) => token.symbol === "PORT");
          Object.keys(pendingRewards).forEach((address) => {
            let pendingRewardsAmount = new BigNumber(pendingRewards[address]);
            portPendingRewards.push({
              address: result.request.contractAddress,
              pendingReward: pendingRewardsAmount
                .div(Math.pow(10, token?.decimals || 4))
                .toNumber(),
              pendingRewardSymbol: "PORT",
            });
          });
        }
      } else if (result.request.type === EnumBatchRequestType.XcadStakers) {
        if (result.result !== null) {
          let stakers = result.result.stakers_total_bal;
          let stake = {
            name: result.request.name || "XCAD",
            logo: "https://meta.viewblock.io/ZIL.zil1xfcg9hfpdlmz2aytz0s4dww35hfa6s0jnjut5f/logo",
            ratio: 0,
            seedNode: result.request.seedNode || 0,
            address: result.request.contractAddress || "",
            stakingBalance: 0,
            stakeSymbol: result.request.stakeSymbol,
            rewardSymbol: result.request.rewardSymbol,
          };
          const token = tokens.find((token) => token.symbol === "dXCAD");
          Object.entries<string>(stakers).forEach(([key, value]) => {
            if (key === fromBech32Address(walletAddress).toLowerCase()) {
              const staked = new BigNumber(value);
              stake.stakingBalance = staked
                .div(Math.pow(10, token?.decimals || 18))
                .toNumber();
            }
          });
          xcadStakers.push(stake);
        }
      } else if (
        result.request.type === EnumBatchRequestType.XcadPendingRewards
      ) {
        if (result.result !== null) {
          // TODO xcad pending reward
        }
      } else if (result.request.type === EnumBatchRequestType.OkiStakers) {
        if (result.result !== null) {
          let stakers = result.result.stakers;
          let tmp = {
            name: "Okipad",
            logo: "https://meta.viewblock.io/zilliqa.zil18jx2rm9xdct0kx3s94arpyq9yu2lcga4gg8vzr/logo?t=light",
            ratio: 0,
            address: result.request.contractAddress || "",
            stakingBalance: 0,
            stakeSymbol: "Oki",
          };
          const token = tokens.find((token) => token.symbol === "Oki");
          for (const address in stakers) {
            if (address === fromBech32Address(walletAddress).toLowerCase()) {
              let amount = stakers[address];
              let staked = new BigNumber(amount);
              tmp.stakingBalance = staked
                .div(Math.pow(10, token?.decimals || 5))
                .toNumber();
            }
          }
          okiStakers.push(tmp);
        }
      } else if (
        result.request.type === EnumBatchRequestType.OkiPendingRewards
      ) {
        if (result.result !== null) {
          let pendingOkis: { [key: string]: string } =
            result.result.pending_okis;
          let pending = {
            address: result.request.contractAddress,
            pendingReward: 0,
            pendingRewardSymbol: "Oki",
          };
          const token = tokens.find((token) => token.symbol === "Oki");
          Object.keys(pendingOkis).forEach((address) => {
            if (address === fromBech32Address(walletAddress).toLowerCase()) {
              let pendingOkisAmount = new BigNumber(pendingOkis[address]);
              pending.pendingReward = pendingOkisAmount
                .div(Math.pow(10, token?.decimals || 8))
                .toNumber();
            }
          });
          okiPendingRewards.push(pending);
        }
      } else if (result.request.type === EnumBatchRequestType.ScoStakers) {
        if (result.result !== null) {
          let mapWallets = result.result.map_wallets;
          for (const address in mapWallets) {
            scoStakers.push({
              name: "Score",
              logo: "https://meta.viewblock.io/ZIL.zil1kwfu3x9n6fsuxc4ynp72uk5rxge25enw7zsf9z/logo",
              ratio: 0,
              address: result.request.contractAddress || address,
              stakingBalance: 0,
              stakeSymbol: "SCO",
            });
          }
        }
      } else if (result.request.type === EnumBatchRequestType.CarbonStakers) {
        if (result.result !== null) {
          let stakers = result.result.stakers;
          const token = tokens.find((token) => token.symbol === "CARB");
          for (const address in stakers) {
            let amount = stakers[address];
            let staked = new BigNumber(amount);
            carbonStakers.push({
              name: "Carbon",
              logo: Images.carbon,
              ratio: 0,
              address: result.request.contractAddress || address,
              stakingBalance: staked
                .div(Math.pow(10, token?.decimals || 8))
                .toNumber(),
              stakeSymbol: "CARB",
            });
          }
        }
      } else if (
        result.request.type === EnumBatchRequestType.CarbonPendingCarbs
      ) {
        if (result.result !== null) {
          let pendingCarbs: { [key: string]: string } =
            result.result.pending_carbs;
          const token = tokens.find((token) => token.symbol === "CARB");
          Object.keys(pendingCarbs).forEach((address) => {
            let pendingCarbsAmount = new BigNumber(pendingCarbs[address]);
            carbonPendingCarbs.push({
              address: result.request.contractAddress,
              pendingReward: pendingCarbsAmount
                .div(Math.pow(10, token?.decimals || 8))
                .toNumber(),
              pendingRewardSymbol: "CARB",
            });
          });
        }
      } else if (result.request.type === EnumBatchRequestType.UnifeeStakers) {
        const token = tokens.find((token) => token.symbol === "FEES");
        let amount = 0;
        if (result.result !== null) {
          let stakers = result.result.stakers;
          for (const address in stakers) {
            amount = stakers[address];
          }
        }
        let staked = new BigNumber(amount);
        unifeeStakers.push({
          name: result.request.name || "Unifees",
          logo: "https://meta.viewblock.io/zilliqa.zil1jy3g5j9w5njqwxuuv3zwkz9syyueelmu7g080v/logo",
          ratio: 0,
          seedNode: result.request.seedNode || 0,
          address: result.request.contractAddress || "",
          stakingBalance: staked
            .div(Math.pow(10, token?.decimals || 4))
            .toNumber(),
          stakeSymbol: "FEES",
        });
      } else if (
        result.request.type === EnumBatchRequestType.UnifeePendingRewards
      ) {
        if (result.result !== null) {
          let pendingRewards: { [key: string]: string } =
            result.result.pendingRewards;
          const token = tokens.find((token) => token.symbol === "FEES");
          Object.keys(pendingRewards).forEach((address) => {
            let pendingRewardsAmount = new BigNumber(pendingRewards[address]);
            unifeePendingRewards.push({
              address: result.request.contractAddress,
              pendingReward: pendingRewardsAmount
                .div(Math.pow(10, token?.decimals || 4))
                .toNumber(),
              pendingRewardSymbol: "FEES",
            });
          });
        }
      } else if (result.request.type === EnumBatchRequestType.BloxStakers) {
        if (result.result !== null) {
          let stakers = result.result.stakers;
          const token = tokens.find((token) => token.symbol === "BLOX");
          for (const address in stakers) {
            let amount = stakers[address];
            let staked = new BigNumber(amount);
            bloxStakers.push({
              name: "Blox",
              logo: "https://meta.viewblock.io/zilliqa.zil1gf5vxndx44q6fn025fwdaajnrmgvngdzel0jzp/logo",
              ratio: 0,
              address: result.request.contractAddress || address,
              stakingBalance: staked
                .div(Math.pow(10, token?.decimals || 8))
                .toNumber(),
              stakeSymbol: "BLOX",
            });
          }
        }
      } else if (
        result.request.type === EnumBatchRequestType.BloxPendingRewards
      ) {
        if (result.result !== null) {
          let pendingBloxs: { [key: string]: string } =
            result.result.pending_bloxs;
          const token = tokens.find((token) => token.symbol === "BLOX");
          Object.keys(pendingBloxs).forEach((address) => {
            let pendingBloxsAmount = new BigNumber(pendingBloxs[address]);
            bloxPendingRewards.push({
              address: result.request.contractAddress,
              pendingReward: pendingBloxsAmount
                .div(Math.pow(10, token?.decimals || 8))
                .toNumber(),
              pendingRewardSymbol: "BLOX",
            });
          });
        }
      } else if (result.request.type === EnumBatchRequestType.DemonzStakers) {
        if (result.result !== null) {
          let stakers = result.result.stakers_total_bal;
          let staked = new BigNumber(stakers[walletBase16Address]);
          const token = tokens.find((token) => token.symbol === "DMZ");
          demonzStakers.push({
            name: "Demonz",
            logo: token?.icon || "",
            ratio: 0,
            address: result.request.contractAddress || "",
            stakingBalance: staked
              .div(Math.pow(10, token?.decimals || 18))
              .toNumber(),
            stakeSymbol: "DMZ",
          });
        }
      } else if (result.request.type === EnumBatchRequestType.GrphStakers) {
        if (result.result !== null) {
          let stakers = result.result.stakers;
          const token = tokens.find((token) => token.symbol === "GRPH");
          for (const address in stakers) {
            let amount = stakers[address];
            let staked = new BigNumber(amount);
            grphStakers.push({
              name: "GRPH",
              logo: Images.graph,
              ratio: 0,
              address: result.request.contractAddress || address,
              stakingBalance: staked
                .div(Math.pow(10, token?.decimals || 8))
                .toNumber(),
              stakeSymbol: "GRPH",
            });
          }
        }
      } else if (
        result.request.type === EnumBatchRequestType.GrphPendingRewards
      ) {
        if (result.result !== null) {
          let pendingCarbs: { [key: string]: string } =
            result.result.pending_carbs;
          const token = tokens.find((token) => token.symbol === "GRPH");
          Object.keys(pendingCarbs).forEach((address) => {
            let pendingCarbsAmount = new BigNumber(pendingCarbs[address]);
            grphPendingRewards.push({
              address: result.request.contractAddress,
              pendingReward: pendingCarbsAmount
                .div(Math.pow(10, token?.decimals || 8))
                .toNumber(),
              pendingRewardSymbol: "GRPH",
            });
          });
        }
      } else if (result.request.type === EnumBatchRequestType.LunarStakers) {
        const token = tokens.find((token) => token.symbol === "LUNR");
        let amountWithBlock = 0;
        let amount = 0;
        if (result.result !== null) {
          let stakers = result.result.stakes;
          for (const address in stakers) {
            amountWithBlock = stakers[address];
          }
        }
        Object.values(amountWithBlock).forEach((value) => {
          amount += new BigNumber(value).toNumber();
        });
        let staked = new BigNumber(amount);
        lunarStakers.push({
          name: result.request.name || "Lunar",
          logo: "https://meta.viewblock.io/zilliqa.zil1xxl6yp2twxvljdnn87g9fk7wykdrcv66xdy4rc/logo",
          ratio: 0,
          seedNode: result.request.seedNode || 0,
          address: result.request.contractAddress || "",
          stakingBalance: staked
            .div(Math.pow(10, token?.decimals || 4))
            .toNumber(),
          stakeSymbol: "LUNR",
        });
      } else if (result.request.type === EnumBatchRequestType.XcadDexPools) {
        if (result.result !== null) {
          let xpools = result.result.xpools;
          Object.entries<string>(xpools).forEach(([key, value]) => {
            const args = xpools[key].arguments;
            const farmAddress = args[1];
            const poolReserve = new BigNumber(args[2]);
            const tokenReserve = new BigNumber(args[3]);
            const exchangeRate = poolReserve.dividedBy(tokenReserve);
            const token = tokens.find(
              (token) => token.address_bech32 === toBech32Address(farmAddress)
            );
            if (token) {
              xcadResponsePools.push({
                token: token,
                zilReserve: poolReserve,
                tokenReserve,
                exchangeRate,
                totalContribution: new BigNumber(0),
                userContribution: new BigNumber(0),
              });
            }
          });
        }
      } else if (
        result.request.type === EnumBatchRequestType.XcadDexTotalContribution
      ) {
        if (result.result !== null) {
          let xtotalContributions = result.result.xtotal_contributions;
          Object.entries<any>(xtotalContributions).forEach(([key, value]) => {
            const keys = key.split(",");
            const poolAddress = keys[0];
            const farmAddress = keys[1];
            const totalContribution = value[poolAddress];
            const poolIndex = xcadResponsePools.findIndex(
              (xcadResponsePool) =>
                xcadResponsePool.token.address_bech32 ===
                toBech32Address(farmAddress)
            );
            if (poolIndex >= 0 && totalContribution) {
              xcadResponsePools[poolIndex].totalContribution = new BigNumber(
                totalContribution
              );
            }
          });
        }
      } else if (
        result.request.type === EnumBatchRequestType.XcadDexPoolsBalance
      ) {
        if (result.result !== null) {
          const xbalances = result.result.xbalances;
          Object.entries<any>(xbalances).forEach(([key, value]) => {
            const keys = key.split(",");
            const poolAddress = keys[0];
            const farmAddress = keys[1];
            const userContribution = value[poolAddress][walletBase16Address];
            const poolIndex = xcadResponsePools.findIndex(
              (xcadResponsePool) =>
                xcadResponsePool.token.address_bech32 ===
                toBech32Address(farmAddress)
            );
            if (poolIndex >= 0 && userContribution) {
              xcadResponsePools[poolIndex].userContribution = new BigNumber(
                userContribution
              );
            }
          });
        }
      } else if (result.request.type === EnumBatchRequestType.XcadZilPools) {
        if (result.result !== null) {
          let pools = result.result.pools;
          Object.entries<string>(pools).forEach(([key, value]) => {
            const args = pools[key].arguments;
            const farmAddress = key;
            const poolReserve = new BigNumber(args[0]);
            const tokenReserve = new BigNumber(args[1]);
            const exchangeRate = poolReserve.dividedBy(tokenReserve);
            const token = tokens.find(
              (token) => token.address_bech32 === toBech32Address(farmAddress)
            );
            if (token) {
              xcadResponsePools.push({
                token: token,
                zilReserve: poolReserve,
                tokenReserve,
                exchangeRate,
                totalContribution: new BigNumber(0),
                userContribution: new BigNumber(0),
                isSpecial: true,
              });
            }
          });
        }
      } else if (
        result.request.type === EnumBatchRequestType.XcadZilTotalContribution
      ) {
        let totalContributions = result.result.total_contributions;
        Object.entries<any>(totalContributions).forEach(([key, value]) => {
          const farmAddress = key;
          const totalContribution = value;
          const poolIndex = xcadResponsePools.findIndex(
            (xcadResponsePool) =>
              xcadResponsePool.token.address_bech32 ===
                toBech32Address(farmAddress) && xcadResponsePool.isSpecial
          );
          if (poolIndex >= 0 && totalContribution) {
            xcadResponsePools[poolIndex].totalContribution = new BigNumber(
              totalContribution
            );
          }
        });
      } else if (
        result.request.type === EnumBatchRequestType.XcadZilPoolsBalance
      ) {
        if (result.result !== null) {
          const balances = result.result.balances;
          Object.entries<any>(balances).forEach(([key, value]) => {
            const farmAddress = key;
            const userContribution = value[walletBase16Address];
            const poolIndex = xcadResponsePools.findIndex(
              (xcadResponsePool) =>
                xcadResponsePool.token.address_bech32 ===
                  toBech32Address(farmAddress) && xcadResponsePool.isSpecial
            );
            if (poolIndex >= 0 && userContribution) {
              xcadResponsePools[poolIndex].userContribution = new BigNumber(
                userContribution
              );
            }
          });
        }
      } else if (result.request.type === EnumBatchRequestType.CarbSwapPools) {
        if (result.result !== null) {
          let pools = result.result.pools;

          Object.entries<string>(pools).forEach(([key, value]) => {
            const args = pools[key].arguments;
            const farmAddress = key;
            const poolReserve = new BigNumber(args[0]);
            const tokenReserve = new BigNumber(args[1]);
            const exchangeRate = poolReserve.dividedBy(tokenReserve);
            const token = tokens.find(
              (token) => token.address_bech32 === toBech32Address(farmAddress)
            );
            if (token) {
              carbSwapResponsePools.push({
                token: token,
                zilReserve: poolReserve,
                tokenReserve,
                exchangeRate,
                totalContribution: new BigNumber(0),
                userContribution: new BigNumber(0),
              });
            }
          });
        }
      } else if (
        result.request.type === EnumBatchRequestType.CarbSwapPoolsBalance
      ) {
        if (result.result !== null) {
          const balances = result.result.balances;
          Object.entries<any>(balances).forEach(([key, value]) => {
            const farmAddress = key;
            const userContribution = value[walletBase16Address];
            const poolIndex = carbSwapResponsePools.findIndex(
              (carbSwapResponsePool) =>
                carbSwapResponsePool.token.address_bech32 ===
                toBech32Address(farmAddress)
            );
            if (poolIndex >= 0 && userContribution) {
              carbSwapResponsePools[poolIndex].userContribution = new BigNumber(
                userContribution
              );
            }
          });
        }
      } else if (
        result.request.type ===
        EnumBatchRequestType.CarbSwapPoolTotalContributions
      ) {
        let totalContributions = result.result.total_contributions;

        Object.entries<any>(totalContributions).forEach(([key, value]) => {
          const farmAddress = key;
          const totalContribution = value;
          const poolIndex = carbSwapResponsePools.findIndex(
            (carbSwapResponsePool) =>
              carbSwapResponsePool.token.address_bech32 ===
              toBech32Address(farmAddress)
          );
          if (poolIndex >= 0 && totalContribution) {
            carbSwapResponsePools[poolIndex].totalContribution = new BigNumber(
              totalContribution
            );
          }
        });
      } else if (
        result.request.type ===
        EnumBatchRequestType.HunyVaultPoolTotalContributions
      ) {
        let totalContribution = result.result.total_contribution;
        hunyResponsePools[0].totalContribution = new BigNumber(
          totalContribution
        );
      } else if (
        result.request.type === EnumBatchRequestType.HunyVaultPoolsBalance
      ) {
        if (result.result !== null) {
          const balances = result.result.balances;
          Object.entries<any>(balances).forEach(([key, value]) => {
            if (key.toLowerCase() === walletBase16Address) {
              hunyResponsePools[0].userContribution = new BigNumber(value);
            }
          });
        }
      }

      if (!result.request.token) {
        return;
      }

      const token: IToken = result.request.token;
      switch (result.request.type) {
        // Zillqa balance
        case EnumBatchRequestType.Balance:
          walletTokens.push({
            token: token,
            balance: new BigNumber(result.result.balance)
              .div(Math.pow(10, token.decimals))
              .toNumber(),
          });
          break;

        // Token balance
        case EnumBatchRequestType.TokenAllowance:
          if (result.result?.allowances) {
            const allowances =
              result.result.allowances[
                fromBech32Address(walletAddress).toLowerCase()
              ];
            tokenAllowances.push({
              token: token,
              allowances: allowances,
            });
          }
          break;

        // Token balance
        case EnumBatchRequestType.TokenBalance:
          let balance: BigNumber = new BigNumber(0);

          if (result.result) {
            balance = bnOrZero(result.result.balances[walletBase16Address]);
          }

          walletTokens.push({
            token: token,
            balance: balance.div(Math.pow(10, token.decimals)).toNumber(),
          });
          break;
      }
    });
    dispatch(updateWalletStakingList(walletStakingList));
    dispatch(updatePools(responsePools));
    dispatch(updateXcadPool(xcadResponsePools));
    dispatch(updateCarbSwapPool(carbSwapResponsePools));
    dispatch(updateHunyPool(hunyResponsePools));
    dispatch(updateWalletTokens(walletTokens));
    dispatch(updateTokenAllowances(tokenAllowances));

    // ZIL APY
    const stakeRewardState = await apiGetStakingRewardState();
    let zilApy = 0;
    if (stakeRewardState && stakeRewardState["success"]) {
      const state = stakeRewardState["success"];
      zilApy = state["apy"];
    }

    // SCO STAKING
    let scoPendingRewards = [] as IPendingReward[];
    const scoReward = await apiGetScoRewards(currentWallet.zilAddress);
    scoPendingRewards.push({
      address: scoStakingHash,
      pendingReward: scoReward,
      pendingRewardSymbol: "SCO",
    });

    if (scoStakers.length > 0) {
      scoStakers[0].stakingBalance =
        walletTokens.find((token) => token.token.symbol === "SCO")?.balance ||
        0;
    }

    // ZIL STAKING REWARD
    let zilPendingRewards = Array<IPendingReward>();
    for (let i = 0; i < walletStakingList.length; i++) {
      const stakingWallet = walletStakingList[i];
      //@ts-ignore
      const { address, stakingBalance } = stakingWallet;
      if (stakingBalance && stakingBalance > 0) {
        const rewardResult = await fetchReward(
          address,
          currentWallet.zilAddress
        );
        const rewardNumber = parseFloat(rewardResult.toString());
        if (!isNaN(rewardNumber) && rewardNumber > 0) {
          zilPendingRewards.push({
            address: address,
            pendingReward: rewardNumber * Math.pow(10, -12),
            pendingRewardSymbol: "ZIL",
          });
        }
      }
    }

    dispatch(
      updateStakingList([
        {
          name: "Zillion",
          link: "stake.zilliqa.com",
          stakeLogo: Images.zil,
          stakeList: sortStakingList(walletStakingList),
          pendingRewards: zilPendingRewards,
          apy: zilApy,
          symbol: "ZIL",
          subName: "stake.zilliqa.com",
          unstakingPending: [],
        },
        {
          name: "Carbon",
          link: "staking.carbontoken.info",
          stakeLogo: Images.carbon,
          stakeList: carbonStakers,
          apy: 15,
          symbol: "CARB",
          pendingRewards: carbonPendingCarbs,
          subName: "staking.carbontoken.info",
          unstakingPending: [],
        },
        {
          name: "GRPH",
          link: "grph-staking.carb.tools/#/0xff4a3d18df5dd4719b77e5baf6a31ed8533b54d4",
          stakeLogo: Images.graph,
          stakeList: grphStakers,
          apy: 100,
          symbol: "GRPH",
          pendingRewards: grphPendingRewards,
          subName: "grph-staking.carb.tools",
          unstakingPending: [],
        },
        {
          name: "Score",
          link: "stake.uffsports.com",
          stakeLogo:
            "https://meta.viewblock.io/ZIL.zil1kwfu3x9n6fsuxc4ynp72uk5rxge25enw7zsf9z/logo",
          stakeList: scoStakers,
          apy: 35,
          symbol: "SCO",
          pendingRewards: scoPendingRewards,
          subName: "stake.uffsports.com",
          unstakingPending: [],
        },
        {
          name: "Port",
          link: "crypto.packageportal.com/#/staking",
          stakeLogo: Images.port,
          stakeList: portStakers,
          apy: "7% - 19",
          symbol: "PORT",
          pendingRewards: portPendingRewards,
          subName: "crypto.packageportal.com",
          unstakingPending: [],
        },
        {
          name: "Okimoto",
          link: "okimoto.io/okipad/",
          stakeLogo:
            "https://meta.viewblock.io/zilliqa.zil18jx2rm9xdct0kx3s94arpyq9yu2lcga4gg8vzr/logo?t=light",
          stakeList: okiStakers,
          apy: 0,
          symbol: "Oki",
          pendingRewards: okiPendingRewards,
          subName: "okimoto.io",
          unstakingPending: [],
        },
        {
          name: "XCAD",
          link: "swap.xcadnetwork.com/pool-overview/",
          stakeLogo:
            "https://meta.viewblock.io/ZIL.zil1xfcg9hfpdlmz2aytz0s4dww35hfa6s0jnjut5f/logo",
          stakeList: sortStakingList(xcadStakers),
          apy: 0,
          symbol: "dXCAD",
          pendingRewards: xcadPendingRewards,
          subName: "swap.xcadnetwork.com",
          unstakingPending: [],
        },
        {
          name: "Unifees",
          link: "unifees.io/stake/",
          stakeLogo:
            "https://meta.viewblock.io/zilliqa.zil1jy3g5j9w5njqwxuuv3zwkz9syyueelmu7g080v/logo",
          stakeList: unifeeStakers,
          apy: "8% - 30",
          symbol: "FEES",
          pendingRewards: unifeePendingRewards,
          subName: "unifees.io",
          unstakingPending: [],
        },
        {
          name: "Blox",
          link: "blox-sdk.com/staking",
          stakeLogo:
            "https://meta.viewblock.io/zilliqa.zil1gf5vxndx44q6fn025fwdaajnrmgvngdzel0jzp/logo",
          stakeList: bloxStakers,
          apy: 0,
          symbol: "BLOX",
          pendingRewards: bloxPendingRewards,
          subName: "blox-sdk.com",
          unstakingPending: [],
        },
        {
          name: "DMZ",
          link: "demons.world/",
          stakeLogo:
            "https://meta.viewblock.io/zilliqa.zil19lr3vlpm4lufu2q94mmjvdkvmx8wdwajuntzx2/logo",
          stakeList: demonzStakers,
          apy: 0,
          symbol: "DMZ",
          pendingRewards: [],
          subName: "demons.world",
          unstakingPending: [],
        },
        {
          name: "Lunr",
          link: "lunarcrush.com/account/lunrfi/addstake",
          stakeLogo:
            "https://meta.viewblock.io/zilliqa.zil1xxl6yp2twxvljdnn87g9fk7wykdrcv66xdy4rc/logo",
          stakeList: lunarStakers,
          apy: "8% - 25",
          symbol: "LUNR",
          pendingRewards: [],
          subName: "lunarcrush.com",
          unstakingPending: [],
        },
      ])
    );
  } catch (error) {
    // TODO: Do nothing? or display error dialog?
  } finally {
    dispatch(setLoading(false));
  }
};

export const syncClaimableData = (): AppThunk => async (dispatch, getState) => {
  try {
    const loginState: ILoginState = loginSelector(getState());
    const overviewState: IOverviewState = overviewSelector(getState());
    const { tokens } = overviewState;
    const { currentWallet } = loginState;
    if (!currentWallet) {
      return;
    }

    const distributors = await apiGetDistributionInfo();
    const claimableData = await apiGetZilSwapClaimableData(
      currentWallet.zilAddress
    );
    const estimatedReward = await apiGetZilswapEstimateReward(
      currentWallet.zilAddress,
      tokens
    );
    const distributions = await apiGetDistributionsWithStatus(claimableData);

    dispatch(updateZwapEstimatedReward(estimatedReward));
    dispatch(updateDistributors(distributors));
    dispatch(updateDistributions(distributions));
    dispatch(updateClaimableData(claimableData));
  } catch (error) {
    // TODO: Do nothing? or display error dialog?
  } finally {
    // TMP: dispatch(setLoading(false));
  }
};

export const syncZilPriceInUsd = (): AppThunk => async (dispatch, getState) => {
  try {
    const priceInUsd = await apiGetZilPriceInUsd();
    dispatch(updateZilPriceInUsd(priceInUsd));
  } catch (error) {
    // TODO: Do nothing? or display error dialog?
  } finally {
    // TMP: dispatch(setLoading(false));
  }
};

export const syncHeyAlfieNfts = (): AppThunk => async (dispatch, getState) => {
  try {
    const loginState: ILoginState = loginSelector(getState());
    const { currentWallet } = loginState;
    if (currentWallet) {
      const heyAlfieNfts = await getHeyAlfieNfts(
        currentWallet.zilAddressBase16
      );
      dispatch(updateHeyAlfieNfts(heyAlfieNfts));
    }
  } catch (error) {}
};

export const syncCollectionsData =
  (): AppThunk => async (dispatch, getState) => {
    try {
      const loginState: ILoginState = loginSelector(getState());
      const { currentWallet } = loginState;
      if (currentWallet) {
        const collections = await getCollections(currentWallet.zilAddress);
        dispatch(updateCollections(collections));
      }
    } catch (error) {}
  };

export const syncXcadClaimableData =
  (): AppThunk => async (dispatch, getState) => {
    try {
      const loginState: ILoginState = loginSelector(getState());
      const overviewState: IOverviewState = overviewSelector(getState());
      const { tokens } = overviewState;
      const { currentWallet } = loginState;
      if (currentWallet) {
        const xcadDistributors = await apiGetXcadDistributor();
        const xcadEstimatedReward = await apiGetXcadEstimatedReward(
          currentWallet.zilAddress,
          tokens
        );

        const claimableData = await apiGetXcadClaimable(
          currentWallet.zilAddress
        );

        dispatch(updateXcadEstimatedReward(xcadEstimatedReward));
        dispatch(updateXcadClaimableData(claimableData));
        dispatch(updateXcadDistributors(xcadDistributors));
        dispatch(
          updateXcadDistributions(
            claimableData.map((claimable) => {
              return {
                info: {
                  distrAddr: claimable.distributor_address,
                  epochNumber: claimable.epoch_number,
                  proof: claimable.proof.split(" "),
                  amount: claimable.amount,
                  id: claimable.id,
                },
                readyToClaim: true,
              };
            })
          )
        );
      }
    } catch (error) {}
  };

export const syncCarbSwapClaimableData =
  (): AppThunk => async (dispatch, getState) => {
    try {
      const loginState: ILoginState = loginSelector(getState());
      const overviewState: IOverviewState = overviewSelector(getState());
      const { tokens } = overviewState;
      const { currentWallet } = loginState;
      if (currentWallet) {
        const reward = await carbSwapTotalReward(currentWallet?.zilAddress);
        const graph = tokens.find((token) => token.symbol === "GRPH");
        if (graph) {
          const result = new BigNumber(reward)
            .div(Math.pow(10, graph.decimals))
            .toNumber();
          dispatch(
            updateCarbSwapEstimatedReward([
              {
                estimateReward: result,
                estimateRewardSymbol: "GRPH",
              },
            ])
          );
        }
        const claimableData = await carbSwapClaimable(currentWallet.zilAddress);
        dispatch(updateCarbSwapClaimableData(claimableData));
      }
    } catch (error) {}
  };

export const clearData = (): AppThunk => async (dispatch, getState) => {
  try {
    const overviewState: IOverviewState = overviewSelector(getState());
    const { tokens } = overviewState;
    const cleanWalletTokens = tokens.map((token) => {
      return {
        token: token,
        balance: 0,
        priceInZil: 0,
        lpZilAmount: 0,
        lpZrcTokenAmount: 0,
      };
    });
    const cleanPools = tokens.map((token) => {
      return {
        token: token,
        zilReserve: new BigNumber(0),
        tokenReserve: new BigNumber(0),
        exchangeRate: new BigNumber(0),
        totalContribution: new BigNumber(0),
        userContribution: new BigNumber(0),
        contributionPercentage: new BigNumber(0),
      };
    });
    const cleanTokenPools = Array<ITokenPool>();
    const cleanWalletStakingList = Array<ISsnInfo>();
    const cleanClaimableData = Array<IClaimableData>();
    const cleanStakingList = Array<IStakingInfo>();
    const cleanDistribution = Array<DistributionWithStatus>();
    const cleanCollections = Array<ICollection>();
    const cleanXcadEstimatedReward = Array<IEstimateReward>();

    dispatch(updateStakingList(cleanStakingList));
    dispatch(updateWalletTokens(cleanWalletTokens));
    dispatch(updatePools(cleanPools));
    dispatch(updateXcadPool(cleanTokenPools));
    dispatch(updateDistributions(cleanDistribution));
    dispatch(updateXcadClaimableData(cleanClaimableData));
    dispatch(updateWalletStakingList(cleanWalletStakingList));
    dispatch(updateClaimableData(cleanClaimableData));
    dispatch(updateCollections(cleanCollections));
    dispatch(updateXcadEstimatedReward(cleanXcadEstimatedReward));
    dispatch(updateCarbSwapPool(cleanTokenPools));
  } catch (error) {
    // TODO: Do nothing? or display error dialog?
  } finally {
    // TMP: dispatch(setLoading(false));
  }
};

export const overviewSelector = (state: RootState) => state.overview;

export const loginSelector = (state: RootState) => state.login;

export default overviewSlice.reducer;
