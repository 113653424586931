import React from "react";
import {
  FormControl,
  IconButton,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  useDisclosure,
  useMergeRefs,
  Button,
  Stack,
} from "@chakra-ui/react";
import { HiEye, HiEyeOff } from "react-icons/hi";
import { useAppColor } from "src/hooks";
import { useTranslation } from "react-i18next";

export const PasswordField = React.forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => {
    const inputRef = React.useRef<HTMLInputElement>(null);
    const mergeRef = useMergeRefs(inputRef, ref);
    const { t } = useTranslation(["common", "login"]);
    const { main1, main6 } = useAppColor();
    const { isOpen, onToggle } = useDisclosure();

    const onClickReveal = () => {
      onToggle();
      const input = inputRef.current;
      if (input) {
        input.focus({ preventScroll: true });
        const length = input.value.length * 2;
        requestAnimationFrame(() => {
          input.setSelectionRange(length, length);
        });
      }
    };

    return (
      <Stack direction={{ base: "row", md: "row" }} spacing="2">
        <FormControl id="password">
          <InputGroup>
            <InputRightElement>
              <IconButton
                color={main6}
                bg="transparent !important"
                variant="ghost"
                aria-label={isOpen ? "Mask password" : "Reveal password"}
                icon={isOpen ? <HiEye /> : <HiEyeOff />}
                onClick={onClickReveal}
              />
            </InputRightElement>
            <Input
              placeholder={t("login:placeholder")}
              ref={mergeRef}
              name="password"
              type={isOpen ? "password" : "text"}
              autoComplete="current-password"
              required
              {...props}
              bg={main1}
            />
          </InputGroup>
        </FormControl>
        <Button type="submit" minW="24">
          {t("track")}
        </Button>
      </Stack>
    );
  }
);
