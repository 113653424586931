import { Box, Flex, Grid, Text, useDisclosure } from "@chakra-ui/react";
import React, { memo } from "react";
import { PageLayout } from "src/templates";
import { useTranslation } from "react-i18next";
import { useAppColor } from "src/hooks";
import MembershipItem from "./Component/MembershipItem";
import PremiumContentPage from "./Component/PremiumContent";
import { PopupLearnMore } from "./Component/PopupLearnMore";
import useModal from "src/hooks/useModal";

interface MembershipPageProps {
  title: string;
}

export const MembershipPage: React.FC<MembershipPageProps> = memo(
  ({ title }) => {
    const { t } = useTranslation(["membership", "common", "learnMorePremium"]);
    const { brand, main2, text2 } = useAppColor();
    const { onCloseModalPremium } = useModal();

    const _onClickReadyPremium = () => {};
    const _onGetOneNow = () => {};

    //     const [isMemberPremium, setIsMemberPremium] = React.useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();

    const data_membership = [
      {
        title: t("title_content1"),
        description: t("details_content1"),
        isChecked: true,
      },
      {
        title: t("title_content2"),
        description: t("details_content2"),
        isChecked: true,
      },
      {
        title: t("title_content3"),
        description: t("details_content3"),
        isChecked: true,
      },
      {
        title: t("title_content4"),
        description: t("details_content4"),
        isChecked: false,
      },
    ];
    React.useEffect(() => {
      onCloseModalPremium();
    }, [onCloseModalPremium]);

    return (
      <PageLayout title={title} w="100%">
        <Box position="relative" w="100%" alignItems={"center"}>
          <Flex
            mt="8"
            borderRadius="20px"
            bgGradient="linear(to-r,#01FFB0, #01FFB005)"
            padding="1"
            mr={{ base: "unset", xl: "15%" }}
            flexDirection="column"
            w="100%"
            alignItems={"center"}
          >
            <Box
              borderRadius="20px"
              alignItems={"center"}
              w="100%"
              bg={main2}
              pt={{ base: "4", md: "6", lg: "8" }}
              px={{ base: "4", md: "8", lg: "10" }}
              pb={{ base: "3", md: "10", lg: "10" }}
              justifyContent={"space-between"}
            >
              <Flex justify={"center"} w="100%" alignItems={"center"}>
                <Box mt={1} alignItems={"center"} w="100%">
                  <Box
                    w="100%"
                    textAlign={{
                      base: "center",
                      xl: "unset",
                      lg: "unset",
                      md: "center",
                      sm: "center",
                    }}
                  >
                    <Text
                      fontSize="48px"
                      lineHeight="100%"
                      fontWeight="bold"
                      color={text2}
                    >
                      {t("getMore")} ―
                    </Text>
                    <Text
                      fontSize="48px"
                      lineHeight="100%"
                      fontWeight="bold"
                      color={brand}
                      mb={"16px"}
                    >
                      {t("goPremium")}
                    </Text>
                    <Text
                      alignSelf={"center"}
                      fontWeight={"normal"}
                      fontSize={"16px"}
                      lineHeight={"24px"}
                    >
                      {t("description")}
                    </Text>
                  </Box>
                  <PremiumContentPage
                    mt={4}
                    progressValue={25}
                    _onLearnMore={onOpen}
                    _onClickGetOneNow={_onGetOneNow}
                    _onClickReadyPremium={_onClickReadyPremium}
                    isMemberPremium={false}
                    display={{
                      base: "block",
                      lg: "none",
                      xl: "none",
                      md: "block",
                      sm: "block",
                    }}
                  />
                  <Grid
                    templateColumns={{
                      base: "repeat(1, 1fr)",
                      sm: "repeat(2, 1fr)",
                    }}
                    gap="9"
                    h="fit-content"
                    mb={"10"}
                    mt={"9"}
                  >
                    {data_membership.map((item, index) => {
                      return (
                        index < 4 && <MembershipItem key={index} {...item} />
                      );
                    })}
                  </Grid>
                </Box>
                <PremiumContentPage
                  _onLearnMore={onOpen}
                  _onClickGetOneNow={_onGetOneNow}
                  _onClickReadyPremium={_onClickReadyPremium}
                  isMemberPremium={false}
                  progressValue={25}
                  display={{
                    base: "none",
                    lg: "block",
                    md: "none",
                    sm: "none",
                    xl: "block",
                  }}
                  ml={"6"}
                />
              </Flex>
            </Box>
          </Flex>
        </Box>
        <PopupLearnMore isOpen={isOpen} onClose={onClose} />
      </PageLayout>
    );
  }
);

export default MembershipPage;
