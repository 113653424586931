import React from "react";
import { useAppSelector } from "src/redux/hooks";
import { isEmptyDeposit, sortSavedWallet } from "src/utils";
import { loginSelector } from "src/redux/slices/loginSlice";
import HeaderWallet from "../HeaderWallet";
import StakingToken from "./StakingToken";
import BalanceMinify from "../BalanceMinify";
import { overviewSelector } from "src/redux/slices/overviewSlice";

export type StakingBalanceProps = {
  active?: boolean;
};

export const StakingBalance: React.FC<StakingBalanceProps> = ({ active }) => {
  const { savedWallets } = useAppSelector(loginSelector);
  const { tokenRates } = useAppSelector(overviewSelector);

  return (
    <>
      {sortSavedWallet(savedWallets).map((savedWallet, index) => {
        const { allStakingList } = savedWallet;
        let tmpTotalStaking = 0;
        let tmpHoldingStakingIcon = [] as string[];

        allStakingList &&
          allStakingList.forEach((stakingList) => {
            if (!isEmptyDeposit(stakingList)) {
              tmpHoldingStakingIcon.push(stakingList.stakeLogo);
            }
            stakingList.stakeList.forEach((stake) => {
              const stakeRate =
                tokenRates.find(
                  (rate) => rate.token.symbol === stake.stakeSymbol
                )?.rate || 1;
              let balance = stake.stakingBalance || 0;
              tmpTotalStaking += balance * stakeRate;
            });
          });

        return (
          <HeaderWallet
            key={index}
            children={
              <StakingToken active={active} savedWallet={savedWallet} />
            }
            minifyChild={
              <BalanceMinify
                balance={tmpTotalStaking}
                holdings={tmpHoldingStakingIcon}
              />
            }
            savedWallet={savedWallet}
          />
        );
      })}
    </>
  );
};

export default StakingBalance;
