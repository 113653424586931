import React from "react";
import { Box, BoxProps } from "@chakra-ui/layout";
import { useAppColor } from "src/hooks";

interface ScrollBoxProps extends BoxProps {}

const ScrollBox: React.FC<ScrollBoxProps> = ({ ...props }) => {
  const { theme } = useAppColor();
  return (
    <Box
      overflow="auto"
      css={{
        "&::-webkit-scrollbar": {
          width: "4px",
        },
        "&::-webkit-scrollbar-track": {
          width: "6px",
        },
        "&::-webkit-scrollbar-thumb": {
          background: theme === "light" ? "#CBD5E0" : "#1A202C",
          borderRadius: "6px",
        },
      }}
      {...props}
    ></Box>
  );
};

export default ScrollBox;
