import React from "react";
import { Box, Text, Image, Flex } from "@chakra-ui/react";
import {
  autoConnectPreviousWallet,
  loginSelector,
} from "src/redux/slices/loginSlice";
import { useTranslation } from "react-i18next";
import { useAppColor, useRouter } from "src/hooks";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";

import LoginForm from "./components/LoginForm";
import { PageLayout } from "src/templates";
import { ConnectWallet } from "src/atoms";

import { Images } from "src/assets/images";

export const LoginPage: React.FC = () => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const loginState = useAppSelector(loginSelector);
  const { t } = useTranslation("login");
  const { text2, text3 } = useAppColor();

  const handleCreateZilpayWallet = React.useCallback(() => {
    const url = "https://zilpay.io/";
    window.open(url, "_blank", "noreferrer");
  }, []);

  React.useEffect(() => {
    dispatch(autoConnectPreviousWallet());
  }, [dispatch]);

  React.useEffect(() => {
    const { currentWallet } = loginState;
    if (currentWallet) {
      router.push("/profile");
    }
  }, [loginState, router]);

  return (
    <PageLayout
      display="flex"
      flexDirection="column"
      flex="1"
      withoutHeader={true}
    >
      <Image
        src={Images.wallet}
        mx="auto"
        h="80px"
        mt={{ base: "10", lg: "20" }}
      />
      <Text
        textAlign="center"
        fontSize={{ base: "3xl", lg: "4xl" }}
        lineHeight={{ base: "9", md: "10" }}
        fontWeight="extrabold"
        mt="6"
        color={text2}
      >
        {t("title")}
      </Text>
      <Box
        align="center"
        mt={{ base: "4", lg: "6" }}
        fontSize={{ base: "sm", lg: "md" }}
        lineHeight={{ base: "5", md: "6" }}
        color={text3}
      >
        <Text>{t("description1")}</Text>
        <Text>{t("description2")}</Text>
      </Box>
      <Box w="full" maxW="lg" mx="auto" mt={{ base: "6", lg: "8" }}>
        <LoginForm />
      </Box>
      <Text
        align="center"
        fontWeight="extrabold"
        fontSize="xl"
        lineHeight="7"
        color={text2}
        mt={{ base: "8", lg: "12" }}
      >
        {t("connectWallet")}
      </Text>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        mt={{ base: "4", lg: "6" }}
        spacing="20px"
        wrap="wrap"
        flexWrap="wrap"
      >
        <ConnectWallet mx="6px" mb={{ base: "3", sm: "unset" }} />
      </Box>
      <Flex flex={{ base: "1", lg: "unset" }} />
      <Box align="center" mt={{ base: "8", lg: "12" }} color={text2}>
        <Text>
          {t("footer1")}
          <Text
            as="a"
            href="#"
            color={text2}
            textDecorationLine="underline"
            fontWeight="bold"
            onClick={handleCreateZilpayWallet}
          >
            {t("footer2")}
          </Text>
        </Text>
      </Box>
    </PageLayout>
  );
};
export default LoginPage;
