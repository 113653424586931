import { createAvatar } from "@dicebear/avatars";
import * as style from "@dicebear/avatars-jdenticon-sprites";
// import { apiGetAvatarFromAddress } from "src/api/avatarApi";

const generateAvatarUrl = async (address: string) => {
  // const avatar = await apiGetAvatarFromAddress(address);
  // if (avatar && avatar.length > 0) {
  //   return avatar[0];
  // }
  let svg = createAvatar(style, {
    seed: `${address}`,
  });
  return `data:image/svg+xml;utf8,${encodeURIComponent(svg)}`;
};

export default generateAvatarUrl;
