import React from "react";
import {
  Box,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Flex,
  Center,
  Text,
  useBoolean,
  Button,
} from "@chakra-ui/react";
import { IconFilledArrow } from "src/assets/icons";
import { Images } from "src/assets/images";
import { ButtonUnlock, TokenLogo } from "src/atoms";
import { useAppColor } from "src/hooks";
import { useAppSelector } from "src/redux/hooks";
import { IWalletToken, overviewSelector } from "src/redux/slices/overviewSlice";
import { loginSelector } from "src/redux/slices/loginSlice";
import { ITokenRate } from "src/constants/interfaces";
import { formatToken, formatUsdt } from "src/lib/formatCurrency";
import Wallet from "./Wallet";
import { useTranslation } from "react-i18next";
import { handleGoLink } from "src/utils";
export interface IWalletTokenRow {
  walletToken: IWalletToken;
  tokenRate: ITokenRate;
}

export type WalletTokenProps = {};

export const WalletToken: React.FC<WalletTokenProps> = () => {
  const { t } = useTranslation(["overview", "common"]);
  const { text3, text5, text6, main1, main3 } = useAppColor();
  const [show, setShow] = useBoolean(false);

  const { zilPriceInUsd, walletTokens, tokenRates } =
    useAppSelector(overviewSelector);

  const { exchangeRate, isHidden } = useAppSelector(loginSelector);
  const [totalZil, setTotalZil] = React.useState<number>(0);
  const [walletTokenRows, setWalletTokenRows] =
    React.useState<IWalletTokenRow[]>();

  React.useEffect(() => {
    let walletTokenRows = Array<IWalletTokenRow>();
    let totalZil = 0;
    walletTokens.forEach((walletToken) => {
      // if (!walletToken || !walletToken.balance || walletToken.balance <= 0) {
      //   return;
      // }

      if (!walletToken.token.listed) {
        return;
      }

      const tokenRate = tokenRates.find(
        (tokenRate) =>
          tokenRate.token.address_bech32 === walletToken.token.address_bech32
      );
      if (tokenRate) {
        if (
          walletToken.token.address_bech32 ===
          "zil1qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq9yf6pz"
        ) {
          totalZil += walletToken.balance;
          walletTokenRows.push({
            walletToken,
            tokenRate: {
              token: tokenRate.token,
              rate: 1,
            },
          });
        } else {
          totalZil += walletToken.balance * tokenRate.rate;
          walletTokenRows.push({
            walletToken,
            tokenRate,
          });
        }
      }
    });

    walletTokenRows.sort(
      (a, b) =>
        b.walletToken.balance * b.tokenRate.rate -
          a.walletToken.balance * a.tokenRate.rate ||
        (a.walletToken.token.name.toLowerCase() <
        b.walletToken.token.name.toLowerCase()
          ? -1
          : a.walletToken.token.name.toLowerCase() >
            b.walletToken.token.name.toLowerCase()
          ? 1
          : 0)
    );

    setWalletTokenRows(walletTokenRows);
    setTotalZil(totalZil);
  }, [tokenRates, walletTokens]);

  const isEmptyWalletTokens = () => {
    let data = walletTokens.filter((item) => item.balance > 0);
    return data && data.length === 0;
  };

  return (
    <Box>
      {isEmptyWalletTokens() ? (
        // <Box
        //   display="flex"
        //   justifyContent="center"
        //   alignItems="center"
        //   h="312px"
        //   bg={main1}
        //   borderRadius="12px"
        //   borderWidth="1px"
        //   fontSize="lg"
        //   lineHeight="7"
        //   borderColor={main3}
        //   color={text6}
        // >
        // {t("noneToken")}
        // </Box>
        <Center p="1">
          {isHidden ? (
            <ButtonUnlock />
          ) : (
            <Button
              children={t("buyToken")}
              onClick={() => handleGoLink("https://zilswap.io/")}
            />
          )}
        </Center>
      ) : (
        <>
          <Box
            display={{ base: "none", lg: "block" }}
            overflow="auto"
            bg={main1}
            borderRadius="lg"
          >
            <Table variant="simple" minW="2xl">
              <Thead>
                <Tr>
                  <Th>{t("common:asset")}</Th>
                  <Th isNumeric>{t("common:price")}</Th>
                  <Th isNumeric>{t("common:amount")}</Th>
                  <Th isNumeric>{t("common:worth")}</Th>
                  <Th isNumeric>{t("common:share")}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {walletTokenRows &&
                  walletTokenRows.map((item, index) => {
                    const { walletToken, tokenRate } = item;

                    if (
                      !walletToken ||
                      !tokenRate ||
                      (walletToken.balance === 0 && !show)
                    ) {
                      return <Tr key={index} />;
                    }

                    const { token, balance } = walletToken;
                    const { rate } = tokenRate;

                    return (
                      <Tr key={index}>
                        <Td>
                          <Flex alignItems="center">
                            <TokenLogo size="sm" mr="2" src={token.icon} />
                            <Box justifyContent="flex-end">
                              <Text fontSize="sm" fontWeight="semibold">
                                {token.name}
                              </Text>
                              <Text fontSize="xs" color={text6}>
                                {token.symbol}
                              </Text>
                            </Box>
                          </Flex>
                        </Td>
                        <Td isNumeric>
                          <Flex justifyContent="flex-end" alignItems="center">
                            <Text fontSize="sm" fontWeight="semibold" mr="1">
                              {formatToken(rate)}
                            </Text>
                            <TokenLogo w="4" h="4" src={Images.zil} />
                          </Flex>
                          <Text fontSize="xs" color={text6}>
                            ~
                            {zilPriceInUsd
                              ? formatUsdt(rate * zilPriceInUsd, exchangeRate)
                              : t("common:..loading")}
                          </Text>
                        </Td>
                        <Td isNumeric>
                          <Flex justifyContent="flex-end" alignItems="center">
                            <Text fontSize="sm" fontWeight="semibold" mr="1">
                              {formatToken(balance, token.symbol, isHidden)}
                            </Text>
                            <TokenLogo w="4" h="4" src={token.icon} />
                          </Flex>
                          <Text fontSize="xs" color={text6}>
                            {"　"}
                          </Text>
                        </Td>
                        <Td isNumeric>
                          <Flex justifyContent="flex-end" alignItems="center">
                            <Text fontSize="sm" fontWeight="semibold" mr="1">
                              {zilPriceInUsd
                                ? formatToken(balance * rate, "ZIL", isHidden)
                                : t("common:..loading")}
                            </Text>
                            <TokenLogo w="4" h="4" src={Images.zil} />
                          </Flex>
                          <Text fontSize="xs" color={text6}>
                            ~
                            {zilPriceInUsd
                              ? formatUsdt(
                                  balance * zilPriceInUsd * rate,
                                  exchangeRate,
                                  isHidden
                                )
                              : t("common:..loading")}
                          </Text>
                        </Td>
                        <Td
                          isNumeric
                          fontSize="sm"
                          fontWeight="semibold"
                          color="blue.600"
                        >
                          {isHidden
                            ? "*** %"
                            : totalZil && totalZil > 0
                            ? `${((balance * rate * 100) / totalZil).toFixed(
                                2
                              )}%`
                            : t("common:..loading")}
                        </Td>
                      </Tr>
                    );
                  })}
              </Tbody>
            </Table>
            <Center
              role="group"
              p="2"
              onClick={setShow.toggle}
              cursor="pointer"
              _hover={{ bg: main3 }}
            >
              <IconFilledArrow
                _groupHover={{ fill: text3 }}
                size="6"
                fill={text5}
                style={{ transform: `rotate(${show ? 0 : 180}deg)` }}
              />
            </Center>
          </Box>
          <Flex
            display={{ base: "block", lg: "none" }}
            flexDirection="column"
            bg={main1}
            borderRadius="lg"
          >
            {walletTokenRows &&
              walletTokenRows.map((token, index) => (
                <Wallet
                  is_show={show}
                  key={index}
                  item={token}
                  totalBalance={totalZil}
                />
              ))}
            <Center
              role="group"
              p="4"
              onClick={setShow.toggle}
              cursor="pointer"
              _hover={{ bg: main3 }}
            >
              <IconFilledArrow
                _groupHover={{ fill: text3 }}
                size="6"
                fill={text5}
                style={{ transform: `rotate(${show ? 0 : 180}deg)` }}
              />
            </Center>
          </Flex>
        </>
      )}
    </Box>
  );
};

export default WalletToken;
