import React from "react";
import {
  BoxProps,
  HStack,
  Stack,
  Switch,
  Text,
  useBoolean,
} from "@chakra-ui/react";
import { IconArrowLeft } from "src/assets/icons";
import { useAppColor } from "src/hooks";
import { t } from "i18next";

interface PortfolioContainerProps extends BoxProps {
  title: string;
  onClose: () => void;
  withSwitch?: boolean;
  children: React.ReactElement;
}

const PortfolioContainer: React.FC<PortfolioContainerProps> = ({
  title,
  onClose,
  withSwitch,
  children,
  ...props
}) => {
  const [active, setActive] = useBoolean(true);
  const { brand, text3, text6 } = useAppColor();
  return (
    <Stack>
      <HStack justify="space-between" pb="16px">
        <HStack>
          <IconArrowLeft
            cursor="pointer"
            color={text3}
            size="24px"
            onClick={onClose}
          />
          <Text
            fontSize="20px"
            lineHeight="28px"
            fontWeight="700"
            color={text3}
          >
            {title}
          </Text>
        </HStack>
        {withSwitch && (
          <HStack>
            <Switch
              isChecked={active}
              onChange={() => {
                setActive.toggle();
              }}
              colorScheme={brand}
              _checked={{ bg: brand }}
              borderRadius="24"
            />
            <Text color={text6}>{t("profile:activeOnly")}</Text>
          </HStack>
        )}
      </HStack>
      {/* {props.children} */}
      {children && React.cloneElement(children, { active })}
    </Stack>
  );
};

export default PortfolioContainer;
