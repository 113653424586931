import React from "react";
import Icon from "@chakra-ui/icon";
import { SvgIconProps } from "src/constants/type";

const SvgComponent: React.FC<SvgIconProps> = ({
  size,
  ...props
}: SvgIconProps) => {
  return (
    <Icon {...props} w={size} h={size} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0834 2.49998C10.0834 2.13179 10.3819 1.83331 10.75 1.83331H13.6667C14.0349 1.83331 14.3334 2.13179 14.3334 2.49998V5.41665C14.3334 5.78484 14.0349 6.08331 13.6667 6.08331C13.2985 6.08331 13 5.78484 13 5.41665V3.16665H10.75C10.3819 3.16665 10.0834 2.86817 10.0834 2.49998ZM2.00004 10.5833C2.36823 10.5833 2.66671 10.8818 2.66671 11.25V13.5H4.91671C5.2849 13.5 5.58337 13.7985 5.58337 14.1666C5.58337 14.5348 5.2849 14.8333 4.91671 14.8333H2.00004C1.63185 14.8333 1.33337 14.5348 1.33337 14.1666V11.25C1.33337 10.8818 1.63185 10.5833 2.00004 10.5833ZM14.3334 11.25C14.3334 10.8818 14.0349 10.5833 13.6667 10.5833C13.2985 10.5833 13 10.8818 13 11.25V13.5H10.75C10.3819 13.5 10.0834 13.7985 10.0834 14.1666C10.0834 14.5348 10.3819 14.8333 10.75 14.8333H13.6667C14.0349 14.8333 14.3334 14.5348 14.3334 14.1666V11.25ZM1.33337 2.49998C1.33337 2.13179 1.63185 1.83331 2.00004 1.83331H4.91671C5.2849 1.83331 5.58337 2.13179 5.58337 2.49998C5.58337 2.86817 5.2849 3.16665 4.91671 3.16665H2.66671V5.41665C2.66671 5.78484 2.36823 6.08331 2.00004 6.08331C1.63185 6.08331 1.33337 5.78484 1.33337 5.41665V2.49998ZM8.47623 7.83331H7.52385H4.66671V9.16665H7.52385H8.47623H11.3334V7.83331H8.47623Z"
        fill="currentColor"
      />
    </Icon>
  );
};

const IconMinify = React.memo(SvgComponent);
export default IconMinify;
