import React, { memo } from "react";
import { PageLayout } from "src/templates";
import { Divider, HStack, Stack, Text } from "@chakra-ui/layout";

import { useAppColor, useRouter } from "src/hooks";
import { useTranslation } from "react-i18next";
import {
  InputGroup,
  InputLeftElement,
  Input,
  Flex,
  Tab,
  TabList,
  Tabs,
  Box,
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
  useBoolean,
  Td,
  Avatar,
  Button,
  Icon,
  InputRightElement,
  IconButton,
  useToast,
} from "@chakra-ui/react";
import { BsSearch } from "react-icons/bs";
import { Images } from "src/assets/images";
import { TokenLogo } from "src/atoms";
import { Directions_Enum, HistorySpec } from "src/constants/type";
import { IconAdd, IconChecked } from "src/assets/icons";
import { CloseIcon } from "@chakra-ui/icons";
import { dayjs, getIcon, getTagName, nameSubString } from "src/utils";
import {
  IWallet,
  loadRecentWalletsFromLocalStorage,
  loginSelector,
  saveRecentWalletsToLocalStorage,
  setSavedWalletFollowings,
} from "src/redux/slices/loginSlice";
import { usePagination } from "@ajna/pagination";
import PaginationPanel from "./components/PaginationPanel";
import {
  followWallet,
  getHistory,
  getWalletsInfo,
  getWalletsMemberClub,
  searchWallet,
  unFollowWallet,
} from "src/api/heyAlfieApi";
import differenceBy from "lodash/differenceBy";
import { useAppSelector } from "src/redux/hooks";
import { isConnectZilpay } from "src/api/zilpayApi";
import { getHeyAlfieNftContractState } from "src/api/membershipApi";
import { to32Address } from "src/api/zilApi";
import useModal from "src/hooks/useModal";
import { overviewSelector } from "src/redux/slices/overviewSlice";
import { getToken } from "src/utils/transaction";
import BigNumber from "bignumber.js";
import { formatToken } from "src/lib/formatCurrency";

export interface IHistory {
  wallet: IWallet;
  transaction: HistorySpec;
}

interface ReefPageProps {
  title: string;
  item?: HistorySpec;
}

export const ReefPage: React.FC<ReefPageProps> = memo(({ title }) => {
  const { t } = useTranslation(["reef", "common"]);
  const { text2, text6, text4, main1, main4, main3, brand } = useAppColor();
  const router = useRouter();
  const toast = useToast();
  const {
    currentWallet: _currentWallet,
    savedWallets,
    isMember,
  } = useAppSelector(loginSelector);
  const { tokens } = useAppSelector(overviewSelector);
  const currentWallet = savedWallets.find(
    (savedWallet) => savedWallet.zilAddress === _currentWallet?.zilAddress
  );
  const [isSearching, setIsSearching] = useBoolean(false);
  const [isSearchDone, setIsSearchDone] = useBoolean(false);
  const [tabIndex, setTabIndex] = React.useState<number>(0);
  const [searchUser, setSearchUser] = React.useState<string>("");
  const [currentWallets, setCurrentWallets] = React.useState<IWallet[]>([]);
  const [clubMemberWallets, setClubMemberWallets] = React.useState<IWallet[]>(
    []
  );
  const [tokenLength, setTokenLengthWallets] = React.useState<number>(0);
  const [searchWallets, setSearchWallets] = React.useState<IWallet[]>([]);
  const [recentWallets, setRecentWallets] = React.useState<IWallet[]>(
    loadRecentWalletsFromLocalStorage() ?? []
  );
  const [followerData, setFollowerData] = React.useState<IWallet[]>([]);
  const [followingData, setFollowingData] = React.useState<IWallet[]>([]);
  const [followings, setFollowings] = React.useState<string[]>([]);
  const [followers, setFollowers] = React.useState<string[]>([]);
  const [_listTransaction, setListTransaction] = React.useState<IHistory[]>([]);
  const { onOpenModalPremium } = useModal();

  const { pages, pagesCount, currentPage, setCurrentPage, isDisabled } =
    usePagination({
      total:
        tabIndex === 0
          ? !isSearching
            ? [...new Set([...followers, ...followings])].length
            : tokenLength
          : tabIndex === 1
          ? followings.length
          : followers.length, //total history,
      limits: {
        outer: 1,
        inner: 2,
      },
      initialState: {
        pageSize: 10,
        isDisabled: false,
        currentPage: 1,
      },
    });
  const getFollower = React.useCallback(async () => {
    const preIndex = (currentPage - 1) * 10;
    const currentIndex = currentPage * 10;
    if (followers.length > 0) {
      const result = await getWalletsInfo(
        followers.length > 10
          ? followers.slice(preIndex, currentIndex)
          : followers
      );
      if (result === undefined) {
        return;
      }
      setFollowerData(result);
    }
  }, [currentPage, followers]);

  const getFollowing = React.useCallback(async () => {
    const preIndex = (currentPage - 1) * 10;
    const currentIndex = currentPage * 10;
    if (followings.length > 0) {
      const result = await getWalletsInfo(
        followings.length > 10
          ? followings.slice(preIndex, currentIndex)
          : followings
      );
      if (result === undefined) {
        return;
      }
      setFollowingData(result);
    }
  }, [currentPage, followings]);

  const onSearchChange = React.useCallback(
    async (searchText: string) => {
      setSearchUser(searchText);
      if (searchText.length === 0) {
        setIsSearching.on();
        setIsSearchDone.off();
      } else {
        setIsSearching.off();
        setIsSearchDone.on();
        const result = await searchWallet(searchText);

        if (result.length === 0) {
          return;
        }
        const wallets = loadRecentWalletsFromLocalStorage();
        if (!wallets) {
          saveRecentWalletsToLocalStorage(result);
          setRecentWallets(result);
        } else if (wallets.length < 3) {
          const ind = wallets.findIndex(
            (item) => item.zilAddress === result[0].zilAddress
          );
          if (ind === -1) {
            saveRecentWalletsToLocalStorage([...result, ...wallets]);
            setRecentWallets([...result, ...wallets]);
          }
        } else {
          const ind = wallets.findIndex(
            (item) => item.zilAddress === result[0].zilAddress
          );
          if (ind === -1) {
            saveRecentWalletsToLocalStorage([
              ...result,
              ...wallets.slice(0, wallets.length - 2),
            ]);
            setRecentWallets([...result, ...wallets.slice(1)]);
          }
        }
        setSearchWallets(result);
      }
    },
    [setIsSearchDone, setIsSearching]
  );

  const onFollow = (zilAddress: string) => {
    if (!currentWallet || !isConnectZilpay(currentWallet.zilAddress)) {
      toast({
        id: "sent",
        description: t("pleaseConnectWallet"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    setSavedWalletFollowings(zilAddress);
    followWallet(currentWallet.zilAddress, zilAddress);
  };
  const onUnFollow = (zilAddress: string) => {
    if (!currentWallet || !isConnectZilpay(currentWallet.zilAddress)) {
      toast({
        id: "sent",
        description: t("pleaseConnectWallet"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    setSavedWalletFollowings(zilAddress);
    unFollowWallet(currentWallet.zilAddress, zilAddress);
  };

  const richText = (
    amount: number,
    title: string,
    hasMargin: boolean = false
  ) => {
    return (
      <Flex mx={hasMargin ? "4" : "0"}>
        <Text mr="2" fontWeight="600" color={text2}>
          {amount}
        </Text>
        <Text fontWeight="600" color={text6}>
          {title}
        </Text>
      </Flex>
    );
  };
  const buttonFollow = (zilAddress: string, sizeButton?: string) => {
    const followingsAddress = JSON.parse(
      currentWallet?.followings && currentWallet?.followings.length > 0
        ? currentWallet?.followings[0].otherBech32
        : "[]"
    ) as string[];

    const ind = followingsAddress.findIndex((e) => e === zilAddress);
    return ind !== -1 ? (
      <Button
        size={sizeButton ?? "sm"}
        leftIcon={<Icon as={IconChecked} boxSize="16px" />}
        color={text6}
        bg={main3}
        visibility={
          zilAddress === currentWallet?.zilAddress ? "hidden" : "visible"
        }
        onClick={() => onUnFollow(zilAddress)}
        children={"Following"}
      />
    ) : (
      <Button
        size={sizeButton ?? "sm"}
        leftIcon={<Icon as={IconAdd} boxSize="16px" />}
        color={"gray.900"}
        bg={brand}
        visibility={
          zilAddress === currentWallet?.zilAddress ? "hidden" : "visible"
        }
        onClick={() => onFollow(zilAddress)}
        children={"Follow"}
      />
    );
  };
  const renderTokenRecieve = (tokenReceive: any[]) => {
    return tokenReceive.map((item, index) => {
      return (
        item && (
          <Flex key={index} display="flex">
            <TokenLogo src={item.token.icon} size="2xs" />
            <Text
              fontSize="sm"
              lineHeight="5"
              fontWeight="semibold"
              ml="4px"
              color={"blue.600"}
            >
              {formatToken(
                new BigNumber(item.amount)
                  .div(Math.pow(10, item.token.decimals))
                  .toNumber(),
                item.token.symbol
              )}
            </Text>
          </Flex>
        )
      );
    });
  };
  const renderTokenSend = (tokenSend: any[]) => {
    return tokenSend.map((item, index) => {
      return (
        item && (
          <Flex key={index} display="flex">
            <TokenLogo src={item?.token.icon} size="2xs" />
            <Text
              fontSize="sm"
              lineHeight="5"
              fontWeight="semibold"
              ml="4px"
              color={"red.600"}
            >
              {formatToken(
                new BigNumber(item.amount)
                  .div(Math.pow(10, item.token.decimals))
                  .toNumber(),
                item.token.symbol
              )}
            </Text>
          </Flex>
        )
      );
    });
  };

  const itemTransaction = (index: number, item: IHistory) => {
    const { name, zilAddress, avatar, uuid } = item.wallet;
    const { tag, direction, timestamp, value, events, to } = item.transaction;
    const { tokenReceive, tokenSend } = getToken(
      tokens,
      events,
      value,
      tag,
      direction
    );
    return (
      <Tr key={index}>
        <Td>
          <Flex
            onClick={() => router.push(`/view-profile/${uuid}`)}
            cursor="pointer"
          >
            <Avatar src={avatar} mr="4" />
            <Box>
              <Flex mb="1" alignItems="center">
                <Text
                  mr="8px"
                  fontSize="md"
                  fontWeight="semibold"
                  color={text2}
                >
                  {name && name !== undefined
                    ? nameSubString(name, 18)
                    : nameSubString(zilAddress, 18)}
                </Text>
                {buttonFollow(zilAddress, "xs")}
              </Flex>
              <Text fontSize="sm" color={text6}>
                {nameSubString(zilAddress, 18)}
              </Text>
            </Box>
          </Flex>
        </Td>
        <Td>
          <Flex alignItems="center">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              w="32px"
              h="32px"
              rounded="16px"
              borderWidth="1px"
              borderColor={main4}
              fill="gray.900"
              mr="8px"
              bg="white"
            >
              {getIcon(tag, direction)}
            </Box>
            <Box>
              <Text
                fontSize="sm"
                lineHeight="5"
                fontWeight="semibold"
                color={text2}
              >
                {getTagName(tag, direction)}
              </Text>
              <Text
                fontSize="xs"
                lineHeight="4"
                fontWeight="normal"
                color={text6}
              >
                {dayjs(timestamp).format("h:mm A")}
              </Text>
            </Box>
          </Flex>
        </Td>
        <Td isNumeric>
          {tokenSend.length <= 0 && tokenReceive.length <= 0 && value !== "0" && (
            <Flex alignItems="center" display="block" justifyContent="flex-end">
              <Flex display="flex">
                <TokenLogo src={Images.zil} size="2xs" />
                <Text
                  fontSize="sm"
                  lineHeight="5"
                  fontWeight="semibold"
                  ml="4px"
                  color={
                    direction === Directions_Enum.In ? "blue.600" : "red.600"
                  }
                >
                  {formatToken(
                    new BigNumber(value).div(Math.pow(10, 12)).toNumber()
                  )}
                </Text>
              </Flex>
            </Flex>
          )}
          <Flex alignItems="flex-end" display="block" justifyContent="flex-end">
            {tokenSend.length > 0 && renderTokenSend(tokenSend)}
          </Flex>
          <Flex
            alignItems="flex-end"
            display="block"
            justifyContent="flex-end"
            mt="2"
          >
            {tokenReceive.length > 0 && renderTokenRecieve(tokenReceive)}
          </Flex>
        </Td>
        <Td isNumeric>
          <Flex alignItems="center" justifyContent="flex-end">
            <Text
              fontSize="sm"
              lineHeight="5"
              fontWeight="semibold"
              ml="4px"
              mr="4px"
            >
              {nameSubString(to, 13)}
            </Text>
          </Flex>
        </Td>
      </Tr>
    );
  };

  const itemTransactionMobile = (index: number, item: IHistory) => {
    const { name, zilAddress, avatar, uuid } = item.wallet;
    const { tag, direction, timestamp, value, events, to } = item.transaction;
    const { tokenReceive, tokenSend } = getToken(
      tokens,
      events,
      value,
      tag,
      direction
    );
    return (
      <Flex
        key={index}
        display="block"
        bg={main1}
        my="4"
        p="6"
        borderRadius="12px"
        onClick={() => router.push(`/view-profile/${uuid}`)}
        cursor="pointer"
      >
        <Flex display="flex" justifyContent="space-between">
          <Flex alignItems="center">
            <Avatar src={avatar} w="40px" h="40px" mr="2" />
            <Box>
              <Flex mb="1" alignItems="center">
                <Text
                  mr="8px"
                  fontSize="md"
                  fontWeight="semibold"
                  color={text2}
                >
                  {name && name !== undefined
                    ? nameSubString(name, 18)
                    : nameSubString(zilAddress, 18)}
                </Text>
                {buttonFollow(zilAddress, "xs")}
              </Flex>
              <Text fontSize="sm" color={text6}>
                {nameSubString(zilAddress, 18)}
              </Text>
            </Box>
          </Flex>
          <Flex alignItems="flex-start">
            <Text
              fontSize="sm"
              lineHeight="5"
              fontWeight="semibold"
              ml="4px"
              mr="4px"
            >
              {nameSubString(to, 13)}
            </Text>
          </Flex>
        </Flex>
        <Divider my="4" />

        <Flex display="flex" justifyContent="space-between">
          <Flex alignItems="center">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              w="40px"
              h="40px"
              rounded="24px"
              borderWidth="1px"
              borderColor={main4}
              fill="gray.900"
              mr="8px"
              bg="white"
            >
              {getIcon(tag, direction)}
            </Box>
            <Box>
              <Text
                fontSize="sm"
                lineHeight="5"
                fontWeight="semibold"
                color={text2}
              >
                {getTagName(tag, direction)}
              </Text>
              <Text
                fontSize="xs"
                lineHeight="4"
                fontWeight="normal"
                color={text6}
              >
                {dayjs(timestamp).format("h:mm A")}
              </Text>
            </Box>
          </Flex>
          <Flex display="block">
            {tokenSend.length <= 0 &&
              tokenReceive.length <= 0 &&
              value !== "0" && (
                <Flex
                  alignItems="center"
                  display="block"
                  justifyContent="flex-end"
                >
                  <Flex display="flex">
                    <TokenLogo src={Images.zil} size="2xs" />
                    <Text
                      fontSize="sm"
                      lineHeight="5"
                      fontWeight="semibold"
                      ml="4px"
                      color={
                        direction === Directions_Enum.In
                          ? "blue.600"
                          : "red.600"
                      }
                    >
                      {formatToken(
                        new BigNumber(value).div(Math.pow(10, 12)).toNumber()
                      )}
                    </Text>
                  </Flex>
                </Flex>
              )}
            <Flex
              alignItems="flex-end"
              display="block"
              justifyContent="flex-end"
            >
              {tokenSend.length > 0 && renderTokenSend(tokenSend)}
            </Flex>
            <Flex
              alignItems="flex-end"
              display="block"
              justifyContent="flex-end"
              mt="2"
            >
              {tokenReceive.length > 0 && renderTokenRecieve(tokenReceive)}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    );
  };

  const itemSearchResult = (index: number, item: IWallet) => {
    const { name, zilAddress, avatar, followings, followers, uuid } = item;

    let followingsAddress = [];
    let followersAddress = [];
    if (followings.length > 0) {
      followingsAddress = JSON.parse(followings[0].otherBech32) as string[];
    }
    if (followers.length > 0) {
      followersAddress = JSON.parse(followers[0].otherBech32) as string[];
    }
    return (
      /* web*/
      <Stack key={index}>
        <Flex m="6" justifyContent="space-between">
          <Flex
            w="60%"
            onClick={() => router.push(`/view-profile/${uuid}`)}
            cursor="pointer"
          >
            <Flex>
              <Avatar src={avatar} mr="4" />
              <Box>
                <Flex mb="1" alignItems="center">
                  <Text
                    mr="8px"
                    fontSize="md"
                    fontWeight="semibold"
                    color={text2}
                  >
                    {name && name !== undefined
                      ? nameSubString(name, 18)
                      : nameSubString(zilAddress, 18)}
                  </Text>
                </Flex>
                <Text fontSize="sm" color={text6}>
                  {nameSubString(zilAddress, 18)}
                </Text>
              </Box>
            </Flex>
          </Flex>
          <Flex alignItems="center">
            {richText(
              followingsAddress.length > 0 ? followingsAddress.length : 0,
              t("common:following")
            )}
            {richText(
              followersAddress.length > 0 ? followersAddress.length : 0,
              t("common:followers"),
              true
            )}
            {currentWallet?.zilAddress === zilAddress ? (
              <></>
            ) : (
              buttonFollow(zilAddress)
            )}
          </Flex>
        </Flex>
        <Divider borderColor={main3} />
      </Stack>
    );
  };

  const getHeyAlfieState = React.useCallback(async () => {
    const { token_owners } = await getHeyAlfieNftContractState();
    const tokenOwners = [
      ...new Set([
        ...Object.keys(token_owners).map((key) =>
          to32Address(token_owners[key])
        ),
      ]),
    ] as string[];
    setTokenLengthWallets(tokenOwners.length);
    if (tokenOwners.length > 0) {
      const result = await getWalletsMemberClub(
        tokenOwners.length > 10
          ? tokenOwners.slice((currentPage - 1) * 10, currentPage * 10)
          : tokenOwners
      );

      if (result === undefined) {
        return;
      }
      setClubMemberWallets(result);
    }
  }, [currentPage]);
  React.useEffect(() => {
    if (!isMember) {
      onOpenModalPremium();
    }
  }, [isMember, onOpenModalPremium]);
  React.useEffect(() => {
    if (!currentWallet) {
      return;
    }
    if (currentWallet?.followings.length > 0) {
      const followingsAddress = JSON.parse(
        currentWallet?.followings[0].otherBech32
      ) as string[];
      setFollowings(followingsAddress);
    }

    if (currentWallet?.followers.length > 0) {
      const followersAddress = JSON.parse(
        currentWallet?.followers[0].otherBech32
      ) as string[];
      setFollowers(followersAddress);
    }
  }, [currentWallet]);

  React.useEffect(() => {
    getHeyAlfieState();
  }, [getHeyAlfieState]);

  React.useEffect(() => {
    getFollower();
    getFollowing();
  }, [getFollower, getFollowing]);

  const getHistoryRecent = async (wallets: IWallet[]) => {
    const transactions: IHistory[] = [];
    for (const wallet of wallets) {
      const res = await getHistory(wallet.zilAddress);
      transactions.push({ transaction: res.result, wallet: wallet });
    }
    setListTransaction(transactions);
  };

  React.useEffect(() => {
    const myDifferences = differenceBy(
      followerData,
      followingData,
      "zilAddress"
    );
    if (tabIndex === 0 && !isSearching) {
      const setListWallet = [...new Set([...myDifferences, ...followingData])];

      getHistoryRecent(setListWallet);
      setCurrentWallets(setListWallet);
    }
    if (tabIndex === 0 && isSearching) {
      setCurrentWallets(clubMemberWallets);
    }
    if (tabIndex === 1 && !isSearching) {
      const setListWallet = [...followingData];
      getHistoryRecent(setListWallet);
      setCurrentWallets(setListWallet);
    }
    if (tabIndex === 1 && isSearching) {
      setCurrentWallets(followingData);
    }
    if (tabIndex === 2 && !isSearching) {
      const setListWallet = [...followerData];
      getHistoryRecent(setListWallet);
      setCurrentWallets(setListWallet);
    }
    if (tabIndex === 2 && isSearching) {
      setCurrentWallets(followerData);
    }
  }, [
    savedWallets,
    tabIndex,
    followingData,
    followerData,
    isSearching,
    clubMemberWallets,
  ]);
  return (
    <PageLayout title={title}>
      <Text
        display={{ base: "block", lg: "none" }}
        fontSize="2xl"
        lineHeight="8"
        fontWeight="semibold"
        color={text2}
        pb="4"
      >
        {isSearching || isSearchDone ? "Lagoon" : title}
      </Text>
      <InputGroup w="full">
        <InputLeftElement color="gray.400" pointerEvents="none">
          <BsSearch />
        </InputLeftElement>
        <InputRightElement color="gray.400">
          <IconButton
            color={text4}
            display={isSearching || isSearchDone ? "block" : "none"}
            bg="transparent"
            variant="ghost"
            aria-label={"Cancel search"}
            icon={<CloseIcon boxSize="3" />}
            onClick={() => {
              setIsSearching.off();
              setIsSearchDone.off();
              setSearchUser("");
            }}
          />
        </InputRightElement>
        <Input
          onFocus={() => {
            if (searchUser.length === 0) {
              setIsSearching.on();
            } else {
              setIsSearching.off();
              setIsSearchDone.on();
            }
          }}
          bg={main1}
          placeholder={t("searchUser")}
          w="full"
          onChange={(event) => onSearchChange(event.target.value)}
          value={searchUser}
        />
      </InputGroup>
      {isSearching && (
        <Box mt="8">
          <Text
            fontSize="xl"
            lineHeight="8"
            fontWeight="semibold"
            color={text2}
          >
            {"Recent Search"}
          </Text>
          <Flex
            spacing={4}
            display={{ base: "block", lg: "flex" }}
            mt="16px"
            justify="space-between"
          >
            {recentWallets &&
              recentWallets.map((item, index) => {
                const { name, avatar, zilAddress, followers } = item;
                let followersAddress = [];
                if (followers.length > 0) {
                  followersAddress = JSON.parse(
                    followers[0].otherBech32
                  ) as string[];
                }
                return (
                  <HStack key={index} mb={{ base: "4", lg: "0" }}>
                    <Avatar src={avatar} mr="2" />
                    <Box>
                      <Flex alignItems="center">
                        <Text
                          mr="8px"
                          fontSize="md"
                          fontWeight="semibold"
                          color={text2}
                        >
                          {name && name !== undefined
                            ? nameSubString(name, 15)
                            : nameSubString(zilAddress, 18)}
                        </Text>
                      </Flex>
                      <Text fontSize="md" color={text6}>
                        {followersAddress.length} {t("common:follow")}
                      </Text>
                    </Box>
                    {buttonFollow(zilAddress)}
                  </HStack>
                );
              })}
          </Flex>
        </Box>
      )}
      {/* ///web */}
      <Box
        display={{ base: "none", lg: "block" }}
        bg={main1}
        mt="8"
        borderRadius="12px"
      >
        <Tabs
          borderColor={main4}
          isLazy
          py="2"
          onChange={(tabIndex) => {
            setCurrentPage(1);
            setTabIndex(tabIndex);
          }}
        >
          <Flex
            borderBottom="1px"
            borderColor={main4}
            justifyContent="space-between"
            alignItems="center"
          >
            <TabList textColor={text6} borderBottom="0px">
              {(isSearching
                ? [
                    "Member Club",
                    richText(followings.length, t("common:following")),
                    richText(followers.length, t("common:followers")),
                  ]
                : isSearchDone
                ? [richText(searchWallets.length, "Result")]
                : [
                    "All",
                    richText(followings.length, t("common:following")),
                    richText(followers.length, t("common:followers")),
                  ]
              ).map((value, index) => {
                return (
                  <Tab
                    px="6"
                    key={index}
                    _selected={{
                      boxShadow: "none",
                      borderColor: brand,
                      color: brand,
                      fontWeight: "bold",
                    }}
                  >
                    {value}
                  </Tab>
                );
              })}
            </TabList>
          </Flex>
        </Tabs>
        {isSearching || isSearchDone ? (
          (isSearchDone ? searchWallets : currentWallets).map((item, index) => {
            return itemSearchResult(index, item);
          })
        ) : (
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th w="40%">{t("user")}</Th>
                <Th>{t("transactionType")}</Th>
                <Th>{t("token")}</Th>
                <Th isNumeric>{t("to")}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {_listTransaction.map((item, index) => {
                const { name, zilAddress, avatar, uuid } = item.wallet;
                return item.transaction ? (
                  itemTransaction(index, item)
                ) : (
                  <Tr key={index}>
                    <Td>
                      <Flex
                        onClick={() => router.push(`/view-profile/${uuid}`)}
                        cursor="pointer"
                      >
                        <Avatar src={avatar} mr="4" />
                        <Box>
                          <Flex mb="1" alignItems="center">
                            <Text
                              mr="8px"
                              fontSize="md"
                              fontWeight="semibold"
                              color={text2}
                            >
                              {name && name !== undefined
                                ? nameSubString(name, 18)
                                : nameSubString(zilAddress, 18)}
                            </Text>
                            {buttonFollow(zilAddress, "xs")}
                          </Flex>
                          <Text fontSize="sm" color={text6}>
                            {nameSubString(zilAddress, 18)}
                          </Text>
                        </Box>
                      </Flex>
                    </Td>
                    <Td></Td>
                    <Td></Td>
                    <Td></Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        )}
        <Flex justifyContent="center" pt="6" pb="6">
          <PaginationPanel
            pagesCount={pagesCount}
            currentPage={currentPage}
            isDisabled={isDisabled}
            onPageChange={(page) => {
              setCurrentPage(page);
            }}
            pages={pages}
            mb="8px"
          />
        </Flex>
      </Box>
      {/* ///mobile */}
      <Box display={{ base: "block", lg: "none" }}>
        <Tabs
          borderColor={main4}
          isLazy
          py="2"
          onChange={(tabIndex) => {
            setCurrentPage(1);
            setTabIndex(tabIndex);
          }}
        >
          <Flex
            borderBottom="1px"
            borderColor={main4}
            justifyContent="space-between"
            alignItems="center"
          >
            <TabList textColor={text6} borderBottom="0px">
              {(isSearching
                ? [
                    "Member Club",
                    richText(followings.length, t("common:following")),
                    richText(followers.length, t("common:followers")),
                  ]
                : isSearchDone
                ? [richText(searchWallets.length, "Result")]
                : [
                    "All",
                    richText(followings.length, t("common:following")),
                    richText(followers.length, t("common:followers")),
                  ]
              ).map((value, index) => {
                return (
                  <Tab
                    key={index}
                    _selected={{
                      boxShadow: "none",
                      borderColor: brand,
                      color: brand,
                      fontWeight: "bold",
                    }}
                  >
                    {value}
                  </Tab>
                );
              })}
            </TabList>
          </Flex>
        </Tabs>
        {isSearching || isSearchDone
          ? (isSearchDone ? searchWallets : currentWallets).map(
              (item, index) => {
                const { name, zilAddress, avatar, followings, followers } =
                  item;
                return (
                  <Flex
                    key={index}
                    display="block"
                    bg={main1}
                    my="4"
                    p="6"
                    borderRadius="12px"
                  >
                    <Flex alignItems="center">
                      <Avatar src={avatar} w="40px" h="40px" mr="2" />
                      <Box>
                        <Flex mb="1" alignItems="center">
                          <Text
                            mr="8px"
                            fontSize="md"
                            fontWeight="semibold"
                            color={text2}
                          >
                            {name && name !== undefined
                              ? nameSubString(name, 18)
                              : nameSubString(zilAddress, 18)}
                          </Text>
                          {buttonFollow(zilAddress, "xs")}
                        </Flex>
                        <Text fontSize="sm" color={text6}>
                          {nameSubString(zilAddress, 18)}
                        </Text>
                      </Box>
                    </Flex>
                    <Divider my="4" />
                    <Flex display="flex" justifyContent="space-between">
                      {richText(followings.length, t("common:following"))}
                      {richText(followers.length, t("common:followers"), true)}
                    </Flex>
                  </Flex>
                );
              }
            )
          : _listTransaction.map((item, index) => {
              const { name, zilAddress, avatar } = item.wallet;
              return item.transaction ? (
                itemTransactionMobile(index, item)
              ) : (
                <Flex
                  key={index}
                  display="block"
                  bg={main1}
                  my="4"
                  p="6"
                  borderRadius="12px"
                >
                  <Flex display="flex" justifyContent="space-between">
                    <Flex alignItems="center">
                      <Avatar src={avatar} w="40px" h="40px" mr="2" />
                      <Box>
                        <Flex mb="1" alignItems="center">
                          <Text
                            mr="8px"
                            fontSize="md"
                            fontWeight="semibold"
                            color={text2}
                          >
                            {name && name !== undefined
                              ? nameSubString(name, 18)
                              : nameSubString(zilAddress, 18)}
                          </Text>
                          {buttonFollow(zilAddress, "xs")}
                        </Flex>
                        <Text fontSize="sm" color={text6}>
                          {nameSubString(zilAddress, 18)}
                        </Text>
                      </Box>
                    </Flex>
                  </Flex>
                </Flex>
              );
            })}
        <Flex justifyContent="center" pt="6" pb="6">
          <PaginationPanel
            pagesCount={pagesCount}
            currentPage={currentPage}
            isDisabled={isDisabled}
            onPageChange={(page) => {
              setCurrentPage(page);
            }}
            pages={pages}
            mb="8px"
          />
        </Flex>
      </Box>
    </PageLayout>
  );
});

export default ReefPage;
