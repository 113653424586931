import { t } from "i18next";
import React from "react";
import {
  IconAddLiquidity,
  IconClaim,
  IconExchange,
  IconMint,
  IconReceive,
  IconRemoveLiquidity,
  IconSend,
  IconStake,
  IconWithDraw,
} from "src/assets/icons";
import { IToken } from "src/constants/interfaces";
import { Directions_Enum, Tags_Enum } from "src/constants/type";

interface ISwapTransactionToken {
  token?: IToken | undefined;
  amount?: any;
}

const getIcon = (tag: any, direction: Directions_Enum | undefined) => {
  switch (tag) {
    case Tags_Enum.DelegateStake:
      return <IconStake />;
    case Tags_Enum.WithdrawStakeRewards:
    case Tags_Enum.Claim:
    case Tags_Enum.ClaimMulti:
      return <IconClaim />;
    case Tags_Enum.AddLiquidity:
      return <IconAddLiquidity />;
    case Tags_Enum.RemoveLiquidity:
      return <IconRemoveLiquidity />;
    case Tags_Enum.withdrawPending:
      return <IconWithDraw />;
    case Tags_Enum.SwapExactZILForTokens:
    case Tags_Enum.SwapExactTokensForZIL:
    case Tags_Enum.SwapZILForExactTokens:
    case Tags_Enum.SwapExactTokensForTokens:
    case Tags_Enum.SwapTokensForExactTokens:
    case Tags_Enum.SwapExactTokensForExactTokens:
      return <IconExchange />;
    case Tags_Enum.Mint:
      return <IconMint />;
    case Tags_Enum.Empty:
    case Tags_Enum.Transfer:
      if (direction === Directions_Enum.Out) {
        return <IconSend />;
      }
      if (direction === Directions_Enum.In) {
        return <IconReceive />;
      }
      return <IconReceive />;
    case Tags_Enum.deposit:
    case Tags_Enum.Deposit:
      return <IconSend />;
    default:
      return <IconExchange />;
  }
};

const getTagName = (tag: any, direction: Directions_Enum | undefined) => {
  switch (tag) {
    case Tags_Enum.DelegateStake:
      return t("stake");
    case Tags_Enum.CompleteWithdrawal:
      return t("completeWithdrawal");
    case Tags_Enum.WithdrawStakeRewards:
      return t("claimRewards");
    case Tags_Enum.AddLiquidity:
      return t("addLiquidity");
    case Tags_Enum.withdrawPending:
      return t("withdrawPending");
    case Tags_Enum.RemoveLiquidity:
      return t("removeLiquidity");
    case Tags_Enum.IncreaseAllowance:
      return t("increaseAllowance");
    case Tags_Enum.WithdrawStakeAmt:
      return t("withdrawStake");
    case Tags_Enum.Claim:
      return t("claim");
    case Tags_Enum.ClaimMulti:
      return t("claimMulti");
    case Tags_Enum.SwapExactZILForTokens:
    case Tags_Enum.SwapExactTokensForZIL:
    case Tags_Enum.SwapZILForExactTokens:
    case Tags_Enum.SwapExactTokensForTokens:
    case Tags_Enum.SwapTokensForExactTokens:
    case Tags_Enum.SwapExactTokensForExactTokens:
      return t("swap");
    case Tags_Enum.Empty:
    case Tags_Enum.Transfer:
      if (direction === Directions_Enum.Out) {
        return t("send");
      }
      if (direction === Directions_Enum.In) {
        return t("receive");
      }
      return t("transfers");
    case Tags_Enum.deposit:
    case Tags_Enum.Deposit:
      return t("deposit");
    default:
      return tag;
  }
};
const getToken = (
  tokens: IToken[],
  events: string | any[],
  value: any,
  tag: any,
  direction?: Directions_Enum
) => {
  let tokenSend: ISwapTransactionToken | any = [];
  let tokenReceive: ISwapTransactionToken | any = [];
  if (events && events.length > 0) {
    switch (tag) {
      case Tags_Enum.SwapTokensForExactTokens:
      case Tags_Enum.DirectSwapExactTokens0ToTokens1:
      case Tags_Enum.SwapExactTokensForTokens:
        try {
          if (events[2]) {
            const sendToken = tokens.find(
              (token: { address_bech32: any }) =>
                token.address_bech32 === events[2]["address"]
            );
            if (sendToken) {
              tokenSend.push({
                token: sendToken,
                amount: events[2]["params"]["amount"],
              });
            }
          }

          if (events[3]) {
            const receiveToken = tokens.find(
              (token: { address_bech32: any }) =>
                token.address_bech32 === events[3]["address"]
            );
            if (receiveToken) {
              tokenReceive.push({
                token: receiveToken,
                amount: events[3]["params"]["amount"],
              });
            }
          }
        } catch (error) {
          console.log(error);
        }
        break;
      case Tags_Enum.SwapExactTokensForZIL:
        try {
          if (events[1]) {
            const token = tokens.find(
              (token: { address_bech32: any }) =>
                token.address_bech32 === events[0]["params"]["pool"]
            );
            if (token) {
              tokenSend.push({
                token: token,
                amount: events[1]["params"]["amount"],
              });
            }
          }
          if (events[0]) {
            const zilToken = tokens.find(
              (token: { address_bech32: string }) =>
                token.address_bech32 ===
                "zil1qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq9yf6pz"
            );
            tokenReceive.push({
              token: zilToken,
              amount: events[0]["params"]["output"][0]["params"][0],
            });
          }
        } catch (error) {
          console.log(error);
        }
        break;
      case Tags_Enum.SwapTokensForExactZIL:
        try {
          if (events[1]) {
            const token = tokens.find(
              (token: { address_bech32: any }) =>
                token.address_bech32 === events[0]["address"]
            );
            if (token) {
              tokenSend.push({
                token: token,
                amount: events[1]["params"]["amount"],
              });
            }
          }
        } catch (error) {
          console.log(error);
        }
        break;
      case Tags_Enum.SwapExactZILForTokens:
      case Tags_Enum.SwapZILForExactTokens:
        tokenSend.push({
          token: tokens.find(
            (token: { address_bech32: string }) =>
              token.address_bech32 ===
              "zil1qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq9yf6pz"
          ),
          amount: value,
        });

        if (events[1]) {
          const token = tokens.find(
            (token: { address_bech32: any }) =>
              token.address_bech32 === events[1]["address"]
          );
          if (token) {
            tokenReceive.push({
              token: token,
              amount: events[1]["params"]["amount"],
            });
          }
        }
        break;
      case Tags_Enum.Claim:
      case Tags_Enum.ClaimAirdrop:
        if (events[1]) {
          const token = tokens.find(
            (token) => token.address_bech32 === events[1]["address"]
          );

          if (token) {
            tokenReceive.push({
              token: token,
              amount: events[1]["params"]["amount"],
            });
          }
        }
        break;
      case Tags_Enum.ClaimMulti:
        if (events[21] && events[10]) {
          const token = tokens.find(
            (token) => token.address_bech32 === events[21]["address"]
          );

          if (token) {
            tokenReceive.push({
              token: token,
              amount: events[21]["params"]["amount"],
            });
          }
          const token2 = tokens.find(
            (token) => token.address_bech32 === events[10]["address"]
          );

          if (token2) {
            tokenReceive.push({
              token: token2,
              amount: events[10]["params"]["amount"],
            });
          }
        } else if (events[7] && events[3]) {
          const token = tokens.find(
            (token) => token.address_bech32 === events[7]["address"]
          );

          if (token) {
            tokenReceive.push({
              token: token,
              amount: events[7]["params"]["amount"],
            });
          }
          const token2 = tokens.find(
            (token) => token.address_bech32 === events[2]["address"]
          );

          if (token2) {
            tokenReceive.push({
              token: token2,
              amount: events[2]["params"]["amount"],
            });
          }
        } else if (events[6]) {
          const token = tokens.find(
            (token) => token.address_bech32 === events[6]["address"]
          );

          if (token) {
            tokenReceive.push({
              token: token,
              amount: events[6]["params"]["amount"],
            });
          }
        } else if (events[5]) {
          const token = tokens.find(
            (token) => token.address_bech32 === events[5]["address"]
          );

          if (token) {
            tokenReceive.push({
              token: token,
              amount: events[5]["params"]["amount"],
            });
          }
        } else if (events[4]) {
          const token = tokens.find(
            (token) => token.address_bech32 === events[4]["address"]
          );

          if (token) {
            tokenReceive.push({
              token: token,
              amount: events[4]["params"]["amount"],
            });
          }
        } else if (events[3] && events[2]) {
          const token = tokens.find(
            (token) => token.address_bech32 === events[3]["address"]
          );

          if (token) {
            tokenReceive.push({
              token: token,
              amount: events[3]["params"]["amount"],
            });
          }
          const token2 = tokens.find(
            (token) => token.address_bech32 === events[2]["address"]
          );

          if (token2) {
            tokenReceive.push({
              token: token2,
              amount: events[2]["params"]["amount"],
            });
          }
        } else if (events[3]) {
          const token = tokens.find(
            (token) => token.address_bech32 === events[3]["address"]
          );

          if (token) {
            tokenReceive.push({
              token: token,
              amount: events[3]["params"]["amount"],
            });
          }
        } else if (events[2]) {
          const token = tokens.find(
            (token) => token.address_bech32 === events[2]["address"]
          );

          if (token) {
            tokenReceive.push({
              token: token,
              amount: events[2]["params"]["amount"],
            });
          }
        } else if (events[1]) {
          const token = tokens.find(
            (token) => token.address_bech32 === events[1]["address"]
          );

          if (token) {
            tokenReceive.push({
              token: token,
              amount: events[1]["params"]["amount"],
            });
          }
        } else if (events[0]) {
          const token = tokens.find(
            (token) => token.address_bech32 === events[0]["address"]
          );

          if (token) {
            tokenReceive.push({
              token: token,
              amount: events[0]["params"]["amount"],
            });
          }
        }
        break;
      case Tags_Enum.Transfer:
        if (events[0]) {
          const token = tokens.find(
            (token) => token.address_bech32 === events[0]["address"]
          );

          if (token) {
            if (direction === Directions_Enum.Out) {
              tokenSend.push({
                token: token,
                amount: events[0]["params"]["amount"],
              });
            }
            if (direction === Directions_Enum.In) {
              tokenReceive.push({
                token: token,
                amount: events[0]["params"]["amount"],
              });
            }
          }
        }
        break;
      case Tags_Enum.Completewithdrawal:
        console.log(events);
        break;
      case Tags_Enum.WithdrawRewards:
      case Tags_Enum.withdrawPending:
      case Tags_Enum.withdrawStake:
        if (events[0]) {
          const token = tokens.find(
            (token) => token.address_bech32 === events[0]["address"]
          );

          if (token) {
            tokenReceive.push({
              token: token,
              amount: events[0]["params"]["amount"],
            });
          }
        }
        break;
      case Tags_Enum.WithdrawStakeAmt:
        if (events[0]) {
          const token = tokens.find(
            (token) => token.address_bech32 === events[0]["address"]
          );

          if (token) {
            tokenSend.push({
              token: token,
              amount: events[0]["params"]["decreased_amt"],
            });
          }
        }
        break;
      case Tags_Enum.ExistingZRC2ToBridgedZRC2:
        if (events[2] && events[1]) {
          const token = tokens.find(
            (token) => token.address_bech32 === events[2]["address"]
          );

          if (token) {
            tokenReceive.push({
              token: token,
              amount: events[2]["params"]["amount"],
            });
            tokenSend.push({
              token: token,
              amount: events[1]["params"]["amount"],
            });
          }
        }
        break;
      case Tags_Enum.claimTransferReward:
        if (events[1]) {
          const token = tokens.find(
            (token) => token.address_bech32 === events[1]["address"]
          );

          if (token) {
            tokenReceive.push({
              token: token,
              amount: events[1]["params"]["amount"],
            });
          }
        }
        break;
      case Tags_Enum.WithdrawStakeRewards:
        if (events[2]) {
          const token = tokens.find(
            (token) => token.address_bech32 === events[2]["address"]
          );

          if (token) {
            tokenReceive.push({
              token: token,
              amount:
                events[2]["params"]["amount"] ?? events[1]["params"]["amt"],
            });
          }
        }
        break;
      // case Tags_Enum.IncreaseAllowance:
      //   if (events[0]) {
      //     const token = tokens.find(
      //       (token) => token.address_bech32 === events[0]["address"]
      //     );

      //     if (token) {
      //       tokenReceive.push({
      //         token: token,
      //         amount: nFormatter(
      //           parseInt(events[0]["params"]["new_allowance"]),
      //           2
      //         ),
      //       });
      //     }
      //   }
      //   break;
      case Tags_Enum.Withdraw:
        if (events[2]) {
          const token = tokens.find(
            (token) => token.address_bech32 === events[2]["address"]
          );

          if (token) {
            tokenReceive.push({
              token: token,
              amount: events[2]["params"]["amount"],
            });
          }
        }
        if (events[4]) {
          const token = tokens.find(
            (token) => token.address_bech32 === events[4]["address"]
          );

          if (token) {
            tokenReceive.push({
              token: token,
              amount: events[4]["params"]["amount"],
            });
          }
        }
        break;
      case Tags_Enum.withdraw:
        if (events[0]) {
          const token = tokens.find((token) => token.symbol === "ZIL");

          if (token) {
            tokenReceive.push({
              token: token,
              amount: events[0]["params"]["amount"],
            });
          }
        }
        break;
      case Tags_Enum.RemoveLiquidity:
        if (events[1]) {
          const token = tokens.find(
            (token) => token.address_bech32 === events[1]["address"]
          );

          if (token) {
            tokenReceive.push({
              token: token,
              amount: events[1]["params"]["amount"],
            });
          }
        }
        break;
      case Tags_Enum.RemoveTokenLiquidity:
        if (events[1]) {
          const token = tokens.find(
            (token) => token.address_bech32 === events[1]["address"]
          );

          if (token) {
            tokenReceive.push({
              token: token,
              amount: events[1]["params"]["amount"],
            });
          }
        }
        if (events[2]) {
          const token = tokens.find(
            (token) => token.address_bech32 === events[2]["address"]
          );

          if (token) {
            tokenReceive.push({
              token: token,
              amount: events[2]["params"]["amount"],
            });
          }
        }
        break;
      case Tags_Enum.AddTokenLiquidity:
        if (events[1]) {
          const token = tokens.find(
            (token) => token.address_bech32 === events[1]["address"]
          );

          if (token) {
            tokenSend.push({
              token: token,
              amount: events[1]["params"]["amount"],
            });
          }
        }
        if (events[2]) {
          const token = tokens.find(
            (token) => token.address_bech32 === events[2]["address"]
          );

          if (token) {
            tokenSend.push({
              token: token,
              amount: events[2]["params"]["amount"],
            });
          }
        }
        break;
      case Tags_Enum.removeStake:
        console.log(tag);
        const token = tokens.find((token) => token.symbol === "ZIL");

        if (token) {
          tokenReceive.push({
            token: token,
            amount: value,
          });
        }
        break;
      case Tags_Enum.Deposit:
      case Tags_Enum.deposit:
        if (events[2]) {
          const token = tokens.find(
            (token) => token.address_bech32 === events[2]["address"]
          );

          if (token) {
            tokenSend.push({
              token: token,
              amount: events[2]["params"]["amount"],
            });
          }
        }
        break;
    }
  }
  return {
    tokenSend: tokenSend,
    tokenReceive: tokenReceive,
  };
};
export { getIcon, getTagName, getToken };
