import React from "react";
import {
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
  Box,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Button,
} from "@chakra-ui/react";
import { useAppColor } from "src/hooks";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "src/redux/hooks";
import { IWallet, loginSelector } from "src/redux/slices/loginSlice";
import { BsSearch } from "react-icons/bs";
import { formatUsdt } from "src/lib/formatCurrency";
import { overviewSelector } from "src/redux/slices/overviewSlice";
import { TokenLogo } from "src/atoms";
import ScrollBox from "src/atoms/ScrollBox";

interface ModalSendAddressProps {
  onClose: () => void;
  setAddress: (address: string) => void;
}

const ModalSendAddress: React.FC<ModalSendAddressProps> = ({
  onClose,
  setAddress,
}) => {
  const { t } = useTranslation(["send"]);
  const { text3, main2, main4 } = useAppColor();
  const { savedWallets, currentWallet, exchangeRate, isHidden } =
    useAppSelector(loginSelector);
  const { zilPriceInUsd } = useAppSelector(overviewSelector);

  const [searchAddress, setSearchAddress] = React.useState<string>("");
  const [searchResult, setSearchResult] =
    React.useState<IWallet[]>(savedWallets);

  const onSelectAddress = React.useCallback(
    (address: string) => {
      onClose && onClose();
      setAddress && setAddress(address);
    },
    [onClose, setAddress]
  );
  React.useEffect(() => {
    setSearchAddress("");
    setSearchResult(savedWallets);
  }, [savedWallets]);

  const onSearchChange = React.useCallback(
    (text: string) => {
      setSearchAddress(text);
      if (text === "" || text === null || text === undefined) {
        setSearchResult(savedWallets);
      } else {
        let data = [];
        for (let i = 0; i < savedWallets.length; i++) {
          if (
            savedWallets[i].zilAddress
              .toLowerCase()
              .includes(text.toLowerCase()) ||
            savedWallets[i].name.toLowerCase().includes(text.toLowerCase())
          ) {
            data.push(savedWallets[i]);
          }
        }
        setSearchResult(data);
      }
    },
    [savedWallets]
  );

  return (
    <>
      <ModalHeader>{t("otherWallet")}</ModalHeader>
      <ModalCloseButton />
      <ModalBody pb="6">
        <Box w="full" borderWidth="0">
          <InputGroup w="full">
            <InputRightElement color="gray.400" pointerEvents="none">
              <BsSearch />
            </InputRightElement>
            <Input
              bg={main2}
              placeholder={t("searchAddress")}
              w="full"
              onChange={(event) => onSearchChange(event.target.value)}
              value={searchAddress}
            />
          </InputGroup>
        </Box>
        <ScrollBox maxH="sm" px="2" mt="4">
          {searchResult &&
            searchResult.map((item: IWallet, index) => {
              const { zilAddress, avatar, name, balance } = item;
              if (currentWallet?.zilAddress === zilAddress) {
                return <Box key={index} />;
              }
              const walletAddress =
                zilAddress.length > 13
                  ? zilAddress.substr(0, 6) +
                    "..." +
                    zilAddress.substr(zilAddress.length - 6)
                  : zilAddress;
              return (
                <Flex
                  key={index}
                  py="4"
                  alignItems="center"
                  w="full"
                  justifyContent="space-between"
                  borderBottom="1px solid"
                  borderColor={main4}
                >
                  <Flex alignItems="center" w="full" mr="4" overflow="hidden">
                    <TokenLogo mr="2" size="sm" src={avatar} />
                    <Box color={text3}>
                      <Text
                        fontSize="md"
                        lineHeight="6"
                        fontWeight="semibold"
                        isTruncated
                      >
                        {name}
                      </Text>
                      <Flex
                        justifyItems="center"
                        alignItems="center"
                        fontSize="sm"
                        lineHeight="6"
                        fontWeight="normal"
                      >
                        <Text mr="2" isTruncated>
                          {walletAddress}{" "}
                        </Text>
                        <Text fontWeight="semibold" fontSize="md">
                          {formatUsdt(
                            balance * zilPriceInUsd,
                            exchangeRate,
                            isHidden
                          )}
                        </Text>
                      </Flex>
                    </Box>
                  </Flex>
                  <Button
                    children={t("Select")}
                    onClick={() => onSelectAddress(zilAddress)}
                  />
                </Flex>
              );
            })}
        </ScrollBox>
      </ModalBody>
    </>
  );
};

export default ModalSendAddress;
