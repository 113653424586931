const Button = {
  baseStyle: {},
  variants: {
    solid: (props: any) => ({
      bg: props.colorMode === "dark" ? "gray.50" : "gray.900",
      color: props.colorMode === "dark" ? "black" : "white",
      _hover: {
        color: "black",
        bg: props.colorMode === "dark" ? "#01FFB0" : "#01FFB0",
      },
      _active: {
        bg: props.colorMode === "dark" ? "gray.300" : "gray.600",
      },
    }),
    secondary: (props: any) => ({
      bg: props.colorMode === "dark" ? "gray.800" : "gray.100",
      color: props.colorMode === "dark" ? "gray.50" : "gray.900",
      _hover: {
        bg: props.colorMode === "dark" ? "gray.600" : "gray.300",
      },
      _active: {
        bg: props.colorMode === "dark" ? "gray.500" : "gray.400",
      },
    }),
  },
  defaultProps: {
    focusBorderColor: "#01FFB0",
    _focus: {
      borderColor: "#01FFB0",
    },
  },
};

export { Button };
