import React from "react";
import IconBase from "@chakra-ui/icon";
import { SvgIconProps } from "src/constants/type";

const SvgComponent: React.FC<SvgIconProps> = ({
  size,
  ...props
}: SvgIconProps) => {
  return (
    <IconBase {...props} w={size} h={size} viewBox="0 0 16 16">
      <path
        d="M3.68389 3.99944L0.796387 1.11194L1.62122 0.287109L5.33355 3.99944L1.62122 7.71178L0.796387 6.88694L3.68389 3.99944Z"
        fill="currentColor"
      />
    </IconBase>
  );
};

const IconArrowRight = React.memo(SvgComponent);
export default IconArrowRight;
