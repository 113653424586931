import React from "react";
import {
  Tr,
  useBoolean,
  Td,
  SimpleGrid,
  Text,
  Flex,
  Tbody,
} from "@chakra-ui/react";
import { useAppSelector } from "src/redux/hooks";
import {
  loginSelector,
  overviewSelector,
} from "src/redux/slices/overviewSlice";
import { useAppColor } from "src/hooks";
import { useTranslation } from "react-i18next";
import { IStakingInfo, IUnstaking } from "src/constants/interfaces";
import { Images } from "src/assets/images";
import { TokenLogo } from "src/atoms";
import { formatToken } from "src/lib/formatCurrency";
import StakingTableItem from "./StakingTableItem";
import StakingTableHeader from "./StakingTableHeader";
import { sortStakingList } from "src/utils";

interface StakingTableProps {
  claimToken: () => void;
  stakeToken: IStakingInfo;
  last?: boolean;
  active?: boolean;
}

const StakingTable: React.FC<StakingTableProps> = ({
  stakeToken,
  claimToken,
  last,
  active,
}) => {
  const { t } = useTranslation(["overview", "common", "history"]);
  const [show, setShow] = useBoolean(false);
  const { text2, text6, main4 } = useAppColor();

  const { isHidden } = useAppSelector(loginSelector);
  const { tokenRates, tokens } = useAppSelector(overviewSelector);
  const { stakeList, symbol, pendingRewards, unstakingPending } = stakeToken;
  const [unstakingList, setUnstakingList] = React.useState<IUnstaking[]>([]);

  const toggleShow = () => {
    setShow.toggle();
  };
  React.useEffect(() => {
    let unstakingListTmp = [] as IUnstaking[];
    let unstaking = unstakingPending || [];
    unstaking.forEach((item) => {
      const stakingSymbol = item.unstakingSymbol || symbol;
      const curIndex = unstakingListTmp.findIndex(
        (temp) => temp.unstakingSymbol === stakingSymbol
      );
      if (curIndex !== -1) {
        if (item.unstakingSymbol) {
          unstakingListTmp[curIndex].unstaking += item.unstaking;
        }
      } else {
        unstakingListTmp.push({
          unstakingSymbol: stakingSymbol,
          unstaking: item.unstaking,
        });
      }
    });
    setUnstakingList(
      unstakingListTmp.filter((staking) => staking.unstaking > 0)
    );
  }, [symbol, unstakingPending]);
  return (
    <React.Fragment>
      <StakingTableHeader
        last={last}
        toggleShow={toggleShow}
        stakeToken={stakeToken}
        claimToken={claimToken}
      />
      {show && (
        <Tbody>
          {unstakingPending.length > 0 && (
            <Tr bg={main4}>
              <Td />
              <Td />
              <Td isNumeric>
                {unstakingList.map((item, index) => {
                  const stakeIcon =
                    tokens.find(
                      (token) => token.symbol === item.unstakingSymbol
                    )?.icon || Images.zil;
                  return (
                    <Flex key={index} alignItems="center" justify="end">
                      <Text
                        fontSize="14px"
                        lineHeight="20px"
                        fontWeight="600"
                        color={text2}
                        marginRight="1"
                      >
                        {item.unstaking > 0
                          ? formatToken(
                              item.unstaking,
                              item.unstakingSymbol,
                              isHidden
                            )
                          : "0"}
                      </Text>
                      <TokenLogo size="2xs" src={stakeIcon} />
                    </Flex>
                  );
                })}

                <Text fontSize="12px" lineHeight="16px" color={text6}>
                  {t("currentUnstaking")}
                </Text>
              </Td>
              <Td isNumeric>
                {/* <Flex alignItems="center" justify="end">
                  <Text
                    fontSize="14px"
                    lineHeight="20px"
                    fontWeight="600"
                    color={text2}
                  >
                    0
                  </Text>
                  <TokenLogo size="2xs" src={Images.zil} />
                </Flex>
                <Text fontSize="12px" lineHeight="16px" color={text6}>
                  {t("availableWithdraw")}
                </Text> */}
              </Td>
              <Td isNumeric>
                {/* <Button
                  children="Withdraw"
                  size="sm"
                  mr="10"
                  bg={brand}
                  textColor={main2}
                  onClick={() => {}}
                /> */}
              </Td>
            </Tr>
          )}
          <Tr>
            <Td colSpan={5} p="0">
              <SimpleGrid
                columns={{ base: 1, md: 2, lg: 3 }}
                spacing="20px"
                p="16px"
              >
                {stakeList &&
                  sortStakingList(stakeList).map((token, index) => {
                    const { stakingBalance } = token;
                    let rate = 1;
                    let pendingReward = undefined;
                    if (pendingRewards) {
                      pendingReward = pendingRewards.find(
                        (pendingReward) =>
                          pendingReward.address === token.address
                      );
                    }
                    if (
                      (!stakingBalance && !pendingReward && !!active) ||
                      (stakingBalance &&
                        stakingBalance <= 0 &&
                        !pendingReward &&
                        !!active)
                    ) {
                      return <React.Fragment key={index} />;
                    }
                    const tokenRate = tokenRates.find(
                      (tokenRate) => tokenRate.token.symbol === symbol
                    );

                    if (tokenRate) {
                      rate = tokenRate.rate;
                    }

                    return (
                      <StakingTableItem
                        key={index}
                        {...stakeToken}
                        {...token}
                        pendingReward={pendingReward}
                        rate={rate}
                      />
                    );
                  })}
              </SimpleGrid>
            </Td>
          </Tr>
        </Tbody>
      )}
    </React.Fragment>
  );
};

export default StakingTable;
