import React from "react";
import {
  Text,
  Button,
  Input,
  useBoolean,
  Flex,
  Divider,
  Box,
} from "@chakra-ui/react";
import {
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import { useAppColor } from "src/hooks";
import { useAppDispatch } from "src/redux/hooks";
import { viewWallet } from "src/redux/slices/loginSlice";
import { apiGetAvatarAddressFromName } from "src/api/avatarApi";
import { toBech32Address } from "@zilliqa-js/zilliqa";
import { useTranslation } from "react-i18next";
import { handleGoLink } from "src/utils";

interface ModalConnectAvatarProps {
  onClose: () => void;
}

const ModalConnectAvatar: React.FC<ModalConnectAvatarProps> = ({ onClose }) => {
  const [name, setName] = React.useState<string>("");
  const dispatch = useAppDispatch();
  const { text4 } = useAppColor();
  const { t } = useTranslation(["login", "common"]);
  const [isError, setError] = useBoolean(false);

  const onConnectAvatar = async () => {
    try {
      const address = await apiGetAvatarAddressFromName(name);
      if (address) {
        dispatch(viewWallet(toBech32Address(address), false, false, name));
        onClose();
      } else {
        setError.on();
      }
      setName("");
    } catch (error) {}
  };

  return (
    <>
      <ModalCloseButton />
      <ModalHeader>
        <Text fontSize="lg" fontWeight="bold" color={text4}>
          {t("loginAvatar")}
        </Text>
      </ModalHeader>
      <ModalBody p="0">
        <Text mx="6" fontSize="sm" color={text4}>
          {t("avatarLogin")}
        </Text>
        <Divider my="6" />
        <Box px="6">
          <Input
            borderColor={isError ? "red.400" : undefined}
            placeholder={t("enterName")}
            onChange={(e) => {
              setError.off();
              setName(e.target.value);
            }}
          />
        </Box>
        <Flex w="full" justifyContent="flex-start"></Flex>
      </ModalBody>
      <ModalFooter justifyContent={"space-between"}>
        <Text
          fontSize="xs"
          fontWeight="semibold"
          color={isError ? "red.400" : text4}
        >
          {isError ? t("avatarNotFound") : t("dontHaveAvatar")}
          <Text
            as="a"
            href="https://avatar.carbontoken.info?ref=heyalfie"
            color={text4}
            textDecorationLine="underline"
            onClick={() => {
              handleGoLink("avatar.carbontoken.info?ref=heyalfie");
            }}
          >
            {t("getOne")}
          </Text>
        </Text>
        <Button children={t("common:track")} onClick={onConnectAvatar} />
      </ModalFooter>
    </>
  );
};

export default ModalConnectAvatar;
