import React from "react";
import { IconBase, IconBaseProps } from "react-icons";

const SvgComponent: React.FC<IconBaseProps> = (props: IconBaseProps) => {
  return (
    <IconBase {...props}>
      <svg viewBox="0 0 14 14">
        <path d="M11.0833 5.83268H11.6667C11.8214 5.83268 11.9697 5.89414 12.0791 6.00354C12.1885 6.11293 12.25 6.26131 12.25 6.41602V12.2493C12.25 12.4041 12.1885 12.5524 12.0791 12.6618C11.9697 12.7712 11.8214 12.8327 11.6667 12.8327H2.33333C2.17862 12.8327 2.03025 12.7712 1.92085 12.6618C1.81146 12.5524 1.75 12.4041 1.75 12.2493V6.41602C1.75 6.26131 1.81146 6.11293 1.92085 6.00354C2.03025 5.89414 2.17862 5.83268 2.33333 5.83268H2.91667V5.24935C2.91667 4.71312 3.02229 4.18214 3.22749 3.68673C3.4327 3.19131 3.73347 2.74117 4.11265 2.362C4.49182 1.98282 4.94196 1.68205 5.43738 1.47684C5.93279 1.27163 6.46377 1.16602 7 1.16602C7.53623 1.16602 8.06721 1.27163 8.56262 1.47684C9.05804 1.68205 9.50818 1.98282 9.88735 2.362C10.2665 2.74117 10.5673 3.19131 10.7725 3.68673C10.9777 4.18214 11.0833 4.71312 11.0833 5.24935V5.83268ZM9.91667 5.83268V5.24935C9.91667 4.4758 9.60938 3.73394 9.06239 3.18695C8.51541 2.63997 7.77355 2.33268 7 2.33268C6.22645 2.33268 5.48459 2.63997 4.93761 3.18695C4.39062 3.73394 4.08333 4.4758 4.08333 5.24935V5.83268H9.91667ZM6.41667 8.16602V10.4993H7.58333V8.16602H6.41667Z" />
      </svg>
    </IconBase>
  );
};
const IconLock = React.memo(SvgComponent);
export default IconLock;
