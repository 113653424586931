import React from "react";
import Icon from "@chakra-ui/icon";
import { SvgIconProps } from "src/constants/type";

const SvgComponent: React.FC<SvgIconProps> = ({
  size,
  ...props
}: SvgIconProps) => {
  return (
    <Icon {...props} w={size} h={size} viewBox="0 0 16 16">
      <path d="M9.78168 0.678662C9.88283 0.577512 10.0093 0.526938 10.1483 0.526938C10.2874 0.526938 10.4139 0.577512 10.515 0.678662C10.7173 0.88096 10.7173 1.2097 10.515 1.412L4.06674 7.86027L10.515 14.3085C10.7173 14.5108 10.7173 14.8396 10.515 15.0419C10.3127 15.2442 9.98398 15.2442 9.78168 15.0419L2.95409 8.22694C2.75179 8.02464 2.75179 7.6959 2.95409 7.4936L9.78168 0.678662Z" />
    </Icon>
  );
};

const IconLeftArrow = React.memo(SvgComponent);
export default IconLeftArrow;
