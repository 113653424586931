import React from "react";
import { Flex, Box } from "@chakra-ui/layout";
import { IconEdit } from "src/assets/icons";
import { useAppColor } from "src/hooks";
import { Input } from "@chakra-ui/input";
import {
  Button,
  Modal,
  ModalContent,
  ModalOverlay,
  useBoolean,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import {
  changeWalletName,
  loginSelector,
  setMessage,
} from "src/redux/slices/loginSlice";
import { AddIcon } from "@chakra-ui/icons";
import ModalAddWallets from "src/templates/ModalAddWallets";
import { useTranslation } from "react-i18next";
import { isConnectZilpay } from "src/api/zilpayApi";
import { LoginMessageEnum } from "src/constants/enum";
import { syncSavedWallet } from "src/api/heyAlfieApi";

interface EditNameProps {}

const EditName: React.FC<EditNameProps> = () => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation(["overview", "sidebar"]);
  const { text3, main6, brand } = useAppColor();
  const {
    currentWallet: _currentWallet,
    savedWallets,
    message,
  } = useAppSelector(loginSelector);

  const currentWallet = savedWallets.find(
    (savedWallet) => savedWallet.zilAddress === _currentWallet?.zilAddress
  );

  const dispatch = useAppDispatch();
  const [inputValue, setInputValue] = React.useState<string>("");
  const [isEditing, setIsEditing] = useBoolean(false);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const onEdit = () => {
    if (!currentWallet || !isConnectZilpay(currentWallet.zilAddress)) {
      toast({
        id: "pleaseConnectWallet",
        description: t("common:pleaseConnectWallet"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    setIsEditing.toggle();
    if (!isEditing && inputRef && inputRef.current) {
      inputRef.current.focus({ preventScroll: true });
    }
  };

  React.useEffect(() => {
    setInputValue(currentWallet?.name || "");
  }, [currentWallet]);

  const onBlur = () => {
    if (currentWallet?.name !== inputValue) {
      dispatch(changeWalletName(inputValue));
    }
  };
  const onAddWallet = () => {
    onOpen();
  };

  const onSyncWallet = React.useCallback(async () => {
    if (!currentWallet) {
      return;
    }
    const { signature, message, publicKey } = await window.zilPay.wallet.sign(
      "Sync Saved Wallet to DB"
    );

    if (!signature || !message || !publicKey) {
      return;
    }
    let subWallets = [] as string[];
    savedWallets.forEach((saved) => {
      if (saved.zilAddress !== currentWallet.zilAddress) {
        subWallets.push(saved.zilAddress);
      }
    });
    const result = await syncSavedWallet(currentWallet.zilAddress, subWallets);
    if (result.status) {
      toast({
        id: "syncWalletSuccess",
        description: t("profile:syncWalletSuccess"),
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [currentWallet, savedWallets, t, toast]);

  React.useEffect(() => {
    const emptyMessage = { id: "", message: "" };
    if (
      message?.id === LoginMessageEnum.UpdateNameFailed ||
      message?.id === LoginMessageEnum.UpdateNameSuccess
    ) {
      dispatch(setMessage(emptyMessage));
      setInputValue(currentWallet?.name || inputValue);
      if (!toast.isActive(message.id)) {
        toast({
          id: message.id,
          description: message.message,
          status: message.status,
          duration: 3000,
          isClosable: true,
        });
      }
    }
  }, [message, dispatch, toast, currentWallet, inputValue]);

  return (
    <Flex w="100%" pl="0px" alignItems="center" justifyContent="space-between">
      <Flex>
        <Box w="100px">
          <Input
            ref={inputRef}
            value={inputValue}
            onChange={
              isEditing
                ? (event) => setInputValue(event.currentTarget.value)
                : () => {}
            }
            fontSize="xl"
            fontWeight="bold"
            variant={isEditing ? "flushed" : "unstyled"}
            onBlur={onBlur}
          />
        </Box>
        <IconEdit
          fill={text3}
          size="6"
          mx="3"
          onClick={onEdit}
          cursor="pointer"
        />
      </Flex>

      <Button
        variant="outline"
        leftIcon={<AddIcon w={3} h={3} color={main6} />}
        onClick={onAddWallet}
        color={main6}
        size="sm"
        _hover={{
          bg: brand,
          color: "black",
        }}
      >
        {t("sidebar:addWallet")}
      </Button>

      <Button
        ml="3"
        variant="outline"
        onClick={onSyncWallet}
        color={main6}
        size="sm"
        _hover={{
          bg: brand,
          color: "black",
        }}
      >
        {t("sidebar:Sync")}
      </Button>

      <Modal size={"2xl"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalAddWallets />
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default EditName;
