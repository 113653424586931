import React from "react";
import { Box } from "@chakra-ui/layout";
import {
  Text,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  BoxProps,
  Modal,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { IconArrowDown } from "src/assets/icons";
import { TokenLogo } from "src/atoms";
import { useAppColor } from "src/hooks";
import { useTranslation } from "react-i18next";
import { IWalletToken } from "src/redux/slices/overviewSlice";
import { Images } from "src/assets/images";
import { formatToken } from "src/lib/formatCurrency";
import ModalToken from "./ModalToken";
import { useAppSelector } from "src/redux/hooks";
import { loginSelector } from "src/redux/slices/loginSlice";

interface TokenInputProps extends BoxProps {
  withoutBalance?: boolean;
  currentToken?: IWalletToken;
  tokenList: IWalletToken[];
  amount?: number | string;
  setAmount: (amount: number) => void;
  setCurrentToken: (walletToken: IWalletToken) => void;
}

const TokenInput: React.FC<TokenInputProps> = ({
  withoutBalance,
  currentToken,
  tokenList,
  amount,
  setAmount,
  setCurrentToken,
  ...props
}) => {
  const { t } = useTranslation(["swap", "common"]);
  const { text2, text3, text5, main2 } = useAppColor();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isHidden } = useAppSelector(loginSelector);

  return (
    <>
      <Box {...props} mt="3">
        <InputGroup mt="10px">
          <Input
            type="number"
            placeholder={t("common:amount")}
            size="lg"
            bgColor={main2}
            value={
              !amount || amount === 0
                ? "0"
                : amount >= 1
                ? `${amount}`.replace(/^0+/, "")
                : amount
            }
            onChange={(event) => {
              const value = parseFloat(`${event.target.value}`);
              setAmount(value);
            }}
            pr="40"
          />
          <InputRightElement w="max-content" px="4" mt="1" cursor="pointer">
            <Flex alignItems="center" onClick={onOpen}>
              <TokenLogo
                src={currentToken ? currentToken.token.icon : Images.zil}
                size="xs"
              />
              <Text
                color={text3}
                mx="3"
                textTransform="uppercase"
                fontSize="sm"
              >
                {currentToken ? currentToken.token.symbol : "ZIL"}
              </Text>
              <IconArrowDown />
            </Flex>
          </InputRightElement>
        </InputGroup>
        {!withoutBalance && (
          <Text
            w="full"
            display="flex"
            alignItems="center"
            justifyContent="end"
            mt="16px"
            fontSize="xs"
            lineHeight="4"
            fontWeight="bold"
            color={text5}
            letterSpacing="wider"
          >
            {t("common:balance")}:
            <Text
              as="span"
              fontSize="xs"
              lineHeight="4"
              fontWeight="bold"
              color={text2}
              letterSpacing="wider"
              ml="8px"
            >
              {currentToken
                ? formatToken(
                    currentToken.balance,
                    currentToken.token.symbol,
                    isHidden
                  )
                : "0 ZIL"}
            </Text>
          </Text>
        )}
      </Box>
      <Modal onClose={onClose} isOpen={isOpen} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalToken
            onClose={onClose}
            tokenList={tokenList}
            setCurrentToken={setCurrentToken}
          />
        </ModalContent>
      </Modal>
    </>
  );
};

export default TokenInput;
