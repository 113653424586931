import React from "react";
import Icon from "@chakra-ui/icon";
import { SvgIconProps } from "src/constants/type";

const SvgComponent: React.FC<SvgIconProps> = ({
  size,
  ...props
}: SvgIconProps) => {
  return (
    <Icon {...props} w={size} h={size} viewBox="0 0 24 24">
      <path fill="currentColor" d="M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z" />
    </Icon>
  );
};

const IconAdd = React.memo(SvgComponent);
export default IconAdd;
