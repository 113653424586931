import React from "react";
import {
  Text,
  SimpleGrid,
  Icon,
  AvatarGroup,
  Box,
  Flex,
  Center,
} from "@chakra-ui/react";
import { IPortfolioData } from "../Portfolio";
import { useAppColor } from "src/hooks";
import { t } from "i18next";
import { TokenLogo } from "src/atoms";
import { useAppSelector } from "src/redux/hooks";
import { loginSelector } from "src/redux/slices/loginSlice";
import { formatUsdt } from "src/lib/formatCurrency";
import { overviewSelector } from "src/redux/slices/overviewSlice";

interface CategoriesContainerProps {
  pieChartData: IPortfolioData[];
  onClick: (index: number) => void;
}

const CategoriesContainer: React.FC<CategoriesContainerProps> = ({
  pieChartData,
  onClick,
}) => {
  const { brand, text2, main4, main6 } = useAppColor();
  const { savedWallets, exchangeRate, isHidden } =
    useAppSelector(loginSelector);
  const { zilPriceInUsd } = useAppSelector(overviewSelector);
  return (
    <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing="20px">
      {pieChartData.map((pieChart: IPortfolioData, index) => {
        const { color, holdings, icon, name, value } = pieChart;
        return (
          <Box
            key={index}
            cursor="pointer"
            onClick={() => onClick(index)}
            borderWidth={1}
            borderColor={main4}
            borderRadius="12px"
            px={4}
            py={5}
          >
            <Flex justify="space-between" alignItems="center">
              <Flex alignItems="center">
                <Center bg={color} w="8" h="8" borderRadius="6px">
                  <Icon as={icon} color={text2} boxSize="4" />
                </Center>
                <Text ml="2" fontSize="sm" fontWeight="semibold" color={text2}>
                  {name}
                </Text>
              </Flex>
              <Text fontSize="sm" fontWeight="semibold" color={brand}>
                {formatUsdt(value * zilPriceInUsd, exchangeRate, isHidden)}
              </Text>
            </Flex>
            <Box ml="10" mt="3">
              {index === 0 ? (
                <Text fontSize="sm" fontWeight="semibold" color={main6}>
                  {savedWallets.length}
                </Text>
              ) : index === 3 ? (
                <Text fontSize="sm" fontWeight="semibold" color={main6}>
                  {t("profile:estimatedReward")}{" "}
                  {formatUsdt(value * zilPriceInUsd, exchangeRate, isHidden)}
                </Text>
              ) : (
                <AvatarGroup size="2xs" spacing={2} max={8} fontSize="xx-small">
                  {holdings.length !== 0 ? (
                    holdings.map((holding, index) => (
                      <TokenLogo key={index} src={holding} />
                    ))
                  ) : (
                    <TokenLogo visibility="hidden" />
                  )}
                </AvatarGroup>
              )}
            </Box>
          </Box>
        );
      })}
    </SimpleGrid>
  );
};

export default CategoriesContainer;
