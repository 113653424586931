import React from "react";
import { Text, useBoolean, Box, Flex, Tag } from "@chakra-ui/react";
import { IconFilledArrow } from "src/assets/icons";
import { TokenLogo } from "src/atoms";
import { formatToken } from "src/lib/formatCurrency";
import { useTranslation } from "react-i18next";
import { useAppColor } from "src/hooks";
import { useAppSelector } from "src/redux/hooks";
import { loginSelector } from "src/redux/slices/loginSlice";
import { handleGoLink } from "src/utils";
import { IStakingInfo } from "src/constants/interfaces";
import { overviewSelector } from "src/redux/slices/overviewSlice";
import { Images } from "src/assets/images";

interface StakingHeaderMbProps {
  stakeToken: IStakingInfo;
  toggleShow: () => void;
}

interface HeaderToken {
  symbol?: string;
  amount: number;
}

const StakingHeaderMb: React.FC<StakingHeaderMbProps> = ({
  stakeToken,
  toggleShow,
}) => {
  const {
    name,
    stakeLogo,
    link,
    apy,
    symbol,
    subName,
    stakeList,
    pendingRewards,
  } = stakeToken;
  const { t } = useTranslation(["overview", "common"]);
  const [show, setShow] = useBoolean(false);
  const { brand, text1, text2, text3, text6, main3 } = useAppColor();
  const { isHidden } = useAppSelector(loginSelector);
  const { tokens } = useAppSelector(overviewSelector);

  const [totalDeposit, setTotalDeposit] = React.useState<HeaderToken[]>([]);
  const [pendingRewardList, setPendingRewardList] = React.useState<
    HeaderToken[]
  >([]);

  const noDeposit =
    totalDeposit.filter((deposit) => deposit.amount > 0).length === 0;

  React.useEffect(() => {
    let totalDepositTmp = [] as HeaderToken[];
    stakeList.forEach((token) => {
      const stakeSymbol = token.stakeSymbol || symbol;
      const curIndex = totalDepositTmp.findIndex(
        (temp) => temp.symbol === stakeSymbol
      );
      if (curIndex !== -1) {
        if (token.stakingBalance) {
          totalDepositTmp[curIndex].amount += token.stakingBalance;
        }
      } else {
        totalDepositTmp.push({
          symbol: stakeSymbol,
          amount: token.stakingBalance || 0,
        });
      }
    });
    setTotalDeposit(totalDepositTmp);
  }, [stakeList, symbol]);

  React.useEffect(() => {
    let pendingRewardListTmp = [] as HeaderToken[];
    pendingRewards &&
      pendingRewards.forEach((pendingReward) => {
        const rewardSymbol = pendingReward.pendingRewardSymbol || symbol;
        const curIndex = pendingRewardListTmp.findIndex(
          (temp) => temp.symbol === rewardSymbol
        );
        if (curIndex !== -1) {
          if (pendingReward.pendingReward) {
            pendingRewardListTmp[curIndex].amount +=
              pendingReward.pendingReward;
          }
        } else {
          pendingRewardListTmp.push({
            symbol: rewardSymbol,
            amount: pendingReward.pendingReward,
          });
        }
      });
    setPendingRewardList(
      pendingRewardListTmp.filter((reward) => reward.amount > 0)
    );
  }, [pendingRewards, symbol]);

  return (
    <Box borderBottomWidth="1px" borderBottomColor={main3}>
      <Flex px="4" alignItems="flex-start">
        <TokenLogo src={stakeLogo} size="sm" mr="2" mt="4" />
        <Flex
          alignItems="center"
          flex="1"
          justifyContent="space-between"
          py="3"
          borderBottomWidth="1px"
          borderBottomColor={main3}
        >
          <Box>
            <Text color={text2} fontSize="sm" fontWeight="semibold">
              {name}
            </Text>
            <Text
              cursor="pointer"
              color={text6}
              fontSize="xs"
              onClick={() => handleGoLink(link)}
              mb="1"
            >
              {subName}
            </Text>
            <Tag bg={brand} color="black">
              {t(name === "GRPH" ? "common:apr" : "common:apy")} {apy}%
            </Tag>
          </Box>
          <Flex alignItems="center">
            <IconFilledArrow
              fill={text1}
              cursor="pointer"
              onClick={() => {
                toggleShow();
                setShow.toggle();
              }}
              _hover={{
                fill: text3,
              }}
              size="6"
              style={{ transform: `rotate(${show ? 0 : 180}deg)` }}
            />
          </Flex>
        </Flex>
      </Flex>
      <Flex alignItems="center" px="4" pr="14">
        <Box w="8" h="8" mr="2" />
        <Flex
          alignItems="center"
          flex="1"
          justifyContent="space-between"
          py="3"
        >
          <Box>
            {!noDeposit && (
              <>
                <Flex
                  w="full"
                  alignItems="center"
                  justifyContent="flex-end"
                  color={text1}
                  fontSize="sm"
                  fontWeight="semibold"
                >
                  {totalDeposit.map((item, index) => {
                    if (index > 1 || item.amount <= 0) {
                      return <React.Fragment key={index} />;
                    }

                    const stakeIcon =
                      tokens.find((token) => token.symbol === item.symbol)
                        ?.icon || Images.zil;

                    return (
                      <React.Fragment key={index}>
                        <Text>
                          {index !== 0 && " + "}
                          {formatToken(
                            item.amount,
                            item.symbol || "ZIL",
                            isHidden
                          )}
                        </Text>
                        <TokenLogo size="2xs" src={stakeIcon} ml="1" />
                      </React.Fragment>
                    );
                  })}
                  {totalDeposit && totalDeposit.length > 2 && (
                    <Text ml="2">...</Text>
                  )}
                </Flex>
                <Text color={text3} fontSize="xs">
                  {t("totalDeposit")}
                </Text>
              </>
            )}
          </Box>
          <Box>
            {pendingRewardList.length > 0 ? (
              <React.Fragment>
                <Flex
                  w="full"
                  alignItems="center"
                  justifyContent="flex-end"
                  color={text1}
                  fontSize="sm"
                  fontWeight="semibold"
                >
                  {pendingRewardList.map((item, index) => {
                    if (index > 1) {
                      return <React.Fragment key={index} />;
                    }

                    const stakeIcon =
                      tokens.find((token) => token.symbol === item.symbol)
                        ?.icon || Images.zil;

                    return (
                      <React.Fragment key={index}>
                        <Text ml="1">
                          {index !== 0 && " + "}
                          {formatToken(
                            item.amount,
                            item.symbol || "ZIL",
                            isHidden
                          )}
                        </Text>
                        <TokenLogo size="2xs" src={stakeIcon} ml="1" />
                      </React.Fragment>
                    );
                  })}
                  {pendingRewardList && pendingRewardList.length > 2 && (
                    <Text ml="2">...</Text>
                  )}
                </Flex>
                <Text color={text3} fontSize="xs">
                  {t("unclaimedReward")}
                </Text>
              </React.Fragment>
            ) : (
              <React.Fragment />
            )}
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};

export default StakingHeaderMb;
