import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fromBech32Address } from "@zilliqa-js/zilliqa";
import { batch } from "react-redux";
import { getWalletProfile } from "src/api/heyAlfieApi";
import { getHeyAlfieNfts } from "src/api/membershipApi";
import { getCollections } from "src/api/nftApi";
import { ICollection } from "src/constants/interfaces";
import { RootState } from "../rootReducer";
import { AppThunk } from "../store";
import { IWallet } from "./loginSlice";

export interface NftError {
  message: string;
}

export interface NftState {
  error?: NftError;
  viewWallet?: IWallet;
  viewCollections: ICollection[];
  viewHeyAlfieNfts: string[];
}

export const initialState: NftState = {
  viewWallet: undefined,
  viewCollections: Array<ICollection>(),
  viewHeyAlfieNfts: Array<string>(),
};

export const viewProfileSlice = createSlice({
  name: "viewProfile",
  initialState,
  reducers: {
    setError: (state, { payload }: PayloadAction<NftError>) => {
      state.error = payload;
    },
    setViewWallet: (state, { payload }: PayloadAction<IWallet>) => {
      state.viewWallet = payload;
    },
    setViewCollections: (state, { payload }: PayloadAction<ICollection[]>) => {
      state.viewCollections = payload;
    },
    setViewHeyAlfieNfts: (state, { payload }: PayloadAction<string[]>) => {
      state.viewHeyAlfieNfts = payload;
    },
  },
});

export const {
  setError,
  setViewWallet,
  setViewCollections,
  setViewHeyAlfieNfts,
} = viewProfileSlice.actions;

export const getViewWallet =
  (address: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      const data = await getWalletProfile([address]);

      const viewWallet: IWallet = {
        isZilPay: false,
        zilAddress: address,
        zilAddressBase16: fromBech32Address(address),
        allStakingList: data.stakingList[0].allStakingList,
        walletTokens: data.walletTokenList[0].walletTokens,
        allLiquidityPools: data.liquidityPoolList[0].allLiquidityPools,
        ...data.wallets[0],
      };
      dispatch(setViewWallet(viewWallet));
    } catch (error) {}
  };

export const getViewWalletCollection =
  (address: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      const collections = await getCollections(address);
      const heyAlfieNfts = await getHeyAlfieNfts(fromBech32Address(address));
      batch(() => {
        dispatch(setViewHeyAlfieNfts(heyAlfieNfts));
        dispatch(setViewCollections(collections));
      });
    } catch (error) {}
  };

export const viewProfileSelector = (state: RootState) => state.viewProfile;

export default viewProfileSlice.reducer;
