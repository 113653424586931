import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { WalletToken } from "./components/WalletToken";
import { useTranslation } from "react-i18next";

export type WalletBalanceProps = {};

const WalletBalance: React.FC<WalletBalanceProps> = () => {
  const { t } = useTranslation(["overview", "common"]);

  return (
    <Box>
      <Flex
        flexDirection={{ base: "column", lg: "row" }}
        justifyContent="space-between"
        alignItems={{ base: "start", lg: "center" }}
        my="8"
      >
        <Text fontSize="xl" fontWeight="bold" mb={{ base: "16px", lg: 0 }}>
          {t("walletBalance")}
        </Text>
      </Flex>
      <WalletToken />
    </Box>
  );
};

export default WalletBalance;
