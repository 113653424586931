import React from "react";
import { PageLayout } from "src/templates";

import Wallet from "./components/Wallet";
import ManageAccount from "./components/ManageAccount";
import ExchangeAccount from "./components/ExchangeAccount";
import { useBoolean } from "@chakra-ui/hooks";

interface TotalWalletPageProps {
  title: string;
}

export const TotalWalletViewPage: React.FC<TotalWalletPageProps> = ({
  title,
}) => {
  const [isShow, toggleShow] = useBoolean(true);
  return (
    <PageLayout title={title}>
      <ManageAccount isShow={isShow} toggleShow={() => toggleShow.toggle()} />
      <Wallet id="totalWallet" isShow={isShow} />
      {false && <ExchangeAccount />}
    </PageLayout>
  );
};

export default TotalWalletViewPage;
