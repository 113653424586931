import React from "react";
import {
  Box,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Text,
  Td,
  Center,
} from "@chakra-ui/react";
import { usePagination } from "@ajna/pagination";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { useTranslation } from "react-i18next";
import { useAppColor } from "src/hooks";

import Transaction from "./Transaction";
import TransactionItem from "./TransactionItem";
import PaginationPanel from "./PaginationPanel";

import { dayjs } from "src/utils";
import { HistorySpec, lHistory } from "src/constants/type";
import { getHistories, historySelector } from "src/redux/slices/historySlice";
import { loginSelector } from "src/redux/slices/loginSlice";
import { ButtonUnlock } from "src/atoms";

interface TableAllProps {}

export const TableAll: React.FC<TableAllProps> = () => {
  const { t } = useTranslation();
  const { text5, main1, main3, main4 } = useAppColor();
  const { listTransaction, total } = useAppSelector(historySelector);
  const { exchangeRate, isHidden, currentWallet } =
    useAppSelector(loginSelector);
  const dispatch = useAppDispatch();
  const pageSize = 25;

  const [_listTransaction, setListTransaction] = React.useState<lHistory[]>([]);

  const { pages, pagesCount, currentPage, setCurrentPage, isDisabled } =
    usePagination({
      total: total, //total history,
      limits: {
        outer: 1,
        inner: 2,
      },
      initialState: {
        pageSize: pageSize,
        isDisabled: false,
        currentPage: 1,
      },
    });

  const getListTransactionByDate = React.useCallback(
    (data: HistorySpec[]): lHistory[] => {
      let content = [];
      let _data = [];
      let first = 0;
      let last =
        data.length > first + pageSize ? first + pageSize : data.length;
      let title = dayjs(data[first]?.timestamp).format("MMMM D, YYYY");

      for (let i = 0; i < last; i++) {
        if (dayjs(data[i]?.timestamp).format("MMMM D, YYYY") === title) {
          content.push(data[i]);
        } else {
          _data.push({ title: title, content: content });
          title = dayjs(data[i]?.timestamp).format("MMMM D, YYYY");
          content = [];
          content.push(data[i]);
        }
      }
      if (content.length > 0) {
        _data.push({ title: title, content: content });
      }
      return _data;
    },
    []
  );

  React.useEffect(() => {
    if (listTransaction && listTransaction.length > 0) {
      setListTransaction(getListTransactionByDate(listTransaction.flat()));
    }
  }, [getListTransactionByDate, listTransaction]);

  const handleGoHistoryDetails = (id: string) => {
    const url = `https://viewblock.io/zilliqa/tx/${id}`;
    window.open(url, "_blank", "noreferrer");
  };

  React.useEffect(() => {
    if (!currentWallet) {
      return;
    }
    dispatch(getHistories(currentWallet.zilAddress, currentPage));
  }, [dispatch, currentWallet, currentPage]);

  return (
    <>
      {isHidden ? (
        <Center
          bg={{ lg: main1 }}
          borderWidth="1px"
          borderRadius="12px"
          height={200}
        >
          <ButtonUnlock />
        </Center>
      ) : (
        <>
          <Box
            bg={main1}
            borderWidth="1px"
            borderRadius="12px"
            overflow="auto"
            borderColor={main4}
            display={{ base: "none", xl: "block" }}
          >
            <Table variant="simple">
              <Thead h="52px">
                <Tr>
                  <Th>{t("transaction")}</Th>
                  <Th>{t("from")}</Th>
                  <Th>{t("to")}</Th>
                  <Th>{t("value")}</Th>
                  <Th isNumeric>{t("fee")}</Th>
                </Tr>
              </Thead>

              <Tbody>
                {_listTransaction.map((i, idx) => {
                  const { title, content } = i;
                  return (
                    <React.Fragment key={idx}>
                      <Tr>
                        <Td p="0" borderWidth="0">
                          <Text
                            fontSize="xs"
                            lineHeight="4"
                            fontWeight="semibold"
                            color={text5}
                            mt="6"
                            ml="6"
                            mb="2"
                          >
                            {title}
                          </Text>
                        </Td>
                      </Tr>
                      {!!content &&
                        content.map((item, index) => {
                          return (
                            <Transaction
                              exchangeRate={exchangeRate}
                              item={item}
                              key={index}
                              onClick={() => {
                                if (item.hash) {
                                  handleGoHistoryDetails(item.hash);
                                }
                              }}
                            />
                          );
                        })}
                    </React.Fragment>
                  );
                })}
              </Tbody>
            </Table>
          </Box>
          <Box display={{ base: "block", xl: "none" }}>
            {_listTransaction.map((i, idx) => {
              const { title, content } = i;
              return (
                <React.Fragment key={idx}>
                  <Text
                    fontSize="xs"
                    lineHeight="4"
                    fontWeight="semibold"
                    color={text5}
                    mt="6"
                    mb="4"
                  >
                    {title}
                  </Text>
                  <Box
                    bg={main1}
                    borderWidth="1px"
                    borderColor={main3}
                    borderRadius="12px"
                    overflow="auto"
                  >
                    {!!content &&
                      content.map((item, index) => {
                        return (
                          <TransactionItem
                            exchangeRate={exchangeRate}
                            item={item}
                            key={index}
                            onClick={() => {
                              if (item.hash) {
                                handleGoHistoryDetails(item.hash);
                              }
                            }}
                          />
                        );
                      })}
                  </Box>
                </React.Fragment>
              );
            })}
          </Box>
          <PaginationPanel
            pagesCount={pagesCount}
            currentPage={currentPage}
            isDisabled={isDisabled}
            onPageChange={(page) => {
              setListTransaction([]);
              setCurrentPage(page);
            }}
            pages={pages}
            mt="24px"
            mb="8px"
          />
        </>
      )}
    </>
  );
};

export default TableAll;
