import { IToken, ITokenRate } from "src/constants/interfaces";

export const apiGetTokenPricesInZil = async (
  tokens: IToken[]
): Promise<ITokenRate[]> => {
  try {
    const res = await fetch("https://api.zilstream.com/rates/latest", {
      headers: {
        accept: "*/*",
        "accept-language":
          "en-US,en;q=0.9,vi;q=0.8,ja;q=0.7,gl;q=0.6,la;q=0.5,es;q=0.4,zh-CN;q=0.3,zh;q=0.2,zh-TW;q=0.1,pl;q=0.1",
        "sec-ch-ua":
          '"Chromium";v="94", "Google Chrome";v="94", ";Not A Brand";v="99"',
        "sec-ch-ua-mobile": "?0",
        "sec-ch-ua-platform": '"macOS"',
        "sec-fetch-dest": "empty",
        "sec-fetch-mode": "cors",
        "sec-fetch-site": "cross-site",
      },
      referrer: "https://api.coingecko.com/",
      referrerPolicy: "strict-origin-when-cross-origin",
      body: null,
      method: "GET",
      mode: "cors",
    });
    const data = await res.json();

    if (!Array.isArray(data)) {
      return [];
    }

    let tokenRates: ITokenRate[] = [];
    data.forEach((datum) => {
      const { address, rate } = datum;
      const token = tokens.find((token) => token.address_bech32 === address);
      if (token) {
        tokenRates.push({
          token: token,
          rate: token.symbol === "ZIL" ? 1 : rate,
        });
      }
    });

    return tokenRates;
  } catch (error) {
    return [];
  }
};
