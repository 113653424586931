import { Images } from "src/assets/images";

export interface IExchangeRate {
  key: string;
  name: string;
  unit: string;
  value: number;
  type: string;
  image?: string;
  tokenValue?: number;
}

const initExchangeRates = {
  usd: {
    name: "US Dollar",
    unit: "$",
    value: 48839.604,
    type: "fiat",
    image: Images.united_state,
  },
  aed: {
    name: "United Arab Emirates Dirham",
    unit: "DH",
    value: 179387.866,
    type: "fiat",
    // image: Images.unitedArabEmirates
  },
  ars: {
    name: "Argentine Peso",
    unit: "$",
    value: 4829680.087,
    type: "fiat",
    image: Images.argentina,
  },
  aud: {
    name: "Australian Dollar",
    unit: "A$",
    value: 67051.648,
    type: "fiat",
    image: Images.australia,
  },
  bdt: {
    name: "Bangladeshi Taka",
    unit: "৳",
    value: 4181989.622,
    type: "fiat",
    image: Images.bangladesh,
  },
  bhd: {
    name: "Bahraini Dinar",
    unit: "BD",
    value: 18414.24,
    type: "fiat",
    // image: Images.bahrain
  },
  bmd: {
    name: "Bermudian Dollar",
    unit: "$",
    value: 48839.604,
    type: "fiat",
    // image: Images.bermuda
  },
  brl: {
    name: "Brazil Real",
    unit: "R$",
    value: 265863.269,
    type: "fiat",
    image: Images.brazil,
  },
  cad: {
    name: "Canadian Dollar",
    unit: "CA$",
    value: 61430.21,
    type: "fiat",
    image: Images.canada,
  },
  chf: {
    name: "Swiss Franc",
    unit: "Fr.",
    value: 45163.056,
    type: "fiat",
  },
  clp: {
    name: "Chilean Peso",
    unit: "CLP$",
    value: 39384256.857,
    type: "fiat",
  },
  cny: {
    name: "Chinese Yuan",
    unit: "¥",
    value: 314854.276,
    type: "fiat",
    image: Images.china,
  },
  czk: {
    name: "Czech Koruna",
    unit: "Kč",
    value: 1065479.922,
    type: "fiat",
  },
  dkk: {
    name: "Danish Krone",
    unit: "kr.",
    value: 312557.447,
    type: "fiat",
  },
  eur: {
    name: "Euro",
    unit: "€",
    value: 42027.212,
    type: "fiat",
    image: Images.european_union,
  },
  gbp: {
    name: "British Pound Sterling",
    unit: "£",
    value: 35891.834,
    type: "fiat",
    image: Images.united_kingdom,
  },
  hkd: {
    name: "Hong Kong Dollar",
    unit: "HK$",
    value: 380301.788,
    type: "fiat",
    image: Images.hong_kong,
  },
  huf: {
    name: "Hungarian Forint",
    unit: "Ft",
    value: 14995597.214,
    type: "fiat",
  },
  idr: {
    name: "Indonesian Rupiah",
    unit: "Rp",
    value: 696894754.854,
    type: "fiat",
    image: Images.indonesia,
  },
  ils: {
    name: "Israeli New Shekel",
    unit: "₪",
    value: 157407.114,
    type: "fiat",
  },
  inr: {
    name: "Indian Rupee",
    unit: "₹",
    value: 3639505.769,
    type: "fiat",
    image: Images.india,
  },
  jpy: {
    name: "Japanese Yen",
    unit: "¥",
    value: 5416214.43,
    type: "fiat",
    image: Images.japan,
  },
  krw: {
    name: "South Korean Won",
    unit: "₩",
    value: 57844623.654,
    type: "fiat",
    image: Images.southKorea,
  },
  kwd: {
    name: "Kuwaiti Dinar",
    unit: "KD",
    value: 14717.814,
    type: "fiat",
  },
  lkr: {
    name: "Sri Lankan Rupee",
    unit: "Rs",
    value: 9767585.856,
    type: "fiat",
  },
  mmk: {
    name: "Burmese Kyat",
    unit: "K",
    value: 91400136.552,
    type: "fiat",
    image: Images.myanmar,
  },
  mxn: {
    name: "Mexican Peso",
    unit: "MX$",
    value: 1004191.151,
    type: "fiat",
  },
  myr: {
    name: "Malaysian Ringgit",
    unit: "RM",
    value: 203856.508,
    type: "fiat",
    image: Images.malaysia,
  },
  ngn: {
    name: "Nigerian Naira",
    unit: "₦",
    value: 20063309.421,
    type: "fiat",
  },
  nok: {
    name: "Norwegian Krone",
    unit: "kr",
    value: 419073.596,
    type: "fiat",
    image: Images.norway,
  },
  nzd: {
    name: "New Zealand Dollar",
    unit: "NZ$",
    value: 70152.67,
    type: "fiat",
    image: Images.newZealand,
  },
  php: {
    name: "Philippine Peso",
    unit: "₱",
    value: 2484226.42,
    type: "fiat",
  },
  pkr: {
    name: "Pakistani Rupee",
    unit: "₨",
    value: 8351283.243,
    type: "fiat",
  },
  pln: {
    name: "Polish Zloty",
    unit: "zł",
    value: 193195.555,
    type: "fiat",
  },
  rub: {
    name: "Russian Ruble",
    unit: "₽",
    value: 3541291.327,
    type: "fiat",
  },
  sar: {
    name: "Saudi Riyal",
    unit: "SR",
    value: 183176.11,
    type: "fiat",
  },
  sek: {
    name: "Swedish Krona",
    unit: "kr",
    value: 426185.18,
    type: "fiat",
  },
  sgd: {
    name: "Singapore Dollar",
    unit: "S$",
    value: 66274.952,
    type: "fiat",
    image: Images.singapore,
  },
  thb: {
    name: "Thai Baht",
    unit: "฿",
    value: 1648483.161,
    type: "fiat",
  },
  try: {
    name: "Turkish Lira",
    unit: "₺",
    value: 433182.869,
    type: "fiat",
  },
  twd: {
    name: "New Taiwan Dollar",
    unit: "NT$",
    value: 1364456.443,
    type: "fiat",
  },
  uah: {
    name: "Ukrainian hryvnia",
    unit: "₴",
    value: 1295770.377,
    type: "fiat",
  },
  vef: {
    name: "Venezuelan bolívar fuerte",
    unit: "Bs.F",
    value: 4890.309,
    type: "fiat",
  },
  vnd: {
    name: "Vietnamese đồng",
    unit: "₫",
    value: 1111634788.53,
    type: "fiat",
    image: Images.vietnam,
  },
  zar: {
    name: "South African Rand",
    unit: "R",
    value: 734961.319,
    type: "fiat",
  },
  xdr: {
    name: "IMF Special Drawing Rights",
    unit: "XDR",
    value: 34578.537,
    type: "fiat",
  },
};

let exchangeRates = Array<IExchangeRate>();

for (const [key, value] of Object.entries(initExchangeRates)) {
  exchangeRates.push({
    key,
    name: value.name,
    unit: value.unit,
    value: value.value,
    type: value.type,
    //@ts-ignore
    image: value.image,
  });
}

export default exchangeRates;
