import React from "react";
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
  useBoolean,
} from "@chakra-ui/react";
import { useAppColor } from "src/hooks";
import { useTranslation } from "react-i18next";

import { IconArrowDown, IconFilledArrow, IconStart } from "src/assets/icons";
import TokenLogo from "src/atoms/TokenLogo";
import { Images } from "src/assets/images";
import { TextCurrency } from "src/molecules";

interface SelectAPairMobileProps {
  onClick?: () => void;
  choiceBtn?: boolean;
}
const selectDataLst = [
  {
    swapName: "Name Swap",
    rating: 4,
    eta: "24mins",
    reviewCount: 198,
    isBestRate: true,
    isFixRate: false,
    saveAmount: 700,
    tokenAmount: "23,146",
    tokenLogo: Images.zil,
  },
  {
    swapName: "Name Swap",
    rating: 4,
    eta: "24mins",
    reviewCount: 198,
    isBestRate: false,
    isFixRate: true,
    saveAmount: 700,
    tokenAmount: "27,091",
    tokenLogo: Images.zil,
  },
  {
    swapName: "Name Swap",
    rating: 4,
    eta: "24mins",
    reviewCount: 198,
    isBestRate: true,
    isFixRate: false,
    saveAmount: 0,
    tokenAmount: "11,906",
    tokenLogo: Images.cex,
  },
  {
    swapName: "Name Swap",
    rating: 4,
    eta: "24mins",
    reviewCount: 198,
    isBestRate: false,
    isFixRate: false,
    saveAmount: 700,
    tokenAmount: "13,146",
    tokenLogo: Images.ezil,
  },
  {
    swapName: "Name Swap",
    rating: 4,
    eta: "24mins",
    reviewCount: 198,
    isBestRate: false,
    isFixRate: false,
    saveAmount: 700,
    tokenAmount: "90,146",
    tokenLogo: Images.gemini,
  },
  {
    swapName: "Name Swap",
    rating: 4,
    eta: "24mins",
    reviewCount: 198,
    isBestRate: false,
    isFixRate: false,
    saveAmount: 32,
    tokenAmount: "21,146",
    tokenLogo: Images.xsgd,
  },
];
const SelectAPairMobile: React.FC<SelectAPairMobileProps> = ({
  onClick,
  choiceBtn,
}) => {
  const { t } = useTranslation(["swap", "common"]);
  const { brand, text1, text3, text5, main3, main4, main6 } = useAppColor();

  const [selectDataList] = React.useState(selectDataLst);
  const [show, setShow] = useBoolean(true);
  const [selectedIndex, setSelectedIndex] = React.useState(-1);

  return (
    <Box display={{ base: "block", lg: "none" }}>
      <Table variant="simple">
        <Tbody>
          {selectDataList.map((item, index) => {
            const {
              swapName,
              eta,
              reviewCount,
              isBestRate,
              isFixRate,
              saveAmount,
              tokenAmount,
              tokenLogo,
            } = item;

            if (index >= 5) {
              return <Tr key={index} />;
            }
            return (
              <Tr
                key={index}
                display="flex"
                border="1px solid"
                borderColor={main4}
                p="0"
                borderRadius="4px"
                mb="15px"
                justifyContent="space-between"
              >
                <Td borderBottom="none" w="full">
                  <Flex alignItems="center" justifyContent="space-between">
                    <Box>
                      <Box display="flex" justifyContent="flex-start">
                        <Text
                          fontSize="10px"
                          fontWeight="500"
                          lineHeight="16px"
                          mr="5px"
                          color="orange.500"
                        >
                          {t("common:save")}
                        </Text>
                        <TextCurrency
                          fontSize="10px"
                          fontWeight="500"
                          lineHeight="16px"
                          color="orange.500"
                        >
                          {saveAmount}
                        </TextCurrency>
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-start"
                      >
                        <Text
                          mr="5px"
                          fontSize="14px"
                          fontWeight="600"
                          lineHeight="20px"
                        >
                          {tokenAmount}
                        </Text>
                        <TokenLogo
                          src={tokenLogo}
                          w={{ base: "2", lg: "4" }}
                          h={{ base: "2", lg: "4" }}
                        />
                      </Box>
                    </Box>
                    <Button
                      backgroundColor={
                        selectedIndex === index && choiceBtn === false
                          ? brand
                          : "transparent"
                      }
                      color={
                        selectedIndex === index && choiceBtn === false
                          ? "black"
                          : text1
                      }
                      border="1px solid"
                      borderColor={main4}
                      onClick={() => {
                        setSelectedIndex(index);
                        !!onClick && onClick();
                      }}
                    >
                      {selectedIndex === index && choiceBtn === false
                        ? t("common:choice")
                        : t("common:exchange")}
                    </Button>
                  </Flex>
                  <Divider my="10px" orientation="horizontal" bg={main4} />

                  <Flex alignItems="center" justifyContent="space-between">
                    <Box>
                      <Box display="flex">
                        <Text
                          fontSize="12px"
                          fontWeight="600"
                          lineHeight="16px"
                          mr="5px"
                        >
                          {swapName}
                        </Text>
                        <IconArrowDown cursor="pointer" />
                      </Box>
                      <IconStart size="4" />
                      <IconStart size="4" />
                      <IconStart size="4" />
                      <IconStart size="4" />
                      <IconStart size="4" fill="gray.200" />
                    </Box>
                    <Box>
                      {isBestRate && (
                        <Box mr="5px">
                          <Text
                            fontSize="12px"
                            fontWeight="600"
                            lineHeight="16px"
                            color="orange.500"
                            textTransform="uppercase"
                          >
                            {t("bestRate")}
                          </Text>
                        </Box>
                      )}
                      {isFixRate && (
                        <Box mr="5px">
                          <Text
                            fontSize="12px"
                            fontWeight="600"
                            lineHeight="16px"
                            textTransform="uppercase"
                            color="green.500"
                          >
                            {t("fixRate")}
                          </Text>
                        </Box>
                      )}
                    </Box>
                    <Box>
                      <Box
                        display="flex"
                        fontSize="12px"
                        fontWeight="600"
                        lineHeight="16px"
                      >
                        <Text mr="5px" color={main6}>
                          {t("eta")}
                        </Text>
                        <Text mr="5px">{eta}</Text>
                      </Box>
                      <Box
                        display="flex"
                        fontSize="12px"
                        fontWeight="600"
                        lineHeight="16px"
                      >
                        <Text mr="5px" color={main6}>
                          {t("common:review")}:
                        </Text>
                        <Text mr="5px">{reviewCount}</Text>
                      </Box>
                    </Box>
                  </Flex>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      {selectDataList.length >= 5 && (
        <Center
          role="group"
          py="2"
          onClick={setShow.toggle}
          cursor="pointer"
          _hover={{ bg: main3 }}
        >
          <IconFilledArrow
            _groupHover={{ fill: text3 }}
            size="6"
            fill={text5}
            style={{ transform: `rotate(${show ? 0 : 180}deg)` }}
          />
        </Center>
      )}
    </Box>
  );
};

export default SelectAPairMobile;
