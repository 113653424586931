import React from "react";
import { IconBase, IconBaseProps } from "react-icons";

const SvgComponent: React.FC<IconBaseProps> = ({
  size,
  ...props
}: IconBaseProps) => {
  return (
    <IconBase {...props} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99992 2.49276C4.95835 2.49276 2.49267 4.95844 2.49267 8.00001C2.49267 9.37149 2.994 10.6259 3.8234 11.5899C4.22051 11.0378 4.72476 10.5676 5.30886 10.2091C5.52381 10.0772 5.74735 9.96172 5.97754 9.8633C5.30828 9.29179 4.88398 8.44183 4.88398 7.49276C4.88398 5.77188 6.27903 4.37682 7.99992 4.37682C9.72081 4.37682 11.1159 5.77188 11.1159 7.49276C11.1159 8.44172 10.6917 9.29159 10.0225 9.8631C10.2529 9.96157 10.4766 10.0771 10.6917 10.2091C11.2756 10.5675 11.7798 11.0375 12.1768 11.5895C13.006 10.6255 13.5072 9.37128 13.5072 8.00001C13.5072 4.95844 11.0415 2.49276 7.99992 2.49276ZM12.4911 12.9269C13.8278 11.7077 14.6666 9.9518 14.6666 8.00001C14.6666 4.31811 11.6818 1.33334 7.99992 1.33334C4.31802 1.33334 1.33325 4.31811 1.33325 8.00001C1.33325 9.95458 2.1744 11.7127 3.51445 12.9321C3.53177 12.9499 3.55037 12.9666 3.57019 12.9823C4.74787 14.0301 6.29956 14.6667 7.99992 14.6667C9.69791 14.6667 11.2476 14.0319 12.4247 12.9867C12.4486 12.9683 12.4708 12.9483 12.4911 12.9269ZM11.3226 12.3924C11.003 11.9102 10.5813 11.5018 10.0852 11.1972C9.46684 10.8177 8.75683 10.6143 8.03174 10.6085C8.02114 10.6087 8.01054 10.6087 7.99992 10.6087C7.98941 10.6087 7.9789 10.6087 7.96841 10.6085C7.24346 10.6143 6.53359 10.8178 5.91537 11.1972C5.41914 11.5018 4.9973 11.9104 4.67775 12.3928C5.60122 13.0923 6.75211 13.5073 7.99992 13.5073C9.24794 13.5073 10.399 13.0921 11.3226 12.3924ZM8.02854 9.44908C9.0959 9.43377 9.95644 8.56376 9.95644 7.49276C9.95644 6.41221 9.08048 5.53624 7.99992 5.53624C6.91936 5.53624 6.0434 6.41221 6.0434 7.49276C6.0434 8.56383 6.90404 9.43388 7.97149 9.44908C7.98108 9.44903 7.99068 9.449 8.00027 9.449C8.0097 9.449 8.01912 9.44903 8.02854 9.44908Z"
        fill="currentColor"
      />
    </IconBase>
  );
};

const IconProfile = React.memo(SvgComponent);
export default IconProfile;
