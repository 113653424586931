import {
  AbsoluteCenter,
  AbsoluteCenterProps,
  Box,
  Flex,
} from "@chakra-ui/react";
import * as React from "react";
import { useAppColor } from "src/hooks";
import Step from "./Step";

interface ProgressbarProps extends AbsoluteCenterProps {
  value: number;
}

const ProgressBar = (props: ProgressbarProps) => {
  const { value, ...rest } = props;
  const { brand } = useAppColor();
  return (
    <Box py="4" as="nav" aria-label="Steps" position="relative">
      <Flex
        justify="space-between"
        align="center"
        as="ol"
        listStyleType="none"
        zIndex={1}
        mx={"-1px"}
      >
        <Step active={value > 0} />
        <Step active={value > (1 / 4) * 100} />
        <Step active={value > (2 / 4) * 100} />
        <Step active={value > (3 / 4) * 100} />
        <Step active={value >= 100} />
      </Flex>
      <AbsoluteCenter
        role="progressbar"
        aria-valuemin={0}
        aria-valuemax={100}
        aria-valuenow={value}
        aria-valuetext={`Progress: ${value}%`}
        position="absolute"
        height="6px"
        axis="vertical"
        bg="#EBF0FF"
        width="full"
        {...rest}
      >
        <Box bg={brand} height="inherit" width={`${value}%`} />
      </AbsoluteCenter>
    </Box>
  );
};

export default ProgressBar;
