import React from "react";
import { Box, Flex } from "@chakra-ui/layout";
import { Thead, Tr, Td, Button, Text, useBoolean, Tag } from "@chakra-ui/react";
import { IconFilledArrow } from "src/assets/icons";
import { TokenLogo, ButtonUnlock } from "src/atoms";
import { formatToken } from "src/lib/formatCurrency";
import { useTranslation } from "react-i18next";
import { useAppColor } from "src/hooks";
import { useAppSelector } from "src/redux/hooks";
import { loginSelector } from "src/redux/slices/loginSlice";
import { handleGoLink } from "src/utils";
import { IStakingInfo } from "src/constants/interfaces";
import { overviewSelector } from "src/redux/slices/overviewSlice";
import { Images } from "src/assets/images";
interface StakingTableHeaderProps {
  stakeToken: IStakingInfo;
  claimToken: () => void;
  toggleShow: () => void;
  last?: boolean;
}

interface HeaderToken {
  symbol?: string;
  amount: number;
}

const StakingTableHeader: React.FC<StakingTableHeaderProps> = ({
  stakeToken,
  claimToken,
  toggleShow,
  last,
}) => {
  const {
    name,
    stakeLogo,
    link,
    apy,
    symbol,
    subName,
    stakeList,
    pendingRewards,
  } = stakeToken;
  const { t } = useTranslation(["overview", "common"]);
  const [show, setShow] = useBoolean(false);
  const { brand, text1, text2, text3, text6 } = useAppColor();
  const { isHidden } = useAppSelector(loginSelector);
  const { tokens } = useAppSelector(overviewSelector);

  const [totalDeposit, setTotalDeposit] = React.useState<HeaderToken[]>([]);
  const [pendingRewardList, setPendingRewardList] = React.useState<
    HeaderToken[]
  >([]);

  const noDeposit =
    totalDeposit.filter((deposit) => deposit.amount > 0).length === 0;

  const handleClaimToken = React.useCallback(() => {
    if (pendingRewardList.length > 0) {
      claimToken();
    } else {
      handleGoLink(link);
    }
  }, [pendingRewardList, claimToken, link]);

  React.useEffect(() => {
    let totalDepositTmp = [] as HeaderToken[];
    stakeList.forEach((token) => {
      const stakeSymbol = token.stakeSymbol || symbol;
      const curIndex = totalDepositTmp.findIndex(
        (temp) => temp.symbol === stakeSymbol
      );
      if (curIndex !== -1) {
        if (token.stakingBalance) {
          totalDepositTmp[curIndex].amount += token.stakingBalance;
        }
      } else {
        totalDepositTmp.push({
          symbol: stakeSymbol,
          amount: token.stakingBalance || 0,
        });
      }
    });
    setTotalDeposit(totalDepositTmp);
  }, [stakeList, symbol]);

  React.useEffect(() => {
    let pendingRewardListTmp = [] as HeaderToken[];
    pendingRewards &&
      pendingRewards.forEach((pendingReward) => {
        const rewardSymbol = pendingReward.pendingRewardSymbol || symbol;
        const curIndex = pendingRewardListTmp.findIndex(
          (temp) => temp.symbol === rewardSymbol
        );
        if (curIndex !== -1) {
          if (pendingReward.pendingReward) {
            pendingRewardListTmp[curIndex].amount +=
              pendingReward.pendingReward;
          }
        } else {
          pendingRewardListTmp.push({
            symbol: rewardSymbol,
            amount: pendingReward.pendingReward,
          });
        }
      });
    setPendingRewardList(
      pendingRewardListTmp.filter((reward) => reward.amount > 0)
    );
  }, [pendingRewards, symbol]);

  return (
    <Thead>
      <Tr>
        <Td w="15%" borderBottomWidth={last ? "0" : undefined}>
          <Flex alignItems="center">
            <TokenLogo src={stakeLogo} size="sm" mr="2" />
            <Box>
              <Text color={text2} fontSize="sm" fontWeight="semibold">
                {name}
              </Text>
              <Text
                cursor="pointer"
                color={text6}
                fontSize="xs"
                onClick={() => handleGoLink(link)}
              >
                {subName}
              </Text>
            </Box>
          </Flex>
        </Td>
        {apy !== 0 ? (
          <Td isNumeric borderBottomWidth={last ? "0" : undefined}>
            <Tag bg={brand} color="black">
              {t(name === "GRPH" ? "common:apr" : "common:apy")} {apy}%
            </Tag>
            {/* <Text color="teal.700" fontSize="sm" fontWeight="semibold">
              {t("common:apy")}
            </Text>
            <Text color="blue.600" fontSize="xs" fontWeight="semibold">
              {apy}%
            </Text> */}
          </Td>
        ) : (
          <Td isNumeric borderBottomWidth={last ? "0" : undefined} />
        )}
        <Td isNumeric borderBottomWidth={last ? "0" : undefined}>
          {!noDeposit && (
            <React.Fragment>
              <Flex
                w="full"
                alignItems="center"
                justifyContent="flex-end"
                color={text1}
                fontSize="sm"
                fontWeight="semibold"
              >
                {totalDeposit.map((item, index) => {
                  if (index > 1 || item.amount <= 0) {
                    return <React.Fragment key={index} />;
                  }

                  const stakeIcon =
                    tokens.find((token) => token.symbol === item.symbol)
                      ?.icon || Images.zil;

                  return (
                    <React.Fragment key={index}>
                      <Text ml="1">
                        {index !== 0 && " + "}
                        {formatToken(
                          item.amount,
                          item.symbol || "ZIL",
                          isHidden
                        )}
                      </Text>
                      <TokenLogo size="2xs" src={stakeIcon} ml="1" />
                    </React.Fragment>
                  );
                })}
                {totalDeposit && totalDeposit.length > 2 && (
                  <Text ml="2">...</Text>
                )}
              </Flex>
              <Text color={text3} fontSize="xs">
                {t("totalDeposit")}
              </Text>
            </React.Fragment>
          )}
        </Td>
        <Td isNumeric borderBottomWidth={last ? "0" : undefined}>
          {pendingRewardList.length > 0 && (
            <React.Fragment>
              <Flex
                w="full"
                alignItems="center"
                justifyContent="flex-end"
                color={text1}
                fontSize="sm"
                fontWeight="semibold"
              >
                {pendingRewardList.map((item, index) => {
                  if (index > 1) {
                    return <React.Fragment key={index} />;
                  }

                  const stakeIcon =
                    tokens.find((token) => token.symbol === item.symbol)
                      ?.icon || Images.zil;

                  return (
                    <React.Fragment key={index}>
                      <Text ml="1">
                        {index !== 0 && " + "}
                        {formatToken(
                          item.amount,
                          item.symbol || "ZIL",
                          isHidden
                        )}
                      </Text>
                      <TokenLogo size="2xs" src={stakeIcon} ml="1" />
                    </React.Fragment>
                  );
                })}
                {pendingRewardList && pendingRewardList.length > 2 && (
                  <Text ml="2">...</Text>
                )}
              </Flex>
              <Text color={text3} fontSize="xs">
                {t("unclaimedReward")}
              </Text>
            </React.Fragment>
          )}
        </Td>
        <Td isNumeric borderBottomWidth={last ? "0" : undefined}>
          <Flex alignItems="center" justifyContent="flex-end">
            {isHidden ? (
              <ButtonUnlock mr="4" />
            ) : (
              <Button
                visibility={pendingRewardList.length > 0 ? "visible" : "hidden"}
                variant="outline"
                children={t("common:claim")}
                mr="4"
                onClick={handleClaimToken}
              />
            )}
            <IconFilledArrow
              fill={text1}
              cursor="pointer"
              onClick={() => {
                toggleShow();
                setShow.toggle();
              }}
              _hover={{
                fill: text3,
              }}
              size="6"
              style={{ transform: `rotate(${show ? 0 : 180}deg)` }}
            />
          </Flex>
        </Td>
      </Tr>
    </Thead>
  );
};

export default StakingTableHeader;
