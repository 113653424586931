import React, { memo } from "react";
import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Box,
  Text,
  Select,
  SimpleGrid,
  Flex,
  Stack,
} from "@chakra-ui/react";
import { useAppColor } from "src/hooks";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { useTranslation } from "react-i18next";

import TableAll from "./TableAll";
import FeatureHistory from "./FeatureHistory";

import { BN } from "@zilliqa-js/zilliqa";
import { loginSelector } from "src/redux/slices/loginSlice";
import { formatToken, formatUsdt } from "src/lib/formatCurrency";
import { overviewSelector } from "src/redux/slices/overviewSlice";
import {
  getHistoryStatistic,
  historySelector,
} from "src/redux/slices/historySlice";
import { dayjs } from "src/utils";
import DownloadCsv from "./DownloadCsv";
import { viewProfileSelector } from "src/redux/slices/viewProfileSlice";
interface HistoryPageProps {}

export interface FeatureHistorySpec {
  title?: string;
  balance?: string;
  value?: string;
}

const HistoryPage: React.FC<HistoryPageProps> = memo(() => {
  const { t } = useTranslation(["history", "common"]);
  const { text3, text5 } = useAppColor();

  const { exchangeRate, isMember } = useAppSelector(loginSelector);

  const { viewWallet } = useAppSelector(viewProfileSelector);
  const { historyStatistic, listTransaction } = useAppSelector(historySelector);
  const { zilPriceInUsd } = useAppSelector(overviewSelector);

  const dispatch = useAppDispatch();

  // const tabs = [t("all"), t("transfers"), t("contractCall"), t("tokens")];
  const tabs = [t("all")];

  const [value, setValue] = React.useState<string>(tabs[0]);

  const QaRate = React.useMemo(() => new BN("1000000000"), []);

  const QaToZil = React.useCallback(
    (amount?: string) => {
      return amount
        ? new BN(amount).div(QaRate).toNumber()
        : new BN("0").toNumber();
    },
    [QaRate]
  );

  React.useEffect(() => {
    if (!viewWallet) {
      return;
    }
    dispatch(getHistoryStatistic(viewWallet.zilAddress));
  }, [dispatch, viewWallet]);

  const data_feature_history = React.useMemo(
    () => [
      {
        title: t("transactionDone"),
        balance: `${historyStatistic.total_txs}`,
        value:
          t("common:since") +
          " " +
          dayjs(historyStatistic.first_transaction_created_at).format(
            "MMM YYYY"
          ),
      },
      {
        title: t("frequentTransaction"),
        balance:
          historyStatistic.most_call_contract_address.length > 13
            ? historyStatistic.most_call_contract_address.substr(0, 6) +
              "..." +
              historyStatistic.most_call_contract_address.substr(
                historyStatistic.most_call_contract_address.length - 3
              )
            : historyStatistic.most_call_contract_address,
        value: `${historyStatistic.most_call_contract_times} times`,
      },
      {
        title: t("biggestTransaction"),
        balance: formatToken(QaToZil(`${historyStatistic.total_fee}`) / 1000),
        value: formatUsdt(
          (QaToZil(`${historyStatistic.total_fee}`) * zilPriceInUsd) / 1000,
          exchangeRate
        ),
      },
      {
        title: t("totalGasFee"),
        balance: formatToken(QaToZil(`${historyStatistic.total_fee}`) / 1000),
        value:
          "~ " +
          formatUsdt(
            (QaToZil(`${historyStatistic.total_fee}`) * zilPriceInUsd) / 1000,
            exchangeRate
          ),
      },
    ],
    [t, historyStatistic, QaToZil, exchangeRate, zilPriceInUsd]
  );

  return (
    <Stack>
      <Text
        display={{ base: "block", lg: "none" }}
        fontSize="xl"
        lineHeight="7"
        fontWeight="bold"
        color={text3}
        mb="4"
      >
        {t("common:history")}
      </Text>
      {!!data_feature_history && (
        <SimpleGrid
          columns={{ base: 2, xl: 4 }}
          spacing={{ base: "4", xl: "20px" }}
        >
          <FeatureHistory item={data_feature_history[0]} />
          <FeatureHistory item={data_feature_history[1]} />
          <FeatureHistory item={data_feature_history[3]} />
        </SimpleGrid>
      )}
      <Tabs
        display={{ base: "none", xl: "block" }}
        variant="soft-rounded"
        mt="8"
      >
        <Flex justifyContent="space-between">
          <TabList borderRadius="full" w="auto" mb="16px">
            {tabs.map((name, index) => (
              <Tab
                display={"none"}
                key={index}
                _focus={{ border: "none" }}
                _selected={{
                  bg: "gray.700",
                  color: "white",
                }}
                color={text5}
              >
                {name}
              </Tab>
            ))}
          </TabList>
          <DownloadCsv listTransaction={listTransaction} />
        </Flex>
        <TabPanels>
          <TabPanel p="0">{isMember && <TableAll />}</TabPanel>
        </TabPanels>
      </Tabs>
      <Box display={{ base: "block", xl: "none" }}>
        <Select
          value={value}
          mt="4"
          mb="6"
          onChange={(event) => setValue(event.target.value)}
        >
          {tabs.map((name, index) => (
            <option key={index} value={name}>
              {name}
            </option>
          ))}
        </Select>
        <DownloadCsv listTransaction={listTransaction} />
        {value === tabs[0] && isMember && <TableAll />}
      </Box>
    </Stack>
  );
});

export default HistoryPage;
