import React from "react";
import { Box, Flex, Text } from "@chakra-ui/layout";
import { MotionBox, TextCurrency } from "src/molecules";
import { TokenLogo } from "src/atoms";
import { useAppColor } from "src/hooks";
import { useBoolean } from "@chakra-ui/hooks";
import { useTranslation } from "react-i18next";

interface WalletItemProps {
  item: any;
  onClick?(): void;
}

const WalletItem: React.FC<WalletItemProps> = ({ item, onClick }) => {
  const { t } = useTranslation();
  const { text2, text6, main4, main3, main6 } = useAppColor();

  const { networkName, subName, logo, total, price, amount, worth } = item;

  const [show, setShow] = useBoolean(false);

  return (
    <Box cursor="pointer" onClick={onClick}>
      <Flex
        justifyContent="space-between"
        ml="4"
        pr="4"
        py="4"
        borderBottomWidth="1px"
        borderBottomColor={main4}
        onClick={setShow.toggle}
      >
        <Flex alignItems="center">
          <TokenLogo size="sm" mr="2" src={logo} />
          <Box>
            <Text
              fontSize="sm"
              lineHeight="5"
              fontWeight="semibold"
              color={text2}
            >
              {networkName}
            </Text>
            <Text fontSize="xs" lineHeight="4" color={text6} mt="2px">
              {subName}
            </Text>
          </Box>
        </Flex>
        <TextCurrency
          fontSize="sm"
          lineHeight="5"
          fontWeight="semibold"
          color={text2}
        >
          {worth}
        </TextCurrency>
      </Flex>
      {!!show && (
        <MotionBox
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            transition: { duration: 0.5 },
          }}
          borderBottomWidth="1px"
          borderBottomColor={main4}
        >
          <Flex borderBottomWidth="1px" borderBottomColor={main3} ml="4" pr="4">
            {[t("price"), t("amount"), t("share")].map((item, key) => {
              return (
                <Flex
                  key={key}
                  flex="1"
                  justifyContent="flex-end"
                  py="3"
                  alignItems="center"
                  fontSize="xs"
                  fontWeight="bold"
                  lineHeight="4"
                  letterSpacing="wider"
                  color={main6}
                >
                  {item}
                </Flex>
              );
            })}
          </Flex>
          <Flex py="3" ml="4" pr="4">
            <Flex alignItems="center" flex="1" justifyContent="flex-end">
              <Text color={text6} fontSize="xs">
                {"~"}${price}
              </Text>
            </Flex>
            <Flex alignItems="center" flex="1" justifyContent="flex-end">
              <Text fontSize="sm" fontWeight="semibold" mr="1">
                {amount}
              </Text>
              <TokenLogo w="4" h="4" src={logo} />
            </Flex>
            <Flex flex="1" justifyContent="flex-end">
              <Text
                fontSize="sm"
                lineHeight="5"
                fontWeight="semibold"
                color="blue.400"
              >
                {total}
                {"%"}
              </Text>
            </Flex>
          </Flex>
        </MotionBox>
      )}
    </Box>
  );
};

export default WalletItem;
