import React from "react";
import Icon from "@chakra-ui/icon";
import { SvgIconProps } from "src/constants/type";

const SvgComponent: React.FC<SvgIconProps> = ({
  size,
  ...props
}: SvgIconProps) => {
  return (
    <Icon {...props} w={size} h={size} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.86466 3.06546C7.15086 2.77926 7.53903 2.61847 7.94378 2.61847C8.34852 2.61847 8.73669 2.77926 9.02289 3.06546C9.30909 3.35166 9.46988 3.73983 9.46988 4.14458V5.67068H6.41767V4.14458C6.41767 3.73983 6.57846 3.35166 6.86466 3.06546ZM5.13253 5.67068V4.14458C5.13253 3.39899 5.42871 2.68394 5.95592 2.15673C6.48314 1.62952 7.19819 1.33333 7.94378 1.33333C8.68936 1.33333 9.40441 1.62952 9.93162 2.15673C10.4588 2.68394 10.755 3.39899 10.755 4.14458V5.67068H12.7631C13.3841 5.67068 13.8876 6.17414 13.8876 6.79518V8.24096V10.1687V12.0964V13.5422C13.8876 14.1632 13.3841 14.6667 12.7631 14.6667H3.1245C2.50345 14.6667 2 14.1632 2 13.5422V12.0964V10.1687V8.24096V6.79518C2 6.17414 2.50345 5.67068 3.1245 5.67068H5.13253ZM10.1124 6.95582H12.6024V7.59839H3.28514V6.95582H5.7751H10.1124ZM3.28514 8.88353V9.5261H12.6024V8.88353H3.28514ZM3.28514 11.4538V10.8112H12.6024V11.4538H3.28514ZM3.28514 12.739H12.6024V13.3815H3.28514V12.739Z"
        fill="currentColor"
      />
    </Icon>
  );
};

const IconStacking = React.memo(SvgComponent);
export default IconStacking;
