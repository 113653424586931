import React from "react";
import {
  Tr,
  Td,
  useBoolean,
  Flex,
  Text,
  SimpleGrid,
  Tbody,
} from "@chakra-ui/react";
import { useAppSelector } from "src/redux/hooks";
import { loginSelector } from "src/redux/slices/loginSlice";
import { useAppColor } from "src/hooks";
import { fetchReward } from "src/api/stakingRewardApi";
import { useTranslation } from "react-i18next";
import {
  IPendingReward,
  IStakingInfo,
  IUnstaking,
} from "src/constants/interfaces";
import StakingTableItem from "./StakingTableItem";
import { formatToken } from "src/lib/formatCurrency";
import { TokenLogo } from "src/atoms";
import { Images } from "src/assets/images";
import StakingTableHeader from "./StakingTableHeader";
import { sortStakingList } from "src/utils";
import { IModalClaimStakingItem } from "./ModalClaimStaking";
import { overviewSelector } from "src/redux/slices/overviewSlice";

interface StakingZilProps {
  stakeToken: IStakingInfo;
  claimStaking?: (stakeToken: IModalClaimStakingItem[]) => void;
  walletAddress: string;
  last?: boolean;
  active?: boolean;
}

const StakingZil: React.FC<StakingZilProps> = ({
  stakeToken,
  claimStaking,
  last,
  walletAddress,
  active,
}) => {
  const { t } = useTranslation(["overview", "common"]);
  const [show, setShow] = useBoolean(false);

  const { text2, text6, main4 } = useAppColor();

  const { isHidden } = useAppSelector(loginSelector);
  const { tokens } = useAppSelector(overviewSelector);

  const { stakeList, symbol, unstakingPending } = stakeToken;

  const [claimStakingItems, setClaimStakingItems] = React.useState<
    IModalClaimStakingItem[]
  >(Array<IModalClaimStakingItem>());

  const [zilPendingRewards, setZilPendingRewards] = React.useState<
    IPendingReward[]
  >(Array<IPendingReward>());
  const [unstakingList, setUnstakingList] = React.useState<IUnstaking[]>([]);

  React.useEffect(() => {
    const syncStakingReward = async () => {
      if (!stakeList) {
        return;
      }

      let claimStakingItems = Array<IModalClaimStakingItem>();
      let zilPendingRewards = Array<IPendingReward>();

      for (let i = 0; i < stakeList.length; i++) {
        const stakingWallet = stakeList[i];
        const { address, stakingBalance } = stakingWallet;
        if (stakingBalance && stakingBalance > 0) {
          const rewardResult = await fetchReward(address, walletAddress);
          const rewardNumber = parseFloat(rewardResult.toString());
          if (!isNaN(rewardNumber) && rewardNumber > 0) {
            zilPendingRewards.push({
              address: address,
              pendingReward: rewardNumber * Math.pow(10, -12),
              pendingRewardSymbol: "ZIL",
            });
            claimStakingItems.push({
              stakingWallet: stakingWallet,
              reward1: rewardNumber * Math.pow(10, -12),
              reward2: rewardNumber * Math.pow(10, -12) * Math.pow(10, -3),
            });
          }
        }
      }
      setZilPendingRewards(zilPendingRewards);
      setClaimStakingItems(claimStakingItems);
    };
    syncStakingReward();
  }, [stakeList, walletAddress]);

  React.useEffect(() => {
    let unstakingListTmp = [] as IUnstaking[];
    let unstaking = unstakingPending || [];
    unstaking.forEach((item) => {
      const stakingSymbol = item.unstakingSymbol || symbol;
      const curIndex = unstakingListTmp.findIndex(
        (temp) => temp.unstakingSymbol === stakingSymbol
      );
      if (curIndex !== -1) {
        if (item.unstakingSymbol) {
          unstakingListTmp[curIndex].unstaking += item.unstaking;
        }
      } else {
        unstakingListTmp.push({
          unstakingSymbol: stakingSymbol,
          unstaking: item.unstaking,
        });
      }
    });
    setUnstakingList(
      unstakingListTmp.filter((staking) => staking.unstaking > 0)
    );
  }, [symbol, unstakingPending]);

  const claimZil = React.useCallback(() => {
    claimStaking && claimStaking(claimStakingItems);
  }, [claimStaking, claimStakingItems]);

  const toggleShow = () => {
    setShow.toggle();
  };

  return (
    <React.Fragment>
      <StakingTableHeader
        stakeToken={stakeToken}
        claimToken={claimZil}
        toggleShow={toggleShow}
        last={last}
        zilPendingRewards={zilPendingRewards}
      />
      {!!show && (
        <Tbody>
          {unstakingPending.length > 0 && (
            <Tr bg={main4}>
              <Td />
              <Td />
              <Td isNumeric>
                {unstakingList.map((item, index) => {
                  const stakeIcon =
                    tokens.find(
                      (token) => token.symbol === item.unstakingSymbol
                    )?.icon || Images.zil;
                  return (
                    <Flex key={index} alignItems="center" justify="end">
                      <Text
                        fontSize="14px"
                        lineHeight="20px"
                        fontWeight="600"
                        color={text2}
                        marginRight="1"
                      >
                        {item.unstaking > 0
                          ? formatToken(
                              item.unstaking,
                              item.unstakingSymbol,
                              isHidden
                            )
                          : "0"}
                      </Text>
                      <TokenLogo size="2xs" src={stakeIcon} />
                    </Flex>
                  );
                })}

                <Text fontSize="12px" lineHeight="16px" color={text6}>
                  {t("currentUnstaking")}
                </Text>
              </Td>
              <Td isNumeric>
                {/* <Flex alignItems="center" justify="end">
                  <Text
                    fontSize="14px"
                    lineHeight="20px"
                    fontWeight="600"
                    color={text2}
                  >
                    0
                  </Text>
                  <TokenLogo size="2xs" src={Images.zil} />
                </Flex>
                <Text fontSize="12px" lineHeight="16px" color={text6}>
                  {t("availableWithdraw")}
                </Text> */}
              </Td>
              <Td isNumeric>
                {/* <Button
                  children="Withdraw"
                  size="sm"
                  mr="10"
                  bg={brand}
                  textColor={main2}
                  onClick={() => {}}
                /> */}
              </Td>
            </Tr>
          )}
          <Tr>
            <Td colSpan={5} p="0">
              <SimpleGrid
                columns={{ base: 1, md: 2, lg: 3 }}
                spacing="20px"
                p="16px"
              >
                {stakeList &&
                  sortStakingList(stakeList).map((token, index) => {
                    const { name, stakingBalance } = token;
                    let reward = 0;
                    let rate = 1;
                    if (
                      (!stakingBalance && !!active) ||
                      (stakingBalance && stakingBalance <= 0 && !!active)
                    ) {
                      return <React.Fragment key={index} />;
                    }

                    let claimStakingItem = claimStakingItems.find(
                      (claimStakingItem) =>
                        claimStakingItem.stakingWallet.name === name
                    );

                    reward = claimStakingItem ? claimStakingItem.reward1 : 0;

                    return (
                      <StakingTableItem
                        key={index}
                        {...stakeToken}
                        {...token}
                        pendingReward={{
                          pendingReward: reward,
                          pendingRewardSymbol: "ZIL",
                        }}
                        rate={rate}
                      />
                    );
                  })}
              </SimpleGrid>
            </Td>
          </Tr>
        </Tbody>
      )}
    </React.Fragment>
  );
};

export default StakingZil;
