import React from "react";
import { IconBase, IconBaseProps } from "react-icons";

const SvgComponent: React.FC<IconBaseProps> = (props: IconBaseProps) => {
  return (
    <IconBase {...props}>
      <svg viewBox="0 0 20 20">
        <path d="M7.91667 1.66699C6.4 1.66699 4.975 2.08366 3.75 2.79199C6.24167 4.23366 7.91667 6.91699 7.91667 10.0003C7.91667 13.0837 6.24167 15.767 3.75 17.2087C4.975 17.917 6.4 18.3337 7.91667 18.3337C12.5167 18.3337 16.25 14.6003 16.25 10.0003C16.25 5.40033 12.5167 1.66699 7.91667 1.66699Z" />
      </svg>
    </IconBase>
  );
};
const IconMoon = React.memo(SvgComponent);
export default IconMoon;
