import React from "react";
import { Box, Circle, Text } from "@chakra-ui/layout";
import { IconChecked } from "src/assets/icons";
import { useAppColor } from "src/hooks";

interface MembershipItemProps {
  title: string;
  description: string;
  isChecked: boolean;
}

const MembershipItem: React.FC<MembershipItemProps> = ({
  title,
  description,
  isChecked,
}) => {
  const { brand, main2, text2, text4 } = useAppColor();
  return (
    <Box mr={"1"}>
      <Circle size="40px" bg={isChecked ? brand : text2} color="white">
        <IconChecked size="40px" color={main2} />
      </Circle>
      <Text
        fontSize="18px"
        lineHeight="28px"
        fontWeight="bold"
        color={text2}
        maxW={"231px"}
        mt={"7"}
      >
        {title}
      </Text>
      <Text
        fontSize="15px"
        lineHeight="24px"
        color={text4}
        mt="1"
        maxW={{ base: "231.5px", lg: "240.5px", md: "231.5px", sm: "375px" }}
      >
        {description}
      </Text>
    </Box>
  );
};

export default MembershipItem;
