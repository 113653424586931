import Icon, { IconProps } from "@chakra-ui/icon";
import React from "react";

interface SvgIconProps extends IconProps {
  size?: string;
}

const SvgComponent: React.FC<SvgIconProps> = ({
  size,
  ...props
}: SvgIconProps) => {
  return (
    <Icon {...props} w={size} h={size} viewBox="0 0 24 24">
      <path d="M22 12a10 10 0 11-20 0 10 10 0 0120 0zM7.4 15.4l4.6-4.6 4.6 4.6L18 14l-6-6-6 6 1.4 1.4z" />
    </Icon>
  );
};
const IconFilledArrow = React.memo(SvgComponent);
export default IconFilledArrow;
