import React from "react";
import { Box, BoxProps } from "@chakra-ui/layout";
import {
  Pagination,
  PaginationPage,
  PaginationPageGroup,
  PaginationContainer,
} from "@ajna/pagination";
import { useAppColor } from "src/hooks";

interface PaginationPanelProps extends BoxProps {
  onPageChange: (page: number) => void;
  currentPage: number;
  pagesCount: number;
  isDisabled?: boolean;
  pages: number[];
}

const PaginationPanel: React.FC<PaginationPanelProps> = ({
  pagesCount,
  currentPage,
  isDisabled,
  pages,
  onPageChange,
  ...rest
}) => {
  const { brand, text5 } = useAppColor();

  return (
    <Box {...rest}>
      <Pagination
        pagesCount={pagesCount}
        currentPage={currentPage}
        isDisabled={isDisabled}
        onPageChange={onPageChange}
      >
        <PaginationContainer align="center" justify="center" w="full">
          <PaginationPageGroup isInline align="center">
            {pages.map((page: number) => (
              <PaginationPage
                w="40px"
                h="40px"
                key={`pagination_page_${page}`}
                page={page}
                borderRadius="50%"
                bg="transparent"
                onClick={() => {}}
                fontSize="md"
                color={text5}
                _focus={{
                  boxShadow: "none",
                  bg: brand,
                  color: "blue.700",
                  fontWeight: "bold",
                }}
                _current={{
                  boxShadow: "none",
                  bg: brand,
                  color: "blue.700",
                  fontWeight: "bold",
                }}
              />
            ))}
          </PaginationPageGroup>
        </PaginationContainer>
      </Pagination>
    </Box>
  );
};

export default PaginationPanel;
