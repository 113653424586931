import React from "react";
import { Box, Flex, HStack } from "@chakra-ui/layout";
import { Tr, Td, Button, Text } from "@chakra-ui/react";
import { TokenLogo, ButtonUnlock } from "src/atoms";
import { formatToken, formatUsdt } from "src/lib/formatCurrency";
import { useTranslation } from "react-i18next";
import { useAppColor } from "src/hooks";
import { useAppSelector } from "src/redux/hooks";
import { loginSelector } from "src/redux/slices/loginSlice";
import { handleGoLink } from "src/utils";
import { IPendingReward, IStakingInfo } from "src/constants/interfaces";
import { overviewSelector } from "src/redux/slices/overviewSlice";
import { Images } from "src/assets/images";
import { fetchReward } from "src/api/stakingRewardApi";
import { fromBech32Address } from "@zilliqa-js/zilliqa";
import BigNumber from "bignumber.js";
interface RewardTableHeaderProps {
  stakeToken: IStakingInfo;
  claimToken: () => void;
  last?: boolean;
  zilPendingRewards?: IPendingReward[];
}

interface HeaderToken {
  symbol?: string;
  amount: number;
}

const RewardTableHeader: React.FC<RewardTableHeaderProps> = ({
  stakeToken,
  claimToken,
  last,
  zilPendingRewards,
}) => {
  const { name, stakeLogo, link, symbol, subName, pendingRewards, stakeList } =
    stakeToken;
  const { t } = useTranslation(["overview", "common"]);
  const { text1, text2, text3, text6 } = useAppColor();
  const { isHidden, exchangeRate, currentWallet } =
    useAppSelector(loginSelector);
  const {
    zilPriceInUsd,
    xcadClaimableData,
    tokenRates,
    distributions,
    carbSwapClaimable,
    tokens,
  } = useAppSelector(overviewSelector);
  const stakeToken2 = tokenRates.find((token) => token.token.symbol === symbol);
  const stakeRate = stakeToken2?.rate || 1;
  const [pendingRewardList, setPendingRewardList] = React.useState<
    HeaderToken[]
  >([]);
  const handleClaimToken = React.useCallback(() => {
    if (pendingRewardList.length > 0) {
      claimToken();
    } else {
      handleGoLink(link);
    }
  }, [pendingRewardList, claimToken, link]);

  React.useEffect(() => {
    if (!currentWallet) {
      return;
    }

    const syncStakingReward = async () => {
      let pendingRewardListTmp = [] as HeaderToken[];
      let pending = zilPendingRewards || pendingRewards || [];
      let tmpTotalUnclaimed = 0;
      if (name === "Zillion") {
        for (let i = 0; i < stakeList.length; i++) {
          const stakingWallet = stakeList[i];
          const { address, stakingBalance, rewardSymbol } = stakingWallet;
          if (stakingBalance && stakingBalance > 0) {
            const rewardResult = await fetchReward(
              address,
              currentWallet?.zilAddress
            );
            const rewardNumber =
              parseFloat(rewardResult.toString()) * Math.pow(10, -12);
            tmpTotalUnclaimed += rewardNumber;
            pendingRewardListTmp.push({
              symbol: rewardSymbol,
              amount: tmpTotalUnclaimed,
            });
          }
        }
      } else {
        pending.forEach((pendingReward) => {
          const rewardSymbol = pendingReward.pendingRewardSymbol || symbol;
          const curIndex = pendingRewardListTmp.findIndex(
            (temp) => temp.symbol === rewardSymbol
          );
          if (curIndex !== -1) {
            if (pendingReward.pendingReward) {
              pendingRewardListTmp[curIndex].amount +=
                pendingReward.pendingReward;
            }
          } else {
            pendingRewardListTmp.push({
              symbol: rewardSymbol,
              amount: pendingReward.pendingReward,
            });
          }
        });
      }

      console.log(pendingRewardListTmp.filter((reward) => reward.amount > 0));
      setPendingRewardList(
        pendingRewardListTmp.filter((reward) => reward.amount > 0)
      );
      const dXCAD = tokens.find((token) => token.symbol === "dXCAD");
      if (dXCAD) {
        const dXCADDecimal = dXCAD.decimals;
        xcadClaimableData &&
          xcadClaimableData.forEach((claimable) => {
            tmpTotalUnclaimed += new BigNumber(claimable.amount)
              .div(Math.pow(10, dXCADDecimal))
              .toNumber();
          });
        pendingRewardListTmp.push({
          symbol: dXCAD.symbol,
          amount: tmpTotalUnclaimed,
        });
      }

      distributions.forEach((distribution) => {
        const rewardToken = tokens.find(
          (token) =>
            fromBech32Address(token.address_bech32).toLowerCase() ===
            distribution.info.distrAddr.toLowerCase()
        );
        if (rewardToken) {
          tmpTotalUnclaimed += new BigNumber(distribution.info.amount)
            .div(Math.pow(10, rewardToken.decimals))
            .toNumber();
        }
      });

      const graph = tokens.find((token) => token.symbol === "GRPH");
      if (graph) {
        const graphDecimal = graph.decimals;
        carbSwapClaimable &&
          carbSwapClaimable.forEach((claimable) => {
            tmpTotalUnclaimed += new BigNumber(claimable.amount)
              .div(Math.pow(10, graphDecimal))
              .toNumber();
          });
      }
    };
    syncStakingReward();
  }, [
    pendingRewards,
    symbol,
    zilPendingRewards,
    carbSwapClaimable,
    currentWallet,
    distributions,
    name,
    stakeList,
    tokens,
    xcadClaimableData,
  ]);

  return pendingRewardList.length > 0 ? (
    <Tr>
      <Td borderBottomWidth={last ? "0" : undefined}>
        <Flex alignItems="center">
          <TokenLogo src={stakeLogo} size="sm" mr="2" />
          <Box>
            <Text color={text2} fontSize="sm" fontWeight="semibold">
              {name}
            </Text>
            <Text
              cursor="pointer"
              color={text6}
              fontSize="xs"
              onClick={() => handleGoLink(link)}
            >
              {subName}
            </Text>
          </Box>
        </Flex>
      </Td>
      <Td isNumeric borderBottomWidth={last ? "0" : undefined}>
        <Flex
          w="full"
          alignItems="center"
          justifyContent="flex-end"
          color={text1}
          fontSize="sm"
          fontWeight="semibold"
        >
          {pendingRewardList.map((item, index) => {
            if (index > 1) {
              return <Flex key={index} />;
            }

            const stakeIcon =
              tokens.find((token) => token.symbol === item.symbol)?.icon ||
              Images.zil;

            return (
              <Box key={index}>
                <HStack>
                  <TokenLogo size="2xs" src={stakeIcon} ml="1" />
                  <Text ml="1">
                    {index !== 0 && " + "}
                    {formatToken(item.amount, item.symbol || "ZIL", isHidden)}
                  </Text>
                </HStack>
                <Text color={text3} fontSize="xs">
                  ~
                  {formatUsdt(
                    item.amount * zilPriceInUsd * stakeRate,
                    exchangeRate,
                    isHidden
                  )}
                </Text>
              </Box>
            );
          })}
          {pendingRewardList && pendingRewardList.length > 2 && (
            <Text ml="2">...</Text>
          )}
        </Flex>
      </Td>
      <Td isNumeric borderBottomWidth={last ? "0" : undefined}>
        <>
          {isHidden ? (
            <ButtonUnlock mr="4" />
          ) : (
            <Button
              variant="outline"
              children={t("common:claim")}
              onClick={handleClaimToken}
            />
          )}
        </>
      </Td>
    </Tr>
  ) : (
    <></>
  );
};

export default RewardTableHeader;
