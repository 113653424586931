import React from "react";
import { ModalHeader, ModalCloseButton, ModalBody } from "@chakra-ui/modal";
import { useAppColor } from "src/hooks";
import { Table, Thead, Tr, Th, Tbody, Td } from "@chakra-ui/table";
import { Box, Button, Flex, Text, useToast } from "@chakra-ui/react";
import { TokenLogo } from "src/atoms";
import { useAppSelector } from "src/redux/hooks";
import { loginSelector } from "src/redux/slices/loginSlice";
import { overviewSelector } from "src/redux/slices/overviewSlice";
import { formatToken, formatUsdt } from "src/lib/formatCurrency";
import { useTranslation } from "react-i18next";
import { bytes, BN } from "@zilliqa-js/zilliqa";

import {
  getGasLimitForClaim,
  getGasPriceForClaim,
  isConnectZilpay,
} from "src/api/zilpayApi";
import BoxTransaction from "src/molecules/BoxTransaction";
import { IStakingInfo } from "src/constants/interfaces";
import { handleGoLink } from "src/utils";

interface ModalClaimProps {
  stakeToken: IStakingInfo;
}

const ModalClaim: React.FC<ModalClaimProps> = ({ stakeToken }) => {
  const toast = useToast();
  const { t } = useTranslation(["overview", "common"]);
  const { text2, text3, text6 } = useAppColor();

  const {
    stakeLogo: _stakeLogo,
    pendingRewards,
    stakeList,
    symbol,
    link,
  } = stakeToken;

  const { tokenRates, zilPriceInUsd, tokens } =
    useAppSelector(overviewSelector);

  const { isHidden, exchangeRate, currentWallet } =
    useAppSelector(loginSelector);

  const handleOnClickClaim = async (stakeAddress: string) => {
    let contractMethod = "";
    switch (stakeToken.name) {
      case "Carbon":
      case "GRPH":
        contractMethod = "withdrawPending";
        break;
      case "Port":
      case "Unifees":
        contractMethod = "WithdrawRewards";
        break;
      case "Blox":
        contractMethod = "withdrawStake";
        break;
      default:
        handleGoLink(link);
        return;
    }

    if (contractMethod === "") {
      return;
    }

    if (!currentWallet || !isConnectZilpay(currentWallet.zilAddress)) {
      toast({
        id: "pleaseConnectWallet",
        description: t("pleaseConnectWallet"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const CHAIN_ID = 1;
    const MSG_VERSION = 1;

    const contractParams = [] as any[];
    const contract = window.zilPay.contracts.at(stakeAddress);

    const version = bytes.pack(CHAIN_ID, MSG_VERSION);
    const gasLimit = getGasLimitForClaim();
    const gasPrice = getGasPriceForClaim();

    const txParams = {
      version: version,
      amount: new BN(0),
      gasPrice: gasPrice,
      gasLimit: gasLimit,
    };

    try {
      const sentTx = await contract.call(
        contractMethod,
        contractParams,
        txParams
      );

      toast({
        render: () => <BoxTransaction TranID={`0x${sentTx.ID}`} />,
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <ModalHeader textTransform="capitalize" color={text3}>
        {t("claimStaking")}
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>{t("seedNode")}</Th>
              <Th isNumeric>{t("unclaimedReward")}</Th>
              <Th />
            </Tr>
          </Thead>
          <Tbody>
            {stakeList.map((stake, index) => {
              if (!stake.stakingBalance) {
                return <React.Fragment key={index} />;
              }

              const { name, stakeSymbol, rewardSymbol } = stake;
              let reward = 0;
              if (pendingRewards) {
                reward =
                  pendingRewards.find(
                    (pendingReward) => pendingReward.address === stake.address
                  )?.pendingReward || 0;
              }

              let rate = 1;
              const tokenRate = tokenRates.find(
                (tokenRate) => tokenRate.token.symbol === symbol
              );
              if (tokenRate) {
                rate = tokenRate.rate;
              }

              const stakeLogo =
                tokens.find((token) => token.symbol === stakeSymbol)?.icon ||
                _stakeLogo;
              const rewardLogo =
                tokens.find((token) => token.symbol === rewardSymbol)?.icon ||
                _stakeLogo;

              return (
                <Tr key={index}>
                  <Td>
                    <Flex alignItems="center">
                      {stakeSymbol && rewardSymbol ? (
                        <Box position="relative" mr="2" w="9" h="9">
                          <TokenLogo size="xs" src={rewardLogo} />
                          <TokenLogo
                            position="absolute"
                            size="xs"
                            src={stakeLogo}
                            right="0"
                            bottom="0"
                          />
                        </Box>
                      ) : (
                        <TokenLogo size="sm" mr="2" src={stakeLogo} />
                      )}
                      <Text color={text2} fontSize="sm" fontWeight="semibold">
                        {name}
                      </Text>
                    </Flex>
                  </Td>
                  <Td isNumeric>
                    <Flex justifyContent="flex-end" alignItems="center">
                      <Text fontSize="sm" fontWeight="semibold" mr="1">
                        {formatToken(reward, rewardSymbol || symbol, isHidden)}
                      </Text>
                      <TokenLogo w="4" h="4" src={rewardLogo} />
                    </Flex>
                    <Text fontSize="xs" color={text6}>
                      ~{" "}
                      {formatUsdt(
                        reward * rate * zilPriceInUsd,
                        exchangeRate,
                        isHidden
                      )}
                    </Text>
                  </Td>
                  <Td isNumeric>
                    <Button
                      children={t("common:claim")}
                      onClick={() => handleOnClickClaim(stake.address)}
                    />
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </ModalBody>
    </>
  );
};

export default ModalClaim;
