import {
  Box,
  Divider,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { IconArrowDown, IconSwap } from "src/assets/icons";
import { Images } from "src/assets/images";
import { TokenLogo } from "src/atoms";
import { useAppColor } from "src/hooks";

interface DexLeftProps {}

const DexLeft: React.FC<DexLeftProps> = () => {
  const { t } = useTranslation(["swap", "common"]);
  const { text2, text3, text5, main2, main4 } = useAppColor();

  return (
    <Box>
      <Text fontSize="md" lineHeight="6" fontWeight="semibold">
        {t("title1")}
      </Text>
      <Divider mt="10px" orientation="horizontal" bg={main4} />
      <Text mt="20px" lineHeight="20px" fontWeight="600">
        {t("common:swap")}
      </Text>
      <InputGroup mt="10px">
        <Input placeholder={t("common:amount")} size="lg" bgColor={main2} />
        <InputRightElement w="max-content" px="10px" cursor="pointer">
          <Flex alignItems="center" mt="2">
            <TokenLogo src={Images.xsgd} size="xs" />
            <Text fontSize="sm" color={text3} mx="3" textTransform="uppercase">
              {"XSGD"}
            </Text>
            <IconArrowDown />
          </Flex>
        </InputRightElement>
      </InputGroup>
      <Text
        display="flex"
        alignItems="center"
        justifyContent="end"
        mt="16px"
        fontSize="xs"
        lineHeight="4"
        fontWeight="bold"
        color={text5}
        letterSpacing="wider"
      >
        {t("common:balance")}:
        <Text
          as="span"
          fontSize="xs"
          lineHeight="4"
          fontWeight="bold"
          color={text2}
          letterSpacing="wider"
          ml="8px"
        >
          40,000
        </Text>
      </Text>
      <Flex alignItems="center" justifyContent="center" my="10px">
        <IconSwap style={{ transform: `rotate(90deg)` }} />
      </Flex>
      <Text lineHeight="20px" fontWeight="600" fontSize="14px">
        {t("common:for")}
      </Text>
      <InputGroup mt="10px">
        <Input placeholder={t("common:amount")} size="lg" bgColor={main2} />
        <InputRightElement w="max-content" px="10px" cursor="pointer">
          <Flex alignItems="center" mt="2">
            <TokenLogo src={Images.zil} size="xs" />
            <Text fontSize="sm" color={text3} mx="3" textTransform="uppercase">
              {"ZIL"}
            </Text>
            <IconArrowDown />
          </Flex>
        </InputRightElement>
      </InputGroup>
      <Text
        display="flex"
        alignItems="center"
        justifyContent="end"
        mt="16px"
        fontSize="xs"
        lineHeight="4"
        fontWeight="bold"
        color={text5}
        letterSpacing="wider"
      >
        {t("common:balance")}:
        <Text
          as="span"
          fontSize="xs"
          lineHeight="4"
          fontWeight="bold"
          color={text2}
          letterSpacing="wider"
          ml="8px"
        >
          40,000
        </Text>
      </Text>
      <Text mt="20px" lineHeight="20px" fontWeight="600" fontSize="14px">
        {t("filter")}
      </Text>
      <InputGroup mt="10px">
        <Input placeholder={t("allRate")} size="lg" bgColor={main2} />
        <InputRightElement w="max-content" px="10px" cursor="pointer">
          <Flex alignItems="center" mt="2">
            <IconArrowDown />
          </Flex>
        </InputRightElement>
      </InputGroup>
      <InputGroup mt="10px">
        <Input placeholder={t("allPartner")} size="lg" bgColor={main2} />
        <InputRightElement w="max-content" px="10px" cursor="pointer">
          <Flex alignItems="center" mt="2">
            <IconArrowDown />
          </Flex>
        </InputRightElement>
      </InputGroup>
    </Box>
  );
};

export default DexLeft;
