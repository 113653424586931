import React from "react";
import Icon from "@chakra-ui/icon";
import { SvgIconProps } from "src/constants/type";

const SvgComponent: React.FC<SvgIconProps> = ({
  size,
  ...props
}: SvgIconProps) => {
  return (
    <Icon {...props} w={size} h={size} viewBox="0 0 16 16">
      <path d="M8.66645 10.7807L12.2425 7.20468L13.1851 8.14735L7.99979 13.3327L2.81445 8.14735L3.75712 7.20468L7.33312 10.7807V2.66602H8.66645V10.7807Z" />
    </Icon>
  );
};

const IconReceive = React.memo(SvgComponent);
export default IconReceive;
