import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { TokenLogo } from "src/atoms";
import { IEstimateReward } from "src/constants/interfaces";
import { useAppColor } from "src/hooks";
import { useAppSelector } from "src/redux/hooks";
import { formatToken, formatUsdt } from "src/lib/formatCurrency";
import { overviewSelector } from "src/redux/slices/overviewSlice";
import { loginSelector } from "src/redux/slices/loginSlice";
import { isEmptyLiquidityShare } from "src/utils";

interface EstimateRowProps {
  icon: string;
  rewardList: IEstimateReward[];
  name?: string;
  frequency?: string;
}

const EstimateRow: React.FC<EstimateRowProps> = ({
  icon,
  name,
  frequency,
  rewardList,
}) => {
  const { text1, text3, text6 } = useAppColor();
  const { tokens, zilPriceInUsd, tokenRates } =
    useAppSelector(overviewSelector);
  const { isHidden, exchangeRate } = useAppSelector(loginSelector);
  const [totalZil, setTotalZil] = React.useState<number>(0);

  React.useEffect(() => {
    if (rewardList) {
      let tmpTotalZil = 0;
      rewardList.forEach((reward) => {
        const tokenRate = tokenRates.find(
          (tokenRate) => tokenRate.token.symbol === reward.estimateRewardSymbol
        );
        tmpTotalZil += reward.estimateReward * (tokenRate?.rate || 1);
      });
      setTotalZil(tmpTotalZil);
    }
  }, [rewardList, tokenRates]);

  return (
    <Flex alignItems="center" justifyContent="space-between">
      <Flex>
        <TokenLogo src={icon} size="xs" mr="2" />
        <Box>
          <Text fontSize="sm" fontWeight="semibold">
            {name}
          </Text>
          <Text color={text6} fontSize="xs">
            {frequency}
          </Text>
        </Box>
      </Flex>
      {rewardList && (
        <Box align="right">
          <Flex
            w="full"
            alignItems="center"
            justifyContent="flex-end"
            color={text1}
            fontSize="sm"
            fontWeight="semibold"
          >
            {isEmptyLiquidityShare(rewardList) ? (
              <React.Fragment>
                <Text fontSize="xs" ml="1">
                  {formatToken(0, "ZIL", isHidden)}
                </Text>
                <TokenLogo size="2xs" src={icon} ml="1" />
              </React.Fragment>
            ) : (
              rewardList.map((reward, index) => {
                if (index > 1 || reward.estimateReward <= 0) {
                  return <React.Fragment key={index} />;
                }

                const stakeIcon =
                  tokens.find(
                    (token) => token.symbol === reward.estimateRewardSymbol
                  )?.icon || icon;

                return (
                  <React.Fragment key={index}>
                    <Text fontSize="xs" ml="1">
                      {index !== 0 && " + "}
                      {formatToken(
                        reward.estimateReward,
                        reward.estimateRewardSymbol || "ZIL",
                        isHidden
                      )}
                    </Text>
                    <TokenLogo size="2xs" src={stakeIcon || icon} ml="1" />
                  </React.Fragment>
                );
              })
            )}
            {rewardList.length > 2 && <Text ml="2">...</Text>}
          </Flex>
          <Text color={text3} fontSize="xs">
            ~ {formatUsdt(totalZil * zilPriceInUsd, exchangeRate, isHidden)}
          </Text>
        </Box>
      )}
    </Flex>
  );
};

export default EstimateRow;
