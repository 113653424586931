import React from "react";
import {
  AvatarGroup,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useBoolean,
} from "@chakra-ui/react";
import { Images } from "src/assets/images";
import { IconFilledArrow } from "src/assets/icons";
import { useAppColor } from "src/hooks";
import { ButtonUnlock, TokenLogo } from "src/atoms";
import { useAppSelector } from "src/redux/hooks";
import { loginSelector } from "src/redux/slices/loginSlice";
import { overviewSelector } from "src/redux/slices/overviewSlice";
import { IPoolInfo, IToken, ITokenPool } from "src/constants/interfaces";
import { formatToken, formatUsdt } from "src/lib/formatCurrency";
import BigNumber from "bignumber.js";
import { useTranslation } from "react-i18next";
import { DistributionWithStatus } from "src/api/zilswapApi";
import { fromBech32Address } from "@zilliqa-js/zilliqa";
import { groupBy } from "lodash";
import { handleGoLink, sortLiquidityPool } from "src/utils";
import LiquidityPoolDetails from "./components/LiquidityPool/LiquidityPoolDetails";
import { MotionBox } from "src/molecules";
import LPXcad from "./components/LiquidityPool/LPXcad";
import LPXcadMb from "./components/LiquidityPool/LPXcadMb";
import LPCarbSwap from "./components/LiquidityPool/LPCarbSwap";
import LPCarbSwapMb from "./components/LiquidityPool/LPCarbSwapMb";

interface IUnclaimedReward {
  token?: IToken;
  reward: number;
}

export type LiquidityPoolBalanceProps = {
  claimEpoch?: (lpToken: any) => void;
  addLP?: (lpToken: any, token: any) => void;
};
export const LiquidityPoolBalance: React.FC<LiquidityPoolBalanceProps> = ({
  claimEpoch,
  addLP,
}) => {
  const { t } = useTranslation(["overview", "common"]);
  const {
    pools,
    tokenRates,
    zilPriceInUsd,
    distributions,
    distributors,
    tokens,
    xcadPools,
    carbSwapPools,
  } = useAppSelector(overviewSelector);

  const { exchangeRate, isHidden } = useAppSelector(loginSelector);
  const [distributionsGroupByToken, setDistributionsGroup] =
    React.useState<any>();
  const [unclaimedReward, setUnclaimedReward] = React.useState<
    IUnclaimedReward[]
  >([
    {
      token: tokens.find((token) => token.symbol === "ZWAP"),
      reward: 0,
    },
  ]);

  /**
   * Check user have liquidity pool or not
   * @param lps
   * @returns
   */
  const isEmptyPool = (checkPool: ITokenPool[]) => {
    const check = checkPool.filter(
      (item) =>
        item.userContribution &&
        item.userContribution.toNumber() > 0 &&
        item.totalContribution.toNumber() > 0
    );
    return check.length === 0;
  };

  /**
   * Check user have rewards or not
   * @param distributions
   * @returns
   */
  const isEmptyDistributions = (distributions: DistributionWithStatus[]) => {
    return distributions.length === 0;
  };

  React.useEffect(() => {
    if (!distributions || !distributors || !tokens) {
      return;
    }

    const distributionsWithToken = distributions
      .filter((distribution) => distribution.readyToClaim)
      .flatMap((d: DistributionWithStatus) => {
        const rewardDistributor = distributors.find((distributor) => {
          return distributor.distributor_address_hex === d.info.distrAddr;
        });

        if (!rewardDistributor) {
          return [];
        }

        const rewardToken = tokens.find((token) => {
          return (
            fromBech32Address(token.address_bech32).toLowerCase() ===
            rewardDistributor.reward_token_address_hex.toLowerCase()
          );
        });

        return [{ rewardToken, rewardDistributor, ...d }];
      })
      .filter((r) => !!r);

    const tokenGroupDistributions = groupBy(
      distributionsWithToken,
      (distribution) => {
        return distribution.rewardDistributor.reward_token_symbol;
      }
    );
    setDistributionsGroup(tokenGroupDistributions);
  }, [distributions, distributors, tokens]);

  const { text1, text2, text3, text5, text6, main1, main2, main3 } =
    useAppColor();

  const [show, setShow] = useBoolean(false);
  const [showAll, setShowAll] = useBoolean(false);

  const onClaim = React.useCallback(
    (lpToken: any) => {
      claimEpoch && claimEpoch(lpToken);
    },
    [claimEpoch]
  );

  const onAdd = React.useCallback(
    (lpToken: any, token: any) => {
      addLP && addLP(lpToken, token);
    },
    [addLP]
  );

  const liquidity_pool_token_list: IPoolInfo[] = [
    {
      name: "ZilSwap",
      link: "www.zilswap.io",
      logo: Images.zilswap,
      tokenPool: sortLiquidityPool(pools),
      distributions: distributions,
      distributors: distributors,
      symbol: "ZIL",
      subName: "www.zilswap.io",
    },
    {
      name: "ZilSwap",
      link: "www.zilswap.io",
      logo: Images.zilswap,
      tokenPool: sortLiquidityPool(pools),
      distributions: distributions,
      distributors: distributors,
      symbol: "ZIL",
      subName: "www.zilswap.io",
    },
    // {
    //   name: "XCAD Network",
    //   link: "https://swap.xcadnetwork.com/pool-overview/",
    //   logo: "https://meta.viewblock.io/ZIL.zil1xfcg9hfpdlmz2aytz0s4dww35hfa6s0jnjut5f/logo",
    //   tokenPool: xcadPools,
    //   distributions: [],
    //   symbol: "XCAD",
    //   subName: "https://swap.xcadnetwork.com"
    // },
    // {
    //   name: "CarbSwap",
    //   link: "https://carbswap.app/#/pool",
    //   logo: Images.carbon,
    //   tokenPool: sortLiquidityPool(carbSwapPools),
    //   distributions: [],
    //   distributors: [],
    //   symbol: "CARB",
    //   subName: "carbswap.app",
    // },
  ];

  React.useEffect(() => {
    let unclaimedReward = [] as IUnclaimedReward[];
    if (!distributionsGroupByToken) {
      return;
    }
    Object.keys(distributionsGroupByToken).forEach((distribution) => {
      let totalReward = 0;
      distributionsGroupByToken[distribution].forEach((list: any) => {
        totalReward += new BigNumber(list.info.amount)
          .div(Math.pow(10, list.rewardToken.decimals))
          .toNumber();
      });
      unclaimedReward.push({
        reward: totalReward,
        token: distributionsGroupByToken[distribution][0].rewardToken,
      });
    });
    setUnclaimedReward(unclaimedReward);
  }, [distributionsGroupByToken]);

  const [selectedRow, setSelectedRow] = React.useState(0);

  const availableToken = (tokenPool: ITokenPool) => {
    const { token, userContribution, totalContribution } = tokenPool;
    return (
      !userContribution ||
      userContribution.toNumber() <= 0 ||
      totalContribution.toNumber() <= 0 ||
      token.address_bech32 === "zil1qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq9yf6pz"
    );
  };

  const allEmpty =
    isEmptyPool(pools) &&
    isEmptyPool(xcadPools) &&
    isEmptyPool(carbSwapPools) &&
    isEmptyDistributions(distributions);

  return (
    <Box>
      <Text my="8" fontSize="xl" fontWeight="bold">
        {t("liquidityPoolBalance")}
      </Text>
      <Box overflow="auto">
        {allEmpty && (
          <Center p="1">
            {isHidden ? (
              <ButtonUnlock />
            ) : (
              <Button
                children={t("addLiquidity")}
                onClick={() => handleGoLink("www.zilswap.io")}
              />
            )}
          </Center>
        )}
        <Box display={{ base: "none", lg: "block" }}>
          <Table
            variant="simple"
            minW="2xl"
            bg={main1}
            borderRadius="lg"
            mb="3"
          >
            {liquidity_pool_token_list.map((lpToken, index) => {
              const {
                name,
                logo,
                link,
                tokenPool,
                distributions,
                symbol,
                subName,
              } = lpToken;

              if (
                isEmptyPool(tokenPool) &&
                isEmptyDistributions(distributions)
              ) {
                return <Thead key={index} />;
              }

              let tokenList = [] as string[];

              tokenPool.forEach((tokenPool) => {
                const { token } = tokenPool;

                if (availableToken(tokenPool)) {
                  return;
                }

                tokenList.push(token.icon);
              });

              const poolSymbol = tokens.find(
                (token) => token.symbol === symbol
              );

              let detail = show && selectedRow === index;
              return (
                <React.Fragment key={index}>
                  <Thead>
                    <Tr>
                      <Td>
                        <Flex alignItems="center">
                          <TokenLogo src={logo} size="sm" mr="2" />
                          <Box>
                            <Text
                              color={text2}
                              fontSize="sm"
                              fontWeight="semibold"
                            >
                              {name}
                            </Text>
                            <Text
                              color={text6}
                              fontSize="xs"
                              cursor="pointer"
                              onClick={() => handleGoLink(link)}
                            >
                              {subName}
                            </Text>
                          </Box>
                        </Flex>
                      </Td>
                      <Td>
                        <AvatarGroup
                          size="xs"
                          spacing={2}
                          max={3}
                          fontSize="xx-small"
                          justifyContent="end"
                        >
                          {tokenList.map((token, index) => (
                            <TokenLogo src={token} key={index} />
                          ))}
                        </AvatarGroup>
                      </Td>
                      <Td isNumeric>
                        {index === 0 ? (
                          <>
                            <Flex
                              alignItems="center"
                              justifyContent="flex-end"
                              color={text1}
                              fontSize="sm"
                              fontWeight="semibold"
                            >
                              {unclaimedReward &&
                                unclaimedReward.map((reward, rIndex) => {
                                  if (rIndex > 1) {
                                    return <div key={rIndex} />;
                                  }
                                  return (
                                    <React.Fragment key={rIndex}>
                                      <Text ml="1">
                                        {rIndex !== 0 && " + "}
                                        {formatToken(
                                          reward.reward,
                                          reward && reward.token
                                            ? reward.token.symbol
                                            : "ZWAP",
                                          isHidden
                                        )}
                                      </Text>
                                      <TokenLogo
                                        size="2xs"
                                        src={reward.token?.icon}
                                        ml="1"
                                      />
                                    </React.Fragment>
                                  );
                                })}
                              {unclaimedReward &&
                                unclaimedReward.length > 2 && (
                                  <Text ml="2">...</Text>
                                )}
                            </Flex>
                            <Text color={text3} fontSize="xs">
                              {t("unclaimedReward")}
                            </Text>
                          </>
                        ) : (
                          <Flex />
                        )}
                      </Td>
                      <Td>
                        <Flex justifyContent="flex-end" alignItems="center">
                          {isHidden ? (
                            <ButtonUnlock mr="4" />
                          ) : (
                            <Button
                              variant="outline"
                              children={t("Claim")}
                              mr="4"
                              onClick={() => onClaim(lpToken)}
                            />
                          )}
                          <IconFilledArrow
                            fill={text1}
                            cursor="pointer"
                            onClick={() => {
                              setShowAll.off();
                              setSelectedRow(index);
                              if (detail || !show) {
                                setShow.toggle();
                              }
                            }}
                            _hover={{
                              fill: text3,
                            }}
                            size="6"
                            style={{
                              transform: `rotate(${detail ? 0 : 180}deg)`,
                            }}
                          />
                        </Flex>
                      </Td>
                    </Tr>
                  </Thead>
                  {detail && (
                    <>
                      <Tbody bg={main3}>
                        <Tr bg={main2}>
                          <Th>{t("pool")}</Th>
                          <Th isNumeric>{t("deposit")}</Th>
                          <Th isNumeric>{t("rewardDistributed")}</Th>
                          <Th isNumeric>{t("action")}</Th>
                        </Tr>
                        {tokenPool.map((tokenPool: ITokenPool, i) => {
                          const {
                            token,
                            userContribution,
                            zilReserve,
                            tokenReserve,
                            totalContribution,
                          } = tokenPool;

                          let distributedRewards = [] as any[];
                          if (token.rewards) {
                            const dXCADCount = token.rewards.filter(
                              (reward) => reward.reward_token_symbol === "dXCAD"
                            ).length;
                            distributedRewards = token.rewards.filter(
                              (reward) =>
                                reward.reward_token_symbol !== "dXCAD" &&
                                reward.reward_token_symbol !== "XCAD"
                            );

                            if (dXCADCount > 1) {
                              const dXCAD = token.rewards.find(
                                (reward) =>
                                  reward.reward_token_symbol === "dXCAD"
                              );
                              distributedRewards.push(dXCAD);
                            }
                          }

                          if (availableToken(tokenPool) && !showAll) {
                            return <Tr key={i} />;
                          }

                          const tokenRate = tokenRates.find(
                            (tokenRate) =>
                              tokenRate.token.address_bech32 ===
                              token.address_bech32
                          );

                          let contributionPercentage = new BigNumber(0);
                          if (totalContribution.toNumber() > 0) {
                            contributionPercentage = userContribution!
                              .dividedBy(totalContribution)
                              .times(100);
                          }

                          let contributionShare =
                            contributionPercentage.shiftedBy(-2);

                          let tokenAmount = contributionShare
                            .times(tokenReserve)
                            .div(Math.pow(10, token.decimals))
                            .toNumber();

                          let zilAmount = contributionShare
                            .times(zilReserve)
                            .div(Math.pow(10, poolSymbol?.decimals || 12))
                            .toNumber();

                          return (
                            <Tr key={i}>
                              <Td display="flex" alignItems="center">
                                <Box position="relative" mr="2" w="9" h="9">
                                  <TokenLogo size="xs" src={token.icon} />
                                  <TokenLogo
                                    position="absolute"
                                    size="xs"
                                    src={poolSymbol?.icon || logo}
                                    right="0"
                                    bottom="0"
                                    bg="white"
                                    p="3px"
                                  />
                                </Box>
                                <Box justifyContent="flex-end">
                                  <Text fontSize="sm" fontWeight="semibold">
                                    {token.symbol} {"<> "} {symbol}
                                  </Text>
                                  <Text fontSize="xs" color={text6}>
                                    {t("poolShare")} |{" "}
                                    {(
                                      contributionShare.toNumber() * 100
                                    ).toFixed(3)}
                                    %
                                  </Text>
                                </Box>
                              </Td>
                              <Td isNumeric>
                                <Flex
                                  justifyContent="flex-end"
                                  alignItems="center"
                                >
                                  <Text
                                    fontSize="sm"
                                    fontWeight="semibold"
                                    mr="1"
                                  >
                                    {formatToken(
                                      tokenAmount,
                                      token.symbol,
                                      isHidden
                                    )}
                                  </Text>
                                  <TokenLogo w="4" h="4" src={token.icon} />
                                  <Text mx="2">+</Text>
                                  <Text
                                    fontSize="sm"
                                    fontWeight="semibold"
                                    mr="1"
                                  >
                                    {formatToken(zilAmount, symbol, isHidden)}
                                  </Text>
                                  <TokenLogo
                                    w="4"
                                    h="4"
                                    src={poolSymbol?.icon || logo}
                                  />
                                </Flex>
                                <Text fontSize="xs" color={text6}>
                                  ~{" "}
                                  {formatUsdt(
                                    (tokenAmount * tokenRate!.rate +
                                      zilAmount) *
                                      zilPriceInUsd,
                                    exchangeRate,
                                    isHidden
                                  )}
                                </Text>
                              </Td>
                              <Td isNumeric>
                                <AvatarGroup
                                  size="sm"
                                  spacing={2}
                                  max={6}
                                  fontSize="xx-small"
                                  justifyContent="end"
                                >
                                  {distributedRewards.map((reward, index) => (
                                    <TokenLogo
                                      src={`https://meta.viewblock.io/ZIL.${reward.reward_token_address}/logo`}
                                      key={index}
                                    />
                                  ))}
                                </AvatarGroup>
                                {/* <Flex
                                  justifyContent="flex-end"
                                  alignItems="center"
                                >
                                  <Text
                                    fontSize="sm"
                                    fontWeight="semibold"
                                    mr="1"
                                    alignItems="center"
                                    display="flex"
                                  >
                                    {token.rewards?.map((reward, index) => {
                                      let contributionPercentage =
                                        reward.adjusted_total_contributed !==
                                        null
                                          ? userContribution!
                                              .dividedBy(
                                                toBigNumber(
                                                  reward.adjusted_total_contributed
                                                )
                                              )
                                              .times(100)
                                          : totalContribution > new BigNumber(0)
                                          ? userContribution!
                                              .dividedBy(totalContribution)
                                              .times(100)
                                          : 0;
                                      // let contributionShare =
                                      //   contributionPercentage.shiftedBy(-2);
                                      let newReward = toBigNumber(
                                        reward.amount
                                      ).times(contributionPercentage);

                                      if (
                                        reward.max_individual_amount > 0 &&
                                        newReward.isGreaterThan(
                                          reward.max_individual_amount
                                        )
                                      ) {
                                        newReward = toBigNumber(
                                          reward.max_individual_amount
                                        );
                                      }

                                      return (
                                        <React.Fragment key={index}>
                                          {index !== 0 && " + "}
                                          {formatToken(
                                            newReward.toNumber(),
                                            reward.reward_token_symbol,
                                            isHidden
                                          )}
                                          <TokenLogo
                                            w="4"
                                            h="4"
                                            ml="1"
                                            src={`https://meta.viewblock.io/ZIL.${reward.reward_token_address}/logo`}
                                          />
                                        </React.Fragment>
                                      );
                                    })}
                                  </Text>
                                </Flex> */}
                                <Text fontSize="xs" color={text6}>
                                  {/* ~${unclaimed} */}
                                </Text>
                              </Td>
                              <Td>
                                <Flex
                                  justifyContent="flex-end"
                                  alignItems="center"
                                >
                                  {isHidden ? (
                                    <ButtonUnlock size="sm" />
                                  ) : (
                                    <>
                                      {/* Hide Remove Button */}
                                      {/* <Button
                                      variant="ghost"
                                      children={t("common:remove")}
                                      mr="4"
                                      size="sm"
                                    /> */}
                                      <Button
                                        children={t("common:add")}
                                        size="sm"
                                        onClick={() => onAdd(lpToken, token)}
                                      />
                                    </>
                                  )}
                                </Flex>
                              </Td>
                            </Tr>
                          );
                        })}
                        <Tr>
                          <Td p="0" position="relative" colSpan={4}>
                            <Center>
                              <IconFilledArrow
                                position="absolute"
                                _groupHover={{
                                  fill: text3,
                                }}
                                onClick={setShowAll.toggle}
                                cursor="pointer"
                                size="6"
                                fill={text5}
                                style={{
                                  transform: `rotate(${showAll ? 0 : 180}deg)`,
                                }}
                              />
                            </Center>
                          </Td>
                        </Tr>
                      </Tbody>
                    </>
                  )}
                </React.Fragment>
              );
            })}
            {!isEmptyPool(xcadPools) && <LPXcad onClaim={onClaim} />}
            {!isEmptyPool(carbSwapPools) && <LPCarbSwap onClaim={onClaim} />}
          </Table>
        </Box>
        {/* ///mobile */}
        <Box display={{ base: "block", lg: "none" }}>
          <Box
            mb="3"
            borderRadius="12px"
            borderWidth={allEmpty ? 0 : 1}
            borderColor={main3}
          >
            <>
              {liquidity_pool_token_list.map((lpToken, index) => {
                const { name, logo, link, tokenPool, distributions, subName } =
                  lpToken;

                if (
                  isEmptyPool(tokenPool) &&
                  isEmptyDistributions(distributions)
                ) {
                  return <Box key={index} />;
                }

                let tokenList = [] as string[];

                tokenPool.forEach((tokenPool) => {
                  const { token } = tokenPool;

                  if (availableToken(tokenPool)) {
                    return;
                  }

                  tokenList.push(token.icon);
                });

                let detail = show && selectedRow === index;
                return (
                  <React.Fragment key={index}>
                    <Box>
                      <Flex alignItems="center" px="4">
                        <TokenLogo src={logo} size="sm" mr="2" />
                        <Flex
                          alignItems="center"
                          flex="1"
                          justifyContent="space-between"
                          py="3"
                          borderBottomWidth="1px"
                          borderBottomColor={main3}
                        >
                          <Box>
                            <Text
                              color={text2}
                              fontSize="sm"
                              fontWeight="semibold"
                            >
                              {name}
                            </Text>
                            <Text
                              color={text6}
                              fontSize="xs"
                              cursor="pointer"
                              onClick={() => handleGoLink(link)}
                            >
                              {subName}
                            </Text>
                          </Box>
                          <Flex alignItems="center">
                            {isHidden ? (
                              <ButtonUnlock mr="4" />
                            ) : (
                              <Button
                                variant="outline"
                                children={t("Claim")}
                                mr="4"
                                onClick={() => onClaim(lpToken)}
                              />
                            )}
                            <IconFilledArrow
                              fill={text1}
                              cursor="pointer"
                              onClick={() => {
                                setShowAll.off();
                                setSelectedRow(index);
                                if (detail || !show) {
                                  setShow.toggle();
                                }
                              }}
                              _hover={{
                                fill: text3,
                              }}
                              size="6"
                              style={{
                                transform: `rotate(${detail ? 0 : 180}deg)`,
                              }}
                            />
                          </Flex>
                        </Flex>
                      </Flex>
                    </Box>
                    <Box px="4" pt="4">
                      <AvatarGroup
                        size="xs"
                        spacing={2}
                        max={3}
                        fontSize="xx-small"
                        justifyContent="end"
                      >
                        {tokenList.map((token, index) => (
                          <TokenLogo src={token} key={index} />
                        ))}
                      </AvatarGroup>
                    </Box>
                    <Flex
                      alignItems="flex-end"
                      flexDirection="column"
                      p="4"
                      pr="14"
                    >
                      <Flex
                        alignItems="center"
                        color={text1}
                        fontSize="sm"
                        fontWeight="semibold"
                      >
                        {unclaimedReward &&
                          unclaimedReward.map((reward, index) => {
                            if (index > 1) {
                              return <div key={index} />;
                            }
                            return (
                              <React.Fragment key={index}>
                                <Text ml="1">
                                  {index !== 0 && " + "}
                                  {formatToken(
                                    reward.reward,
                                    reward && reward.token
                                      ? reward.token.symbol
                                      : "ZWAP",
                                    isHidden
                                  )}
                                </Text>
                                <TokenLogo
                                  size="2xs"
                                  src={reward.token?.icon}
                                  ml="1"
                                />
                              </React.Fragment>
                            );
                          })}
                        {unclaimedReward && unclaimedReward.length > 2 && (
                          <Text ml="2">...</Text>
                        )}
                      </Flex>
                      <Text color={text3} fontSize="xs">
                        {t("rewardDistributed")}
                      </Text>
                    </Flex>
                    {detail && (
                      <MotionBox
                        initial={{ opacity: 0 }}
                        animate={{
                          opacity: 1,
                          transition: { duration: 0.5 },
                        }}
                      >
                        {tokenPool.map((item: ITokenPool, i) => {
                          if (availableToken(item) && !showAll) {
                            return <Box key={i} />;
                          }
                          return (
                            <LiquidityPoolDetails
                              key={i}
                              item={item}
                              onAdd={() => onAdd(lpToken, item.token)}
                            />
                          );
                        })}
                        <Box>
                          <Box p="0" position="relative" colSpan={4}>
                            <Center>
                              <IconFilledArrow
                                zIndex="10"
                                position="absolute"
                                _groupHover={{
                                  fill: text3,
                                }}
                                onClick={() => {
                                  setShowAll.toggle();
                                }}
                                cursor="pointer"
                                size="6"
                                fill={text5}
                                style={{
                                  transform: `rotate(${showAll ? 0 : 180}deg)`,
                                }}
                              />
                            </Center>
                          </Box>
                        </Box>
                      </MotionBox>
                    )}
                    <Divider />
                  </React.Fragment>
                );
              })}
              {!isEmptyPool(xcadPools) && <LPXcadMb onClaim={onClaim} />}
              {!isEmptyPool(carbSwapPools) && (
                <LPCarbSwapMb onClaim={onClaim} />
              )}
            </>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LiquidityPoolBalance;
