import React from "react";
import { IconButton } from "@chakra-ui/react";
import { useAppColor } from "src/hooks";
import { IconStar } from "src/assets/icons";

interface ButtonPinProps {
  active?: boolean;
  isPinned: boolean;
  onClick?: () => void;
}

const ButtonPin: React.FC<ButtonPinProps> = ({ isPinned, active, onClick }) => {
  const { text6 } = useAppColor();

  return (
    <IconButton
      bg={isPinned ? "yellow.400" : text6}
      aria-label="pin"
      size="sm"
      onClick={active ? onClick : () => {}}
      icon={<IconStar />}
    />
  );
};

export default ButtonPin;
