const modal_detail_values = {
  inZil: 0.123,
  poolSize: 0.0000123,
  poolShare: 13,
  estReward: 0.12,
};

const modal_details = [
  { keyName: "Zil per ", value: modal_detail_values.inZil },
  { keyName: "Pool Size", value: modal_detail_values.poolSize },
  {
    keyName: "Pool Share",
    value: modal_detail_values.poolShare.toString() + "%",
  },
  {
    keyName: "Est Reward ",
    value: modal_detail_values.estReward.toString() + "ZWAP",
  },
];

export { modal_details };
