import React from "react";
import { Zilswap } from "zilswap-sdk";
import BigNumber from "bignumber.js";
import { Box, Center, Divider } from "@chakra-ui/layout";
import {
  Flex,
  Text,
  Button,
  Tab,
  TabList,
  Tabs,
  Input,
  useToast,
} from "@chakra-ui/react";
import { IconSwap } from "src/assets/icons";
import { ButtonUnlock, TokenLogo } from "src/atoms";
import { useAppColor, useTransaction } from "src/hooks";
import { useTranslation } from "react-i18next";
import { CONTRACTS, Network } from "zilswap-sdk/lib/constants";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { loginSelector } from "src/redux/slices/loginSlice";
import TokenInput from "src/molecules/TokenInput";
import {
  IWalletToken,
  overviewSelector,
  syncWalletTokens,
} from "src/redux/slices/overviewSlice";
import { isConnectZilpay } from "src/api/zilpayApi";
import { fromBech32Address } from "@zilliqa-js/zilliqa";
import BoxTransaction from "src/molecules/BoxTransaction";
// import { IToken } from "src/constants/interfaces";
// import { fromBech32Address } from "@zilliqa-js/crypto";

const SLIPPAGE_TOLERANCE = [0.1, 0.5, 1, 1.5, 2];
interface OtherLeftProps {
  openAlertModal: (type: number, title: string, content: string) => void;
}

// const getProviderOrKeyFromWallet = (wallet) => {
//   if (!wallet) return null;
//   return wallet.provider;
// };

const OtherLeft: React.FC<OtherLeftProps> = ({ openAlertModal }) => {
  const toast = useToast();
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["swap", "common", "modalAlert"]);
  const { text3, text5, main1, main3, main4 } = useAppColor();

  const {
    currentWallet: _currentWallet,
    isHidden,
    savedWallets,
  } = useAppSelector(loginSelector);
  const { tokenRates } = useAppSelector(overviewSelector);

  const currentWallet = savedWallets.find(
    (savedWallet) => savedWallet.zilAddress === _currentWallet?.zilAddress
  );

  const walletTokens = React.useMemo(
    () => currentWallet?.walletTokens || [],
    [currentWallet]
  );
  const tokenAllowances = React.useMemo(
    () => currentWallet?.tokenAllowances || [],
    [currentWallet]
  );

  const [swapAmount, setSwapAmount] = React.useState<number>(0);
  const [forAmount, setForAmount] = React.useState<number>(0);
  const [swapToken, setSwapToken] = React.useState<IWalletToken>();
  const [forToken, setForToken] = React.useState<IWalletToken>();
  const [slippage, setSlippage] = React.useState<number>(1);
  const [currentTab, setCurrentTab] = React.useState<number>();
  const [showTxApprove, setShowTxApprove] = React.useState(false);

  const [slippageWarning, setSlippageWarning] = React.useState<string>("");

  const [holdingWalletTokens, setHoldingWalletTokens] = React.useState<
    IWalletToken[]
  >([]);

  const [exchangeRate, setExchangeRate] = React.useState<number>(1);
  const [lastChanged, setLastChanged] = React.useState<"swap" | "for">("swap");

  const { printResults } = useTransaction();

  React.useEffect(() => {
    if (!swapToken || swapToken?.token.symbol === "ZIL" || swapAmount <= 0) {
      setShowTxApprove(false);
      return;
    }

    const zilswapContractAddress = CONTRACTS[Network.MainNet];
    const byte20ContractAddress = fromBech32Address(
      zilswapContractAddress
    ).toLowerCase();

    const tokenAllowance = tokenAllowances.find(
      (x) => x.token.address_bech32 === swapToken.token.address_bech32
    );
    if (
      !tokenAllowance ||
      !tokenAllowance?.allowances?.[byte20ContractAddress]
    ) {
      setShowTxApprove(true);
      return;
    }

    const unitlessInAmount = new BigNumber(swapAmount).shiftedBy(
      swapToken.token.decimals
    );
    const allowanceAmount = new BigNumber(
      tokenAllowance?.allowances?.[byte20ContractAddress]
    );
    if (unitlessInAmount.isGreaterThan(allowanceAmount)) {
      setShowTxApprove(true);
    } else {
      setShowTxApprove(false);
    }
  }, [tokenAllowances, swapToken, swapAmount]);

  // Clean up
  // React.useEffect(() => {
  //   setSwapAmount(0);
  //   setForAmount(0);
  // }, [swapToken]);

  const unlock = async () => {
    if (!swapToken) {
      return;
    }

    if (!currentWallet || !isConnectZilpay(currentWallet.zilAddress)) {
      toast({
        id: "sent",
        description: t("pleaseConnectWallet"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    const zilswap = new Zilswap(Network.MainNet, window.zilPay);
    await zilswap.initialize(printResults);

    const txApprove = await zilswap.approveTokenTransferIfRequired(
      swapToken.token.address_bech32,
      new BigNumber(1_000_000_000)
    );

    if (!txApprove) {
      toast({
        id: "unlock",
        description: t("approveTokenTransferFailed"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    toast({
      id: "unlock",
      description: t("approveTxPending"),
      status: "success",
      duration: 3000,
      isClosable: true,
    });

    await zilswap.observeTx(txApprove);

    dispatch(syncWalletTokens());
  };

  React.useEffect(() => {
    setCurrentTab(SLIPPAGE_TOLERANCE.indexOf(slippage));
    if (slippage >= 50 || slippage < 0) {
      setSlippageWarning(t("invalid"));
    } else if (slippage < 0.5 && slippage >= 0) {
      setSlippageWarning(t("fail"));
    } else if (slippage <= 50 && slippage > 5) {
      setSlippageWarning(t("risk"));
    } else setSlippageWarning("");
  }, [slippage, t]);

  const onSlippageChange = (value: string) => {
    if (value.length > 4) {
      return;
    }
    if (value === "") {
      //@ts-ignore
      setSlippage("");
      return;
    }
    setSlippage(Number(value));
  };

  React.useEffect(() => {
    const holdingWalletTokens = walletTokens.filter(
      (walletToken) =>
        walletToken && walletToken.balance && walletToken.balance > 0
    );
    setHoldingWalletTokens(holdingWalletTokens);
  }, [walletTokens]);

  React.useEffect(() => {
    if (!swapToken) {
      // Set zil is default
      const zilToken = walletTokens.find(
        (walletToken) => walletToken.token.symbol === "ZIL"
      );
      setSwapToken(zilToken);
    }
    if (!forToken) {
      //Set zilSwap default
      const zilSwapToken = walletTokens.find(
        (walletToken) => walletToken.token.symbol === "ZWAP"
      );
      setForToken(zilSwapToken);
    }

    if (swapToken && forToken) {
      const swapRate = tokenRates.find(
        (tokenRate) => tokenRate.token.symbol === swapToken.token.symbol
      );
      const forRate = tokenRates.find(
        (tokenRate) => tokenRate.token.symbol === forToken.token.symbol
      );
      if (swapRate && forRate) {
        setExchangeRate(swapRate.rate / forRate.rate);
        if (swapRate?.token.symbol === "ZIL") {
          setExchangeRate(1 / forRate.rate);
        }
        if (forRate?.token.symbol === "ZIL") {
          setExchangeRate(swapRate.rate);
        }
      }
    }
  }, [walletTokens, swapToken, forToken, tokenRates]);

  React.useEffect(() => {
    if (lastChanged === "swap") {
      setForAmount(swapAmount * exchangeRate);
    }
    if (lastChanged === "for") {
      setSwapAmount(forAmount / exchangeRate);
    }
  }, [lastChanged, forAmount, swapAmount, exchangeRate]);

  const handleExchange = async () => {
    if (!currentWallet || !isConnectZilpay(currentWallet.zilAddress)) {
      toast({
        id: "connect",
        description: "Please connect your wallet",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (swapToken && swapAmount > swapToken.balance) {
      toast({
        id: "connect",
        description: t("modalAlert:notEnoughDesc"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (!swapToken || swapToken.balance <= 0 || swapAmount <= 0 || !forToken) {
      toast({
        id: "connect",
        description: t("modalAlert:notEnoughDesc"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const zilswap = new Zilswap(Network.MainNet, window.zilPay);
    await zilswap.initialize(printResults);

    const tokenInAmountUnitless = await zilswap.toUnitless(
      swapToken.token.address_bech32,
      `${swapAmount}`
    );

    // Swap Token
    const txSwap = await zilswap.swapWithExactInput(
      swapToken.token.address_bech32,
      forToken.token.address_bech32,
      tokenInAmountUnitless,
      slippage
    );

    if (!txSwap) {
      toast({
        id: "swap",
        description: t("swapTxFailed"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    await zilswap.observeTx(txSwap);

    toast({
      id: "swap",
      render: () => <BoxTransaction TranID={`0x${txSwap.hash}`} />,
    });

    dispatch(syncWalletTokens());
  };

  const onChangeSwapAmount = (amount: number) => {
    setLastChanged("swap");
    setSwapAmount(amount);
    setForAmount(amount * exchangeRate);
  };

  const onChangeForAmount = (amount: number) => {
    setLastChanged("for");
    setForAmount(amount);
    setSwapAmount(amount / exchangeRate);
  };

  const onSwapIcon = () => {
    onChangeSwapAmount(forAmount);
    setForToken(swapToken);
    setSwapToken(forToken);
  };

  return (
    <Box bg={main1} borderRadius="16px">
      <Box p="5">
        <Text lineHeight="20px" fontWeight="600" fontSize="14px">
          {t("common:swap")}
        </Text>
        <TokenInput
          amount={parseFloat(Number(swapAmount).toFixed(12))}
          currentToken={swapToken}
          setCurrentToken={setSwapToken}
          setAmount={onChangeSwapAmount}
          tokenList={holdingWalletTokens}
        />
        <Center>
          <IconSwap
            style={{ transform: `rotate(90deg)`, margin: 2 }}
            cursor="pointer"
            onClick={onSwapIcon}
          />
        </Center>
        <Text lineHeight="20px" fontWeight="600" fontSize="14px">
          {t("common:for")}
        </Text>
        <TokenInput
          amount={parseFloat(Number(forAmount).toFixed(12))}
          currentToken={forToken}
          setCurrentToken={setForToken}
          setAmount={onChangeForAmount}
          tokenList={walletTokens}
        />
        <Box
          borderRadius="16px"
          border="1px solid"
          mt="20px"
          borderColor={main4}
          p={{ base: "4", lg: "6" }}
        >
          <Text lineHeight="28px" fontWeight="700" fontSize="18px">
            {t("transactionSettings")}
          </Text>
          <Flex justifyContent="space-between" alignItems="center" my="15px">
            <Text
              lineHeight="24px"
              fontWeight="500"
              fontSize="16px"
              color={text5}
            >
              {t("slippageTolerance")}
            </Text>
            <Flex alignItems="center" justifyContent="space-between">
              <Input
                value={slippage}
                variant="unstyled"
                textAlign="right"
                size="xs"
                fontWeight="medium"
                fontSize="md"
                color={text5}
                type="number"
                onChange={(event) => onSlippageChange(event.target.value)}
              />
              <Text color={text5} ml="2">
                %
              </Text>
              {/* <Text lineHeight="24px" fontWeight="medium" fontSize="16px" mx="10px">
                {t("auto")}
              </Text>
              <Switch
                mx="10px"
                size="lg"
                h="28px"
                colorScheme="unset"
                borderRadius="24"
                bg={text5}
                _checked={{ bg: "#01FFB0" }}
              /> */}
            </Flex>
          </Flex>
          <Flex
            direction="column"
            justifyContent="center"
            alignItems="flex-end"
            my="15px"
          >
            <Tabs
              index={currentTab}
              w="full"
              //maxW={{base:'unset',lg:}}
              maxW="360px"
              display="flex"
              justifyContent="flex-end"
              variant="soft-rounded"
              onChange={(index) => setSlippage(SLIPPAGE_TOLERANCE[index])}
            >
              <Flex justifyContent="space-between" alignItems="center" w="full">
                <TabList bg={main4} w="full" borderRadius="full">
                  {SLIPPAGE_TOLERANCE.map((rate, index) => (
                    <Tab
                      key={index}
                      _selected={{
                        bg: currentTab === -1 ? undefined : main3,
                        boxShadow: "base",
                      }}
                      color={text3}
                      w="full"
                      p="0"
                      h="32px"
                    >
                      {rate}%
                    </Tab>
                  ))}
                </TabList>
              </Flex>
            </Tabs>
            <Text
              alignSelf="flex-end"
              fontWeight="bold"
              fontSize="sm"
              color={
                slippageWarning === t("invalid") ? "red.400" : "orange.400"
              }
              h="24px"
              mt="3"
            >
              {slippageWarning}
            </Text>
          </Flex>
          <Flex justifyContent="space-between" alignItems="center" my="15px">
            <Text
              lineHeight="24px"
              fontWeight="500"
              fontSize="16px"
              color={text5}
            >
              {t("minimumReceived")}
            </Text>
            <Box display="flex">
              {slippageWarning === t("invalid") ? (
                <Text
                  lineHeight="28px"
                  fontWeight="700"
                  fontSize="18px"
                  mx="10px"
                >
                  {slippageWarning}
                </Text>
              ) : (
                <Text
                  lineHeight="28px"
                  fontWeight="700"
                  fontSize="18px"
                  mx="10px"
                >
                  {(forAmount || 0) * (1 - slippage / 100)}{" "}
                  {forToken?.token.symbol}
                </Text>
              )}
            </Box>
          </Flex>
          <Flex
            justifyContent="space-between"
            alignItems={{ base: "flex-start", sm: "center" }}
            my="15px"
            flexDirection={{ base: "column", sm: "row" }}
          >
            <Text
              lineHeight="24px"
              fontWeight="500"
              fontSize="16px"
              color={text5}
            >
              {t("exchangeRate")}
            </Text>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Text
                lineHeight="24px"
                fontWeight="500"
                fontSize="16px"
                color={text5}
                mr="5px"
              >
                {1}
              </Text>
              <TokenLogo src={swapToken?.token.icon} w="3" h="3" mr="5px" />
              {` = `}
              <Text
                lineHeight="24px"
                fontWeight="500"
                fontSize="16px"
                color={text5}
                mx="5px"
              >
                {exchangeRate}
              </Text>
              <TokenLogo src={forToken?.token.icon} w="3" h="3"></TokenLogo>
            </Box>
          </Flex>
          {/* <Flex justifyContent="space-between" alignItems="center" my="15px">
            <Text lineHeight="24px" fontWeight="500" size="16px" color={text5}>
              {t("transactionSpeed")}
            </Text>
            <Tabs variant="soft-rounded">
              <Flex justifyContent="space-between" alignItems="center">
                <TabList bg="gray.100" borderRadius="full">
                  {[t("standard"), t("fast"), t("instant")].map(
                    (name, index) => (
                      <Tab
                        key={index}
                        _selected={{ bg: "white", boxShadow: "base" }}
                        color="gray.800"
                        w={{ base: "full", sm: "128px" }}
                        h="48px"
                      >
                        {name}
                      </Tab>
                    )
                  )}
                </TabList>
              </Flex>
            </Tabs>
          </Flex> */}
        </Box>
      </Box>
      <Divider my="10px" orientation="horizontal" bg={main4} />
      <Box display="flex" justifyContent="flex-end" p="15px">
        {isHidden ? (
          <ButtonUnlock />
        ) : showTxApprove ? (
          <Button onClick={unlock}>{t("common:unlock")}</Button>
        ) : (
          <Button onClick={handleExchange}>{t("common:exchange")}</Button>
        )}
      </Box>
    </Box>
  );
};

export default OtherLeft;
