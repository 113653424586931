import React from "react";
import { PageLayout } from "src/templates";
import {
  Modal,
  ModalContent,
  ModalOverlay,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
} from "@chakra-ui/react";
import { useAppColor } from "src/hooks";
import SwapDex from "./components/SwapDex";
import SwapOther from "./components/SwapOther";
import ModalAlert from "src/molecules/ModalAlert";

interface SwapPageProps {
  title: string;
}

const SwapPage: React.FC<SwapPageProps> = ({ title }) => {
  // const { t } = useTranslation("swap");
  // const { text3, main1, main4 } = useAppColor();
  const { main1 } = useAppColor();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modal, setModal] = React.useState<number>(0);
  const [modalTitle, setModalTitle] = React.useState("");
  const [modalContent, setModalContent] = React.useState("");

  const openAlertModal = (type: number, title: string, content: string) => {
    setModalTitle(title);
    setModalContent(content);
    setModal(type);
    onOpen();
  };

  return (
    <PageLayout title={title}>
      <Tabs variant="soft-rounded">
        {/* <Flex
          flexDirection={{ base: "column", lg: "row" }}
          alignItems={{ base: "start", lg: "center" }}
          my="4"
        >
          <TabList
            bg={main4}
            borderRadius="full"
            w={{ base: "full", lg: "unset" }}
          >
            {[t("zilswap"), t("dex")].map((name, index) => (
              <Tab
                key={index}
                _selected={{ bg: main1, boxShadow: "base" }}
                color={text3}
                w={{ base: "full", lg: "112px" }}
              >
                {name}
              </Tab>
            ))}
          </TabList>
        </Flex> */}
        <TabPanels py="5">
          <TabPanel py="5" px="0" bg={main1} borderRadius="16px">
            <SwapOther openAlertModal={openAlertModal} />
          </TabPanel>
          <TabPanel p="0">
            <SwapDex />
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Modal onClose={onClose} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          {modal === 0 ? (
            <ModalAlert
              onClose={onClose}
              title={modalTitle}
              content={modalContent}
            />
          ) : modal === 1 ? (
            <ModalAlert
              onClose={onClose}
              title={modalTitle}
              content={modalContent}
            />
          ) : (
            <div />
          )}
        </ModalContent>
      </Modal>
    </PageLayout>
  );
};

export default SwapPage;
