import React from "react";
import { Box, HStack, Icon, Text, useBoolean } from "@chakra-ui/react";
import { IconExpand, IconMinify } from "src/assets/icons";
import { useAppColor } from "src/hooks";
import { IWallet } from "src/redux/slices/loginSlice";

interface HeaderWalletProps {
  viewWallet: IWallet;
  children?: React.ReactElement;
  minifyChild: React.ReactElement;
}

const HeaderWallet: React.FC<HeaderWalletProps> = ({
  viewWallet,
  children,
  minifyChild,
}) => {
  const { text2, text3 } = useAppColor();

  const { zilAddress } = viewWallet;

  const [show, setShow] = useBoolean(true);

  const onToggleShow = () => {
    setShow.toggle();
  };

  return (
    <Box pb="24px">
      <HStack justify="space-between" pb="18px">
        <HStack>
          <Icon
            as={show ? IconMinify : IconExpand}
            fill={text3}
            size="4"
            mr="1"
            onClick={() => setShow.toggle()}
            cursor="pointer"
          />
          <Text
            display={{ base: "block", md: "none" }}
            fontWeight="semibold"
            color={text2}
          >
            {zilAddress.length > 13
              ? zilAddress.substring(0, 6) +
                "..." +
                zilAddress.substring(zilAddress.length - 6)
              : zilAddress}
          </Text>
          <Text
            display={{ base: "none", md: "block" }}
            fontWeight="semibold"
            color={text2}
          >
            {zilAddress}
          </Text>
        </HStack>
      </HStack>
      {show ? children : React.cloneElement(minifyChild, { onToggleShow })}
    </Box>
  );
};

export default HeaderWallet;
