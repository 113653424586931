import React from "react";
import {
  AvatarGroup,
  Box,
  Flex,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  useBoolean,
} from "@chakra-ui/react";
import BigNumber from "bignumber.js";
import { IconFilledArrow } from "src/assets/icons";
import { TokenLogo } from "src/atoms";
import {
  IPoolInfo,
  IReward,
  IToken,
  ITokenPool,
} from "src/constants/interfaces";
import { formatToken, formatUsdt } from "src/lib/formatCurrency";
import { handleGoLink, profileSortLiquidityPool } from "src/utils";
import { useAppSelector } from "src/redux/hooks";
import { overviewSelector } from "src/redux/slices/overviewSlice";
import { useAppColor } from "src/hooks";
import { DistributionWithStatus } from "src/api/zilswapApi";
import { fromBech32Address } from "@zilliqa-js/zilliqa";
import { groupBy } from "lodash";
import { loginSelector } from "src/redux/slices/loginSlice";
import { useTranslation } from "react-i18next";

interface IUnclaimedReward {
  token?: IToken;
  reward: number;
}

interface LPZwapProps {
  poolInfo: IPoolInfo;
  active?: boolean;
}

const LPZwap: React.FC<LPZwapProps> = ({ poolInfo, active }) => {
  const {
    name,
    link,
    logo,
    tokenPool,
    distributors,
    distributions,
    symbol,
    subName,
  } = poolInfo;

  const { t } = useTranslation(["overview", "common", "profile"]);
  const { exchangeRate, isHidden } = useAppSelector(loginSelector);
  const { tokens, tokenRates, zilPriceInUsd } =
    useAppSelector(overviewSelector);

  const { text1, text2, text3, text6, main2, main3 } = useAppColor();

  const [show, setShow] = useBoolean(false);

  const [tokenList, setTokenList] = React.useState<string[]>([]);

  const [distributionsGroupByToken, setDistributionsGroup] =
    React.useState<any>();

  const [unclaimedReward, setUnclaimedReward] = React.useState<
    IUnclaimedReward[]
  >([
    {
      token: tokens.find((token) => token.symbol === "ZWAP"),
      reward: 0,
    },
  ]);

  const availableToken = (tokenPool: ITokenPool) => {
    const {
      token,
      userContribution: _userContribution,
      totalContribution: _totalContribution,
    } = tokenPool;
    const userContribution = new BigNumber(_userContribution || 0);
    const totalContribution = new BigNumber(_totalContribution || 0);
    return (
      !userContribution ||
      userContribution.toNumber() <= 0 ||
      totalContribution.toNumber() <= 0 ||
      token.address_bech32 === "zil1qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq9yf6pz"
    );
  };

  React.useEffect(() => {
    let tokenList = [] as string[];

    tokenPool.forEach((tokenPool) => {
      const { token } = tokenPool;

      if (availableToken(tokenPool)) {
        return;
      }

      tokenList.push(token.icon);
      setTokenList(tokenList);
    });
  }, [tokenPool]);

  React.useEffect(() => {
    let unclaimedReward = [] as IUnclaimedReward[];
    if (!distributionsGroupByToken) {
      return;
    }
    Object.keys(distributionsGroupByToken).forEach((distribution) => {
      let totalReward = 0;
      distributionsGroupByToken[distribution].forEach((list: any) => {
        totalReward += new BigNumber(list.info.amount)
          .div(Math.pow(10, list.rewardToken.decimals))
          .toNumber();
      });
      unclaimedReward.push({
        reward: totalReward,
        token: distributionsGroupByToken[distribution][0].rewardToken,
      });
    });
    setUnclaimedReward(unclaimedReward);
  }, [distributionsGroupByToken]);

  React.useEffect(() => {
    if (!distributions || !distributors || !tokens) {
      return;
    }

    const distributionsWithToken = distributions
      .filter((distribution) => distribution.readyToClaim)
      .flatMap((d: DistributionWithStatus) => {
        const rewardDistributor = distributors.find((distributor) => {
          return distributor.distributor_address_hex === d.info.distrAddr;
        });

        if (!rewardDistributor) {
          return [];
        }

        const rewardToken = tokens.find((token) => {
          return (
            fromBech32Address(token.address_bech32).toLowerCase() ===
            rewardDistributor.reward_token_address_hex.toLowerCase()
          );
        });

        return [{ rewardToken, rewardDistributor, ...d }];
      })
      .filter((r) => !!r);

    const tokenGroupDistributions = groupBy(
      distributionsWithToken,
      (distribution) => {
        return distribution.rewardDistributor.reward_token_symbol;
      }
    );
    setDistributionsGroup(tokenGroupDistributions);
  }, [distributions, distributors, tokens]);

  const poolSymbol = tokens.find((token) => token.symbol === symbol);

  return (
    <React.Fragment>
      <Thead>
        <Tr>
          <Td>
            <Flex alignItems="center">
              <TokenLogo src={logo} size="sm" mr="2" />
              <Box>
                <Text color={text2} fontSize="sm" fontWeight="semibold">
                  {name}
                </Text>
                <Text
                  color={text6}
                  fontSize="xs"
                  cursor="pointer"
                  onClick={() => handleGoLink(link)}
                >
                  {subName}
                </Text>
              </Box>
            </Flex>
          </Td>
          <Td>
            <AvatarGroup
              size="xs"
              spacing={2}
              max={3}
              fontSize="xx-small"
              justifyContent="end"
            >
              {tokenList.map((token, index) => (
                <TokenLogo src={token} key={index} />
              ))}
            </AvatarGroup>
          </Td>
          <Td isNumeric>
            {unclaimedReward && unclaimedReward.length > 0 && (
              <>
                <Flex
                  alignItems="center"
                  justifyContent="flex-end"
                  color={text1}
                  fontSize="sm"
                  fontWeight="semibold"
                >
                  {unclaimedReward.map((reward, rIndex) => {
                    if (rIndex > 1) {
                      return <div key={rIndex} />;
                    }
                    return (
                      <React.Fragment key={rIndex}>
                        <Text ml="1">
                          {rIndex !== 0 && " + "}
                          {formatToken(
                            reward.reward,
                            reward && reward.token
                              ? reward.token.symbol
                              : "ZWAP",
                            isHidden
                          )}
                        </Text>
                        <TokenLogo size="2xs" src={reward.token?.icon} ml="1" />
                      </React.Fragment>
                    );
                  })}
                  {unclaimedReward.length > 2 && <Text ml="2">...</Text>}
                </Flex>
                <Text color={text3} fontSize="xs">
                  {t("unclaimedReward")}
                </Text>
              </>
            )}
          </Td>
          <Td>
            <Flex justifyContent="flex-end" alignItems="center">
              <IconFilledArrow
                fill={text1}
                cursor="pointer"
                onClick={() => setShow.toggle()}
                _hover={{
                  fill: text3,
                }}
                size="6"
                style={{
                  transform: `rotate(${show ? 0 : 180}deg)`,
                }}
              />
            </Flex>
          </Td>
        </Tr>
      </Thead>
      {show && (
        <>
          <Tbody bg={main3}>
            <Tr bg={main2}>
              <Th>{"Pool"}</Th>
              <Th isNumeric>{t("deposit")}</Th>
              <Th isNumeric>{t("rewardDistributed")}</Th>
              <Th isNumeric>{t("action")}</Th>
            </Tr>
            {profileSortLiquidityPool(tokenPool).map(
              (tokenPool: ITokenPool, i) => {
                const {
                  token,
                  userContribution: _userContribution,
                  zilReserve,
                  tokenReserve,
                  totalContribution: _totalContribution,
                } = tokenPool;

                const userContribution = new BigNumber(_userContribution || 0);
                const totalContribution = new BigNumber(_totalContribution);

                let distributedRewards = [] as any[];
                if (token.rewards) {
                  const dXCADCount = JSON.parse(
                    token.rewards.toString()
                  ).filter(
                    (reward: IReward) => reward.reward_token_symbol === "dXCAD"
                  ).length;
                  distributedRewards = JSON.parse(
                    token.rewards.toString()
                  ).filter(
                    (reward: IReward) =>
                      reward.reward_token_symbol !== "dXCAD" &&
                      reward.reward_token_symbol !== "XCAD"
                  );

                  if (dXCADCount > 1) {
                    const dXCAD = JSON.parse(token.rewards.toString()).find(
                      (reward: IReward) =>
                        reward.reward_token_symbol === "dXCAD"
                    );
                    distributedRewards.push(dXCAD);
                  }
                }

                if (availableToken(tokenPool) && !!active) {
                  return <Tr key={i} />;
                }

                const tokenRate = tokenRates.find(
                  (tokenRate) =>
                    tokenRate.token.address_bech32 === token.address_bech32
                );

                let contributionPercentage = new BigNumber(0);
                if (totalContribution.toNumber() > 0) {
                  contributionPercentage = userContribution!
                    .dividedBy(totalContribution)
                    .times(100);
                }

                let contributionShare = contributionPercentage.shiftedBy(-2);

                let tokenAmount = contributionShare
                  .times(tokenReserve)
                  .div(Math.pow(10, token.decimals))
                  .toNumber();

                let zilAmount = contributionShare
                  .times(zilReserve)
                  .div(Math.pow(10, poolSymbol?.decimals || 12))
                  .toNumber();

                return (
                  <Tr key={i}>
                    <Td display="flex" alignItems="center">
                      <Box position="relative" mr="2" w="9" h="9">
                        <TokenLogo size="xs" src={token.icon} />
                        <TokenLogo
                          position="absolute"
                          size="xs"
                          src={poolSymbol?.icon || logo}
                          right="0"
                          bottom="0"
                          bg="white"
                          p="3px"
                        />
                      </Box>
                      <Box justifyContent="flex-end">
                        <Text fontSize="sm" fontWeight="semibold">
                          {token.symbol} {"<> "} {symbol}
                        </Text>
                        <Text fontSize="xs" color={text6}>
                          {t("poolShare")} |{" "}
                          {(contributionShare.toNumber() * 100).toFixed(3)}%
                        </Text>
                      </Box>
                    </Td>
                    <Td isNumeric>
                      <Flex justifyContent="flex-end" alignItems="center">
                        <Text fontSize="sm" fontWeight="semibold" mr="1">
                          {formatToken(tokenAmount, token.symbol, isHidden)}
                        </Text>
                        <TokenLogo w="4" h="4" src={token.icon} />
                        <Text mx="2">+</Text>
                        <Text fontSize="sm" fontWeight="semibold" mr="1">
                          {formatToken(zilAmount, symbol, isHidden)}
                        </Text>
                        <TokenLogo w="4" h="4" src={poolSymbol?.icon || logo} />
                      </Flex>
                      <Text fontSize="xs" color={text6}>
                        ~{" "}
                        {formatUsdt(
                          (tokenAmount * tokenRate!.rate + zilAmount) *
                            zilPriceInUsd,
                          exchangeRate,
                          isHidden
                        )}
                      </Text>
                    </Td>
                    <Td isNumeric>
                      <AvatarGroup
                        size="sm"
                        spacing={2}
                        max={6}
                        fontSize="xx-small"
                        justifyContent="end"
                      >
                        {distributedRewards.map((reward, index) => (
                          <TokenLogo
                            src={`https://meta.viewblock.io/ZIL.${reward.reward_token_address}/logo`}
                            key={index}
                          />
                        ))}
                      </AvatarGroup>
                      {/* <Flex
                                  justifyContent="flex-end"
                                  alignItems="center"
                                >
                                  <Text
                                    fontSize="sm"
                                    fontWeight="semibold"
                                    mr="1"
                                    alignItems="center"
                                    display="flex"
                                  >
                                    {token.rewards?.map((reward, index) => {
                                      let contributionPercentage =
                                        reward.adjusted_total_contributed !==
                                        null
                                          ? userContribution!
                                              .dividedBy(
                                                toBigNumber(
                                                  reward.adjusted_total_contributed
                                                )
                                              )
                                              .times(100)
                                          : totalContribution > new BigNumber(0)
                                          ? userContribution!
                                              .dividedBy(totalContribution)
                                              .times(100)
                                          : 0;
                                      // let contributionShare =
                                      //   contributionPercentage.shiftedBy(-2);
                                      let newReward = toBigNumber(
                                        reward.amount
                                      ).times(contributionPercentage);

                                      if (
                                        reward.max_individual_amount > 0 &&
                                        newReward.isGreaterThan(
                                          reward.max_individual_amount
                                        )
                                      ) {
                                        newReward = toBigNumber(
                                          reward.max_individual_amount
                                        );
                                      }

                                      return (
                                        <React.Fragment key={index}>
                                          {index !== 0 && " + "}
                                          {formatToken(
                                            newReward.toNumber(),
                                            reward.reward_token_symbol,
                                            isHidden
                                          )}
                                          <TokenLogo
                                            w="4"
                                            h="4"
                                            ml="1"
                                            src={`https://meta.viewblock.io/ZIL.${reward.reward_token_address}/logo`}
                                          />
                                        </React.Fragment>
                                      );
                                    })}
                                  </Text>
                                </Flex> */}
                      <Text fontSize="xs" color={text6}>
                        {/* ~${unclaimed} */}
                      </Text>
                    </Td>
                    <Td>
                      <Flex
                        justifyContent="flex-end"
                        alignItems="center"
                      ></Flex>
                    </Td>
                  </Tr>
                );
              }
            )}
          </Tbody>
        </>
      )}
    </React.Fragment>
  );
};

export default LPZwap;
