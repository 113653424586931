import React from "react";
import {
  AvatarGroup,
  Box,
  Center,
  Flex,
  HStack,
  Popover,
  PopoverArrow,
  PopoverContent,
  Spacer,
  Stack,
  Text,
} from "@chakra-ui/react";
import { VictoryPie } from "victory";
import { useAppColor } from "src/hooks";

import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { overviewSelector } from "src/redux/slices/overviewSlice";
import { formatTokenWithSymbol, formatUsdt } from "src/lib/formatCurrency";
import { loginSelector } from "src/redux/slices/loginSlice";
import { useTranslation } from "react-i18next";
import { isEmptyDeposit, isEmptyProfileLiquidity } from "src/utils";
import { isEmpty } from "lodash";
import DonutTooltip from "src/pages/Overview/components/Chart/DonutTooltip";
import { TokenLogo } from "src/atoms";
import IconWallet from "src/assets/icons/IconWallet";
import IconStaking from "src/assets/icons/IconStacking";
import IconLiquidityPool from "src/assets/icons/IconLiquiPool";
import WalletBalance from "./Wallet/WalletBalance";
import { StakingBalance } from "./Staking/StakingBalance";
import PortfolioContainer from "./components/PortfolioContainer";
import CategoriesContainer from "./components/CategoriesContainer";
import LiquidityPoolBalance from "./Liquidity/LiquidityPoolBalance";
import BigNumber from "bignumber.js";
import { ITokenPool } from "src/constants/interfaces";
import { viewProfileSelector } from "src/redux/slices/viewProfileSlice";

export interface PortfolioProps {}

export interface IPortfolioData {
  name: string;
  value: number;
  color: string;
  icon: any;
  holdings: string[];
}

const chartRadius = 160 / 2;
const strokeWidth = 24;

const emptyPortfolio: IPortfolioData[] = [
  {
    name: "",
    value: 0,
    color: "",
    icon: "",
    holdings: [],
  },
];

const Portfolio: React.FC<PortfolioProps> = ({ ...props }) => {
  const dispatch = useAppDispatch();
  const { tokenRates, tokens } = useAppSelector(overviewSelector);
  const { savedWallets, exchangeRate, isHidden } =
    useAppSelector(loginSelector);

  const { viewWallet } = useAppSelector(viewProfileSelector);

  const [, forceUpdate] = React.useReducer((x) => x + 1, 0);
  const [totalZil, setTotalZil] = React.useState<number>(0);
  const [totalWallet, setTotalWallet] = React.useState<number>(0);
  const [totalStaking, setTotalStaking] = React.useState<number>(0);
  const [totalUnstaking, setTotalUnstaking] = React.useState<number>(0);
  const [totalLP, setTotalLP] = React.useState<number>(0);
  const [totalUnclaimed, setTotalUnClaimed] = React.useState<number>(0);

  const [holdingWalletIcon, setHoldingWalletIcon] = React.useState<string[]>(
    []
  );
  const [holdingStakingIcon, setHoldingStakingIcon] = React.useState<string[]>(
    []
  );
  const [holdingLiquidityIcon, setHoldingLiquidityIcon] = React.useState<
    string[]
  >([]);
  const [holdingUnstakingIcon, setHoldingUnstakingIcon] = React.useState<
    string[]
  >([]);

  const { zilPriceInUsd } = useAppSelector(overviewSelector);

  React.useEffect(() => {
    if (!viewWallet) {
      return;
    }
    let tmpTotalWallet = 0;
    let tmpTotalStaking = 0;
    let tmpTotalLP = 0;
    let tmpTotalUnstaking = 0;
    let tmpTotalUnclaimed = 0;

    let tmpHoldingWalletIcon = [] as string[];
    let tmpHoldingStakingIcon = [] as string[];
    let tmpHoldingLiquidityIcon = [] as string[];
    let tmpHoldingUnstakingIcon = [] as string[];

    const { walletTokens, allStakingList, allLiquidityPools } = viewWallet;

    walletTokens &&
      walletTokens.forEach((walletToken) => {
        if (walletToken.balance && walletToken.balance > 0) {
          tmpHoldingWalletIcon.push(walletToken.token.icon);
        }
        const tokenRate = tokenRates.find(
          (tokenRate) =>
            tokenRate.token.address_bech32 === walletToken.token.address_bech32
        );
        if (tokenRate && walletToken.balance) {
          if (
            walletToken.token.address_bech32 ===
            "zil1qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq9yf6pz"
          ) {
            tmpTotalWallet += walletToken.balance;
          } else {
            tmpTotalWallet += walletToken.balance * tokenRate.rate;
          }
        }
      });

    allStakingList &&
      allStakingList.forEach((stakingList) => {
        if (!isEmptyDeposit(stakingList)) {
          tmpHoldingStakingIcon.push(stakingList.stakeLogo);
        }

        stakingList.stakeList.forEach((stake) => {
          const stakeRate =
            tokenRates.find((rate) => rate.token.symbol === stake.stakeSymbol)
              ?.rate || 1;
          let balance = stake.stakingBalance || 0;
          tmpTotalStaking += balance * stakeRate;
        });

        stakingList.pendingRewards &&
          stakingList.pendingRewards.forEach((pendingReward) => {
            const rewardRate =
              tokenRates.find(
                (rate) =>
                  rate.token.symbol === pendingReward.pendingRewardSymbol
              )?.rate || 1;
            let reward = pendingReward.pendingReward || 0;
            tmpTotalUnclaimed += reward * rewardRate;
          });

        if (stakingList.unstakingPending.length > 0) {
          for (const item of stakingList.unstakingPending) {
            const rewardRate =
              tokenRates.find(
                (rate) => rate.token.symbol === item.unstakingSymbol
              )?.rate || 1;
            tmpTotalUnstaking += item.unstaking * rewardRate;
          }
        }
      });

    allLiquidityPools &&
      allLiquidityPools.forEach((pools) => {
        if (
          !isEmptyProfileLiquidity(pools.tokenPool) ||
          !isEmpty(pools.distributions)
        ) {
          tmpHoldingLiquidityIcon.push(pools.logo);
        }
        pools.tokenPool.forEach((pool: ITokenPool) => {
          const {
            token,
            userContribution: _userContribution,
            // zilReserve,
            tokenReserve,
            totalContribution: _totalContribution,
          } = pool;

          const userContribution = new BigNumber(_userContribution || 0);
          const totalContribution = new BigNumber(_totalContribution || 0);

          const tokenRate = tokenRates.find(
            (tokenRate) =>
              tokenRate.token.address_bech32 === token.address_bech32
          );

          let contributionPercentage = userContribution!
            .dividedBy(totalContribution)
            .times(100);
          let contributionShare = contributionPercentage.shiftedBy(-2);
          let tokenAmount = contributionShare
            .times(tokenReserve)
            .div(Math.pow(10, token.decimals))
            .toNumber();

          // let zilAmount = contributionShare
          //   .times(zilReserve)
          //   .div(Math.pow(10, poolToken))
          //   .toNumber();

          if (
            !isNaN(tokenAmount) && // !isNaN(zilAmount) &&
            tokenRate &&
            tokenRate.rate
          ) {
            tmpTotalLP += 2 * tokenAmount * tokenRate.rate;
          }
        });
      });

    let total =
      tmpTotalWallet +
      tmpTotalStaking +
      tmpTotalLP +
      tmpTotalUnstaking +
      tmpTotalUnclaimed;

    setTotalLP(tmpTotalLP);
    setTotalWallet(tmpTotalWallet);
    setTotalStaking(tmpTotalStaking);
    setTotalUnstaking(tmpTotalUnstaking);
    setTotalUnClaimed(tmpTotalUnclaimed);
    setTotalZil(total);

    setHoldingWalletIcon([...new Set(tmpHoldingWalletIcon)]);
    setHoldingStakingIcon([...new Set(tmpHoldingStakingIcon)]);
    setHoldingLiquidityIcon([...new Set(tmpHoldingLiquidityIcon)]);
    setHoldingUnstakingIcon([...new Set(tmpHoldingUnstakingIcon)]);
  }, [dispatch, tokenRates, totalZil, tokens, savedWallets, viewWallet]);

  const { t } = useTranslation(["overview", "common", "profile"]);
  const { text2, text5, main1, main3, main6 } = useAppColor();

  const [pieChartData, setPortfolioData] =
    React.useState<IPortfolioData[]>(emptyPortfolio);

  const [isOpenTooltip, setIsOpenTooltip] = React.useState<boolean>(false);
  const [selected, setSelected] = React.useState<number>(0);
  const [totalHoldings, setTotalHolding] = React.useState<string[]>([]);
  const initRef = React.useRef<any>();

  React.useEffect(() => {
    let holdings = [] as string[];
    setPortfolioData([
      {
        name: t("walletBalance"),
        value: totalWallet,
        color: "#805AD5",
        icon: IconWallet,
        holdings: holdingWalletIcon,
      },
      {
        name: t("profile:staking"),
        value: totalStaking,
        color: "#DD6B20",
        icon: IconStaking,
        holdings: holdingStakingIcon,
      },
      {
        name: t("profile:unstaking"),
        value: totalUnstaking,
        color: "#3182CE",
        icon: IconStaking,
        holdings: holdingUnstakingIcon,
      },
      {
        name: t("profile:liquidityPools"),
        value: totalLP,
        color: "#D69E2E",
        icon: IconLiquidityPool,
        holdings: holdingLiquidityIcon,
      },
    ]);
    savedWallets.forEach((savedWallet) => {
      savedWallet.holdings.forEach((holding) => {
        holdings.push(holding);
      });
    });
    setTotalHolding([...new Set(holdings)]);
  }, [
    t,
    savedWallets,
    totalLP,
    totalStaking,
    totalWallet,
    totalUnstaking,
    totalUnclaimed,
    holdingWalletIcon,
    holdingStakingIcon,
    holdingUnstakingIcon,
    holdingLiquidityIcon,
  ]);

  // React.useEffect(() => {
  //   dispatch(setSavedWalletHoldings(holdingWalletToken()));
  // }, [dispatch, holdingWalletToken]);

  const colorScale = React.useMemo(
    (): string[] => pieChartData.map((i) => i.color),
    [pieChartData]
  );

  const chartData = React.useMemo(
    () =>
      pieChartData.map((i) => ({
        y:
          (totalZil <= 0 ? 100 / pieChartData.length : i.value / totalZil) *
          100,
      })),
    [totalZil, pieChartData]
  );

  const [selectedPieData, setSelectedPieData] = React.useState<number>(-1);

  React.useEffect(() => {
    const resize = () => forceUpdate();

    // initiate the event handler
    window.addEventListener("resize", resize, false);

    return () => {
      // this will clean up the event every time the component is re-rendered
      window.removeEventListener("resize", resize, false);
    };
  }, []);

  return (
    <Box pt="2">
      {selectedPieData !== -1 ? (
        <PortfolioContainer
          onClose={() => setSelectedPieData(-1)}
          title={
            pieChartData[selectedPieData].name +
            " " +
            (selectedPieData === 0 ? "" : t("profile:balance"))
          }
          withSwitch={selectedPieData === 1 || selectedPieData === 3}
          children={
            selectedPieData === 0 ? (
              <WalletBalance />
            ) : selectedPieData === 1 ? (
              <StakingBalance />
            ) : selectedPieData === 2 ? (
              <Box />
            ) : selectedPieData === 3 ? (
              <LiquidityPoolBalance />
            ) : selectedPieData === 4 ? (
              <Box />
            ) : (
              <Box />
            )
          }
        />
      ) : (
        // {selectedPieData === 0 && <WalletBalance />}
        // {selectedPieData === 1 && <StakingBalance />}
        // {selectedPieData === 2 && <Box />}
        // {selectedPieData === 3 && <LiquidityPoolBalance />}
        // {selectedPieData === 4 && <UnclaimedReward />}
        // </PortfolioContainer>
        <>
          <Flex {...props} direction={{ base: "column", md: "row" }} mb="5">
            <HStack
              w={{ base: "full", md: "49%" }}
              mr={{ base: 0, md: 8 }}
              mb={{ base: 8, md: 0 }}
              borderRadius="lg"
              border="2px"
              justify="space-between"
              align="start"
              borderColor={main3}
              bg={main1}
              p="4"
            >
              <Flex
                h="full"
                flexDirection="column"
                justifyContent="space-evenly"
              >
                <Text fontSize="14px" color={text2}>
                  {t("profile:netWorth")}
                </Text>
                <Box>
                  <Text fontSize="30px" fontWeight="800" color={text2}>
                    {formatTokenWithSymbol(
                      isOpenTooltip ? pieChartData[selected].value : totalZil,
                      "ZIL",
                      isHidden
                    )}
                  </Text>
                  <Text fontSize="16px" color={text5}>
                    ~{" "}
                    {formatUsdt(
                      (isOpenTooltip
                        ? pieChartData[selected].value
                        : totalZil) * zilPriceInUsd,
                      exchangeRate,
                      isHidden
                    )}
                  </Text>
                </Box>
                <AvatarGroup
                  size="2xs"
                  spacing={2}
                  py="2"
                  max={8}
                  fontSize="xx-small"
                >
                  {totalHoldings.length !== 0 ? (
                    totalHoldings.map((holding, index) => (
                      <TokenLogo key={index} src={holding} />
                    ))
                  ) : (
                    <TokenLogo visibility="hidden" />
                  )}
                </AvatarGroup>
              </Flex>
            </HStack>
            <Spacer />
            <Stack
              direction={{ base: "column", md: "row" }}
              w={{ base: "full", md: "49%" }}
              borderRadius="lg"
              border="2px"
              borderColor={main3}
              bg={main1}
              p="4"
            >
              <Center overflow="visible" position="relative">
                <Popover
                  isOpen={isOpenTooltip}
                  closeOnBlur={false}
                  initialFocusRef={initRef}
                >
                  <PopoverContent
                    w="3xs"
                    onMouseEnter={() => setIsOpenTooltip(true)}
                    onMouseLeave={() => setIsOpenTooltip(false)}
                  >
                    <PopoverArrow />
                    <DonutTooltip
                      holdingIcon={
                        selected === 0
                          ? holdingWalletIcon
                          : selected === 1
                          ? holdingStakingIcon
                          : selected === 2
                          ? holdingUnstakingIcon
                          : holdingLiquidityIcon
                      }
                      percentage={
                        totalZil !== 0
                          ? (
                              (pieChartData[selected].value * 100) /
                              totalZil
                            ).toFixed(2)
                          : "0"
                      }
                      chartData={pieChartData[selected]}
                    />
                  </PopoverContent>
                </Popover>
                <VictoryPie
                  events={[
                    {
                      target: "data",
                      eventHandlers: {
                        onMouseEnter: () => {
                          return [
                            {
                              mutation: ({ index }) => {
                                setIsOpenTooltip(true);
                                setSelected(index);
                                return null;
                              },
                            },
                          ];
                        },
                        onMouseLeave: () => {
                          return [
                            {
                              mutation: () => {
                                setIsOpenTooltip(false);
                                return null;
                              },
                            },
                          ];
                        },
                      },
                    },
                  ]}
                  width={chartRadius * 3}
                  height={chartRadius * 1.8}
                  padding={2}
                  cornerRadius={24}
                  innerRadius={chartRadius - strokeWidth}
                  labelPosition="centroid"
                  padAngle={1}
                  animate={{ duration: 2500 }}
                  colorScale={colorScale}
                  data={chartData}
                  labels={() => ""}
                />
              </Center>
              <Spacer />
              <Stack spacing={3} px="4">
                {pieChartData.map((pieChart: IPortfolioData, index) => {
                  return (
                    <HStack key={index} justify="space-between">
                      <HStack>
                        <Box
                          bg={pieChart.color}
                          borderRadius="12px"
                          w="6px"
                          h="6px"
                        />
                        <Text color={main6} fontSize="14px" fontWeight="600">
                          {pieChart.name}
                        </Text>
                      </HStack>
                      <Text color={text2} fontSize="14px" fontWeight="700">
                        {totalZil !== 0
                          ? (
                              (pieChartData[index].value * 100) /
                              totalZil
                            ).toFixed(2)
                          : "0"}
                        %
                      </Text>
                    </HStack>
                  );
                })}
              </Stack>
            </Stack>
          </Flex>
          <CategoriesContainer
            pieChartData={pieChartData}
            onClick={setSelectedPieData}
          />
        </>
      )}
    </Box>
  );
};

export default Portfolio;
