import React from "react";
import { Flex, Box } from "@chakra-ui/layout";
import { IconEdit } from "src/assets/icons";
import { Button } from "@chakra-ui/button";
import { Images } from "src/assets/images";
import { useAppColor } from "src/hooks";
import { Input } from "@chakra-ui/input";
import { TokenLogo } from "src/atoms";
import { useBoolean } from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { changeWalletName, loginSelector } from "src/redux/slices/loginSlice";

interface EditAddressProps {}

const EditAddress: React.FC<EditAddressProps> = () => {
  const { text3 } = useAppColor();
  const { currentWallet } = useAppSelector(loginSelector);
  const dispatch = useAppDispatch();
  const [inputValue, setInputValue] = React.useState<string>("");
  const [isEditing, setIsEditing] = useBoolean(false);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const onEdit = () => {
    setIsEditing.toggle();
    if (!isEditing && inputRef && inputRef.current) {
      inputRef.current.focus({ preventScroll: true });
    }
  };

  React.useEffect(() => {
    setInputValue(currentWallet?.name || "");
  }, [currentWallet]);

  const onBlur = () => {
    dispatch(changeWalletName(inputValue));
  };

  return (
    <Flex alignItems="center">
      <Box w="60">
        <Input
          ref={inputRef}
          value={inputValue}
          onChange={
            isEditing
              ? (event) => setInputValue(event.currentTarget.value)
              : () => {}
          }
          fontSize="xl"
          fontWeight="bold"
          variant={isEditing ? "flushed" : "unstyled"}
          onBlur={onBlur}
        />
      </Box>
      <IconEdit
        fill={text3}
        size="6"
        mx="3"
        onClick={onEdit}
        cursor="pointer"
      />
      <Button
        leftIcon={<TokenLogo src={Images.zil} size="xs" />}
        children={"Zilliqa"}
        variant="outline"
      />
    </Flex>
  );
};

export default EditAddress;
