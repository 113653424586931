import React from "react";
import { Box, Flex } from "@chakra-ui/layout";
import {
  Thead,
  Tr,
  Td,
  AvatarGroup,
  Tbody,
  Th,
  Text,
  useBoolean,
} from "@chakra-ui/react";
import BigNumber from "bignumber.js";
import { isEmpty } from "lodash";
import { IconFilledArrow } from "src/assets/icons";
import { TokenLogo } from "src/atoms";
import { IPoolInfo, ITokenPool } from "src/constants/interfaces";
import { formatToken, formatUsdt } from "src/lib/formatCurrency";
import { useAppSelector } from "src/redux/hooks";
import { overviewSelector } from "src/redux/slices/overviewSlice";
import {
  availableToken,
  handleGoLink,
  profileSortLiquidityPool,
} from "src/utils";
import { useAppColor } from "src/hooks";
import { loginSelector } from "src/redux/slices/loginSlice";
import { useTranslation } from "react-i18next";
import { Images } from "src/assets/images";

interface LPCarbSwapProps {
  poolInfo: IPoolInfo;
  active?: boolean;
}

const LPCarbSwap: React.FC<LPCarbSwapProps> = ({ poolInfo, active }) => {
  const { name, link, logo, tokenPool, symbol, subName, claimableData } =
    poolInfo;
  const { t } = useTranslation(["overview", "common"]);
  const { tokenRates, zilPriceInUsd, tokens } =
    useAppSelector(overviewSelector);
  const { exchangeRate, isHidden } = useAppSelector(loginSelector);

  const [show, setShow] = useBoolean(false);

  const { text1, text2, text3, text6, main2, main3 } = useAppColor();

  const [totalReward, setTotalReward] = React.useState<number>(0);

  React.useEffect(() => {
    let total: number = 0;
    const graph = tokens.find((token) => token.symbol === "GRPH");
    if (graph) {
      const graphDecimal = graph.decimals;
      claimableData &&
        claimableData.forEach((claimable) => {
          total += new BigNumber(claimable.amount)
            .div(Math.pow(10, graphDecimal))
            .toNumber();
        });
    }
    setTotalReward(total);
  }, [claimableData, tokens]);

  if (isEmpty(tokenPool)) {
    return <Thead />;
  }

  let tokenList = [] as string[];

  tokenPool.forEach((tokenPool) => {
    const { token } = tokenPool;
    if (availableToken(tokenPool)) {
      return;
    }
    tokenList.push(token.icon);
  });

  return (
    <React.Fragment>
      <Thead>
        <Tr>
          <Td>
            <Flex alignItems="center">
              <TokenLogo src={logo} size="sm" mr="2" />
              <Box>
                <Text color={text2} fontSize="sm" fontWeight="semibold">
                  {name}
                </Text>
                <Text
                  color={text6}
                  fontSize="xs"
                  cursor="pointer"
                  onClick={() => handleGoLink(link)}
                >
                  {subName}
                </Text>
              </Box>
            </Flex>
          </Td>
          <Td>
            <AvatarGroup
              size="xs"
              spacing={2}
              max={3}
              fontSize="xx-small"
              justifyContent="end"
            >
              {tokenList.map((token, index) => (
                <TokenLogo src={token} key={index} />
              ))}
            </AvatarGroup>
          </Td>
          <Td isNumeric>
            {totalReward > 0 && (
              <>
                <Flex
                  alignItems="center"
                  justifyContent="flex-end"
                  color={text1}
                  fontSize="sm"
                  fontWeight="semibold"
                >
                  <Text>{formatToken(totalReward, "GRPH", isHidden)}</Text>
                  <TokenLogo size="2xs" src={Images.graph} ml="1" />
                </Flex>
                <Text color={text3} fontSize="xs">
                  {t("unclaimedReward")}
                </Text>
              </>
            )}
          </Td>
          <Td>
            <Flex justifyContent="flex-end" alignItems="center">
              <IconFilledArrow
                fill={text1}
                cursor="pointer"
                onClick={() => setShow.toggle()}
                _hover={{
                  fill: text3,
                }}
                size="6"
                style={{
                  transform: `rotate(${show ? 0 : 180}deg)`,
                }}
              />
            </Flex>
          </Td>
        </Tr>
      </Thead>
      {show && (
        <>
          <Tbody bg={main3}>
            <Tr bg={main2}>
              <Th>{t("pool")}</Th>
              <Th isNumeric>{t("deposit")}</Th>
              <Th isNumeric>{t("rewardDistributed")}</Th>
              <Th isNumeric>{t("action")}</Th>
            </Tr>
            {profileSortLiquidityPool(tokenPool).map(
              (tokenPool: ITokenPool, i) => {
                const {
                  token,
                  userContribution: _userContribution,
                  zilReserve,
                  tokenReserve,
                  totalContribution: _totalContribution,
                } = tokenPool;
                const userContribution = new BigNumber(_userContribution || 0);
                const totalContribution = new BigNumber(_totalContribution);

                const poolSymbol = tokens.find(
                  (token) => token.symbol === symbol
                );

                if (availableToken(tokenPool) && !!active) {
                  return <Tr key={i} />;
                }

                const tokenRate = tokenRates.find(
                  (tokenRate) =>
                    tokenRate.token.address_bech32 === token.address_bech32
                );

                const poolTokenRate = tokenRates.find(
                  (tokenRate) => tokenRate.token.symbol === symbol
                );

                let contributionPercentage = new BigNumber(0);
                if (totalContribution.toNumber() > 0) {
                  contributionPercentage = userContribution!
                    .dividedBy(totalContribution)
                    .times(100);
                }

                let contributionShare = contributionPercentage.shiftedBy(-2);

                let tokenAmount = contributionShare
                  .times(tokenReserve)
                  .div(Math.pow(10, token.decimals))
                  .toNumber();

                let poolTokenAmount = contributionShare
                  .times(zilReserve)
                  .div(Math.pow(10, poolSymbol?.decimals || 12))
                  .toNumber();

                return (
                  <Tr key={i}>
                    <Td>
                      <Flex alignItems="center">
                        <Box position="relative" mr="2" w="9" h="9">
                          <TokenLogo size="xs" src={token.icon} />
                          <TokenLogo
                            position="absolute"
                            size="xs"
                            src={poolSymbol?.icon || logo}
                            right="0"
                            bottom="0"
                            p="3px"
                          />
                        </Box>
                        <Box justifyContent="flex-end">
                          <Text fontSize="sm" fontWeight="semibold">
                            {token.symbol} {"<> "} {symbol}
                          </Text>
                          <Text fontSize="xs" color={text6}>
                            {t("poolShare")} |{" "}
                            {(contributionShare.toNumber() * 100).toFixed(3)}%
                          </Text>
                        </Box>
                      </Flex>
                    </Td>
                    <Td isNumeric>
                      <Flex justifyContent="flex-end" alignItems="center">
                        <Text fontSize="sm" fontWeight="semibold" mr="1">
                          {formatToken(tokenAmount, token.symbol, isHidden)}
                        </Text>
                        <TokenLogo w="4" h="4" src={token.icon} />
                        <Text mx="2">+</Text>
                        <Text fontSize="sm" fontWeight="semibold" mr="1">
                          {formatToken(poolTokenAmount, symbol, isHidden)}
                        </Text>
                        <TokenLogo w="4" h="4" src={poolSymbol?.icon || logo} />
                      </Flex>
                      <Text fontSize="xs" color={text6}>
                        ~{" "}
                        {formatUsdt(
                          (tokenAmount * (tokenRate?.rate || 1) +
                            poolTokenAmount * (poolTokenRate?.rate || 1)) *
                            zilPriceInUsd,
                          exchangeRate,
                          isHidden
                        )}
                      </Text>
                    </Td>
                    <Td isNumeric>
                      <AvatarGroup
                        size="sm"
                        spacing={2}
                        max={6}
                        fontSize="sm"
                        justifyContent="end"
                      >
                        {[0].map((i, index) => (
                          <TokenLogo src={Images.graph} key={index} />
                        ))}
                      </AvatarGroup>
                      {/* <Flex justifyContent="flex-end" alignItems="center">
                      <Text
                        fontSize="sm"
                        fontWeight="semibold"
                        mr="1"
                        alignItems="center"
                        display="flex"
                      >
                        {formatToken(0, "dXCAD", isHidden)}
                        <TokenLogo w="4" h="4" ml="1" src={logo} />
                      </Text>
                    </Flex> */}
                      <Text fontSize="xs" color={text6}>
                        {/* ~${unclaimed} */}
                      </Text>
                    </Td>
                    <Td>
                      <Flex
                        justifyContent="flex-end"
                        alignItems="center"
                      ></Flex>
                    </Td>
                  </Tr>
                );
              }
            )}
          </Tbody>
        </>
      )}
    </React.Fragment>
  );
};

export default LPCarbSwap;
