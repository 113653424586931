import React from "react";
import {
  AvatarGroup,
  Box,
  Button,
  Flex,
  Text,
  useBoolean,
} from "@chakra-ui/react";
import { useAppColor } from "src/hooks";
import { ButtonUnlock, TokenLogo } from "src/atoms";
import { IconFilledArrow } from "src/assets/icons";
import { useAppSelector } from "src/redux/hooks";
import { loginSelector } from "src/redux/slices/loginSlice";
import { Images } from "src/assets/images";
import { useTranslation } from "react-i18next";
import { formatToken, formatUsdt } from "src/lib/formatCurrency";
import BigNumber from "bignumber.js";
import { ITokenPool } from "src/constants/interfaces";
import { overviewSelector } from "src/redux/slices/overviewSlice";
import { MotionBox } from "src/molecules";

interface LPZwapDetailsProps {
  item: ITokenPool;
  onAdd?(): void;
}

const LPZwapDetails: React.FC<LPZwapDetailsProps> = ({ item, onAdd }) => {
  const { text3, text5, text6, main1, main3, main4, main6 } = useAppColor();
  const { exchangeRate, isHidden } = useAppSelector(loginSelector);
  const { zilPriceInUsd, tokenRates } = useAppSelector(overviewSelector);

  const { t } = useTranslation(["overview", "common"]);
  const [show, setShow] = useBoolean(false);
  const {
    token,
    userContribution: _userContribution,
    zilReserve,
    tokenReserve,
    totalContribution: _totalContribution,
  } = item;

  const userContribution = new BigNumber(_userContribution || 0);
  const totalContribution = new BigNumber(_totalContribution);

  let distributedRewards = [] as any[];
  if (token.rewards) {
    const dXCADCount = token.rewards.filter(
      (reward) => reward.reward_token_symbol === "dXCAD"
    ).length;
    distributedRewards = token.rewards.filter(
      (reward) =>
        reward.reward_token_symbol !== "dXCAD" &&
        reward.reward_token_symbol !== "XCAD"
    );

    if (dXCADCount > 1) {
      const dXCAD = token.rewards.find(
        (reward) => reward.reward_token_symbol === "dXCAD"
      );
      distributedRewards.push(dXCAD);
    }
  }

  const tokenRate = tokenRates.find(
    (tokenRate) => tokenRate.token.address_bech32 === token.address_bech32
  );

  let contributionPercentage = new BigNumber(0);
  if (totalContribution.toNumber() > 0) {
    contributionPercentage = userContribution!
      .dividedBy(totalContribution)
      .times(100);
  }

  let contributionShare = contributionPercentage.shiftedBy(-2);

  let tokenAmount = contributionShare
    .times(tokenReserve)
    .div(Math.pow(10, token.decimals))
    .toNumber();

  let zilAmount = contributionShare
    .times(zilReserve)
    .div(Math.pow(10, 12))
    .toNumber();

  return (
    <Box bg={main3} borderBottomWidth="1px" borderColor={main4}>
      <Flex alignItems="center" justifyContent="space-between" p="4">
        <Flex alignItems="center">
          <Box position="relative" mr="2" w="9" h="9">
            <TokenLogo size="xs" src={token.icon} />
            <TokenLogo
              position="absolute"
              size="xs"
              src={Images.zil}
              right="0"
              bottom="0"
              bg="white"
            />
          </Box>
          <Text fontSize="sm" fontWeight="semibold">
            {token.symbol} {"<> ZIL"}
          </Text>
        </Flex>
        <IconFilledArrow
          _groupHover={{
            fill: text3,
          }}
          onClick={setShow.toggle}
          cursor="pointer"
          size="6"
          fill={text5}
          style={{
            transform: `rotate(${show ? 0 : 180}deg)`,
          }}
        />
      </Flex>
      <Flex alignItems="center" justifyContent="space-between" p="4">
        <Text fontSize="xs" color={text6}>
          {t("poolShare")} | {(contributionShare.toNumber() * 100).toFixed(3)}%
        </Text>
        <Flex>
          {isHidden ? (
            <ButtonUnlock size="sm" />
          ) : (
            <>
              {/* Hide Remove Button */}
              {/* <Button
		      variant="ghost"
		      children={t("common:remove")}
		      mr="4"
		      size="sm"
		    /> */}
              <Button children={t("common:add")} size="sm" onClick={onAdd} />
            </>
          )}
        </Flex>
      </Flex>
      {!!show && (
        <MotionBox
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            transition: { duration: 0.5 },
          }}
          pl="16px"
          bg={main1}
        >
          <Flex
            justifyContent="space-between"
            alignItems="center"
            pt="4"
            pb="2"
            borderBottomWidth="1px"
            borderColor={main4}
            pr="4"
          >
            <Text
              fontSize="xs"
              fontWeight="bold"
              textTransform="uppercase"
              color={main6}
            >
              {t("deposit")}
            </Text>
            <Flex flexDirection="column" alignItems="flex-end">
              <Flex alignItems="center">
                <Text fontSize="sm" fontWeight="semibold" mr="1">
                  {formatToken(tokenAmount, token.symbol, isHidden)}
                </Text>
                <TokenLogo w="4" h="4" src={token.icon} />
                <Text mx="2">+</Text>
                <Text fontSize="sm" fontWeight="semibold" mr="1">
                  {formatToken(zilAmount, "ZIL", isHidden)}
                </Text>
                <TokenLogo w="4" h="4" src={Images.zil} />
              </Flex>
              <Text fontSize="xs" color={text6}>
                ~{" "}
                {formatUsdt(
                  (tokenAmount * tokenRate!.rate + zilAmount) * zilPriceInUsd,
                  exchangeRate,
                  isHidden
                )}
              </Text>
            </Flex>
          </Flex>
          <Flex
            alignItems="center"
            justifyContent="space-between"
            pt="4"
            pb="2"
            pr="4"
            minH="14"
          >
            <Text
              fontSize="xs"
              fontWeight="bold"
              textTransform="uppercase"
              color={main6}
            >
              {t("rewardDistributed")}
            </Text>
            <AvatarGroup
              size="sm"
              spacing={2}
              max={6}
              fontSize="sm"
              justifyContent="end"
            >
              {distributedRewards.map((reward, index) => (
                <TokenLogo
                  src={`https://meta.viewblock.io/ZIL.${reward.reward_token_address}/logo`}
                  key={index}
                />
              ))}
            </AvatarGroup>
            {/* <Text
              fontSize="sm"
              fontWeight="semibold"
              mr="1"
              alignItems="center"
              display="flex"
            >
              {token.rewards?.map((reward, index) => {
                let contributionPercentage =
                  reward.adjusted_total_contributed !== null
                    ? userContribution!
                        .dividedBy(
                          toBigNumber(reward.adjusted_total_contributed)
                        )
                        .times(100)
                    : totalContribution > new BigNumber(0)
                    ? userContribution!.dividedBy(totalContribution).times(100)
                    : 0;
                // let contributionShare =
                //   contributionPercentage.shiftedBy(-2);
                let newReward = toBigNumber(reward.amount).times(
                  contributionPercentage
                );

                if (
                  reward.max_individual_amount > 0 &&
                  newReward.isGreaterThan(reward.max_individual_amount)
                ) {
                  newReward = toBigNumber(reward.max_individual_amount);
                }

                return (
                  <React.Fragment key={index}>
                    {index !== 0 && " + "}
                    {formatToken(
                      newReward.toNumber(),
                      reward.reward_token_symbol,
                      isHidden
                    )}
                    <TokenLogo
                      w="4"
                      h="4"
                      ml="1"
                      src={`https://meta.viewblock.io/ZIL.${reward.reward_token_address}/logo`}
                    />
                  </React.Fragment>
                );
              })}
            </Text> */}
          </Flex>
        </MotionBox>
      )}
    </Box>
  );
};

export default LPZwapDetails;
