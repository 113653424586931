import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// import ms from "./locales/ms.json";
import en from "./locales/en.json";
// import vi from "./locales/vi.json";

import { dayjs } from "src/utils";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en,
      // ms,
      // vi,
    },
    // supportedLngs: ["en", "ms", "vi"],
    supportedLngs: ["en"],
    // fallbackLng: ["en", "ms", "vi"],
    fallbackLng: ["en"],
    defaultNS: "common",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

i18n.on("languageChanged", (lng) => {
  dayjs.locale(`${lng}`);
  localStorage.setItem("lng", lng);
});

export default i18n;
