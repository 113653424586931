import React from "react";
import { Box, Circle } from "@chakra-ui/react";
import { useAppColor } from "src/hooks";

interface StepProps {
  active: boolean;
}

const Step = ({ active }: StepProps) => {
  const { brand } = useAppColor();
  return (
    <Box as="li" display="inline-flex">
      <Circle
        aria-hidden
        zIndex={1}
        position="relative"
        size="5"
        bg={active ? brand : "#EBF0FF"}
      />
    </Box>
  );
};

export default Step;
