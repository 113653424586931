import { extendTheme, withDefaultColorScheme } from "@chakra-ui/react";
import Color from "./Color";
import { Button, Input } from "./components";
import globalStyles from "./global";

const appTheme = extendTheme(
  withDefaultColorScheme({
    colorScheme: "gray",
    components: ["Button", "Switch", "Checkbox", "IconButton"],
  }),
  {
    styles: {
      ...globalStyles,
    },
    colors: {
      ...Color,
    },
    components: {
      Button,
      Input,
    },
  }
);

export default appTheme;
