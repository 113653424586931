import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { normaliseAddress } from "src/api/zilApi";
import { get, set, remove } from "local-storage";

import exchangeRates, { IExchangeRate } from "src/constants/exchangeRates";
import { AppThunk } from "../store";
import { RootState } from "../rootReducer";
import { clearData, IWalletToken, overviewSelector } from "./overviewSlice";
import { generateAvatarUrl } from "src/utils";
import {
  INft,
  IPendingReward,
  IStakingInfo,
  ITokenAllowance,
} from "src/constants/interfaces";
import {
  getSavedWalletsFromDatabase,
  getWalletProfile,
  updateWallet,
} from "src/api/heyAlfieApi";
import BigNumber from "bignumber.js";
import { Images } from "src/assets/images";
import { LoginMessageEnum } from "src/constants/enum";
import { fromBech32Address } from "@zilliqa-js/zilliqa";
import { apiGetBtcPriceInUsd, apiGetEthPriceInUsd } from "src/api/coingeckoApi";
import { getHeyAlfieNfts } from "src/api/membershipApi";
import { isConnectZilpay } from "src/api/zilpayApi";

const MAX_PIN_NFTS = 100;

export interface IWallet {
  uuid?: string;
  isZilPay: boolean;
  name: string;
  avatar: string;
  banner?: string;
  zilAddress: string;
  zilAddressBase16: string;
  balance: number;
  holdings: string[];
  pinNfts: INft[];
  walletTokens?: IWalletToken[];
  allStakingList?: IStakingInfo[];
  allLiquidityPools?: any[];
  followers: IFollow[];
  followings: IFollow[];
  tokenAllowances?: ITokenAllowance[];
}
export interface IFollow {
  id: number;
  myBech32: string;
  otherBech32: string;
  createdAt?: Date;
  updatedAt?: Date;
}
export interface ILoginMessage {
  id: string;
  message: string;
  status?: "success" | "error" | "warning";
}

export interface ILoginState {
  currentWallet?: IWallet;
  savedWallets: IWallet[];
  preventAuto: boolean;
  isHidden: boolean;
  isLoading: boolean;
  isLoadingWallets: boolean;
  isMember: boolean;
  isLoadingInfo: boolean;
  lockPin: string;
  exchangeRate: IExchangeRate;
  exchangeRates: IExchangeRate[];
  walletBalanceIndex: number;
  message?: ILoginMessage;
  currentTab?: number;
  mainWallet: string;
}

const getExchangeRateFromLocalStorage = (): IExchangeRate => {
  let exchangeRate: IExchangeRate = get("exchangeRate");
  if (!exchangeRate) {
    return exchangeRates[0];
  }
  return exchangeRate;
};

export const initialState: ILoginState = {
  isHidden: false,
  currentWallet: undefined,
  lockPin: "",
  isLoading: false,
  isLoadingWallets: false,
  isMember: false,
  isLoadingInfo: false,
  message: undefined,
  savedWallets: [],
  preventAuto: false,
  exchangeRate: getExchangeRateFromLocalStorage(),
  exchangeRates: exchangeRates,
  walletBalanceIndex: 0,
  currentTab: 0,
  mainWallet: "",
};

const saveExchangeRateToLocalStorage = (exchangeRate: IExchangeRate) => {
  set("exchangeRate", exchangeRate);
};

const saveCurrentWalletToLocalStorage = (currentWallet: IWallet) => {
  set("currentWallet", currentWallet);
};

const loadCurrentWalletFromLocalStorage = (): IWallet | null => {
  return get("currentWallet");
};

const removeCurrentWalletFromLocalStorage = () => {
  remove("currentWallet");
};

const saveWalletsToLocalStorage = (savedWallets: IWallet[]) => {
  set("savedWallets", savedWallets);
};

const loadSavedWalletFromLocalStorage = (): IWallet[] => {
  return get("savedWallets");
};

const removeSavedWalletFromLocalStorage = () => {
  set("savedWallets", []);
};

export const saveMembershipToLocalStorage = (isMember: boolean) => {
  set("isMember", isMember);
};

const loadMembershipFromLocalStorage = () => {
  return get("isMember");
};

const removeMembershipFromLocalStorage = () => {
  set("isMember", false);
};

// TODO: set balance type
const saveWalletBalanceToLocalStorage = (address: string, balance: any) => {
  set(`${address}`, balance);
};

// TODO: set balance type
// const getWalletBalanceFromLocalStorage = (address: string): any => {
//   return get(`${address}`);
// };

const saveLockPinToLocalStorage = (lockPin: string) => {
  set("lockPin", lockPin);
};

const loadLockPinFromLocalStorage = (): string => {
  return get("lockPin");
};

const removeLockPinFromLocalStorage = () => {
  remove("lockPin");
};

const saveCurrentTabToLocalStorage = (currentTab: number) => {
  set("currentTab", currentTab);
};

const loadCurrentTabFromLocalStorage = (): number => {
  return get("currentTab");
};

const saveMainWalletToLocalStorage = (mainWallet: string) => {
  set("mainWallet", mainWallet);
};

const loadMainWalletFromLocalStorage = (): string => {
  return get("mainWallet");
};

const removeMainWalletFromLocalStorage = () => {
  remove("mainWallet");
};
export const saveRecentWalletsToLocalStorage = (recentWallets: IWallet[]) => {
  set("recentWallets", recentWallets);
};

export const loadRecentWalletsFromLocalStorage = (): IWallet[] | [] => {
  return get("recentWallets");
};

const checkIsCorrectLockPin = (code: string): boolean => {
  const lockPin: string = loadLockPinFromLocalStorage();
  return lockPin === code;
};

const checkIsHidden = (): boolean => {
  const lockPin: string = loadLockPinFromLocalStorage();
  if (!lockPin) {
    return false;
  }
  return true;
};

const getSavedWallet = (address: string): IWallet | undefined => {
  const savedWallets: IWallet[] = loadSavedWalletFromLocalStorage();

  if (!savedWallets || savedWallets.length <= 0) {
    return undefined;
  }

  const registered = savedWallets.find(
    (wallet) => wallet.zilAddress === address
  );

  return registered;
};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setMessage: (state, { payload }: PayloadAction<ILoginMessage>) => {
      state.message = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setLoadingWallets: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoadingWallets = payload;
    },
    setLoadingInfo: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoadingInfo = payload;
    },
    setIsHidden: (state, { payload }: PayloadAction<boolean>) => {
      state.isHidden = payload;
    },
    setUnlockFailed: (state, { payload }: PayloadAction<ILoginMessage>) => {
      state.isHidden = true;
      state.message = payload;
    },
    setLockPin: (state, { payload }: PayloadAction<string>) => {
      state.lockPin = payload;
    },
    setLoginSuccess: (state, { payload }: PayloadAction<IWallet>) => {
      state.currentWallet = payload;
      saveCurrentWalletToLocalStorage(payload);
      state.message = undefined;
    },
    setLoginFailed: (state, { payload }: PayloadAction<ILoginMessage>) => {
      state.currentWallet = undefined;
      state.message = payload;
    },
    setLogOut: (state) => {
      state.currentWallet = undefined;
    },
    setSavedWallet: (state, { payload }: PayloadAction<IWallet>) => {
      const savedWallet = state.savedWallets.find(
        (e) => e.zilAddress === payload.zilAddress
      );
      if (!savedWallet) {
        state.savedWallets.push(payload);
        saveWalletsToLocalStorage(state.savedWallets);
      }
    },
    setSavedWallets: (state, { payload }: PayloadAction<IWallet[]>) => {
      state.savedWallets = payload;
      if (payload) {
        saveWalletsToLocalStorage(payload);
      }
    },
    setRemoveWallet: (state, { payload }: PayloadAction<IWallet>) => {
      state.savedWallets = state.savedWallets.filter(
        (wallet) => wallet.zilAddress !== payload.zilAddress
      );
      if (
        state.currentWallet?.zilAddress === payload.zilAddress &&
        state.savedWallets[0]
      ) {
        state.currentWallet = state.savedWallets[0];
      }
      saveWalletsToLocalStorage(state.savedWallets);
      if (state.currentWallet) {
        saveCurrentWalletToLocalStorage(state.currentWallet);
      } else {
        removeCurrentWalletFromLocalStorage();
      }
    },
    setWalletName: (state, { payload }: PayloadAction<string>) => {
      if (state.currentWallet) {
        state.currentWallet.name = payload;
        saveCurrentWalletToLocalStorage(state.currentWallet);
      }
      state.savedWallets.map((e) => {
        if (e.zilAddress === state.currentWallet?.zilAddress) {
          e.name = payload;
          state.currentWallet.name = payload;
        }
        return null;
      });
      saveWalletsToLocalStorage(state.savedWallets);
    },
    setWalletBalance: (state, { payload }: PayloadAction<number>) => {
      state.savedWallets.map((e) => {
        if (e.zilAddress === state.currentWallet?.zilAddress) {
          saveWalletBalanceToLocalStorage(
            state.currentWallet.zilAddress,
            payload
          );
          e.balance = payload;
          state.currentWallet.balance = payload;
          saveWalletsToLocalStorage(state.savedWallets);
        }
        return null;
      });
    },
    setPreventAuto: (state, { payload }: PayloadAction<boolean>) => {
      state.preventAuto = payload;
    },
    setExchangeRate: (state, { payload }: PayloadAction<IExchangeRate>) => {
      state.exchangeRate = payload;
      saveExchangeRateToLocalStorage(payload);
    },
    setSavedWalletHoldings: (state, { payload }: PayloadAction<string[]>) => {
      state.savedWallets.map((e) => {
        if (e.zilAddress === state.currentWallet?.zilAddress) {
          e.holdings = payload;
          saveWalletsToLocalStorage(state.savedWallets);
        }
        return null;
      });
    },
    setWalletBalanceIndex: (state, { payload }: PayloadAction<number>) => {
      state.walletBalanceIndex = payload;
    },
    setIsMember: (state, { payload }: PayloadAction<boolean>) => {
      state.isMember = payload;
      saveMembershipToLocalStorage(payload);
    },
    updateWalletPinNfts: (state, { payload }: PayloadAction<INft[]>) => {
      if (state.currentWallet) {
        state.currentWallet.pinNfts = payload;
        saveCurrentWalletToLocalStorage(state.currentWallet);
      }
      state.savedWallets.map((e) => {
        if (e.zilAddress === state.currentWallet?.zilAddress) {
          e.pinNfts = payload;
        }
        return null;
      });
      saveWalletsToLocalStorage(state.savedWallets);
    },
    updateWalletAvatar: (state, { payload }: PayloadAction<string>) => {
      if (state.currentWallet) {
        state.currentWallet.avatar = payload;
        saveCurrentWalletToLocalStorage(state.currentWallet);
      }
      state.savedWallets.map((e) => {
        if (e.zilAddress === state.currentWallet?.zilAddress) {
          e.avatar = payload;
        }
        return null;
      });
      saveWalletsToLocalStorage(state.savedWallets);
    },
    updateWalletBanner: (state, { payload }: PayloadAction<string>) => {
      if (state.currentWallet) {
        state.currentWallet.banner = payload;
        saveCurrentWalletToLocalStorage(state.currentWallet);
      }
      state.savedWallets.map((e) => {
        if (e.zilAddress === state.currentWallet?.zilAddress) {
          e.banner = payload;
        }
        return null;
      });
      saveWalletsToLocalStorage(state.savedWallets);
    },
    updateFollowings: (state, { payload }: PayloadAction<IFollow[]>) => {
      if (state.currentWallet) {
        state.currentWallet.followings = payload;
        saveCurrentWalletToLocalStorage(state.currentWallet);
      }
      state.savedWallets.map((e) => {
        if (e.zilAddress === state.currentWallet?.zilAddress) {
          e.followings = payload;
        }
        return null;
      });
      saveWalletsToLocalStorage(state.savedWallets);
    },
    setCurrentTab: (state, { payload }: PayloadAction<number>) => {
      state.currentTab = payload;
      saveCurrentTabToLocalStorage(payload);
    },
    setMainWallet: (state, { payload }: PayloadAction<string>) => {
      state.mainWallet = payload;
      saveMainWalletToLocalStorage(payload);
    },
    setExchangeRates: (state, { payload }: PayloadAction<IExchangeRate[]>) => {
      state.exchangeRates = payload;
    },
  },
});

export const {
  setMessage,
  setLoginSuccess,
  setLogOut,
  setLoading,
  setLoadingWallets,
  setLoadingInfo,
  setLoginFailed,
  setLockPin,
  setUnlockFailed,
  setSavedWallets,
  setSavedWallet,
  setRemoveWallet,
  setWalletName,
  setWalletBalance,
  setPreventAuto,
  setExchangeRate,
  setIsHidden,
  setSavedWalletHoldings,
  setWalletBalanceIndex,
  setIsMember,
  updateWalletPinNfts,
  updateWalletAvatar,
  updateWalletBanner,
  updateFollowings,
  setCurrentTab,
  setMainWallet,
  setExchangeRates,
} = loginSlice.actions;

export const setSavedWalletFollowings =
  (otherBech32: string): AppThunk =>
  async (dispatch, getState) => {
    const { currentWallet } = loginSelector(getState());
    try {
      if (!currentWallet) {
        return;
      }
      // const wallets = loadRecentWalletsFromLocalStorage();
      // const newWallets = [...wallets];
      // const index = newWallets.findIndex(
      //   (item) => item.zilAddress === otherBech32
      // );

      const newOtherBech32 = JSON.parse(
        currentWallet?.followings[0].otherBech32
      );
      const ind = newOtherBech32.find((item: any) => item === otherBech32);
      if (ind === -1) {
        newOtherBech32.push(otherBech32);
      } else {
        newOtherBech32.splice(ind, 1);
      }

      dispatch(
        updateFollowings([
          { ...currentWallet?.followings[0], otherBech32: newOtherBech32 },
        ])
      );
    } catch (error) {}
  };

export const viewWallet =
  (
    zilAddress: string,
    select?: boolean,
    isZilPay?: boolean,
    name?: string
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(clearData());
      dispatch(setLoading(true));
      dispatch(setCurrentTab(loadCurrentTabFromLocalStorage()));
      let savedWallet = getSavedWallet(zilAddress);
      let currentWallet: IWallet;
      if (savedWallet) {
        // when added before
        currentWallet = savedWallet;
      } else {
        // new address
        const zilAddressBase16 = normaliseAddress(zilAddress);
        const avatar = await generateAvatarUrl(zilAddressBase16);
        currentWallet = {
          isZilPay: isZilPay ? true : false,
          name: name || zilAddress,
          avatar: avatar,
          zilAddress,
          zilAddressBase16,
          balance: 0,
          holdings: [],
          pinNfts: [],
          followers: [],
          followings: [],
        };
      }
      saveCurrentWalletToLocalStorage(currentWallet);
      dispatch(setLoginSuccess(currentWallet));
      if (!select) {
        dispatch(addWallet(currentWallet));
      }
    } catch (error) {
      dispatch(
        setLoginFailed({
          id: "loginFailed",
          message: `${error}`,
          status: "error",
        })
      );
    } finally {
      dispatch(setLoading(false));
    }
  };

export const connectWalletViaZilpay = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const isConnected = await window.zilPay.wallet.connect();
    if (!isConnected) {
      return;
    }
    const { signature, message, publicKey } = await window.zilPay.wallet.sign(
      "Sign in to HeyAlfie"
    );
    if (!signature || !message || !publicKey) {
      return;
    }

    const defaultAccount = window.zilPay.wallet.defaultAccount;
    if (
      !defaultAccount ||
      !defaultAccount.bech32 ||
      defaultAccount.bech32.length === 0
    ) {
      throw new Error("Connect failed: Connection refused.");
    }

    const savedWalletFromDatabase = await getSavedWalletsFromDatabase(
      defaultAccount.bech32
    );
    const heyAlfieNfts = await getHeyAlfieNfts(defaultAccount.base16);
    if (heyAlfieNfts.length > 0 && isConnectZilpay(defaultAccount.bech32)) {
      dispatch(setIsMember(true));
    } else {
      dispatch(setIsMember(false));
    }

    let savedWallets = [] as IWallet[];

    if (savedWalletFromDatabase) {
      const { mainAddress, subAddresses } = savedWalletFromDatabase;
      dispatch(setMainWallet(mainAddress));
      savedWallets = [mainAddress, ...subAddresses].map((address: string) => {
        return {
          isZilPay: address === mainAddress,
          name: address,
          avatar: Images.defaultAvatar,
          zilAddress: address,
          zilAddressBase16: fromBech32Address(address),
          balance: 0,
          holdings: [],
          pinNfts: [],
          followers: [],
          followings: [],
        };
      });
    }

    const avatar = await generateAvatarUrl(defaultAccount.base16);
    const currentWallet: IWallet = {
      isZilPay: true,
      name: defaultAccount.bech32,
      avatar: avatar,
      zilAddress: defaultAccount.bech32,
      zilAddressBase16: defaultAccount.base16,
      balance: 0,
      holdings: [],
      pinNfts: [],
      followers: [],
      followings: [],
    };

    dispatch(setSavedWallets(savedWallets));
    saveCurrentWalletToLocalStorage(currentWallet);
    dispatch(setLoginSuccess(currentWallet));
    dispatch(addWallet(currentWallet));
  } catch (error) {
    console.log(error);
    // const {ca} = error;
    // toast({
    //   id: "pleaseConnectWallet",
    //   description: "",
    //   status: "error",
    //   duration: 3000,
    //   isClosable: true,
    // });
    dispatch(
      setLoginFailed({
        id: "loginFailed",
        message: `${error}`,
        status: "error",
      })
    );
  } finally {
    dispatch(setLoading(false));
  }
};

export const autoConnectPreviousWallet = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    dispatch(setCurrentTab(loadCurrentTabFromLocalStorage()));
    dispatch(setMainWallet(loadMainWalletFromLocalStorage()));

    const currentWallet = loadCurrentWalletFromLocalStorage();
    const isMember = loadMembershipFromLocalStorage();
    if (currentWallet) {
      dispatch(setLoginSuccess(currentWallet));
      dispatch(setIsMember(!!isMember));
      const isHidden = checkIsHidden();
      dispatch(setIsHidden(isHidden));
      dispatch(setSavedWallets(loadSavedWalletFromLocalStorage()));
    }
  } catch (error) {
    dispatch(
      setLoginFailed({
        id: "loginFailed",
        message: `${error}`,
        status: "error",
      })
    );
  } finally {
    dispatch(setLoading(false));
  }
};

export const logoutWallet =
  (all?: boolean): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      removeCurrentWalletFromLocalStorage();
      if (all) {
        removeLockPinFromLocalStorage();
        removeSavedWalletFromLocalStorage();
        removeMembershipFromLocalStorage();
        removeMainWalletFromLocalStorage();
        dispatch(setSavedWallets([]));
      }
      dispatch(setIsMember(false));
      dispatch(setLogOut());
    } catch (error) {
      dispatch(
        setLoginFailed({
          id: "loginFailed",
          message: `${error}`,
          status: "error",
        })
      );
    } finally {
      dispatch(setLoading(false));
    }
  };

export const unlock =
  (lockPin: string): AppThunk =>
  async (dispatch) => {
    try {
      const isCorrectLockPin = checkIsCorrectLockPin(lockPin);
      if (!isCorrectLockPin) {
        dispatch(
          setUnlockFailed({
            id: "unlockFailed",
            message: "Your entered PIN is incorrect.",
            status: "error",
          })
        );
        return;
      }
      dispatch(setLoading(true));
      removeLockPinFromLocalStorage();
      dispatch(setIsHidden(false));
    } catch (error) {
      dispatch(
        setLoginFailed({
          id: "loginFailed",
          message: `${error}`,
          status: "error",
        })
      );
    } finally {
      dispatch(setLoading(false));
    }
  };

export const lock =
  (lockPin: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      saveLockPinToLocalStorage(lockPin);
      dispatch(setIsHidden(true));
    } catch (error) {
      dispatch(
        setLoginFailed({
          id: "loginFailed",
          message: `${error}`,
          status: "error",
        })
      );
    } finally {
      dispatch(setLoading(false));
    }
  };

export const toggleHidden = (): AppThunk => async (dispatch, getState) => {
  try {
    const { isHidden } = loginSelector(getState());
    dispatch(setIsHidden(!isHidden));
  } catch (error) {}
};

export const addWallet =
  (wallet: IWallet): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setSavedWallet(wallet));
      dispatch(setLoginSuccess(wallet));
    } catch (error) {
      // TODO: Display error dialog
      console.log("Wrong Format", error);
    }
  };

export const addZilpayWallet =
  (wallet: IWallet): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setSavedWallet(wallet));
    } catch (error) {
      // TODO: Display error dialog
      console.log("Wrong Format", error);
    }
  };

export const selectWallet =
  (wallet: IWallet): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(logoutWallet());
      dispatch(setLoginSuccess(wallet));
      dispatch(viewWallet(wallet.zilAddress, true, wallet.isZilPay));
    } finally {
      // TODO: ?!
    }
  };

export const removeWallet =
  (wallet: IWallet): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setRemoveWallet(wallet));
    } catch (error) {}
  };

export const changeWalletName =
  (value: string): AppThunk =>
  async (dispatch, getState) => {
    const { currentWallet } = loginSelector(getState());
    try {
      const { signature, message, publicKey } = await window.zilPay.wallet.sign(
        "Change name to " + value
      );
      if (signature && message && publicKey) {
        await updateWallet(currentWallet?.zilAddress || "", value);
        dispatch(setWalletName(value));
        dispatch(
          setMessage({
            id: LoginMessageEnum.UpdateNameSuccess,
            message: "Successfully changed wallet name.",
            status: "success",
          })
        );
      }
    } catch (error) {
      dispatch(
        setMessage({
          id: LoginMessageEnum.UpdateNameFailed,
          message: "Update name failed",
          status: "error",
        })
      );
    }
  };

export const saveWalletBalance =
  (value: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setWalletBalance(value));
    } catch (error) {}
  };

export const preventAutoConnect =
  (value: boolean): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setPreventAuto(value));
    } catch (error) {}
  };

export const changeCurrency =
  (exchangeRate: IExchangeRate): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setExchangeRate(exchangeRate));
      saveExchangeRateToLocalStorage(exchangeRate);
    } catch (error) {}
  };

export const changeWalletBalanceTab =
  (value: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setWalletBalanceIndex(value));
    } catch (error) {}
  };

export const togglePinNft =
  (nft: INft, errorCallback?: () => void): AppThunk =>
  async (dispatch, getState) => {
    const { currentWallet } = loginSelector(getState());
    try {
      if (currentWallet) {
        let temp = currentWallet.pinNfts ? [...currentWallet.pinNfts] : [];
        const index = temp.findIndex((pinNft) => pinNft.image === nft.image);
        if (index === -1) {
          if (temp && temp.length >= MAX_PIN_NFTS) {
            errorCallback && errorCallback();
            return;
          }
          temp.push(nft);
        } else {
          temp.splice(index, 1);
        }
        dispatch(updateWalletPinNfts([...temp]));
      }
    } catch (error) {}
  };

export const getProfileWallet = (): AppThunk => async (dispatch, getState) => {
  try {
    const { savedWallets, isLoadingWallets } = loginSelector(getState());
    const { tokenRates } = overviewSelector(getState());
    if (isLoadingWallets) {
      return;
    }
    dispatch(setLoadingWallets(true));

    const wallet = savedWallets.map((wallet) => wallet.zilAddress);
    const data = await getWalletProfile(wallet);

    const newSavedWallets = savedWallets.map((savedWallet) => {
      const walletTokens = data?.walletTokenList.find(
        (wallet: any) => savedWallet.zilAddress === wallet.walletAddress
      );
      const allStakingList = data?.stakingList.find(
        (staking: any) => savedWallet.zilAddress === staking.walletAddress
      );
      const allLiquidityPools = data?.liquidityPoolList.find(
        (liquidity: any) => savedWallet.zilAddress === liquidity.walletAddress
      );

      const walletInfo = data?.wallets.find(
        (wallet: any) => savedWallet.zilAddress === wallet.bech32
      );
      let walletIconList = [] as string[];
      if (walletTokens) {
        walletTokens.walletTokens
          .filter(
            (walletToken: any) =>
              walletToken && walletToken.balance && walletToken.balance > 0
          )
          .forEach((walletToken: any) =>
            walletIconList.push(walletToken.token.icon)
          );
      }
      let tmpTotalWallet = 0;
      let tmpTotalStaking = 0;
      let tmpTotalLP = 0;
      let tmpTotalUnstaking = 0;
      let tmpTotalUnclaimed = 0;

      walletTokens &&
        walletTokens.walletTokens.forEach((walletToken: IWalletToken) => {
          const tokenRate = tokenRates.find(
            (tokenRate) =>
              tokenRate.token.address_bech32 ===
              walletToken.token.address_bech32
          );
          if (tokenRate && walletToken.balance) {
            if (
              walletToken.token.address_bech32 ===
              "zil1qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq9yf6pz"
            ) {
              tmpTotalWallet += walletToken.balance;
            } else {
              tmpTotalWallet += walletToken.balance * tokenRate.rate;
            }
          }
        });

      allStakingList &&
        allStakingList.allStakingList.forEach((stakingList: IStakingInfo) => {
          stakingList.stakeList.forEach((stake: any) => {
            const stakeRate =
              tokenRates.find((rate) => rate.token.symbol === stake.stakeSymbol)
                ?.rate || 1;
            let balance = stake.stakingBalance || 0;
            tmpTotalStaking += balance * stakeRate;
          });

          stakingList.pendingRewards &&
            stakingList.pendingRewards.forEach(
              (pendingReward: IPendingReward) => {
                const rewardRate =
                  tokenRates.find(
                    (rate) =>
                      rate.token.symbol === pendingReward.pendingRewardSymbol
                  )?.rate || 1;
                let reward = pendingReward.pendingReward || 0;
                tmpTotalUnclaimed += reward * rewardRate;
              }
            );

          if (stakingList.unstakingPending.length > 0) {
            for (const item of stakingList.unstakingPending) {
              const rewardRate =
                tokenRates.find(
                  (rate) => rate.token.symbol === item.unstakingSymbol
                )?.rate || 1;
              tmpTotalUnstaking += item.unstaking * rewardRate;
            }
          }
        });

      allLiquidityPools &&
        allLiquidityPools.allLiquidityPools.forEach((pools: any) => {
          // const poolToken = index === 0 ? 12 : 18;
          pools.tokenPool.forEach((pool: any) => {
            const {
              token,
              userContribution: _userContribution,
              // zilReserve,
              tokenReserve,
              totalContribution: _totalContribution,
            } = pool;

            const userContribution = new BigNumber(_userContribution || 0);
            const totalContribution = new BigNumber(_totalContribution || 0);

            const tokenRate = tokenRates.find(
              (tokenRate) =>
                tokenRate.token.address_bech32 === token.address_bech32
            );

            let contributionPercentage = userContribution!
              .dividedBy(totalContribution)
              .times(100);
            let contributionShare = contributionPercentage.shiftedBy(-2);
            let tokenAmount = contributionShare
              .times(tokenReserve)
              .div(Math.pow(10, token.decimals))
              .toNumber();

            if (
              !isNaN(tokenAmount) && // !isNaN(zilAmount) &&
              tokenRate &&
              tokenRate.rate
            ) {
              tmpTotalLP += 2 * tokenAmount * tokenRate.rate;
            }
          });
        });

      let total =
        tmpTotalWallet +
        tmpTotalStaking +
        tmpTotalLP +
        tmpTotalUnstaking +
        tmpTotalUnclaimed;
      let changedWallet = {
        ...savedWallet,
        balance: total,
        uuid: walletInfo?.uuid,
        followings:
          walletInfo?.followings !== undefined ? walletInfo.followings : [],
        followers:
          walletInfo?.followers !== undefined ? walletInfo.followers : [],
        name: walletInfo?.name || walletInfo?.bech32 || savedWallet?.name,
        avatar: walletInfo?.avatar || Images.defaultAvatar,
        banner: walletInfo?.banner || Images.defaultBanner,
        walletTokens: walletTokens
          ? walletTokens.walletTokens
          : savedWallet.walletTokens,
        tokenAllowances: walletTokens
          ? walletTokens.tokenAllowances
          : savedWallet.tokenAllowances,
        holdings: walletIconList,
        allStakingList: allStakingList
          ? allStakingList.allStakingList
          : savedWallet.allStakingList,
        allLiquidityPools: allLiquidityPools
          ? allLiquidityPools.allLiquidityPools
          : savedWallet.allLiquidityPools,
      };
      return changedWallet;
    });
    // console.log(newSavedWallets);
    dispatch(setSavedWallets(newSavedWallets));
  } catch (error) {
    console.log("pulling data failed", error);
  } finally {
    dispatch(setLoadingWallets(false));
  }
};

export const setSavedWalletAvatar =
  (avatar: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      const { currentWallet } = loginSelector(getState());
      const { signature, message, publicKey } = await window.zilPay.wallet.sign(
        "Change avatar"
      );
      if (signature && message && publicKey) {
        await updateWallet(currentWallet?.zilAddress || "", undefined, avatar);
        dispatch(updateWalletAvatar(avatar));
        dispatch(
          setMessage({
            id: LoginMessageEnum.UpdateAvatarSuccess,
            message: "Update avatar successfully",
            status: "success",
          })
        );
      }
    } catch (error) {
      dispatch(
        setMessage({
          id: LoginMessageEnum.UpdateAvatarFailed,
          message: "Update avatar failed",
          status: "error",
        })
      );
    }
  };

export const setSavedWalletBanner =
  (banner: string): AppThunk =>
  async (dispatch, getState) => {
    const { currentWallet } = loginSelector(getState());
    try {
      const { signature, message, publicKey } = await window.zilPay.wallet.sign(
        "Change banner"
      );
      if (signature && message && publicKey) {
        await updateWallet(
          currentWallet?.zilAddress || "",
          undefined,
          undefined,
          banner
        );
        dispatch(updateWalletBanner(banner));
        dispatch(
          setMessage({
            id: LoginMessageEnum.UpdateBannerSuccess,
            message: "Update banner successfully",
            status: "success",
          })
        );
      }
    } catch (error) {
      dispatch(
        setMessage({
          id: LoginMessageEnum.UpdateBannerFailed,
          message: "Update banner failed",
          status: "error",
        })
      );
    }
  };
export const syncExchangeRates = (): AppThunk => async (dispatch, getState) => {
  try {
    const btcValue = await apiGetBtcPriceInUsd();
    const ethValue = await apiGetEthPriceInUsd();

    const btc: IExchangeRate = {
      key: "btc",
      name: "Bitcoin",
      unit: "BTC",
      value: 48839.604,
      type: "currency",
      tokenValue: btcValue,
      image: Images.bitcoin,
    };

    const eth: IExchangeRate = {
      key: "eth",
      name: "Ethereum",
      unit: "ETH",
      value: 48839.604,
      type: "currency",
      tokenValue: ethValue,
      image:
        "https://meta.viewblock.io/ZIL.zil19j33tapjje2xzng7svslnsjjjgge930jx0w09v/logo",
    };

    const rates: IExchangeRate[] = [btc, eth, ...exchangeRates];

    dispatch(setExchangeRates(rates));
  } catch (error) {}
};

export const loginSelector = (state: RootState) => state.login;

export default loginSlice.reducer;
