import React from "react";
import { useAppSelector } from "src/redux/hooks";
import HeaderWallet from "../HeaderWallet";
import BalanceMinify from "../BalanceMinify";
import { WalletToken } from "./WalletToken";
import { overviewSelector } from "src/redux/slices/overviewSlice";
import { viewProfileSelector } from "src/redux/slices/viewProfileSlice";

export type WalletBalanceProps = {};

const WalletBalance: React.FC<WalletBalanceProps> = () => {
  const { viewWallet } = useAppSelector(viewProfileSelector);
  const { tokenRates } = useAppSelector(overviewSelector);

  if (!viewWallet) {
    return <></>;
  }
  const { walletTokens } = viewWallet;

  let tmpTotalWallet = 0;

  let tmpHoldingWalletIcon = [] as string[];

  walletTokens &&
    walletTokens.forEach((walletToken) => {
      if (walletToken.balance && walletToken.balance > 0) {
        tmpHoldingWalletIcon.push(walletToken.token.icon);
      }
      const tokenRate = tokenRates.find(
        (tokenRate) =>
          tokenRate.token.address_bech32 === walletToken.token.address_bech32
      );
      if (tokenRate && walletToken.balance) {
        if (
          walletToken.token.address_bech32 ===
          "zil1qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq9yf6pz"
        ) {
          tmpTotalWallet += walletToken.balance;
        } else {
          tmpTotalWallet += walletToken.balance * tokenRate.rate;
        }
      }
    });

  return (
    <HeaderWallet
      children={walletTokens && <WalletToken walletTokens={walletTokens} />}
      minifyChild={
        <BalanceMinify
          balance={tmpTotalWallet}
          holdings={tmpHoldingWalletIcon}
        />
      }
      viewWallet={viewWallet}
    />
  );
};

export default WalletBalance;
