import React from "react";
import { Box, Center, Flex, Stack } from "@chakra-ui/layout";
import {
  BoxProps,
  Modal,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  Text,
  Button,
} from "@chakra-ui/react";
import { Footer, Header, Sidebar } from "src/molecules";
import { useAppColor, useRouter } from "src/hooks";
import ModalAddWallets from "./ModalAddWallets";
import ModalPinCode from "./ModalPinCode";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import {
  getProfileWallet,
  loginSelector,
  syncExchangeRates,
} from "src/redux/slices/loginSlice";
import { batch } from "react-redux";
import { apiGetTokens } from "src/api/heyAlfieApi";
import {
  syncCollectionsData,
  syncHeyAlfieNfts,
  updateTokens,
  syncZilPriceInUsd,
  syncTokenRates,
} from "src/redux/slices/overviewSlice";
import { useTranslation } from "react-i18next";
import { ScrollTop } from "src/atoms";
import ModalPremiumAccess from "./ModalPremiumAccess";
import useModal from "src/hooks/useModal";

export interface PageLayoutProps extends BoxProps {
  withoutHeader?: boolean;
  title?: string;
  isGoBack?: boolean;
  isLocked?: boolean;
  onScrollToBottom?: () => void;
}

const PageLayout: React.FC<PageLayoutProps> = ({
  withoutHeader,
  title,
  isGoBack,
  isLocked,
  onScrollToBottom,
  ...props
}) => {
  const { brand, main1, main2 } = useAppColor();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modal, setModal] = React.useState<number>();
  const { currentWallet } = useAppSelector(loginSelector);
  const dispatch = useAppDispatch();
  const router = useRouter();
  const { t } = useTranslation("common");
  const { show, onCloseModalPremium } = useModal();

  const onLock = () => {
    setModal(0);
    onOpen();
  };

  const onAddWallet = () => {
    setModal(1);
    onOpen();
  };

  const loadData = React.useCallback(async () => {
    try {
      // Update tokens
      const tokens = await apiGetTokens();

      // unstaking pending
      // const zilUnstakingPending = await getZilUnstakingPending(
      //   zilAddressBech32
      // );

      batch(() => {
        dispatch(getProfileWallet());
        dispatch(syncExchangeRates());
        // dispatch(getProfileWalletBalances());
        // dispatch(getProfileStakingBalances());
        // dispatch(getProfileLiquidityPools());

        // Update wallet data
        dispatch(updateTokens(tokens));
        // dispatch(syncClaimableData());
        dispatch(syncZilPriceInUsd());
        dispatch(syncTokenRates());
        // dispatch(syncWalletTokens());
        dispatch(syncCollectionsData());
        dispatch(syncHeyAlfieNfts());
        // dispatch(syncXcadClaimableData());
        // dispatch(syncCarbSwapClaimableData());
        // dispatch(syncStakingRewardState());

        // Update pending wallet
        // dispatch(updateUnstakingPending(zilUnstakingPending));
      });
    } catch (error) {
      // Display error
    }
  }, [dispatch]);

  React.useEffect(() => {
    if (!currentWallet) {
      return;
    }

    const secondToRefresh = 60;

    loadData();

    const interval = setInterval(() => {
      loadData();
    }, 1000 * secondToRefresh);

    return () => clearInterval(interval);
  }, [currentWallet, loadData]);

  window.onscroll = () => {
    if (
      window.innerHeight + window.scrollY + 0.5 >=
      document.body.offsetHeight
    ) {
      onScrollToBottom && onScrollToBottom();
    }
  };

  const childrenWithProps = React.Children.map(props.children, (child) => {
    if (React.isValidElement(child) && child.props.id === "totalWallet") {
      return React.cloneElement(child, { onAddWallet });
    }
    return child;
  });

  return (
    <>
      <ScrollTop />
      <Flex bg={main2}>
        <Box display={{ base: "none", xl: "block" }}>
          <Sidebar onAddWallet={onAddWallet} />
        </Box>
        <Flex
          w="full"
          minH="100vh"
          h="auto"
          direction="column"
          maxW="6xl"
          m="auto"
          px="4"
          justifyContent="space-between"
        >
          <Stack flex="1">
            {!!!withoutHeader && (
              <Header
                onLock={onLock}
                isGoBack={isGoBack}
                title={title}
                hidden={true}
                onAddWallet={onAddWallet}
                onRefresh={loadData}
              />
            )}
            <Box {...props} h="full" children={childrenWithProps} />
            {isLocked && (
              <Flex
                w="full"
                maxW="6xl"
                position="absolute"
                h="100vh"
                bg="blackAlpha.900"
                p="6"
              >
                <Center w="full">
                  <Box w="sm" bg={main1} p="6" borderRadius="md">
                    <Text fontSize="sm" fontWeight="semibold">
                      {t("historyPremium")}
                    </Text>
                    <Text mb="6" mt="2" fontSize="xs">
                      {t("needPremium")}
                    </Text>
                    <Button
                      size="lg"
                      w="full"
                      children={t("getPremium")}
                      onClick={() => router.push("/membership")}
                    />
                  </Box>
                </Center>
              </Flex>
            )}
          </Stack>
          <Footer />
        </Flex>
      </Flex>
      <Modal
        size={modal === 0 ? "md" : "2xl"}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          {modal === 0 ? (
            <ModalPinCode onClose={onClose} />
          ) : modal === 1 ? (
            <ModalAddWallets />
          ) : (
            <div />
          )}
        </ModalContent>
      </Modal>
      <Modal size={"md"} isCentered isOpen={show} onClose={onCloseModalPremium}>
        <ModalOverlay css={{ backdropFilter: "blur(3px)" }} />
        <ModalContent>
          <ModalPremiumAccess />
        </ModalContent>
      </Modal>
      <Box
        data-tf-popover="GbMK7s"
        data-tf-button-color={brand}
        data-tf-chat
        data-tf-medium="snippet"
      />
    </>
  );
};

export default PageLayout;
