import React from "react";
import { IconBase, IconBaseProps } from "react-icons";

const SvgComponent: React.FC<IconBaseProps> = (props: IconBaseProps) => {
  return (
    <IconBase {...props}>
      <svg viewBox="0 0 16 16">
        <path
          d="M12.3333 1.66699L1.66666 5.63555L7.28069 6.72187L8.6856 12.3337L12.3333 1.66699Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
        <path
          d="M7.28296 6.72144L8.87089 5.13379"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </IconBase>
  );
};

const IconSendSidebar = React.memo(SvgComponent);
export default IconSendSidebar;
