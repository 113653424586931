import BigNumber from "bignumber.js";
import Long from "long";

export const getGasPriceForClaim = (): string => {
  return "2000";
};

export const getGasLimitForClaim = (): Long => {
  return Long.fromNumber(30000);
};

export const getGasPriceForClaimZilSwap = (): string => {
  return "2000";
};

export const getGasLimitForClaimZilSwap = (): Long => {
  return Long.fromNumber(15000);
};

export const getGasPriceForSend = (isZil: boolean): string => {
  if (isZil) {
    return "2000";
  } else {
    return "2000";
  }

  // let gasPrice = "";
  // const utils = window.zilPay.utils;
  // if (isZil) {
  //   gasPrice = utils.units.toQa("10000", utils.units.Units.Li); // 0.1 ZIL
  // } else {
  //   gasPrice = utils.units.toQa("10000", utils.units.Units.Li); // 3 ZIL
  // }
  // return gasPrice;
};

export const getGasLimitForSend = (isZil: boolean): Long => {
  if (isZil) {
    return Long.fromNumber(50);
  }

  return Long.fromNumber(1500);
};

export const getGasLimit = (): any => {
  return Long.fromNumber(2000);
};

export const getGasPrice = async (): Promise<string> => {
  if (!window.zilPay) {
    return "0";
  }

  const minGasPrice = (await window.zilPay.blockchain.getMinimumGasPrice())
    .result as string;

  const gasPrice = new BigNumber(minGasPrice).div(new BigNumber(10).pow(1));

  return gasPrice.toString();
};

export const isConnectZilpay = (zilAddress: string) => {
  try {
    return (
      window.zilPay &&
      window.zilPay.wallet &&
      window.zilPay.wallet.isEnable &&
      window.zilPay.wallet.isConnect &&
      window.zilPay.wallet.defaultAccount &&
      window.zilPay.wallet.defaultAccount.bech32 &&
      window.zilPay.wallet.defaultAccount.bech32 === zilAddress
    );
  } catch (error) {
    return false;
  }
};

export const isConnectedZilpay = () => {
  try {
    return (
      window.zilPay &&
      window.zilPay.wallet &&
      window.zilPay.wallet.isEnable &&
      window.zilPay.wallet.isConnect
    );
  } catch (error) {
    return false;
  }
};
