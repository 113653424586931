import React from "react";
import Icon from "@chakra-ui/icon";
import { SvgIconProps } from "src/constants/type";

interface IconCheckedProps extends SvgIconProps {
  isIndeterminate?: boolean;
  isChecked?: boolean;
}

const SvgComponent: React.FC<IconCheckedProps> = ({ size, ...props }) => {
  const { isIndeterminate, isChecked, ...rest } = props;

  const d = isIndeterminate
    ? ""
    : "M6.66649 10.115L12.7945 3.98633L13.7378 4.92899L6.66649 12.0003L2.42383 7.75766L3.36649 6.81499L6.66649 10.115Z";

  return (
    <Icon {...rest} w={size} h={size} viewBox="0 0 16 16">
      <path fill="currentColor" d={d} />
    </Icon>
  );
};

const IconChecked = React.memo(SvgComponent);
export default IconChecked;
