import React, { useEffect } from "react";
import { validation } from "@zilliqa-js/util";
import { Box, Flex, Text } from "@chakra-ui/layout";
import { useAppColor } from "src/hooks";
import { Input, InputGroup, InputRightElement } from "@chakra-ui/input";
import { Button } from "@chakra-ui/button";

import { useToast } from "@chakra-ui/toast";
import Long from "long";
import { BN } from "@zilliqa-js/util";
import { IconArrowRight, IconRecipientAddress } from "src/assets/icons";
import { useTranslation } from "react-i18next";
import { fromBech32Address } from "@zilliqa-js/crypto";
import { IToken } from "src/constants/interfaces";
import { IWalletToken, loginSelector } from "src/redux/slices/overviewSlice";
import { useAppSelector } from "src/redux/hooks";
import {
  getGasLimitForSend,
  getGasPriceForSend,
  isConnectZilpay,
} from "src/api/zilpayApi";
import TokenInput from "src/molecules/TokenInput";
import {
  Modal,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import ModalSendAddress from "src/molecules/ModalSendAddress";
import BoxTransaction from "src/molecules/BoxTransaction";
import { ButtonUnlock } from "src/atoms";

interface IContractParams {
  vname: string;
  type: string;
  value: string;
}

interface ITxParam {
  version?: number;
  amount: BN;
  gasPrice: string;
  gasLimit: Long;
}

const currentData = "USD"; // ?!

interface SendProps {
  openAlertModal: (type: number, title: string, content: string) => void;
}
const Send: React.FC<SendProps> = ({ openAlertModal }) => {
  // I18n
  const { t } = useTranslation(["send", "common"]);

  // Constants
  const { text2, text3, text5, main1, main2, main3 } = useAppColor();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  // Redux
  const {
    currentWallet: _currentWallet,
    isHidden,
    savedWallets,
  } = useAppSelector(loginSelector);

  const currentWallet = savedWallets.find(
    (savedWallet) => savedWallet.zilAddress === _currentWallet?.zilAddress
  );

  const walletTokens = React.useMemo(
    () => currentWallet?.walletTokens || [],
    [currentWallet]
  );

  // State
  const [targetAddress, setTargetAddress] = React.useState<string>("");
  const [amount, setAmount] = React.useState<number>(0);
  const [currentToken, setCurrentToken] = React.useState<IWalletToken>();
  const [holdingWalletTokens, setHoldingWalletTokens] = React.useState<
    IWalletToken[]
  >([]);

  // Hide Fee
  // const networkFeeList = [
  //   { name: t("fast"), value: "25ZIL", time: "1 sec" },
  //   { name: t("slow"), value: "15ZIL", time: "20 sec" },
  //   { name: t("avg"), value: "20ZIL", time: "15 sec" },
  // ];
  // const [type, setType] = React.useState(networkFeeList[0]);
  // const [show, setShow] = useBoolean(true);

  useEffect(() => {
    const holdingWalletTokens = walletTokens.filter(
      (walletToken) =>
        walletToken && walletToken.balance && walletToken.balance > 0
    );
    setHoldingWalletTokens(holdingWalletTokens);
    if (!currentToken) {
      // Set zil is default
      const zilToken = walletTokens.find(
        (walletToken) => walletToken.token.symbol === "ZIL"
      );
      setCurrentToken(zilToken);
    }
  }, [walletTokens, currentToken]);

  /**
   * Call contract via zilPay
   * @param token
   * @param contractMethod
   * @param contractParams
   * @param txParams
   * @returns
   */
  const callZilpayContract = async (
    token: IToken,
    contractMethod: string,
    contractParams: IContractParams[],
    txParams: ITxParam
  ): Promise<string> => {
    try {
      const tokenAddress = fromBech32Address(token.address_bech32);

      const contract = window.zilPay.contracts.at(tokenAddress);

      const tx = await contract.call(contractMethod, contractParams, txParams);

      return `0x${tx.ID}`;
    } catch (error) {
      console.log("ERROR: zilpay call contract", error);
      return "";
    }
  };

  /**
   * Send token
   * @param token
   * @param amount
   * @param targetAddress
   * @returns
   */
  const sendToken = async (
    token: IToken,
    amount: number,
    targetAddress: string
  ): Promise<string> => {
    if (!window.zilPay) {
      return "";
    }

    let contractParams: IContractParams[] = [];

    const gasPrice = getGasPriceForSend(false);
    const gasLimit = getGasLimitForSend(false);

    let txParams: ITxParam = {
      amount: new BN(0),
      gasPrice: gasPrice,
      gasLimit: gasLimit,
    };

    contractParams.push({
      vname: "to",
      type: "ByStr20",
      value: fromBech32Address(targetAddress),
    });

    contractParams.push({
      vname: "amount",
      type: "Uint128",
      value: (amount * Math.pow(10, token.decimals)).toString(),
    });

    return await callZilpayContract(
      token,
      "Transfer",
      contractParams,
      txParams
    );
  };

  const sendZil = async (
    amount: number,
    targetAddress: string
  ): Promise<string> => {
    if (!window.zilPay) {
      return "";
    }
    const zilliqa = window.zilPay;
    const utils = window.zilPay.utils;
    const sendAmount = utils.units.toQa(amount, utils.units.Units.Zil);
    const gasPrice = getGasPriceForSend(true);
    const txParams = zilliqa.transactions.new({
      toAddr: targetAddress,
      amount: sendAmount,
      gasPrice: gasPrice,
    });
    try {
      const txResult = await zilliqa.blockchain.createTransaction(txParams);

      return `0x${txResult.ID}`;
    } catch (err) {
      return "";
    }
  };

  /**
   * Send zil or token
   * @returns void
   */
  const handleSend = async () => {
    if (!validation.isBech32(targetAddress)) {
      openAlertModal(1, t("invalidAddress"), t("invalidAddressDesc"));
      return;
    }

    if (!currentWallet || !isConnectZilpay(currentWallet.zilAddress)) {
      openAlertModal(1, t("noWallet"), t("noWalletDesc"));
      return;
    }

    if (!currentToken) {
      openAlertModal(1, t("invalidToken"), t("invalidTokenDesc"));
      return;
    }

    if (currentToken && amount > currentToken.balance) {
      openAlertModal(1, t("notEnough"), t("notEnoughDesc"));
      return;
    }

    let tranID = "";
    if (currentToken.token.symbol === "ZIL") {
      tranID = await sendZil(amount, targetAddress);
    } else {
      tranID = await sendToken(currentToken.token, amount, targetAddress);
    }

    if (tranID) {
      toast({
        render: () => <BoxTransaction TranID={tranID} />,
      });
    }
  };

  return (
    <Box
      flexDirection="column"
      bg={main1}
      borderRadius="16px"
      shadow="sm"
      py="24px"
      w="full"
      mr={{ base: "unset", lg: "86px" }}
      maxW={{ base: "unset", lg: "65%" }}
    >
      <Text
        fontSize="lg"
        lineHeight="7"
        fontWeight="600"
        ml="24px"
        mt="16px"
        mb="10px"
        color={text3}
      >
        {t("recipient")}
      </Text>
      <InputGroup px="24px">
        <Input
          placeholder={t("address")}
          size="lg"
          bgColor={main2}
          value={targetAddress}
          onChange={(event) => setTargetAddress(event.target.value)}
        />
        <InputRightElement
          w="max-content"
          px="10"
          cursor="pointer"
          onClick={onOpen}
        >
          <Flex alignItems="center" mt="2" pt="10px" color="red">
            <IconRecipientAddress size="6" color={text3} />
          </Flex>
          <Flex alignItems="center">
            <IconArrowRight size="5" mt="18px" />
          </Flex>
        </InputRightElement>
      </InputGroup>
      <Text
        fontSize="lg"
        lineHeight="7"
        fontWeight="600"
        ml="24px"
        mt="16px"
        color={text3}
      >
        {t("token")}
      </Text>
      <TokenInput
        amount={amount}
        px="6"
        tokenList={holdingWalletTokens}
        setAmount={setAmount}
        currentToken={currentToken}
        setCurrentToken={setCurrentToken}
      />
      <Box bg={main1} borderRadius="lg" shadow="sm" m="6" borderColor={main3}>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          flexDirection={{ base: "column", lg: "row" }}
        >
          {false && (
            <Box w="full" mr={{ base: "none", lg: "10px" }}>
              <Text
                fontSize="lg"
                lineHeight="7"
                fontWeight="700"
                color={text3}
                mb="10px"
              >
                ETH
              </Text>
              <Input placeholder="0" size="lg" bgColor={main2} />
              <Text
                display="flex"
                alignItems="center"
                justifyContent="end"
                mt="10px"
                fontSize="xs"
                lineHeight="4"
                fontWeight="bold"
                color={text5}
                letterSpacing="wider"
              >
                {t("common:balance")}:
                <Text
                  as="span"
                  fontSize="xs"
                  lineHeight="4"
                  fontWeight="bold"
                  color={text2}
                  letterSpacing="wider"
                  ml="8px"
                >
                  40,000
                </Text>
              </Text>
            </Box>
          )}
          {false && (
            <Box w="full" ml={{ base: "none", lg: "10px" }}>
              <Text
                fontSize="lg"
                lineHeight="7"
                fontWeight="700"
                color={text3}
                mb="10px"
              >
                {currentData}
              </Text>
              <Input placeholder="0" size="lg" bgColor={main2} />
              <Text
                display="flex"
                alignItems="center"
                justifyContent="end"
                mt="10px"
                fontSize="xs"
                lineHeight="4"
                fontWeight="bold"
                color={text5}
                letterSpacing="wider"
              >
                {t("common:balance")}:
                <Text
                  as="span"
                  fontSize="xs"
                  lineHeight="4"
                  fontWeight="bold"
                  color={text2}
                  letterSpacing="wider"
                  ml="8px"
                >
                  40,000
                </Text>
              </Text>
            </Box>
          )}
        </Flex>
      </Box>
      {/* <Divider mt="24px" orientation="horizontal" bg={main4} />
      <Box m="6">
        <Box justifyContent="space-between" display="flex">
          <Text fontSize="lg" lineHeight="7" fontWeight="700" color={text3}>
            {t("networkFee")}
          </Text>
          <Flex alignItems="center" justifyContent="end" cursor="pointer">
            <Box
              display="flex"
              alignItems="center"
              color={text2}
              onClick={setShow.toggle}
            >
              <Text
                mx="8px"
                fontSize="md"
                lineHeight="6"
                fontWeight="semibold"
                color="cyan.500"
              >
                {type.name}
              </Text>
              <IconArrowRight size="5" mt="15px" color="cyan.500" />
            </Box>
          </Flex>
        </Box>
        <Flex
          mt="6"
          flexDirection={{ base: "column", lg: "row" }}
          justifyContent="space-between"
          alignItems="center"
          hidden={show}
        >
          {networkFeeList.map((item, index) => {
            const { name, value, time } = item;
            return (
              <React.Fragment key={index}>
                <Box
                  borderRadius="lg"
                  shadow="sm"
                  py="10px"
                  px="15px"
                  color={type.name === item.name ? "green.300" : text3}
                  border="1px solid"
                  borderColor={type.name === item.name ? "green.300" : main3}
                  w={{ base: "full" }}
                  onClick={() => setType(item)}
                  mr={{ base: "none", lg: "10px" }}
                  mt={{ base: "10px", lg: "none" }}
                >
                  <Text fontWeight="600" lineHeight="24px" fontSize="16px">
                    {name}
                  </Text>
                  <Box display="flex" justifyContent="space-between" mt="15px">
                    <Text fontWeight="400">~{time}</Text>
                    <Text
                      fontWeight="600"
                      lineHeight="24px"
                      size="16px"
                      justifyContent="end"
                    >
                      {value}
                    </Text>
                  </Box>
                </Box>
              </React.Fragment>
            );
          })}
        </Flex>
      </Box> */}
      <Box display="flex" justifyContent="flex-end" mr="8" mt="20px">
        {isHidden ? (
          <ButtonUnlock />
        ) : (
          <Button onClick={handleSend}>{t("sendNow")}</Button>
        )}
      </Box>
      <Modal size={"xl"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalSendAddress onClose={onClose} setAddress={setTargetAddress} />
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Send;
