import React from "react";
import { Button, ButtonProps } from "@chakra-ui/button";
import { TokenLogo } from ".";
import { useAppDispatch } from "src/redux/hooks";
import { connectWalletViaZilpay } from "src/redux/slices/loginSlice";
import { Images } from "src/assets/images";
// import { useTranslation } from "react-i18next";
import {
  Modal,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import ModalConnectAvatar from "src/molecules/ModalConnectAvatar";

interface ConnectWalletProps extends ButtonProps {
  withoutZilpay?: boolean;
}

const ConnectWallet: React.FC<ConnectWalletProps> = ({
  withoutZilpay,
  ...rest
}) => {
  const dispatch = useAppDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleConnectZilpayWallet = async () => {
    try {
      dispatch(connectWalletViaZilpay());
    } catch {
      // TODO: Display error
    }
  };

  const connect_wallet_list = [
    {
      children: "Zilpay",
      logo: Images.zilpay,
      onClick: handleConnectZilpayWallet,
    },
    { children: "AVATAR", logo: Images.avatar, onClick: onOpen },
    // HIDE BOLT , LEDGER
    // { children: "BOLT", logo: Images.boltx },
    // { children: "Ledger", logo: Images.ledger},
  ];

  return (
    <>
      {connect_wallet_list.map((item, index) => (
        <Button
          display={withoutZilpay && index === 0 ? "none" : "block"}
          key={index}
          {...item}
          variant="outline"
          h="48px"
          w="120px"
          leftIcon={<TokenLogo size="2xs" src={item.logo} />}
          {...rest}
        />
      ))}
      <Modal size="lg" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalConnectAvatar onClose={onClose} />
        </ModalContent>
      </Modal>
    </>
  );
};

export default ConnectWallet;
