import React from "react";
import { IconBase, IconBaseProps } from "react-icons";

const SvgComponent: React.FC<IconBaseProps> = (props: IconBaseProps) => {
  return (
    <IconBase {...props}>
      <svg viewBox="0 0 14 14">
        <path d="M12.8337 11.666H1.16699V10.4993H1.75033V6.4341C1.75033 3.52443 4.10116 1.16602 7.00033 1.16602C9.89949 1.16602 12.2503 3.52443 12.2503 6.4341V10.4993H12.8337V11.666ZM5.54199 12.2493H8.45866C8.45866 12.6361 8.30501 13.0071 8.03152 13.2805C7.75803 13.554 7.3871 13.7077 7.00033 13.7077C6.61355 13.7077 6.24262 13.554 5.96913 13.2805C5.69564 13.0071 5.54199 12.6361 5.54199 12.2493Z" />
      </svg>
    </IconBase>
  );
};
const IconNotification = React.memo(SvgComponent);
export default IconNotification;
