import React from "react";
import {
  Text,
  Button,
  PinInput,
  PinInputField,
  HStack,
  useToast,
} from "@chakra-ui/react";
import {
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import { useAppColor } from "src/hooks";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { lock, loginSelector, unlock } from "src/redux/slices/loginSlice";
import { useTranslation } from "react-i18next";

interface ModalLimitedProps {
  onClose: () => void;
}

const ModalLimited: React.FC<ModalLimitedProps> = ({ onClose }) => {
  const { t } = useTranslation("pinCode");
  const { text6, text2 } = useAppColor();
  const [lockPin, setLockPin] = React.useState<string>("");
  const { isHidden } = useAppSelector(loginSelector);
  const dispatch = useAppDispatch();
  const [unlocking, setUnlocking] = React.useState(false);

  const toast = useToast();

  React.useEffect(() => {
    if (!isHidden && unlocking) {
      toast({
        id: "unlockSuccess",
        description: t("unlockSuccess"),
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
    if (isHidden && unlocking) {
      toast({
        id: "unlockFail",
        description: t("unlockFail"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    setUnlocking(false);
  }, [isHidden, unlocking, t, toast]);

  const INPUT_LENGTH = 6;

  const handleLock = () => {
    if (!lockPin || lockPin.length < 6) {
      return;
    }
    dispatch(lock(lockPin));
    onClose();
  };

  const handleUnlock = () => {
    if (!lockPin || lockPin.length < 6) {
      return;
    }
    dispatch(unlock(lockPin));
    onClose();
    setUnlocking(true);
  };

  return (
    <>
      <ModalCloseButton />
      <ModalHeader />
      <ModalBody
        display="flex"
        alignItems="center"
        align="center"
        flexDirection="column"
      >
        <Text fontSize="2xl" fontWeight="extrabold" color={text2} mb="4">
          {t(isHidden ? "enterPin" : "setPin")}
        </Text>
        <HStack>
          <PinInput
            value={lockPin}
            size="lg"
            mask
            onChange={(value) => setLockPin(value)}
          >
            {new Array(INPUT_LENGTH).fill(0).map((i, index) => (
              <PinInputField key={index} borderColor={text6} color={text2} />
            ))}
          </PinInput>
        </HStack>
      </ModalBody>
      <ModalFooter>
        <Button
          children={isHidden ? "Unlock" : "Lock"}
          onClick={isHidden ? handleUnlock : handleLock}
        />
      </ModalFooter>
    </>
  );
};

export default ModalLimited;
