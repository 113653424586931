import React from "react";
import { useAppSelector } from "src/redux/hooks";
import { sortSavedWallet } from "src/utils";
import { loginSelector } from "src/redux/slices/loginSlice";
import { IStakingInfo } from "src/constants/interfaces";

import { IModalClaimStakingItem } from "../Staking/ModalClaimStaking";
import UnclaimRewardItem from "./UnclaimRewardItem";

export type UnclaimedRewardProps = {
  claimStaking?: (stakeToken: IModalClaimStakingItem[]) => void;
  claimCarbon?: () => void;
  addStaking?: (stakeToken: any, token: any) => void;
  onClaimStaking?: (stakingList: IStakingInfo) => void;
};

export const UnclaimedReward: React.FC<UnclaimedRewardProps> = ({
  onClaimStaking = () => {},
  claimStaking,
}) => {
  const { savedWallets } = useAppSelector(loginSelector);

  return (
    <>
      {sortSavedWallet(savedWallets).map((savedWallet, index) => {
        return (
          <UnclaimRewardItem
            key={index}
            savedWallet={savedWallet}
            claimStaking={claimStaking}
            onClaimStaking={onClaimStaking}
          />
        );
      })}
    </>
  );
};

export default UnclaimedReward;
