import React from "react";
import {
  Box,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Flex,
  Text,
  Button,
  AvatarGroup,
  useToast,
} from "@chakra-ui/react";
import { IconAdd } from "src/assets/icons";
import { Images } from "src/assets/images";
import { TokenLogo } from "src/atoms";
import { useAppColor, useRouter, useCopy } from "src/hooks";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import {
  IWallet,
  loginSelector,
  selectWallet,
} from "src/redux/slices/loginSlice";
import { get } from "local-storage";
import { overviewSelector } from "src/redux/slices/overviewSlice";
import { isConnectZilpay } from "src/api/zilpayApi";
import { formatUsdt } from "src/lib/formatCurrency";
import { CopyIcon } from "@chakra-ui/icons";
import { sortSavedWallet } from "src/utils";
interface WalletProps {
  id: string;
  onAddWallet?(): void;
  isShow: boolean;
}

const Wallet: React.FC<WalletProps> = ({ onAddWallet, isShow }) => {
  const { t } = useTranslation(["totalWallet", "common"]);
  const { text6, main1, main2, main3, main4, main8 } = useAppColor();
  const router = useRouter();

  const [copy] = useCopy();
  const toast = useToast();

  const { savedWallets, currentWallet, exchangeRate, isHidden } =
    useAppSelector(loginSelector);
  const { zilPriceInUsd } = useAppSelector(overviewSelector);
  const dispatch = useAppDispatch();

  const chooseWallet = (wallet: IWallet) => {
    dispatch(selectWallet(wallet));
    router.push("/");
  };

  const handleCopy = (event: any, text: string) => {
    event.stopPropagation();
    copy(text);
    toast({
      id: "copied",
      description: t("common:copied"),
      status: "success",
      duration: 3000,
      isClosable: true,
      position: "top",
    });
  };

  return (
    <Box>
      <Box m="auto" p="10 0">
        <Flex
          justifyContent="space-between"
          alignItems="center"
          my={{ base: "6", lg: "4" }}
        >
          <Text fontSize="xl" fontWeight="bold">
            {savedWallets.length} Wallet
          </Text>
          <Button
            display={{ base: "none", lg: "block" }}
            type="submit"
            minW="24"
            onClick={onAddWallet}
            bg={main8}
            color="white"
            _hover={{ bg: "gray.700" }}
          >
            {t("addWallet")}
          </Button>
          <Button
            display={{ base: "block", lg: "none" }}
            p="0"
            justifyContent="center"
            alignItems="center"
            type="submit"
            w="40px"
            h="40px"
            onClick={onAddWallet}
          >
            <IconAdd size="24px" />
          </Button>
        </Flex>
      </Box>
      <Box
        display={{ base: "none", lg: "block" }}
        overflow="auto"
        bg={main1}
        borderRadius="lg"
      >
        <Table variant="simple" minW="2xl" bg={main1} borderRadius="lg" mb="3">
          <Thead>
            <Tr>
              <Th>{t("common:wallet")}</Th>
              <Th>{t("common:holdings")}</Th>
              <Th>{t("common:network")}</Th>
              <Th>{t("common:connection")}</Th>
              <Th isNumeric>{t("common:total")}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {sortSavedWallet(savedWallets).map((savedWallet, index) => {
              const { name, isZilPay, zilAddress, holdings } = savedWallet;

              const connected = isConnectZilpay(zilAddress);

              return (
                <Tr
                  cursor="pointer"
                  _hover={{ bg: main3 }}
                  bg={currentWallet?.zilAddress === zilAddress ? main2 : main1}
                  key={index}
                  onClick={() => chooseWallet(savedWallet)}
                >
                  <Td>
                    <Flex alignItems="center">
                      <TokenLogo
                        size="sm"
                        mr="2"
                        src={isZilPay ? Images.zilpay : Images.zil}
                      />
                      <Box justifyContent="flex-end">
                        <Text fontSize="sm" fontWeight="semibold">
                          {name}
                        </Text>
                        <Flex alignItems="center">
                          <Text fontSize="xs" color={text6}>
                            {zilAddress}
                          </Text>
                          <CopyIcon
                            w="3"
                            ml="2"
                            color={text6}
                            onClick={(event) => handleCopy(event, zilAddress)}
                          />
                        </Flex>
                      </Box>
                    </Flex>
                  </Td>
                  <Td>
                    <AvatarGroup
                      size="xs"
                      spacing={2}
                      pl="3"
                      py="2"
                      max={3}
                      fontSize="xs"
                    >
                      {holdings.length !== 0 ? (
                        holdings.map((holding, index) => (
                          <TokenLogo key={index} src={holding} />
                        ))
                      ) : (
                        <TokenLogo visibility="hidden" />
                      )}
                    </AvatarGroup>
                  </Td>
                  <Td isNumeric>
                    {isZilPay && (
                      <Flex>
                        <Box
                          display="flex"
                          alignItems="center"
                          border="1px"
                          py="4px"
                          pl="12px"
                          pr="8px"
                          borderRadius="6px"
                          borderColor={main4}
                        >
                          <TokenLogo src={Images.zil} w="12px" h="12px" />
                          <Text
                            fontSize="xs"
                            lineHeight="4"
                            fontWeight="semibold"
                            paddingLeft="5px"
                          >
                            Zilliqa
                          </Text>
                        </Box>
                      </Flex>
                    )}
                  </Td>
                  <Td isNumeric>
                    <Flex>
                      <Box
                        display="flex"
                        alignItems="center"
                        border="1px"
                        py="4px"
                        pl="12px"
                        pr="8px"
                        borderRadius="6px"
                        borderColor={main4}
                      >
                        <Box
                          w="4px"
                          h="4px"
                          rounded="4px"
                          bg="green.600"
                          mr="12px"
                          display={connected ? "block" : "none"}
                        />
                        <Text
                          fontSize="xs"
                          lineHeight="4"
                          fontWeight="semibold"
                        >
                          {connected
                            ? t("common:connected")
                            : t("common:viewOnly")}
                        </Text>
                      </Box>
                    </Flex>
                  </Td>
                  <Td isNumeric>
                    <Text fontWeight="600">
                      {formatUsdt(
                        get<number>(`${zilAddress}`) * zilPriceInUsd,
                        exchangeRate,
                        !isShow || isHidden
                      )}
                    </Text>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};

export default Wallet;
