import React from "react";
import {
  Flex,
  Image,
  Text,
  Button,
  HStack,
  InputGroup,
  InputRightElement,
  IconButton,
  Box,
  VStack,
  chakra,
  AvatarBadge,
  useBoolean,
} from "@chakra-ui/react";
import {
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  Input,
  ModalFooter,
} from "@chakra-ui/react";
import { Images } from "src/assets/images";
import { useAppColor, useRouter } from "src/hooks";
import { IconArrowDown, IconEdit, IconTrash } from "src/assets/icons";
import { HiEyeOff, HiEye } from "react-icons/hi";
import { ConnectWallet, TokenLogo } from "src/atoms";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import {
  changeWalletName,
  IWallet,
  loginSelector,
  logoutWallet,
  removeWallet,
  selectWallet,
  viewWallet,
} from "src/redux/slices/loginSlice";
import { sortSavedWallet } from "src/utils";
import useModal from "src/hooks/useModal";

interface ModalAddWalletsProps {}

const ModalAddWallets: React.FC<ModalAddWalletsProps> = () => {
  const { currentWallet, savedWallets, isLoadingWallets, isMember } =
    useAppSelector(loginSelector);
  const router = useRouter();
  const { onOpenModalPremium } = useModal();

  const { text2, text3, text6, main6 } = useAppColor();
  const [isEditing, setIsEditing] = useBoolean(false);
  const [showTutorial, setShowTutorial] = useBoolean(false);
  const [showText, setShowText] = useBoolean(false);

  const onEditing = (wallet: IWallet, value: string) => {
    dispatch(changeWalletName(value));
    dispatch(selectWallet(wallet));
  };

  const dispatch = useAppDispatch();

  const chooseWallet = React.useCallback(
    (wallet: IWallet) => {
      dispatch(selectWallet(wallet));
      if (
        currentWallet?.zilAddress !== wallet.zilAddress &&
        router.location.pathname !== "/overview" &&
        !isLoadingWallets
      ) {
        router.push("/");
      }
    },
    [router, currentWallet, isLoadingWallets, dispatch]
  );

  const inputRefs = React.useRef<any>([]);

  const onEdit = (wallet: IWallet, index: number) => {
    if (wallet.zilAddress === currentWallet?.zilAddress) {
      setIsEditing.toggle();
    } else setIsEditing.on();
    inputRefs.current[index].focus({ preventScroll: true });
    chooseWallet(wallet);
  };

  let haveWallet = savedWallets && savedWallets.length > 0;

  const changeWallet = (wallet: IWallet) => {
    if (wallet.zilAddress === currentWallet?.zilAddress) {
      return;
    }
    chooseWallet(wallet);
    setIsEditing.off();
  };

  const handleAddWallet = React.useCallback(
    (formEvent: React.FormEvent<HTMLFormElement>) => {
      formEvent.preventDefault();
      //@ts-ignore
      let zilAddress = formEvent.target.zil.value;
      if (zilAddress.length <= 0 || isLoadingWallets) {
        return;
      }

      try {
        if (!isMember) {
          if (savedWallets.length > 1) {
            onOpenModalPremium();
          } else {
            dispatch(viewWallet(zilAddress));
          }
        } else {
          dispatch(viewWallet(zilAddress));
        }

        //@ts-ignore
        formEvent.target.zil.value = "";
        setIsEditing.off();
      } catch (error) {
        console.log("Error handleAddWallet", error);
      }
    },
    [
      dispatch,
      isLoadingWallets,
      isMember,
      setIsEditing,
      onOpenModalPremium,
      savedWallets,
    ]
  );

  const onClickTrash = React.useCallback(
    (mouseEvent: React.MouseEvent<SVGElement, MouseEvent>, item: IWallet) => {
      if (isLoadingWallets) {
        return;
      }
      mouseEvent.stopPropagation();
      dispatch(removeWallet(item));
      if (savedWallets.length === 1) {
        dispatch(logoutWallet());
      }
    },
    [dispatch, isLoadingWallets, savedWallets]
  );

  return (
    <>
      <ModalCloseButton />
      <ModalHeader />
      <ModalBody>
        <VStack spacing="6" py="4">
          {sortSavedWallet(savedWallets).map((wallet, index) => {
            let currentInput =
              isEditing && currentWallet?.zilAddress === wallet.zilAddress;
            return (
              <Flex
                key={index}
                onClick={() => changeWallet(wallet)}
                w="full"
                px={{ base: "4", lg: "6" }}
                py="4"
                border="1px"
                borderRadius="lg"
                borderColor={
                  currentWallet?.zilAddress === wallet.zilAddress
                    ? text2
                    : main6
                }
                justifyContent="space-between"
              >
                <Flex alignItems="center">
                  <TokenLogo
                    src={Images.zilpay}
                    size="sm"
                    bg="transparent"
                    mr={{ base: "4", lg: "6" }}
                  >
                    {wallet.isZilPay && (
                      <AvatarBadge
                        boxSize="4"
                        bg="green.500"
                        borderColor={"transparent"}
                      />
                    )}
                  </TokenLogo>
                  <Box>
                    <Flex alignItems="center">
                      <chakra.form
                        onSubmit={(event) => {
                          event.preventDefault();
                          setIsEditing.off();
                        }}
                      >
                        <Input
                          cursor={currentInput ? "auto" : "default"}
                          ref={(element) =>
                            (inputRefs.current[index] = element)
                          }
                          onClick={(e) => {
                            e.stopPropagation();
                            if (
                              wallet.zilAddress !== currentWallet?.zilAddress
                            ) {
                              setIsEditing.off();
                            }
                            chooseWallet(wallet);
                          }}
                          style={{
                            caretColor: currentInput
                              ? undefined
                              : "transparent",
                          }}
                          size="xs"
                          value={wallet.name}
                          onChange={
                            currentInput
                              ? (event) => onEditing(wallet, event.target.value)
                              : // {
                                //     let temp = [...allWallets];
                                //     temp[index].name = event.currentTarget.value;
                                //     setAllWallets(temp);
                                //   }
                                () => {}
                          }
                          fontSize="sm"
                          color={text3}
                          fontWeight="semibold"
                          variant={currentInput ? "flushed" : "unstyled"}
                        />
                      </chakra.form>
                      <IconEdit
                        ml="4"
                        fill={text3}
                        size="4"
                        cursor="pointer"
                        onClick={(event) => {
                          event.stopPropagation();
                          onEdit(wallet, index);
                        }}
                      />
                    </Flex>
                    <Text
                      fontSize="sm"
                      color={text6}
                      mt="2"
                      isTruncated
                      maxW={{ base: "170px", sm: "unset" }}
                    >
                      {wallet.zilAddress}
                    </Text>
                  </Box>
                </Flex>
                <IconTrash
                  fill={main6}
                  size="6"
                  cursor={isLoadingWallets ? "wait" : "pointer"}
                  onClick={
                    (mouseEvent) => onClickTrash(mouseEvent, wallet)
                    // {
                    // event.stopPropagation();
                    // let temp = [...savedWallets];
                    // temp.splice(index, 1);
                    // setAllWallets(temp);
                    // if (wallet.zilAddress === currentWallet?.zilAddress) {
                    //   chooseWallet(temp[0]);
                    // }
                    // }
                  }
                />
              </Flex>
            );
          })}
        </VStack>
        <Text
          display={haveWallet ? "none" : "block"}
          fontSize="lg"
          color={text3}
          fontWeight="bold"
          children="Track wallet"
        />
        <chakra.form onSubmit={(formEvent) => handleAddWallet(formEvent)}>
          <Flex my="8">
            <InputGroup size="md">
              <Input
                name="zil"
                placeholder={"Paste ZIL address"}
                pr="10"
                type={showText ? "password" : "text"}
              />
              <InputRightElement>
                <IconButton
                  color={main6}
                  bg="transparent !important"
                  variant="ghost"
                  aria-label={showText ? "Reveal password" : "Mask password"}
                  icon={showText ? <HiEye /> : <HiEyeOff />}
                  onClick={() => setShowText.toggle()}
                />
              </InputRightElement>
            </InputGroup>
            <Button
              cursor={isLoadingWallets ? "wait" : "pointer"}
              type="submit"
              children="Track"
              ml="8"
            />
          </Flex>
        </chakra.form>
        {/* {!haveWallet && ( */}
        <>
          <Text
            fontSize="lg"
            color={text3}
            fontWeight="bold"
            children="Connect wallet"
          />
          <HStack my="4" w="full" spacing={"4"}>
            <ConnectWallet withoutZilpay />
            {/* <Button children="+ Create Wallet" /> */}
          </HStack>
        </>
        {/* )} */}
      </ModalBody>
      <ModalFooter flexDirection="column" justifyContent="center">
        <Button
          rightIcon={<IconArrowDown />}
          variant="ghost"
          fontSize="sm"
          color={text3}
          fontWeight="semibold"
          onClick={() => setShowTutorial.toggle()}
          mb="4"
        >
          Add another account from the same wallet
        </Button>
        {showTutorial && <Image w="full" h="full" src={Images.tutorialGif} />}
      </ModalFooter>
    </>
  );
};

export default ModalAddWallets;
