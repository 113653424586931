import React from "react";
import {
  Box,
  BoxProps,
  Button,
  Center,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
} from "@chakra-ui/react";
import { VictoryPie } from "victory";
import { useAppColor, useRouter } from "src/hooks";

import { Images } from "src/assets/images";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { overviewSelector } from "src/redux/slices/overviewSlice";
import { formatTokenWithSymbol, formatUsdt } from "src/lib/formatCurrency";
import {
  loginSelector,
  setSavedWalletHoldings,
} from "src/redux/slices/loginSlice";
import { useTranslation } from "react-i18next";
import { isEmptyDeposit, isEmptyLiquidity } from "src/utils";
import { isEmpty } from "lodash";
import DonutTooltip from "./DonutTooltip";
import EstimateRow from "./EstimateRow";

export interface DonutChartProps extends BoxProps {
  totalWallet: number;
  totalStaking: number;
  totalLP: number;
  totalZil: number;
  totalUnstaking: number;
  totalUnclaimed: number;
}

export interface IDonutChartData {
  name: string;
  value: number;
  color: string;
}

const chartRadius = 160 / 2;
const strokeWidth = 16;

const DonutChart: React.FC<DonutChartProps> = ({
  totalZil,
  totalWallet,
  totalStaking,
  totalLP,
  totalUnstaking,
  totalUnclaimed,
  ...props
}) => {
  const { t } = useTranslation(["overview", "common"]);
  const { text2, text4, text6, main1, main3 } = useAppColor();
  const {
    zilPriceInUsd,
    walletTokens,
    pools,
    allStakingList,
    distributions,
    xcadPools,
    xcadClaimableData,
    xcadEstimatedReward,
    zwapEstimatedReward,
    carbSwapEstimatedReward,
    carbSwapPools,
    carbSwapClaimable,
  } = useAppSelector(overviewSelector);
  const { exchangeRate, isHidden, isMember } = useAppSelector(loginSelector);
  const dispatch = useAppDispatch();
  const router = useRouter();

  const [donutChartData, setDonutChartData] = React.useState<IDonutChartData[]>(
    [
      { name: t("common:wallet"), value: totalWallet, color: "#805AD5" },
      { name: t("stakingBalance"), value: totalStaking, color: "#DD6B20" },
      { name: t("unstaking"), value: totalUnstaking, color: "#3182CE" },
      { name: t("lpBalance"), value: totalLP, color: "#D69E2E" },
      { name: t("unclaimedReward"), value: totalUnclaimed, color: "#38A169" },
    ]
  );

  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [selected, setSelected] = React.useState<number>(0);
  const initRef = React.useRef<any>();

  React.useEffect(() => {
    setDonutChartData([
      { name: t("common:wallet"), value: totalWallet, color: "#805AD5" },
      { name: t("stakingBalance"), value: totalStaking, color: "#DD6B20" },
      { name: t("unstaking"), value: totalUnstaking, color: "#3182CE" },
      { name: t("lpBalance"), value: totalLP, color: "#D69E2E" },
      { name: t("unclaimedReward"), value: totalUnclaimed, color: "#38A169" },
    ]);
  }, [t, totalLP, totalStaking, totalWallet, totalUnstaking, totalUnclaimed]);

  const holdingWalletToken = React.useCallback(() => {
    let walletIconList = [] as string[];

    walletTokens
      .filter(
        (walletToken) =>
          walletToken && walletToken.balance && walletToken.balance > 0
      )
      .forEach((walletToken) => walletIconList.push(walletToken.token.icon));

    return walletIconList;
  }, [walletTokens]);

  const holdingStakingToken = () => {
    let stakingIconList = [] as string[];
    allStakingList.forEach((stakingList) => {
      if (!isEmptyDeposit(stakingList)) {
        stakingIconList.push(stakingList.stakeLogo);
      }
    });
    return stakingIconList;
  };

  const holdingLPToken = () => {
    let lpIconList = [] as string[];
    if (!isEmptyLiquidity(pools) || !isEmpty(distributions)) {
      lpIconList.push(Images.zilswap);
    }
    if (!isEmptyLiquidity(xcadPools) || !isEmpty(xcadClaimableData)) {
      lpIconList.push(
        "https://meta.viewblock.io/ZIL.zil1xfcg9hfpdlmz2aytz0s4dww35hfa6s0jnjut5f/logo"
      );
    }
    if (!isEmptyLiquidity(carbSwapPools) || !isEmpty(carbSwapClaimable)) {
      lpIconList.push(Images.carbon);
    }
    return lpIconList;
  };

  const unstakingToken = () => {
    let unstakingIconList = [] as string[];
    if (totalUnstaking !== 0) {
      unstakingIconList.push(Images.zil);
    }
    return unstakingIconList;
  };

  React.useEffect(() => {
    dispatch(setSavedWalletHoldings(holdingWalletToken()));
  }, [dispatch, holdingWalletToken]);

  const colorScale = React.useMemo(
    (): string[] => donutChartData.map((i) => i.color),
    [donutChartData]
  );

  const chartData = React.useMemo(
    () =>
      donutChartData.map((i) => ({
        y:
          (totalZil <= 0 ? 100 / donutChartData.length : i.value / totalZil) *
          100,
      })),
    [totalZil, donutChartData]
  );

  return (
    <Box
      {...props}
      borderRadius="lg"
      border="2px"
      borderColor={main3}
      bg={main1}
      p="4"
      pt="8"
    >
      <Center h={250} overflow="visible" mb="10" position="relative">
        <Popover isOpen={isOpen} closeOnBlur={false} initialFocusRef={initRef}>
          <PopoverTrigger>
            <Box
              display="flex"
              flexDirection="column"
              position="absolute"
              alignItems="center"
              justifyContent="center"
              minW={chartRadius / 2}
              minH={chartRadius / 2}
              ref={initRef}
            >
              <Text fontSize="lg" fontWeight="bold" color={text2}>
                {formatTokenWithSymbol(
                  isOpen ? donutChartData[selected].value : totalZil,
                  "ZIL",
                  isHidden
                )}
              </Text>
              <Text fontSize="sm" color={text4}>
                ~{" "}
                {formatUsdt(
                  (isOpen ? donutChartData[selected].value : totalZil) *
                    zilPriceInUsd,
                  exchangeRate,
                  isHidden
                )}
              </Text>
            </Box>
          </PopoverTrigger>
          <PopoverContent
            w="3xs"
            onMouseEnter={() => setIsOpen(true)}
            onMouseLeave={() => setIsOpen(false)}
          >
            <PopoverArrow />
            <DonutTooltip
              holdingIcon={
                selected === 0
                  ? holdingWalletToken()
                  : selected === 1
                  ? holdingStakingToken()
                  : selected === 2
                  ? unstakingToken()
                  : holdingLPToken()
              }
              percentage={
                totalZil !== 0
                  ? ((donutChartData[selected].value * 100) / totalZil).toFixed(
                      2
                    )
                  : "0"
              }
              chartData={donutChartData[selected]}
            />
          </PopoverContent>
        </Popover>
        <VictoryPie
          events={[
            {
              target: "data",
              eventHandlers: {
                onMouseEnter: () => {
                  return [
                    {
                      mutation: ({ index }) => {
                        setIsOpen(true);
                        setSelected(index);
                        return null;
                      },
                    },
                  ];
                },
                onMouseLeave: () => {
                  return [
                    {
                      mutation: ({ index }) => {
                        setIsOpen(false);
                        return null;
                      },
                    },
                  ];
                },
              },
            },
          ]}
          width={chartRadius * 2}
          height={chartRadius * 2}
          padding={0}
          cornerRadius={24}
          innerRadius={chartRadius - strokeWidth}
          labelPosition="centroid"
          padAngle={1}
          animate={{ duration: 2500 }}
          colorScale={colorScale}
          data={chartData}
          labels={() => ""}
        />
      </Center>

      <Stack spacing={6} px="4">
        <Text fontSize="sm" fontWeight="bold">
          {t("estimateReward")}:
        </Text>
        {isMember ? (
          <React.Fragment>
            <EstimateRow
              icon={Images.zilswap}
              name={"Zilswap"}
              frequency={t("common:weekly")}
              rewardList={zwapEstimatedReward}
            />
            <EstimateRow
              rewardList={xcadEstimatedReward}
              frequency={t("common:daily")}
              name={"XCAD DEX"}
              icon={
                "https://meta.viewblock.io/ZIL.zil1xfcg9hfpdlmz2aytz0s4dww35hfa6s0jnjut5f/logo"
              }
            />
            <EstimateRow
              rewardList={carbSwapEstimatedReward}
              name={"Carb Swap"}
              icon={Images.carbon}
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Text fontSize="sm" color={text6}>
              {t("common:needPremium")}
            </Text>
            <Button
              children={t("common:getPremium")}
              onClick={() => router.push("/membership")}
            />
          </React.Fragment>
        )}
      </Stack>
    </Box>
  );
};

export default DonutChart;
