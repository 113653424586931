import React from "react";
import HeaderWallet from "../HeaderWallet";
import UnclaimRewardToken from "./UnclaimRewardToken";
import { IWallet } from "src/redux/slices/loginSlice";
import { fromBech32Address } from "@zilliqa-js/zilliqa";
import BigNumber from "bignumber.js";
import { fetchReward } from "src/api/stakingRewardApi";
import { useAppSelector } from "src/redux/hooks";
import { overviewSelector } from "src/redux/slices/overviewSlice";
import { IStakingInfo } from "src/constants/interfaces";
import { IModalClaimStakingItem } from "../Staking/ModalClaimStaking";
import BalanceMinify from "../BalanceMinify";

interface UnclaimRewardItemProps {
  savedWallet: IWallet;
  claimStaking?: (stakeToken: IModalClaimStakingItem[]) => void;
  onClaimStaking?: (stakingList: IStakingInfo) => void;
}

const UnclaimRewardItem: React.FC<UnclaimRewardItemProps> = ({
  savedWallet,
  onClaimStaking = () => {},
  claimStaking,
}) => {
  const {
    tokenRates,
    xcadClaimableData,
    tokens,
    distributions,
    carbSwapClaimable,
  } = useAppSelector(overviewSelector);
  const { allStakingList, zilAddress } = savedWallet;
  const [totalUnclaim, setTotalUnclaim] = React.useState<number>(0);

  React.useEffect(() => {
    if (!allStakingList) {
      return;
    }
    const syncStakingReward = async () => {
      let tmpTotalUnclaimed = 0;
      for (let stakingList of allStakingList) {
        if (stakingList.name === "Zillion") {
          const { stakeList } = stakingList;
          for (let i = 0; i < stakeList.length; i++) {
            const stakingWallet = stakeList[i];
            const { address, stakingBalance } = stakingWallet;
            if (stakingBalance && stakingBalance > 0) {
              const rewardResult = await fetchReward(address, zilAddress);
              const rewardNumber =
                parseFloat(rewardResult.toString()) * Math.pow(10, -12);
              tmpTotalUnclaimed += rewardNumber;
            }
          }
        } else {
          if (stakingList.pendingRewards) {
            for (let i = 0; i < stakingList.pendingRewards.length; i++) {
              const pendingReward = stakingList.pendingRewards[i];
              const rewardRate =
                tokenRates.find(
                  (rate) =>
                    rate.token.symbol === pendingReward.pendingRewardSymbol
                )?.rate || 1;
              let reward = pendingReward.pendingReward || 0;
              tmpTotalUnclaimed += reward * rewardRate;
            }
          }
        }
      }

      const dXCAD = tokens.find((token) => token.symbol === "dXCAD");
      if (dXCAD) {
        const dXCADDecimal = dXCAD.decimals;
        xcadClaimableData &&
          xcadClaimableData.forEach((claimable) => {
            tmpTotalUnclaimed += new BigNumber(claimable.amount)
              .div(Math.pow(10, dXCADDecimal))
              .toNumber();
          });
      }

      distributions.forEach((distribution) => {
        const rewardToken = tokens.find(
          (token) =>
            fromBech32Address(token.address_bech32).toLowerCase() ===
            distribution.info.distrAddr.toLowerCase()
        );
        if (rewardToken) {
          tmpTotalUnclaimed += new BigNumber(distribution.info.amount)
            .div(Math.pow(10, rewardToken.decimals))
            .toNumber();
        }
      });

      const graph = tokens.find((token) => token.symbol === "GRPH");
      if (graph) {
        const graphDecimal = graph.decimals;
        carbSwapClaimable &&
          carbSwapClaimable.forEach((claimable) => {
            tmpTotalUnclaimed += new BigNumber(claimable.amount)
              .div(Math.pow(10, graphDecimal))
              .toNumber();
          });
      }
      setTotalUnclaim(tmpTotalUnclaimed);
    };
    syncStakingReward();
  }, [
    allStakingList,
    carbSwapClaimable,
    distributions,
    tokenRates,
    tokens,
    xcadClaimableData,
    zilAddress,
  ]);

  return !allStakingList ? (
    <React.Fragment />
  ) : (
    <HeaderWallet
      children={
        <UnclaimRewardToken
          savedWallet={savedWallet}
          onClaimStaking={onClaimStaking}
          claimStaking={claimStaking}
        />
      }
      minifyChild={<BalanceMinify balance={totalUnclaim} />}
      savedWallet={savedWallet}
    />
  );
};

export default UnclaimRewardItem;
