
import { IToken } from "./interfaces";

const tokens: IToken[] = [
  {
    id: 7,
    created_at: "2021-03-06T07:57:36.701516Z",
    updated_at: "2021-10-02T00:56:16.322801Z",
    name: "RedChillies",
    symbol: "REDC",
    address_bech32: "zil14jmjrkvfcz2uvj3y69kl6gas34ecuf2j5ggmye",
    icon: "https://meta.viewblock.io/ZIL.zil14jmjrkvfcz2uvj3y69kl6gas34ecuf2j5ggmye/logo",
    decimals: 9,
    website: "https://zilchill.com/",
    telegram: "https://t.me/redchilliesREDC",
    whitepaper:
      "https://github.com/RedChillies-Core/RedChillies-Improvement-Proposals/blob/main/Whitepaper.md",
    init_supply: 2000000,
    max_supply: 2000000,
    total_supply: 2000000.0000000002,
    current_supply: 349619.38503443514,
    daily_volume: 35241.37110392995,
    current_liquidity: 3446466.4087148733,
    liquidity_providers: 96,
    supply_skip_addresses:
      "zil1vx266dnzmzv6c52np5jlwz43804q45eakecmcz,zil1w7lzhut82pf86692cp2v7j4hsdyhtexmdmw8k2,zil1djraajp9an7radt9hg3xccn6k2cjua2r9r9t2q,zil12c0huzt89fy97kuy6mzwrcezswqqy89l59k68n,zil1cuqevexszj0p0tkdks8e0zzuuxgy7hmhxxaeh7,zil1ckvdll6mzrqeslkm5j6vjmw757yf4jv936ktud,zil1z5xlcce7qpjslkt58rv0q6c0pgqlz9t2kcwnje,zil13u44fqhmu62rk88r5f8gu0mxv0gfy2t4r20ye5,zil1lw7527hy73p8sddzlnc5ykrygsg4n8d45pjgtd,zil12ae5ce3s2gpc5rmh4ww5762gry48qq9sc8h8w0,zil1e4kj2lel54fw7kx0nny9r9dfry3y5q2052sukx,zil1xm507m5njv2gzxpquqm7k6y2t7e54tes64trns",
    viewblock_score: 52,
    unvetted: false,
    bridged: false,
    last_vote_start: "0001-01-01T00:00:00Z",
    last_vote_end: "0001-01-01T00:00:00Z",
    last_vote_hash: "",
    rewards: [
      {
        id: 36,
        created_at: "2021-05-13T14:09:54.056616Z",
        updated_at: "2021-10-02T00:53:47.366306Z",
        type: "liquidity",
        amount: 56.52,
        max_individual_amount: 0,
        reward_token_id: 3,
        reward_token_address: "zil1p5suryq6q647usxczale29cu3336hhp376c627",
        reward_token_symbol: "ZWAP",
        frequency: 604800,
        frequency_type: "seconds",
        excluded_addresses: "",
        adjusted_total_contributed: "975464479717648900",
        adjusted_total_contributed_share: "1",
        contract_address: "",
        contract_state: "",
        start_date: null,
        end_date: null,
        payment_day: 3,
      },
      {
        id: 14,
        created_at: "2021-05-13T15:17:19.335959Z",
        updated_at: "2021-10-02T00:53:47.368539Z",
        type: "liquidity",
        amount: 1212,
        max_individual_amount: 0,
        reward_token_id: 7,
        reward_token_address: "zil14jmjrkvfcz2uvj3y69kl6gas34ecuf2j5ggmye",
        reward_token_symbol: "REDC",
        frequency: 604800,
        frequency_type: "seconds",
        excluded_addresses: "",
        adjusted_total_contributed: "975464479717648800",
        adjusted_total_contributed_share: "1",
        contract_address: "",
        contract_state: "",
        start_date: null,
        end_date: null,
        payment_day: 3,
      },
    ],
  },
  {
    id: 66,
    created_at: "2021-09-03T16:41:08.986505Z",
    updated_at: "2021-10-02T00:56:16.49404Z",
    name: "United DEX Index",
    symbol: "UNIDEX",
    address_bech32: "zil19cyp6kj7zrz3c2q50ts2nly6hrhrr7vr89g9gf",
    icon: "https://meta.viewblock.io/ZIL.zil19cyp6kj7zrz3c2q50ts2nly6hrhrr7vr89g9gf/logo",
    decimals: 2,
    website: "https://zilall.com/",
    telegram: "https://t.me/zilall_community",
    whitepaper: "",
    init_supply: 0,
    max_supply: 0,
    total_supply: 13368.050000000001,
    current_supply: 13368.050000000001,
    daily_volume: 47703.61054496202,
    current_liquidity: 1702844.758360297,
    liquidity_providers: 22,
    supply_skip_addresses: "",
    viewblock_score: 29,
    unvetted: false,
    bridged: false,
    last_vote_start: "0001-01-01T00:00:00Z",
    last_vote_end: "0001-01-01T00:00:00Z",
    last_vote_hash: "",
    rewards: [
      {
        id: 59,
        created_at: "2021-05-13T14:09:54.056616Z",
        updated_at: "2021-10-02T00:53:47.090632Z",
        type: "liquidity",
        amount: 56.52,
        max_individual_amount: 0,
        reward_token_id: 3,
        reward_token_address: "zil1p5suryq6q647usxczale29cu3336hhp376c627",
        reward_token_symbol: "ZWAP",
        frequency: 604800,
        frequency_type: "seconds",
        excluded_addresses: "",
        adjusted_total_contributed: "614491895166185600",
        adjusted_total_contributed_share: "1",
        contract_address: "",
        contract_state: "",
        start_date: null,
        end_date: null,
        payment_day: 3,
      },
    ],
  },
  {
    id: 63,
    created_at: "2021-08-24T05:54:53.702397Z",
    updated_at: "2021-10-02T00:56:16.578314Z",
    name: "Yoda",
    symbol: "Yoda",
    address_bech32: "zil1vdc79fg75sth96xnt6xmq5f55swcd5t6m5zhvn",
    icon: "https://meta.viewblock.io/ZIL.zil1vdc79fg75sth96xnt6xmq5f55swcd5t6m5zhvn/logo",
    decimals: 5,
    website: "https://yodamerch.co.za/",
    telegram: "https://t.me/Yodaco",
    whitepaper: "https://www.yodaco.co.za/",
    init_supply: 10000,
    max_supply: 10000,
    total_supply: 10000,
    current_supply: 2640.1499999999996,
    daily_volume: 129938.73437490658,
    current_liquidity: 3973588.6285880166,
    liquidity_providers: 30,
    supply_skip_addresses:
      "zil1nlgjdf60upg9pnstdmwdmjaugnv3tx9n07whly,zil1m6kpchh3cg60x9ftgxk38rfdy4yyp92ms9wx0n",
    viewblock_score: 43,
    unvetted: false,
    bridged: false,
    last_vote_start: "0001-01-01T00:00:00Z",
    last_vote_end: "0001-01-01T00:00:00Z",
    last_vote_hash: "",
    rewards: [
      {
        id: 50,
        created_at: "2021-08-27T11:39:53.32969Z",
        updated_at: "2021-10-02T00:53:47.151511Z",
        type: "liquidity",
        amount: 50,
        max_individual_amount: 0,
        reward_token_id: 63,
        reward_token_address: "zil1vdc79fg75sth96xnt6xmq5f55swcd5t6m5zhvn",
        reward_token_symbol: "YODA",
        frequency: 604800,
        frequency_type: "seconds",
        excluded_addresses: "",
        adjusted_total_contributed: "382421968282931840",
        adjusted_total_contributed_share: "1",
        contract_address: "",
        contract_state: "",
        start_date: null,
        end_date: null,
        payment_day: 0,
      },
      {
        id: 54,
        created_at: "2021-05-13T14:09:54.056616Z",
        updated_at: "2021-10-02T00:53:47.153565Z",
        type: "liquidity",
        amount: 113.03,
        max_individual_amount: 0,
        reward_token_id: 3,
        reward_token_address: "zil1p5suryq6q647usxczale29cu3336hhp376c627",
        reward_token_symbol: "ZWAP",
        frequency: 604800,
        frequency_type: "seconds",
        excluded_addresses: "",
        adjusted_total_contributed: "382421968282931900",
        adjusted_total_contributed_share: "1",
        contract_address: "",
        contract_state: "",
        start_date: null,
        end_date: null,
        payment_day: 3,
      },
    ],
  },
  {
    id: 68,
    created_at: "2021-09-16T03:45:55.499135Z",
    updated_at: "2021-10-02T00:56:16.31019Z",
    name: "Zilliqa NFT Index",
    symbol: "NFTDEX",
    address_bech32: "zil18l7eyut8t4gu68w7mg0ck664ee33mykrycps6l",
    icon: "https://meta.viewblock.io/ZIL.zil18l7eyut8t4gu68w7mg0ck664ee33mykrycps6l/logo",
    decimals: 0,
    website: "https://zilall.com/",
    telegram: "https://t.me/zilall_community",
    whitepaper: "",
    init_supply: 0,
    max_supply: 0,
    total_supply: 306,
    current_supply: 306,
    daily_volume: 49748.39791924551,
    current_liquidity: 940447.2714849489,
    liquidity_providers: 6,
    supply_skip_addresses: "",
    viewblock_score: 29,
    unvetted: false,
    bridged: false,
    last_vote_start: "0001-01-01T00:00:00Z",
    last_vote_end: "0001-01-01T00:00:00Z",
    last_vote_hash: "",
    rewards: [],
  },
  {
    id: 61,
    created_at: "2021-08-22T15:53:35.260962Z",
    updated_at: "2021-10-02T00:56:16.315958Z",
    name: "Consult Coin",
    symbol: "CONSULT",
    address_bech32: "zil108ex3na3dvwnjq5p4nls4z3gjy8fx66693hf4d",
    icon: "https://meta.viewblock.io/ZIL.zil108ex3na3dvwnjq5p4nls4z3gjy8fx66693hf4d/logo",
    decimals: 6,
    website: "https://www.consultcrypto.net/",
    telegram: "https://t.me/stokenlaunch",
    whitepaper:
      "https://www.consultcrypto.net/wp-content/uploads/2021/08/CONSULT-Token-Whitepaper-2108_1.pdf",
    init_supply: 21600000,
    max_supply: 0,
    total_supply: 1823723.163,
    current_supply: 1823723.163,
    daily_volume: 10810.386916420894,
    current_liquidity: 2800950.834603141,
    liquidity_providers: 23,
    supply_skip_addresses: "zil10cd3993r4dxsahssvc9zlhy0p3qlttw7dqr7qc",
    viewblock_score: 52,
    unvetted: false,
    bridged: false,
    last_vote_start: "0001-01-01T00:00:00Z",
    last_vote_end: "0001-01-01T00:00:00Z",
    last_vote_hash: "",
    rewards: [
      {
        id: 56,
        created_at: "2021-05-13T14:09:54.056616Z",
        updated_at: "2021-10-02T00:53:46.972511Z",
        type: "liquidity",
        amount: 56.52,
        max_individual_amount: 0,
        reward_token_id: 3,
        reward_token_address: "zil1p5suryq6q647usxczale29cu3336hhp376c627",
        reward_token_symbol: "ZWAP",
        frequency: 604800,
        frequency_type: "seconds",
        excluded_addresses: "",
        adjusted_total_contributed: "1172763289394174500",
        adjusted_total_contributed_share: "1",
        contract_address: "",
        contract_state: "",
        start_date: null,
        end_date: null,
        payment_day: 3,
      },
    ],
  },
  {
    id: 65,
    created_at: "2021-09-03T16:41:08.986505Z",
    updated_at: "2021-10-02T00:56:16.554254Z",
    name: "Zilliqa Index",
    symbol: "ZILLEX",
    address_bech32: "zil1rcp57nc2ue00f2hk7esxaa3j8q93xrexgrmpz7",
    icon: "https://meta.viewblock.io/ZIL.zil1rcp57nc2ue00f2hk7esxaa3j8q93xrexgrmpz7/logo",
    decimals: 12,
    website: "https://zilall.com/",
    telegram: "https://t.me/zilall_community",
    whitepaper: "",
    init_supply: 0,
    max_supply: 0,
    total_supply: 838.231931904317,
    current_supply: 838.231931904317,
    daily_volume: 85914.0371644647,
    current_liquidity: 3438589.587367675,
    liquidity_providers: 18,
    supply_skip_addresses: "",
    viewblock_score: 29,
    unvetted: false,
    bridged: false,
    last_vote_start: "0001-01-01T00:00:00Z",
    last_vote_end: "0001-01-01T00:00:00Z",
    last_vote_hash: "",
    rewards: [
      {
        id: 57,
        created_at: "2021-05-13T14:09:54.056616Z",
        updated_at: "2021-10-02T00:53:46.998072Z",
        type: "liquidity",
        amount: 56.52,
        max_individual_amount: 0,
        reward_token_id: 3,
        reward_token_address: "zil1p5suryq6q647usxczale29cu3336hhp376c627",
        reward_token_symbol: "ZWAP",
        frequency: 604800,
        frequency_type: "seconds",
        excluded_addresses: "",
        adjusted_total_contributed: "1559264834180545800",
        adjusted_total_contributed_share: "1",
        contract_address: "",
        contract_state: "",
        start_date: null,
        end_date: null,
        payment_day: 3,
      },
    ],
  },
  {
    id: 1,
    created_at: "2021-03-05T10:37:00.315284Z",
    updated_at: "2021-03-05T10:37:00.315284Z",
    name: "Zilliqa",
    symbol: "ZIL",
    address_bech32: "zil1qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq9yf6pz",
    icon: "https://meta.viewblock.io/ZIL/logo",
    decimals: 12,
    website: "https://zilliqa.com",
    telegram: "https://t.me/zilliqachat",
    whitepaper: "",
    init_supply: 0,
    max_supply: 0,
    total_supply: 0,
    current_supply: 0,
    daily_volume: 0,
    current_liquidity: 0,
    liquidity_providers: 0,
    supply_skip_addresses: "",
    viewblock_score: 100,
    unvetted: false,
    bridged: false,
    last_vote_start: "0001-01-01T00:00:00Z",
    last_vote_end: "0001-01-01T00:00:00Z",
    last_vote_hash: "",
    rewards: [],
  },
  {
    id: 73,
    created_at: "2021-09-30T18:19:56.769115Z",
    updated_at: "2021-10-02T00:56:16.293594Z",
    name: "The Button",
    symbol: "BUTTON",
    address_bech32: "zil1epq9dyctg0m5yaxeqv7ph0j532qze28psqfu8h",
    icon: "https://meta.viewblock.io/ZIL.zil1epq9dyctg0m5yaxeqv7ph0j532qze28psqfu8h/logo",
    decimals: 12,
    website: "https://button.claims",
    telegram: "https://t.me/bridge_button",
    whitepaper: "",
    init_supply: 100000,
    max_supply: 0,
    total_supply: 105353,
    current_supply: 75353,
    daily_volume: 996567.7860150881,
    current_liquidity: 342328.80224183167,
    liquidity_providers: 14,
    supply_skip_addresses: "zil13au6yhpsv7lg03vw0mkgfd2l7vef73jw8vvpxz",
    viewblock_score: 10,
    unvetted: false,
    bridged: false,
    last_vote_start: "0001-01-01T00:00:00Z",
    last_vote_end: "0001-01-01T00:00:00Z",
    last_vote_hash: "",
    rewards: [
      {
        id: 62,
        created_at: "2021-09-30T18:21:52.014321Z",
        updated_at: "2021-10-02T00:53:47.256554Z",
        type: "liquidity",
        amount: 2000,
        max_individual_amount: 0,
        reward_token_id: 73,
        reward_token_address: "zil1epq9dyctg0m5yaxeqv7ph0j532qze28psqfu8h",
        reward_token_symbol: "BUTTON",
        frequency: 604800,
        frequency_type: "seconds",
        excluded_addresses: "0xdec0f8ba43076df87a2cd51507dd8f9fc820cdcd",
        adjusted_total_contributed: "22129147176077204",
        adjusted_total_contributed_share: "0.6887561333266318",
        contract_address: "",
        contract_state: "",
        start_date: null,
        end_date: null,
        payment_day: null,
      },
    ],
  },
  {
    id: 18,
    created_at: "2021-03-27T17:40:01.77138Z",
    updated_at: "2021-10-02T00:56:16.358836Z",
    name: "Gary",
    symbol: "GARY",
    address_bech32: "zil1w5hwupgc9rxyuyd742g2c9annwahugrx80fw9h",
    icon: "https://meta.viewblock.io/ZIL.zil1w5hwupgc9rxyuyd742g2c9annwahugrx80fw9h/logo",
    decimals: 4,
    website: "http://thegarytoken.com/",
    telegram: "https://t.me/TheGARYToken",
    whitepaper: "",
    init_supply: 100,
    max_supply: 100,
    total_supply: 100,
    current_supply: 88.5992,
    daily_volume: 206501.1314334664,
    current_liquidity: 1480654.637049089,
    liquidity_providers: 46,
    supply_skip_addresses: "zil17rk7da3pk7eqqpy9v4k4v0rrl782e3uen7xz5u",
    viewblock_score: 33,
    unvetted: false,
    bridged: false,
    last_vote_start: "2021-09-03T00:00:00Z",
    last_vote_end: "2021-09-07T07:00:00Z",
    last_vote_hash: "QmaezBmBwLvMDEtHjRgfc8uZu2ivQvXckdqxJ5a2jgKHmk",
    rewards: [
      {
        id: 41,
        created_at: "2021-05-13T14:09:54.056616Z",
        updated_at: "2021-10-02T00:53:46.965906Z",
        type: "liquidity",
        amount: 56.52,
        max_individual_amount: 0,
        reward_token_id: 3,
        reward_token_address: "zil1p5suryq6q647usxczale29cu3336hhp376c627",
        reward_token_symbol: "ZWAP",
        frequency: 604800,
        frequency_type: "seconds",
        excluded_addresses: "",
        adjusted_total_contributed: "200126897466898270",
        adjusted_total_contributed_share: "1",
        contract_address: "",
        contract_state: "",
        start_date: null,
        end_date: null,
        payment_day: 3,
      },
    ],
  },
  {
    id: 70,
    created_at: "2021-09-27T18:31:23.195951Z",
    updated_at: "2021-10-02T00:56:16.364181Z",
    name: "Tether",
    symbol: "zUSDT",
    address_bech32: "zil1sxx29cshups269ahh5qjffyr58mxjv9ft78jqy",
    icon: "https://meta.viewblock.io/ZIL.zil1sxx29cshups269ahh5qjffyr58mxjv9ft78jqy/logo",
    decimals: 6,
    website: "https://tether.to/",
    telegram: "",
    whitepaper:
      "https://tether.to/wp-content/uploads/2016/06/TetherWhitePaper.pdf",
    init_supply: 0,
    max_supply: 0,
    total_supply: 0,
    current_supply: 68896070575.3984,
    daily_volume: 881267.8751354604,
    current_liquidity: 2969831.8144105854,
    liquidity_providers: 18,
    supply_skip_addresses: "",
    viewblock_score: 0,
    unvetted: false,
    bridged: true,
    last_vote_start: "0001-01-01T00:00:00Z",
    last_vote_end: "0001-01-01T00:00:00Z",
    last_vote_hash: "",
    rewards: [],
  },
  {
    id: 6,
    created_at: "2021-03-06T07:56:36.56627Z",
    updated_at: "2021-10-02T00:56:16.572876Z",
    name: "Bolt",
    symbol: "BOLT",
    address_bech32: "zil1x6z064fkssmef222gkhz3u5fhx57kyssn7vlu0",
    icon: "https://meta.viewblock.io/ZIL.zil1x6z064fkssmef222gkhz3u5fhx57kyssn7vlu0/logo",
    decimals: 18,
    website: "https://bolt.global/",
    telegram: "https://t.me/BoltGlobal",
    whitepaper: "",
    init_supply: 2000000,
    max_supply: 0,
    total_supply: 3000000.0000000102,
    current_supply: 3000000.0000000102,
    daily_volume: 90202.43532726335,
    current_liquidity: 356350.79180973,
    liquidity_providers: 665,
    supply_skip_addresses: "zil1wzz6vd9ejckvz3259nw5f4qz3rm74d3rn2p349",
    viewblock_score: 33,
    unvetted: false,
    bridged: false,
    last_vote_start: "0001-01-01T00:00:00Z",
    last_vote_end: "0001-01-01T00:00:00Z",
    last_vote_hash: "",
    rewards: [],
  },
  {
    id: 13,
    created_at: "2021-03-06T10:19:19.644009Z",
    updated_at: "2021-10-02T00:56:16.504116Z",
    name: "ZilFlip",
    symbol: "ZLF",
    address_bech32: "zil1r9dcsrya4ynuxnzaznu00e6hh3kpt7vhvzgva0",
    icon: "https://meta.viewblock.io/ZIL.zil1r9dcsrya4ynuxnzaznu00e6hh3kpt7vhvzgva0/logo",
    decimals: 5,
    website: "https://zilflip.com/",
    telegram: "https://t.me/zilFlip",
    whitepaper: "",
    init_supply: 50000,
    max_supply: 50000,
    total_supply: 50000.00000000001,
    current_supply: 19232.589570000004,
    daily_volume: 92522.67929507463,
    current_liquidity: 1470964.0616571535,
    liquidity_providers: 61,
    supply_skip_addresses: "zil16s2869hqf597mmxpnwg3p76mlpzg44686uafcl",
    viewblock_score: 48,
    unvetted: false,
    bridged: false,
    last_vote_start: "0001-01-01T00:00:00Z",
    last_vote_end: "0001-01-01T00:00:00Z",
    last_vote_hash: "",
    rewards: [
      {
        id: 48,
        created_at: "2021-05-13T14:09:54.056616Z",
        updated_at: "2021-10-02T00:53:46.959812Z",
        type: "liquidity",
        amount: 56.52,
        max_individual_amount: 0,
        reward_token_id: 3,
        reward_token_address: "zil1p5suryq6q647usxczale29cu3336hhp376c627",
        reward_token_symbol: "ZWAP",
        frequency: 604800,
        frequency_type: "seconds",
        excluded_addresses: "",
        adjusted_total_contributed: "167387133089126980",
        adjusted_total_contributed_share: "1",
        contract_address: "",
        contract_state: "",
        start_date: null,
        end_date: null,
        payment_day: 3,
      },
    ],
  },
  {
    id: 69,
    created_at: "2021-09-27T18:31:23.195951Z",
    updated_at: "2021-10-02T00:56:16.567349Z",
    name: "Ethereum",
    symbol: "zETH",
    address_bech32: "zil19j33tapjje2xzng7svslnsjjjgge930jx0w09v",
    icon: "https://meta.viewblock.io/ZIL.zil19j33tapjje2xzng7svslnsjjjgge930jx0w09v/logo",
    decimals: 18,
    website: "https://ethereum.org",
    telegram: "",
    whitepaper: "https://ethereum.org/en/whitepaper/",
    init_supply: 0,
    max_supply: 0,
    total_supply: 0,
    current_supply: 117755713.874,
    daily_volume: 750062.5423401175,
    current_liquidity: 17327824.445197865,
    liquidity_providers: 57,
    supply_skip_addresses: "",
    viewblock_score: 0,
    unvetted: false,
    bridged: true,
    last_vote_start: "0001-01-01T00:00:00Z",
    last_vote_end: "0001-01-01T00:00:00Z",
    last_vote_hash: "",
    rewards: [],
  },
  {
    id: 38,
    created_at: "2021-04-15T16:53:11.749256Z",
    updated_at: "2021-10-02T00:56:16.28813Z",
    name: "Score",
    symbol: "SCO",
    address_bech32: "zil1kwfu3x9n6fsuxc4ynp72uk5rxge25enw7zsf9z",
    icon: "https://meta.viewblock.io/ZIL.zil1kwfu3x9n6fsuxc4ynp72uk5rxge25enw7zsf9z/logo",
    decimals: 4,
    website: "https://uffsports.com",
    telegram: "https://t.me/uffsports",
    whitepaper:
      "https://a2410bb1-7d7b-4996-a99b-ef91046e37c6.filesusr.com/ugd/fec035_8655866acaf04bae83f16eb3c8d860f3.pdf",
    init_supply: 100000000,
    max_supply: 100000000,
    total_supply: 100000000,
    current_supply: 23646944.854500003,
    daily_volume: 261275.6976842914,
    current_liquidity: 17342826.95275418,
    liquidity_providers: 30,
    supply_skip_addresses:
      "zil14l9cg48snu5pj40lfmkvtckx62hfxp660wrmyh,zil1s9fqxk0skq7cfp5u59qqh3yftj9ufnhfkh9sxj,zil1jfn678cvphay4f4qkqwnlu20pwavm05n0lytsk,zil1t0jrgnrsysmy3xpjze28ynsrda59j2alt93jwf",
    viewblock_score: 43,
    unvetted: false,
    bridged: false,
    last_vote_start: "0001-01-01T00:00:00Z",
    last_vote_end: "0001-01-01T00:00:00Z",
    last_vote_hash: "",
    rewards: [
      {
        id: 31,
        created_at: "2021-05-13T14:09:54.056616Z",
        updated_at: "2021-10-02T00:53:47.067601Z",
        type: "liquidity",
        amount: 113.03,
        max_individual_amount: 0,
        reward_token_id: 3,
        reward_token_address: "zil1p5suryq6q647usxczale29cu3336hhp376c627",
        reward_token_symbol: "ZWAP",
        frequency: 604800,
        frequency_type: "seconds",
        excluded_addresses: "",
        adjusted_total_contributed: "5627110131753309000",
        adjusted_total_contributed_share: "1",
        contract_address: "",
        contract_state: "",
        start_date: null,
        end_date: null,
        payment_day: 3,
      },
    ],
  },
  {
    id: 67,
    created_at: "2021-09-10T07:17:10.557227Z",
    updated_at: "2021-10-02T00:56:16.65549Z",
    name: "PELE Network",
    symbol: "PELE",
    address_bech32: "zil12htx8pdfwk39e47fhd7t3vd3rftyxeuf9px354",
    icon: "https://meta.viewblock.io/ZIL.zil12htx8pdfwk39e47fhd7t3vd3rftyxeuf9px354/logo",
    decimals: 5,
    website: "https://pele.network/",
    telegram: "https://t.me/getyousomePELE",
    whitepaper: "",
    init_supply: 420000069,
    max_supply: 420000069,
    total_supply: 420000069.00000006,
    current_supply: 46197565.08269005,
    daily_volume: 82462.03286450886,
    current_liquidity: 2557408.830098474,
    liquidity_providers: 57,
    supply_skip_addresses:
      "zil1s8nxv2mshxkjzfnr4g2g45w0gauzm8w68yg60t,zil169xyk4twmg9mehxrgpecptsjezvaudwg2dfjgg,zil158f0cpeew2grtaukckdkz887z3c50aexggh8p4",
    viewblock_score: 29,
    unvetted: false,
    bridged: false,
    last_vote_start: "0001-01-01T00:00:00Z",
    last_vote_end: "0001-01-01T00:00:00Z",
    last_vote_hash: "",
    rewards: [
      {
        id: 58,
        created_at: "2021-09-16T05:00:11.093347Z",
        updated_at: "2021-10-02T00:53:47.356658Z",
        type: "liquidity",
        amount: 450000,
        max_individual_amount: 0,
        reward_token_id: 67,
        reward_token_address: "zil12htx8pdfwk39e47fhd7t3vd3rftyxeuf9px354",
        reward_token_symbol: "PELE",
        frequency: 604800,
        frequency_type: "seconds",
        excluded_addresses: "0xa1d2fc0739729035f796c59b611cfe147147f726",
        adjusted_total_contributed: "481149405655419000",
        adjusted_total_contributed_share: "0.5115146318916978",
        contract_address: "",
        contract_state: "",
        start_date: null,
        end_date: null,
        payment_day: null,
      },
      {
        id: 60,
        created_at: "2021-05-13T14:09:54.056616Z",
        updated_at: "2021-10-02T00:53:47.358748Z",
        type: "liquidity",
        amount: 56.52,
        max_individual_amount: 0,
        reward_token_id: 3,
        reward_token_address: "zil1p5suryq6q647usxczale29cu3336hhp376c627",
        reward_token_symbol: "ZWAP",
        frequency: 604800,
        frequency_type: "seconds",
        excluded_addresses: "",
        adjusted_total_contributed: "940636641958840400",
        adjusted_total_contributed_share: "1",
        contract_address: "",
        contract_state: "",
        start_date: null,
        end_date: null,
        payment_day: 3,
      },
    ],
  },
  {
    id: 42,
    created_at: "2021-05-30T03:32:09.586993Z",
    updated_at: "2021-10-02T00:56:16.247935Z",
    name: "Mambo",
    symbol: "MAMBO",
    address_bech32: "zil1ykcdhtgyercmv7ndfxt00wm9xzpq4k7hjmfm4e",
    icon: "https://meta.viewblock.io/ZIL.zil1ykcdhtgyercmv7ndfxt00wm9xzpq4k7hjmfm4e/logo",
    decimals: 12,
    website: "https://mambo.li/",
    telegram: "https://t.me/MamboToken",
    whitepaper: "",
    init_supply: 44000000,
    max_supply: 44000000,
    total_supply: 43956000,
    current_supply: 33644073.269999996,
    daily_volume: 37023.16733611567,
    current_liquidity: 1174108.9671048776,
    liquidity_providers: 34,
    supply_skip_addresses:
      "zil1lrx59grjpkewqrqkylmuztcaxmee6dyx20v3te,zil1w4ea2dqp0t00klhxarsaf0l6527vlzuygx2epy,zil1fgv6drpm80z0mx6jht7q35e382xcdva9kcgmp8",
    viewblock_score: 24,
    unvetted: false,
    bridged: false,
    last_vote_start: "0001-01-01T00:00:00Z",
    last_vote_end: "0001-01-01T00:00:00Z",
    last_vote_hash: "",
    rewards: [
      {
        id: 51,
        created_at: "2021-05-13T14:09:54.056616Z",
        updated_at: "2021-10-02T00:53:47.084194Z",
        type: "liquidity",
        amount: 56.52,
        max_individual_amount: 0,
        reward_token_id: 3,
        reward_token_address: "zil1p5suryq6q647usxczale29cu3336hhp376c627",
        reward_token_symbol: "ZWAP",
        frequency: 604800,
        frequency_type: "seconds",
        excluded_addresses: "",
        adjusted_total_contributed: "29226855679764496",
        adjusted_total_contributed_share: "1",
        contract_address: "",
        contract_state: "",
        start_date: null,
        end_date: null,
        payment_day: 3,
      },
    ],
  },
  {
    id: 46,
    created_at: "2021-06-08T11:13:16.661217Z",
    updated_at: "2021-10-02T00:56:16.275889Z",
    name: "MESSI",
    symbol: "MESSI",
    address_bech32: "zil1qzwxvp3wz3q5ewzg3afesut9pqfax0sjdyna42",
    icon: "https://meta.viewblock.io/ZIL.zil1qzwxvp3wz3q5ewzg3afesut9pqfax0sjdyna42/logo",
    decimals: 2,
    website: "https://messitokens.me/",
    telegram: "https://t.me/TheMessiProject",
    whitepaper: "https://messitokens.me/assets/docs/Messi%20-%20Whitepaper.pdf",
    init_supply: 100000,
    max_supply: 100000,
    total_supply: 100000,
    current_supply: 41100.26,
    daily_volume: 21701.160329870905,
    current_liquidity: 58179.019845119365,
    liquidity_providers: 10,
    supply_skip_addresses: "zil14nhxgk67rdtpnsz9ghhzqh8xyp44u3n9y9x86g",
    viewblock_score: 43,
    unvetted: false,
    bridged: false,
    last_vote_start: "0001-01-01T00:00:00Z",
    last_vote_end: "0001-01-01T00:00:00Z",
    last_vote_hash: "",
    rewards: [
      {
        id: 22,
        created_at: "2021-07-05T02:23:29.968921Z",
        updated_at: "2021-10-02T00:53:47.051657Z",
        type: "liquidity",
        amount: 250,
        max_individual_amount: 0,
        reward_token_id: 46,
        reward_token_address: "zil1qzwxvp3wz3q5ewzg3afesut9pqfax0sjdyna42",
        reward_token_symbol: "MESSI",
        frequency: 604800,
        frequency_type: "seconds",
        excluded_addresses: "0xacee645b5e1b5619c04545ee205ce6206b5e4665",
        adjusted_total_contributed: "2629096750220219",
        adjusted_total_contributed_share: "0.724449341302538",
        contract_address: "",
        contract_state: "",
        start_date: null,
        end_date: null,
        payment_day: 0,
      },
    ],
  },
  {
    id: 2,
    created_at: "2021-03-05T10:37:32.147344Z",
    updated_at: "2021-10-02T00:56:16.609018Z",
    name: "governance ZIL",
    symbol: "gZIL",
    address_bech32: "zil14pzuzq6v6pmmmrfjhczywguu0e97djepxt8g3e",
    icon: "https://meta.viewblock.io/ZIL.zil14pzuzq6v6pmmmrfjhczywguu0e97djepxt8g3e/logo",
    decimals: 15,
    website: "https://zilliqa.com",
    telegram: "",
    whitepaper: "",
    init_supply: 0,
    max_supply: 722700,
    total_supply: 540194.6530776389,
    current_supply: 540194.6530776389,
    daily_volume: 11273111.742590956,
    current_liquidity: 100401888.67418337,
    liquidity_providers: 1023,
    supply_skip_addresses: "",
    viewblock_score: 100,
    unvetted: false,
    bridged: false,
    last_vote_start: "0001-01-01T00:00:00Z",
    last_vote_end: "0001-01-01T00:00:00Z",
    last_vote_hash: "",
    rewards: [
      {
        id: 30,
        created_at: "2021-05-13T14:09:54.056616Z",
        updated_at: "2021-10-02T00:53:47.344743Z",
        type: "liquidity",
        amount: 282.58,
        max_individual_amount: 0,
        reward_token_id: 3,
        reward_token_address: "zil1p5suryq6q647usxczale29cu3336hhp376c627",
        reward_token_symbol: "ZWAP",
        frequency: 604800,
        frequency_type: "seconds",
        excluded_addresses: "",
        adjusted_total_contributed: "89866315855037830000",
        adjusted_total_contributed_share: "1",
        contract_address: "",
        contract_state: "",
        start_date: null,
        end_date: null,
        payment_day: 3,
      },
    ],
  },
  {
    id: 71,
    created_at: "2021-09-27T18:31:23.195951Z",
    updated_at: "2021-10-02T00:56:16.660779Z",
    name: "Wrapped Bitcoin",
    symbol: "zWBTC",
    address_bech32: "zil1wha8mzaxhm22dpm5cav2tepuldnr8kwkvmqtjq",
    icon: "https://meta.viewblock.io/ZIL.zil1wha8mzaxhm22dpm5cav2tepuldnr8kwkvmqtjq/logo",
    decimals: 8,
    website: "https://bitcoin.org",
    telegram: "",
    whitepaper: "https://bitcoin.org/bitcoin.pdf",
    init_supply: 0,
    max_supply: 0,
    total_supply: 0,
    current_supply: 205770.58493299,
    daily_volume: 966781.4694874764,
    current_liquidity: 2797208.291175923,
    liquidity_providers: 8,
    supply_skip_addresses: "",
    viewblock_score: 0,
    unvetted: false,
    bridged: true,
    last_vote_start: "0001-01-01T00:00:00Z",
    last_vote_end: "0001-01-01T00:00:00Z",
    last_vote_hash: "",
    rewards: [],
  },
  {
    id: 17,
    created_at: "2021-03-07T10:01:23.688527Z",
    updated_at: "2021-10-02T00:56:16.281566Z",
    name: "CARBON",
    symbol: "CARB",
    address_bech32: "zil1hau7z6rjltvjc95pphwj57umdpvv0d6kh2t8zk",
    icon: "https://meta.viewblock.io/ZIL.zil1hau7z6rjltvjc95pphwj57umdpvv0d6kh2t8zk/logo",
    decimals: 8,
    website: "https://www.carbontoken.info/",
    telegram: "https://t.me/carbonholders",
    whitepaper: "https://github.com/GenesysLabs/whitepapers",
    init_supply: 10000000,
    max_supply: 10000000,
    total_supply: 10000000,
    current_supply: 3651607.81362316,
    daily_volume: 536276.9383405643,
    current_liquidity: 9443072.799637388,
    liquidity_providers: 162,
    supply_skip_addresses:
      "zil14gjgjmw3cf0vvzewshtux422zafhq89cq796cx,zil1g5kvpvmdypv43zqn8hrcezmpzhsenezzr20cw7",
    viewblock_score: 52,
    unvetted: false,
    bridged: false,
    last_vote_start: "0001-01-01T00:00:00Z",
    last_vote_end: "0001-01-01T00:00:00Z",
    last_vote_hash: "",
    rewards: [
      {
        id: 34,
        created_at: "2021-05-13T14:09:54.056616Z",
        updated_at: "2021-10-02T00:53:47.291585Z",
        type: "liquidity",
        amount: 113.03,
        max_individual_amount: 0,
        reward_token_id: 3,
        reward_token_address: "zil1p5suryq6q647usxczale29cu3336hhp376c627",
        reward_token_symbol: "ZWAP",
        frequency: 604800,
        frequency_type: "seconds",
        excluded_addresses: "",
        adjusted_total_contributed: "3616672023774297600",
        adjusted_total_contributed_share: "1",
        contract_address: "",
        contract_state: "",
        start_date: null,
        end_date: null,
        payment_day: 3,
      },
      {
        id: 13,
        created_at: "2021-05-13T15:06:47.970245Z",
        updated_at: "2021-10-02T00:53:47.293924Z",
        type: "liquidity",
        amount: 14000,
        max_individual_amount: 1000,
        reward_token_id: 17,
        reward_token_address: "zil1hau7z6rjltvjc95pphwj57umdpvv0d6kh2t8zk",
        reward_token_symbol: "CARB",
        frequency: 604800,
        frequency_type: "seconds",
        excluded_addresses: "",
        adjusted_total_contributed: "3616672023774297600",
        adjusted_total_contributed_share: "1",
        contract_address: "",
        contract_state: "",
        start_date: null,
        end_date: null,
        payment_day: 0,
      },
    ],
  },
  {
    id: 58,
    created_at: "2021-07-10T12:56:43.504078Z",
    updated_at: "2021-10-02T00:56:16.403155Z",
    name: "Shards",
    symbol: "SHARDS",
    address_bech32: "zil14d6wwelssqumu6w9c6kaucz2r57z34cxuh96lf",
    icon: "https://meta.viewblock.io/ZIL.zil14d6wwelssqumu6w9c6kaucz2r57z34cxuh96lf/logo",
    decimals: 12,
    website: "https://shards.robounicats.com/",
    telegram: "https://t.me/ruc_shards",
    whitepaper: "https://shards.robounicats.com/data/shards_whitepaper_0.1.pdf",
    init_supply: 140000,
    max_supply: 300000,
    total_supply: 160000,
    current_supply: 146042,
    daily_volume: 71472.05229664667,
    current_liquidity: 2369310.984221878,
    liquidity_providers: 22,
    supply_skip_addresses:
      "zil1z6tryf5exjfh9ud5df6mqgrvxznyqg78ju880t,zil1ruf9fv7aace76s8ecrlklaqqn4vgl5f3yzefgc",
    viewblock_score: 48,
    unvetted: false,
    bridged: false,
    last_vote_start: "0001-01-01T00:00:00Z",
    last_vote_end: "0001-01-01T00:00:00Z",
    last_vote_hash: "",
    rewards: [
      {
        id: 61,
        created_at: "2021-05-13T14:09:54.056616Z",
        updated_at: "2021-10-02T00:53:46.780217Z",
        type: "liquidity",
        amount: 700,
        max_individual_amount: 0,
        reward_token_id: 58,
        reward_token_address: "zil14d6wwelssqumu6w9c6kaucz2r57z34cxuh96lf",
        reward_token_symbol: "SHARDS",
        frequency: 604800,
        frequency_type: "seconds",
        excluded_addresses: "",
        adjusted_total_contributed: "65386346707465630",
        adjusted_total_contributed_share: "1",
        contract_address: "",
        contract_state: "",
        start_date: null,
        end_date: null,
        payment_day: 0,
      },
      {
        id: 45,
        created_at: "2021-05-13T14:09:54.056616Z",
        updated_at: "2021-10-02T00:53:46.782824Z",
        type: "liquidity",
        amount: 56.52,
        max_individual_amount: 0,
        reward_token_id: 3,
        reward_token_address: "zil1p5suryq6q647usxczale29cu3336hhp376c627",
        reward_token_symbol: "ZWAP",
        frequency: 604800,
        frequency_type: "seconds",
        excluded_addresses: "",
        adjusted_total_contributed: "65386346707465650",
        adjusted_total_contributed_share: "1",
        contract_address: "",
        contract_state: "",
        start_date: null,
        end_date: null,
        payment_day: 3,
      },
    ],
  },
  {
    id: 56,
    created_at: "2021-06-28T03:54:27.151457Z",
    updated_at: "2021-10-02T00:56:16.415612Z",
    name: "Chocolate Stablecoin",
    symbol: "FRANC",
    address_bech32: "zil1z4hxwnqk9gu6tamcw4umxss9wjpmrkhzdh4n85",
    icon: "https://meta.viewblock.io/ZIL.zil1z4hxwnqk9gu6tamcw4umxss9wjpmrkhzdh4n85/logo",
    decimals: 6,
    website: "https://mambo.li/stablecoins/franc/",
    telegram: "https://t.me/MamboToken",
    whitepaper:
      "https://mambo.li/downloads/francchocolatestabelcoinwhitepaper.pdf",
    init_supply: 1000000,
    max_supply: 0,
    total_supply: 1000000,
    current_supply: 87457.39849199995,
    daily_volume: 18442.604342600105,
    current_liquidity: 434424.0162929126,
    liquidity_providers: 11,
    supply_skip_addresses:
      "zil1jwupzktvdqpd90y7mrh68hpgxr9w8hlve8zk4l,zil1r07m0fhtutpuphulut0kah3hycejz3d47g3l77,zil1lrltfxlmzky9dl3m3k35wdyamuz5ekmz7cm300,zil1nn0w0wyezp2l09eksyzekzv8t0yuc9jrwc37qn,zil1ccc4jk946szpl7qt2pnr5qa50tjyqsrgae0f89,zil175g5v8q64q55yz28e98u8jlxc77g0vxsyak2jr,zil1f0gpn358eudw0l7ehr3cefe395hk97s2up7pk5,zil1q2pkpjydz9z2dtccjtdsfja54qkr538hsrr7xy,zil1ge5p5nf7jurqvqupuqn4lls99jjtqusq5qpj39",
    viewblock_score: 24,
    unvetted: false,
    bridged: false,
    last_vote_start: "0001-01-01T00:00:00Z",
    last_vote_end: "0001-01-01T00:00:00Z",
    last_vote_hash: "",
    rewards: [
      {
        id: 19,
        created_at: "2021-09-21T15:35:14.493712Z",
        updated_at: "2021-10-02T00:53:47.204756Z",
        type: "liquidity",
        amount: 10000,
        max_individual_amount: 0,
        reward_token_id: 42,
        reward_token_address: "zil1ykcdhtgyercmv7ndfxt00wm9xzpq4k7hjmfm4e",
        reward_token_symbol: "MAMBO",
        frequency: 604800,
        frequency_type: "seconds",
        excluded_addresses: "0x93b811596c6802d2bc9ed8efa3dc2830cae3dfec",
        adjusted_total_contributed: "69228999168060420",
        adjusted_total_contributed_share: "0.2892450438143385",
        contract_address: "",
        contract_state: "",
        start_date: null,
        end_date: null,
        payment_day: 0,
      },
    ],
  },
  {
    id: 21,
    created_at: "2021-03-28T04:35:16.476023Z",
    updated_at: "2021-10-02T00:56:16.476883Z",
    name: "Review Capital",
    symbol: "RECAP",
    address_bech32: "zil12drvflckms6874ffuujcdxj75raavl4yfn4ssc",
    icon: "https://meta.viewblock.io/ZIL.zil12drvflckms6874ffuujcdxj75raavl4yfn4ssc/logo",
    decimals: 12,
    website: "https://reviewcapital.org/",
    telegram: "https://t.me/RECAPCommunity",
    whitepaper:
      "https://github.com/Review-Capital-Blockchain-Ecosystem/RCIP/blob/master/RECAP-Whitepaper.md",
    init_supply: 200000,
    max_supply: 11000000,
    total_supply: 230000,
    current_supply: 230000,
    daily_volume: 16799.89424642713,
    current_liquidity: 1612144.5141323095,
    liquidity_providers: 50,
    supply_skip_addresses: "zil1vqvh89p856wkll8v8jgajhsm4xf3m5wkqaafz2",
    viewblock_score: 52,
    unvetted: false,
    bridged: false,
    last_vote_start: "0001-01-01T00:00:00Z",
    last_vote_end: "0001-01-01T00:00:00Z",
    last_vote_hash: "",
    rewards: [
      {
        id: 52,
        created_at: "2021-05-13T14:09:54.056616Z",
        updated_at: "2021-10-02T00:53:46.875415Z",
        type: "liquidity",
        amount: 56.52,
        max_individual_amount: 0,
        reward_token_id: 3,
        reward_token_address: "zil1p5suryq6q647usxczale29cu3336hhp376c627",
        reward_token_symbol: "ZWAP",
        frequency: 604800,
        frequency_type: "seconds",
        excluded_addresses: "",
        adjusted_total_contributed: "95704464602990600",
        adjusted_total_contributed_share: "1",
        contract_address: "",
        contract_state: "",
        start_date: null,
        end_date: null,
        payment_day: 3,
      },
      {
        id: 23,
        created_at: "2021-07-07T14:52:24.975933Z",
        updated_at: "2021-10-02T00:53:46.877562Z",
        type: "liquidity",
        amount: 3000,
        max_individual_amount: 0,
        reward_token_id: 21,
        reward_token_address: "zil12drvflckms6874ffuujcdxj75raavl4yfn4ssc",
        reward_token_symbol: "RECAP",
        frequency: 604800,
        frequency_type: "seconds",
        excluded_addresses: "",
        adjusted_total_contributed: "95704464602990560",
        adjusted_total_contributed_share: "1",
        contract_address: "",
        contract_state: "",
        start_date: null,
        end_date: null,
        payment_day: 3,
      },
    ],
  },
  {
    id: 4,
    created_at: "2021-03-05T14:55:01.377298Z",
    updated_at: "2021-10-02T00:56:16.448477Z",
    name: "PackagePortal",
    symbol: "PORT",
    address_bech32: "zil18f5rlhqz9vndw4w8p60d0n7vg3n9sqvta7n6t2",
    icon: "https://meta.viewblock.io/ZIL.zil18f5rlhqz9vndw4w8p60d0n7vg3n9sqvta7n6t2/logo",
    decimals: 4,
    website: "https://www.packageportal.com/",
    telegram: "https://t.me/packageportal",
    whitepaper:
      "https://docs.google.com/document/d/1-3qjW4bozTt72CzfGcwDGzcm8---opu0fN1pxs34RyE/edit?usp=sharing",
    init_supply: 10000000,
    max_supply: 10000000,
    total_supply: 10000000,
    current_supply: 2698680.2700999994,
    daily_volume: 2277397.092554467,
    current_liquidity: 24522554.048347093,
    liquidity_providers: 239,
    supply_skip_addresses:
      "zil1dw5m8tulqxhcen0n5u84nskljas03gmtckdmnp,zil1xlqwglrgr336rss9te4gwg2gy6frr4r2ymzwx8,zil1t8kjnnxqlmdzw0fvs902q3gvqx867r8tktkjqc,zil1l2duu9tryqaru5r7tnlz2ex3ceg5rg2tvamnsg,zil1d0ym7zugp7hgz58vfqsqut5c4sf78dxulx6wtf,zil1s7lfhje2q52ul4sk5a7nztv6fe5y44c7z6jna9,zil15sl9jjmtaqtwq2l5fsswe5eph024zttn0aj754,zil1729y42qrkajtsecustfaret3vu6ehhy7vdwlu0",
    viewblock_score: 90,
    unvetted: false,
    bridged: false,
    last_vote_start: "0001-01-01T00:00:00Z",
    last_vote_end: "0001-01-01T00:00:00Z",
    last_vote_hash: "",
    rewards: [
      {
        id: 38,
        created_at: "2021-05-13T14:09:54.056616Z",
        updated_at: "2021-10-02T00:53:47.273624Z",
        type: "liquidity",
        amount: 169.55,
        max_individual_amount: 0,
        reward_token_id: 3,
        reward_token_address: "zil1p5suryq6q647usxczale29cu3336hhp376c627",
        reward_token_symbol: "ZWAP",
        frequency: 604800,
        frequency_type: "seconds",
        excluded_addresses: "",
        adjusted_total_contributed: "5635035609204065000",
        adjusted_total_contributed_share: "1",
        contract_address: "",
        contract_state: "",
        start_date: null,
        end_date: null,
        payment_day: 3,
      },
    ],
  },
  {
    id: 59,
    created_at: "2021-08-04T18:27:47.762567Z",
    updated_at: "2021-10-02T00:56:16.454245Z",
    name: "BLOX",
    symbol: "BLOX",
    address_bech32: "zil1gf5vxndx44q6fn025fwdaajnrmgvngdzel0jzp",
    icon: "https://meta.viewblock.io/ZIL.zil1gf5vxndx44q6fn025fwdaajnrmgvngdzel0jzp/logo",
    decimals: 2,
    website: "https://blox-sdk.com",
    telegram: "https://t.me/zilliqaroyale",
    whitepaper: "",
    init_supply: 5000000,
    max_supply: 5000000,
    total_supply: 5000000,
    current_supply: 3141409.69,
    daily_volume: 1172845.988976795,
    current_liquidity: 16297456.224061785,
    liquidity_providers: 162,
    supply_skip_addresses: "zil1gy9v2fy4nsa5n5mx7grtt5sn205d6qduxkl84t",
    viewblock_score: 33,
    unvetted: false,
    bridged: false,
    last_vote_start: "0001-01-01T00:00:00Z",
    last_vote_end: "0001-01-01T00:00:00Z",
    last_vote_hash: "",
    rewards: [
      {
        id: 35,
        created_at: "2021-05-13T14:09:54.056616Z",
        updated_at: "2021-10-02T00:53:47.281547Z",
        type: "liquidity",
        amount: 169.55,
        max_individual_amount: 0,
        reward_token_id: 3,
        reward_token_address: "zil1p5suryq6q647usxczale29cu3336hhp376c627",
        reward_token_symbol: "ZWAP",
        frequency: 604800,
        frequency_type: "seconds",
        excluded_addresses: "",
        adjusted_total_contributed: "3220612472769043500",
        adjusted_total_contributed_share: "1",
        contract_address: "",
        contract_state: "",
        start_date: null,
        end_date: null,
        payment_day: 3,
      },
      {
        id: 27,
        created_at: "2021-08-13T09:03:18.967291Z",
        updated_at: "2021-10-02T00:53:47.283757Z",
        type: "liquidity",
        amount: 10869,
        max_individual_amount: 0,
        reward_token_id: 59,
        reward_token_address: "zil1gf5vxndx44q6fn025fwdaajnrmgvngdzel0jzp",
        reward_token_symbol: "BLOX",
        frequency: 604800,
        frequency_type: "seconds",
        excluded_addresses: "0x93d49ddd22dfc3831d26395a3f0cebbde87ab1b5",
        adjusted_total_contributed: "1923612472769043000",
        adjusted_total_contributed_share: "0.5972815695876458",
        contract_address: "",
        contract_state: "",
        start_date: null,
        end_date: null,
        payment_day: 0,
      },
    ],
  },
  {
    id: 34,
    created_at: "2021-04-07T07:56:07.694584Z",
    updated_at: "2021-10-02T00:56:16.29935Z",
    name: "zilSurvey",
    symbol: "SRV",
    address_bech32: "zil168qdlq4xsua6ac9hugzntqyasf8gs7aund882v",
    icon: "https://meta.viewblock.io/ZIL.zil168qdlq4xsua6ac9hugzntqyasf8gs7aund882v/logo",
    decimals: 2,
    website: "https://zilsurvey.com/",
    telegram: "https://t.me/zilSurveyChat",
    whitepaper: "https://zilsurvey.com/whitepaper.pdf",
    init_supply: 1000000,
    max_supply: 1000000,
    total_supply: 1000000,
    current_supply: 187263.17999999993,
    daily_volume: 2407.490262588111,
    current_liquidity: 1704076.1940597172,
    liquidity_providers: 75,
    supply_skip_addresses: "zil1rehtqwzgawn88rfje4x4uk4ryacal8atkf0qdg",
    viewblock_score: 43,
    unvetted: false,
    bridged: false,
    last_vote_start: "0001-01-01T00:00:00Z",
    last_vote_end: "0001-01-01T00:00:00Z",
    last_vote_hash: "",
    rewards: [
      {
        id: 49,
        created_at: "2021-05-13T14:09:54.056616Z",
        updated_at: "2021-10-02T00:53:47.077814Z",
        type: "liquidity",
        amount: 56.52,
        max_individual_amount: 0,
        reward_token_id: 3,
        reward_token_address: "zil1p5suryq6q647usxczale29cu3336hhp376c627",
        reward_token_symbol: "ZWAP",
        frequency: 604800,
        frequency_type: "seconds",
        excluded_addresses: "",
        adjusted_total_contributed: "250026171980773440",
        adjusted_total_contributed_share: "1",
        contract_address: "",
        contract_state: "",
        start_date: null,
        end_date: null,
        payment_day: 3,
      },
    ],
  },
  {
    id: 55,
    created_at: "2021-06-27T13:43:40.332719Z",
    updated_at: "2021-10-02T00:56:16.395079Z",
    name: "ZilWall",
    symbol: "ZWALL",
    address_bech32: "zil1xswavlggsqmkd9kddcp0ulceqm9ht36gqkt8ua",
    icon: "https://meta.viewblock.io/ZIL.zil1xswavlggsqmkd9kddcp0ulceqm9ht36gqkt8ua/logo",
    decimals: 12,
    website: "https://zilwall.com/",
    telegram: "https://t.me/zilwall_community",
    whitepaper: "https://zilwall.com/whitepaper",
    init_supply: 0,
    max_supply: 275000,
    total_supply: 87521.87566592297,
    current_supply: 87521.87566592297,
    daily_volume: 62918.55437066684,
    current_liquidity: 3403913.099605688,
    liquidity_providers: 26,
    supply_skip_addresses: "zil1p9cdug0euzgcuswl5cpnhgprm0k6arag6eklqf",
    viewblock_score: 38,
    unvetted: false,
    bridged: false,
    last_vote_start: "2021-09-10T14:00:00Z",
    last_vote_end: "2021-09-12T14:00:00Z",
    last_vote_hash: "QmNMFacSNwUradje5BTimJ7y4JipE5fumvxJs2H2Pn51pp",
    rewards: [
      {
        id: 25,
        created_at: "2021-07-14T12:37:33.926432Z",
        updated_at: "2021-10-02T00:55:46.240068Z",
        type: "liquidity",
        amount: 449318.9478,
        max_individual_amount: 0,
        reward_token_id: 54,
        reward_token_address: "zil1qldr63ds7yuspqcf02263y2lctmtqmr039vrht",
        reward_token_symbol: "ZPAINT",
        frequency: 604800,
        frequency_type: "seconds",
        excluded_addresses: "0x0eabf904a7851b59fdddc941e4a1e9b7b516ec38",
        adjusted_total_contributed: "1287429965138711000",
        adjusted_total_contributed_share: "1",
        contract_address: "0x18e615689e2d4eb02b570827891c8c5cedc0322c",
        contract_state: "",
        start_date: null,
        end_date: null,
        payment_day: 0,
      },
      {
        id: 46,
        created_at: "2021-05-13T14:09:54.056616Z",
        updated_at: "2021-10-02T00:53:47.058508Z",
        type: "liquidity",
        amount: 113.03,
        max_individual_amount: 0,
        reward_token_id: 3,
        reward_token_address: "zil1p5suryq6q647usxczale29cu3336hhp376c627",
        reward_token_symbol: "ZWAP",
        frequency: 604800,
        frequency_type: "seconds",
        excluded_addresses: "",
        adjusted_total_contributed: "1287429965138710800",
        adjusted_total_contributed_share: "1",
        contract_address: "",
        contract_state: "",
        start_date: null,
        end_date: null,
        payment_day: 3,
      },
    ],
  },
  {
    id: 16,
    created_at: "2021-03-06T10:20:25.434375Z",
    updated_at: "2021-10-02T00:56:16.468731Z",
    name: "zyro",
    symbol: "ZYRO",
    address_bech32: "zil1ucvrn22x8366vzpw5t7su6eyml2auczu6wnqqg",
    icon: "https://meta.viewblock.io/ZIL.zil1ucvrn22x8366vzpw5t7su6eyml2auczu6wnqqg/logo",
    decimals: 8,
    website: "https://zyro.finance/",
    telegram: "",
    whitepaper: "",
    init_supply: 300000000,
    max_supply: 300000000,
    total_supply: 300000000,
    current_supply: 25319294.80100023,
    daily_volume: 31148.485086306795,
    current_liquidity: 2814622.0667855917,
    liquidity_providers: 89,
    supply_skip_addresses:
      "zil1x3hzmah0ydcx8vsvpaxjmwcz0llssf0ppss7gl,zil18uzjpmzta69qahwy2hntdtmtegrs3um26840fy,zil13hwp8zerntrxapzhy5f8u7qcc9cp6dgx0htswe,zil1flkuv77wad93q23j93nsehsaml56m855lfk9hy,zil1dq6nu8tzuug0zs5atza2x4x0z7yggtg0dtw0uv,zil1g78v3cmwnc6s9zturwq7yr44hxv6lpvhd8fe55,zil17rdj7quznwg44w37cwspk7helcu3a5m8lelh73,zil1rkvz9tkwyx3g4fyeeyujphurnws58cmdszmx88",
    viewblock_score: 33,
    unvetted: false,
    bridged: false,
    last_vote_start: "0001-01-01T00:00:00Z",
    last_vote_end: "0001-01-01T00:00:00Z",
    last_vote_hash: "",
    rewards: [
      {
        id: 40,
        created_at: "2021-05-13T14:09:54.056616Z",
        updated_at: "2021-10-02T00:53:46.866978Z",
        type: "liquidity",
        amount: 56.52,
        max_individual_amount: 0,
        reward_token_id: 3,
        reward_token_address: "zil1p5suryq6q647usxczale29cu3336hhp376c627",
        reward_token_symbol: "ZWAP",
        frequency: 604800,
        frequency_type: "seconds",
        excluded_addresses: "",
        adjusted_total_contributed: "4967575008446183000",
        adjusted_total_contributed_share: "1",
        contract_address: "",
        contract_state: "",
        start_date: null,
        end_date: null,
        payment_day: 3,
      },
    ],
  },
  {
    id: 14,
    created_at: "2021-03-06T10:19:19.644009Z",
    updated_at: "2021-10-02T00:56:16.499152Z",
    name: "ZilPay Wallet",
    symbol: "ZLP",
    address_bech32: "zil1l0g8u6f9g0fsvjuu74ctyla2hltefrdyt7k5f4",
    icon: "https://meta.viewblock.io/ZIL.zil1l0g8u6f9g0fsvjuu74ctyla2hltefrdyt7k5f4/logo",
    decimals: 18,
    website: "https://zilpay.io",
    telegram: "https://t.me/zilpaychat",
    whitepaper:
      "https://drive.google.com/file/d/1X-z5AHBp2cOwyXo4ZuVCCBuk7MIX-r6k/view",
    init_supply: 1000000,
    max_supply: 0,
    total_supply: 1000000,
    current_supply: 1000000,
    daily_volume: 113574.94484889807,
    current_liquidity: 3442576.794422171,
    liquidity_providers: 615,
    supply_skip_addresses: "",
    viewblock_score: 62,
    unvetted: false,
    bridged: false,
    last_vote_start: "0001-01-01T00:00:00Z",
    last_vote_end: "0001-01-01T00:00:00Z",
    last_vote_hash: "",
    rewards: [
      {
        id: 42,
        created_at: "2021-05-13T14:09:54.056616Z",
        updated_at: "2021-10-02T00:53:47.182994Z",
        type: "liquidity",
        amount: 113.03,
        max_individual_amount: 0,
        reward_token_id: 3,
        reward_token_address: "zil1p5suryq6q647usxczale29cu3336hhp376c627",
        reward_token_symbol: "ZWAP",
        frequency: 604800,
        frequency_type: "seconds",
        excluded_addresses: "",
        adjusted_total_contributed: "40917315992536136",
        adjusted_total_contributed_share: "1",
        contract_address: "",
        contract_state: "",
        start_date: null,
        end_date: null,
        payment_day: 3,
      },
    ],
  },
  {
    id: 54,
    created_at: "2021-06-27T13:42:03.815544Z",
    updated_at: "2021-10-02T00:56:16.268882Z",
    name: "ZilWall Paint",
    symbol: "ZPAINT",
    address_bech32: "zil1qldr63ds7yuspqcf02263y2lctmtqmr039vrht",
    icon: "https://meta.viewblock.io/ZIL.zil1qldr63ds7yuspqcf02263y2lctmtqmr039vrht/logo",
    decimals: 4,
    website: "https://zilwall.com/",
    telegram: "https://t.me/zilwall_community",
    whitepaper: "https://zilwall.com/whitepaper",
    init_supply: 420000000,
    max_supply: 420000000,
    total_supply: 409621782,
    current_supply: 40830317.80000001,
    daily_volume: 2853.52749334773,
    current_liquidity: 3423183.680627602,
    liquidity_providers: 42,
    supply_skip_addresses:
      "zil12dej4ey99ekw22g92ec69kuqzt6rnq9lcdwuf3,zil1nrh69xg03zkwy3j7rjfrrn9w8297aq27fpmwjp,zil1fzlngm2ydhkn65lx7xq50y8yqsc6f3luavypqq,zil1f690jftavzpm8rxz96tu8h3dl0gytf9c00zmvc,zil1e3pw790l6vvr9xx923u932svvnc6qfukl722la,zil1rrnp26y7948tq26hpqncj8yvtnkuqv3vvt00au",
    viewblock_score: 38,
    unvetted: false,
    bridged: false,
    last_vote_start: "0001-01-01T00:00:00Z",
    last_vote_end: "0001-01-01T00:00:00Z",
    last_vote_hash: "",
    rewards: [
      {
        id: 24,
        created_at: "2021-07-14T12:37:33.926432Z",
        updated_at: "2021-10-02T00:55:46.482717Z",
        type: "liquidity",
        amount: 25272,
        max_individual_amount: 0,
        reward_token_id: 54,
        reward_token_address: "zil1qldr63ds7yuspqcf02263y2lctmtqmr039vrht",
        reward_token_symbol: "ZPAINT",
        frequency: 604800,
        frequency_type: "seconds",
        excluded_addresses: "0x0eabf904a7851b59fdddc941e4a1e9b7b516ec38",
        adjusted_total_contributed: "552832459772361500",
        adjusted_total_contributed_share: "0.2598101449357081",
        contract_address: "0x18e615689e2d4eb02b570827891c8c5cedc0322c",
        contract_state: "",
        start_date: null,
        end_date: null,
        payment_day: 0,
      },
      {
        id: 37,
        created_at: "2021-05-13T14:09:54.056616Z",
        updated_at: "2021-10-02T00:53:47.308952Z",
        type: "liquidity",
        amount: 113.03,
        max_individual_amount: 0,
        reward_token_id: 3,
        reward_token_address: "zil1p5suryq6q647usxczale29cu3336hhp376c627",
        reward_token_symbol: "ZWAP",
        frequency: 604800,
        frequency_type: "seconds",
        excluded_addresses: "",
        adjusted_total_contributed: "2127832459772361700",
        adjusted_total_contributed_share: "1",
        contract_address: "",
        contract_state: "",
        start_date: null,
        end_date: null,
        payment_day: 3,
      },
    ],
  },
  {
    id: 11,
    created_at: "2021-03-06T10:16:24.23442Z",
    updated_at: "2021-10-02T00:56:16.351734Z",
    name: "XSGD",
    symbol: "XSGD",
    address_bech32: "zil1zu72vac254htqpg3mtywdcfm84l3dfd9qzww8t",
    icon: "https://meta.viewblock.io/zilliqa.zil180v66mlw007ltdv8tq5t240y7upwgf7djklmwh/logo",
    decimals: 6,
    website: "",
    telegram: "",
    whitepaper: "",
    init_supply: 0,
    max_supply: 0,
    total_supply: 40558547.91,
    current_supply: 40558547.91,
    daily_volume: 6588393.59074843,
    current_liquidity: 54256673.466613024,
    liquidity_providers: 461,
    supply_skip_addresses: "",
    viewblock_score: 100,
    unvetted: false,
    bridged: false,
    last_vote_start: "0001-01-01T00:00:00Z",
    last_vote_end: "0001-01-01T00:00:00Z",
    last_vote_hash: "",
    rewards: [
      {
        id: 29,
        created_at: "2021-05-13T14:09:54.056616Z",
        updated_at: "2021-10-02T00:53:47.248571Z",
        type: "liquidity",
        amount: 282.58,
        max_individual_amount: 0,
        reward_token_id: 3,
        reward_token_address: "zil1p5suryq6q647usxczale29cu3336hhp376c627",
        reward_token_symbol: "ZWAP",
        frequency: 604800,
        frequency_type: "seconds",
        excluded_addresses: "",
        adjusted_total_contributed: "51759503063300596000",
        adjusted_total_contributed_share: "1",
        contract_address: "",
        contract_state: "",
        start_date: null,
        end_date: null,
        payment_day: 3,
      },
    ],
  },
  {
    id: 3,
    created_at: "2021-03-05T10:37:52.654046Z",
    updated_at: "2021-10-02T00:56:16.389082Z",
    name: "ZilSwap",
    symbol: "ZWAP",
    address_bech32: "zil1p5suryq6q647usxczale29cu3336hhp376c627",
    icon: "https://meta.viewblock.io/ZIL.zil1p5suryq6q647usxczale29cu3336hhp376c627/logo",
    decimals: 12,
    website: "https://zilswap.io/",
    telegram: "https://t.me/zilswapcommunity",
    whitepaper: "",
    init_supply: 0,
    max_supply: 1000000,
    total_supply: 246409.09908924796,
    current_supply: 246409.09908924796,
    daily_volume: 13664599.674135325,
    current_liquidity: 197291829.11638013,
    liquidity_providers: 2106,
    supply_skip_addresses: "",
    viewblock_score: 100,
    unvetted: false,
    bridged: false,
    last_vote_start: "2021-08-20T16:00:00Z",
    last_vote_end: "2021-08-23T16:00:00Z",
    last_vote_hash: "QmZJpNuTxdfVoBsHgpf9UZ4MHvwf4PHGDFWTzdkT64gh3b",
    rewards: [
      {
        id: 1,
        created_at: "2021-05-13T14:09:54.056616Z",
        updated_at: "2021-10-02T00:53:46.984492Z",
        type: "liquidity",
        amount: 2260.64,
        max_individual_amount: 0,
        reward_token_id: 3,
        reward_token_address: "zil1p5suryq6q647usxczale29cu3336hhp376c627",
        reward_token_symbol: "ZWAP",
        frequency: 604800,
        frequency_type: "seconds",
        excluded_addresses: "",
        adjusted_total_contributed: "171759015616459600000",
        adjusted_total_contributed_share: "1",
        contract_address: "",
        contract_state: "",
        start_date: null,
        end_date: null,
        payment_day: 3,
      },
    ],
  },
  {
    id: 64,
    created_at: "2021-09-03T13:24:59.784806Z",
    updated_at: "2021-10-02T00:56:16.597287Z",
    name: "DeMons",
    symbol: "DMZ",
    address_bech32: "zil19lr3vlpm4lufu2q94mmjvdkvmx8wdwajuntzx2",
    icon: "https://meta.viewblock.io/ZIL.zil19lr3vlpm4lufu2q94mmjvdkvmx8wdwajuntzx2/logo",
    decimals: 18,
    website: "https://demons.world/",
    telegram: "https://t.me/de_monsters",
    whitepaper: "",
    init_supply: 16666660,
    max_supply: 16666660,
    total_supply: 16666660.000000002,
    current_supply: 2520784.3710045684,
    daily_volume: 792220.6934339302,
    current_liquidity: 17453231.466453552,
    liquidity_providers: 89,
    supply_skip_addresses:
      "zil14el9f3w5u26caeevy055rcr79amxd2kzdaf43d,zil1yqsfakjh9ufhtdt3e930daupa0z9elljuvypun,zil1e9e82jhdflk9ag5wak5gv0xg40p4l9f0ueulkv",
    viewblock_score: 52,
    unvetted: false,
    bridged: false,
    last_vote_start: "0001-01-01T00:00:00Z",
    last_vote_end: "0001-01-01T00:00:00Z",
    last_vote_hash: "",
    rewards: [
      {
        id: 55,
        created_at: "2021-05-13T14:09:54.056616Z",
        updated_at: "2021-10-02T00:53:47.110725Z",
        type: "liquidity",
        amount: 169.55,
        max_individual_amount: 0,
        reward_token_id: 3,
        reward_token_address: "zil1p5suryq6q647usxczale29cu3336hhp376c627",
        reward_token_symbol: "ZWAP",
        frequency: 604800,
        frequency_type: "seconds",
        excluded_addresses: "",
        adjusted_total_contributed: "7136504094566844000",
        adjusted_total_contributed_share: "1",
        contract_address: "",
        contract_state: "",
        start_date: null,
        end_date: null,
        payment_day: 3,
      },
      {
        id: 53,
        created_at: "2021-05-13T14:09:54.056616Z",
        updated_at: "2021-10-02T00:53:47.112767Z",
        type: "liquidity",
        amount: 8653.84,
        max_individual_amount: 0,
        reward_token_id: 64,
        reward_token_address: "zil19lr3vlpm4lufu2q94mmjvdkvmx8wdwajuntzx2",
        reward_token_symbol: "DMZ",
        frequency: 604800,
        frequency_type: "seconds",
        excluded_addresses: "0x1643e2067cedce8cc924e840ca6c7ed9c818fd2c",
        adjusted_total_contributed: "2575848094566842400",
        adjusted_total_contributed_share: "0.36093976272330386",
        contract_address: "",
        contract_state: "",
        start_date: null,
        end_date: null,
        payment_day: null,
      },
    ],
  },
  {
    id: 24,
    created_at: "2021-03-30T00:17:26.93548Z",
    updated_at: "2021-10-02T00:56:16.383282Z",
    name: "Elons",
    symbol: "Elons",
    address_bech32: "zil1lq3ghn3yaqk0w7fqtszv53hejunpyfyh3rx9gc",
    icon: "https://zilstream.sgp1.cdn.digitaloceanspaces.com/tokens/tokens/Elons.png",
    decimals: 3,
    website: "https://elons.io",
    telegram: "https://t.me/elonstoken",
    whitepaper: "",
    init_supply: 1000,
    max_supply: 1000,
    total_supply: 1000,
    current_supply: 517,
    daily_volume: 287625.2969038326,
    current_liquidity: 3460997.9427648983,
    liquidity_providers: 73,
    supply_skip_addresses: "zil1jgr575w69us9u5u0h8jt2wz0dhcdf4qwp574pd",
    viewblock_score: 10,
    unvetted: false,
    bridged: false,
    last_vote_start: "0001-01-01T00:00:00Z",
    last_vote_end: "0001-01-01T00:00:00Z",
    last_vote_hash: "",
    rewards: [
      {
        id: 39,
        created_at: "2021-05-13T14:09:54.056616Z",
        updated_at: "2021-10-02T00:53:47.16459Z",
        type: "liquidity",
        amount: 113.03,
        max_individual_amount: 0,
        reward_token_id: 3,
        reward_token_address: "zil1p5suryq6q647usxczale29cu3336hhp376c627",
        reward_token_symbol: "ZWAP",
        frequency: 604800,
        frequency_type: "seconds",
        excluded_addresses: "",
        adjusted_total_contributed: "286465543839223650",
        adjusted_total_contributed_share: "1",
        contract_address: "",
        contract_state: "",
        start_date: null,
        end_date: null,
        payment_day: 3,
      },
    ],
  },
  {
    id: 51,
    created_at: "2021-06-18T05:07:50.015333Z",
    updated_at: "2021-10-02T00:56:16.548542Z",
    name: "Sparda Wallet",
    symbol: "SPW",
    address_bech32: "zil1pqcev4ykxla0jhy3anx32lnqgv8xncd8q57ql2",
    icon: "https://meta.viewblock.io/ZIL.zil1pqcev4ykxla0jhy3anx32lnqgv8xncd8q57ql2/logo",
    decimals: 4,
    website: "https://www.spardawallet.com",
    telegram: "https://t.me/SpardaWalletOfficial",
    whitepaper:
      "https://drive.google.com/file/d/1nuJGF_OeAEtZDtyQzaBSoKQkClkFObRt/view?usp=sharing",
    init_supply: 100000000,
    max_supply: 100000000,
    total_supply: 100000000,
    current_supply: 15152309.351800006,
    daily_volume: 100859.78165837972,
    current_liquidity: 5431134.668211722,
    liquidity_providers: 79,
    supply_skip_addresses:
      "zil1eltv93h8sfmahxksd9tsx2phh8q6e05sgp0za5,zil1pz3c34daz8y92nwzha3jq736eq07n3sn5js3g9,zil10x8mpgfmegmv79yr6me7th924sh2u7g4dpt7l9,zil10xln7l3wvtergejsqjkmsews2gkqs73gt9tyey,zil14zxaksp0u4mt3z3cp68e9s0nqjczuzuhl2r2v9,zil1qccx9ejrp6g6n0ptwutc0s5ym0vquf6h4pfy90,zil1rjwwwhgayy6sks0gscj0tpgru0a442fg9xwndy",
    viewblock_score: 38,
    unvetted: false,
    bridged: false,
    last_vote_start: "0001-01-01T00:00:00Z",
    last_vote_end: "0001-01-01T00:00:00Z",
    last_vote_hash: "",
    rewards: [
      {
        id: 20,
        created_at: "2021-06-19T12:01:54.867782Z",
        updated_at: "2021-10-02T00:53:47.192729Z",
        type: "liquidity",
        amount: 300000,
        max_individual_amount: 0,
        reward_token_id: 51,
        reward_token_address: "zil1pqcev4ykxla0jhy3anx32lnqgv8xncd8q57ql2",
        reward_token_symbol: "SPW",
        frequency: 604800,
        frequency_type: "seconds",
        excluded_addresses: "0x08a388d5bd11c8554dc2bf63207a3ac81fe9c613",
        adjusted_total_contributed: "1723287063514007600",
        adjusted_total_contributed_share: "0.8693280545068733",
        contract_address: "",
        contract_state: "",
        start_date: null,
        end_date: null,
        payment_day: 0,
      },
      {
        id: 44,
        created_at: "2021-05-13T14:09:54.056616Z",
        updated_at: "2021-10-02T00:53:47.194981Z",
        type: "liquidity",
        amount: 113.03,
        max_individual_amount: 0,
        reward_token_id: 3,
        reward_token_address: "zil1p5suryq6q647usxczale29cu3336hhp376c627",
        reward_token_symbol: "ZWAP",
        frequency: 604800,
        frequency_type: "seconds",
        excluded_addresses: "",
        adjusted_total_contributed: "1982320775891148000",
        adjusted_total_contributed_share: "1",
        contract_address: "",
        contract_state: "",
        start_date: null,
        end_date: null,
        payment_day: 3,
      },
    ],
  },
  {
    id: 50,
    created_at: "2021-06-16T16:49:57.704286Z",
    updated_at: "2021-10-02T00:56:16.463071Z",
    name: "ZilStream",
    symbol: "STREAM",
    address_bech32: "zil1504065pp76uuxm7s9m2c4gwszhez8pu3mp6r8c",
    icon: "https://meta.viewblock.io/ZIL.zil1504065pp76uuxm7s9m2c4gwszhez8pu3mp6r8c/logo",
    decimals: 8,
    website: "https://zilstream.com",
    telegram: "https://t.me/zilstream",
    whitepaper: "",
    init_supply: 20000000,
    max_supply: 20000000,
    total_supply: 20000000,
    current_supply: 5934968.69956634,
    daily_volume: 34091.836096794235,
    current_liquidity: 8177807.597055104,
    liquidity_providers: 173,
    supply_skip_addresses: "zil1xsfg85lm208juqlxjjl82dyft3aad0y0w4sytn",
    viewblock_score: 100,
    unvetted: false,
    bridged: false,
    last_vote_start: "0001-01-01T00:00:00Z",
    last_vote_end: "0001-01-01T00:00:00Z",
    last_vote_hash: "",
    rewards: [
      {
        id: 32,
        created_at: "2021-05-13T14:09:54.056616Z",
        updated_at: "2021-10-02T00:53:46.85218Z",
        type: "liquidity",
        amount: 113.03,
        max_individual_amount: 0,
        reward_token_id: 3,
        reward_token_address: "zil1p5suryq6q647usxczale29cu3336hhp376c627",
        reward_token_symbol: "ZWAP",
        frequency: 604800,
        frequency_type: "seconds",
        excluded_addresses: "",
        adjusted_total_contributed: "7655455484501649000",
        adjusted_total_contributed_share: "1",
        contract_address: "",
        contract_state: "",
        start_date: null,
        end_date: null,
        payment_day: 3,
      },
      {
        id: 18,
        created_at: "2021-06-18T17:59:46.459649Z",
        updated_at: "2021-10-02T00:53:46.854306Z",
        type: "liquidity",
        amount: 24038,
        max_individual_amount: 0,
        reward_token_id: 50,
        reward_token_address: "zil1504065pp76uuxm7s9m2c4gwszhez8pu3mp6r8c",
        reward_token_symbol: "STREAM",
        frequency: 604800,
        frequency_type: "seconds",
        excluded_addresses: "0x1499856ca9a32e717e9e872923fd3f4740af99e7",
        adjusted_total_contributed: "6264836233188409000",
        adjusted_total_contributed_share: "0.8183492472618351",
        contract_address: "",
        contract_state: "",
        start_date: null,
        end_date: null,
        payment_day: 5,
      },
    ],
  },
  {
    id: 41,
    created_at: "2021-05-28T07:58:54.447251Z",
    updated_at: "2021-10-02T00:56:16.344233Z",
    name: "XCAD Network",
    symbol: "XCAD",
    address_bech32: "zil1h63h5rlg7avatnlzhfnfzwn8vfspwkapzdy2aw",
    icon: "https://meta.viewblock.io/ZIL.zil1h63h5rlg7avatnlzhfnfzwn8vfspwkapzdy2aw/logo",
    decimals: 6,
    website: "https://xcademytoken.com/",
    telegram: "https://t.me/xcademy",
    whitepaper: "",
    init_supply: 9980000,
    max_supply: 200000000,
    total_supply: 1500000,
    current_supply: 16923882.927058723,
    daily_volume: 1473281.3676628547,
    current_liquidity: 32460079.83987701,
    liquidity_providers: 82,
    supply_skip_addresses: "zil15utgalp0m5n9ve04kda770duq6lxtu8mx04t7d",
    viewblock_score: 100,
    unvetted: false,
    bridged: false,
    last_vote_start: "0001-01-01T00:00:00Z",
    last_vote_end: "0001-01-01T00:00:00Z",
    last_vote_hash: "",
    rewards: [
      {
        id: 33,
        created_at: "2021-05-13T14:09:54.056616Z",
        updated_at: "2021-10-02T00:53:46.790152Z",
        type: "liquidity",
        amount: 226.06,
        max_individual_amount: 0,
        reward_token_id: 3,
        reward_token_address: "zil1p5suryq6q647usxczale29cu3336hhp376c627",
        reward_token_symbol: "ZWAP",
        frequency: 604800,
        frequency_type: "seconds",
        excluded_addresses: "",
        adjusted_total_contributed: "5197051003352372000",
        adjusted_total_contributed_share: "1",
        contract_address: "",
        contract_state: "",
        start_date: null,
        end_date: null,
        payment_day: 3,
      },
    ],
  },
  {
    id: 12,
    created_at: "2021-03-06T10:17:35.897991Z",
    updated_at: "2021-10-02T00:56:16.257292Z",
    name: "ZilChess",
    symbol: "ZCH",
    address_bech32: "zil1s8xzysqcxva2x6aducncv9um3zxr36way3fx9g",
    icon: "https://meta.viewblock.io/ZIL.zil1s8xzysqcxva2x6aducncv9um3zxr36way3fx9g/logo",
    decimals: 6,
    website: "https://www.zilchess.com/",
    telegram: "",
    whitepaper: "https://www.zilchess.com/whitepaper/",
    init_supply: 500000,
    max_supply: 500000,
    total_supply: 500000,
    current_supply: 500000,
    daily_volume: 10528.525129286889,
    current_liquidity: 1527980.7648086147,
    liquidity_providers: 50,
    supply_skip_addresses: "zil1fuacrtr9x0e8z6u5kwxcuy7tmx2xfnlkhh4537",
    viewblock_score: 29,
    unvetted: false,
    bridged: false,
    last_vote_start: "2021-09-03T08:00:00Z",
    last_vote_end: "2021-09-08T08:00:00Z",
    last_vote_hash: "QmYavqAsWuzHsnwjERt5KFAp5CL7CQrWQN5R1sTrm72AJo",
    rewards: [
      {
        id: 43,
        created_at: "2021-05-13T14:09:54.056616Z",
        updated_at: "2021-10-02T00:53:47.005614Z",
        type: "liquidity",
        amount: 56.52,
        max_individual_amount: 0,
        reward_token_id: 3,
        reward_token_address: "zil1p5suryq6q647usxczale29cu3336hhp376c627",
        reward_token_symbol: "ZWAP",
        frequency: 604800,
        frequency_type: "seconds",
        excluded_addresses: "",
        adjusted_total_contributed: "567097225730535600",
        adjusted_total_contributed_share: "1",
        contract_address: "",
        contract_state: "",
        start_date: null,
        end_date: null,
        payment_day: 3,
      },
    ],
  },
  {
    id: 25,
    created_at: "2021-03-30T04:01:51.352408Z",
    updated_at: "2021-10-02T00:56:16.371894Z",
    name: "DuckDuck",
    symbol: "DUCK",
    address_bech32: "zil1c6akv8k6dqaac7ft8ezk5gr2jtxrewfw8hc27d",
    icon: "https://meta.viewblock.io/ZIL.zil1c6akv8k6dqaac7ft8ezk5gr2jtxrewfw8hc27d/logo",
    decimals: 2,
    website: "https://duck.community/",
    telegram: "https://t.me/zilduck",
    whitepaper: "https://duck.community/papers/DUCK%20-%20Widepaper.pdf",
    init_supply: 421,
    max_supply: 390.69,
    total_supply: 420.69,
    current_supply: 296.7,
    daily_volume: 72467.1380815013,
    current_liquidity: 2070623.7924657264,
    liquidity_providers: 64,
    supply_skip_addresses: "zil14jct2x5nw73rnwdy8g0kjs99k8z29mu0eccspg",
    viewblock_score: 29,
    unvetted: false,
    bridged: false,
    last_vote_start: "2021-08-27T11:00:00Z",
    last_vote_end: "2021-09-03T11:00:00Z",
    last_vote_hash: "Qmf5YChJ5zn2nQ9JE9C7r5ZYDLCGgoTM3pStGoqCSP7f1J",
    rewards: [
      {
        id: 47,
        created_at: "2021-05-13T14:09:54.056616Z",
        updated_at: "2021-10-02T00:53:47.044203Z",
        type: "liquidity",
        amount: 56.52,
        max_individual_amount: 0,
        reward_token_id: 3,
        reward_token_address: "zil1p5suryq6q647usxczale29cu3336hhp376c627",
        reward_token_symbol: "ZWAP",
        frequency: 604800,
        frequency_type: "seconds",
        excluded_addresses: "",
        adjusted_total_contributed: "78973008491680460",
        adjusted_total_contributed_share: "1",
        contract_address: "",
        contract_state: "",
        start_date: null,
        end_date: null,
        payment_day: 3,
      },
    ],
  },
  {
    id: 44,
    created_at: "2021-05-31T04:18:53.531403Z",
    updated_at: "2021-10-02T00:56:16.377196Z",
    name: "DogZilliqa",
    symbol: "DogZilliqa",
    address_bech32: "zil17hdu9r4h49d7gyh8s7zkeh3yatqft62uvsu8ep",
    icon: "https://meta.viewblock.io/ZIL.zil17hdu9r4h49d7gyh8s7zkeh3yatqft62uvsu8ep/logo",
    decimals: 4,
    website: "https://godzilliqadefi.medium.com/dogzilliqa-token-9141f33beba5",
    telegram: "",
    whitepaper: "",
    init_supply: 210,
    max_supply: 210,
    total_supply: 210,
    current_supply: 210,
    daily_volume: 1111.471708188283,
    current_liquidity: 46759.99076983503,
    liquidity_providers: 5,
    supply_skip_addresses: "",
    viewblock_score: 0,
    unvetted: true,
    bridged: false,
    last_vote_start: "0001-01-01T00:00:00Z",
    last_vote_end: "0001-01-01T00:00:00Z",
    last_vote_hash: "",
    rewards: [],
  },
  {
    id: 48,
    created_at: "2021-06-11T09:37:15.170417Z",
    updated_at: "2021-10-02T00:56:16.591193Z",
    name: "HODL",
    symbol: "HODL",
    address_bech32: "zil177u3jh5x4hqual22zy7s2ru3gc2y4yq5wnq2un",
    icon: "https://meta.viewblock.io/ZIL.zil177u3jh5x4hqual22zy7s2ru3gc2y4yq5wnq2un/logo",
    decimals: 0,
    website: "",
    telegram: "https://t.me/thehodltoken",
    whitepaper: "",
    init_supply: 21000000,
    max_supply: 21000000,
    total_supply: 21000000,
    current_supply: 737920,
    daily_volume: 2606.2366097909407,
    current_liquidity: 127699.16382561454,
    liquidity_providers: 4,
    supply_skip_addresses: "zil1d7vnr9c3p5zr9x59lam79rafcqqxnlpdnk3f0h",
    viewblock_score: 0,
    unvetted: true,
    bridged: false,
    last_vote_start: "0001-01-01T00:00:00Z",
    last_vote_end: "0001-01-01T00:00:00Z",
    last_vote_hash: "",
    rewards: [],
  },
  {
    id: 15,
    created_at: "2021-03-06T10:19:53.863007Z",
    updated_at: "2021-10-02T00:56:16.263429Z",
    name: "Zil Yield Farming",
    symbol: "ZYF",
    address_bech32: "zil1arrjugcg28rw8g9zxpa6qffc6wekpwk2alu7kj",
    icon: "https://meta.viewblock.io/ZIL.zil1arrjugcg28rw8g9zxpa6qffc6wekpwk2alu7kj/logo",
    decimals: 3,
    website: "",
    telegram: "",
    whitepaper: "",
    init_supply: 137850000,
    max_supply: 0,
    total_supply: 3926214.953,
    current_supply: 1175514.918,
    daily_volume: 1671.595697519367,
    current_liquidity: 15995.189028011864,
    liquidity_providers: 10,
    supply_skip_addresses:
      "zil1e3kfxrpejmhas8dkatsfzly6q3qkgxlwcas2jr,zil1x3f2ag3hjzhhr05dv0qu7cs4hktzt65hn2jt7q,zil1y2n9k6p4dw82u0tmh7rczq7m3hujs20tc5w97v,zil1nl3fcvnjtatprt3rwkyzyw8q8ny8g25md4vy3p,zil16tawqz6peu0xxprl7yy87ykyfjryyal684lth8,zil18jjq5z4m574939xufrnkhz6z0lmjhxan77zy2k,zil16a80vmt6c4p82x0fn06x0t8r3tjnq0tx0yeq0m,zil1ht2jh567xlph5tx40drc4etj982qjjayttd63f",
    viewblock_score: 0,
    unvetted: true,
    bridged: false,
    last_vote_start: "0001-01-01T00:00:00Z",
    last_vote_end: "0001-01-01T00:00:00Z",
    last_vote_hash: "",
    rewards: [],
  },
  {
    id: 8,
    created_at: "2021-03-06T10:13:45.135933Z",
    updated_at: "2021-10-02T00:56:16.650248Z",
    name: "Sergey Saved Link",
    symbol: "SERGS",
    address_bech32: "zil1ztmv5jhfpnxu95ts9ylup7hj73n5ka744jm4ea",
    icon: "https://meta.viewblock.io/ZIL.zil1ztmv5jhfpnxu95ts9ylup7hj73n5ka744jm4ea/logo",
    decimals: 5,
    website: "",
    telegram: "",
    whitepaper: "",
    init_supply: 1100000,
    max_supply: 1100000,
    total_supply: 1100000,
    current_supply: 787657.51392,
    daily_volume: 119.932324396702,
    current_liquidity: 1500.267228756824,
    liquidity_providers: 2,
    supply_skip_addresses:
      "zil1h52jzuf0522zv8xkslfhx8gl3wnttualsdecaz,zil1ys8u7hw9589tfc7kptva9euzl0d3v9m9vmezpu,zil1s2pmeq0umwchjsyafajhwfgyr450gt2f0zfxgm",
    viewblock_score: 0,
    unvetted: true,
    bridged: false,
    last_vote_start: "0001-01-01T00:00:00Z",
    last_vote_end: "0001-01-01T00:00:00Z",
    last_vote_hash: "",
    rewards: [],
  },
  {
    id: 43,
    created_at: "2021-05-30T08:25:08.237877Z",
    updated_at: "2021-10-02T00:56:16.603853Z",
    name: "Flat Lazy Ass Token",
    symbol: "FLAT",
    address_bech32: "zil133vrh59edllqz7guq7htfthjmj7gct5jxqqtl7",
    icon: "https://meta.viewblock.io/ZIL.zil133vrh59edllqz7guq7htfthjmj7gct5jxqqtl7/logo",
    decimals: 5,
    website: "",
    telegram: "https://t.me/Flattoken",
    whitepaper: "",
    init_supply: 1000000,
    max_supply: 1000000,
    total_supply: 1000000.0000000001,
    current_supply: 59822.67662000004,
    daily_volume: 331.696209448967,
    current_liquidity: 42779.440176365504,
    liquidity_providers: 3,
    supply_skip_addresses:
      "zil1a53zvzc9zzn59ly0fky8yaq3usy6xk8n5dmwac,zil1qmw3lhql5lw90dh5vt35xn45ykjynq74twe4g3,zil1kal22ajrtvfl07seskkdg5u2mkksdg0ngtrcex,zil1tkkq0t9s9sxttgacwgj3fx7hef77pzuqfnphry,zil16e74e3xaqjpn0kweuf6q592d79hlz55kj4jdal",
    viewblock_score: 0,
    unvetted: true,
    bridged: false,
    last_vote_start: "0001-01-01T00:00:00Z",
    last_vote_end: "0001-01-01T00:00:00Z",
    last_vote_hash: "",
    rewards: [],
  },
  {
    id: 5,
    created_at: "2021-03-06T07:56:12.099775Z",
    updated_at: "2021-10-02T00:56:16.488827Z",
    name: "BARTER",
    symbol: "BARTER",
    address_bech32: "zil17zvlqn2xamqpumlm2pgul9nezzd3ydmrufxnct",
    icon: "https://meta.viewblock.io/ZIL.zil17zvlqn2xamqpumlm2pgul9nezzd3ydmrufxnct/logo",
    decimals: 2,
    website: "https://cryptobarter.group/",
    telegram: "",
    whitepaper:
      "https://cryptobarter.group/wp-content/uploads/2020/12/whitepaper.pdf",
    init_supply: 10000000,
    max_supply: 10000000,
    total_supply: 10000000,
    current_supply: 865809.6199999996,
    daily_volume: 111.813455002518,
    current_liquidity: 58572.31225582165,
    liquidity_providers: 2,
    supply_skip_addresses:
      "zil1dqe3ljj6vezgwlhyrhyadppscvemxfslhdynal,zil103zgjytejj0yv5hszj9xzqvjm9lxv3v8zq6z07, zil1fwz6gq6x4785c3c227k9l22687t9hh6pxrp2xp,zil1jh3yq8dy4fxn34dfw55n75j20gamykkkxmywt8,zil1nt8t4fk3h2kxzn84qxst70ry95vjnpu5d8m26j",
    viewblock_score: 29,
    unvetted: true,
    bridged: false,
    last_vote_start: "0001-01-01T00:00:00Z",
    last_vote_end: "0001-01-01T00:00:00Z",
    last_vote_hash: "",
    rewards: [],
  },
  {
    id: 62,
    created_at: "2021-08-24T05:50:59.095637Z",
    updated_at: "2021-10-02T00:56:16.617493Z",
    name: "Silly and Foolish Person",
    symbol: "SIMP",
    address_bech32: "zil1aqu3cqd0nrlyvp3hms5gzqvpueedd2e806e9rs",
    icon: "https://meta.viewblock.io/ZIL.zil1aqu3cqd0nrlyvp3hms5gzqvpueedd2e806e9rs/logo",
    decimals: 4,
    website: "https://zilsimp.com/",
    telegram: "https://t.me/ZilSIMP",
    whitepaper: "",
    init_supply: 10000,
    max_supply: 10000,
    total_supply: 10000,
    current_supply: 7206.8327,
    daily_volume: 436.98273415874996,
    current_liquidity: 13427.49315559374,
    liquidity_providers: 4,
    supply_skip_addresses: "zil1fukk6c5n0chnjg58xmnmmz96w4tqa27mdrc3p2",
    viewblock_score: 14,
    unvetted: true,
    bridged: false,
    last_vote_start: "0001-01-01T00:00:00Z",
    last_vote_end: "0001-01-01T00:00:00Z",
    last_vote_hash: "",
    rewards: [
      {
        id: 28,
        created_at: "2021-08-25T03:30:37.802115Z",
        updated_at: "2021-10-02T00:53:47.300141Z",
        type: "liquidity",
        amount: 50,
        max_individual_amount: 0,
        reward_token_id: 62,
        reward_token_address: "zil1aqu3cqd0nrlyvp3hms5gzqvpueedd2e806e9rs",
        reward_token_symbol: "SIMP",
        frequency: 604800,
        frequency_type: "seconds",
        excluded_addresses: "",
        adjusted_total_contributed: "1637294996606305",
        adjusted_total_contributed_share: "1",
        contract_address: "",
        contract_state: "",
        start_date: null,
        end_date: null,
        payment_day: null,
      },
    ],
  },
  {
    id: 9,
    created_at: "2021-03-06T10:14:51.770682Z",
    updated_at: "2021-10-02T00:56:16.542433Z",
    name: "Shark",
    symbol: "SHRK",
    address_bech32: "zil17tsmlqgnzlfxsq4evm6n26txm2xlp5hele0kew",
    icon: "https://meta.viewblock.io/ZIL.zil17tsmlqgnzlfxsq4evm6n26txm2xlp5hele0kew/logo",
    decimals: 6,
    website:
      "https://bafybeihjzjcpghxq3aa6kupzzq3jcxjslsfxq43k2qkump6q2bu75cd5wy.ipfs.infura-ipfs.io/",
    telegram: "https://t.me/SHRKFINTECH",
    whitepaper: "",
    init_supply: 420000000,
    max_supply: 420000000,
    total_supply: 91230460,
    current_supply: 82027.54943800985,
    daily_volume: 110.37216943418,
    current_liquidity: 7994.111136240338,
    liquidity_providers: 5,
    supply_skip_addresses:
      "zil109vuy7hj9epl4zxse09vy6wrt04ytkcmw438zy,zil1vtjutgxcgn75g7279ncflxk6gh9j8gshcav0hd,zil1eadtael94ezfqesgzngfprk7lt4x5a7cslan0t,zil1yeh7jpv20savtd3katuj9zk2hu57um75jdsysz,zil1kz3xhggqtt2ezvsljesectaxdaj98h4de93cux",
    viewblock_score: 5,
    unvetted: true,
    bridged: false,
    last_vote_start: "0001-01-01T00:00:00Z",
    last_vote_end: "0001-01-01T00:00:00Z",
    last_vote_hash: "",
    rewards: [],
  },
  {
    id: 20,
    created_at: "2021-03-28T04:18:08.382786Z",
    updated_at: "2021-10-02T00:56:16.241524Z",
    name: "AXToken",
    symbol: "AXT",
    address_bech32: "zil1rk9vdfu2xsp7y4h24qg78n6ss23mxxge5slsv2",
    icon: "https://meta.viewblock.io/ZIL.zil1rk9vdfu2xsp7y4h24qg78n6ss23mxxge5slsv2/logo",
    decimals: 6,
    website: "",
    telegram: "",
    whitepaper: "https://axholding.medium.com/axt-tokenomics-6d32d0f3b20a",
    init_supply: 0,
    max_supply: 0,
    total_supply: 474535,
    current_supply: 409508.748342,
    daily_volume: 107.171163953236,
    current_liquidity: 3570.021502610358,
    liquidity_providers: 3,
    supply_skip_addresses:
      "zil1zf288hn2vmh2v7kfhrt0psmyzwfn4gggaqcua4,zil1pjrgzgde9fetpemfe2sjc7hwee3pzl3wgu4ahf,zil1u985tarkaxay8ghjcges9e33nghw0eaw0dv09d,zil1dhj60zx4au40mj5k83rfjg6klht5fx6q9xx850,zil1juwhe8kyz3lmchdnxvephuhq5x9jzcfjrhrp0f,zil1qfr7ny9n6es6v97e7a286llh7jnj4gtesj4jz4",
    viewblock_score: 19,
    unvetted: true,
    bridged: false,
    last_vote_start: "0001-01-01T00:00:00Z",
    last_vote_end: "0001-01-01T00:00:00Z",
    last_vote_hash: "",
    rewards: [],
  },
  {
    id: 27,
    created_at: "2021-04-01T02:56:44.674661Z",
    updated_at: "2021-10-02T00:56:16.510362Z",
    name: "COVID-19 Tracker",
    symbol: "COVID-19",
    address_bech32: "zil1g85eeqe8pl8g4ngqncl83m4d8yuyugvn6g9055",
    icon: "https://meta.viewblock.io/ZIL.zil1g85eeqe8pl8g4ngqncl83m4d8yuyugvn6g9055/logo",
    decimals: 8,
    website: "https://covid19tracking0.medium.com/",
    telegram: "",
    whitepaper: "",
    init_supply: 32181782,
    max_supply: 29360428,
    total_supply: 29360428,
    current_supply: 29360428,
    daily_volume: 202.95717460577998,
    current_liquidity: 161.30565110493,
    liquidity_providers: 1,
    supply_skip_addresses: "",
    viewblock_score: 5,
    unvetted: true,
    bridged: false,
    last_vote_start: "0001-01-01T00:00:00Z",
    last_vote_end: "0001-01-01T00:00:00Z",
    last_vote_hash: "",
    rewards: [],
  },
];

export default tokens;