import React from "react";
import { AvatarGroup, Flex, HStack, Text } from "@chakra-ui/react";
import { useAppColor } from "src/hooks";
import { loginSelector } from "src/redux/slices/loginSlice";
import { TokenLogo } from "src/atoms";
import { formatUsdt } from "src/lib/formatCurrency";
import { useAppSelector } from "src/redux/hooks";
import { overviewSelector } from "src/redux/slices/overviewSlice";

interface BalanceMinifyProps {
  balance: number;
  onToggleShow?: () => void;
  holdings?: string[];
}

const BalanceMinify: React.FC<BalanceMinifyProps> = ({
  balance,
  onToggleShow,
  holdings,
}) => {
  const { text3, main1, brand } = useAppColor();
  const { exchangeRate, isHidden } = useAppSelector(loginSelector);
  const { zilPriceInUsd } = useAppSelector(overviewSelector);

  return (
    <Flex
      direction={{ base: "column", md: "row" }}
      justifyContent="space-between"
      bg={main1}
      borderRadius="lg"
      p="24px"
      onClick={onToggleShow}
      cursor="pointer"
    >
      <HStack justifyContent="space-between">
        <Text fontWeight="semibold" color={text3}>
          Balance:
        </Text>
        <Text fontSize="xl" fontWeight="semibold" color={brand}>
          {formatUsdt(balance * zilPriceInUsd, exchangeRate, isHidden)}
        </Text>
      </HStack>
      {holdings && (
        <AvatarGroup size="xs" spacing={2} pt={{ base: "4", md: "0" }} max={8}>
          {[...new Set(holdings)].map((holding, index) => (
            <TokenLogo key={index} src={holding} />
          ))}
        </AvatarGroup>
      )}
    </Flex>
  );
};

export default BalanceMinify;
