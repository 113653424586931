import React from "react";
import {
  Box,
  Button,
  Center,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
  useBoolean,
} from "@chakra-ui/react";
import { useAppColor } from "src/hooks";
import { useTranslation } from "react-i18next";

import { IconArrowDown, IconFilledArrow, IconStart } from "src/assets/icons";
import TokenLogo from "src/atoms/TokenLogo";
import { Images } from "src/assets/images";
import { TextCurrency } from "src/molecules";

interface SelectAPairProps {
  onClick?: () => void;
  choiceBtn?: boolean;
}
const selectDataLst = [
  {
    swapName: "Name Swap",
    rating: 4,
    eta: "24mins",
    reviewCount: 198,
    isBestRate: true,
    isFixRate: false,
    saveAmount: 700,
    tokenAmount: "23,146",
    tokenLogo: Images.zil,
  },
  {
    swapName: "Name Swap",
    rating: 4,
    eta: "24mins",
    reviewCount: 198,
    isBestRate: false,
    isFixRate: true,
    saveAmount: 700,
    tokenAmount: "27,091",
    tokenLogo: Images.zil,
  },
  {
    swapName: "Name Swap",
    rating: 4,
    eta: "24mins",
    reviewCount: 198,
    isBestRate: true,
    isFixRate: false,
    saveAmount: 0,
    tokenAmount: "11,906",
    tokenLogo: Images.cex,
  },
  {
    swapName: "Name Swap",
    rating: 4,
    eta: "24mins",
    reviewCount: 198,
    isBestRate: false,
    isFixRate: false,
    saveAmount: 700,
    tokenAmount: "13,146",
    tokenLogo: Images.ezil,
  },
  {
    swapName: "Name Swap",
    rating: 4,
    eta: "24mins",
    reviewCount: 198,
    isBestRate: false,
    isFixRate: false,
    saveAmount: 700,
    tokenAmount: "90,146",
    tokenLogo: Images.gemini,
  },
  {
    swapName: "Name Swap",
    rating: 4,
    eta: "24mins",
    reviewCount: 198,
    isBestRate: false,
    isFixRate: false,
    saveAmount: 32,
    tokenAmount: "21,146",
    tokenLogo: Images.xsgd,
  },
];
const SelectAPair: React.FC<SelectAPairProps> = ({ onClick, choiceBtn }) => {
  const { t } = useTranslation(["swap", "common"]);
  const { brand, text1, text3, text5, main3, main4, main6 } = useAppColor();
  const [selectDataList] = React.useState(selectDataLst);
  const [show, setShow] = useBoolean(true);
  const [selectedIndex, setSelectedIndex] = React.useState(-1);

  return (
    <Box display={{ base: "none", lg: "block" }}>
      <Table variant="simple">
        <Tbody>
          {selectDataList.map((item, index) => {
            const {
              swapName,
              eta,
              reviewCount,
              isBestRate,
              isFixRate,
              saveAmount,
              tokenAmount,
              tokenLogo,
            } = item;

            if (index >= 5) {
              return <Tr key={index} />;
            }
            return (
              <Tr
                key={index}
                display="flex"
                border="1px solid"
                borderColor={main4}
                p="0"
                borderRadius="4px"
                mb="15px"
                justifyContent="space-between"
              >
                <Td borderBottom="none">
                  <Box display="flex">
                    <Text
                      fontSize="12px"
                      fontWeight="600"
                      lineHeight="16px"
                      mr="5px"
                    >
                      {swapName}
                    </Text>
                    <IconArrowDown cursor="pointer" />
                  </Box>
                  <IconStart size="4" />
                  <IconStart size="4" />
                  <IconStart size="4" />
                  <IconStart size="4" />
                  <IconStart size="4" fill="gray.200" />
                </Td>
                <Td borderBottom="none" w="15%">
                  {isBestRate && (
                    <Box mr="5px">
                      <Text
                        fontSize="12px"
                        fontWeight="600"
                        lineHeight="16px"
                        color="orange.500"
                        textTransform="uppercase"
                      >
                        {t("bestRate")}
                      </Text>
                    </Box>
                  )}
                  {isFixRate && (
                    <Box mr="5px">
                      <Text
                        fontSize="12px"
                        fontWeight="600"
                        lineHeight="16px"
                        color="green.500"
                        textTransform="uppercase"
                      >
                        {t("fixRate")}
                      </Text>
                    </Box>
                  )}
                </Td>
                <Td borderBottom="none">
                  <Box
                    display="flex"
                    fontSize="12px"
                    fontWeight="600"
                    lineHeight="16px"
                  >
                    <Text mr="5px" color={main6}>
                      {t("eta")}
                    </Text>
                    <Text mr="5px">{eta}</Text>
                  </Box>
                  <Box
                    display="flex"
                    fontSize="12px"
                    fontWeight="600"
                    lineHeight="16px"
                  >
                    <Text mr="5px" color={main6}>
                      {t("common:review")}:
                    </Text>
                    <Text mr="5px">{reviewCount}</Text>
                  </Box>
                </Td>
                {saveAmount > 0 ? (
                  <Td borderBottom="none">
                    {saveAmount > 0 && (
                      <Box display="flex" justifyContent="flex-end">
                        <Text
                          fontSize="10px"
                          fontWeight="500"
                          lineHeight="16px"
                          mr="5px"
                          color="orange.500"
                        >
                          {t("common:save")}
                        </Text>
                        <TextCurrency
                          fontSize="10px"
                          fontWeight="500"
                          lineHeight="16px"
                          color="orange.500"
                        >
                          {saveAmount}
                        </TextCurrency>
                      </Box>
                    )}
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Text
                        mr="5px"
                        fontSize="14px"
                        fontWeight="600"
                        lineHeight="20px"
                      >
                        {tokenAmount}
                      </Text>
                      <TokenLogo
                        src={tokenLogo}
                        w={{ base: "2", lg: "4" }}
                        h={{ base: "2", lg: "4" }}
                      />
                    </Box>
                  </Td>
                ) : (
                  <Td borderBottom="none" display="flex">
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Text
                        mr="5px"
                        fontSize="14px"
                        fontWeight="600"
                        lineHeight="20px"
                      >
                        23,146
                      </Text>
                      <TokenLogo
                        src={Images.zil}
                        w={{ base: "2", lg: "4" }}
                        h={{ base: "2", lg: "4" }}
                      />
                    </Box>
                  </Td>
                )}
                <Td borderBottom="none">
                  <Box display="flex">
                    <Button
                      backgroundColor={
                        selectedIndex === index && choiceBtn === false
                          ? brand
                          : "transparent"
                      }
                      color={
                        selectedIndex === index && choiceBtn === false
                          ? "black"
                          : text1
                      }
                      border="1px solid"
                      borderColor={main4}
                      onClick={() => {
                        setSelectedIndex(index);
                        !!onClick && onClick();
                      }}
                    >
                      {selectedIndex === index && choiceBtn === false
                        ? t("common:choice")
                        : t("common:exchange")}
                    </Button>
                  </Box>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      {selectDataList.length >= 5 && (
        <Center
          role="group"
          py="2"
          onClick={setShow.toggle}
          cursor="pointer"
          _hover={{ bg: main3 }}
        >
          <IconFilledArrow
            _groupHover={{ fill: text3 }}
            size="6"
            fill={text5}
            style={{ transform: `rotate(${show ? 0 : 180}deg)` }}
          />
        </Center>
      )}
    </Box>
  );
};

export default SelectAPair;
