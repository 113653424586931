import React from "react";
import {
  Divider,
  Flex,
  Stack,
  Icon,
  Link,
  Text,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Box,
  ButtonGroup,
  Avatar,
  Spacer,
} from "@chakra-ui/react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";
import "src/App.css";
import {
  AiOutlineTwitter,
  IoIosArrowDropdownCircle,
  FaTelegramPlane,
  RiLogoutCircleRFill,
} from "react-icons/all";
import useRouter from "src/hooks/useRouter";
import { Images } from "src/assets/images";
import {
  IconSwap,
  IconSendSidebar,
  IconLock,
  IconArrowDown,
  IconMembership,
  IconProfile,
  // IconReef,
} from "src/assets/icons";
import { NavLinkProps, WalletProps } from "src/constants/type";
import { ads_sidebar_list } from "src/constants/data";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import {
  changeCurrency,
  IWallet,
  loginSelector,
  logoutWallet,
  selectWallet,
} from "src/redux/slices/loginSlice";
import { clearData, overviewSelector } from "src/redux/slices/overviewSlice";
import { useTranslation } from "react-i18next";
import { formatUsdt } from "src/lib/formatCurrency";
import { isConnectZilpay } from "src/api/zilpayApi";
import ScrollBox from "src/atoms/ScrollBox";
import { useAppColor, useCampaign } from "src/hooks";
import { IExchangeRate } from "src/constants/exchangeRates";
import { handleGoLink, sortSavedWallet } from "src/utils";
import BannerNft from "src/atoms/BannerNft";
import ButtonTransak from "src/atoms/ButtonTransak";
interface SidebarProps {
  onAddWallet?(): void;
  onLock?(): void;
  onChangeTheme?(): void;
}

const Sidebar: React.FC<SidebarProps> = React.memo(
  ({ onAddWallet, onLock, onChangeTheme }: SidebarProps) => {
    const { t } = useTranslation(["common", "sidebar"]);
    const router = useRouter();
    const dispatch = useAppDispatch();
    const { brand, text2, main1, main4, ThemeIcon } = useAppColor();
    const { onCampaign } = useCampaign();

    const {
      currentWallet: _currentWallet,
      savedWallets,
      isHidden,
      exchangeRate,
      exchangeRates,
      isLoadingWallets,
    } = useAppSelector(loginSelector);

    const currentWallet =
      savedWallets.find(
        (wallet) => _currentWallet?.zilAddress === wallet.zilAddress
      ) || _currentWallet;

    const walletName = currentWallet?.name || currentWallet?.zilAddress || "";

    const { zilPriceInUsd } = useAppSelector(overviewSelector);

    const [selectedIndex, setSelectedIndex] = React.useState<number>(0);

    const handleLogout = () => {
      if (isLoadingWallets) {
        return;
      }
      dispatch(clearData());
      dispatch(logoutWallet(true));
    };

    const chooseWallet = (wallet: IWallet) => {
      if (isLoadingWallets || currentWallet?.zilAddress === wallet.zilAddress) {
        return;
      }
      dispatch(selectWallet(wallet));
      if (currentWallet?.zilAddress !== wallet.zilAddress) {
        router.push("/");
      }
    };

    React.useEffect(() => {
      if (
        !currentWallet &&
        router.location.pathname !== "/" &&
        !router.location.pathname.includes("/view-profile/")
      ) {
        router.push("/");
      }
    }, [currentWallet, router]);

    const features: NavLinkProps[] = [
      {
        label: "Profile",
        icon: IconProfile,
        pathname: "/profile",
      },
      // {
      //   label: "Reef",
      //   icon: IconReef,
      //   pathname: "/reef",
      // },
      {
        label: t("swap"),
        icon: IconSwap,
        pathname: "/swap",
      },
      {
        label: t("send"),
        icon: IconSendSidebar,
        pathname: "/send",
      },
      {
        label: t("membership"),
        icon: IconMembership,
        pathname: "/membership",
      },
    ];

    const data_features = [
      {
        icon: <IconLock />,
        aria_label: "Lock",
        onClick: onLock,
      },
      {
        icon: <ThemeIcon />,
        aria_label: "Theme",
        onClick: onChangeTheme,
      },
      // {
      //   icon: <HiOutlineRefresh />,
      //   aria_label: "Refresh",
      //   onClick: () => {},
      // },
    ];

    const onChangeCurrency = (currency: IExchangeRate) => {
      try {
        dispatch(changeCurrency(currency));
      } catch (error) {}
    };

    const NavLink = (props: NavLinkProps) => {
      const { icon, isActive, label, ...rest } = props;
      return (
        <Link
          display="block"
          py={2}
          px={3}
          borderRadius="md"
          noOfLines={1}
          isTruncated
          transition="all 0.3s"
          fontWeight="medium"
          lineHeight="1.5rem"
          aria-current={isActive ? "page" : undefined}
          color={label !== "Membership" ? "white" : brand}
          _hover={{
            bg: "gray.600",
          }}
          _activeLink={{
            bg: "gray.700",
          }}
          {...rest}
        >
          <HStack spacing="2">
            <Icon as={icon} boxSize="20px" />
            <Text mr="4" as="span">
              {label}
            </Text>
          </HStack>
        </Link>
      );
    };

    const Wallet = ({ item, onClick }: WalletProps) => {
      const { name, zilAddress, balance, avatar } = item;
      const connected = isConnectZilpay(zilAddress);
      const walletName = name || zilAddress;
      return (
        <MenuItem p={0}>
          <Box
            display="flex"
            flex={1}
            alignItems="center"
            justifyItems="center"
            cursor={isLoadingWallets ? "wait" : "pointer"}
            _hover={{
              bg: "gray.600",
            }}
            p="16px"
            bg="black"
            onClick={onClick}
          >
            <Image w="32px" h="32px" rounded="16px" mr="8px" src={avatar} />
            <Box>
              <Flex alignItems="center">
                <Text
                  fontSize="sm"
                  lineHeight="5"
                  fontWeight="semibold"
                  color="gray.50"
                  display="flex"
                >
                  {walletName.length > 13
                    ? walletName.substring(0, 6) +
                      "..." +
                      walletName.substring(walletName.length - 6)
                    : walletName}
                </Text>
                {connected && (
                  <Box ml="6px" w="6px" h="6px" rounded="6px" bg="green.500" />
                )}
              </Flex>
              <Box display="flex" justifyItems="center" alignItems="center">
                <Text
                  fontSize="sm"
                  lineHeight="4"
                  fontWeight="normal"
                  color="gray.50"
                >
                  {formatUsdt(balance * zilPriceInUsd, exchangeRate, isHidden)}
                </Text>
                <Text
                  ml="4px"
                  fontSize="sm"
                  lineHeight="4"
                  fontWeight="semibold"
                  color={connected ? "green.600" : "gray.50"}
                >
                  - {connected ? t("common:connected") : t("common:viewOnly")}
                </Text>
              </Box>
            </Box>
          </Box>
        </MenuItem>
      );
    };

    return (
      <Box width="256px" minW="256px" bg="gray.900">
        <Flex
          direction="column"
          px="16px"
          w="256px"
          height="100vh"
          position="fixed"
          color="white"
          pt={{ base: "0px", lg: "24px" }}
          pb="24px"
          bg="gray.900"
        >
          <Box display={{ base: "none", lg: "block" }}>
            <Box
              display="flex"
              alignItems="center"
              cursor="pointer"
              onClick={() => router.push("/")}
            >
              <Image w="32px" h="32px" rounded="8px" src={Images.logo} />
              <Text
                color="white"
                ml="16px"
                fontWeight="semibold"
                fontSize="3xl"
              >
                {"HeyAlfie"}
              </Text>
            </Box>
          </Box>
          <Box display={{ base: "block", xl: "none" }}>
            <Stack display="inline-flex" direction="row" spacing="4" my="4">
              <Menu placement="bottom-start">
                <MenuButton
                  pl="12px"
                  pr="16px"
                  px="8px"
                  rounded="24px"
                  variant="transparent"
                >
                  <Box display="flex" alignItems="center">
                    <Avatar
                      bg={main1}
                      src={exchangeRate.image}
                      size="sm"
                      icon={<Box fontSize="xx-small">No flag</Box>}
                    />
                    <Text
                      mx="8px"
                      fontSize="md"
                      lineHeight="6"
                      fontWeight="semibold"
                      textTransform="uppercase"
                    >
                      {exchangeRate.key}
                    </Text>
                    <IconArrowDown size="24" />
                  </Box>
                </MenuButton>
                <MenuList
                  py="0"
                  rounded={16}
                  bg="transparent"
                  border="none"
                  shadow="none"
                >
                  <ScrollBox
                    h="md"
                    w="165px"
                    borderRadius="16px"
                    shadow="ism"
                    bg="black"
                  >
                    {exchangeRates.map((item, index) => {
                      const { key, image } = item;
                      if (!image) {
                        return null;
                      }
                      return (
                        <MenuItem
                          key={index}
                          onClick={() => onChangeCurrency(item)}
                          pl="32px"
                          color="gray.500"
                          _hover={{
                            bg: "gray.600",
                          }}
                        >
                          <Avatar
                            bg={"transparent"}
                            src={image}
                            size="sm"
                            icon={
                              <Box fontSize="xx-small" color={text2}>
                                No flag
                              </Box>
                            }
                          />
                          <Text
                            ml="8px"
                            fontSize="md"
                            lineHeight="6"
                            fontWeight="semibold"
                            textTransform="uppercase"
                          >
                            {key}
                          </Text>
                        </MenuItem>
                      );
                    })}
                  </ScrollBox>
                </MenuList>
              </Menu>
              <ButtonGroup variant="outline">
                {data_features.map((i, idx) => {
                  return (
                    <Box
                      cursor="pointer"
                      display="flex"
                      aria-label={i.aria_label}
                      key={idx}
                      w="40px"
                      h="40px"
                      justifyContent="center"
                      alignItems="center"
                      rounded="20px"
                      border="1px"
                      borderColor={main4}
                      _hover={{}}
                      {...i}
                    >
                      {i.icon}
                    </Box>
                  );
                })}
              </ButtonGroup>
            </Stack>
            <ButtonTransak isButton />
          </Box>
          <Menu gutter={0} matchWidth>
            {currentWallet ? (
              <>
                <MenuButton bg="whiteAlpha.300" my="4" p="3" rounded="8px">
                  <Box display="flex" alignItems="center">
                    <Image
                      w="32px"
                      h="32px"
                      src={currentWallet.avatar}
                      mr="8px"
                    />
                    <Box
                      flex="1"
                      display="flex"
                      flexDirection="column"
                      justify="center"
                    >
                      <Text
                        color="white"
                        fontSize="sm"
                        lineHeight="5"
                        fontWeight="semibold"
                        textAlign="left"
                      >
                        {walletName.length > 11
                          ? walletName.substring(0, 7) +
                            "..." +
                            walletName.substring(walletName.length - 3)
                          : walletName}
                      </Text>
                      <Box display="flex" alignItems="center">
                        <Text
                          color="whiteAlpha.800"
                          fontSize="xs"
                          lineHeight="5"
                          fontWeight="400"
                        >
                          {formatUsdt(
                            currentWallet.balance * zilPriceInUsd,
                            exchangeRate,
                            isHidden
                          )}
                        </Text>
                        <Text
                          ml="4px"
                          fontSize="xs"
                          fontWeight="400"
                          color="whiteAlpha.800"
                        >
                          -{" "}
                          {isConnectZilpay(currentWallet.zilAddress)
                            ? t("common:connected")
                            : t("common:viewOnly")}
                        </Text>
                      </Box>
                    </Box>
                    <IoIosArrowDropdownCircle />
                  </Box>
                </MenuButton>
                <MenuList py="0px" overflow="hidden" border="none" bg="black">
                  <ScrollBox borderWidth={0} maxH="2xs" px="2" mt="4">
                    {sortSavedWallet(savedWallets).map((item, index) => (
                      <Wallet
                        onClick={() => chooseWallet(item)}
                        key={index}
                        item={item}
                      />
                    ))}
                  </ScrollBox>
                  <Divider borderColor="gray.600" />
                  <MenuItem
                    fontSize="sm"
                    fontWeight="bold"
                    lineHeight="5"
                    textAlign="center"
                    justifyContent="center"
                    py="16px"
                    color="gray.500"
                    _hover={{
                      bg: "gray.600",
                    }}
                    onClick={() => router.push("total-wallet")}
                  >
                    {t("manageAccounts")}
                  </MenuItem>
                  <Divider borderColor="gray.600" />
                  <MenuItem
                    fontSize="sm"
                    fontWeight="bold"
                    lineHeight="5"
                    color="gray.500"
                    justifyContent="center"
                    py="16px"
                    onClick={onAddWallet}
                    _hover={{
                      bg: "gray.600",
                    }}
                  >
                    {t("sidebar:addWallet")}
                  </MenuItem>
                  <Divider borderColor="gray.600" />
                  <MenuItem
                    fontSize="sm"
                    fontWeight="bold"
                    lineHeight="5"
                    color="gray.500"
                    justifyContent="center"
                    py="16px"
                    onClick={handleLogout}
                    _hover={{
                      bg: "gray.600",
                    }}
                  >
                    {t("logout")}
                  </MenuItem>
                </MenuList>
              </>
            ) : (
              <Box h="24" />
            )}
          </Menu>
          <Stack spacing={4}>
            <Stack spacing={1}>
              {features.map((item, index) => {
                const { pathname } = item;
                return (
                  <NavLink
                    key={index}
                    {...item}
                    isActive={pathname === router.location.pathname}
                    onClick={() => {
                      if (pathname) {
                        router.push(pathname);
                      }
                    }}
                  />
                );
              })}
            </Stack>
          </Stack>
          <Text
            fontSize="sm"
            lineHeight="4"
            fontWeight="medium"
            letterSpacing="wide"
            color="whiteAlpha.800"
            textTransform="uppercase"
            mt="4"
            ml="3"
          >
            {t("sidebar:social")}
          </Text>
          <Stack p="3" direction={"row"}>
            <Icon
              cursor="pointer"
              as={AiOutlineTwitter}
              boxSize="4"
              onClick={() => handleGoLink("twitter.com/heyalfie_io")}
            />
            <Icon
              cursor="pointer"
              as={FaTelegramPlane}
              boxSize="4"
              onClick={() => handleGoLink("t.me/heyalfie_io")}
            />
          </Stack>
          {false && (
            <Box my="24px">
              {ads_sidebar_list.map((item, index) => {
                const { image } = item;
                return <BannerNft image={image} key={index} />;
              })}
            </Box>
          )}
          {onCampaign && (
            <Box my="24px">
              <Swiper
                style={{ zIndex: 0 }}
                id="main"
                tag="section"
                wrapperTag="ul"
                spaceBetween={0}
                slidesPerView={1}
                onSlideChange={(swiper) => setSelectedIndex(swiper.activeIndex)}
                autoplay={true}
              >
                {ads_sidebar_list.map((item, index) => {
                  const { image } = item;
                  return (
                    <SwiperSlide key={index}>
                      <BannerNft image={image} />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
              <Box display="flex" justifyContent="center" mt="16px">
                {ads_sidebar_list.map((item, index) => {
                  return (
                    <Box
                      key={index}
                      w="8px"
                      h="8px"
                      rounded="full"
                      bg={selectedIndex === index ? "gray.50" : "gray.500"}
                      mx="4px"
                    />
                  );
                })}
              </Box>
            </Box>
          )}
          <Spacer />
          <Divider borderColor="whiteAlpha.400" />
          <NavLink
            cursor={isLoadingWallets ? "wait" : "pointer"}
            visibility={currentWallet ? "visible" : "hidden"}
            mt="2"
            label={t("logout")}
            icon={RiLogoutCircleRFill}
            onClick={handleLogout}
          />
        </Flex>
      </Box>
    );
  }
);
SwiperCore.use([Autoplay]);
export default Sidebar;
