import React from "react";
import Icon from "@chakra-ui/icon";
import { SvgIconProps } from "src/constants/type";

const SvgComponent: React.FC<SvgIconProps> = ({
  size,
  ...props
}: SvgIconProps) => {
  return (
    <Icon {...props} w={size} h={size} viewBox="0 0 16 16">
      <path d="M14.6667 3.33333V4.66667H12.6667H11.3333H9.33333V3.33333H11.3333H12.6667H14.6667ZM12.6667 12.6667H3.33333V3.33333H7.33333V2H3.33333C2.6 2 2 2.6 2 3.33333V12.6667C2 13.4 2.6 14 3.33333 14H12.6667C13.4 14 14 13.4 14 12.6667V8.66667H12.6667V12.6667ZM10 8.66667V11.3333H11.3333V8.66667H10ZM7.33333 11.3333H8.66667V6H7.33333V11.3333ZM6 11.3333V7.33333H4.66667V11.3333H6Z" />
    </Icon>
  );
};

const IconRemoveLiquidity = React.memo(SvgComponent);
export default IconRemoveLiquidity;
