import React from "react";
import { Flex, Text } from "@chakra-ui/layout";
import { PageLayout } from "src/templates";
import { useAppColor } from "src/hooks";

import Send from "./components/Send";
import { useTranslation } from "react-i18next";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  useDisclosure,
} from "@chakra-ui/react";
import ModalAlert from "src/molecules/ModalAlert";
import SendRight from "./components/SendRight";
interface SendPageProps {
  title: string;
}

const SendPage: React.FC<SendPageProps> = ({ title }) => {
  const { t } = useTranslation();
  const { text3 } = useAppColor();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalTitle, setModalTitle] = React.useState("");
  const [modalContent, setModalContent] = React.useState("");
  // const [modal, setModal] = React.useState<number>(0);

  const onOpenErrorModal = (type: number, title: string, content: string) => {
    // setModal(type);
    setModalTitle(title);
    setModalContent(content);
    onOpen();
  };

  return (
    <PageLayout title={title}>
      <Text
        display={{ base: "block", lg: "none" }}
        fontSize="xl"
        lineHeight="7"
        fontWeight="bold"
        color={text3}
        mb="4"
      >
        {t("send")}
      </Text>
      <Flex>
        <Send openAlertModal={onOpenErrorModal} />
        {false && <SendRight />}
      </Flex>
      <Modal onClose={onClose} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalAlert
            onClose={onClose}
            title={modalTitle}
            content={modalContent}
          />
        </ModalContent>
      </Modal>
    </PageLayout>
  );
};

export default SendPage;
