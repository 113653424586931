import React from "react";
import { useAppSelector } from "src/redux/hooks";
import { loginSelector } from "src/redux/slices/loginSlice";
import { sortSavedWallet } from "src/utils";
import HeaderWallet from "../HeaderWallet";
import BalanceMinify from "../BalanceMinify";
import { WalletToken } from "./WalletToken";
import { overviewSelector } from "src/redux/slices/overviewSlice";

export type WalletBalanceProps = {};

const WalletBalance: React.FC<WalletBalanceProps> = () => {
  const { savedWallets } = useAppSelector(loginSelector);
  const { tokenRates } = useAppSelector(overviewSelector);

  return (
    <>
      {sortSavedWallet(savedWallets).map((savedWallet, index) => {
        const { walletTokens } = savedWallet;
        let tmpTotalWallet = 0;

        let tmpHoldingWalletIcon = [] as string[];

        walletTokens &&
          walletTokens.forEach((walletToken) => {
            if (walletToken.balance && walletToken.balance > 0) {
              tmpHoldingWalletIcon.push(walletToken.token.icon);
            }
            const tokenRate = tokenRates.find(
              (tokenRate) =>
                tokenRate.token.address_bech32 ===
                walletToken.token.address_bech32
            );
            if (tokenRate && walletToken.balance) {
              if (
                walletToken.token.address_bech32 ===
                "zil1qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq9yf6pz"
              ) {
                tmpTotalWallet += walletToken.balance;
              } else {
                tmpTotalWallet += walletToken.balance * tokenRate.rate;
              }
            }
          });
        return (
          <HeaderWallet
            key={index}
            children={
              savedWallet.walletTokens && (
                <WalletToken walletTokens={savedWallet.walletTokens} />
              )
            }
            minifyChild={
              <BalanceMinify
                balance={tmpTotalWallet}
                holdings={tmpHoldingWalletIcon}
              />
            }
            savedWallet={savedWallet}
          />
        );
      })}
    </>
  );
};

export default WalletBalance;
