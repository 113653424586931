import React from "react";
import { Button, Text, Box, Flex } from "@chakra-ui/react";
import { ButtonUnlock, TokenLogo } from "src/atoms";
import { formatToken, formatUsdt } from "src/lib/formatCurrency";
import { handleGoLink } from "src/utils";
import { useTranslation } from "react-i18next";
import { useAppColor } from "src/hooks";
import { useAppSelector } from "src/redux/hooks";
import { loginSelector } from "src/redux/slices/loginSlice";
import { overviewSelector } from "src/redux/slices/overviewSlice";
import { MotionBox } from "src/molecules";

interface StakingItemMbProps {
  name: string;
  logo: string;
  ratio: number;
  stakingBalance?: number;
  reward: number;
  rate: number;
  seedNode?: number | string;
  symbol: string;
  link: string;
  stakeLogo: string;
  stakeSymbol?: string;
  rewardSymbol?: string;
}

const StakingItemMb: React.FC<StakingItemMbProps> = (props) => {
  const {
    name,
    logo,
    ratio,
    stakingBalance,
    reward,
    rate,
    symbol,
    link,
    seedNode,
    stakeLogo: _stakeLogo,
    stakeSymbol,
    rewardSymbol,
  } = props;
  const { t } = useTranslation(["overview", "common"]);
  const { zilPriceInUsd, tokenRates } = useAppSelector(overviewSelector);

  const { text6, main1, main3, main4, main6 } = useAppColor();
  const { isHidden, exchangeRate } = useAppSelector(loginSelector);
  // const [show, setShow] = useBoolean(false);

  const stakeToken = tokenRates.find(
    (token) => token.token.symbol === stakeSymbol
  );
  const rewardToken = tokenRates.find(
    (token) => token.token.symbol === rewardSymbol
  );

  const stakeLogo = stakeToken?.token.icon || _stakeLogo;
  const rewardLogo = rewardToken?.token.icon || _stakeLogo;
  const stakeRate = stakeToken?.rate || rate;
  const rewardRate = rewardToken?.rate || rate;

  return (
    <Box bg={main3} borderBottomWidth="1px" borderColor={main4}>
      <Flex alignItems="center" justifyContent="space-between" px="4" py="3">
        <Flex alignItems="center">
          {stakeSymbol && rewardSymbol ? (
            <Box position="relative" mr="2" w="9" h="9">
              <TokenLogo size="xs" src={rewardLogo} />
              <TokenLogo
                position="absolute"
                size="xs"
                src={stakeLogo}
                right="0"
                bottom="0"
              />
            </Box>
          ) : (
            <TokenLogo size="sm" mr="2" src={logo} />
          )}
          <Text fontSize="sm" fontWeight="semibold">
            {name}
          </Text>
        </Flex>
        {/* <IconFilledArrow
          _groupHover={{
            fill: text3,
          }}
          onClick={() => {
            setShow.toggle();
          }}
          cursor="pointer"
          size="6"
          fill={text5}
          style={{
            transform: `rotate(${show ? 0 : 180}deg)`,
          }}
        /> */}
      </Flex>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        pt="2"
        pb="6"
        px="4"
      >
        <Box>
          {ratio !== 0 && (
            <Text fontSize="xs" color={text6}>
              {t("common:fee")} | {ratio}%
            </Text>
          )}
          {!!seedNode && seedNode !== 0 && (
            <Text fontSize="xs" color={text6}>
              {t("overview:seedNode")} | {seedNode}%
            </Text>
          )}
        </Box>
        <Flex justifyContent="flex-end" alignItems="center">
          {false && (
            <Button
              variant="ghost"
              children={t("common:remove")}
              mr="4"
              size="sm"
            />
          )}
          {isHidden ? (
            <ButtonUnlock size="sm" />
          ) : (
            <Button
              children={t("common:add")}
              size="sm"
              onClick={() => handleGoLink(link)}
            />
          )}
        </Flex>
      </Flex>
      {/* {!!show && ( */}
      <MotionBox
        initial={{ opacity: 0 }}
        animate={{
          opacity: 1,
          transition: { duration: 0.5 },
        }}
        pl="16px"
        bg={main1}
      >
        <Flex
          justifyContent="space-between"
          pt="4"
          pb="2"
          borderBottomWidth="1px"
          borderColor={main4}
          pr="4"
        >
          <Text
            fontSize="xs"
            fontWeight="bold"
            textTransform="uppercase"
            color={main6}
          >
            {t("deposit")}
          </Text>
          <Flex display="flex" flexDirection="column" alignItems="flex-end">
            <Flex justifyContent="flex-end" alignItems="center">
              <Text fontSize="sm" fontWeight="semibold" mr="1">
                {stakingBalance
                  ? formatToken(stakingBalance, stakeSymbol || symbol, isHidden)
                  : "0.00 " + symbol}
              </Text>
              <TokenLogo w="4" h="4" src={stakeLogo} />
            </Flex>
            <Text fontSize="xs" color={text6}>
              ~
              {stakingBalance
                ? formatUsdt(
                    stakingBalance * zilPriceInUsd * stakeRate,
                    exchangeRate,
                    isHidden
                  )
                : "$0.00"}
            </Text>
          </Flex>
        </Flex>
        <Flex justifyContent="space-between" pt="4" pb="2" pr="4">
          <Text
            fontSize="xs"
            fontWeight="bold"
            textTransform="uppercase"
            color={main6}
          >
            {t("unclaimedReward")}
          </Text>
          <Flex display="flex" flexDirection="column" alignItems="flex-end">
            {reward > 0 ? (
              <>
                <Flex justifyContent="flex-end" alignItems="center">
                  <Text fontSize="sm" fontWeight="semibold" mr="1">
                    {formatToken(reward, rewardSymbol || symbol, isHidden)}
                  </Text>
                  <TokenLogo w="4" h="4" src={rewardLogo} />
                </Flex>
                <Text fontSize="xs" color={text6}>
                  ~{" "}
                  {formatUsdt(
                    reward * rewardRate * zilPriceInUsd,
                    exchangeRate,
                    isHidden
                  )}
                </Text>
              </>
            ) : (
              <TokenLogo size="xs" src={rewardLogo} />
            )}
          </Flex>
        </Flex>
      </MotionBox>
      {/* )} */}
    </Box>
  );
};

export default StakingItemMb;
