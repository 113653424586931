import React from "react";
import { IStakingInfo } from "src/constants/interfaces";
import RewardTableHeader from "./RewardTableHeader";

interface RewardTableProps {
  claimToken: () => void;
  stakeToken: IStakingInfo;
  last?: boolean;
}

const RewardTable: React.FC<RewardTableProps> = ({
  stakeToken,
  claimToken,
  last,
}) => {
  return (
    <RewardTableHeader
      last={last}
      stakeToken={stakeToken}
      claimToken={claimToken}
    />
  );
};

export default RewardTable;
