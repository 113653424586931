import React from "react";
import { useBoolean, Box } from "@chakra-ui/react";
import { useAppSelector } from "src/redux/hooks";
import { overviewSelector } from "src/redux/slices/overviewSlice";
import { IStakingInfo } from "src/constants/interfaces";
import StakingItemMb from "./StackingItemMb";
import StakingHeaderMb from "./StakingHeaderMb";
import { Swiper, SwiperSlide } from "swiper/react";

interface StakingTableMbProps {
  stakeToken: IStakingInfo;
  active?: boolean;
}

const StakingTableMb: React.FC<StakingTableMbProps> = ({
  stakeToken,
  active,
}) => {
  const [show, setShow] = useBoolean(false);

  const { tokenRates } = useAppSelector(overviewSelector);

  const { stakeList, symbol, pendingRewards } = stakeToken;

  const toggleShow = () => {
    setShow.toggle();
  };

  return (
    <React.Fragment>
      <StakingHeaderMb toggleShow={toggleShow} stakeToken={stakeToken} />
      {!!show && (
        <Box m="4">
          <Box display={{ base: "none", lg: "block" }}>
            {stakeList &&
              stakeList.map((token, index) => {
                const { stakingBalance } = token;
                let rate = 1;
                let reward = 0;
                if (pendingRewards) {
                  reward =
                    pendingRewards.find(
                      (pendingReward) => pendingReward.address === token.address
                    )?.pendingReward || 0;
                }
                if (
                  (!stakingBalance && reward <= 0 && !!active) ||
                  (stakingBalance &&
                    stakingBalance <= 0 &&
                    reward <= 0 &&
                    !!active)
                ) {
                  return <Box key={index} />;
                }
                const tokenRate = tokenRates.find(
                  (tokenRate) => tokenRate.token.symbol === symbol
                );

                if (tokenRate) {
                  rate = tokenRate.rate;
                }

                return (
                  <StakingItemMb
                    key={index}
                    {...stakeToken}
                    {...token}
                    reward={reward}
                    rate={rate}
                  />
                );
              })}
          </Box>

          <Box display={{ base: "block", lg: "block" }}>
            <Swiper slidesPerView={1} spaceBetween={20} width={220}>
              {stakeList &&
                stakeList.map((token, index) => {
                  let rate = 1;
                  let reward = 0;
                  if (pendingRewards) {
                    reward =
                      pendingRewards.find(
                        (pendingReward) =>
                          pendingReward.address === token.address
                      )?.pendingReward || 0;
                  }
                  const tokenRate = tokenRates.find(
                    (tokenRate) => tokenRate.token.symbol === symbol
                  );

                  if (tokenRate) {
                    rate = tokenRate.rate;
                  }

                  return (
                    <SwiperSlide key={index}>
                      <StakingItemMb
                        key={index}
                        {...stakeToken}
                        {...token}
                        reward={reward}
                        rate={rate}
                      />
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </Box>
        </Box>
      )}
    </React.Fragment>
  );
};

export default StakingTableMb;
