import React from "react";
import Icon from "@chakra-ui/icon";
import { SvgIconProps } from "src/constants/type";

const SvgComponent: React.FC<SvgIconProps> = ({
  size,
  ...props
}: SvgIconProps) => {
  return (
    <Icon {...props} w={size} h={size} viewBox="0 0 16 16">
      <path d="M3.21875 5.33312H11.3334V6.66645H3.21875L6.79475 10.2425L5.85208 11.1851L0.666748 5.99979L5.85208 0.814453L6.79475 1.75712L3.21875 5.33312Z" />
    </Icon>
  );
};

const IconArrow = React.memo(SvgComponent);
export default IconArrow;
