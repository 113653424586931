import React from "react";
import Icon from "@chakra-ui/icon";
import { SvgIconProps } from "src/constants/type";

const SvgComponent: React.FC<SvgIconProps> = ({
  size,
  ...props
}: SvgIconProps) => {
  return (
    <Icon {...props} w={size} h={size} viewBox="0 0 14 14">
      <path
        d="M10.7124 9.06266L9.88753 8.23666L10.7124 7.41183C10.9851 7.14142 11.2018 6.8198 11.35 6.46542C11.4981 6.11105 11.5748 5.7309 11.5757 5.34681C11.5765 4.96271 11.5015 4.58223 11.3549 4.22721C11.2083 3.87219 10.993 3.54962 10.7214 3.27802C10.4498 3.00643 10.1272 2.79115 9.77223 2.64455C9.41721 2.49795 9.03673 2.42292 8.65263 2.42376C8.26853 2.4246 7.88839 2.5013 7.53401 2.64945C7.17964 2.7976 6.85802 3.01429 6.58761 3.28708L5.76278 4.11249L4.93736 3.28766L5.76336 2.46283C6.52917 1.69701 7.56784 1.26678 8.65086 1.26678C9.73388 1.26678 10.7725 1.69701 11.5384 2.46283C12.3042 3.22864 12.7344 4.2673 12.7344 5.35033C12.7344 6.43335 12.3042 7.47201 11.5384 8.23783L10.7129 9.06266H10.7124ZM9.06269 10.7123L8.23728 11.5372C7.47146 12.303 6.4328 12.7332 5.34978 12.7332C4.26675 12.7332 3.22809 12.303 2.46228 11.5372C1.69646 10.7713 1.26624 9.73268 1.26624 8.64966C1.26624 7.56664 1.69646 6.52797 2.46228 5.76216L3.28769 4.93733L4.11253 5.76333L3.28769 6.58816C3.01491 6.85857 2.79822 7.18019 2.65007 7.53456C2.50192 7.88894 2.42522 8.26908 2.42438 8.65318C2.42354 9.03728 2.49857 9.41776 2.64517 9.77278C2.79177 10.1278 3.00704 10.4504 3.27864 10.722C3.55024 10.9936 3.87281 11.2088 4.22783 11.3554C4.58285 11.502 4.96333 11.5771 5.34742 11.5762C5.73152 11.5754 6.11167 11.4987 6.46604 11.3505C6.82042 11.2024 7.14204 10.9857 7.41244 10.7129L8.23728 9.88808L9.06269 10.7129V10.7123ZM8.64969 4.52491L9.47511 5.35033L5.35036 9.47449L4.52494 8.64966L8.64969 4.52549V4.52491Z"
        fill="currentColor"
      />
    </Icon>
  );
};

const IconLink = React.memo(SvgComponent);
export default IconLink;
