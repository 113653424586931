import React from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  Stack,
  Tab,
  Table,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Th,
  Thead,
  Tr,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ButtonUnlock } from "src/atoms";
import { IStakingInfo, IPoolInfo } from "src/constants/interfaces";
import { useAppColor } from "src/hooks";
import { useAppSelector } from "src/redux/hooks";
import { IWallet, loginSelector } from "src/redux/slices/loginSlice";
import { isEmptyStaking, handleGoLink } from "src/utils";
import LPReward from "./LPReward";
import LPZwapReward from "./LPZwapReward";
import RewardTable from "./RewardTable";
import RewardZil from "./RewardZil";
import { IModalClaimStakingItem } from "../Staking/ModalClaimStaking";

interface UnclaimRewardTokenProps {
  claimStaking?: (stakeToken: IModalClaimStakingItem[]) => void;
  onClaimStaking?: (stakingList: IStakingInfo) => void;
  savedWallet: IWallet;
}

const UnclaimRewardToken: React.FC<UnclaimRewardTokenProps> = ({
  onClaimStaking = () => {},
  claimStaking,
  savedWallet,
}) => {
  const { t } = useTranslation(["overview", "common", "profile"]);
  const { main1, main2, text5, main6, brand, main9 } = useAppColor();
  const { isHidden } = useAppSelector(loginSelector);

  const [tabIndex, setTabIndex] = React.useState<number>(0);
  const { allStakingList, allLiquidityPools } = savedWallet;
  if (!allStakingList) {
    return <React.Fragment />;
  }
  const availableList = allStakingList.filter(
    (stakingList) =>
      // !isEmptyStaking(stakingList) && !isEmptyReward(stakingList.pendingRewards)
      !isEmptyStaking(stakingList)
  );
  const isAllStakingRewardEmpty = () => availableList.length === 0;

  return (
    <Box>
      <Box overflow="auto">
        <Box bg={main1} borderRadius="8px">
          <Tabs
            index={tabIndex}
            onChange={(currentTabIndex) => setTabIndex(currentTabIndex)}
            variant="soft-rounded"
          >
            <Flex w="full" justifyContent="space-between" p="4">
              <TabList bg={main2} borderRadius="full">
                {[t("stakingBalance"), t("liquidityPoolBalance")].map(
                  (_, index) => (
                    <Tab
                      key={index}
                      _selected={{
                        bg: main9,
                        color: main1,
                      }}
                      children={_}
                    />
                  )
                )}
              </TabList>
              {tabIndex === 1 && <Button children={t("claimAll")} />}
            </Flex>
            <TabPanels>
              <TabPanel p="0">
                {isAllStakingRewardEmpty() ? (
                  <Center h="312px" borderRadius="12px" bg={main1}>
                    <Stack align="center">
                      <Text fontSize="18px" color={main6}>
                        {t("profile:emptyStake")}
                      </Text>
                      {isHidden ? (
                        <ButtonUnlock />
                      ) : (
                        <Button
                          bg={brand}
                          textColor={main2}
                          onClick={() => handleGoLink("stake.zilliqa.com")}
                          children={t("stakeZIL")}
                        />
                      )}
                    </Stack>
                  </Center>
                ) : (
                  <Table
                    variant="simple"
                    minW="2xl"
                    mb="3"
                    bg={main1}
                    borderRadius="12px"
                  >
                    <Thead>
                      <Tr bg={main1} color={text5}>
                        <Th w="80%">{t("seedNode")}</Th>
                        <Th w="full" isNumeric>
                          {t("profile:unclaimedReward")}
                        </Th>
                        <Th />
                      </Tr>
                    </Thead>
                    <Tbody>
                      {availableList.map((stakingList: IStakingInfo, index) => {
                        return stakingList.name === "Zillion" ? (
                          <RewardZil
                            last={index === availableList.length - 1}
                            key={index}
                            stakeToken={stakingList}
                            claimStaking={claimStaking}
                          />
                        ) : (
                          <RewardTable
                            last={index === availableList.length - 1}
                            key={index}
                            stakeToken={stakingList}
                            claimToken={() => onClaimStaking(stakingList)}
                          />
                        );
                      })}
                    </Tbody>
                  </Table>
                )}
              </TabPanel>
              <TabPanel p="0">
                {false ? (
                  <Center h="312px" borderRadius="12px" bg={main1}>
                    <Stack align="center">
                      <Text fontSize="18px" color={main6}>
                        {t("profile:emptyStake")}
                      </Text>
                      {isHidden ? (
                        <ButtonUnlock />
                      ) : (
                        <Button
                          bg={brand}
                          textColor={main2}
                          onClick={() => handleGoLink("stake.zilliqa.com")}
                          children={t("stakeZIL")}
                        />
                      )}
                    </Stack>
                  </Center>
                ) : (
                  <Table
                    variant="simple"
                    minW="2xl"
                    mb="3"
                    bg={main1}
                    borderRadius="12px"
                  >
                    <Thead>
                      <Tr bg={main1} color={text5}>
                        <Th />
                        <Th w="80%">{t("seedNode")}</Th>
                        <Th w="full" isNumeric>
                          {t("profile:unclaimedReward")}
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {allLiquidityPools &&
                        allLiquidityPools.map(
                          (liquidityPool: IPoolInfo, index) => {
                            if (index === 0) {
                              return (
                                <LPZwapReward
                                  key={index}
                                  poolInfo={liquidityPool}
                                />
                              );
                            }
                            return (
                              <LPReward key={index} poolInfo={liquidityPool} />
                            );
                          }
                        )}
                    </Tbody>
                  </Table>
                )}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Box>
    </Box>
  );
};

export default UnclaimRewardToken;
