import React from "react";
import { Text, Switch, Box } from "@chakra-ui/react";
import { useAppColor } from "src/hooks";

import { SettingProps } from "src/constants/type";
const Setting: React.FC<SettingProps> = ({ title, icon, last, ...rest }) => {
  const { brand, text4, text5, main3 } = useAppColor();

  return (
    <Box
      py="18px"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      borderBottomWidth={last ? 0 : "0.5px"}
      borderBottomColor={main3}
    >
      <Box display="flex" alignItems="center" color={text4} direction="row">
        {icon}
        <Text
          ml="12px"
          fontSize="sm"
          lineHeight="5"
          fontWeight="semibold"
          color={text4}
        >
          {title}
        </Text>
      </Box>
      <Switch
        size="lg"
        {...rest}
        h="28px"
        colorScheme="unset"
        borderRadius="24"
        bg={text5}
        _checked={{ bg: brand }}
      />
    </Box>
  );
};

export default Setting;
