import React from "react";
import {
  Box,
  Button,
  Center,
  Stack,
  Table,
  TableContainer,
  Th,
  Thead,
  Tr,
  Text,
  Modal,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { ButtonUnlock } from "src/atoms";
import { IStakingInfo } from "src/constants/interfaces";
import { handleGoLink, isEmptyStaking } from "src/utils";
import StakingZilMb from "./StakingZilMb";
import StakingTableMb from "./StakingTableMb";
import StakingZil from "./StakingZil";
import StakingTable from "./StakingTable";
import { useTranslation } from "react-i18next";
import { useAppColor } from "src/hooks";
import { IWallet, loginSelector } from "src/redux/slices/loginSlice";
import { useAppSelector } from "src/redux/hooks";
import { ProfileModalEnum } from "src/constants/enum";
import ModalClaimStaking, { IModalClaimStakingItem } from "./ModalClaimStaking";
import ModalClaim from "./ModalClaim";

interface StakingTokenProps {
  savedWallet: IWallet;
  active?: boolean;
}

const StakingToken: React.FC<StakingTokenProps> = ({ savedWallet, active }) => {
  const { t } = useTranslation(["overview", "common", "profile"]);
  const { main1, main2, text5, main3, main6, brand } = useAppColor();
  const { onClose, isOpen, onOpen } = useDisclosure();
  const [modal, setModal] = React.useState<ProfileModalEnum>();
  const [selectedStake, setSelectedStake] = React.useState<
    IModalClaimStakingItem[]
  >([]);
  const [stakeToken, setStakeToken] = React.useState<IStakingInfo>({
    name: "",
    link: "",
    stakeLogo: "",
    stakeList: [],
    apy: 0,
    symbol: "ZIL",
    pendingRewards: [],
    subName: "",
    unstakingPending: [],
  });
  const { isHidden } = useAppSelector(loginSelector);
  const { zilAddress, allStakingList } = savedWallet;
  const availableList = allStakingList
    ? allStakingList.filter(
        (stakingList) => !isEmptyStaking(stakingList) || !active
      )
    : ([] as IStakingInfo[]);

  const isAllEmpty = () => availableList.length === 0;
  const claimStaking = (stakeToken: IModalClaimStakingItem[]) => {
    setSelectedStake(stakeToken);
    setModal(ProfileModalEnum.ClaimZil);
    onOpen();
  };

  const onClaimStaking = (stakingList: IStakingInfo) => {
    setModal(ProfileModalEnum.ClaimStaking);
    setStakeToken(stakingList);
    onOpen();
  };
  return (
    <Box>
      {isAllEmpty() ? (
        <Center h="312px" borderRadius="12px" bg={main3}>
          <Stack align="center">
            <Text fontSize="18px" color={main6}>
              {t("profile:emptyStake")}
            </Text>
            {isHidden ? (
              <ButtonUnlock />
            ) : (
              <Button
                w="122px"
                bg={brand}
                textColor={main2}
                onClick={() => handleGoLink("stake.zilliqa.com")}
                children={t("stakeZIL")}
              />
            )}
          </Stack>
        </Center>
      ) : (
        <Box overflow="auto">
          <Box display={{ base: "none", lg: "block" }}>
            <TableContainer>
              <Table
                variant="simple"
                minW="2xl"
                mb="3"
                bg={main1}
                borderRadius="12px"
              >
                <Thead>
                  <Tr bg={main1} color={text5}>
                    <Th>Asset</Th>
                    <Th isNumeric>APY</Th>
                    <Th isNumeric>Deposits</Th>
                    <Th isNumeric>Unclaimed Reward</Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                {availableList.map((stakingList: IStakingInfo, index) => {
                  if (stakingList.name === "Zillion") {
                    return (
                      <StakingZil
                        active={active}
                        last={index === availableList.length - 1}
                        key={index}
                        stakeToken={stakingList}
                        claimStaking={claimStaking}
                        walletAddress={zilAddress}
                      />
                    );
                  }
                  return (
                    <StakingTable
                      active={active}
                      last={index === availableList.length - 1}
                      key={index}
                      stakeToken={stakingList}
                      claimToken={() => onClaimStaking(stakingList)}
                    />
                  );
                })}
              </Table>
            </TableContainer>
          </Box>
          <Box
            display={{ base: "block", lg: "none" }}
            borderRadius="12px"
            borderWidth={isAllEmpty() ? 0 : 1}
            overflow="auto"
            bg={main1}
            borderColor={main3}
          >
            {availableList.map((stakingList: IStakingInfo, index) => {
              if (index === 0) {
                return (
                  <StakingZilMb
                    active={active}
                    key={index}
                    stakeToken={stakingList}
                    claimStaking={claimStaking}
                  />
                );
              }
              return (
                <StakingTableMb
                  active={active}
                  key={index}
                  stakeToken={stakingList}
                  claimToken={() => onClaimStaking(stakingList)}
                />
              );
            })}
          </Box>
        </Box>
      )}
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="3xl"
        scrollBehavior="outside"
      >
        <ModalOverlay />
        <ModalContent>
          {modal === ProfileModalEnum.ClaimZil ? (
            <ModalClaimStaking
              claimStakingItems={selectedStake}
              onClose={onClose}
            />
          ) : modal === ProfileModalEnum.ClaimStaking ? (
            <ModalClaim stakeToken={stakeToken} />
          ) : (
            <div />
          )}
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default StakingToken;
