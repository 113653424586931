import React from "react";
import { Box, Stack } from "@chakra-ui/layout";
import { DownloadIcon } from "@chakra-ui/icons";
import {
  Popover,
  PopoverTrigger,
  Button,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  Select,
  Text,
} from "@chakra-ui/react";
import { useAppColor } from "src/hooks";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
//@ts-expect-error
import { CSVLink } from "react-csv";
import { HistorySpec } from "src/constants/type";
import { formatToken } from "src/lib/formatCurrency";
import { BN } from "@zilliqa-js/zilliqa";

interface TimeRange {
  start: Date;
  end: Date;
}

interface DownloadCsvProps {
  listTransaction?: HistorySpec[];
}

const DownloadCsv: React.FC<DownloadCsvProps> = ({ listTransaction }) => {
  const { t } = useTranslation("history");
  const popoverRef = React.useRef<any>(null);
  const { text4, main6 } = useAppColor();

  const [value, setValue] = React.useState<string>("0");

  const [csvData, setCsvData] = React.useState<any[]>([]);

  const QaRate = React.useMemo(() => new BN("1000000000"), []);

  const minusMonth = (num: number) => {
    return new Date(new Date().setMonth(new Date().getMonth() - num));
  };

  const options: TimeRange[] = [
    {
      start: minusMonth(1),
      end: new Date(),
    },
    {
      start: minusMonth(2),
      end: minusMonth(1),
    },
    {
      start: minusMonth(3),
      end: minusMonth(2),
    },
    {
      start: minusMonth(4),
      end: minusMonth(3),
    },
  ];

  const QaToZil = React.useCallback(
    (amount?: string) => {
      return amount
        ? new BN(amount).div(QaRate).toNumber()
        : new BN("0").toNumber();
    },
    [QaRate]
  );

  React.useEffect(() => {
    if (listTransaction) {
      setCsvData(
        listTransaction.map((e) => {
          return {
            type: e.tag,
            from: e.from,
            to: e.to,
            value: formatToken(QaToZil(e.value) / 1000),
            fee: formatToken(QaToZil(e.fee) / 1000),
          };
        })
      );
    }
  }, [listTransaction, QaToZil]);

  const csvHeaders = [
    { label: "Hash", key: "type" },
    { label: "From", key: "from" },
    { label: "To", key: "to" },
    { label: "Value", key: "value" },
    { label: "Fee", key: "fee" },
  ];

  const csvReport = {
    filename: `HistoryReport.csv`,
    headers: csvHeaders,
    data: csvData,
  };

  return (
    <Popover placement="bottom-end" initialFocusRef={popoverRef}>
      <PopoverTrigger>
        <Button
          display="none"
          color={main6}
          variant="outline"
          children={t("Export")}
          leftIcon={<DownloadIcon />}
        />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody p="4">
          <Stack spacing="4">
            <Box ref={popoverRef}>
              <Text fontSize="sm" fontWeight="semibold" color={text4}>
                {t("downloadCsv")}
              </Text>
              <Text fontSize="xs" color={text4}>
                {t("downloadCsvDescription")}
              </Text>
            </Box>
            <Select
              value={value}
              onChange={(event) => setValue(event.target.value)}
            >
              {options.map((option, index) => {
                const start = dayjs(option.start).format("MMM DD, YYYY");
                const end = dayjs(option.end).format("MMM DD, YYYY");
                return (
                  <option key={index} value={index.toString()}>
                    {start + " - " + end}
                  </option>
                );
              })}
            </Select>
            <CSVLink {...csvReport}>
              <Button children={t("download")} w="full" />
            </CSVLink>
          </Stack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default DownloadCsv;
