import React from "react";
import { Text, Box, Flex, SimpleGrid } from "@chakra-ui/react";
import { NFTItem } from "src/atoms";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { useAppColor, useRouter } from "src/hooks";
import { useAppSelector } from "src/redux/hooks";
import { overviewSelector } from "src/redux/slices/overviewSlice";
import { useTranslation } from "react-i18next";
import { ICollection, INft } from "src/constants/interfaces";
import ViewCollection from "./ViewCollection";
import { loginSelector } from "src/redux/slices/loginSlice";

interface WalletNFTProps {
  searchText: string;
}

const WalletNFT: React.FC<WalletNFTProps> = ({ searchText }) => {
  const { t } = useTranslation(["overview", "common"]);
  const router = useRouter();
  const { text3, text6, main1, main3 } = useAppColor();

  const { collections, heyAlfieNfts } = useAppSelector(overviewSelector);

  const { currentWallet } = useAppSelector(loginSelector);
  const [resultCollections, setResultCollections] =
    React.useState<ICollection[]>(collections);

  const [limit, setLimit] = React.useState<number>(4);

  const isEmptyCollections = () =>
    collections.length === 0 &&
    heyAlfieNfts.length === 0 &&
    (!currentWallet ||
      !currentWallet.pinNfts ||
      currentWallet.pinNfts.length === 0);

  React.useEffect(() => {
    if (searchText === "" || searchText === null || searchText === undefined) {
      setResultCollections(collections);
    } else {
      let data = [];
      for (let i = 0; i < collections.length; i++) {
        if (
          collections[i].title.toLowerCase().includes(searchText.toLowerCase())
        ) {
          data.push(collections[i]);
        }
      }
      setResultCollections(data);
    }
  }, [searchText, collections]);

  const onLoadMore = React.useCallback(() => {
    if (limit > collections.length) {
      return;
    }
    setLimit(limit + 1);
  }, [limit, collections]);

  window.onscroll = () => {
    if (
      window.innerHeight + window.scrollY + 0.5 >=
      document.body.offsetHeight
    ) {
      onLoadMore();
    }
  };

  return (
    <Box>
      {isEmptyCollections() ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          h="312px"
          bg={main1}
          borderRadius="12px"
          borderWidth="1px"
          fontSize="lg"
          lineHeight="7"
          borderColor={main3}
          color={text6}
        >
          {t("noneNft")}
        </Box>
      ) : (
        <>
          {currentWallet &&
            currentWallet.pinNfts &&
            currentWallet.pinNfts.length > 0 && (
              <React.Fragment>
                <Flex justifyContent="space-between">
                  <Text
                    fontSize="lg"
                    lineHeight="7"
                    fontWeight="semibold"
                    color={text3}
                  >
                    {"Pinned NFTs"}
                  </Text>
                </Flex>
                {/* For wide screen */}
                <Box py="8" display={{ base: "none", lg: "block" }}>
                  <SimpleGrid columns={5} spacing="4">
                    {currentWallet &&
                      currentWallet.pinNfts &&
                      currentWallet.pinNfts.map((pinNft: INft, index) => {
                        return (
                          <NFTItem
                            isPinned={true}
                            initNft={pinNft}
                            key={index}
                          />
                        );
                      })}
                  </SimpleGrid>
                </Box>
                {/* For small screen */}
                <Box py="8" display={{ base: "block", lg: "none" }}>
                  <Swiper slidesPerView={1} spaceBetween={20} width={220}>
                    {currentWallet &&
                      currentWallet.pinNfts &&
                      currentWallet.pinNfts.map((pinNft: INft, index) => {
                        return (
                          <SwiperSlide key={index}>
                            <NFTItem
                              initNft={pinNft}
                              w="full"
                              isPinned={true}
                            />
                          </SwiperSlide>
                        );
                      })}
                  </Swiper>
                </Box>
              </React.Fragment>
            )}
          {resultCollections &&
            resultCollections.map((collection, index) => {
              if (index > limit - 1) {
                return <React.Fragment key={index} />;
              }
              return (
                <React.Fragment key={index}>
                  <Text
                    fontSize="lg"
                    lineHeight="7"
                    fontWeight="semibold"
                    color={text3}
                  >
                    {collection.title}
                  </Text>

                  {/* For wide screen */}
                  <Box py="8" display={{ base: "none", lg: "block" }}>
                    <SimpleGrid columns={5} spacing={4}>
                      {collection.smartContracts &&
                        collection.smartContracts.map((smartContract, idx) => {
                          if (idx < 4) {
                            return (
                              <NFTItem
                                smartContract={smartContract}
                                key={idx}
                                w="full"
                              />
                            );
                          }
                          return <React.Fragment key={idx} />;
                        })}

                      {collection.smartContracts.length > 4 && (
                        <ViewCollection
                          onClick={() =>
                            router.push({
                              pathname: "/nft",
                              state: collection,
                            })
                          }
                        />
                      )}
                    </SimpleGrid>
                  </Box>
                  {/* For small screen */}
                  <Box py="8" display={{ base: "block", lg: "none" }}>
                    <Swiper slidesPerView={1} spaceBetween={20} width={220}>
                      {collection.smartContracts &&
                        collection.smartContracts.map((i, idx) => {
                          if (idx < 5) {
                            return (
                              <SwiperSlide key={idx}>
                                <NFTItem smartContract={i} w="full" />
                              </SwiperSlide>
                            );
                          }
                          return <React.Fragment key={idx} />;
                        })}
                      {collection.smartContracts.length > 5 && (
                        <SwiperSlide>
                          <ViewCollection
                            h="auto"
                            onClick={() =>
                              router.push({
                                pathname: "/nft",
                                state: collection,
                              })
                            }
                          />
                        </SwiperSlide>
                      )}
                    </Swiper>
                  </Box>
                </React.Fragment>
              );
            })}
        </>
      )}
    </Box>
  );
};

export default WalletNFT;
