import React from "react";
import { Box, Button, Center, Table, Text } from "@chakra-ui/react";
import { useAppColor } from "src/hooks";
import { useAppSelector } from "src/redux/hooks";
import { overviewSelector } from "src/redux/slices/overviewSlice";
import { IModalClaimStakingItem } from "./components/ModalClaimStaking";
import { useTranslation } from "react-i18next";
import { handleGoLink, isEmptyStaking } from "src/utils";
import { StakingTable, StakingZil } from "./components/Staking";
import { loginSelector } from "src/redux/slices/loginSlice";
import { ButtonUnlock } from "src/atoms";
import { IStakingInfo } from "src/constants/interfaces";
import StakingZilMb from "./components/Staking/StakingZilMb";
import StakingTableMb from "./components/Staking/StakingTableMb";

export type StakingBalanceProps = {
  claimStaking?: (stakeToken: IModalClaimStakingItem[]) => void;
  claimCarbon?: () => void;
  addStaking?: (stakeToken: any, token: any) => void;
  onClaimStaking?: (stakingList: IStakingInfo) => void;
};

export const StakingBalance: React.FC<StakingBalanceProps> = ({
  onClaimStaking = () => {},
  claimStaking,
}) => {
  const { t } = useTranslation(["overview", "common"]);
  const { main1, main3 } = useAppColor();
  const { isHidden } = useAppSelector(loginSelector);

  const { allStakingList } = useAppSelector(overviewSelector);

  const availableList = allStakingList.filter(
    (stakingList) => !isEmptyStaking(stakingList)
  );

  const isAllEmpty = () => availableList.length === 0;

  return (
    <Box>
      <Text my="8" fontSize="xl" fontWeight="bold">
        {t("stakingBalance")}
      </Text>
      <Box overflow="auto">
        {isAllEmpty() && (
          <Center p="1">
            {isHidden ? (
              <ButtonUnlock />
            ) : (
              <Button
                onClick={() => handleGoLink("stake.zilliqa.com")}
                children={t("stakeZIL")}
              />
            )}
          </Center>
        )}
        <Box display={{ base: "none", lg: "block" }}>
          <Table
            variant="simple"
            minW="2xl"
            mb="3"
            bg={main1}
            borderRadius="lg"
          >
            {availableList.map((stakingList: IStakingInfo, index) => {
              if (stakingList.name === "Zillion") {
                return (
                  <StakingZil
                    last={index === availableList.length - 1}
                    key={index}
                    stakeToken={stakingList}
                    claimStaking={claimStaking}
                  />
                );
              }
              return (
                <StakingTable
                  last={index === availableList.length - 1}
                  key={index}
                  stakeToken={stakingList}
                  claimToken={() => onClaimStaking(stakingList)}
                />
              );
            })}
          </Table>
        </Box>
        <Box
          display={{ base: "block", lg: "none" }}
          borderRadius="12px"
          borderWidth={isAllEmpty() ? 0 : 1}
          overflow="auto"
          borderColor={main3}
        >
          {availableList.map((stakingList: IStakingInfo, index) => {
            if (index === 0) {
              return (
                <StakingZilMb
                  key={index}
                  stakeToken={stakingList}
                  claimStaking={claimStaking}
                />
              );
            }
            return (
              <StakingTableMb
                key={index}
                stakeToken={stakingList}
                claimToken={() => onClaimStaking(stakingList)}
              />
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default StakingBalance;
