import React from "react";
import {
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Image,
  Text,
  Avatar,
  Box,
  Flex,
  HStack,
  IconButton,
  useToast,
} from "@chakra-ui/react";
import { useBreakpointValue } from "@chakra-ui/media-query";
import { PageLayout } from "src/templates";

import { useAppDispatch, useAppSelector } from "src/redux/hooks";
// Components
import { useAppColor, useCopy, useRouter } from "src/hooks";
import { Images } from "src/assets/images";
import { NFTs } from "./components/NFT/NFTs";
import HistoryPage from "./components/History/HistoryPage";
import { useTranslation } from "react-i18next";
import Portfolio from "./components/Portfolio/Portfolio";
import {
  getViewWallet,
  getViewWalletCollection,
  viewProfileSelector,
} from "src/redux/slices/viewProfileSlice";
import IconLink from "src/assets/icons/IconLink";
import { batch } from "react-redux";
import { apiGetTokens, getWalletByUuid } from "src/api/heyAlfieApi";
import {
  updateTokens,
  syncZilPriceInUsd,
  syncTokenRates,
} from "src/redux/slices/overviewSlice";

export enum OverviewModalStakingEnum {
  ClaimZil = 0,
  ClaimStaking = 1,
  ClaimSco = 2,
  ClaimPort = 3,
  ClaimZilSwap = 4,
  AddStaking = 5,
  AddLp = 6,
  Avatar = 7,
}

export type ViewProfilePageProps = { title: string };

export const ViewProfilePage: React.FC<ViewProfilePageProps> = ({ title }) => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const toast = useToast();
  const [copy] = useCopy();
  const [followings, setFollowings] = React.useState(0);
  const [followers, setFollowers] = React.useState(0);
  const [address, setAddress] = React.useState<string>();

  const routerParam = router.match.params as { uuid: string };
  const uuid = routerParam.uuid;

  const getAddress = async () => {
    if (!uuid) {
      return;
    }

    const res = await getWalletByUuid(uuid);
    setAddress(res.wallet.bech32);
  };

  const loadData = React.useCallback(async () => {
    try {
      if (!address) {
        return;
      }
      const tokens = await apiGetTokens();
      batch(() => {
        dispatch(getViewWallet(address));
        dispatch(getViewWalletCollection(address));
        dispatch(updateTokens(tokens));
        dispatch(syncZilPriceInUsd());
        dispatch(syncTokenRates());
      });
    } catch (error) {
      // Display error
    }
  }, [dispatch, address]);

  const { t } = useTranslation(["profile"]);
  const { text2, text6, main4, main6, brand } = useAppColor();

  const { viewWallet } = useAppSelector(viewProfileSelector);

  const onLink = () => {
    copy(window.location.href);

    if (!toast.isActive("copied"))
      toast({
        id: "copied",
        description: t("common:copied"),
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
  };

  React.useEffect(() => {
    getAddress();
  });
  React.useEffect(() => {
    loadData();
  }, [loadData, address]);

  React.useEffect(() => {
    if (viewWallet?.followings && viewWallet?.followings.length > 0) {
      const followingsAddress = JSON.parse(
        "[" + viewWallet?.followings[0].otherBech32 + "]"
      )[0] as string[];
      setFollowings(followingsAddress.length);
    }

    if (viewWallet?.followers && viewWallet?.followers.length > 0) {
      const followersAddress = JSON.parse(
        "[" + viewWallet?.followers[0].otherBech32 + "]"
      )[0] as string[];
      setFollowers(followersAddress.length);
    }
  }, [dispatch, viewWallet]);

  return (
    <PageLayout title={title}>
      <Text
        display={{ base: "block", xl: "none" }}
        mb={{ base: "16px", xl: "0" }}
        fontSize="3xl"
        lineHeight="9"
        fontWeight="bold"
      >
        {title}
      </Text>
      <Stack>
        <Box
          cursor="pointer"
          position="relative"
          transition="all 0.3s ease-in-out"
          borderRadius="16px"
          borderWidth="1px"
          h="180px"
          overflow={"hidden"}
          role="group"
          mb={{ base: "-20px", md: "-60px" }}
        >
          <Image
            transition="all 0.3s ease-in-out"
            objectFit="cover"
            src={viewWallet?.banner || Images.defaultBanner}
            w="full"
            h="180px"
          />
        </Box>
        <Stack
          direction={{ base: "column", lg: "row" }}
          position="relative"
          top={{ base: "-20px", md: "20px" }}
          justify="space-between"
        >
          <Stack
            direction={{ base: "column", md: "row" }}
            mb={{ base: "16px", md: "0" }}
          >
            <Avatar
              ml="30px"
              cursor="pointer"
              size={useBreakpointValue({ base: "xl", md: "2xl" })}
              position="relative"
              top={{ base: "-16px", md: "-30px" }}
              src={viewWallet?.avatar}
              alt="avatar"
            />
            <Flex>
              <Text ml="2" mt={{ md: "10" }} fontSize="xl" fontWeight="bold">
                {viewWallet?.name}
              </Text>
            </Flex>
          </Stack>

          <HStack justifyContent={{ base: "space-between", md: "start" }}>
            <HStack>
              <Text color={text2} fontSize="14px" fontWeight="700">
                {followings}
              </Text>
              <Text color={main6} fontSize="14px" fontWeight="700">
                {t("profile:following")}
              </Text>
            </HStack>
            <HStack pl="8px" pr="8px">
              <Text color={text2} fontSize="14px" fontWeight="700">
                {followers}
              </Text>
              <Text color={main6} fontSize="14px" fontWeight="700">
                {t("profile:followers")}
              </Text>
            </HStack>
            {/* <Button
              leftIcon={<AddIcon w={3} h={3} color={main6} />}
              onClick={() => {}}
              color={main6}
              bg={main3}
              size="sm"
              _hover={{
                bg: brand,
                color: "black",
              }}
            >
              {t("profile:follow")}
            </Button> */}
            {viewWallet?.uuid && (
              <IconButton
                display={{ base: "none", md: "block" }}
                aria-label="link"
                size="sm"
                borderColor={main6}
                color={main6}
                borderRadius="8px"
                variant="outline"
                _hover={{
                  bg: brand,
                  color: "black",
                }}
                onClick={onLink}
                icon={<IconLink />}
              />
            )}
          </HStack>
        </Stack>

        <Tabs borderColor={main4} isLazy>
          <Flex
            borderBottom="1px"
            borderColor={main4}
            justifyContent="space-between"
            alignItems="center"
          >
            <TabList textColor={text6} borderBottom="0px">
              {["Portfolios", "NFTs", "History" /*"Feeds"*/].map(
                (value, index) => {
                  return (
                    <Tab
                      key={index}
                      pb="20px"
                      pl={{ base: index === 0 ? "0" : "24px", lg: "24px" }}
                      _selected={{
                        boxShadow: "none",
                        borderColor: brand,
                        color: brand,
                      }}
                    >
                      {value}
                    </Tab>
                  );
                }
              )}
            </TabList>
          </Flex>

          <TabPanels>
            <TabPanel px="0">
              <Portfolio />
            </TabPanel>
            <TabPanel px="0">
              <NFTs />
            </TabPanel>
            <TabPanel px="0">
              <HistoryPage />
            </TabPanel>
            <TabPanel px="0"></TabPanel>
          </TabPanels>
        </Tabs>
      </Stack>
    </PageLayout>
  );
};

export default ViewProfilePage;
