import React from "react";
import {
  AvatarGroup,
  Box,
  Button,
  Flex,
  Text,
  useBoolean,
} from "@chakra-ui/react";
import { useAppColor } from "src/hooks";
import { ButtonUnlock, TokenLogo } from "src/atoms";
import { IconFilledArrow } from "src/assets/icons";
import { useAppSelector } from "src/redux/hooks";
import { loginSelector } from "src/redux/slices/loginSlice";
import { useTranslation } from "react-i18next";
import { formatToken, formatUsdt } from "src/lib/formatCurrency";
import BigNumber from "bignumber.js";
import { IToken, ITokenPool } from "src/constants/interfaces";
import { overviewSelector } from "src/redux/slices/overviewSlice";
import { MotionBox } from "src/molecules";

interface LPXcadDetailsProps {
  item: ITokenPool;
  onAdd?(): void;
  poolSymbol?: IToken;
  poolItem?: any;
}

const LPXcadDetails: React.FC<LPXcadDetailsProps> = ({
  item,
  poolSymbol,
  poolItem,
  onAdd,
}) => {
  const { text3, text5, text6, main1, main3, main4, main6 } = useAppColor();
  const { exchangeRate, isHidden } = useAppSelector(loginSelector);
  const { zilPriceInUsd, tokenRates } = useAppSelector(overviewSelector);

  const { t } = useTranslation(["overview", "common"]);
  const [show, setShow] = useBoolean(false);
  const {
    token,
    userContribution,
    zilReserve,
    tokenReserve,
    totalContribution,
  } = item;
  const { symbol, logo } = poolItem;

  const tokenRate = tokenRates.find(
    (tokenRate) => tokenRate.token.address_bech32 === token.address_bech32
  );

  const poolTokenRate = tokenRates.find(
    (tokenRate) => tokenRate.token.symbol === symbol
  );

  let contributionPercentage = new BigNumber(0);
  if (totalContribution.toNumber() > 0) {
    contributionPercentage = userContribution!
      .dividedBy(totalContribution)
      .times(100);
  }

  let contributionShare = contributionPercentage.shiftedBy(-2);

  let tokenAmount = contributionShare
    .times(tokenReserve)
    .div(Math.pow(10, token.decimals))
    .toNumber();

  let poolTokenAmount = contributionShare
    .times(zilReserve)
    .div(Math.pow(10, poolSymbol?.decimals || 18))
    .toNumber();

  return (
    <Box bg={main3} borderBottomWidth="1px" borderColor={main4}>
      <Flex alignItems="center" justifyContent="space-between" p="4">
        <Flex alignItems="center">
          <Box position="relative" mr="2" w="9" h="9">
            <TokenLogo size="xs" src={token.icon} />
            <TokenLogo
              position="absolute"
              size="xs"
              src={poolSymbol?.icon || token.icon}
              right="0"
              bottom="0"
            />
          </Box>
          <Text fontSize="sm" fontWeight="semibold">
            {token.symbol} {"<> "}
            {poolSymbol?.symbol || "ZIL"}
          </Text>
        </Flex>
        <IconFilledArrow
          _groupHover={{
            fill: text3,
          }}
          onClick={setShow.toggle}
          cursor="pointer"
          size="6"
          fill={text5}
          style={{
            transform: `rotate(${show ? 0 : 180}deg)`,
          }}
        />
      </Flex>
      <Flex alignItems="center" justifyContent="space-between" p="4">
        <Text fontSize="xs" color={text6}>
          {t("poolShare")} | {(contributionShare.toNumber() * 100).toFixed(3)}%
        </Text>
        <Flex>
          {isHidden ? (
            <ButtonUnlock size="sm" />
          ) : (
            <>
              {/* Hide Remove Button */}
              {/* <Button
		      variant="ghost"
		      children={t("common:remove")}
		      mr="4"
		      size="sm"
		    /> */}
              <Button
                children={t("common:add")}
                size="sm"
                // onClick={onAdd}
              />
            </>
          )}
        </Flex>
      </Flex>
      {!!show && (
        <MotionBox
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            transition: { duration: 0.5 },
          }}
          pl="16px"
          bg={main1}
        >
          <Flex
            justifyContent="space-between"
            alignItems="center"
            pt="4"
            pb="2"
            borderBottomWidth="1px"
            borderColor={main4}
            pr="4"
          >
            <Text
              fontSize="xs"
              fontWeight="bold"
              textTransform="uppercase"
              color={main6}
            >
              {t("deposit")}
            </Text>
            <Flex flexDirection="column" alignItems="flex-end">
              <Flex alignItems="center">
                <Text fontSize="sm" fontWeight="semibold" mr="1">
                  {formatToken(tokenAmount, token.symbol, isHidden)}
                </Text>
                <TokenLogo w="4" h="4" src={token.icon} />
                <Text mx="2">+</Text>
                <Text fontSize="sm" fontWeight="semibold" mr="1">
                  {formatToken(poolTokenAmount, symbol, isHidden)}
                </Text>
                <TokenLogo w="4" h="4" src={poolSymbol?.icon || logo} />
              </Flex>
              <Text fontSize="xs" color={text6}>
                ~{" "}
                {formatUsdt(
                  (tokenAmount * tokenRate!.rate +
                    poolTokenAmount * poolTokenRate!.rate) *
                    zilPriceInUsd,
                  exchangeRate,
                  isHidden
                )}
              </Text>
            </Flex>
          </Flex>
          <Flex
            alignItems="center"
            justifyContent="space-between"
            pt="4"
            pb="2"
            pr="4"
            minH="14"
          >
            <Text
              fontSize="xs"
              fontWeight="bold"
              textTransform="uppercase"
              color={main6}
            >
              {t("rewardDistributed")}
            </Text>
            <Box>
              <AvatarGroup
                size="sm"
                spacing={2}
                max={6}
                fontSize="sm"
                justifyContent="end"
              >
                {[0].map((i, index) => (
                  <TokenLogo src={logo} key={index} />
                ))}
              </AvatarGroup>
              {/* <Flex justifyContent="flex-end" alignItems="center">
                <Text
                  fontSize="sm"
                  fontWeight="semibold"
                  mr="1"
                  alignItems="center"
                  display="flex"
                >
                  {formatToken(0, "dXCAD", isHidden)}
                  <TokenLogo w="4" h="4" ml="1" src={logo} />
                </Text>
              </Flex> */}
              <Text fontSize="xs" color={text6}>
                {/* ~${unclaimed} */}
              </Text>
            </Box>
          </Flex>
        </MotionBox>
      )}
    </Box>
  );
};

export default LPXcadDetails;
