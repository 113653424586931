import React from "react";
import { IconBase, IconBaseProps } from "react-icons";

const SvgComponent: React.FC<IconBaseProps> = (props: IconBaseProps) => {
  return (
    <IconBase {...props}>
      <svg viewBox="0 0 14 14">
        <path d="M1.75033 1.75H12.2503C12.405 1.75 12.5534 1.81146 12.6628 1.92085C12.7722 2.03025 12.8337 2.17862 12.8337 2.33333V11.6667C12.8337 11.8214 12.7722 11.9697 12.6628 12.0791C12.5534 12.1885 12.405 12.25 12.2503 12.25H1.75033C1.59562 12.25 1.44724 12.1885 1.33785 12.0791C1.22845 11.9697 1.16699 11.8214 1.16699 11.6667V2.33333C1.16699 2.17862 1.22845 2.03025 1.33785 1.92085C1.44724 1.81146 1.59562 1.75 1.75033 1.75ZM7.03533 6.81508L3.29499 3.63883L2.53958 4.52783L7.04291 8.35158L11.4652 4.52492L10.7022 3.64233L7.03591 6.81508H7.03533Z" />
      </svg>
    </IconBase>
  );
};
const IconEmail = React.memo(SvgComponent);
export default IconEmail;
