import React from "react";
import Icon from "@chakra-ui/icon";
import { SvgIconProps } from "src/constants/type";

const SvgComponent: React.FC<SvgIconProps> = ({
  size,
  ...props
}: SvgIconProps) => {
  return (
    <Icon {...props} w={size} h={size} viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M9.34677 0.613321C9.21417 0.383626 8.99575 0.216016 8.73957 0.147365C8.48338 0.0787135 8.21042 0.114645 7.98073 0.247254L4.53865 2.2345H1.64383C1.23797 2.2345 0.848728 2.39573 0.561741 2.68272C0.274753 2.9697 0.113525 3.35894 0.113525 3.7648V11.189C0.113525 11.5949 0.274753 11.9841 0.561741 12.2711C0.84873 12.5581 1.23797 12.7193 1.64383 12.7193H11.1893C11.5951 12.7193 11.9844 12.5581 12.2714 12.2711C12.5584 11.9841 12.7196 11.5949 12.7196 11.189V8.8102L12.7196 8.80268V6.15117L12.7196 6.14365V3.7648C12.7196 3.35894 12.5584 2.9697 12.2714 2.68272C11.9844 2.39573 11.5951 2.2345 11.1893 2.2345H10.2827L9.34677 0.613321ZM10.7196 4.42018C10.7196 4.42269 10.7196 4.42519 10.7196 4.4277V5.15117H9.39954C8.12473 5.15117 7.00749 6.14725 7.00749 7.47692C7.00749 8.80659 8.12473 9.80268 9.39954 9.80268H10.7196V10.7193H2.11353V4.2345H10.7196V4.42018ZM10.7196 7.80268H9.39954C9.13647 7.80268 9.00749 7.61142 9.00749 7.47692C9.00749 7.34243 9.13647 7.15117 9.39954 7.15117H10.7196V7.80268Z"
      />
    </Icon>
  );
};

const IconRecipientAddress = React.memo(SvgComponent);
export default IconRecipientAddress;
