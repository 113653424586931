import React from "react";
import { Box, Flex } from "@chakra-ui/layout";
import { Tr, Td, Button, Text } from "@chakra-ui/react";
import { ButtonUnlock, TokenLogo } from "src/atoms";
import { formatToken, formatUsdt } from "src/lib/formatCurrency";
import { handleGoLink } from "src/utils";
import { useTranslation } from "react-i18next";
import { useAppColor } from "src/hooks";
import { useAppSelector } from "src/redux/hooks";
import { loginSelector } from "src/redux/slices/loginSlice";
import { overviewSelector } from "src/redux/slices/overviewSlice";
import { IPendingReward } from "src/constants/interfaces";

interface StakingTableItemProps {
  name: string;
  logo: string;
  ratio: number;
  stakingBalance?: number;
  pendingReward?: IPendingReward;
  rate: number;
  seedNode?: number | string;
  symbol: string;
  link: string;
  stakeLogo: string;
  stakeSymbol?: string;
  rewardSymbol?: string;
  last?: boolean;
  reward?: number;
}

const StakingTableItem: React.FC<StakingTableItemProps> = (props) => {
  const {
    name,
    logo,
    ratio,
    stakingBalance,
    pendingReward,
    rate,
    symbol,
    link,
    seedNode,
    stakeLogo: _stakeLogo,
    stakeSymbol,
    rewardSymbol,
    last,
  } = props;
  const { t } = useTranslation(["overview", "common"]);
  const { zilPriceInUsd, tokenRates } = useAppSelector(overviewSelector);

  const { text6 } = useAppColor();
  const { isHidden, exchangeRate } = useAppSelector(loginSelector);

  const stakeToken = tokenRates.find(
    (token) => token.token.symbol === stakeSymbol
  );
  const rewardToken = tokenRates.find((token) =>
    rewardSymbol
      ? token.token.symbol === rewardSymbol
      : token.token.symbol === pendingReward?.pendingRewardSymbol
  );

  const stakeLogo = stakeToken?.token.icon || _stakeLogo;
  const rewardLogo = rewardToken?.token.icon || _stakeLogo;
  const stakeRate = stakeToken?.rate || rate;
  const rewardRate = rewardToken?.rate || rate;

  return (
    <Tr>
      <Td colspan="2" borderBottomWidth={last ? "0" : undefined}>
        <Flex alignItems="center">
          {stakeSymbol && rewardSymbol ? (
            <Box position="relative" mr="2" w="9" h="9">
              <TokenLogo size="xs" src={stakeLogo} />
              <TokenLogo
                position="absolute"
                size="xs"
                src={rewardLogo}
                right="0"
                bottom="0"
              />
            </Box>
          ) : (
            <TokenLogo size="sm" mr="2" src={logo} />
          )}
          <Box justifyContent="flex-end">
            <Text fontSize="sm" fontWeight="semibold">
              {name}
            </Text>
            {ratio !== 0 && (
              <Text fontSize="xs" color={text6}>
                {t("common:fee")} | {ratio}%
              </Text>
            )}
            {!!seedNode && seedNode !== 0 && (
              <Text fontSize="xs" color={text6}>
                {t("overview:seedNode")} | {seedNode}%
              </Text>
            )}
          </Box>
        </Flex>
      </Td>
      <Td isNumeric borderBottomWidth={last ? "0" : undefined}>
        <Flex justifyContent="flex-end" alignItems="center">
          <Text fontSize="sm" fontWeight="semibold" mr="1">
            {stakingBalance
              ? formatToken(stakingBalance, stakeSymbol || symbol, isHidden)
              : "0.00 " + (stakeSymbol || symbol)}
          </Text>
          <TokenLogo w="4" h="4" src={stakeLogo} />
        </Flex>
        <Text fontSize="xs" color={text6}>
          ~
          {stakingBalance
            ? formatUsdt(
                stakingBalance * zilPriceInUsd * stakeRate,
                exchangeRate,
                isHidden
              )
            : "$0.00"}
        </Text>
      </Td>
      <Td isNumeric borderBottomWidth={last ? "0" : undefined}>
        {pendingReward && pendingReward.pendingReward > 0 ? (
          <>
            <Flex justifyContent="flex-end" alignItems="center">
              <Text fontSize="sm" fontWeight="semibold" mr="1">
                {formatToken(
                  pendingReward.pendingReward,
                  rewardSymbol || symbol,
                  isHidden
                )}
              </Text>
              <TokenLogo w="4" h="4" src={rewardLogo} />
            </Flex>
            <Text fontSize="xs" color={text6}>
              ~{" "}
              {formatUsdt(
                pendingReward.pendingReward * rewardRate * zilPriceInUsd,
                exchangeRate,
                isHidden
              )}
            </Text>
          </>
        ) : (
          <TokenLogo size="sm" src={rewardLogo} />
        )}
      </Td>
      <Td borderBottomWidth={last ? "0" : undefined}>
        <Flex justifyContent="flex-end" alignItems="center">
          {false && (
            <Button
              variant="ghost"
              children={t("common:remove")}
              mr="4"
              size="sm"
            />
          )}
          {isHidden ? (
            <ButtonUnlock size="sm" />
          ) : (
            <Button
              children={t("common:add")}
              size="sm"
              onClick={() => handleGoLink(link)}
            />
          )}
        </Flex>
      </Td>
    </Tr>
  );
};

export default StakingTableItem;
