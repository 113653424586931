import React from "react";
import {
  Box,
  Divider,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { useAppColor } from "src/hooks";
import { useTranslation } from "react-i18next";
import { IconArrow, IconInformation } from "src/assets/icons";
import SelectAPair from "./SelectAPair";
import SelectAPairMobile from "./SelectAPairMobile";

interface DexMiddleProps {
  onClick?(): void;
  choiceBtn?: boolean;
}

const DexMiddle: React.FC<DexMiddleProps> = ({ onClick, choiceBtn }) => {
  const { t } = useTranslation("swap");
  const { text1, main4, main5 } = useAppColor();

  const tabs = [t("bestRate"), t("fastest"), t("bestRating")];

  return (
    <Box>
      <Flex alignItems="center" justifyContent="start">
        <Box hidden={choiceBtn}>
          <IconArrow onClick={onClick} fill={text1} />
        </Box>
        <Text fontSize="md" lineHeight="6" fontWeight="semibold">
          {t("title2")}
        </Text>
      </Flex>
      <Divider my="10px" orientation="horizontal" bg={main4} />
      <Tabs variant="soft-rounded">
        <Flex alignItems="center" justifyContent="space-between">
          <TabList borderRadius="full" w="auto" mb="16px" height="28px">
            {tabs.map((name, index) => (
              <Tab
                key={index}
                _focus={{ border: "none" }}
                _selected={{
                  bg: "gray.700",
                  color: "white",
                }}
                color={main5}
              >
                {name}
              </Tab>
            ))}
          </TabList>
          <IconInformation size="20px" cursor="pointer" fill={main4} />
        </Flex>
        <TabPanels>
          <TabPanel p="0">
            {window.screen.width > 414 ? (
              <SelectAPair onClick={onClick} choiceBtn={choiceBtn} />
            ) : (
              <SelectAPairMobile onClick={onClick} choiceBtn={choiceBtn} />
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default DexMiddle;
