import React from "react";
import Icon from "@chakra-ui/icon";
import { SvgIconProps } from "src/constants/type";

const SvgComponent: React.FC<SvgIconProps> = ({
  size,
  ...props
}: SvgIconProps) => {
  return (
    <Icon {...props} w={size} h={size} viewBox="0 0 16 16">
      <path d="M14.6667 3.33398V4.66732H12.6667V6.66732H11.3333V4.66732H9.33333V3.33398H11.3333V1.33398H12.6667V3.33398H14.6667ZM12.6667 12.6673H3.33333V3.33398H7.33333V2.00065H3.33333C2.6 2.00065 2 2.60065 2 3.33398V12.6673C2 13.4007 2.6 14.0007 3.33333 14.0007H12.6667C13.4 14.0007 14 13.4007 14 12.6673V8.66732H12.6667V12.6673ZM10 8.66732V11.334H11.3333V8.66732H10ZM7.33333 11.334H8.66667V6.00065H7.33333V11.334ZM6 11.334V7.33398H4.66667V11.334H6Z" />
    </Icon>
  );
};

const IconAddLiquidity = React.memo(SvgComponent);
export default IconAddLiquidity;
