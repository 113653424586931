import React from "react";
import { Box, Button, Center, Flex, Text } from "@chakra-ui/react";
import { useAppColor, useRouter } from "src/hooks";

interface DialogPremiumProps {}

const DialogPremium: React.FC<DialogPremiumProps> = () => {
  const { text2, text3, main3, brand } = useAppColor();
  const router = useRouter();
  return (
    <Center w="full" h="full">
      <Box
        w="md"
        bg={main3}
        borderRadius="16px"
        padding="24px"
        justifyContent="center"
      >
        <Flex
          display="flex"
          alignItems="start"
          align="start"
          flexDirection="column"
        >
          <Text fontSize="xl" fontWeight="extrabold" color={text2}>
            Premium Access Needed
          </Text>
          <Text my="4" fontSize="md" color={text3}>
            To access this feature, you would need a premium membership.
          </Text>
        </Flex>
        <Flex justifyContent="center">
          <Button
            w="100%"
            color="black"
            fontWeight="semibold"
            bg={brand}
            onClick={() => router.push("membership")}
            children="Get Premium"
          />
        </Flex>
      </Box>
    </Center>
  );
};

export default DialogPremium;
