import React from "react";
import { useBoolean, Box, Flex, Text } from "@chakra-ui/react";
import { useAppSelector } from "src/redux/hooks";
import { overviewSelector } from "src/redux/slices/overviewSlice";
import { IStakingInfo, IUnstaking } from "src/constants/interfaces";
import StakingItemMb from "./StakingItemMb";
import StakingHeaderMb from "./StakingHeaderMb";
import { Swiper, SwiperSlide } from "swiper/react";
import { Images } from "src/assets/images";
import { TokenLogo } from "src/atoms";
import { formatToken } from "src/lib/formatCurrency";
import { loginSelector } from "src/redux/slices/loginSlice";
import { useAppColor } from "src/hooks";
import { useTranslation } from "react-i18next";

interface StakingTableMbProps {
  claimToken: () => void;
  stakeToken: IStakingInfo;
  active?: boolean;
}

const StakingTableMb: React.FC<StakingTableMbProps> = ({
  stakeToken,
  claimToken,
  active,
}) => {
  const { t } = useTranslation(["overview", "common", "history"]);
  const [show, setShow] = useBoolean(false);
  const { text2, text6, main4 } = useAppColor();

  const { isHidden } = useAppSelector(loginSelector);
  const { tokenRates, tokens } = useAppSelector(overviewSelector);

  const { stakeList, symbol, pendingRewards, unstakingPending } = stakeToken;
  const [unstakingList, setUnstakingList] = React.useState<IUnstaking[]>([]);

  const toggleShow = () => {
    setShow.toggle();
  };
  React.useEffect(() => {
    let unstakingListTmp = [] as IUnstaking[];
    let unstaking = unstakingPending || [];
    unstaking.forEach((item) => {
      const stakingSymbol = item.unstakingSymbol || symbol;
      const curIndex = unstakingListTmp.findIndex(
        (temp) => temp.unstakingSymbol === stakingSymbol
      );
      if (curIndex !== -1) {
        if (item.unstakingSymbol) {
          unstakingListTmp[curIndex].unstaking += item.unstaking;
        }
      } else {
        unstakingListTmp.push({
          unstakingSymbol: stakingSymbol,
          unstaking: item.unstaking,
        });
      }
    });
    setUnstakingList(
      unstakingListTmp.filter((staking) => staking.unstaking > 0)
    );
  }, [symbol, unstakingPending]);
  return (
    <React.Fragment>
      <StakingHeaderMb
        toggleShow={toggleShow}
        stakeToken={stakeToken}
        claimToken={claimToken}
      />
      {!!show && (
        <Box>
          <Box display={{ base: "none", lg: "block" }}>
            {stakeList &&
              stakeList.map((token, index) => {
                const { stakingBalance } = token;
                let rate = 1;
                let reward = 0;
                if (pendingRewards) {
                  reward =
                    pendingRewards.find(
                      (pendingReward) => pendingReward.address === token.address
                    )?.pendingReward || 0;
                }
                if (
                  (!stakingBalance && reward <= 0 && !!active) ||
                  (stakingBalance &&
                    stakingBalance <= 0 &&
                    reward <= 0 &&
                    !!active)
                ) {
                  return <Box key={index} />;
                }
                const tokenRate = tokenRates.find(
                  (tokenRate) => tokenRate.token.symbol === symbol
                );

                if (tokenRate) {
                  rate = tokenRate.rate;
                }

                return (
                  <StakingItemMb
                    key={index}
                    {...stakeToken}
                    {...token}
                    reward={reward}
                    rate={rate}
                  />
                );
              })}
          </Box>
          <Box py="20px" px="14" bg={main4}>
            {unstakingList.map((item, index) => {
              const stakeIcon =
                tokens.find((token) => token.symbol === item.unstakingSymbol)
                  ?.icon || Images.zil;
              return (
                <Flex key={index} alignItems="center">
                  <Text
                    fontSize="14px"
                    lineHeight="20px"
                    fontWeight="600"
                    color={text2}
                    marginRight="1"
                  >
                    {item.unstaking > 0
                      ? formatToken(
                          item.unstaking,
                          item.unstakingSymbol,
                          isHidden
                        )
                      : "0"}
                  </Text>
                  <TokenLogo size="2xs" src={stakeIcon} />
                </Flex>
              );
            })}

            <Text fontSize="12px" lineHeight="16px" color={text6}>
              {t("currentUnstaking")}
            </Text>
          </Box>
          <Box m="4" display={{ base: "block", lg: "block" }}>
            <Swiper slidesPerView={1} spaceBetween={20} width={220}>
              {stakeList &&
                stakeList.map((token, index) => {
                  let rate = 1;
                  let reward = 0;
                  if (pendingRewards) {
                    reward =
                      pendingRewards.find(
                        (pendingReward) =>
                          pendingReward.address === token.address
                      )?.pendingReward || 0;
                  }
                  const tokenRate = tokenRates.find(
                    (tokenRate) => tokenRate.token.symbol === symbol
                  );

                  if (tokenRate) {
                    rate = tokenRate.rate;
                  }

                  return (
                    <SwiperSlide key={index}>
                      <StakingItemMb
                        key={index}
                        {...stakeToken}
                        {...token}
                        reward={reward}
                        rate={rate}
                      />
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </Box>
        </Box>
      )}
    </React.Fragment>
  );
};

export default StakingTableMb;
