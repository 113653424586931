import {
  ICollection,
  INft,
  ISmartContract,
  INftAttribute,
} from "src/constants/interfaces";
import { normaliseAddress } from "./zilApi";
import axios, { CancelToken } from "axios";
import {
  contractInitBatchRequest,
  contractStateBatchRequest,
  sendBatchRequest,
} from "./zilApi";
import { EnumNetwork } from "./network";
import {
  IApiGetCollectionsResponse,
  ICollectionAddress,
} from "./apiInterfaces";
import { fromBech32Address, toBech32Address } from "@zilliqa-js/zilliqa";
import { Images } from "src/assets/images";
import { heyAlfieApiEndpoint } from "./heyAlfieApi";

const baseNftUrl = "https://ipfs.io/ipfs/";

const nftDuck = {
  address: "zil132e27rxvaecetf7pvqc0hh77v5qajxgrdl8rav",
  logo: Images.nftDuck,
  dataUri: "https://api.duck.community/duck/",
};

const godZilliqaAddress = {
  address: "zil1z0eeqa7t673gv94v83sdrk9fyffuayuxpkscna",
  logo: Images.godZilliqa,
  dataUri: "",
};

const okiOfficial = {
  address: "zil18jx2rm9xdct0kx3s94arpyq9yu2lcga4gg8vzr",
  logo: "https://arkstatic.s3.ap-southeast-1.amazonaws.com/prod/collection/0xa18983655af9141a9cbdc1c44de8565d44e2d239/profile.png",
  dataUri: "",
};

const zilApe = {
  address: "zil15uqy4c6sagcfgd0gyvy8274eggpknwnzlnaest",
  logo: Images.zilApe,
  dataUri: "",
};

const beanterra = {
  address: "zil16265u7gexrwh6ph228eu9fk09sq0ze02yxvuvw",
  logo: Images.beanterra,
  dataUri: `${heyAlfieApiEndpoint}/api/v1/nft/beanterra/`,
};

const heyAlfie = {
  address: "zil1vdgz4dl383d6gtt02lncgu0g49dkpr899qwlmn",
  logo: Images.logo,
  dataUri: "",
};

const soullessCitadel = {
  address: "zil13fum43ax8qeprt5s9u6wsmrtw2vsvdrdhmvtrm",
  logo: Images.soulless,
  dataUri: `${heyAlfieApiEndpoint}/api/v1/nft/soulless/`,
};

const everydayArt = {
  address: "zil1x3876630z747ze3vd456xm5hm28dxhfwxvdkmc",
  logo: "",
  dataUri: "",
};

const metaSkull = {
  address: "zil14szppxu0tdhww539z84psk99nmy2l5jlhcjq2u",
  logo: "",
  dataUri: "",
};

const delusion = {
  address: "zil1mztv4q0rts78h598et3hgklnzgwc35e6zh2sta",
  logo: "",
  dataUri: "",
};

const nationalGalleryNFT = {
  address: "zil16l70elu5k666qng23xmpxtrh9y59rkxsydhd4z",
  logo: "",
  dataUri: "",
};

const footballJunk = {
  address: "zil1u6hnc7039suuvctz0j8eg47pf63tnl2jvnqrat",
  logo: "",
  dataUri: "",
};

const zilThugz3 = {
  address: "zil1cqzk3tmp487k8ahvrj46e5vmy2qva4q42xm6lq",
  logo: "",
  dataUri: "",
};

const auldsToken = {
  address: "zil1we2h369efp8lr308qvly8ew8ku6x2aznxr7zkr",
  logo: "",
  dataUri: "",
};

const biomesToken = {
  address: "zil18rps8ytx05tcaxp27ws5az7j4gqwl3m35jpa78",
  logo: "",
  dataUri: "https://api.bizarrebiomes.com/biome/",
};
const muskdropToken = {
  address: "zil1k0s67azv52yx7lzcvh0gxxzfat7ydtva9gw0tz",
  logo: "",
  dataUri: "",
};
const frankBrunoToken = {
  address: "zil1kq8pck8fnjqm7jdr2cp5vg0y0f9ggr5g7sgxkm",
  logo: "",
  dataUri: "",
};
const zilWaifuToken = {
  address: "zil1ttspeq0z6qy7hglnkltymerp3zjcaqupk3wce7",
  logo: "",
  dataUri: "",
};
const redemptionToken = {
  address: "zil1n7xthjngxqmcevw9u4yy9qc8749dm7tp4cx6l3",
  logo: "",
  dataUri: "",
};
const zilMechaToken = {
  address: "zil123tnvah05yergwtqjua5lc5rlw945d4zq3zd0p",
  logo: "",
  dataUri: "",
};

const otherNfts: ICollectionAddress[] = [
  heyAlfie,
  nftDuck,
  godZilliqaAddress,
  okiOfficial,
  zilApe,
  beanterra,
  soullessCitadel,
  metaSkull,
  everydayArt,
  delusion,
  nationalGalleryNFT,
  footballJunk,
  zilThugz3,
  auldsToken,
  biomesToken,
  muskdropToken,
  frankBrunoToken,
  zilWaifuToken,
  redemptionToken,
  zilMechaToken,
];

const toHttpsFromIpfs = (uri: string) => {
  const splitUri = uri.split("/");
  const url = `https://${splitUri[2]}.ipfs.dweb.link/${splitUri[3]}`;
  return url;
};

/**
 * Fetch nft
 * @param smartContract
 * @returns
 */

const fetchNft = async (
  smartContract: ISmartContract,
  cancelToken?: CancelToken
): Promise<INft> => {
  try {
    const url = smartContract.tokenUri.includes("/")
      ? smartContract.tokenUri.replace(
          "https://gateway.pinata.cloud/ipfs/",
          baseNftUrl
        )
      : baseNftUrl + smartContract.tokenUri;
    const { data } = await axios.get<INft>(url, { cancelToken: cancelToken });
    if (typeof data === "string") {
      return {
        id: smartContract.id,
        image: url,
        title: `#${smartContract.id}`,
        description: "",
      };
    }
    const attributes = data.attributes
      ? data.attributes.map((attribute: any) => {
          return {
            traitType: attribute.trait_type.trait,
            value: attribute.value,
            rarity: attribute.rarity,
          };
        })
      : ([] as INftAttribute[]);
    const image = data.image?.includes("/")
      ? data.image
      : baseNftUrl + data.image;
    return {
      id: smartContract.id,
      image: image,
      title: data.title || `#${smartContract.id}`,
      description: data.description,
      attributes: attributes,
      video: data.video,
    };
  } catch (error) {
    return {};
  }
};

const fetchRedemptionNft = async (
  smartContract: ISmartContract,
  cancelToken?: CancelToken
): Promise<INft> => {
  try {
    const url = smartContract.tokenUri;
    const { data } = await axios.get<INft>(url, { cancelToken: cancelToken });
    if (typeof data === "string") {
      return {
        id: smartContract.id,
        image: url,
        title: `#${smartContract.id}`,
        description: "",
      };
    }
    const attributes = data.attributes
      ? data.attributes.map((attribute: any) => {
          return {
            traitType: attribute.trait_type.trait,
            value: attribute.value,
            rarity: attribute.rarity,
          };
        })
      : ([] as INftAttribute[]);
    const image = data.image?.includes("/")
      ? data.image
      : baseNftUrl + data.image;
    return {
      id: smartContract.id,
      image: image,
      title: data.title || `#${smartContract.id}`,
      description: data.description,
      attributes: attributes,
      video: data.video,
    };
  } catch (error) {
    return {};
  }
};

/**
 * Fetch MUSK Drop nft
 * @param smartContract
 * @returns
 */

const fetchMuskdropNft = async (
  smartContract: ISmartContract,
  cancelToken?: CancelToken
): Promise<INft> => {
  try {
    const url = smartContract.tokenUri.includes("/")
      ? smartContract.tokenUri.replace(
          "https://gateway.pinata.cloud/ipfs/",
          baseNftUrl
        )
      : baseNftUrl + smartContract.tokenUri;
    return {
      id: smartContract.id,
      title: `${smartContract.name} #${smartContract.id}`,
      description: "",
      attributes: [],
      video: url,
    };
  } catch (error) {
    return {};
  }
};

/**
 * Fetch biomes nft
 * @param smartContract
 * @returns
 */

const fetchBiomesNft = async (
  smartContract: ISmartContract,
  cancelToken?: CancelToken
): Promise<INft> => {
  const url = smartContract.dataUri + smartContract.id;
  const { data } = await axios.get<any>(url, { cancelToken: cancelToken });

  const attributes = data.attributes
    ? data.attributes.map((attribute: any) => {
        return {
          traitType: attribute.trait_type,
          value: attribute.value,
        };
      })
    : ([] as INftAttribute[]);
  return {
    id: smartContract.id,
    title: `${data.name}`,
    description: `${data.name}`,
    attributes: attributes,
    image: toHttpsFromIpfs(data.resources[0].uri),
  };
};

/**
 * Fetch zilApe
 * @param smartContract
 * @returns
 */

const fetchZilApe = async (
  smartContract: ISmartContract,
  cancelToken?: CancelToken
): Promise<INft> => {
  const url = baseNftUrl + smartContract.tokenUri;
  const { data } = await axios.get<any>(url, { cancelToken: cancelToken });
  if (typeof data === "string") {
    return {
      id: smartContract.id,
      image: url,
      title: `#${smartContract.id}`,
      description: "",
    };
  }
  const attributes = data.attributes
    ? data.attributes.map((attribute: any) => {
        return {
          traitType: attribute.trait_type.trait,
          value: attribute.value,
          rarity: attribute.rarity,
        };
      })
    : ([] as INftAttribute[]);

  return {
    id: smartContract.id,
    image: data.image,
    title: `#${smartContract.id}`,
    description: data.description,
    attributes: attributes,
  };
};

/**
 * Fetch nft Oki Official
 * @param smartContract
 * @returns
 */

const fetchOki = async (
  smartContract: ISmartContract,
  cancelToken?: CancelToken
): Promise<INft> => {
  const url = baseNftUrl + smartContract.tokenUri;
  const { data } = await axios.get<any>(url, { cancelToken: cancelToken });
  if (typeof data === "string") {
    return {
      id: smartContract.id,
      image: smartContract.tokenUri,
      title: `#${smartContract.id}`,
      description: "",
    };
  }
  const attributes = data.attributes
    ? data.attributes.map((attribute: any) => {
        return {
          traitType: attribute.trait_type.trait,
          value: attribute.value,
          rarity: attribute.rarity,
        };
      })
    : ([] as INftAttribute[]);
  const image = baseNftUrl + data.image;
  return {
    id: data.name,
    image: image,
    title: data.name,
    description: data.description,
    attributes: attributes,
  };
};

/**
 * Fetch GodZilliqa
 * @param smartContract
 * @returns
 */

const fetchGodZilliqa = async (
  smartContract: ISmartContract,
  cancelToken?: CancelToken
): Promise<INft> => {
  try {
    const url = baseNftUrl + smartContract.tokenUri;
    const { data } = await axios.get<any>(url, {
      cancelToken: cancelToken,
    });
    const attributes = data.attributes
      ? data.attributes.map((attribute: any) => {
          return {
            traitType: attribute.trait_type.trait,
            value: attribute.value,
            rarity: attribute.rarity,
          };
        })
      : ([] as INftAttribute[]);
    const image = baseNftUrl + data.image;
    return {
      id: data.id,
      image: image,
      title: data.name,
      description: data.description,
      attributes: attributes,
    };
  } catch (error) {
    return {};
  }
};

/**
 * Fetch beanterra
 * @param smartContract
 * @returns
 */
const fetchBeanterra = async (
  smartContract: ISmartContract,
  cancelToken?: CancelToken
): Promise<INft> => {
  try {
    const url = smartContract.dataUri + smartContract.id;

    const { data } = await axios.get<any>(url, { cancelToken: cancelToken });
    const { result } = data;

    const attributes = result.data.attributes
      ? result.data.attributes.map((attribute: any) => {
          return {
            traitType: attribute.trait_type.trait,
            value: attribute.value,
            rarity: attribute.rarity,
          };
        })
      : ([] as INftAttribute[]);
    const image = result.data.image;
    return {
      id: result.data.id,
      image: image,
      title: result.data.name,
      description: result.data.description,
      attributes: attributes,
    };
  } catch (error) {
    return {};
  }
};

/**
 * Fetch HeyAlfie
 * @param smartContract
 * @returns
 */
const fetchHeyAlfieNft = async (
  smartContract: ISmartContract,
  cancelToken?: CancelToken
): Promise<INft> => {
  try {
    const url = toHttpsFromIpfs(smartContract.tokenUri);

    if (smartContract.tokenUri === "Updating") {
      return {
        id: "Updating",
        description: "Updating",
        image:
          "https://user-images.githubusercontent.com/47516405/153706762-3539bdf5-597b-4d3b-8e73-3d237a887c66.png",
        title: "Alfie Members Club",
        attributes: [],
      };
    }

    const { data } = await axios.get<any>(url, { cancelToken: cancelToken });

    const attributes = data.attributes
      ? data.attributes.map((attribute: any) => {
          return {
            traitType: attribute.trait_type,
            value: attribute.value,
            rarity: attribute.rarity,
          };
        })
      : ([] as INftAttribute[]);
    const image = toHttpsFromIpfs(data.image);
    return {
      id: data.name,
      image: image,
      title: data.name,
      description: data.description,
      attributes: attributes,
    };
  } catch (error) {
    return {
      id: "Updating",
      description: "Updating",
      image:
        "https://user-images.githubusercontent.com/47516405/153706762-3539bdf5-597b-4d3b-8e73-3d237a887c66.png",
      title: "Alfie Members Club",
      attributes: [],
    };
  }
};

/**
 * Fetch UFFSport
 * @param smartContract
 * @returns
 */
// const fetchUltimateSport = async(smartContract: ISmartContract) => {
//   console.log(smartContract);
//   return {};
// };

/**
 * Fetch Soulless
 * @param smartContract
 * @returns
 */
const fetchSoullessCitadel = async (
  smartContract: ISmartContract,
  cancelToken?: CancelToken
) => {
  try {
    const url = smartContract.dataUri + smartContract.id;
    const { data: dataStatus } = await axios.get<any>(url, {
      cancelToken: cancelToken,
    });
    const { result } = dataStatus;
    const { data } = result;
    // const { data: imageData } = await axios.get<any>(
    //   `${heyAlfieApiEndpoint}/api/v1/nft/soulless-image/${smartContract.id}`
    // );
    // const image = imageData.data;
    const image = `https://ootvgibnikitgskvmkqn.supabase.in/storage/v1/object/public/tsc-full-images-79343/${smartContract.id}.png`;
    const attributes = data.attributes
      ? data.attributes.map((attribute: any) => {
          return {
            traitType: attribute.trait_type.trait,
            value: attribute.value,
            rarity: attribute.rarity,
          };
        })
      : ([] as INftAttribute[]);

    return {
      id: smartContract.id,
      image: image,
      title: `#${smartContract.id}`,
      description: data.description,
      attributes: attributes,
    };
  } catch (error) {
    return {};
  }
};

/**
 * Fetch Soulless
 * @param smartContract
 * @returns
 */
const fetchZilSwap = async (
  smartContract: ISmartContract,
  cancelToken?: CancelToken
): Promise<INft> => {
  try {
    const { id, contractAddress, name } = smartContract;
    const url = `https://api-ark.zilswap.org/nft/collection/${fromBech32Address(
      contractAddress
    )}/${id}/detail`;

    const { data } = await axios.get<any>(url, {
      cancelToken: cancelToken,
    });

    const attributes = data.result?.model?.traitValues
      ? data.result.model.traitValues.map((attribute: any) => {
          return {
            traitType: attribute.traitType.trait,
            value: attribute.value,
            rarity: attribute.rarity,
          };
        })
      : ([] as INftAttribute[]);
    const title =
      name !== data.result?.model?.name
        ? data?.result?.model?.name
        : `#${data?.result?.model?.tokenId}`;
    return {
      id: data.result?.model?.id,
      image: data.result?.model?.asset?.url,
      // title: data.result?.model?.name,
      // title: data.result?.model?.collection?.name,
      title: title,
      description: data.result?.model?.collection?.description,
      attributes: attributes,
    };
  } catch (error) {
    return {};
  }
};

/**
 * Fetch duck nft
 * @param smartContract
 * @returns
 */

const fetchDuckNft = async (
  smartContract: ISmartContract,
  cancelToken?: CancelToken
): Promise<INft> => {
  try {
    const url = smartContract.dataUri + smartContract.id;
    const { data } = await axios.get<any>(url, { cancelToken: cancelToken });
    if (typeof data === "string") {
      return {
        id: smartContract.id,
        image: url,
        title: `#${smartContract.id}`,
        description: "",
      };
    }
    const attributes = data.attributes
      ? data.attributes.map((attribute: any) => {
          return {
            traitType: attribute.trait_type.trait,
            value: attribute.value,
            rarity: attribute.rarity,
          };
        })
      : ([] as INftAttribute[]);

    const image = data.quick_resource;
    return {
      id: smartContract.id,
      image: image,
      title: data.title || `#${smartContract.id}`,
      description:
        "The first hard-cap bonding curve art collection and community on Zilliqa.",
      attributes: attributes,
      video: data.video,
    };
  } catch (error) {
    return {};
  }
};

/**
 * Fetch football junk nft
 * @param smartContract
 * @returns
 */

const fetchFootballJunkNft = async (
  smartContract: ISmartContract,
  cancelToken?: CancelToken
): Promise<INft> => {
  try {
    const url = toHttpsFromIpfs(smartContract.tokenUri);

    const { data } = await axios.get<any>(url, { cancelToken: cancelToken });

    const attributes = data.attributes
      ? data.attributes.map((attribute: any) => {
          return {
            traitType: attribute.trait_type,
            value: attribute.value,
            rarity: attribute.rarity,
          };
        })
      : ([] as INftAttribute[]);
    const image = toHttpsFromIpfs(data.image);
    return {
      id: data.name,
      image: image,
      title: data.name,
      description: data.description,
      attributes: attributes,
    };
  } catch (error) {
    return {};
  }
};

/**
 * Fetch Zil Thugz 3.0
 * @param smartContract
 * @returns
 */

const fetchZilThugz3 = async (
  smartContract: ISmartContract,
  cancelToken?: CancelToken
): Promise<INft> => {
  try {
    const url = smartContract.tokenUri.includes("/")
      ? smartContract.tokenUri.replace(
          "https://gateway.pinata.cloud/ipfs/",
          baseNftUrl
        )
      : baseNftUrl + smartContract.tokenUri;
    const { data } = await axios.get<INft>(url, { cancelToken: cancelToken });
    if (typeof data === "string") {
      return {
        id: smartContract.id,
        image: url,
        title: `#${smartContract.id}`,
        description: "",
      };
    }
    const attributes = data.attributes
      ? data.attributes.map((attribute: any) => {
          return {
            traitType: attribute.trait_type,
            value: attribute.value,
            rarity: attribute.rarity,
          };
        })
      : ([] as INftAttribute[]);
    const image = data.image?.includes("/")
      ? data.image
      : baseNftUrl + data.image;
    return {
      id: smartContract.id,
      image: image,
      title: data.title || `#${smartContract.id}`,
      description: data.description,
      attributes: attributes,
      video: data.video,
    };
  } catch (error) {
    return {};
  }
};

/**
 * Fetch nft from smart contract
 * @param contractAddress
 * @param tokenUri
 * @returns INft
 */
export const fetchNftFromSmartContract = async (
  smartContract: ISmartContract,
  cancelToken?: CancelToken,
  collection?: ICollection
): Promise<INft> => {
  if (collection && collection.collectionAddress?.isZilSwap) {
    return fetchZilSwap(smartContract, cancelToken);
  }
  switch (smartContract.contractAddress) {
    case godZilliqaAddress.address:
      return fetchGodZilliqa(smartContract, cancelToken);
    case okiOfficial.address:
      return fetchOki(smartContract, cancelToken);
    case zilApe.address:
      return fetchZilApe(smartContract, cancelToken);
    case beanterra.address:
      return fetchBeanterra(smartContract, cancelToken);
    case heyAlfie.address:
      return fetchHeyAlfieNft(smartContract, cancelToken);
    case soullessCitadel.address:
      return fetchSoullessCitadel(smartContract, cancelToken);
    case nftDuck.address:
      return fetchDuckNft(smartContract, cancelToken);
    case footballJunk.address:
    case auldsToken.address:
    case zilMechaToken.address:
      return fetchFootballJunkNft(smartContract, cancelToken);
    case zilThugz3.address:
      return fetchZilThugz3(smartContract, cancelToken);
    case biomesToken.address:
      return fetchBiomesNft(smartContract, cancelToken);
    case muskdropToken.address:
      return fetchMuskdropNft(smartContract, cancelToken);
    case redemptionToken.address:
      return fetchRedemptionNft(smartContract, cancelToken);
    default:
      return fetchNft(smartContract, cancelToken);
  }
};

export const fetchNftFromDatabase = async (
  smartContract: ISmartContract,
  cancelToken?: CancelToken,
  collection?: ICollection
): Promise<INft> => {
  switch (smartContract.contractAddress) {
    case soullessCitadel.address:
      return fetchSoullessCitadel(smartContract, cancelToken);
    default:
      return fetchNftNew(smartContract, cancelToken);
  }
};

const fetchNftNew = async (
  smartContract: ISmartContract,
  cancelToken?: CancelToken
) => {
  try {
    const url = heyAlfieApiEndpoint + "/api/v1/ipfs";
    const postParams = JSON.stringify({
      token_uris: smartContract.tokenUri,
    });
    const { data } = await axios.post<any>(url, postParams, {
      headers: {
        "Content-Type": "application/json",
      },
      cancelToken: cancelToken,
    });
    const { result } = data;
    const { data: dataStatus } = result;
    const attributes = dataStatus.metadata
      ? JSON.parse(dataStatus.metadata).map((attribute: any) => {
          return {
            traitType: attribute.trait_type,
            value: attribute.value,
            rarity: attribute.rarity,
          };
        })
      : ([] as INftAttribute[]);
    return {
      id: dataStatus.id,
      image: dataStatus.image_url,
      title: dataStatus.name || "#" + dataStatus.id,
      description: dataStatus.description,
      attributes: attributes,
    };
  } catch (error) {
    return {};
  }
};

/**
 * Get nft collections
 * @param currentWalletBech32Address
 * @returns
 */
export const getCollections = async (
  currentWalletBech32Address: string
): Promise<ICollection[]> => {
  let collections = Array<ICollection>();

  const currentWalletBase16Address = normaliseAddress(
    currentWalletBech32Address
  );

  const contractAddresses = await getCollectionAddresses();

  const batchRequests: any[] = [];

  for (let index = 0; index < contractAddresses.length; index++) {
    const contractAddress = contractAddresses[index];

    batchRequests.push(contractInitBatchRequest(contractAddress));
    batchRequests.push(contractStateBatchRequest(contractAddress));
  }

  const batchResults = await sendBatchRequest(
    EnumNetwork.MainNet,
    batchRequests
  );

  for (let index = 0; index < batchResults.length; index += 2) {
    const contractInfo = batchResults[index].result;
    const contractState = batchResults[index + 1].result;

    const request = batchResults[index].request;

    const smartContractAddressBech32 = request.contractAddress;
    if (
      !contractState ||
      !contractState.token_owners ||
      !contractState.token_uris
    ) {
      continue;
    }

    const collectionTitle = contractInfo.find(
      (info: any) =>
        info.vname === "name" &&
        info.type === "String" &&
        !info.value.includes("https")
    ).value;

    // get the first one of each for test
    let smartContracts = Array<ISmartContract>();
    for (const [tokenId, tokenOwnersBase16Address] of Object.entries(
      contractState.token_owners
    )) {
      if (
        `${tokenOwnersBase16Address}`.toUpperCase() !==
        `${currentWalletBase16Address}`.toUpperCase()
      ) {
        continue;
      }

      const token_uris: { [tokenId: string]: string } =
        contractState.token_uris;

      smartContracts.push({
        id: tokenId,
        name: collectionTitle,
        symbol: contractInfo.find((info: any) => (info.vname = "symbol")).value,
        contractAddress: `${smartContractAddressBech32}`,
        logo:
          request.collectionAddress?.logo ||
          `https://meta.viewblock.io/${smartContractAddressBech32}/logo`,
        dataUri: request.collectionAddress?.dataUri || "",
        tokenUri: token_uris[tokenId],
      });
    }

    if (smartContracts.length > 0) {
      const collectionAddress = contractAddresses.find(
        (item: ICollectionAddress) =>
          item.address.toLowerCase() ===
          `${smartContractAddressBech32}`.toLowerCase()
      );

      collections.push({
        title: collectionTitle,
        contractAddress: `${smartContractAddressBech32}`,
        smartContracts: smartContracts,
        collectionAddress: collectionAddress,
      });
    }
  }

  return collections;
};

export const getCollectionAddresses = async () => {
  try {
    const { data } = await axios.get<IApiGetCollectionsResponse>(
      "https://api-ark.zilswap.org/nft/collection/list?limit=1000"
    );
    let contractAddresses = [...otherNfts];
    data.result.entries.forEach((entry: any) => {
      if (
        otherNfts.find(
          (nft) =>
            nft.address.toLowerCase() ===
            toBech32Address(entry.address).toLowerCase()
        )
      ) {
        return;
      }
      contractAddresses.push({
        ...entry,
        address: toBech32Address(entry.address),
        logo: entry.profileImageUrl,
        dataUri: "",
        isZilSwap: true,
      });
    });

    return contractAddresses;
  } catch (error) {
    return otherNfts;
  }
};
