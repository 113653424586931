import { BN, Long, bytes, units } from "@zilliqa-js/util";
import { Zilliqa } from "@zilliqa-js/zilliqa";

/* Config */
export const heyAlfieContractAddress =
  "zil1vdgz4dl383d6gtt02lncgu0g49dkpr899qwlmn";
const zilliqa = new Zilliqa("https://api.zilliqa.com");
const chainId = 1;
const msgVersion = 1;
const VERSION = bytes.pack(chainId, msgVersion);

export const getHeyAlfieNftContractState = async () => {
  const heyAlfieContract = zilliqa.contracts.at(heyAlfieContractAddress);
  const state = await heyAlfieContract.getState();

  return state;
};

export const getHeyAlfieNfts = async (walletAddressBase16: string) => {
  const heyAlfieContract = zilliqa.contracts.at(heyAlfieContractAddress);
  const state = await heyAlfieContract.getState();

  const nftIds = [];
  const nftTokenUris: any[] = [];
  for (const [key, value] of Object.entries(state.token_owners)) {
    if (`${value}`.toUpperCase() === `${walletAddressBase16}`.toUpperCase()) {
      nftIds.push(key);
    }
  }

  nftIds.forEach((nftId) => {
    nftTokenUris.push(state.token_uris[nftId]);
  });

  return nftTokenUris;
};

export const mintNFT = async (walletAddressBase16: string, price: number) => {
  const gasLimit = Long.fromNumber(2000);
  const myGasPrice = units.toQa("2000", units.Units.Li);
  const minGasPrice = await zilliqa.blockchain.getMinimumGasPrice();
  const isGasSufficient = myGasPrice.gte(new BN(minGasPrice.result || "2000"));
  if (!isGasSufficient) {
    console.log(`OPS! Gas price is ${myGasPrice.toString()}`);
    return;
  }

  try {
    const heyAlfieContract = window.zilPay.contracts.at(
      heyAlfieContractAddress
    );

    //Get the contract state
    console.log("Getting contract state...");
    const state = await heyAlfieContract.getState();
    console.log("The state of the contract is:");
    console.log(JSON.stringify(state, null, 4));

    const mintParameters = [
      {
        vname: "to",
        type: "ByStr20",
        value: `${walletAddressBase16}`,
      },
      {
        vname: "token_uri",
        type: "String",
        value: "",
      },
    ];

    const callTx = await heyAlfieContract.call("Mint", mintParameters, {
      version: VERSION,
      //@ts-ignore
      amount: new BN(units.toQa(`${1 * price}`, units.Units.Zil)),
      //@ts-ignore
      gasPrice: myGasPrice,
      gasLimit: gasLimit,
    });

    //@ts-ignore
    return callTx;
  } catch (err) {
    console.log("ERROR Minting");
    console.log(err);
    return false;
  }
};

export const batchMintNFT = async (
  walletAddressBase16: string,
  amountNfts: number,
  price: number
) => {
  const gasLimit = Long.fromNumber(6000);
  const myGasPrice = units.toQa("2000", units.Units.Li);
  const minGasPrice = await zilliqa.blockchain.getMinimumGasPrice();
  const isGasSufficient = myGasPrice.gte(new BN(minGasPrice.result || "2000"));
  if (!isGasSufficient) {
    console.log(`OPS! Gas price is ${myGasPrice.toString()}`);
    return;
  }

  try {
    const values = [];
    for (let i = 0; i < amountNfts; i++) {
      values.push({
        constructor: "Pair",
        argtypes: ["ByStr20", "String"],
        arguments: [`${walletAddressBase16}`, "Updating"],
      });
    }

    const mintParameters = [
      {
        vname: "to_token_uri_pair_list",
        type: "List (Pair ByStr20 String)",
        value: values,
      },
    ];

    const heyAlfieContract = window.zilPay.contracts.at(
      heyAlfieContractAddress
    );

    //Get the contract state
    console.log("Getting contract state...", heyAlfieContract);
    const state = await heyAlfieContract.getState();
    console.log("The state of the contract is:");
    console.log(JSON.stringify(state, null, 4));

    //@ts-ignore
    const callTx = await heyAlfieContract.call("BatchMint", mintParameters, {
      // amount, gasPrice and gasLimit must be explicitly provided
      version: VERSION,
      amount: new BN(units.toQa(`${amountNfts * price}`, units.Units.Zil)),
      gasPrice: myGasPrice,
      gasLimit: gasLimit,
    });

    // Retrieving the transaction receipt (See note 2)
    console.log(callTx);
    return callTx;
  } catch (err) {
    console.log("ERROR Minting");
    console.log(err);
    return false;
  }
};
