import React from "react";
import {
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
} from "@chakra-ui/react";
import { useAppColor } from "src/hooks";

interface ModalAlertProps {
  onClose: () => void;
  title?: string;
  content?: string;
}

const ModalAlert: React.FC<ModalAlertProps> = ({ onClose, title, content }) => {
  const { text2, text3 } = useAppColor();
  return (
    <>
      <ModalHeader />
      <ModalCloseButton />
      <ModalBody
        display="flex"
        alignItems="center"
        align="center"
        flexDirection="column"
        px="10"
      >
        <Text fontSize="xl" fontWeight="extrabold" color={text2} mb="4">
          {title}
        </Text>
        <Text color={text3}>{content}</Text>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose}>Close</Button>
      </ModalFooter>
    </>
  );
};

export default ModalAlert;
