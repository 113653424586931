import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  Button,
  ButtonProps,
} from "@chakra-ui/react";
import ModalPinCode from "src/templates/ModalPinCode";
import { useTranslation } from "react-i18next";

interface ButtonUnlockProps extends ButtonProps {}

const ButtonUnlock: React.FC<ButtonUnlockProps> = ({ ...props }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation("common");
  return (
    <React.Fragment>
      <Button children={t("unlock")} onClick={onOpen} {...props} />
      <Modal size="md" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalPinCode onClose={onClose} />
        </ModalContent>
      </Modal>
    </React.Fragment>
  );
};

export default ButtonUnlock;
