import React from "react";
import { Box, Text } from "@chakra-ui/layout";
import { AttributeSpec } from "src/constants/type";
import { useAppColor } from "src/hooks";

interface AttributeProps {
  item: AttributeSpec;
  onClick?(): void;
}

const Attribute: React.FC<AttributeProps> = ({ item, onClick }) => {
  const { text2, text5, main1 } = useAppColor();
  const { traitType, value, rarity } = item;

  return (
    <Box
      bg={main1}
      overflow="hidden"
      borderRadius="8px"
      mr="12px"
      p="8px"
      w="100%"
      onClick={onClick}
    >
      <Text fontSize="sm" lineHeight="5" fontWeight="normal" color={text5}>
        {traitType}
      </Text>
      <Text fontSize="md" lineHeight="6" fontWeight="semibold" color={text2}>
        {value}
      </Text>
      {rarity && (
        <Text fontSize="sm" lineHeight="5" fontWeight="normal" color={text5}>
          {(rarity * 100).toFixed(2)}%
        </Text>
      )}
    </Box>
  );
};

export default Attribute;
