import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { heyAlfieApiEndpoint } from "src/api/heyAlfieApi";
import { HistorySpec, HistoryStatistic } from "src/constants/type";
import { RootState } from "../rootReducer";
import { AppThunk } from "../store";

const initStatistic: HistoryStatistic = {
  first_transaction_created_at: new Date().toString(),
  most_call_contract_address: "",
  most_call_contract_times: 0,
  total_fee: 0,
  total_txs: 0,
};
export interface HistoryError {
  message: string;
}

export interface HistoryState {
  error?: HistoryError;
  listTransaction?: HistorySpec[];
  numberOfPage: number;
  total: number;
  historyStatistic: HistoryStatistic;
}

export const initialState: HistoryState = {
  numberOfPage: 1,
  total: 0,
  historyStatistic: initStatistic,
};

export const historySlice = createSlice({
  name: "history",
  initialState,
  reducers: {
    setListTransaction: (state, { payload }: PayloadAction<HistorySpec[]>) => {
      state.listTransaction = payload;
    },
    setNumberOfPage: (state, { payload }: PayloadAction<number>) => {
      state.numberOfPage = payload;
    },
    setTotal: (state, { payload }: PayloadAction<number>) => {
      state.total = payload;
    },
    setHistoryStatistic: (
      state,
      { payload }: PayloadAction<HistoryStatistic>
    ) => {
      state.historyStatistic = payload;
    },
  },
});

export const {
  setListTransaction,
  setNumberOfPage,
  setTotal,
  setHistoryStatistic,
} = historySlice.actions;

export const getHistories =
  (address: string, page: number): AppThunk =>
  async (dispatch) => {
    try {
      const url = `${heyAlfieApiEndpoint}/api/v1/wallets/${address}/transactions/${page}`;
      fetch(url, {}).then((response) => {
        response.json().then((res) => {
          dispatch(setListTransaction(res.result.txs));
          dispatch(setNumberOfPage(Math.ceil(res.result.total / 25)));
          dispatch(setTotal(res.result.total));
        });
      });
    } catch (error) {
      throw error;
    }
  };

export const getHistoryStatistic =
  (address: string): AppThunk =>
  async (dispatch) => {
    try {
      const url = `${heyAlfieApiEndpoint}/api/v1/wallets/${address}/statistic`;
      const { data } = await axios.get<any>(url);
      if (data.status) {
        dispatch(setHistoryStatistic(data.result));
      } else {
        dispatch(setHistoryStatistic(initStatistic));
      }
    } catch (error) {
      throw error;
    }
  };

export const historySelector = (state: RootState) => state.history;

export default historySlice.reducer;
