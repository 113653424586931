import React from "react";
import { Button, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { sortSavedWallet } from "src/utils";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import {
  IWallet,
  loginSelector,
  selectWallet,
} from "src/redux/slices/loginSlice";
import { useAppColor, useRouter } from "src/hooks";

interface WalletDropdownProps {}

const WalletDropdown: React.FC<WalletDropdownProps> = () => {
  const {
    savedWallets,
    isLoadingWallets,
    currentWallet: _currentWallet,
  } = useAppSelector(loginSelector);

  const router = useRouter();

  const dispatch = useAppDispatch();

  const currentWallet = savedWallets.find(
    (savedWallet) => savedWallet.zilAddress === _currentWallet?.zilAddress
  );

  const chooseWallet = (wallet: IWallet) => {
    if (isLoadingWallets || currentWallet?.zilAddress === wallet.zilAddress) {
      return;
    }
    dispatch(selectWallet(wallet));
    if (currentWallet?.zilAddress !== wallet.zilAddress) {
      router.push("/");
    }
  };

  const { main1, brand } = useAppColor();

  return (
    <Menu>
      <MenuButton
        mb="4"
        bg={main1}
        w="full"
        as={Button}
        h="48px"
        color={brand}
        rightIcon={<ChevronDownIcon />}
        textAlign="left"
      >
        Currently Viewing:{" "}
        {currentWallet && currentWallet.name.length > 13
          ? currentWallet?.name.substring(0, 6) +
            "..." +
            currentWallet?.name.substring(currentWallet?.name.length - 6)
          : currentWallet?.name}
      </MenuButton>
      <MenuList w={{ md: "xl" }}>
        {sortSavedWallet(savedWallets).map((item, index) => (
          <MenuItem
            cursor={isLoadingWallets ? "wait" : undefined}
            key={index}
            onClick={() => chooseWallet(item)}
          >
            {item.name}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default WalletDropdown;
