import { useColorMode, useColorModeValue } from "@chakra-ui/react";
import { FaSun } from "react-icons/fa";
import { IconMoon } from "src/assets/icons";

const useAppColor = () => {
  const brand = "#01FFB0";
  const { toggleColorMode } = useColorMode();

  const text1 = useColorModeValue("black", "white");
  const text2 = useColorModeValue("gray.900", "gray.50");
  const text3 = useColorModeValue("gray.800", "gray.100");
  const text4 = useColorModeValue("gray.700", "gray.200");
  const text5 = useColorModeValue("gray.600", "gray.300");
  const text6 = useColorModeValue("gray.500", "gray.400");

  const main1 = useColorModeValue("white", "gray.900");
  const main2 = useColorModeValue("gray.50", "black");
  const main3 = useColorModeValue("gray.100", "gray.800");
  const main4 = useColorModeValue("gray.200", "gray.700");
  const main5 = useColorModeValue("gray.300", "gray.600");
  const main6 = useColorModeValue("gray.400", "gray.500");
  const main7 = useColorModeValue("white", "black");
  const main8 = useColorModeValue("black", "gray.900");
  const main9 = useColorModeValue("gray.900", "white");

  const ThemeIcon = useColorModeValue(IconMoon, FaSun);
  const theme = useColorModeValue("light", "dark");

  return {
    brand,
    text1,
    text2,
    text3,
    text4,
    text5,
    text6,
    main1,
    main2,
    main3,
    main4,
    main5,
    main6,
    main7,
    main8,
    main9,
    ThemeIcon,
    theme,
    toggleColorMode,
  };
};

export default useAppColor;
