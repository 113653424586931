import React from "react";
import { IconBase, IconBaseProps } from "react-icons";

const SvgComponent: React.FC<IconBaseProps> = (props: IconBaseProps) => {
  return (
    <IconBase {...props}>
      <svg viewBox="0 0 24 24">
        <path d="M11.9997 13.1727L16.9497 8.22266L18.3637 9.63666L11.9997 16.0007L5.63574 9.63666L7.04974 8.22266L11.9997 13.1727Z" />
      </svg>
    </IconBase>
  );
};
const IconArrowDown = React.memo(SvgComponent);
export default IconArrowDown;
