import React from "react";
import Icon from "@chakra-ui/icon";
import { SvgIconProps } from "src/constants/type";

const SvgComponent: React.FC<SvgIconProps> = ({
  size,
  ...props
}: SvgIconProps) => {
  return (
    <Icon {...props} w={size} h={size} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.26422 3.7643C3.32673 3.70178 3.41151 3.66667 3.49992 3.66667H11.9999C12.3681 3.66667 12.6666 3.36819 12.6666 3C12.6666 2.63181 12.3681 2.33333 11.9999 2.33333H3.49992C3.05789 2.33333 2.63397 2.50893 2.32141 2.82149C2.00885 3.13405 1.83325 3.55797 1.83325 4V12C1.83325 12.442 2.00885 12.8659 2.32141 13.1785C2.63397 13.4911 3.05789 13.6667 3.49992 13.6667H13.4999C13.8093 13.6667 14.1061 13.5438 14.3249 13.325C14.5437 13.1062 14.6666 12.8094 14.6666 12.5V5.5C14.6666 5.19058 14.5437 4.89383 14.3249 4.67504C14.1061 4.45625 13.8093 4.33333 13.4999 4.33333H3.49992C3.41151 4.33333 3.32673 4.29821 3.26422 4.2357C3.2017 4.17319 3.16659 4.0884 3.16659 4C3.16659 3.91159 3.2017 3.82681 3.26422 3.7643ZM3.16659 12V5.633C3.27548 5.65523 3.38711 5.66667 3.49992 5.66667H13.3333V12.3333H3.49992C3.41151 12.3333 3.32673 12.2982 3.26422 12.2357C3.2017 12.1732 3.16659 12.0884 3.16659 12ZM11.9999 9C11.9999 9.41421 11.6641 9.75 11.2499 9.75C10.8357 9.75 10.4999 9.41421 10.4999 9C10.4999 8.58579 10.8357 8.25 11.2499 8.25C11.6641 8.25 11.9999 8.58579 11.9999 9Z"
        fill="currentColor"
      />
    </Icon>
  );
};

const IconWallet = React.memo(SvgComponent);
export default IconWallet;
