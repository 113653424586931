import currency from "currency.js";
import BigNumber from "bignumber.js";
import exchangeRates, { IExchangeRate } from "src/constants/exchangeRates";

const currencies = {
  SGD: {
    name: "Singapore Dollars",
    compression: 2,
    symbol: "SGD",
    country_code: "sg",
  },
  USD: {
    name: "U.S. Dollars",
    compression: 2,
    symbol: "USD",
    country_code: "us",
  },
  ZIL: {
    name: "Zilliqa",
    compression: 12,
    symbol: "ZIL",
  },
  ITN: {
    name: "iToken",
    compression: 12,
    symbol: "ITN",
  },
};

export type IMoneyFormatterOptions = {
  currency?: any;
  symbol?: string;
  compression?: number;
  decPlaces?: number;
  maxFractionDigits?: number;
  showCurrency?: boolean;
  toHumanNumber?: boolean;
};

export const active = () => "USD";
export const map = () => JSON.parse(JSON.stringify(currencies));

const usdExchangeRate = exchangeRates[0];

export const formatUsdt = (
  value: number,
  exchangeRate: IExchangeRate,
  hide?: boolean
) => {
  if (hide) {
    return `${exchangeRate.unit}***`;
  }
  try {
    const rate = exchangeRate.value / usdExchangeRate.value;

    const tokenValue = exchangeRate.tokenValue || 1;

    return currency((parseFloat(`${value}`) * rate) / tokenValue, {
      symbol: exchangeRate.unit,
      precision: 2,
      pattern: exchangeRate.tokenValue ? `# !` : `!#`,
    }).format();
  } catch (error) {
    return `${exchangeRate.unit}0.00`;
  }
};

export const toBigNumber = (
  inputNumber: BigNumber | number | string = 0,
  opts: IMoneyFormatterOptions = {}
): BigNumber => {
  if (typeof inputNumber === "string") inputNumber = Number(inputNumber);
  if (typeof inputNumber === "number") {
    if (isNaN(inputNumber) || !isFinite(inputNumber)) return new BigNumber(0);
  }
  let number = new BigNumber(inputNumber);
  if (isNaN(number.toNumber())) number = new BigNumber(0);
  let { currency, compression = 0, decPlaces, maxFractionDigits = 2 } = opts;

  if (decPlaces === undefined) decPlaces = maxFractionDigits || 0;

  if (currency && !compression) {
    const defaultCurrency = active();
    const currencies = map();
    const currencyData = currencies[currency] || currencies[defaultCurrency];
    compression = currencyData.compression;
  }
  number = number.shiftedBy(-compression);
  return number;
};

export const formatToken = (value: number, symbol = "ZIL", hide?: boolean) => {
  if (hide) {
    return `***`;
  }
  try {
    return currency(value, {
      symbol: "",
      precision: value === 0 ? 2 : 5,
      pattern: `# !`,
    }).format();
  } catch (error) {
    return `0.00 `;
  }
};

export const formatTokenWithSymbol = (
  value: number,
  symbol = "ZIL",
  hide?: boolean
) => {
  if (hide) {
    return `*** ${symbol}`;
  }
  try {
    return currency(value, {
      symbol: symbol,
      precision: value === 0 ? 2 : 5,
      pattern: `# !`,
    }).format();
  } catch (error) {
    return `0.00 ${symbol}`;
  }
};

export const shortenFormatToken = (value: number, symbol = "ZIL") => {
  try {
    let newValue = value;
    let multiply = "";

    if (value >= Math.pow(10, 3)) {
      newValue = value / Math.pow(10, 3);
      multiply = "K";
    }
    if (value >= Math.pow(10, 6)) {
      newValue = value / Math.pow(10, 6);
      multiply = "M";
    }
    if (value >= Math.pow(10, 9)) {
      newValue = value / Math.pow(10, 9);
      multiply = "B";
    }
    if (value >= Math.pow(10, 12)) {
      newValue = value / Math.pow(10, 9);
      multiply = "T";
    }

    newValue = Math.round(newValue * 1000) / 1000;
    return `${newValue}${multiply} ${symbol}`;
  } catch (error) {
    return `0.000 ${symbol}`;
  }
};
