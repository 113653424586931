import BigNumber from "bignumber.js";
import { isConnectZilpay } from "src/api/zilpayApi";
import {
  IEstimateReward,
  IPendingReward,
  ISsnInfo,
  IStakingInfo,
  ITokenPool,
  IUnstaking,
} from "src/constants/interfaces";
import { IWallet } from "src/redux/slices/loginSlice";

/**
 * Check address staking or not
 * @param stakingList
 * @returns
 */
const isEmptyStakeList = (stakingList?: ISsnInfo[]) => {
  if (!stakingList || stakingList.length <= 0) {
    return true;
  }
  let result = true;

  stakingList.forEach((item) => {
    const { stakingBalance } = item;
    if (stakingBalance && stakingBalance > 0) {
      result = false;
    }
  });
  return result;
};

const isEmptyReward = (pendingRewards?: IPendingReward[]) => {
  if (!pendingRewards || pendingRewards.length <= 0) {
    return true;
  }
  let result = true;

  pendingRewards.forEach((reward) => {
    const { pendingReward } = reward;
    if (pendingReward && pendingReward > 0) {
      result = false;
    }
  });
  return result;
};

const isEmptyUnstakingPending = (unstakingPending: IUnstaking[]) => {
  return unstakingPending.length === 0;
};

const isEmptyDeposit = (staking: IStakingInfo) =>
  isEmptyStakeList(staking.stakeList) && isEmptyReward(staking.pendingRewards);

const isEmptyStaking = (staking: IStakingInfo) =>
  isEmptyStakeList(staking.stakeList) &&
  isEmptyReward(staking.pendingRewards) &&
  isEmptyUnstakingPending(staking.unstakingPending);

/**
 * Sort StakingList by balance and name
 * @param stakingList
 * @returns
 */

const sortStakingList = (stakingList: ISsnInfo[]) => {
  const sortedList = [...stakingList].sort(
    (a, b) =>
      (b?.stakingBalance || 0) - (a?.stakingBalance || 0) ||
      (a.name.toLowerCase() > b.name.toLowerCase()
        ? 1
        : a.name.toLowerCase() < b.name.toLowerCase()
          ? -1
          : 0)
  );
  return sortedList;
};

const profileSortLiquidityPool = (pools: ITokenPool[]) => {
  const sortedList = [...pools].sort(
    (a, b) =>
      (new BigNumber(b.userContribution || 0)?.toNumber() || 0) -
      (new BigNumber(a?.userContribution || 0)?.toNumber() || 0) ||
      (a.isSpecial && !b.isSpecial
        ? 1
        : !a.isSpecial && b.isSpecial
          ? -1
          : 0) ||
      (a.token.symbol.toLowerCase() > b.token.symbol.toLowerCase()
        ? 1
        : a.token.symbol.toLowerCase() < b.token.symbol.toLowerCase()
          ? -1
          : 0)
  );
  return sortedList;
};

const sortLiquidityPool = (pools: ITokenPool[]) => {
  const sortedList = [...pools].sort(
    (a, b) =>
      (b.userContribution?.toNumber() || 0) -
      (a?.userContribution?.toNumber() || 0) ||
      (a.isSpecial && !b.isSpecial
        ? 1
        : !a.isSpecial && b.isSpecial
          ? -1
          : 0) ||
      (a.token.symbol.toLowerCase() > b.token.symbol.toLowerCase()
        ? 1
        : a.token.symbol.toLowerCase() < b.token.symbol.toLowerCase()
          ? -1
          : 0)
  );
  return sortedList;
};

const isEmptyLiquidity = (checkPool: ITokenPool[]) => {
  const check = checkPool.filter(
    (item) =>
      item.userContribution &&
      item.userContribution.toNumber() > 0 &&
      item.totalContribution.toNumber() > 0
  );
  return check.length === 0;
};

const isEmptyProfileLiquidity = (checkPool: ITokenPool[]) => {
  const check = checkPool.filter((item) => {
    const {
      userContribution: _userContribution,
      totalContribution: _totalContribution,
    } = item;
    const userContribution = new BigNumber(_userContribution || 0);
    const totalContribution = new BigNumber(_totalContribution);
    return (
      userContribution &&
      userContribution.toNumber() > 0 &&
      totalContribution.toNumber() > 0
    );
  });
  return check.length === 0;
};

const isEmptyLiquidityShare = (estimateReward: IEstimateReward[]) =>
  estimateReward.filter((reward) => reward.estimateReward > 0).length === 0;

const availableToken = (tokenPool: ITokenPool) => {
  const {
    userContribution: _userContribution,
    totalContribution: _totalContribution,
  } = tokenPool;
  const userContribution = new BigNumber(_userContribution || 0);
  const totalContribution = new BigNumber(_totalContribution);
  return (
    !userContribution ||
    userContribution.toNumber() <= 0 ||
    totalContribution.toNumber() <= 0
  );
};

const sortSavedWallet = (savedWallets: IWallet[]) =>
  [...savedWallets].sort((a, b) =>
    isConnectZilpay(a.zilAddress) === isConnectZilpay(b.zilAddress)
      ? 0
      : isConnectZilpay(a.zilAddress)
        ? -1
        : 1
  );

export {
  isEmptyDeposit,
  isEmptyStaking,
  isEmptyReward,
  sortStakingList,
  sortLiquidityPool,
  profileSortLiquidityPool,
  isEmptyLiquidity,
  isEmptyLiquidityShare,
  isEmptyProfileLiquidity,
  availableToken,
  sortSavedWallet,
};
