import React from "react";
import { Text, Box, SimpleGrid, HStack } from "@chakra-ui/react";
import { NFTItem } from "src/atoms";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { useAppColor, useRouter } from "src/hooks";
import { useAppSelector } from "src/redux/hooks";
import { useTranslation } from "react-i18next";
import { ICollection } from "src/constants/interfaces";
import ViewCollection from "./ViewCollection";
import { viewProfileSelector } from "src/redux/slices/viewProfileSlice";

interface WalletNFTProps {
  searchText: string;
}

const WalletNFT: React.FC<WalletNFTProps> = ({ searchText }) => {
  const { t } = useTranslation(["overview", "common"]);
  const router = useRouter();
  const { text3, text2, text6, main1, main3, brand } = useAppColor();

  const { viewCollections, viewHeyAlfieNfts } =
    useAppSelector(viewProfileSelector);

  const [resultCollections, setResultCollections] =
    React.useState<ICollection[]>(viewCollections);

  const [limit, setLimit] = React.useState<number>(4);
  const [totalNfts, setTotalNfts] = React.useState([
    { title: "NFT Value", amount: "0" },
    { title: "Own Collection", amount: 0 },
    { title: "Own NFT", amount: 0 },
  ]);

  React.useEffect(() => {
    const ownCollections = viewCollections.length;
    let ownNfts = 0;
    viewCollections.forEach((collection) => {
      ownNfts += collection.smartContracts.length;
    });
    setTotalNfts([
      { title: "NFT Value", amount: "~$0" },
      { title: "Own Collection", amount: ownCollections },
      { title: "Own NFT", amount: ownNfts },
    ]);
  }, [viewCollections]);

  const isEmptyCollections = () =>
    viewCollections.length === 0 && viewHeyAlfieNfts.length === 0;

  React.useEffect(() => {
    if (searchText === "" || searchText === null || searchText === undefined) {
      setResultCollections(viewCollections);
    } else {
      let data = [];
      for (let i = 0; i < viewCollections.length; i++) {
        if (
          viewCollections[i].title
            .toLowerCase()
            .includes(searchText.toLowerCase())
        ) {
          data.push(viewCollections[i]);
        }
      }
      setResultCollections(data);
    }
  }, [searchText, viewCollections]);

  const onLoadMore = React.useCallback(() => {
    if (limit > viewCollections.length) {
      return;
    }
    setLimit(limit + 1);
  }, [limit, viewCollections]);

  window.onscroll = () => {
    if (
      window.innerHeight + window.scrollY + 0.5 >=
      document.body.offsetHeight
    ) {
      onLoadMore();
    }
  };

  return (
    <Box>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing="20px" pb="20px">
        {totalNfts.map((item: any, index) => {
          return (
            <HStack
              key={index}
              align="flex-start"
              justify="space-between"
              bg={main1}
              borderRadius="12px"
              p="20px"
            >
              <Text fontWeight="semibold" color={text2}>
                {item.title}
              </Text>
              <Text fontWeight="semibold" color={brand}>
                {item.amount}
              </Text>
            </HStack>
          );
        })}
      </SimpleGrid>
      {isEmptyCollections() ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          h="312px"
          bg={main1}
          borderRadius="12px"
          borderWidth="1px"
          fontSize="lg"
          lineHeight="7"
          borderColor={main3}
          color={text6}
        >
          {t("noneNft")}
        </Box>
      ) : (
        <>
          {resultCollections &&
            resultCollections.map((collection, index) => {
              if (index > limit - 1) {
                return <React.Fragment key={index} />;
              }
              return (
                <React.Fragment key={index}>
                  <Text
                    fontSize="lg"
                    lineHeight="7"
                    fontWeight="semibold"
                    color={text3}
                  >
                    {collection.title}
                  </Text>

                  {/* For wide screen */}
                  <Box py="8" display={{ base: "none", lg: "block" }}>
                    <SimpleGrid columns={5} spacing={4}>
                      {collection.smartContracts &&
                        collection.smartContracts.map((smartContract, idx) => {
                          if (idx < 4) {
                            return (
                              <NFTItem
                                smartContract={smartContract}
                                key={idx}
                                w="full"
                              />
                            );
                          }
                          return <React.Fragment key={idx} />;
                        })}

                      {collection.smartContracts.length > 4 && (
                        <ViewCollection
                          onClick={() =>
                            router.push({
                              pathname: "/nft",
                              state: collection,
                            })
                          }
                        />
                      )}
                    </SimpleGrid>
                  </Box>
                  {/* For small screen */}
                  <Box py="8" display={{ base: "block", lg: "none" }}>
                    <Swiper slidesPerView={1} spaceBetween={20} width={220}>
                      {collection.smartContracts &&
                        collection.smartContracts.map((i, idx) => {
                          if (idx < 5) {
                            return (
                              <SwiperSlide key={idx}>
                                <NFTItem smartContract={i} w="full" />
                              </SwiperSlide>
                            );
                          }
                          return <React.Fragment key={idx} />;
                        })}
                      {collection.smartContracts.length > 5 && (
                        <SwiperSlide>
                          <ViewCollection
                            h="auto"
                            onClick={() =>
                              router.push({
                                pathname: "/nft",
                                state: collection,
                              })
                            }
                          />
                        </SwiperSlide>
                      )}
                    </Swiper>
                  </Box>
                </React.Fragment>
              );
            })}
        </>
      )}
    </Box>
  );
};

export default WalletNFT;
