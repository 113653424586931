import React from "react";
import { Tr, Tbody, Th, useBoolean, Center, Td } from "@chakra-ui/react";
import { useAppSelector } from "src/redux/hooks";
import { overviewSelector } from "src/redux/slices/overviewSlice";
import { useAppColor } from "src/hooks";
import { useTranslation } from "react-i18next";
import StakingTableHeader from "./StakingTableHeader";
import StakingTableItem from "./StakingTableItem";
import { IStakingInfo } from "src/constants/interfaces";
import { IconFilledArrow } from "src/assets/icons";

interface StakingTableProps {
  claimToken: () => void;
  stakeToken: IStakingInfo;
  last?: boolean;
}

const StakingTable: React.FC<StakingTableProps> = ({
  stakeToken,
  claimToken,
  last,
}) => {
  const { t } = useTranslation(["overview", "common"]);
  const [show, setShow] = useBoolean(false);
  const [showAll, setShowAll] = useBoolean(false);
  const { main2, main3, text3, text5 } = useAppColor();

  const { tokenRates } = useAppSelector(overviewSelector);

  const { stakeList, symbol, pendingRewards } = stakeToken;

  const toggleShow = () => {
    setShow.toggle();
    setShowAll.off();
  };

  const headerList = [
    { children: t("seedNode") },
    { children: <React.Fragment /> },
    { children: t("deposit"), isNumeric: true },
    { children: t("unclaimedReward"), isNumeric: true },
    { children: t("action"), isNumeric: true },
  ];

  return (
    <React.Fragment>
      <StakingTableHeader
        last={last}
        toggleShow={toggleShow}
        stakeToken={stakeToken}
        claimToken={claimToken}
      />
      {!!show && (
        <>
          <Tbody bg={main3}>
            <Tr bg={main2}>
              {headerList.map((header, index) => (
                <Th key={index} {...header} />
              ))}
            </Tr>
            {stakeList &&
              stakeList.map((token, index) => {
                const { stakingBalance } = token;
                let rate = 1;
                let pendingReward = undefined;
                if (pendingRewards) {
                  pendingReward = pendingRewards.find(
                    (pendingReward) => pendingReward.address === token.address
                  );
                }
                if (
                  (!stakingBalance && !pendingReward && !showAll) ||
                  (stakingBalance &&
                    stakingBalance <= 0 &&
                    !pendingReward &&
                    !showAll)
                ) {
                  return <Tr key={index} />;
                }
                const tokenRate = tokenRates.find(
                  (tokenRate) => tokenRate.token.symbol === symbol
                );

                if (tokenRate) {
                  rate = tokenRate.rate;
                }

                return (
                  <StakingTableItem
                    key={index}
                    {...stakeToken}
                    {...token}
                    pendingReward={pendingReward}
                    rate={rate}
                  />
                );
              })}
            {stakeList.filter(
              (stake) => stake.stakingBalance && stake.stakingBalance > 0
            ).length !== stakeList.length && (
              <Tr>
                <Td
                  borderColor="transparent"
                  p="0"
                  position="relative"
                  colSpan={headerList.length}
                >
                  <Center>
                    <IconFilledArrow
                      position="absolute"
                      _groupHover={{
                        fill: text3,
                      }}
                      onClick={() => {
                        setShowAll.toggle();
                      }}
                      cursor="pointer"
                      size="6"
                      fill={text5}
                      style={{
                        transform: `rotate(${showAll ? 0 : 180}deg)`,
                      }}
                    />
                  </Center>
                </Td>
              </Tr>
            )}
          </Tbody>
        </>
      )}
    </React.Fragment>
  );
};

export default StakingTable;
