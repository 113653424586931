import React from "react";
import Icon from "@chakra-ui/icon";
import { SvgIconProps } from "src/constants/type";

const SvgComponent: React.FC<SvgIconProps> = ({
  size,
  ...props
}: SvgIconProps) => {
  return (
    <Icon {...props} w={size} h={size} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0834 2.50004C10.0834 2.13185 10.3819 1.83337 10.75 1.83337H13.6667C14.0349 1.83337 14.3334 2.13185 14.3334 2.50004V5.41671C14.3334 5.7849 14.0349 6.08337 13.6667 6.08337C13.2985 6.08337 13 5.7849 13 5.41671V3.16671H10.75C10.3819 3.16671 10.0834 2.86823 10.0834 2.50004ZM2.00004 10.5834C2.36823 10.5834 2.66671 10.8819 2.66671 11.25V13.5H4.91671C5.2849 13.5 5.58337 13.7985 5.58337 14.1667C5.58337 14.5349 5.2849 14.8334 4.91671 14.8334H2.00004C1.63185 14.8334 1.33337 14.5349 1.33337 14.1667V11.25C1.33337 10.8819 1.63185 10.5834 2.00004 10.5834ZM14.3334 11.25C14.3334 10.8819 14.0349 10.5834 13.6667 10.5834C13.2985 10.5834 13 10.8819 13 11.25V13.5H10.75C10.3819 13.5 10.0834 13.7985 10.0834 14.1667C10.0834 14.5349 10.3819 14.8334 10.75 14.8334H13.6667C14.0349 14.8334 14.3334 14.5349 14.3334 14.1667V11.25ZM1.33337 2.50004C1.33337 2.13185 1.63185 1.83337 2.00004 1.83337H4.91671C5.2849 1.83337 5.58337 2.13185 5.58337 2.50004C5.58337 2.86823 5.2849 3.16671 4.91671 3.16671H2.66671V5.41671C2.66671 5.7849 2.36823 6.08337 2.00004 6.08337C1.63185 6.08337 1.33337 5.7849 1.33337 5.41671V2.50004ZM11.3334 7.83337H8.66671V5.16671H7.33337V7.83337H4.66671V9.16671H7.33337V11.8334H8.66671V9.16671H11.3334V7.83337Z"
        fill="currentColor"
      />
    </Icon>
  );
};

const IconExpand = React.memo(SvgComponent);
export default IconExpand;
