import React from "react";
import {
  AvatarGroup,
  Box,
  Divider,
  Flex,
  useBoolean,
  Text,
} from "@chakra-ui/react";
import { IconFilledArrow } from "src/assets/icons";
import { TokenLogo } from "src/atoms";
import { IPoolInfo, IToken, ITokenPool } from "src/constants/interfaces";
import { formatToken } from "src/lib/formatCurrency";
import { MotionBox } from "src/molecules";
import {
  availableToken,
  handleGoLink,
  profileSortLiquidityPool,
} from "src/utils";
import LPZwapDetails from "./LPZwapDetails";
import { fromBech32Address } from "@zilliqa-js/zilliqa";
import BigNumber from "bignumber.js";
import { groupBy } from "lodash";
import { DistributionWithStatus } from "src/api/zilswapApi";
import { useAppColor } from "src/hooks";
import { useAppSelector } from "src/redux/hooks";
import { loginSelector } from "src/redux/slices/loginSlice";
import { overviewSelector } from "src/redux/slices/overviewSlice";
import { useTranslation } from "react-i18next";

interface IUnclaimedReward {
  token?: IToken;
  reward: number;
}

interface LPZwapMbProps {
  poolInfo: IPoolInfo;
  active?: boolean;
}

const LPZwapMb: React.FC<LPZwapMbProps> = ({ poolInfo, active }) => {
  const { name, link, logo, tokenPool, distributors, distributions, subName } =
    poolInfo;
  const { t } = useTranslation(["overview", "common", "profile"]);
  const { isHidden } = useAppSelector(loginSelector);
  const { tokens } = useAppSelector(overviewSelector);

  const { text1, text2, text3, text6, main3 } = useAppColor();

  const [show, setShow] = useBoolean(false);

  const [tokenList, setTokenList] = React.useState<string[]>([]);

  const [distributionsGroupByToken, setDistributionsGroup] =
    React.useState<any>();

  const [unclaimedReward, setUnclaimedReward] = React.useState<
    IUnclaimedReward[]
  >([
    {
      token: tokens.find((token) => token.symbol === "ZWAP"),
      reward: 0,
    },
  ]);

  React.useEffect(() => {
    let tokenList = [] as string[];

    tokenPool.forEach((tokenPool) => {
      const { token } = tokenPool;

      if (availableToken(tokenPool)) {
        return;
      }

      tokenList.push(token.icon);
      setTokenList(tokenList);
    });
  }, [tokenPool]);

  React.useEffect(() => {
    let unclaimedReward = [] as IUnclaimedReward[];
    if (!distributionsGroupByToken) {
      return;
    }
    Object.keys(distributionsGroupByToken).forEach((distribution) => {
      let totalReward = 0;
      distributionsGroupByToken[distribution].forEach((list: any) => {
        totalReward += new BigNumber(list.info.amount)
          .div(Math.pow(10, list.rewardToken.decimals))
          .toNumber();
      });
      unclaimedReward.push({
        reward: totalReward,
        token: distributionsGroupByToken[distribution][0].rewardToken,
      });
    });
    setUnclaimedReward(unclaimedReward);
  }, [distributionsGroupByToken]);

  React.useEffect(() => {
    if (!distributions || !distributors || !tokens) {
      return;
    }

    const distributionsWithToken = distributions
      .filter((distribution) => distribution.readyToClaim)
      .flatMap((d: DistributionWithStatus) => {
        const rewardDistributor = distributors.find((distributor) => {
          return distributor.distributor_address_hex === d.info.distrAddr;
        });

        if (!rewardDistributor) {
          return [];
        }

        const rewardToken = tokens.find((token) => {
          return (
            fromBech32Address(token.address_bech32).toLowerCase() ===
            rewardDistributor.reward_token_address_hex.toLowerCase()
          );
        });

        return [{ rewardToken, rewardDistributor, ...d }];
      })
      .filter((r) => !!r);

    const tokenGroupDistributions = groupBy(
      distributionsWithToken,
      (distribution) => {
        return distribution.rewardDistributor.reward_token_symbol;
      }
    );
    setDistributionsGroup(tokenGroupDistributions);
  }, [distributions, distributors, tokens]);

  return (
    <React.Fragment>
      <Box>
        <Flex alignItems="center" px="4">
          <TokenLogo src={logo} size="sm" mr="2" />
          <Flex
            alignItems="center"
            flex="1"
            justifyContent="space-between"
            py="3"
            borderBottomWidth="1px"
            borderBottomColor={main3}
          >
            <Box>
              <Text color={text2} fontSize="sm" fontWeight="semibold">
                {name}
              </Text>
              <Text
                color={text6}
                fontSize="xs"
                cursor="pointer"
                onClick={() => handleGoLink(link)}
              >
                {subName}
              </Text>
            </Box>
            <Flex alignItems="center">
              <IconFilledArrow
                fill={text1}
                cursor="pointer"
                onClick={() => setShow.toggle()}
                _hover={{
                  fill: text3,
                }}
                size="6"
                style={{
                  transform: `rotate(${show ? 0 : 180}deg)`,
                }}
              />
            </Flex>
          </Flex>
        </Flex>
      </Box>
      <Box px="4" pt="4">
        <AvatarGroup
          size="xs"
          spacing={2}
          max={3}
          fontSize="xx-small"
          justifyContent="end"
        >
          {tokenList.map((token, index) => (
            <TokenLogo src={token} key={index} />
          ))}
        </AvatarGroup>
      </Box>
      <Flex alignItems="flex-end" flexDirection="column" p="4" pr="14">
        <Flex
          alignItems="center"
          color={text1}
          fontSize="sm"
          fontWeight="semibold"
        >
          {unclaimedReward &&
            unclaimedReward.map((reward, index) => {
              if (index > 1) {
                return <div key={index} />;
              }
              return (
                <React.Fragment key={index}>
                  <Text ml="1">
                    {index !== 0 && " + "}
                    {formatToken(
                      reward.reward,
                      reward && reward.token ? reward.token.symbol : "ZWAP",
                      isHidden
                    )}
                  </Text>
                  <TokenLogo size="2xs" src={reward.token?.icon} ml="1" />
                </React.Fragment>
              );
            })}
          {unclaimedReward && unclaimedReward.length > 2 && (
            <Text ml="2">...</Text>
          )}
        </Flex>
        <Text color={text3} fontSize="xs">
          {t("rewardDistributed")}
        </Text>
      </Flex>
      {show && (
        <MotionBox
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            transition: { duration: 0.5 },
          }}
        >
          {profileSortLiquidityPool(tokenPool).map((item: ITokenPool, i) => {
            if (availableToken(item) && !!active) {
              return <Box key={i} />;
            }
            return <LPZwapDetails key={i} item={item} />;
          })}
        </MotionBox>
      )}
      <Divider />
    </React.Fragment>
  );
};

export default LPZwapMb;
