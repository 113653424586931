import { toBech32Address, Zilliqa } from "@zilliqa-js/zilliqa";
import { validation } from "@zilliqa-js/util";
import { fromBech32Address, isValidChecksumAddress } from "@zilliqa-js/crypto";
import { ISsnInfo, IToken } from "src/constants/interfaces";
import { EnumNetwork } from "./network";
import BigNumber from "bignumber.js";
import { ICollectionAddress } from "./apiInterfaces";

export enum EnumBatchRequestType {
  SmartContractInit = "smartContractInit",
  SmartContractState = "smartContractState",

  Balance = "balance",
  TokenBalance = "tokenBalance",
  TokenAllowance = "tokenAllowance",
  Pools = "pools",
  PoolBalance = "poolBalance",
  TotalContributions = "totalContributions",
  XcadDexPools = "xcadDexPools",
  XcadDexPoolsBalance = "xcadDexPoolsBalance",
  XcadDexTotalContribution = "xcadDexTotalContribution",
  XcadDexPoolRewards = "xcadDexPoolRewards",
  XcadZilPools = "xcadZilPools",
  XcadZilPoolsBalance = "xcadZilPoolsBalance",
  XcadZilTotalContribution = "xcadZilPoolsTotalContribution",
  XcadZilPoolRewards = "xcadZilPoolRewards",
  CarbSwapPools = "carbSwapPools",
  CarbSwapPoolsBalance = "carbSwapPoolsBalance",
  CarbSwapPoolTotalContributions = "carbSwapPoolTotalContribution",
  HunyVaultPoolsBalance = "hunyVaultPoolsBalance",
  HunyVaultPoolTotalContributions = "hunyVaultPoolTotalContribution",

  StakingOperators = "stakingOperators",
  StakingDelegators = "stakingDelegators",
  PortStakers = "portStakers",
  PortPendingRewards = "portPendingRewards",
  CarbonStakers = "carbonStakers",
  CarbonPendingCarbs = "carbonPendingCarbs",
  UnifeeStakers = "unifeeStakers",
  UnifeePendingRewards = "unifeePendingRewards",
  BloxStakers = "bloxStakers",
  BloxPendingRewards = "bloxPendingRewards",
  DemonzStakers = "demonzStakers",
  XcadStakers = "xcadStakers",
  XcadPendingRewards = "xcadPendingRewards",
  ScoStakers = "scoStakers",
  ScoPendingCarbs = "scoPendingCarbs",
  OkiStakers = "okiStakers",
  OkiPendingRewards = "okiPendingRewards",
  GrphStakers = "grphStakers",
  GrphPendingRewards = "grphPendingRewards",
  LunarStakers = "lunarStakers",
  LunarPendingRewards = "lunarPendingRewards",
}

const zilSwapAddress = "zil1gkwt95a67lnpe774lcmz72y6ay4jh2asmmjw6u";
const zilSwapHash = fromBech32Address(zilSwapAddress);

const zillionStakingAddress = "zil15lr86jwg937urdeayvtypvhy6pnp6d7p8n5z09";
const zillionStakingHash = fromBech32Address(zillionStakingAddress);

const xcadDexAddress = "zil1r7c6fltm4993v9myr4sz9wjgeta80mhs59sq67";
const xcadDexHash = fromBech32Address(xcadDexAddress);

const carbSwapAddress = "zil1m8wfldkkm6spqdmg4ws269772a8q9wlzl8nr9u";
const carbSwapHash = fromBech32Address(carbSwapAddress);

const hunyVaultAddress = "zil1328u4vxdsguz09qxlzp2e8ayt3p0mt33glpcl6";
const hunyVaultHash = fromBech32Address(hunyVaultAddress);

export const carbStakingAddress = "zil18r37xks4r3rj7rzydujcckzlylftdy2qerszne";
export const carbStakingHash = fromBech32Address(carbStakingAddress);

export const scoStakingAddress = "zil1mvatdevqlk9q07225umuv7a4t295aqycgzz3dc";
export const scoStakingHash = fromBech32Address(scoStakingAddress);

export const okiStakingAddress = "zil1eeahtrggk3m77nu40ltmaqdmtcyhdh9ujahgf6";
export const okiStakingHash = fromBech32Address(okiStakingAddress);

export const bloxStakingAddress = "zil1heh4x9lhp2cuma9n8qvrap80ax900s9024dmlc";
export const bloxStakingHash = fromBech32Address(bloxStakingAddress);

export const demonzStakingAddress =
  "zil1n93rsttd44ryckftu8900ketkdz0ky3eu2ljqe";
export const demonzStakingHash = fromBech32Address(demonzStakingAddress);

export const grphStakingAddress = "zil1la9r6xxlth28rxmhuka0dgc7mpfnk4x5jwcw3l";
export const grphStakingHash = fromBech32Address(grphStakingAddress);

export const portStakingHashes = [
  {
    name: "The Dock",
    seedNode: 19,
    hash: fromBech32Address("zil1yhy3wm79cx8v9zyg7qecwa457w0ysupgvzk5pt"), // 90 days
  },
  {
    name: "The Buoy",
    seedNode: 5,
    hash: fromBech32Address("zil1lkhea3egremrwtn4lfhsa4psk978k2sat3cs3u"), // 7 days
  },
];

export const xcadStakingHashes = [
  {
    name: "XCAD - XCAD | 180 Days",
    address: "zil1efh3a4t75hv68t024lyznrl52j3umerwwfvr6f",
    stakeSymbol: "XCAD",
    rewardSymbol: "XCAD",
    hash: "0xca6f1ed57ea5d9a3adeaafc8298ff454a3cde46e",
  },
  {
    name: "XCAD - XCAD | 120 Days",
    address: "zil1fw4tdnlswzfnrszgh7wlyr0g0jum72r4mhqecm",
    stakeSymbol: "XCAD",
    rewardSymbol: "XCAD",
    hash: "0x4baab6cff0709331c048bf9df20de87cb9bf2875",
  },
  {
    name: "XCAD - XCAD | 90 Days",
    address: "zil1p8pak57jxqap2f82k7g0cmkywrx7xyvnmj4cep",
    stakeSymbol: "XCAD",
    rewardSymbol: "XCAD",
    hash: "0x09c3db53d2303a1524eab790fc6ec470cde31193",
  },
  {
    name: "XCAD - XCAD",
    address: "zil1pdce67ghg8fexl82tdnpch2xn96q5crrhnugwq",
    rewardSymbol: "XCAD",
    stakeSymbol: "XCAD",
    hash: "0x0b719d791741d3937cea5b661c5d4699740a6063",
  },
  {
    name: "XCAD - dXCAD",
    address: "zil1g53vg867vrky0glghvhcuu72dn7zhc95nhpuqx",
    rewardSymbol: "dXCAD",
    stakeSymbol: "XCAD",
  },
  {
    name: "XCAD - dXCAD",
    address: "zil135jt4t5cwltntyqgwwp5gjph8wy30kslsdljsz",
    rewardSymbol: "dXCAD",
    stakeSymbol: "XCAD",
  },
  {
    name: "XCAD - dXCAD",
    address: "zil1k9d8ej0upz3vwlukkhvf92clrfx0qgkvrcc94x",
    rewardSymbol: "dXCAD",
    stakeSymbol: "XCAD",
    hash: "0xb15a7cc9fc08a2c77f96b5d892ab1f1a4cf022cc",
  },
  {
    name: "dXCAD - SCO",
    address: "zil1vt8wm7ns5d8uh6kwhdkhd09lxw0dgajgwqv37p",
    rewardSymbol: "SCO",
    stakeSymbol: "dXCAD",
    hash: "0x62ceedfa70a34fcbeacebb6d76bcbf339ed47648",
  },
  {
    name: "dXCAD - DMZ",
    address: "zil1tzzp8stxgsrfmqkg8z4g448drj6m7k52qhcsu0",
    rewardSymbol: "DMZ",
    stakeSymbol: "dXCAD",
    hash: "0x588413c16644069d82c838aa8ad4ed1cb5bf5a8a",
  },
  {
    name: "dXCAD - zBRKL",
    address: "zil1j62693l6tts5ru0dn9x5lczdz93vg3p8y7nr9r",
    rewardSymbol: "zBRKL",
    stakeSymbol: "dXCAD",
    hash: "0x9695a2c7fa5ae141f1ed994d4fe04d1162c44427",
  },
  {
    name: "dXCAD - BLOX",
    address: "zil15lvcvtwwatfme4pcz9rzzx9l7zrn0gp6tfe0sz",
    rewardSymbol: "BLOX",
    stakeSymbol: "dXCAD",
    hash: "0xa7d9862dceead3bcd43811462118bff08737a03a",
  },
  {
    name: "dXCAD - DUCK",
    address: "zil1m32y9rdszgr4ysu94vrmgx9l2s00ak47slsf3d",
    rewardSymbol: "DUCK",
    stakeSymbol: "dXCAD",
    hash: "0xdc54428db01207524385ab07b418bf541efedabe",
  },
  {
    name: "dXCAD - HOL",
    address: "zil17h6wvmr925df5jxaz3nc8nswca2gxc5pa64qu2",
    rewardSymbol: "HOL",
    stakeSymbol: "dXCAD",
    hash: "0xf5f4e66c65551a9a48dd146783ce0ec754836281",
  },
  {
    name: "dXCAD - FEES",
    address: "zil15yw0236v6yewduvp9slzpwj8u5vp3jmz4mfy3x",
    rewardSymbol: "FEES",
    stakeSymbol: "dXCAD",
    hash: "0xa11cf5474cd132e6f1812c3e20ba47e51818cb62",
  },
  {
    name: "dXCAD - Oki",
    address: "zil156v5hrvv25cdrxt06ahcnhc9ywuf8ewstqrl6w",
    rewardSymbol: "Oki",
    stakeSymbol: "dXCAD",
    hash: "0xa6994b8d8c5530d1996fd76f89df0523b893e5d0",
  },
  {
    name: "dXCAD - PORT",
    address: "zil1cer5kct0hdcle2xu7jcfkh4pk4fjxxjd82ther",
    rewardSymbol: "PORT",
    stakeSymbol: "dXCAD",
    hash: "0xc6474b616fbb71fca8dcf4b09b5ea1b553231a4d",
  },
  {
    name: "dXCAD - CARB",
    address: "zil1hyxx8yhzc4gw4l64lk7gzqdlyn9kasux3mmfl9",
    rewardSymbol: "CARB",
    stakeSymbol: "dXCAD",
    hash: "0xb90c6392e2c550eaff55fdbc8101bf24cb6ec386",
  },
  {
    name: "dXCAD - dXCAD",
    address: "zil15wtur23s2j76mzhv7ez69dvzyqhw54aerlwpna",
    rewardSymbol: "dXCAD",
    stakeSymbol: "dXCAD",
    hash: "0xa397c1aa3054bdad8aecf645a2b582202eea57b9",
  },
  {
    name: "dXCAD - dXCAD",
    address: "zil1mgf04qafec5sad0ph04xu504lj3f2khp8zqgjy",
    rewardSymbol: "dXCAD",
    stakeSymbol: "dXCAD",
  },
  {
    name: "dXCAD - STREAM",
    address: "zil1tvd73crh7ne8q260elunmlhuvqgt6gmsw3vn9y",
    rewardSymbol: "STREAM",
    stakeSymbol: "dXCAD",
    hash: "0x5b1be8e077f4f2702b4fcff93dfefc6010bd2370",
  },
  {
    name: "dXCAD - REDC",
    address: "zil1c79kcjnuz0c724hwtxhjp3603jg4de4k350kxq",
    rewardSymbol: "REDC",
    stakeSymbol: "dXCAD",
    hash: "0xc78b6c4a7c13f1e556ee59af20c74f8c9156e6b6",
  },
  {
    name: "dXCAD - SPW",
    address: "zil1e7rmmplr9cze2v7sk63v2awm8fdgx7fvuvnr92",
    rewardSymbol: "SPW",
    stakeSymbol: "dXCAD",
    hash: "0xcf87bd87e32e059533d0b6a2c575db3a5a83792c",
  },
  {
    name: "dXCAD - zOPUL",
    address: "zil1p0cyc6hzs0y6q492zhd6fxt0jfrr2xffvalxtl",
    rewardSymbol: "zOPUL",
    stakeSymbol: "dXCAD",
  },
];

export const unifeeStakingHashes = [
  {
    name: "Bachelor’s",
    seedNode: 8,
    hash: fromBech32Address("zil19hl9kaq3ddpqlr58qy8ewn9vcyueundmx22uyp"),
  },
  {
    name: "Master’s",
    seedNode: 20,
    hash: fromBech32Address("zil1yvnanl43330kfw272vxgu7yucctt0sk5syejln"),
  },
  {
    name: "Doctoral",
    hash: fromBech32Address("zil1t40vh32v888m9s9e9uzrhgc6kj4030urquvylt"),
    seedNode: 30,
  },
];

export const lunarStakingHashes = [
  {
    name: "LunrFi 1-Month",
    seedNode: 8,
    hash: fromBech32Address("zil10pt00wklns2aetdnfvg4fe9ng65ser42z2cwuk"),
  },
  {
    name: "LunrFi 3-Month",
    seedNode: 12,
    hash: fromBech32Address("zil1eft78svmxf0cxn82ysdtxxs6ky8e85xnzfvzrh"),
  },
  {
    name: "LunrFi 6-Month",
    seedNode: 19,
    hash: fromBech32Address("zil1dt57qg37zmxzm58tvdx8rvyrk09zpsxwc77hpp"),
  },
  {
    name: "LunrFi 1-Year",
    seedNode: 25,
    hash: fromBech32Address("zil1qs6ppsy0p6rnaqznx3zs9q4rafmu4p8qtm3pdd"),
  },
];

declare global {
  interface Window {
    zilPay: any;
  }
}

export const normaliseAddress = (address: string) => {
  if (validation.isBech32(address)) {
    return fromBech32Address(address);
  }

  if (isValidChecksumAddress(address)) {
    return address;
  }

  throw new Error("Address format is invalid");
};

export const to32Address = (address: string) => {
  if (validation.isAddress(address)) {
    return toBech32Address(address);
  }

  if (isValidChecksumAddress(address)) {
    return address;
  }

  throw new Error("Address format is invalid");
};

export const connectZilpayService = async () => {
  if (!window.zilPay) {
    return null;
  }

  const isConnected = await window.zilPay.wallet.connect();

  if (!isConnected) {
    return null;
  }

  return window.zilPay.wallet.defaultAccount;
};

interface IBatchRequestItem {
  id: string;
  jsonrpc: string;
  method: string;
  params: any[];
}

interface IBatchRequest {
  type: string;
  token?: IToken;
  name?: string;
  seedNode?: number | string;
  contractAddress?: string;
  item: IBatchRequestItem;
  collectionAddress?: ICollectionAddress;
  rewardSymbol?: string;
  stakeSymbol?: string;
}
export interface IBatchResponse {
  request: IBatchRequest;
  result: any;
}

/**
 * Create a `GetBalance` request.
 *
 * @param IToken The token for which it's requested.
 * @param string The wallet address.
 * @returns IBatchRequest
 */
export const balanceBatchRequest = (
  token: IToken,
  address: string
): IBatchRequest => {
  const walletAddress = fromBech32Address(address)
    .replace("0x", "")
    .toLowerCase();
  return {
    type: EnumBatchRequestType.Balance,
    token: token,
    item: {
      id: "1",
      jsonrpc: "2.0",
      method: "GetBalance",
      params: [walletAddress],
    },
  };
};

/**
 * Create a `GetSmartContractSubState` request for the balances variable.
 *
 * @param IToken The token for which it's requested.
 * @param string The wallet address.
 * @returns IBatchRequest
 */
export const tokenBalanceBatchRequest = (
  token: IToken,
  walletAddress: string
): IBatchRequest => {
  const address = fromBech32Address(token.address_bech32);
  const walletAddr = fromBech32Address(walletAddress).toLowerCase();
  return {
    type: EnumBatchRequestType.TokenBalance,
    token: token,
    item: {
      id: "1",
      jsonrpc: "2.0",
      method: "GetSmartContractSubState",
      params: [
        address.replace("0x", "").toLowerCase(),
        "balances",
        [walletAddr],
      ],
    },
  };
};

/**
 * Create a `GetSmartContractSubState` request for the allowances variable.
 *
 * @param IToken The token for which it's requested.
 * @param string The wallet address.
 * @returns IBatchRequest
 */
export const tokenAllowancesBatchRequest = (
  token: IToken,
  walletAddress: string
): IBatchRequest => {
  const address = fromBech32Address(token.address_bech32);
  return {
    type: EnumBatchRequestType.TokenAllowance,
    token: token,
    item: {
      id: "1",
      jsonrpc: "2.0",
      method: "GetSmartContractSubState",
      params: [
        address.replace("0x", "").toLowerCase(),
        "allowances",
        [fromBech32Address(walletAddress).toLowerCase()],
      ],
    },
  };
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////// POOL API
// #region POOL
/**
 * Create a `GetSmartContractSubState` request for the token pools.
 *
 * @returns IBatchRequest
 */
export const poolsBatchRequest = (): IBatchRequest => {
  return {
    type: EnumBatchRequestType.Pools,
    token: undefined,
    item: {
      id: "1",
      jsonrpc: "2.0",
      method: "GetSmartContractSubState",
      params: [zilSwapHash.replace("0x", "").toLowerCase(), "pools", []],
    },
  };
};

/**
 * Create a `GetSmartContractSubState` request for the token pool total contributions.
 *
 * @returns IBatchRequest
 */
export const totalContributionsBatchRequest = (): IBatchRequest => {
  return {
    type: EnumBatchRequestType.TotalContributions,
    token: undefined,
    item: {
      id: "1",
      jsonrpc: "2.0",
      method: "GetSmartContractSubState",
      params: [
        zilSwapHash.replace("0x", "").toLowerCase(),
        "total_contributions",
        [],
      ],
    },
  };
};

/**
 * Create a `GetSmartContractSubState` request for the token pool balance.
 *
 * @param IToken The token for which it's requested.
 * @param string The wallet address.
 * @returns IBatchRequest
 */
export const tokenPoolBalanceBatchRequest = (): IBatchRequest => {
  return {
    type: EnumBatchRequestType.PoolBalance,
    item: {
      id: "1",
      jsonrpc: "2.0",
      method: "GetSmartContractSubState",
      params: [zilSwapHash.replace("0x", "").toLowerCase(), "balances", []],
    },
  };
};

/**
 * Create a `GetSmartContractSubState` request for the staking operators.
 *
 * @returns IBatchRequest
 */
export const stakingOperatorsBatchRequest = (): IBatchRequest => {
  return {
    type: EnumBatchRequestType.StakingOperators,
    token: undefined,
    item: {
      id: "1",
      jsonrpc: "2.0",
      method: "GetSmartContractSubState",
      params: [
        zillionStakingHash.replace("0x", "").toLowerCase(),
        "ssnlist",
        [],
      ],
    },
  };
};

/**
 * Create a `GetSmartContractSubState` request for the staking delegators.
 *
 * @param Operator The operator for which it's requested.
 * @param string The wallet address.
 * @returns IBatchRequest
 */
export const stakingDelegatorsBatchRequest = (
  operator: ISsnInfo,
  walletAddress: string
): IBatchRequest => {
  return {
    type: EnumBatchRequestType.StakingDelegators,
    token: undefined,
    item: {
      id: "1",
      jsonrpc: "2.0",
      method: "GetSmartContractSubState",
      params: [
        zillionStakingHash.replace("0x", "").toLowerCase(),
        "ssn_deleg_amt",
        [operator.address, fromBech32Address(walletAddress).toLowerCase()],
      ],
    },
  };
};

/**
 * Create a `GetSmartContractSubState` request for the token pool balance.
 *
 * @param IToken The token for which it's requested.
 * @param string The wallet address.
 * @returns IBatchRequest
 */
export const xcadPoolsBatchRequest = (): IBatchRequest => {
  return {
    type: EnumBatchRequestType.XcadDexPools,
    token: undefined,
    item: {
      id: "1",
      jsonrpc: "2.0",
      method: "GetSmartContractSubState",
      params: [xcadDexHash.replace("0x", "").toLowerCase(), "xpools", []],
    },
  };
};

/**
 * Create a `GetSmartContractSubState` request for the xcad token pool total contributions.
 *
 * @returns IBatchRequest
 */
export const xcadTotalContributionsBatchRequest = (): IBatchRequest => {
  return {
    type: EnumBatchRequestType.XcadDexTotalContribution,
    token: undefined,
    item: {
      id: "1",
      jsonrpc: "2.0",
      method: "GetSmartContractSubState",
      params: [
        xcadDexHash.replace("0x", "").toLowerCase(),
        "xtotal_contributions",
        [],
      ],
    },
  };
};

/**
 * Create a `GetSmartContractSubState` request for the xcad token pool balance.
 *
 * @param IToken The token for which it's requested.
 * @param string The wallet address.
 * @returns IBatchRequest
 */
export const xcadTokenPoolBalanceBatchRequest = (): IBatchRequest => {
  return {
    type: EnumBatchRequestType.XcadDexPoolsBalance,
    token: undefined,
    item: {
      id: "1",
      jsonrpc: "2.0",
      method: "GetSmartContractSubState",
      params: [xcadDexHash.replace("0x", "").toLowerCase(), "xbalances", []],
    },
  };
};

/**
 * Create a `GetSmartContractSubState` request for the token pool balance.
 *
 * @param IToken The token for which it's requested.
 * @param string The wallet address.
 * @returns IBatchRequest
 */
export const xcadZilPoolsBatchRequest = (): IBatchRequest => {
  return {
    type: EnumBatchRequestType.XcadZilPools,
    token: undefined,
    item: {
      id: "1",
      jsonrpc: "2.0",
      method: "GetSmartContractSubState",
      params: [xcadDexHash.replace("0x", "").toLowerCase(), "pools", []],
    },
  };
};

/**
 * Create a `GetSmartContractSubState` request for the xcad token pool total contributions.
 *
 * @returns IBatchRequest
 */
export const xcadZilTotalContributionsBatchRequest = (): IBatchRequest => {
  return {
    type: EnumBatchRequestType.XcadZilTotalContribution,
    token: undefined,
    item: {
      id: "1",
      jsonrpc: "2.0",
      method: "GetSmartContractSubState",
      params: [
        xcadDexHash.replace("0x", "").toLowerCase(),
        "total_contributions",
        [],
      ],
    },
  };
};

/**
 * Create a `GetSmartContractSubState` request for the token pool balance.
 *
 * @returns IBatchRequest
 */
export const carbSwapPoolsBatchRequest = (): IBatchRequest => {
  return {
    type: EnumBatchRequestType.CarbSwapPools,
    token: undefined,
    item: {
      id: "1",
      jsonrpc: "2.0",
      method: "GetSmartContractSubState",
      params: [carbSwapHash.replace("0x", "").toLowerCase(), "pools", []],
    },
  };
};

/**
 * Create a `GetSmartContractSubState` request for the carbswap token pool total contributions.
 *
 * @returns IBatchRequest
 */
export const carbSwapTotalContributionsBatchRequest = (): IBatchRequest => {
  return {
    type: EnumBatchRequestType.CarbSwapPoolTotalContributions,
    token: undefined,
    item: {
      id: "1",
      jsonrpc: "2.0",
      method: "GetSmartContractSubState",
      params: [
        carbSwapHash.replace("0x", "").toLowerCase(),
        "total_contributions",
        [],
      ],
    },
  };
};
/**
 * Create a `GetSmartContractSubState` request for the carbswap token pool balance.
 *
 * @returns IBatchRequest
 */
export const carbSwapPoolBalanceBatchRequest = (): IBatchRequest => {
  return {
    type: EnumBatchRequestType.CarbSwapPoolsBalance,
    token: undefined,
    item: {
      id: "1",
      jsonrpc: "2.0",
      method: "GetSmartContractSubState",
      params: [carbSwapHash.replace("0x", "").toLowerCase(), "balances", []],
    },
  };
};

/**
 * Create a `GetSmartContractSubState` request for the huny vault token pool total contributions.
 *
 * @returns IBatchRequest
 */
export const hunyVaultTotalContributionsBatchRequest = (): IBatchRequest => {
  return {
    type: EnumBatchRequestType.HunyVaultPoolTotalContributions,
    token: undefined,
    item: {
      id: "1",
      jsonrpc: "2.0",
      method: "GetSmartContractSubState",
      params: [
        hunyVaultHash.replace("0x", "").toLowerCase(),
        "total_contribution",
        [],
      ],
    },
  };
};
/**
 * Create a `GetSmartContractSubState` request for the huny vault token pool balance.
 *
 * @returns IBatchRequest
 */
export const hunyVaultPoolBalanceBatchRequest = (): IBatchRequest => {
  return {
    type: EnumBatchRequestType.HunyVaultPoolsBalance,
    token: undefined,
    item: {
      id: "1",
      jsonrpc: "2.0",
      method: "GetSmartContractSubState",
      params: [hunyVaultHash.replace("0x", "").toLowerCase(), "balances", []],
    },
  };
};

/**
 * Create a `GetSmartContractSubState` request for the xcad token pool balance.
 *
 * @param IToken The token for which it's requested.
 * @param string The wallet address.
 * @returns IBatchRequest
 */
export const xcadZilPoolBalanceBatchRequest = (): IBatchRequest => {
  return {
    type: EnumBatchRequestType.XcadZilPoolsBalance,
    token: undefined,
    item: {
      id: "1",
      jsonrpc: "2.0",
      method: "GetSmartContractSubState",
      params: [xcadDexHash.replace("0x", "").toLowerCase(), "balances", []],
    },
  };
};

// #endregion

//////////////////////////////////////////////////////////////////////////////////////////////////////////// STAKING API
// #region STAKING

/**
 * Create a `GetSmartContractSubState` request for the xcad stakers.
 *
 * @param string The wallet address.
 * @returns IBatchRequest
 */
export const xcadStakersBatchRequest = (
  contractAddress: string,
  name: string,
  stake: string,
  reward: string
): IBatchRequest => {
  return {
    type: EnumBatchRequestType.XcadStakers,
    token: undefined,
    contractAddress: contractAddress,
    name: name,
    stakeSymbol: stake,
    rewardSymbol: reward,
    item: {
      id: "1",
      jsonrpc: "2.0",
      method: "GetSmartContractSubState",
      params: [
        contractAddress.replace("0x", "").toLowerCase(),
        "stakers_total_bal",
        [],
      ],
    },
  };
};

/**
 * Create a `GetSmartContractSubState` request for the pending xcad.
 *
 * @param string The wallet address.
 * @returns IBatchRequest
 */
export const xcadPendingRewardsBatchRequest = (
  contractAddress: string,
  name: string,
  stake: string,
  reward: string
): IBatchRequest => {
  return {
    type: EnumBatchRequestType.XcadPendingRewards,
    token: undefined,
    contractAddress: contractAddress,
    name: name,
    stakeSymbol: stake,
    rewardSymbol: reward,
    item: {
      id: "1",
      jsonrpc: "2.0",
      method: "GetSmartContractSubState",
      params: [
        contractAddress.replace("0x", "").toLowerCase(),
        "last_deposit_cycle",
        [],
      ],
    },
  };
};

/**
 * Create a `GetSmartContractSubState` request for the Score stakers.
 *
 * @param string The wallet address.
 * @returns IBatchRequest
 */
export const scoStakersBatchRequest = (
  walletAddress: string
): IBatchRequest => {
  return {
    type: EnumBatchRequestType.ScoStakers,
    token: undefined,
    contractAddress: scoStakingHash,
    item: {
      id: "1",
      jsonrpc: "2.0",
      method: "GetSmartContractSubState",
      params: [
        scoStakingHash.replace("0x", "").toLowerCase(),
        "map_wallets",
        [fromBech32Address(walletAddress).toLowerCase()],
      ],
    },
  };
};

/**
 * Create a `GetSmartContractSubState` request for the Port stakers.
 *
 * @param string The wallet address.
 * @returns IBatchRequest
 */
export const portStakersBatchRequest = (
  walletAddress: string,
  portStakingHash: string,
  name: string,
  seedNode: number | string
): IBatchRequest => {
  return {
    type: EnumBatchRequestType.PortStakers,
    name: name,
    seedNode: seedNode,
    contractAddress: portStakingHash,
    item: {
      id: "1",
      jsonrpc: "2.0",
      method: "GetSmartContractSubState",
      params: [
        portStakingHash.replace("0x", "").toLowerCase(),
        "stakers",
        [fromBech32Address(walletAddress).toLowerCase()],
      ],
    },
  };
};

/**
 * Create a `GetSmartContractSubState` request for the port pending reward.
 *
 * @param string The wallet address.
 * @returns IBatchRequest
 */
export const portPendingRewardsBatchRequest = (
  walletAddress: string,
  portStakingHash: string
): IBatchRequest => {
  return {
    type: EnumBatchRequestType.PortPendingRewards,
    contractAddress: portStakingHash,
    item: {
      id: "1",
      jsonrpc: "2.0",
      method: "GetSmartContractSubState",
      params: [
        portStakingHash.replace("0x", "").toLowerCase(),
        "pendingRewards",
        [fromBech32Address(walletAddress).toLowerCase()],
      ],
    },
  };
};

/**
 * Create a `GetSmartContractSubState` request for the carb stakers.
 *
 * @param string The wallet address.
 * @returns IBatchRequest
 */
export const carbonStakersBatchRequest = (
  walletAddress: string
): IBatchRequest => {
  return {
    type: EnumBatchRequestType.CarbonStakers,
    token: undefined,
    contractAddress: carbStakingHash,
    item: {
      id: "1",
      jsonrpc: "2.0",
      method: "GetSmartContractSubState",
      params: [
        carbStakingHash.replace("0x", "").toLowerCase(),
        "stakers",
        [fromBech32Address(walletAddress).toLowerCase()],
      ],
    },
  };
};

/**
 * Create a `GetSmartContractSubState` request for the pending carbs.
 *
 * @param string The wallet address.
 * @returns IBatchRequest
 */
export const carbonPendingCarbsBatchRequest = (
  walletAddress: string
): IBatchRequest => {
  return {
    type: EnumBatchRequestType.CarbonPendingCarbs,
    token: undefined,
    contractAddress: carbStakingHash,
    item: {
      id: "1",
      jsonrpc: "2.0",
      method: "GetSmartContractSubState",
      params: [
        carbStakingHash.replace("0x", "").toLowerCase(),
        "pending_carbs",
        [fromBech32Address(walletAddress).toLowerCase()],
      ],
    },
  };
};

/**
 * Create a `GetSmartContractSubState` request for the oki stakers.
 *
 * @param string The wallet address.
 * @returns IBatchRequest
 */
export const okiStakersBatchRequest = (): IBatchRequest => {
  return {
    type: EnumBatchRequestType.OkiStakers,
    token: undefined,
    contractAddress: okiStakingHash,
    item: {
      id: "1",
      jsonrpc: "2.0",
      method: "GetSmartContractSubState",
      params: [okiStakingHash.replace("0x", "").toLowerCase(), "stakers", []],
    },
  };
};

/**
 * Create a `GetSmartContractSubState` request for the pending xcad.
 *
 * @param string The wallet address.
 * @returns IBatchRequest
 */
export const okiPendingRewardsBatchRequest = (): IBatchRequest => {
  return {
    type: EnumBatchRequestType.OkiPendingRewards,
    token: undefined,
    contractAddress: okiStakingHash,
    item: {
      id: "1",
      jsonrpc: "2.0",
      method: "GetSmartContractSubState",
      params: [
        okiStakingHash.replace("0x", "").toLowerCase(),
        "pending_okis",
        [],
      ],
    },
  };
};

/**
 * Create a `GetSmartContractSubState` request for the unifees stakers.
 *
 * @param string The wallet address.
 * @returns IBatchRequest
 */
export const unifeeStakersBatchRequest = (
  walletAddress: string,
  unifeeStakingHash: string,
  name: string,
  seedNode: number | string
): IBatchRequest => {
  return {
    type: EnumBatchRequestType.UnifeeStakers,
    name: name,
    seedNode: seedNode,
    contractAddress: unifeeStakingHash,
    item: {
      id: "1",
      jsonrpc: "2.0",
      method: "GetSmartContractSubState",
      params: [
        unifeeStakingHash.replace("0x", "").toLowerCase(),
        "stakers",
        [fromBech32Address(walletAddress).toLowerCase()],
      ],
    },
  };
};

/**
 * Create a `GetSmartContractSubState` request for the unifees pending reward.
 *
 * @param string The wallet address.
 * @returns IBatchRequest
 */
export const unifeePendingRewardsBatchRequest = (
  walletAddress: string,
  unifeeStakingHash: string
): IBatchRequest => {
  return {
    type: EnumBatchRequestType.UnifeePendingRewards,
    contractAddress: unifeeStakingHash,
    item: {
      id: "1",
      jsonrpc: "2.0",
      method: "GetSmartContractSubState",
      params: [
        unifeeStakingHash.replace("0x", "").toLowerCase(),
        "pendingRewards",
        [fromBech32Address(walletAddress).toLowerCase()],
      ],
    },
  };
};

/**
 * Create a `GetSmartContractSubState` request for the blox stakers.
 *
 * @param string The wallet address.
 * @returns IBatchRequest
 */
export const bloxStakersBatchRequest = (
  walletAddress: string
): IBatchRequest => {
  return {
    type: EnumBatchRequestType.BloxStakers,
    token: undefined,
    contractAddress: bloxStakingHash,
    item: {
      id: "1",
      jsonrpc: "2.0",
      method: "GetSmartContractSubState",
      params: [
        bloxStakingHash.replace("0x", "").toLowerCase(),
        "stakers",
        [fromBech32Address(walletAddress).toLowerCase()],
      ],
    },
  };
};

/**
 * Create a `GetSmartContractSubState` request for the pending blox.
 *
 * @param string The wallet address.
 * @returns IBatchRequest
 */
export const bloxPendingBloxsBatchRequest = (
  walletAddress: string
): IBatchRequest => {
  return {
    type: EnumBatchRequestType.BloxPendingRewards,
    token: undefined,
    contractAddress: bloxStakingHash,
    item: {
      id: "1",
      jsonrpc: "2.0",
      method: "GetSmartContractSubState",
      params: [
        bloxStakingHash.replace("0x", "").toLowerCase(),
        "pending_bloxs",
        [fromBech32Address(walletAddress).toLowerCase()],
      ],
    },
  };
};

/**
 * Create a `GetSmartContractSubState` request for the xcad stakers.
 *
 * @param string The wallet address.
 * @returns IBatchRequest
 */
export const demonzStakersBatchRequest = (): IBatchRequest => {
  return {
    type: EnumBatchRequestType.DemonzStakers,
    token: undefined,
    contractAddress: demonzStakingHash,
    item: {
      id: "1",
      jsonrpc: "2.0",
      method: "GetSmartContractSubState",
      params: [
        demonzStakingHash.replace("0x", "").toLowerCase(),
        "stakers_total_bal",
        [],
      ],
    },
  };
};

/**
 * Create a `GetSmartContractSubState` request for the grph stakers.
 *
 * @param string The wallet address.
 * @returns IBatchRequest
 */
export const grphStakersBatchRequest = (
  walletAddress: string
): IBatchRequest => {
  return {
    type: EnumBatchRequestType.GrphStakers,
    token: undefined,
    contractAddress: grphStakingHash,
    item: {
      id: "1",
      jsonrpc: "2.0",
      method: "GetSmartContractSubState",
      params: [
        grphStakingHash.replace("0x", "").toLowerCase(),
        "stakers",
        [fromBech32Address(walletAddress).toLowerCase()],
      ],
    },
  };
};

/**
 * Create a `GetSmartContractSubState` request for the xcad stakers.
 *
 * @param string The wallet address.
 * @returns IBatchRequest
 */
export const grphPendingRewardsBatchRequest = (
  walletAddress: string
): IBatchRequest => {
  return {
    type: EnumBatchRequestType.GrphPendingRewards,
    token: undefined,
    contractAddress: grphStakingHash,
    item: {
      id: "1",
      jsonrpc: "2.0",
      method: "GetSmartContractSubState",
      params: [
        grphStakingHash.replace("0x", "").toLowerCase(),
        "pending_carbs",
        [fromBech32Address(walletAddress).toLowerCase()],
      ],
    },
  };
};

export const lunarStakersBatchRequest = (
  walletAddress: string,
  lunarStakingHash: string,
  name: string,
  seedNode: number | string
): IBatchRequest => {
  return {
    type: EnumBatchRequestType.LunarStakers,
    token: undefined,
    name: name,
    seedNode: seedNode,
    contractAddress: lunarStakingHash,
    item: {
      id: "1",
      jsonrpc: "2.0",
      method: "GetSmartContractSubState",
      params: [
        lunarStakingHash.replace("0x", "").toLowerCase(),
        "stakes",
        [fromBech32Address(walletAddress).toLowerCase()],
      ],
    },
  };
};

// #endregion
//////////////////////////////////////////////////////////////// END STAKING API

/**
 * Create a `GetSmartContractInit` request for the token pool balance.
 *
 * @param string The smart contract address.
 * @returns IBatchRequest
 */
export const contractInitBatchRequest = (
  collectionAddress: ICollectionAddress
): IBatchRequest => {
  const address = fromBech32Address(collectionAddress.address).toLowerCase();
  return {
    type: EnumBatchRequestType.SmartContractInit,
    contractAddress: collectionAddress.address,
    collectionAddress: collectionAddress,
    item: {
      id: "1",
      jsonrpc: "2.0",
      method: "GetSmartContractInit",
      params: [address.replace("0x", "").toLowerCase()],
    },
  };
};

/**
 * Create a `GetSmartContractInit` request for the token pool balance.
 *
 * @param string The smart contract address.
 * @returns IBatchRequest
 */
export const contractStateBatchRequest = (
  collectionAddress: ICollectionAddress
): IBatchRequest => {
  const address = fromBech32Address(collectionAddress.address).toLowerCase();
  return {
    type: EnumBatchRequestType.SmartContractState,
    contractAddress: collectionAddress.address,
    collectionAddress: collectionAddress,
    item: {
      id: "1",
      jsonrpc: "2.0",
      method: "GetSmartContractState",
      params: [address.replace("0x", "").toLowerCase()],
    },
  };
};

/**
 * Sends a series of requests as a batch to the Zilliqa API.
 *
 * @param EnumNetwork The currently selected network.
 * @param IBatchRequest[] An array of RPC requests.
 * @returns Promise<IBatchResponse[]> Array of responses.
 */
export const sendBatchRequest = async (
  network: EnumNetwork,
  requests: IBatchRequest[]
): Promise<IBatchResponse[]> => {
  var baseUrl = "https://api.zilliqa.com/";
  if (network === EnumNetwork.TestNet) {
    baseUrl = "https://dev-api.zilliqa.com/";
  }

  const response = await fetch(baseUrl, {
    method: "POST",
    body: JSON.stringify(requests.flatMap((request) => request.item)),
  });

  const results = await response.json();

  if (!Array.isArray(results)) {
    return [];
  }

  var responseItems: IBatchResponse[] = [];
  results.forEach((result: any, i: number) => {
    responseItems.push({
      request: requests[i],
      result: result.result,
    });
  });

  return responseItems;
};

/**
 * get unstaking pending
 * @param bech32Address
 */
export const getZilUnstakingPending = async (bech32Address: string) => {
  if (!bech32Address) {
    return 0;
  }

  const zilliqa = new Zilliqa("https://api.zilliqa.com");
  const ZilSeedNodeStakingImplementationAddress =
    "zil15lr86jwg937urdeayvtypvhy6pnp6d7p8n5z09";

  const base16Address = fromBech32Address(bech32Address).toLowerCase();

  const smartContract = await zilliqa.blockchain.getSmartContractSubState(
    ZilSeedNodeStakingImplementationAddress,
    "withdrawal_pending",
    [base16Address]
  );

  let zilUnstakingPending: number = 0;

  if (smartContract.result && smartContract.result.withdrawal_pending) {
    // Get staking wallets
    const unstakingPending =
      smartContract.result.withdrawal_pending[base16Address];

    // Get balance
    for (let id in unstakingPending) {
      zilUnstakingPending += new BigNumber(unstakingPending[id])
        .div(Math.pow(10, 12))
        .toNumber();
    }
  }

  return zilUnstakingPending;
};
