import React from "react";
import { Box, Center } from "@chakra-ui/layout";
import {
  Tr,
  Td,
  Tbody,
  Th,
  useBoolean,
  Thead,
  Flex,
  Button,
  Text,
} from "@chakra-ui/react";
import { IconFilledArrow } from "src/assets/icons";
import { useAppSelector } from "src/redux/hooks";
import { loginSelector } from "src/redux/slices/loginSlice";
import { overviewSelector } from "src/redux/slices/overviewSlice";
import { useAppColor } from "src/hooks";
import { fetchReward } from "src/api/stakingRewardApi";
import { useTranslation } from "react-i18next";
import { IStakingInfo } from "src/constants/interfaces";
import StakingTableHeader from "./StakingTableHeader";
import StakingTableItem from "./StakingTableItem";
import { IModalClaimStakingItem } from "../ModalClaimStaking";
import { formatToken } from "src/lib/formatCurrency";
import { TokenLogo } from "src/atoms";

interface StakingZilProps {
  stakeToken: IStakingInfo;
  claimStaking?: (stakeToken: IModalClaimStakingItem[]) => void;
  last?: boolean;
}

const StakingZil: React.FC<StakingZilProps> = ({
  stakeToken,
  claimStaking,
  last,
}) => {
  const { t } = useTranslation(["overview", "common"]);
  const [show, setShow] = useBoolean(false);
  const [showAll, setShowAll] = useBoolean(false);
  const { brand, text3, text5, main2, main3 } = useAppColor();

  const { walletStakingList } = useAppSelector(overviewSelector);

  const { currentWallet, isHidden } = useAppSelector(loginSelector);

  const { stakeList, stakeLogo, symbol, unstakingPending } = stakeToken;

  const [claimStakingItems, setClaimStakingItems] = React.useState<
    IModalClaimStakingItem[] | []
  >([]);

  React.useEffect(() => {
    const syncStakingReward = async () => {
      if (!currentWallet || !walletStakingList) {
        return;
      }

      let claimStakingItems = Array<IModalClaimStakingItem>();

      for (let i = 0; i < walletStakingList.length; i++) {
        const stakingWallet = walletStakingList[i];
        const { address, stakingBalance } = stakingWallet;
        if (stakingBalance && stakingBalance > 0) {
          const rewardResult = await fetchReward(
            address,
            currentWallet.zilAddress
          );
          const rewardNumber = parseFloat(rewardResult.toString());
          if (!isNaN(rewardNumber) && rewardNumber > 0) {
            claimStakingItems.push({
              stakingWallet: stakingWallet,
              reward1: rewardNumber * Math.pow(10, -12),
              reward2: rewardNumber * Math.pow(10, -12) * Math.pow(10, -3),
            });
          }
        }
      }
      setClaimStakingItems(claimStakingItems);
    };
    syncStakingReward();
  }, [walletStakingList, currentWallet]);

  const claimZil = React.useCallback(() => {
    claimStaking && claimStaking(claimStakingItems);
  }, [claimStaking, claimStakingItems]);

  const toggleShow = () => {
    setShow.toggle();
    setShowAll.off();
  };

  const headerList = [
    { child: t("seedNode") },
    { child: <React.Fragment /> },
    { child: t("deposit"), isNumeric: true },
    { child: t("unclaimedReward"), isNumeric: true },
    { child: t("action"), isNumeric: true },
  ];

  return (
    <React.Fragment>
      <StakingTableHeader
        stakeToken={stakeToken}
        claimToken={claimZil}
        toggleShow={toggleShow}
        last={last}
      />
      {!!show && (
        <>
          {!isHidden && unstakingPending[0].unstaking > 0 && (
            <Thead>
              <Tr bg={brand}>
                <Td />
                <Td />
                <Td isNumeric>
                  {unstakingPending[0].unstaking > 0 && (
                    <React.Fragment>
                      <Flex
                        w="full"
                        alignItems="center"
                        justifyContent="flex-end"
                        color="black"
                        fontSize="sm"
                        fontWeight="semibold"
                      >
                        <Text ml="1">
                          {formatToken(
                            unstakingPending[0].unstaking,
                            symbol,
                            isHidden
                          )}
                        </Text>
                        <TokenLogo size="2xs" src={stakeLogo} ml="1" />
                      </Flex>
                      <Text color="blackAlpha.900" fontSize="xs">
                        {t("currentUnstaking")}
                      </Text>
                    </React.Fragment>
                  )}
                </Td>
                <Td isNumeric>
                  {/* {unstakingPending > 0 && (
                    <React.Fragment>
                      <Flex
                        w="full"
                        alignItems="center"
                        justifyContent="flex-end"
                        color="black"
                        fontSize="sm"
                        fontWeight="semibold"
                      >
                        <Text ml="1">
                          {formatToken(unstakingPending, symbol, isHidden)}
                        </Text>
                        <TokenLogo size="2xs" src={stakeLogo} ml="1" />
                      </Flex>
                      <Text color="blackAlpha.900" fontSize="xs">
                        {t("availableWithdraw")}
                      </Text>
                    </React.Fragment>
                  )} */}
                </Td>
                <Td>
                  {false && (
                    <Flex alignItems="center" justifyContent="flex-end">
                      <Button children={t("withdraw")} mr="4" />
                      <Box w="6" />
                    </Flex>
                  )}
                </Td>
              </Tr>
            </Thead>
          )}
          <Tbody bg={main3}>
            <Tr bg={main2}>
              {headerList.map((header, index) => (
                <Th key={index} {...header}>
                  {header.child}
                </Th>
              ))}
            </Tr>
            {stakeList &&
              stakeList.map((token, index) => {
                const { name, stakingBalance } = token;
                let reward = 0;
                let rate = 1;
                if (
                  (!stakingBalance && !showAll) ||
                  (stakingBalance && stakingBalance <= 0 && !showAll)
                ) {
                  return <Tr key={index} />;
                }

                let claimStakingItem = claimStakingItems.find(
                  (claimStakingItem) =>
                    claimStakingItem.stakingWallet.name === name
                );

                reward = claimStakingItem ? claimStakingItem.reward1 : 0;

                return (
                  <StakingTableItem
                    key={index}
                    {...stakeToken}
                    {...token}
                    pendingReward={{
                      pendingReward: reward,
                      pendingRewardSymbol: "ZIL",
                    }}
                    rate={rate}
                  />
                );
              })}
            <Tr>
              <Td
                borderColor="transparent"
                p="0"
                position="relative"
                colSpan={headerList.length}
              >
                <Center>
                  <IconFilledArrow
                    position="absolute"
                    _groupHover={{
                      fill: text3,
                    }}
                    onClick={() => {
                      setShowAll.toggle();
                    }}
                    cursor="pointer"
                    size="6"
                    fill={text5}
                    style={{
                      transform: `rotate(${showAll ? 0 : 180}deg)`,
                    }}
                  />
                </Center>
              </Td>
            </Tr>
          </Tbody>
        </>
      )}
    </React.Fragment>
  );
};

export default StakingZil;
