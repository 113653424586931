import React from "react";
import { useAppSelector } from "src/redux/hooks";
import { isEmptyDeposit } from "src/utils";
import HeaderWallet from "../HeaderWallet";
import StackingToken from "./StackingToken";
import BalanceMinify from "../BalanceMinify";
import { overviewSelector } from "src/redux/slices/overviewSlice";
import { viewProfileSelector } from "src/redux/slices/viewProfileSlice";

export type StakingBalanceProps = {
  active?: boolean;
};

export const StakingBalance: React.FC<StakingBalanceProps> = ({ active }) => {
  const { viewWallet } = useAppSelector(viewProfileSelector);
  const { tokenRates } = useAppSelector(overviewSelector);
  if (!viewWallet) {
    return <></>;
  }

  const { allStakingList } = viewWallet;
  let tmpTotalStaking = 0;
  let tmpHoldingStakingIcon = [] as string[];

  allStakingList &&
    allStakingList.forEach((stakingList) => {
      if (!isEmptyDeposit(stakingList)) {
        tmpHoldingStakingIcon.push(stakingList.stakeLogo);
      }
      stakingList.stakeList.forEach((stake) => {
        const stakeRate =
          tokenRates.find((rate) => rate.token.symbol === stake.stakeSymbol)
            ?.rate || 1;
        let balance = stake.stakingBalance || 0;
        tmpTotalStaking += balance * stakeRate;
      });
    });
  return (
    <HeaderWallet
      children={<StackingToken active={active} viewWallet={viewWallet} />}
      minifyChild={
        <BalanceMinify
          balance={tmpTotalStaking}
          holdings={tmpHoldingStakingIcon}
        />
      }
      viewWallet={viewWallet}
    />
  );
};

export default StakingBalance;
