import React from "react";
import { useAppSelector } from "src/redux/hooks";
import { isEmptyProfileLiquidity } from "src/utils";
import BigNumber from "bignumber.js";
import BalanceMinify from "../BalanceMinify";
import HeaderWallet from "../HeaderWallet";
import { overviewSelector } from "src/redux/slices/overviewSlice";
import LiquidityPoolToken from "./LiquidityPoolToken";
import { isEmpty } from "lodash";
import { viewProfileSelector } from "src/redux/slices/viewProfileSlice";

export type LiquidityPoolBalanceProps = {
  active?: boolean;
};

export const LiquidityPoolBalance: React.FC<LiquidityPoolBalanceProps> = ({
  active,
}) => {
  const { viewWallet } = useAppSelector(viewProfileSelector);
  const { tokenRates } = useAppSelector(overviewSelector);

  if (!viewWallet) {
    return <></>;
  }

  const { allLiquidityPools } = viewWallet;
  let tmpTotalLP = 0;

  let tmpHoldingLiquidityIcon = [] as string[];

  allLiquidityPools &&
    allLiquidityPools.forEach((pools, index) => {
      // const poolToken = index === 0 ? 12 : 18;

      if (
        !isEmptyProfileLiquidity(pools.tokenPool) ||
        !isEmpty(pools.distributions)
      ) {
        tmpHoldingLiquidityIcon.push(pools.logo);
      }
      pools.tokenPool.forEach((pool: any) => {
        const {
          token,
          userContribution: _userContribution,
          // zilReserve,
          tokenReserve,
          totalContribution: _totalContribution,
        } = pool;

        const userContribution = new BigNumber(_userContribution || 0);
        const totalContribution = new BigNumber(_totalContribution || 0);

        const tokenRate = tokenRates.find(
          (tokenRate) => tokenRate.token.address_bech32 === token.address_bech32
        );

        let contributionPercentage = userContribution!
          .dividedBy(totalContribution)
          .times(100);
        let contributionShare = contributionPercentage.shiftedBy(-2);
        let tokenAmount = contributionShare
          .times(tokenReserve)
          .div(Math.pow(10, token.decimals))
          .toNumber();

        // let zilAmount = contributionShare
        //   .times(zilReserve)
        //   .div(Math.pow(10, poolToken))
        //   .toNumber();

        if (
          !isNaN(tokenAmount) && // !isNaN(zilAmount) &&
          tokenRate &&
          tokenRate.rate
        ) {
          tmpTotalLP += 2 * tokenAmount * tokenRate.rate;
        }
      });
    });
  return (
    <HeaderWallet
      children={<LiquidityPoolToken active={active} viewWallet={viewWallet} />}
      minifyChild={
        <BalanceMinify
          balance={tmpTotalLP}
          holdings={tmpHoldingLiquidityIcon}
        />
      }
      viewWallet={viewWallet}
    />
  );
};

export default LiquidityPoolBalance;
