import React from "react";
import Icon from "@chakra-ui/icon";
import { SvgIconProps } from "src/constants/type";

const SvgComponent: React.FC<SvgIconProps> = ({
  size,
  ...props
}: SvgIconProps) => {
  return (
    <Icon {...props} w={size} h={size} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.03 2.545c0-.3.244-.545.546-.545h4.848c.302 0 .546.244.546.545v2.182h2.484a.545.545 0 0 1 .544.586l-.605 8.182a.545.545 0 0 1-.545.505H3.152a.545.545 0 0 1-.544-.505L2 5.313a.545.545 0 0 1 .544-.586H5.03V2.545Zm4.849 3.94v-.667H6.12v.667a.545.545 0 1 1-1.09 0v-.667H3.132l.525 7.091h8.684l.525-7.09H10.97v.666a.545.545 0 0 1-1.091 0Zm0-3.394v1.636H6.12V3.091H9.88Zm-4.303 7.394a.545.545 0 1 0 0 1.09h4.848a.545.545 0 1 0 0-1.09H5.576Z"
        fill="currentColor"
      />
    </Icon>
  );
};

const IconBuy = React.memo(SvgComponent);
export default IconBuy;
