import React from "react";
import { useAppSelector } from "src/redux/hooks";
import { loginSelector } from "src/redux/slices/loginSlice";
import { overviewSelector } from "src/redux/slices/overviewSlice";
import { fetchReward } from "src/api/stakingRewardApi";
import { IPendingReward, IStakingInfo } from "src/constants/interfaces";
import { IModalClaimStakingItem } from "src/pages/Overview/components/ModalClaimStaking";
import RewardTableHeader from "./RewardTableHeader";

interface RewardZilProps {
  stakeToken: IStakingInfo;
  claimStaking?: (stakeToken: IModalClaimStakingItem[]) => void;
  last?: boolean;
}

const RewardZil: React.FC<RewardZilProps> = ({
  stakeToken,
  claimStaking,
  last,
}) => {
  const { walletStakingList } = useAppSelector(overviewSelector);

  const { currentWallet } = useAppSelector(loginSelector);

  const [zilPendingRewards, setZilPendingRewards] = React.useState<
    IPendingReward[]
  >(Array<IPendingReward>());

  const [claimStakingItems, setClaimStakingItems] = React.useState<
    IModalClaimStakingItem[]
  >(Array<IModalClaimStakingItem>());

  React.useEffect(() => {
    const syncStakingReward = async () => {
      if (!currentWallet || !walletStakingList) {
        return;
      }

      let claimStakingItems = Array<IModalClaimStakingItem>();
      let zilPendingRewards = Array<IPendingReward>();

      for (let i = 0; i < walletStakingList.length; i++) {
        const stakingWallet = walletStakingList[i];
        const { address, stakingBalance } = stakingWallet;
        if (stakingBalance && stakingBalance > 0) {
          const rewardResult = await fetchReward(
            address,
            currentWallet.zilAddress
          );
          const rewardNumber = parseFloat(rewardResult.toString());
          if (!isNaN(rewardNumber) && rewardNumber > 0) {
            zilPendingRewards.push({
              address: address,
              pendingReward: rewardNumber * Math.pow(10, -12),
              pendingRewardSymbol: "ZIL",
            });
            claimStakingItems.push({
              stakingWallet: stakingWallet,
              reward1: rewardNumber * Math.pow(10, -12),
              reward2: rewardNumber * Math.pow(10, -12) * Math.pow(10, -3),
            });
          }
        }
      }
      setZilPendingRewards(zilPendingRewards);
      setClaimStakingItems(claimStakingItems);
    };
    syncStakingReward();
  }, [walletStakingList, currentWallet]);

  const claimZil = React.useCallback(() => {
    claimStaking && claimStaking(claimStakingItems);
  }, [claimStaking, claimStakingItems]);

  return (
    <RewardTableHeader
      stakeToken={stakeToken}
      claimToken={claimZil}
      last={last}
      zilPendingRewards={zilPendingRewards}
    />
  );
};

export default RewardZil;
