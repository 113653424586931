import React from "react";
import { IconBase, IconBaseProps } from "react-icons";

const SvgComponent: React.FC<IconBaseProps> = (props: IconBaseProps) => {
  return (
    <IconBase {...props}>
      <svg viewBox="0 0 16 16">
        <path d="M14.4738 6.19378L11.8071 3.52712C11.7449 3.46496 11.6711 3.41565 11.5899 3.38201C11.5087 3.34837 11.4217 3.33105 11.3338 3.33105C11.1562 3.33105 10.986 3.40158 10.8604 3.52712C10.7349 3.65265 10.6644 3.82291 10.6644 4.00045C10.6644 4.17798 10.7349 4.34825 10.8604 4.47378L12.3938 6.00045H4.66709C4.49027 6.00045 4.3207 6.07069 4.19568 6.19571C4.07066 6.32074 4.00042 6.4903 4.00042 6.66712C4.00042 6.84393 4.07066 7.0135 4.19568 7.13852C4.3207 7.26354 4.49027 7.33378 4.66709 7.33378H14.0004C14.132 7.33313 14.2605 7.29352 14.3697 7.21997C14.4788 7.14641 14.5637 7.0422 14.6138 6.92045C14.6648 6.79904 14.6788 6.66523 14.6538 6.53591C14.6289 6.40658 14.5663 6.28753 14.4738 6.19378ZM11.3338 8.66712H2.00042C1.8688 8.66777 1.74032 8.70737 1.63118 8.78093C1.52203 8.85448 1.4371 8.9587 1.38709 9.08045C1.33603 9.20186 1.32208 9.33566 1.347 9.46499C1.37192 9.59432 1.43458 9.71337 1.52709 9.80712L4.19375 12.4738C4.25573 12.5363 4.32946 12.5859 4.4107 12.6197C4.49194 12.6536 4.57908 12.671 4.66709 12.671C4.75509 12.671 4.84223 12.6536 4.92347 12.6197C5.00471 12.5859 5.07844 12.5363 5.14042 12.4738C5.2029 12.4118 5.2525 12.3381 5.28635 12.2568C5.32019 12.1756 5.33762 12.0885 5.33762 12.0004C5.33762 11.9124 5.32019 11.8253 5.28635 11.7441C5.2525 11.6628 5.2029 11.5891 5.14042 11.5271L3.60708 10.0004H11.3338C11.5106 10.0004 11.6801 9.93021 11.8052 9.80519C11.9302 9.68016 12.0004 9.51059 12.0004 9.33378C12.0004 9.15697 11.9302 8.9874 11.8052 8.86238C11.6801 8.73735 11.5106 8.66712 11.3338 8.66712Z" />
      </svg>
    </IconBase>
  );
};
const IconSwap = React.memo(SvgComponent);
export default IconSwap;
