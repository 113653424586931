import React from "react";
import { useBoolean, Box } from "@chakra-ui/react";
import { useAppSelector } from "src/redux/hooks";
import { loginSelector } from "src/redux/slices/loginSlice";
import { overviewSelector } from "src/redux/slices/overviewSlice";
import { fetchReward } from "src/api/stakingRewardApi";
import { IStakingInfo } from "src/constants/interfaces";
import StakingHeaderMb from "./StakingHeaderMb";
import StakingItemMb from "./StackingItemMb";
import { Swiper, SwiperSlide } from "swiper/react";
import { IModalClaimStakingItem } from "./StakingZil";

interface StakingZilProps {
  stakeToken: IStakingInfo;
  active?: boolean;
}

const StakingZilMb: React.FC<StakingZilProps> = ({ stakeToken, active }) => {
  const [show, setShow] = useBoolean(false);

  const { walletStakingList } = useAppSelector(overviewSelector);

  const { currentWallet /* isHidden */ } = useAppSelector(loginSelector);

  const { stakeList /* symbol, stakeLogo, unstakingPending */ } = stakeToken;

  // const { t } = useTranslation("overview");

  const [claimStakingItems, setClaimStakingItems] = React.useState<
    IModalClaimStakingItem[] | []
  >([]);

  React.useEffect(() => {
    const syncStakingReward = async () => {
      if (!currentWallet || !walletStakingList) {
        return;
      }

      let claimStakingItems = Array<IModalClaimStakingItem>();

      for (let i = 0; i < walletStakingList.length; i++) {
        const stakingWallet = walletStakingList[i];
        const { address, stakingBalance } = stakingWallet;
        if (stakingBalance && stakingBalance > 0) {
          const rewardResult = await fetchReward(
            address,
            currentWallet.zilAddress
          );
          const rewardNumber = parseFloat(rewardResult.toString());
          if (!isNaN(rewardNumber) && rewardNumber > 0) {
            claimStakingItems.push({
              stakingWallet: stakingWallet,
              reward1: rewardNumber * Math.pow(10, -12),
              reward2: rewardNumber * Math.pow(10, -12) * Math.pow(10, -3),
            });
          }
        }
      }
      setClaimStakingItems(claimStakingItems);
    };
    syncStakingReward();
  }, [walletStakingList, currentWallet]);

  const toggleShow = () => {
    setShow.toggle();
  };

  return (
    <React.Fragment>
      <StakingHeaderMb stakeToken={stakeToken} toggleShow={toggleShow} />

      {!!show && (
        <Box m="4">
          {/* <Box borderBottomWidth="1px" borderBottomColor={main3}>
            <Flex
              alignItems="center"
              flex="1"
              justifyContent="space-between"
              py="3"
              bg={brand}
              px="12"
            >
              <Flex direction="column">
                <Flex
                  w="full"
                  alignItems="center"
                  justifyContent="flex-end"
                  fontSize="sm"
                  fontWeight="semibold"
                >
                  {unstakingPending > 0 && (
                    <React.Fragment>
                      <Flex
                        w="full"
                        alignItems="center"
                        justifyContent="flex-end"
                        color="black"
                        fontSize="sm"
                        fontWeight="semibold"
                      >
                        <Text>
                          {formatToken(unstakingPending, symbol, isHidden)}
                        </Text>
                        <TokenLogo size="2xs" src={stakeLogo} ml="1" />
                      </Flex>
                    </React.Fragment>
                  )}
                </Flex>
                <Text color="blackAlpha.900" fontSize="xs">
                  {t("currentUnstaking")}
                </Text>
              </Flex>
              <Box> */}
          {/* <Flex
                  w="full"
                  alignItems="center"
                  justifyContent="flex-end"
                  fontSize="sm"
                  fontWeight="semibold"
                >
                  {unstakingPending > 0 && (
                    <React.Fragment>
                      <Flex
                        w="full"
                        alignItems="center"
                        justifyContent="flex-end"
                        color="black"
                        fontSize="sm"
                        fontWeight="semibold"
                      >
                        <Text>
                          {formatToken(unstakingPending, symbol, isHidden)}
                        </Text>
                        <TokenLogo size="2xs" src={stakeLogo} ml="1" />
                      </Flex>
                    </React.Fragment>
                  )}
                </Flex>
                <Text color="blackAlpha.900" fontSize="xs">
                  {t("availableWithdraw")}
                </Text> */}
          {/* </Box>
            </Flex>
          </Box> */}

          <Box display={{ base: "none", lg: "block" }}>
            {stakeList &&
              stakeList.map((token, index) => {
                const { name, stakingBalance } = token;
                let reward = 0;
                let rate = 1;
                if (
                  (!stakingBalance && !!active) ||
                  (stakingBalance && stakingBalance <= 0 && !!active)
                ) {
                  return <Box key={index} />;
                }

                let claimStakingItem = claimStakingItems.find(
                  (claimStakingItem) =>
                    claimStakingItem.stakingWallet.name === name
                );

                reward = claimStakingItem ? claimStakingItem.reward1 : 0;

                return (
                  <StakingItemMb
                    key={index}
                    {...stakeToken}
                    {...token}
                    reward={reward}
                    rate={rate}
                  />
                );
              })}
          </Box>

          <Box display={{ base: "block", lg: "none" }}>
            <Swiper slidesPerView={1} spaceBetween={20} width={220}>
              {stakeList &&
                stakeList.map((token, index) => {
                  const { name } = token;
                  let reward = 0;
                  let rate = 1;

                  let claimStakingItem = claimStakingItems.find(
                    (claimStakingItem) =>
                      claimStakingItem.stakingWallet.name === name
                  );

                  reward = claimStakingItem ? claimStakingItem.reward1 : 0;

                  return (
                    <SwiperSlide key={index}>
                      <StakingItemMb
                        key={index}
                        {...stakeToken}
                        {...token}
                        reward={reward}
                        rate={rate}
                      />
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </Box>
        </Box>
      )}
    </React.Fragment>
  );
};

export default StakingZilMb;
