import {
  InputGroup,
  Input,
  Flex,
  InputLeftElement,
  Stack,
} from "@chakra-ui/react";
import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { BsSearch } from "react-icons/bs";
import { useAppColor } from "src/hooks";
import { WalletNFT } from "..";

interface NFTsProps {}

export const NFTs: React.FC<NFTsProps> = memo(() => {
  const { t } = useTranslation("nfts");
  const { main1 } = useAppColor();
  const [searchCollection, setSearchCollection] = React.useState<string>("");
  const onSearchChange = React.useCallback((searchText: string) => {
    setSearchCollection(searchText);
  }, []);
  return (
    <Stack>
      <Flex justifyContent="flex-end" mb="6">
        <InputGroup w={{ base: "100%", md: "60%", lg: "40%", xl: "30%" }}>
          <InputLeftElement color="gray.400" pointerEvents="none">
            <BsSearch />
          </InputLeftElement>
          <Input
            bg={main1}
            placeholder={t("searchCollection")}
            w="full"
            onChange={(event) => onSearchChange(event.target.value)}
            value={searchCollection}
          />
        </InputGroup>
      </Flex>
      <WalletNFT searchText={searchCollection} />
    </Stack>
  );
});

export default NFTs;
