import React from "react";
import Icon from "@chakra-ui/icon";
import { SvgIconProps } from "src/constants/type";

const SvgComponent: React.FC<SvgIconProps> = ({
  size,
  ...props
}: SvgIconProps) => {
  return (
    <Icon {...props} w={size} h={size} viewBox="0 0 16 16">
      <path
        d="M1.33325 3.5C1.60939 3.5 1.83325 3.72386 1.83325 4V12C1.83325 12.0112 1.83961 12.1015 2.03566 12.256C2.22607 12.406 2.53257 12.563 2.95587 12.7041C3.79725 12.9846 4.99138 13.1667 6.33325 13.1667C7.1459 13.1667 7.90501 13.1 8.56463 12.9848C8.35691 12.7677 8.17451 12.5261 8.0219 12.2643C7.68029 11.6782 7.50018 11.012 7.49992 10.3337C7.49863 9.38804 7.84803 8.47555 8.48055 7.77262C8.83972 7.37172 9.27948 7.05114 9.77105 6.83184C10.1096 6.68082 10.4674 6.58017 10.8333 6.53231V4C10.8333 3.72386 11.0571 3.5 11.3333 3.5C11.6094 3.5 11.8333 3.72386 11.8333 4V7C11.8333 7.13271 11.7805 7.25997 11.6866 7.35376C11.5927 7.44754 11.4654 7.50015 11.3327 7.5C10.935 7.49954 10.5417 7.58306 10.1785 7.74508C9.81527 7.90711 9.49037 8.144 9.22504 8.44025L9.22421 8.44117C8.75697 8.96026 8.49889 9.63421 8.49992 10.3326L8.49992 10.3332C8.50009 10.8348 8.63326 11.3273 8.88585 11.7607C9.13845 12.194 9.50143 12.5527 9.93781 12.8C10.1118 12.8987 10.2103 13.0916 10.1882 13.2904C10.166 13.4892 10.0275 13.6557 9.83598 13.7136C8.89172 13.9991 7.66328 14.1667 6.33325 14.1667C4.91379 14.1667 3.60792 13.9756 2.63963 13.6528C2.1581 13.4923 1.73235 13.2901 1.41671 13.0414C1.10673 12.7971 0.833252 12.449 0.833252 12V4C0.833252 3.72386 1.05711 3.5 1.33325 3.5ZM7.49992 10.3337C7.49992 10.3336 7.49992 10.3336 7.49992 10.3335L7.99992 10.3333L7.49992 10.3341C7.49992 10.3339 7.49992 10.3338 7.49992 10.3337Z"
        fill="currentColor"
      />
      <path
        d="M11.3339 6.5C10.7954 6.49938 10.2629 6.61246 9.77113 6.83184C9.27959 7.05112 8.83987 7.37168 8.48071 7.77253C7.87146 8.45075 7.5 9.34928 7.5 10.3333C7.50023 11.0118 7.68035 11.6782 8.02199 12.2643C8.36359 12.8503 8.85447 13.3353 9.44459 13.6699C10.0027 13.9866 10.6481 14.1667 11.3333 14.1667C13.4505 14.1667 15.1667 12.4505 15.1667 10.3333C15.1667 8.21639 13.4508 6.50032 11.3339 6.5Z"
        fill="currentColor"
      />
      <path
        d="M2.03566 3.74468C1.83961 3.89918 1.83325 3.98946 1.83325 4.00065C1.83325 4.01184 1.83961 4.10212 2.03566 4.25662C2.22607 4.40666 2.53257 4.56366 2.95587 4.70477C3.79725 4.98524 4.99138 5.16732 6.33325 5.16732C7.67512 5.16732 8.86925 4.98524 9.71063 4.70477C10.1339 4.56366 10.4404 4.40666 10.6308 4.25662C10.8269 4.10212 10.8333 4.01184 10.8333 4.00065C10.8333 3.98946 10.8269 3.89918 10.6308 3.74468C10.4404 3.59464 10.1339 3.43764 9.71063 3.29653C8.86925 3.01606 7.67512 2.83398 6.33325 2.83398C4.99138 2.83398 3.79725 3.01606 2.95587 3.29653C2.53257 3.43764 2.22607 3.59464 2.03566 3.74468ZM2.63963 2.34785C3.60792 2.02508 4.91379 1.83398 6.33325 1.83398C7.75272 1.83398 9.05858 2.02508 10.0269 2.34785C10.5084 2.50837 10.9342 2.71052 11.2498 2.95925C11.5598 3.20353 11.8333 3.55167 11.8333 4.00065C11.8333 4.44963 11.5598 4.79777 11.2498 5.04205C10.9342 5.29078 10.5084 5.49293 10.0269 5.65345C9.05858 5.97623 7.75272 6.16732 6.33325 6.16732C4.91379 6.16732 3.60792 5.97623 2.63963 5.65345C2.1581 5.49293 1.73235 5.29078 1.41671 5.04205C1.10673 4.79777 0.833252 4.44963 0.833252 4.00065C0.833252 3.55167 1.10673 3.20353 1.41671 2.95925C1.73235 2.71052 2.1581 2.50837 2.63963 2.34785Z"
        fill="currentColor"
      />
      <path
        d="M1.33325 8.83398C1.60939 8.83398 1.83325 9.05784 1.83325 9.33398C1.83325 9.34517 1.83961 9.43545 2.03566 9.58995C2.22607 9.74 2.53257 9.897 2.95587 10.0381C3.79725 10.3186 4.99138 10.5007 6.33325 10.5007C6.91635 10.5007 7.47373 10.4664 7.98727 10.4037C8.26138 10.3702 8.51073 10.5652 8.54423 10.8393C8.57772 11.1134 8.38267 11.3628 8.10856 11.3963C7.55277 11.4642 6.95482 11.5007 6.33325 11.5007C4.91379 11.5007 3.60792 11.3096 2.63963 10.9868C2.1581 10.8263 1.73235 10.6241 1.41671 10.3754C1.10673 10.1311 0.833252 9.78296 0.833252 9.33398C0.833252 9.05784 1.05711 8.83398 1.33325 8.83398Z"
        fill="currentColor"
      />
      <path
        d="M1.33325 6.16602C1.60939 6.16602 1.83325 6.38987 1.83325 6.66602C1.83325 6.6772 1.83961 6.76748 2.03566 6.92198C2.22607 7.07203 2.53257 7.22903 2.95587 7.37013C3.79725 7.65061 4.99138 7.83268 6.33325 7.83268C7.2232 7.83268 8.05198 7.75249 8.75704 7.61523C9.02809 7.56246 9.2906 7.73941 9.34337 8.01047C9.39614 8.28152 9.21919 8.54403 8.94813 8.5968C8.17386 8.74754 7.28064 8.83268 6.33325 8.83268C4.91379 8.83268 3.60792 8.64159 2.63963 8.31881C2.1581 8.1583 1.73235 7.95615 1.41671 7.70741C1.10673 7.46313 0.833252 7.11499 0.833252 6.66602C0.833252 6.38987 1.05711 6.16602 1.33325 6.16602Z"
        fill="currentColor"
      />
      <path
        d="M11.3526 12.6667V8H11.6516V12.6667H11.3526ZM12.1456 9.54036C12.131 9.39332 12.0684 9.27908 11.9578 9.19766C11.8472 9.11623 11.6971 9.07552 11.5076 9.07552C11.3787 9.07552 11.27 9.09375 11.1813 9.13021C11.0925 9.16545 11.0245 9.21467 10.9771 9.27786C10.9309 9.34106 10.9078 9.41276 10.9078 9.49297C10.9054 9.55981 10.9194 9.61814 10.9497 9.66797C10.9813 9.7178 11.0245 9.76094 11.0792 9.7974C11.1339 9.83264 11.197 9.86363 11.2688 9.89036C11.3405 9.91589 11.417 9.93776 11.4984 9.95599L11.8339 10.0362C11.9967 10.0727 12.1462 10.1213 12.2823 10.182C12.4184 10.2428 12.5363 10.3175 12.6359 10.4062C12.7356 10.495 12.8128 10.5995 12.8674 10.7198C12.9234 10.8401 12.9519 10.978 12.9531 11.1336C12.9519 11.3621 12.8936 11.5602 12.7781 11.7279C12.6639 11.8944 12.4986 12.0238 12.2823 12.1161C12.0672 12.2073 11.8077 12.2529 11.5039 12.2529C11.2025 12.2529 10.94 12.2067 10.7164 12.1143C10.494 12.022 10.3202 11.8852 10.1951 11.7042C10.0711 11.5219 10.0061 11.2964 10 11.0279H10.7638C10.7723 11.153 10.8082 11.2576 10.8714 11.3414C10.9358 11.424 11.0214 11.4866 11.1284 11.5292C11.2365 11.5705 11.3587 11.5911 11.4948 11.5911C11.6285 11.5911 11.7445 11.5717 11.843 11.5328C11.9426 11.4939 12.0198 11.4398 12.0745 11.3706C12.1292 11.3013 12.1565 11.2217 12.1565 11.1318C12.1565 11.0479 12.1316 10.9774 12.0818 10.9203C12.0332 10.8632 11.9615 10.8146 11.8667 10.7745C11.7731 10.7344 11.6582 10.6979 11.5221 10.6651L11.1156 10.563C10.8009 10.4865 10.5523 10.3668 10.3701 10.2039C10.1878 10.0411 10.0972 9.8217 10.0984 9.54583C10.0972 9.31979 10.1574 9.12231 10.2789 8.95339C10.4016 8.78446 10.57 8.6526 10.7839 8.55781C10.9977 8.46302 11.2408 8.41563 11.513 8.41563C11.7901 8.41563 12.0319 8.46302 12.2385 8.55781C12.4464 8.6526 12.608 8.78446 12.7234 8.95339C12.8389 9.12231 12.8984 9.31797 12.9021 9.54036H12.1456Z"
        fill="currentColor"
      />
    </Icon>
  );
};

const IconStake = React.memo(SvgComponent);
export default IconStake;
