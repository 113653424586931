import React, { useEffect } from "react";
import {
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
  Flex,
  Box,
  Input,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { useAppColor } from "src/hooks";
import { BsSearch } from "react-icons/bs";
import { TokenLogo } from "src/atoms";
import { formatToken } from "src/lib/formatCurrency";
import { IWalletToken, loginSelector } from "src/redux/slices/overviewSlice";
import { useTranslation } from "react-i18next";
import ScrollBox from "src/atoms/ScrollBox";
import { useAppSelector } from "src/redux/hooks";

interface ModalTokenProps {
  onClose: () => void;
  tokenList: IWalletToken[];
  setCurrentToken: (walletToken: IWalletToken) => void;
}

const ModalToken: React.FC<ModalTokenProps> = ({
  onClose,
  tokenList,
  setCurrentToken,
}) => {
  const { t } = useTranslation(["swap", "common"]);
  const { text2, text4, main2, main4 } = useAppColor();

  const { isHidden } = useAppSelector(loginSelector);

  const [searchToken, setSearchToken] = React.useState<string>("");
  const [searchResult, setSearchResult] =
    React.useState<IWalletToken[]>(tokenList);

  useEffect(() => {
    // Reset form when tokenList changed
    setSearchToken("");
    setSearchResult(sorted(tokenList));
  }, [tokenList]);

  const sorted = (tokenList: IWalletToken[]) => {
    let result = [...tokenList].sort(
      (a, b) =>
        b.balance - a.balance ||
        (a.token.name.toLowerCase() < b.token.name.toLowerCase()
          ? -1
          : a.token.name.toLowerCase() > b.token.name.toLowerCase()
          ? 1
          : 0)
    );

    return result;
  };

  const onSearchChange = React.useCallback(
    (text: string) => {
      setSearchToken(text);
      if (text === "" || text === null || text === undefined) {
        setSearchResult(sorted(tokenList));
      } else {
        let data = [];
        for (let i = 0; i < tokenList.length; i++) {
          if (
            tokenList[i].token.name
              .toLowerCase()
              .includes(text.toLowerCase()) ||
            tokenList[i].token.symbol.toLowerCase().includes(text.toLowerCase())
          ) {
            data.push(tokenList[i]);
          }
        }
        setSearchResult(sorted(data));
      }
    },
    [tokenList]
  );
  return (
    <>
      <ModalHeader>{t("selectToken")}</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Box w="full" borderWidth="0">
          <InputGroup w="full">
            <InputRightElement color="gray.400" pointerEvents="none">
              <BsSearch />
            </InputRightElement>
            <Input
              bg={main2}
              placeholder={t("searchToken")}
              w="full"
              onChange={(event) => onSearchChange(event.target.value)}
              value={searchToken}
            />
          </InputGroup>
          <Flex
            py="4"
            alignItems="center"
            w="full"
            justifyContent="space-between"
            borderBottom="1px solid"
            borderColor={main4}
          >
            <Text justifyContent="flex-start" fontSize="sm" fontWeight="bold">
              {t("common:token")}
            </Text>
            <Text justifyContent="flex-end" fontSize="sm" fontWeight="bold">
              {t("common:available")}
            </Text>
          </Flex>
        </Box>
        <ScrollBox borderWidth={0} h="sm" py="0">
          {searchResult &&
            searchResult.map((item: IWalletToken, index) => (
              <Flex
                borderRadius={8}
                px="2"
                _hover={{
                  background: main4,
                }}
                cursor={"pointer"}
                key={index}
                py="3"
                onClick={() => {
                  onClose();
                  setCurrentToken(item);
                }}
                borderBottom="1px solid"
                borderColor={main4}
              >
                <Flex alignItems="center" w="full">
                  <TokenLogo src={item.token.icon} size="sm" mr="2" />
                  <Box>
                    <Text fontSize="sm" fontWeight="bold" color={text4}>
                      {item.token.name}
                    </Text>
                    <Text fontSize="sm" fontWeight="normal" color={text4}>
                      {item.token.name}
                    </Text>
                  </Box>
                </Flex>
                <Flex alignItems="center" justifyContent="flex-end" w="full">
                  <Text fontSize="sm" fontWeight="semibold" color={text2}>
                    {formatToken(item.balance, item.token.symbol, isHidden)}
                  </Text>
                  <TokenLogo src={item.token.icon} size="2xs" ml="2" />
                </Flex>
              </Flex>
            ))}
        </ScrollBox>
      </ModalBody>
    </>
  );
};

export default ModalToken;
