const ssnListMap = {
  "0x122219cceab410901e96c3a0e55e46231480341b": {
    logo: "/logo/Zilliqa.png",
    ratio: 12,
    unknown01: "144256828594494601381",
    unknown02: "904485811906975130",
    name: "Zilliqa",
    raw: "https://staking.seed.zilliqa.com",
    api: "https://stakingseed-api.seed.zilliqa.com",
    unknown03: "780606043502001252",
    unknown04: "120000000",
    unknown05: "1172199313531192108",
    address: "0x122219cceab410901e96c3a0e55e46231480341b",
    reward1: 0,
    reward2: 0,
  },
  "0x2afe9e18edd39d927d0ffff8990612fc4afa2295": {
    logo: "/logo/Ezil.me.png",
    ratio: 2,
    unknown01: "95909290512582916909",
    unknown02: "374331545339346023",
    name: "Ezil.me",
    raw: "https://zil-staking.ezil.me/raw",
    api: "https://zil-staking.ezil.me/api",
    unknown03: "38571422082543753",
    unknown04: "20000000",
    unknown05: "82409292339579295",
    address: "0x2afe9e18edd39d927d0ffff8990612fc4afa2295",
    reward1: 0,
    reward2: 0,
  },
  "0x3ee34d308f962d17774a591f32cd1214e8bc470d": {
    logo: "/logo/Shardpool.io.png",
    ratio: 2,
    unknown01: "69642079657659784741",
    unknown02: "293275158563898063",
    name: "Shardpool.io",
    raw: "https://seed-zil.shardpool.io/raw",
    api: "https://seed-zil.shardpool.io",
    unknown03: "145622152300000000",
    unknown04: "20000000",
    unknown05: "2261254967945755",
    address: "0x3ee34d308f962d17774a591f32cd1214e8bc470d",
    reward1: 0,
    reward2: 0,
  },
  "0x635eff625a147c7ca0397445eee436129ee6ca0b": {
    logo: "/logo/Moonlet.io.png",
    ratio: 5,
    unknown01: "1203240895980911472789",
    unknown02: "4053551777461587750",
    name: "Moonlet.io",
    raw: "https://ssn-zilliqa.moonlet.network/raw",
    api: "https://ssn-zilliqa.moonlet.network/api",
    unknown03: "1627631292141825652",
    unknown04: "50000000",
    unknown05: "274305990516036756",
    address: "0x635eff625a147c7ca0397445eee436129ee6ca0b",
    reward1: 0,
    reward2: 0,
  },
  "0x657077b8dc9a60300fc805d559c0a5ef9bdd94a5": {
    logo: "/logo/Everstake.one.png",
    ratio: 5,
    constructor: "False",
    unknown01: "118937253007252384",
    unknown02: "0",
    name: "Everstake.one",
    raw: "https://zilapi.everstake.one/TrdFrsHsHsYdOpfgNdTsIdxtJldtMfLd",
    api: "https://zilapi.everstake.one/status/TrdFrsHsHsYdOpfgNdTsIdxtJldtMfLd",
    unknown03: "0",
    unknown04: "50000000",
    unknown05: "0",
    address: "0x657077b8dc9a60300fc805d559c0a5ef9bdd94a5",
    reward1: 0,
    reward2: 0,
  },
  "0x82b82c65213e0b2b206492d3d8a2a679e7fe52e0": {
    logo: "/logo/ViewBlock.png",
    ratio: 4,
    constructor: "True",
    unknown01: "452697276249153636988",
    unknown02: "1976913795785643616",
    name: "ViewBlock",
    raw: "https://ssn-raw-mainnet.viewblock.io",
    api: "https://ssn-api-mainnet.viewblock.io",
    unknown03: "244851152839322654",
    unknown04: "40000000",
    unknown05: "45589671828531352",
    address: "0x82b82c65213e0b2b206492d3d8a2a679e7fe52e0", //"0x413eef8e35281e8fb17bc1e289de956ccc6afea6",
    reward1: 0,
    reward2: 0,
  },
  "0x90d3dbd71c54c38341a6f5682c607e8a17023c28": {
    logo: "/logo/AtomicWallet.png",
    ratio: 5,
    constructor: "True",
    unknown01: "1213436724691050297024",
    unknown02: "9687658957345375027",
    name: "AtomicWallet",
    raw: "https://zilliqa.atomicwallet.io/raw",
    api: "https://zilliqa.atomicwallet.io/api",
    unknown03: "3800419948862346269",
    unknown04: "50000000",
    unknown05: "249637920981845890",
    address: "0x90d3dbd71c54c38341a6f5682c607e8a17023c28",
    reward1: 0,
    reward2: 0,
  },
  "0x9fb9e7ef9d0dd545c2f4a29a5bb97cc8ac15d2eb": {
    logo: "/logo/CEX.IO.png",
    ratio: 1,
    constructor: "True",
    unknown01: "656955853232734662298",
    unknown02: "5058885071517201849",
    name: "CEX.IO",
    raw: "https://ssn-zilliqa.cex.io/raw",
    api: "https://ssn-zilliqa.cex.io/api",
    unknown03: "2509293752767796642",
    unknown04: "10000000",
    unknown05: "103270386353373052",
    address: "0x9fb9e7ef9d0dd545c2f4a29a5bb97cc8ac15d2eb",
    reward1: 0,
    reward2: 0,
  },
  "0xb83fc2c72c44b6b869c64384375c979dc3f7cf05": {
    logo: "/logo/Zillacracy.png",
    ratio: 5,
    constructor: "True",
    unknown01: "595624166093549865952",
    unknown02: "2724036177435605817",
    name: "Ignite DAO",
    raw: "https://ignitedao.io",
    api: "https://ssn.ignitedao.io/api",
    unknown03: "275540845401748029",
    unknown04: "50000000",
    unknown05: "460798646137048313",
    address: "0xb83fc2c72c44b6b869c64384375c979dc3f7cf05",
    reward1: 0,
    reward2: 0,
  },
  "0xbf4e5001339dec3cda012f471f4f2d9e8bed2f5b": {
    logo: "/logo/Zillet.png",
    ratio: 5,
    constructor: "True",
    unknown01: "253233411960494256093",
    unknown02: "1909419426225375764",
    name: "Zillet",
    raw: "https://ssn.zillet.io/staking",
    api: "https://ssn.zillet.io",
    unknown03: "706424445361929028",
    unknown04: "50000000",
    unknown05: "102007647933810586",
    address: "0xbf4e5001339dec3cda012f471f4f2d9e8bed2f5b",
    reward1: 0,
    reward2: 0,
  },
  "0xc3ed69338765424f4771dd636a5d3bfa0a776a35": {
    logo: "/logo/Staked.png",
    ratio: 10,
    constructor: "False",
    unknown01: "475866700717910770",
    unknown02: "0",
    name: "Staked",
    raw: "https://zilliqa-staking.staked.cloud",
    api: "https://zilliqa-api.staked.cloud",
    unknown03: "0",
    unknown04: "100000000",
    unknown05: "0",
    address: "0xc3ed69338765424f4771dd636a5d3bfa0a776a35",
    reward1: 0,
    reward2: 0,
  },
  "0xd8de27a85c0dbc43bdd9a525e016670732db899f": {
    logo: "/logo/KuCoin.png",
    ratio: 5,
    constructor: "True",
    unknown01: "81277449135033079560",
    unknown02: "253915398143985005",
    name: "KuCoin",
    raw: "https://staking-zil.kucoin.com",
    api: "https://staking-zil.kucoin.com/api",
    unknown03: "11002727000000000",
    unknown04: "50000000",
    unknown05: "244956389423096166",
    address: "0xd8de27a85c0dbc43bdd9a525e016670732db899f",
    reward1: 0,
    reward2: 0,
  },
};

export default ssnListMap;
