import React from "react";
import { Text, Button } from "@chakra-ui/react";
import { ModalBody, ModalFooter } from "@chakra-ui/react";
import { useAppColor, useRouter } from "src/hooks";

interface ModalPremiumAccessProps {}

const ModalPremiumAccess: React.FC<ModalPremiumAccessProps> = () => {
  const { brand, text2, text3 } = useAppColor();
  const router = useRouter();
  return (
    <>
      <ModalBody
        display="flex"
        alignItems="start"
        align="start"
        flexDirection="column"
      >
        <Text fontSize="xl" fontWeight="extrabold" color={text2} mt="6">
          Premium Access Needed
        </Text>
        <Text fontSize="md" color={text3}>
          To access this feature, you would need a premium membership.
        </Text>
      </ModalBody>
      <ModalFooter justifyContent="center" mb="4">
        <Button
          w="100%"
          color="black"
          fontWeight="semibold"
          bg={brand}
          onClick={() => router.push("membership")}
          children="Get Premium"
        />
      </ModalFooter>
    </>
  );
};

export default ModalPremiumAccess;
