import React from "react";
import { PageLayout } from "src/templates";
import ExchangeAccountTop from "./components/ExchangeAccountTop";
import OtherWallet from "./components/OtherWallet";
interface ExchangeAccountPageProps {
  title: string;
}

const ExchangeAccountPage: React.FC<ExchangeAccountPageProps> = ({ title }) => {
  return (
    <PageLayout title={title} isGoBack>
      <ExchangeAccountTop />
      <OtherWallet />
    </PageLayout>
  );
};

export default ExchangeAccountPage;
