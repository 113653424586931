import React from "react";
import { Box, Checkbox, Flex, Td, Text, Tr } from "@chakra-ui/react";
import { TokenLogo } from "src/atoms";
import { formatToken, formatUsdt } from "src/lib/formatCurrency";
import { availableToken, handleGoLink } from "src/utils";
import { useAppColor } from "src/hooks";
import { IPoolInfo } from "src/constants/interfaces";
import BigNumber from "bignumber.js";
import { useAppSelector } from "src/redux/hooks";
import { overviewSelector } from "src/redux/slices/overviewSlice";
import { loginSelector } from "src/redux/slices/loginSlice";

interface LPRewardProps {
  poolInfo: IPoolInfo;
}

const LPReward: React.FC<LPRewardProps> = ({ poolInfo }) => {
  const { name, link, logo, tokenPool, symbol, subName, claimableData } =
    poolInfo;
  const { text1, text2, text3, text6 } = useAppColor();

  const [totalReward, setTotalReward] = React.useState<number>(0);
  const { tokenRates, zilPriceInUsd, tokens } =
    useAppSelector(overviewSelector);

  const { isHidden, exchangeRate } = useAppSelector(loginSelector);
  const poolToken = tokens.find((token) => token.symbol === symbol);
  const rate =
    tokenRates.find((tokenRate) => tokenRate.token.symbol === symbol)?.rate ||
    1;

  React.useEffect(() => {
    let total: number = 0;
    if (poolToken) {
      const poolTokenDecimal = poolToken.decimals;
      claimableData &&
        claimableData.forEach((claimable) => {
          total += new BigNumber(claimable.amount)
            .div(Math.pow(10, poolTokenDecimal))
            .toNumber();
        });
    }
    setTotalReward(total);
  }, [claimableData, tokens, poolToken]);

  let tokenList = [] as string[];

  tokenPool.forEach((tokenPool) => {
    const { token } = tokenPool;
    if (availableToken(tokenPool)) {
      return;
    }
    tokenList.push(token.icon);
  });
  return (
    <Tr>
      <Td>
        <Checkbox />
      </Td>
      <Td>
        <Flex alignItems="center">
          <TokenLogo src={logo} size="sm" mr="2" />
          <Box>
            <Text color={text2} fontSize="sm" fontWeight="semibold">
              {name}
            </Text>
            <Text
              color={text6}
              fontSize="xs"
              cursor="pointer"
              onClick={() => handleGoLink(link)}
            >
              {subName}
            </Text>
          </Box>
        </Flex>
      </Td>
      <Td isNumeric>
        {totalReward > 0 && (
          <>
            <Flex
              alignItems="center"
              justifyContent="flex-end"
              color={text1}
              fontSize="sm"
              fontWeight="semibold"
            >
              <Text>{formatToken(totalReward, symbol, isHidden)}</Text>
              <TokenLogo size="2xs" src={logo} ml="1" />
            </Flex>
            <Text color={text3} fontSize="xs">
              {formatUsdt(
                totalReward * zilPriceInUsd * rate,
                exchangeRate,
                isHidden
              )}
            </Text>
          </>
        )}
      </Td>
    </Tr>
  );
};

export default LPReward;
