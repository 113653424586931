import axios from "axios";

const apiGetStakingRewardState = async () => {
  try {
    const url = "https://viewblock.zillet.io/stats";
    const { data } = await axios.get(url);
    return data;
  } catch (error) {
    return undefined;
  }
};

export { apiGetStakingRewardState };
