import axios from "axios";
import BigNumber from "bignumber.js";
import groupBy from "lodash/groupBy";
import { BN, bytes } from "@zilliqa-js/util";
// import { ObservedTx, Zilswap } from "zilswap-sdk";
import { Zilswap } from "zilswap-sdk";
import {
  IToken,
  IClaimableData as IEstimatedReward,
  IEstimateReward,
} from "src/constants/interfaces";
import { IWallet } from "src/redux/slices/loginSlice";
import { Network } from "zilswap-sdk/lib/constants";
import { fromBech32Address } from "@zilliqa-js/zilliqa";
import {
  getGasLimitForClaimZilSwap,
  getGasPriceForClaimZilSwap,
} from "./zilpayApi";

export const MSG_VERSION = 1;
export const CHAIN_ID = 1;
export const REWARDS_DISTRIBUTOR_CONTRACT =
  "zil17vn9jep2s2ajqf694tygg83nrv6nmyqjja8t85";

export interface EmissionInfo {
  epoch_period: number;
  initial_epoch_number: number;
  tokens_per_epoch: string;
  tokens_for_retroactive_distribution: string;
  retroactive_distribution_cutoff_time: number;
  distribution_start_time: number;
  total_number_of_epochs: number;
  developer_token_ratio_bps: number;
  trader_token_ratio_bps: number;
}

export interface Distributor {
  name: string;
  distributor_name: string;
  reward_token_symbol: string;
  reward_token_address_hex: string;
  distributor_address_hex: string;
  emission_info: EmissionInfo;
  incentivized_pools?: {
    [pool: string]: number;
  };
}

export interface DistributorWithTimings extends Distributor {
  currentEpochStart: number;
  currentEpochEnd: number;
}

export interface Distribution {
  id: string;
  distrAddr: string;
  epochNumber: number;
  amount: BigNumber;
  proof: string[];
}

export interface DistributionWithStatus {
  info: Distribution;
  readyToClaim: boolean;
  claimed?: boolean;
  claimTx?: any;
}

export interface DistributorWithTimings extends Distributor {
  currentEpochStart: number;
  currentEpochEnd: number;
}

export type ClaimableRewards = DistributionWithStatus & {
  rewardToken: IToken;
  // rewardDistributor: DistributorWithTimings;
  rewardDistributor: Distributor;
};

export interface PotentialRewards {
  [pool: string]: ReadonlyArray<{
    amount: BigNumber;
    tokenAddress: string;
  }>;
}

export type PoolReward = {
  distributorName: string;
  rewardToken: IToken;
  currentEpochStart: number;
  currentEpochEnd: number;
  amountPerEpoch: BigNumber;
  weightedLiquidity: BigNumber;
};

export type PoolRewards = ReadonlyArray<PoolReward>;

export interface SimpleMap<V> {
  [index: string]: V;
}

export interface RewardsState {
  distributors: ReadonlyArray<DistributorWithTimings>;
  distributions: ReadonlyArray<DistributionWithStatus>;
  rewardsByPool: SimpleMap<PoolRewards>;
  potentialRewardsByPool: PotentialRewards;
  claimedDistributions: ReadonlyArray<string>;
}

export interface ClaimEpochOpts {
  // network: Network;
  // wallet: ConnectedWallet;
  epochNumber: number;
  amount: BigNumber;
  proof: string[];
}

export interface ClaimMultiOpts {
  // network: Network;
  // wallet: ConnectedWallet;
  distributions: Distribution[];
}

export const apiGetDistributionInfo = async (): // address_bech32: string
Promise<Array<Distributor>> => {
  try {
    const url = `https://stats.zilswap.org/distribution/info`;
    const { data } = await axios.get(url);
    return data;
  } catch (error) {
    return Array<Distributor>();
  }
};

export const apiGetZilswapEstimateReward = async (
  address_bech32: string,
  tokens: IToken[]
): Promise<IEstimateReward[]> => {
  try {
    const url = `https://stats.zilswap.org/distribution/estimated_amounts/${address_bech32}`;
    const { data } = await axios.get<any>(url);
    let estimatedReward = [] as IEstimateReward[];

    for (const dex in data) {
      Object.entries<string>(data[dex]).forEach(([key, value]) => {
        let rewardToken = tokens.find((token) => token.address_bech32 === key);
        if (rewardToken) {
          let updateIndex = estimatedReward.findIndex(
            (estimateReward) =>
              estimateReward.estimateRewardSymbol === rewardToken?.symbol
          );
          if (updateIndex !== -1) {
            estimatedReward[updateIndex].estimateReward += new BigNumber(value)
              .div(Math.pow(10, 12 || rewardToken.decimals))
              .toNumber();
          } else {
            estimatedReward.push({
              estimateReward: new BigNumber(value)
                .div(Math.pow(10, 12 || rewardToken.decimals))
                .toNumber(),
              estimateRewardSymbol: rewardToken.symbol,
            });
          }
        }
      });
    }
    return estimatedReward;
  } catch (error) {
    return [] as IEstimateReward[];
  }
};

export const apiGetZilSwapClaimableData = async (
  address_bech32: string
): Promise<IEstimatedReward[]> => {
  try {
    const url = `https://stats.zilswap.org/distribution/claimable_data/${address_bech32}`;
    const { data } = await axios.get<any>(url);
    let claimableData: IEstimatedReward[] = Array<IEstimatedReward>();
    if (Array.isArray(data)) {
      data.forEach((item) => {
        claimableData.push({
          id: item.id,
          distributor_address: item.distributor_address,
          epoch_number: item.epoch_number,
          address_bech32: item.address_bech32,
          address_hex: item.address_hex,
          amount: item.amount,
          proof: item.proof,
        });
      });
    }
    return claimableData;
  } catch (error) {
    return Array<IEstimatedReward>();
  }
};

export const getMultiTxArgs = (
  distributions: Distribution[],
  userAddr: string,
  contractAddr: string
) => {
  const groupedDistrs = groupBy(distributions, "distrAddr");

  return [
    {
      vname: "account",
      type: "ByStr20",
      value: userAddr,
    },
    {
      vname: "claims",
      type: `List(Pair ByStr20 (List (Pair (Pair Uint32 Uint128) (List ByStr32))))`,
      value: Object.entries(groupedDistrs).map(([distrAddr, distrs]) => ({
        constructor: "Pair",
        argtypes: [
          "ByStr20",
          "List (Pair (Pair Uint32 Uint128) (List ByStr32))",
        ],
        arguments: [
          distrAddr,
          distrs.map((distribution) => {
            return {
              constructor: "Pair",
              argtypes: ["Pair Uint32 Uint128", "List ByStr32"],
              arguments: [
                {
                  constructor: "Pair",
                  argtypes: ["Uint32", "Uint128"],
                  arguments: [
                    distribution.epochNumber.toString(),
                    distribution.amount.toString(10),
                  ],
                },
                distribution.proof.map((item) => `0x${item}`),
              ],
            };
          }),
        ],
      })),
    },
  ];
};

/**
 * Claim multi epochs
 * @param zilswap
 * @param currentWallet
 * @param claimOpts
 */
export const claimMulti = async (
  zilswap: any,
  currentWallet: IWallet,
  claimOpts: ClaimMultiOpts
): Promise<string | undefined> => {
  const { distributions } = claimOpts;
  if (!distributions || !distributions.length) {
    return;
  }

  const contractAddr = REWARDS_DISTRIBUTOR_CONTRACT;

  const distContract = zilswap.getContract(contractAddr);

  const userAddr = currentWallet.zilAddressBase16;

  const contractAddrByStr20 = fromBech32Address(contractAddr).toLowerCase();

  const args: any = getMultiTxArgs(
    distributions,
    userAddr,
    contractAddrByStr20
  );

  const gasPrice = getGasPriceForClaimZilSwap();
  const gasLimit = getGasLimitForClaimZilSwap();

  const params: any = {
    version: bytes.pack(CHAIN_ID, MSG_VERSION),
    amount: new BN(0),
    gasPrice: gasPrice,
    gasLimit: gasLimit,
  };

  const claimTx = await zilswap.callContract(
    distContract,
    "ClaimMulti",
    args,
    params,
    true
  );

  // console.log("claimTx", claimTx);

  if (claimTx.isRejected()) {
    throw new Error("Submitted transaction was rejected.");
  }

  // const observeTxn: ObservedTx = {
  //   hash: claimTx.id!,
  //   deadline: Number.MAX_SAFE_INTEGER,
  // };
  // await zilswap.observeTx(observeTxn);

  return `0x${claimTx.id}`;
};

/**
 * Fetch zilswap distribution
 * @param claimableData
 */
export const apiGetDistributionsWithStatus = async (
  claimableData: IEstimatedReward[]
): Promise<DistributionWithStatus[]> => {
  const zilswap = new Zilswap(Network.MainNet);
  type Substate = { merkle_roots: { [epoch_number: string]: string } };
  const distributions: DistributionWithStatus[] = [];
  const uploadStates: { [distributor_address: string]: Substate } = {};
  for (let i = 0; i < claimableData.length; ++i) {
    const info = claimableData[i];
    const addr = info.distributor_address;
    let uploadState = uploadStates[addr];
    if (!uploadState) {
      const contract = zilswap.getContract(addr);
      uploadStates[addr] = uploadState = await contract.getSubState(
        "merkle_roots"
      );
    }
    const merkleRoots = uploadState?.merkle_roots ?? {};
    distributions.push({
      info: {
        id: info.id,
        distrAddr: info.distributor_address,
        epochNumber: info.epoch_number,
        amount: info.amount,
        proof: info.proof.split(" "),
      },
      readyToClaim: typeof merkleRoots[info.epoch_number] === "string",
    });
  }
  return distributions;
};
