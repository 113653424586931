import React from "react";
import { Box, Flex, SimpleGrid, Text } from "@chakra-ui/layout";
import { useBoolean } from "@chakra-ui/hooks";
import { Button } from "@chakra-ui/button";
import { HiEyeOff, HiEye } from "react-icons/hi";
import { TextCurrency } from "src/molecules";
import { Format_Types } from "src/constants/type";
import { TokenLogo } from "src/atoms";
import { Images } from "src/assets/images";
import { useAppColor, useRouter } from "src/hooks";
import { IconArrowLeft } from "src/assets/icons";
import { useTranslation } from "react-i18next";

interface ExchangeAccountTopProps {}

const tokenList = [
  { logo: Images.zil, tokenName: "zil329bhfjs783jdsak7c6" },
  { logo: Images.coinbase, tokenName: "wasasdadasdad" },
  { logo: Images.zilswap, tokenName: "wasasdadasdad" },
  { logo: Images.boltx, tokenName: "wasasdadasdad" },
];

const ExchangeAccountTop: React.FC<ExchangeAccountTopProps> = () => {
  const router = useRouter();
  const { t } = useTranslation(["totalWalletAccount", "common"]);
  const { main1, text4, text2 } = useAppColor();

  const totalBalance = 5000000;

  const [isShow, setShow] = useBoolean();
  const [filteredList] = React.useState(tokenList);

  return (
    <Box>
      <Box display={{ base: "block", lg: "none" }}>
        <Flex flexDirection="row" alignItems="center" mb="4">
          <Box
            cursor="pointer"
            display="flex"
            w="24px"
            h="24px"
            aria-label="back"
            justifyContent="flex-start"
            alignItems="center"
            onClick={router.history.goBack}
          >
            <IconArrowLeft fill={text2} />
          </Box>
          <Text
            fontSize="2xl"
            fontWeight="semibold"
            lineHeight="8"
            color={text2}
          >
            {t("common:binanceAccounts")}
          </Text>
        </Flex>
      </Box>
      <SimpleGrid columns={{ base: 1, md: 1 }} spacing="6">
        <Flex
          boxShadow="2px 10px 15px rgba(0, 0, 0, 0.05)"
          px="4"
          pt="4"
          pb="6"
          bg={main1}
          borderRadius="lg"
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          flexDirection={{ base: "column", lg: "row" }}
        >
          <Box>
            <Flex alignItems="center">
              <Text
                fontSize="sm"
                lineHeight="5"
                fontWeight="semibold"
                color={text4}
                mr="1"
              >
                {t("common:balance")}
              </Text>
              <Button
                variant="outline"
                onClick={setShow.toggle}
                size="xs"
                leftIcon={isShow ? <HiEyeOff /> : <HiEye />}
                bg={main1}
                color={text4}
              >
                {isShow ? t("common:show") : t("common:hide")} {t("balance")}
              </Button>
            </Flex>
            <TextCurrency
              fontSize="3xl"
              lineHeight="9"
              fontWeight="bold"
              color={text2}
              mt="4"
              formatType={isShow ? Format_Types.Default : Format_Types.Secure}
            >
              {totalBalance}
            </TextCurrency>
          </Box>
          <Box display="flex" alignItems="center" mt={{ base: "4", lg: "0" }}>
            {filteredList.map((token, index) => {
              const { logo } = token;
              if (index >= 4) {
                return <div key={index} />;
              }
              return (
                <TokenLogo
                  key={index}
                  src={logo}
                  w={{ base: "4", lg: "6" }}
                  h={{ base: "4", lg: "6" }}
                  mr={index < filteredList.length - 1 ? "3" : 0}
                />
              );
            })}
          </Box>
        </Flex>
      </SimpleGrid>
    </Box>
  );
};

export default ExchangeAccountTop;
