import React from "react";
import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useAppColor } from "src/hooks";

import TCItem from "./TCItem";

import { data, data_tc } from "src/constants/data";

const Footer: React.FC = () => {
  const { text5, main3 } = useAppColor();
  const { t } = useTranslation("common");
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box minH="55px" mt="6">
      <Box
        borderTop="1px"
        borderColor={main3}
        display="flex"
        maxW="7xl"
        justifyContent="space-between"
        alignItems="center"
        mx="auto"
        py="16px"
        fontSize="xs"
        lineHeight="4"
        fontWeight="bold"
        color={text5}
      >
        <Text>© 2022 www.heyalfie.io. {` v3.5.3`}</Text>
        <Text cursor="pointer" onClick={onOpen}>
          {t("termsOfUse")}
        </Text>
      </Box>
      <Modal size="2xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent h="full" maxH="70%" mx="4">
          <Flex alignItems="center" justifyContent="space-between" p="6" pb="4">
            <Text fontSize="xl" fontWeight="semibold">
              {t("termsOfUse")}
            </Text>
            <ModalCloseButton position="unset" />
          </Flex>
          <ModalBody overflowY="auto">
            <Text fontSize="lg" fontWeight="semibold" mb="4">
              Effective Date: December, 2021
            </Text>
            <VStack spacing="6" alignItems="unset" fontSize="md" color={text5}>
              {data.map((item, index) => {
                return <Text key={index}>{item}</Text>;
              })}
              {data_tc.map((item, index) => {
                return <TCItem key={index} item={item} index={index} />;
              })}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Footer;
