import React from "react";
import Icon from "@chakra-ui/icon";
import { SvgIconProps } from "src/constants/type";

const SvgComponent: React.FC<SvgIconProps> = ({
  size,
  ...props
}: SvgIconProps) => {
  return (
    <Icon {...props} w={size} h={size} viewBox="0 0 16 16">
      <path d="M14.4738 6.19281L11.8071 3.52614C11.7449 3.46398 11.6711 3.41467 11.5899 3.38103C11.5087 3.34739 11.4217 3.33008 11.3338 3.33008C11.1562 3.33008 10.986 3.4006 10.8604 3.52614C10.7349 3.65168 10.6644 3.82194 10.6644 3.99947C10.6644 4.17701 10.7349 4.34727 10.8604 4.47281L12.3938 5.99947H4.66709C4.49027 5.99947 4.32071 6.06971 4.19568 6.19473C4.07066 6.31976 4.00042 6.48933 4.00042 6.66614C4.00042 6.84295 4.07066 7.01252 4.19568 7.13754C4.32071 7.26257 4.49027 7.33281 4.66709 7.33281H14.0004C14.132 7.33215 14.2605 7.29255 14.3697 7.21899C14.4788 7.14544 14.5637 7.04122 14.6138 6.91947C14.6648 6.79807 14.6788 6.66426 14.6538 6.53493C14.6289 6.4056 14.5663 6.28655 14.4738 6.19281V6.19281ZM11.3338 8.66614H2.00042C1.8688 8.6668 1.74032 8.7064 1.63118 8.77995C1.52203 8.85351 1.4371 8.95773 1.38709 9.07947C1.33603 9.20088 1.32208 9.33469 1.347 9.46401C1.37192 9.59334 1.43458 9.71239 1.52709 9.80614L4.19375 12.4728C4.25573 12.5353 4.32946 12.5849 4.4107 12.6187C4.49194 12.6526 4.57908 12.67 4.66709 12.67C4.75509 12.67 4.84223 12.6526 4.92347 12.6187C5.00471 12.5849 5.07844 12.5353 5.14042 12.4728C5.2029 12.4108 5.2525 12.3371 5.28635 12.2559C5.32019 12.1746 5.33762 12.0875 5.33762 11.9995C5.33762 11.9115 5.32019 11.8243 5.28635 11.7431C5.2525 11.6618 5.2029 11.5881 5.14042 11.5261L3.60708 9.99947H11.3338C11.5106 9.99947 11.6801 9.92923 11.8052 9.80421C11.9302 9.67919 12.0004 9.50962 12.0004 9.33281C12.0004 9.15599 11.9302 8.98643 11.8052 8.8614C11.6801 8.73638 11.5106 8.66614 11.3338 8.66614Z" />
    </Icon>
  );
};

const IconExchange = React.memo(SvgComponent);
export default IconExchange;
