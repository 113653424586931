import React, { useEffect } from "react";
import {
  Image,
  Text,
  Button,
  Input,
  chakra,
  useToast,
  Alert,
  AlertIcon,
  Box,
  CloseButton,
  Flex,
} from "@chakra-ui/react";
import {
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import { Images } from "src/assets/images";
import { useAppColor, useCampaign, useRouter } from "src/hooks";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "src/redux/hooks";
import { loginSelector } from "src/redux/slices/loginSlice";
import { overviewSelector } from "src/redux/slices/overviewSlice";
import { heyAlfieApiEndpoint } from "src/api/heyAlfieApi";
import dayjs from "dayjs";
import axios from "axios";

interface ModalBannerProps {
  onClose: () => void;
}

const ModalBanner: React.FC<ModalBannerProps> = ({ onClose }) => {
  const [isSubmitted, setIsSubmitted] = React.useState<boolean | undefined>();

  const toast = useToast();

  const router = useRouter();

  const { t } = useTranslation("banner");

  const { currentWallet } = useAppSelector(loginSelector);

  const { heyAlfieNfts } = useAppSelector(overviewSelector);

  const campaignQualified = heyAlfieNfts.length > 0;

  const { text2, text3 } = useAppColor();

  const { startOfCampaign } = useCampaign();

  useEffect(() => {
    const postParams = JSON.stringify({
      bech32: currentWallet?.zilAddress,
    });
    axios
      .post(`${heyAlfieApiEndpoint}/api/v1/events/check`, postParams, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(function (response: any) {
        const { data } = response;

        if (!data?.result?.lastSubmitedAt) {
          // can submit
          setIsSubmitted(false);
          return;
        }

        // Last submitted
        const unixLastSubmittedAt = dayjs(
          `${data?.result?.lastSubmitedAt}`
        ).unix();

        setIsSubmitted(unixLastSubmittedAt >= startOfCampaign);
      })
      .catch(function (error: any) {
        console.log(error);
      });
  }, [currentWallet?.zilAddress, startOfCampaign]);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!campaignQualified) {
      if (!toast.isActive("noAlfie")) {
        toast({
          id: "noAlfie",
          render: () => (
            <Alert
              status={"error"}
              p={3}
              borderRadius="8"
              variant={"solid"}
              pr={8}
            >
              <CloseButton
                size="sm"
                position={"absolute"}
                right="1"
                top="1"
                onClick={() => toast.close("noAlfie")}
              />
              <Flex>
                <AlertIcon mt="1" />
                <Box>
                  <Text fontWeight="bold">
                    {"You need to own an ALFIE to join."}.
                  </Text>
                  <Text
                    textDecorationLine="underline"
                    cursor="pointer"
                    onClick={() => {
                      onClose();
                      router.push("/membership");
                    }}
                  >
                    {"Mint an ALFIE today."}
                  </Text>
                </Box>
              </Flex>
            </Alert>
          ),
        });
      }
      return;
    }
    const postParams = JSON.stringify({
      bech32: currentWallet?.zilAddress,
    });
    axios
      .post(`${heyAlfieApiEndpoint}/api/v1/events/sync_twitter`, postParams, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(function (response: any) {
        setIsSubmitted(true);
        const url = "https://ctt.ac/6f55X"; // Zil Thug
        window.open(url, "_blank", "noreferrer");
      })
      .catch(function (error: any) {
        toast({
          id: "twitter",
          description:
            "Please check your twitter account id, or try again later",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  return (
    <>
      <ModalCloseButton />
      <ModalHeader />
      <ModalBody
        display="flex"
        alignItems="center"
        align="center"
        flexDirection="column"
        px="20"
      >
        <Image w="24" h="24" src={Images.firework} />
        <Text fontSize="3xl" fontWeight="extrabold" color={text2} my="6">
          {t("title")}
        </Text>
        <Text textAlign="left" color={text3} whiteSpace={"pre-wrap"}>
          {t("detail")}
        </Text>
        <chakra.form w="full" onSubmit={(event) => onSubmit(event)}>
          <br />
          {false && (
            <Input
              name="account"
              placeholder={t("detail")}
              onChange={
                (/*event*/) => {
                  // setAccountName(event.target.value);
                }
              }
              my="6"
            />
          )}
          {isSubmitted === true && (
            <Button children={t("entered")} type="submit" disabled />
          )}
          {isSubmitted === false && (
            <Button children={t("join")} type="submit" />
          )}
        </chakra.form>
      </ModalBody>
      <ModalFooter flexDirection="column" justifyContent="center" px="6">
        <Text
          textAlign="center"
          mb="6"
          fontSize="sm"
          color={text2}
          whiteSpace={"pre-wrap"}
        >
          {t("footer")}
        </Text>
      </ModalFooter>
    </>
  );
};

export default ModalBanner;
