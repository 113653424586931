import React from "react";
import { Box } from "@chakra-ui/layout";
import { Button, Text, HStack, Stack } from "@chakra-ui/react";
import { TokenLogo } from "src/atoms";
import { formatToken, formatUsdt } from "src/lib/formatCurrency";
import { useTranslation } from "react-i18next";
import { useAppColor } from "src/hooks";
import { useAppSelector } from "src/redux/hooks";
import { loginSelector } from "src/redux/slices/loginSlice";
import { overviewSelector } from "src/redux/slices/overviewSlice";
import { IPendingReward } from "src/constants/interfaces";
import { handleGoLink } from "src/utils";

interface StakingTableItemProps {
  name: string;
  logo: string;
  ratio: number;
  stakingBalance?: number;
  pendingReward?: IPendingReward;
  rate: number;
  seedNode?: number | string;
  symbol: string;
  link: string;
  stakeLogo: string;
  stakeSymbol?: string;
  rewardSymbol?: string;
  last?: boolean;
  reward?: number;
}

const StakingTableItem: React.FC<StakingTableItemProps> = (props) => {
  const {
    name,
    logo,
    stakingBalance,
    pendingReward,
    rate,
    symbol,
    seedNode,
    stakeLogo: _stakeLogo,
    stakeSymbol,
    rewardSymbol,
    ratio,
    link,
  } = props;
  const { t } = useTranslation(["overview", "common"]);
  const { zilPriceInUsd, tokenRates } = useAppSelector(overviewSelector);

  const { text2, text5, text6, main4, main3, brand, main2 } = useAppColor();

  const { isHidden, exchangeRate } = useAppSelector(loginSelector);

  const stakeToken = tokenRates.find(
    (token) => token.token.symbol === stakeSymbol
  );
  const rewardToken = tokenRates.find((token) =>
    rewardSymbol
      ? token.token.symbol === rewardSymbol
      : token.token.symbol === pendingReward?.pendingRewardSymbol
  );

  const stakeLogo = stakeToken?.token.icon || _stakeLogo;
  const rewardLogo = rewardToken?.token.icon || _stakeLogo;
  const stakeRate = stakeToken?.rate || rate;
  const rewardRate = rewardToken?.rate || rate;

  return (
    <Stack
      cursor="pointer"
      justify="space-between"
      bg={main3}
      borderRadius="12px"
      p="20px"
    >
      <HStack>
        {stakeSymbol && rewardSymbol ? (
          <Box position="relative" mr="2" w="9" h="9">
            <TokenLogo size="xs" src={stakeLogo} />
            <TokenLogo
              position="absolute"
              size="xs"
              src={rewardLogo}
              right="0"
              bottom="0"
            />
          </Box>
        ) : (
          <TokenLogo size="sm" mr="2" src={logo} />
        )}
        <Box justifyContent="flex-end">
          <Text color={text2} fontWeight="semibold">
            {name}
          </Text>
          {ratio !== 0 && (
            <Text fontSize="xs" color={text6}>
              {t("common:fee")} | {ratio}%
            </Text>
          )}
          {!!seedNode && seedNode !== 0 && (
            <Text fontSize="xs" color={text6}>
              {t("overview:seedNode")} | {seedNode}%
            </Text>
          )}
        </Box>
      </HStack>
      <HStack justify="space-between" align="start">
        <Text fontSize="12px" lineHeight="16px" fontWeight="500" color={text5}>
          {t("deposit")}
        </Text>
        <Stack align="end">
          <HStack>
            <Text
              fontSize="14px"
              lineHeight="20px"
              fontWeight="600"
              color={text2}
            >
              {stakingBalance
                ? formatToken(stakingBalance, stakeSymbol || symbol, isHidden)
                : "0.00 " + (stakeSymbol || symbol)}
            </Text>
            <TokenLogo size="2xs" src={stakeLogo} />
          </HStack>
          <Text fontSize="12px" lineHeight="16px" color={text6}>
            ~
            {stakingBalance
              ? formatUsdt(
                  stakingBalance * zilPriceInUsd * stakeRate,
                  exchangeRate,
                  isHidden
                )
              : "$0.00"}
          </Text>
        </Stack>
      </HStack>
      <HStack justify="space-between" align="start">
        <Text fontSize="12px" lineHeight="16px" fontWeight="500" color={text5}>
          {t("profile:unclaimReward")}
        </Text>
        <Stack align="end">
          <HStack>
            <Text
              fontSize="14px"
              lineHeight="20px"
              fontWeight="600"
              color={text2}
            >
              {pendingReward && pendingReward.pendingReward > 0
                ? formatToken(
                    pendingReward.pendingReward,
                    rewardSymbol || symbol,
                    isHidden
                  )
                : 0}
            </Text>
            <TokenLogo size="2xs" src={rewardLogo} />
          </HStack>
          <Text fontSize="12px" lineHeight="16px" color={text6}>
            ~{" "}
            {pendingReward && pendingReward.pendingReward > 0
              ? formatUsdt(
                  pendingReward.pendingReward * rewardRate * zilPriceInUsd,
                  exchangeRate,
                  isHidden
                )
              : 0}
          </Text>
        </Stack>
      </HStack>
      <Box bg={main4} w="100%" h="1px"></Box>
      <HStack justify="center" pt="8px">
        {/* <Button
                          w="122px"
                          children={t("common:remove")}
                          bg={main4}
                          textColor={text4}
                          mr="4"
                          size="sm"
                        /> */}
        <Button
          w="122px"
          children={t("common:add")}
          size="sm"
          bg={brand}
          textColor={main2}
          onClick={() => handleGoLink(link)}
        />
      </HStack>
    </Stack>
  );
};

export default StakingTableItem;
