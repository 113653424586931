import React from "react";
import { Box, Flex, SimpleGrid, Text } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/button";
import { HiEyeOff, HiEye } from "react-icons/hi";
import { TokenLogo } from "src/atoms";
import { useAppColor } from "src/hooks";
import { useAppSelector } from "src/redux/hooks";
import { loginSelector } from "src/redux/slices/loginSlice";
import { get } from "local-storage";
import { overviewSelector } from "src/redux/slices/overviewSlice";
import { useTranslation } from "react-i18next";
import { AvatarGroup } from "@chakra-ui/avatar";
import { formatUsdt } from "src/lib/formatCurrency";

interface ManageAccountProps {
  isShow: boolean;
  toggleShow: () => void;
}

const ManageAccount: React.FC<ManageAccountProps> = ({
  isShow,
  toggleShow,
}) => {
  const { t } = useTranslation(["totalWallet", "common"]);
  const { main1, text4, text2 } = useAppColor();

  const { savedWallets, exchangeRate, isHidden } =
    useAppSelector(loginSelector);
  const { zilPriceInUsd } = useAppSelector(overviewSelector);
  const [totalHoldings, setTotalHolding] = React.useState<string[]>([]);

  const [totalBalance, setTotalBalance] = React.useState<number>(0);

  React.useEffect(() => {
    let total = 0;
    let holdings = [] as string[];
    savedWallets.forEach((savedWallet) => {
      total += get<number>(`${savedWallet.zilAddress}`);
      savedWallet.holdings.forEach((holding) => {
        holdings.push(holding);
      });
    });
    setTotalBalance(total);
    setTotalHolding([...new Set(holdings)]);
  }, [savedWallets]);

  return (
    <Box>
      <Text
        display={{ base: "block", lg: "none" }}
        fontSize="2xl"
        fontWeight="semibold"
        lineHeight="8"
        color={text2}
        mb="4"
      >
        {t("common:manageAccounts")}
      </Text>
      <SimpleGrid columns={{ base: 1, md: 1 }} spacing="6">
        <Flex
          boxShadow="2px 10px 15px rgba(0, 0, 0, 0.05)"
          px="4"
          pt="4"
          pb="6"
          bg={main1}
          borderRadius="lg"
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          flexDirection={{ base: "column", lg: "row" }}
        >
          <Box>
            <Flex alignItems="center">
              <Text
                fontSize="sm"
                lineHeight="5"
                fontWeight="semibold"
                color={text4}
                mr="1"
              >
                {t("yourNetWorth")}
              </Text>
              <Button
                variant="outline"
                onClick={toggleShow}
                size="xs"
                leftIcon={!isShow ? <HiEyeOff /> : <HiEye />}
                bg={main1}
                color={text4}
              >
                {!isShow ? t("common:show") : t("common:hide")} {t("balance")}
              </Button>
            </Flex>
            <Text
              fontSize="3xl"
              lineHeight="9"
              fontWeight="bold"
              color={text2}
              mt="4"
            >
              {formatUsdt(
                totalBalance * zilPriceInUsd,
                exchangeRate,
                !isShow || isHidden
              )}
            </Text>
          </Box>
          <Box display="flex" alignItems="center" mt={{ base: "4", lg: "0" }}>
            <AvatarGroup
              size="xs"
              spacing={2}
              pl="3"
              py="2"
              max={8}
              fontSize="xs"
            >
              {totalHoldings.length !== 0 ? (
                totalHoldings.map((holding, index) => (
                  <TokenLogo key={index} src={holding} />
                ))
              ) : (
                <TokenLogo visibility="hidden" />
              )}
            </AvatarGroup>
          </Box>
        </Flex>
      </SimpleGrid>
    </Box>
  );
};

export default ManageAccount;
