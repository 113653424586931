import dayjs from "dayjs";

const useCampaign = () => {
  const now = dayjs.utc().unix();
  const start = dayjs.utc("2022-07-04 00:00", "YYYY-MM-DD HH:mm").unix();
  const end = dayjs.utc("2022-07-07 12:00", "YYYY-MM-DD HH:mm").unix();

  let onCampaign = now >= start && now < end;

  return { onCampaign, startOfCampaign: start };
};

export default useCampaign;
