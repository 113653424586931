import React from "react";
import { Box, Button, Center, Stack, Table, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { DistributionWithStatus } from "src/api/zilswapApi";
import { ButtonUnlock } from "src/atoms";
import { useAppColor } from "src/hooks";
import { useAppSelector } from "src/redux/hooks";
import { IWallet, loginSelector } from "src/redux/slices/loginSlice";
import { handleGoLink, isEmptyProfileLiquidity } from "src/utils";
import LPHuny from "./LPHuny";
import LPHunyMb from "./LPHunyMb";
import LPZwap from "./LPZwap";
import LPZwapMb from "./LPZwapMb";
import LPCarbSwap from "./LPCarbSwap";
import LPXcad from "./LPXcad";
import LPXcadMb from "./LPXcadMb";
import LPCarbSwapMb from "./LPCarbSwapMb";

interface LiquidityPoolTokenProps {
  viewWallet: IWallet;
  active?: boolean;
}

const LiquidityPoolToken: React.FC<LiquidityPoolTokenProps> = ({
  viewWallet,
  active,
}) => {
  const { t } = useTranslation(["overview", "common", "profile"]);

  const { main1, main2, main3, main6, brand } = useAppColor();

  const { isHidden } = useAppSelector(loginSelector);
  const { allLiquidityPools } = viewWallet;

  const isEmptyDistributions = (distributions: DistributionWithStatus[]) => {
    return distributions.length === 0;
  };
  const availableLiquidityPool = allLiquidityPools
    ? allLiquidityPools.filter(
        (liquidityPool) =>
          !isEmptyProfileLiquidity(liquidityPool.tokenPool) ||
          !isEmptyDistributions(liquidityPool.distributions) ||
          !active
      )
    : [];

  const allEmpty = availableLiquidityPool.length === 0;

  return (
    <Box>
      {allEmpty ? (
        <Center h="312px" borderRadius="12px" bg={main3}>
          <Stack align="center">
            <Text fontSize="18px" color={main6}>
              {t("profile:emptyPools")}
            </Text>
            {isHidden ? (
              <ButtonUnlock />
            ) : (
              <Button
                w="122px"
                bg={brand}
                textColor={main2}
                children={t("addLiquidity")}
                onClick={() => handleGoLink("www.zilswap.io")}
              />
            )}
          </Stack>
        </Center>
      ) : (
        <Box overflow="auto">
          <Box display={{ base: "none", lg: "block" }}>
            <Table
              variant="simple"
              minW="2xl"
              bg={main1}
              borderRadius="lg"
              mb="3"
            >
              {availableLiquidityPool.map((liquidityPool, index) => {
                switch (index) {
                  case 0:
                    return (
                      <LPZwap
                        poolInfo={liquidityPool}
                        key={index}
                        active={active}
                      />
                    );
                  case 1:
                    return (
                      <LPXcad
                        poolInfo={liquidityPool}
                        key={index}
                        active={active}
                      />
                    );
                  case 2:
                    return (
                      <LPCarbSwap
                        poolInfo={liquidityPool}
                        key={index}
                        active={active}
                      />
                    );
                  case 3:
                    return (
                      <LPHuny
                        poolInfo={liquidityPool}
                        key={index}
                        active={active}
                      />
                    );
                  default:
                    return <React.Fragment key={index} />;
                }
              })}
            </Table>
          </Box>
          {/* ///mobile */}
          <Box display={{ base: "block", lg: "none" }}>
            <Box
              mb="3"
              borderRadius="12px"
              borderWidth={allEmpty ? 0 : 1}
              borderColor={main3}
            >
              {availableLiquidityPool.map((liquidityPool, index) => {
                switch (index) {
                  case 0:
                    return (
                      <LPZwapMb
                        poolInfo={liquidityPool}
                        key={index}
                        active={active}
                      />
                    );
                  case 1:
                    return (
                      <LPXcadMb
                        poolInfo={liquidityPool}
                        key={index}
                        active={active}
                      />
                    );
                  case 2:
                    return (
                      <LPCarbSwapMb
                        poolInfo={liquidityPool}
                        key={index}
                        active={active}
                      />
                    );

                  case 3:
                    return (
                      <LPHunyMb
                        poolInfo={liquidityPool}
                        key={index}
                        active={active}
                      />
                    );
                  default:
                    return <React.Fragment key={index} />;
                }
              })}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default LiquidityPoolToken;
