import { IToken } from "src/constants/interfaces";
import backupTokens from "src/constants/tokens";
import axios from "axios";
import { IApiScoRewardsResponse } from "./apiInterfaces";
import { Images } from "src/assets/images";
import { IWallet } from "src/redux/slices/loginSlice";

export const heyAlfieApiEndpoint = "https://api.heyalfie.io";
// export const heyAlfieApiEndpoint = "http://localhost:3000";

export const apiGetTokens = async (): Promise<IToken[]> => {
  try {
    const res = await fetch(`${heyAlfieApiEndpoint}/api/v1/tokens`, {
      method: "GET",
    });
    let resultTokens = [] as IToken[];
    const { status, result } = await res.json();
    if (status !== true) {
      return backupTokens;
    }
    resultTokens = [...result!.tokens];
    const portIndex = resultTokens.findIndex(
      (token: IToken) => token.symbol === "PORT"
    );
    const carbonIndex = resultTokens.findIndex(
      (token: IToken) => token.symbol === "CARB"
    );
    const xsgdIndex = resultTokens.findIndex(
      (token: IToken) => token.symbol === "XSGD"
    );
    console.log(xsgdIndex);
    if (portIndex !== -1) {
      resultTokens[portIndex].icon = Images.port;
    }
    if (carbonIndex !== -1) {
      resultTokens[carbonIndex].icon = Images.carbon;
    }
    // if (xsgdIndex !== -1) {
    //   resultTokens[xsgdIndex].address_bech32 =
    //     "zil180v66mlw007ltdv8tq5t240y7upwgf7djklmwh";
    // }

    // resultTokens.pop();

    return resultTokens;
  } catch (error) {
    return backupTokens;
  }
};

export const apiGetScoRewards = async (address?: string) => {
  try {
    const url = `${heyAlfieApiEndpoint}/api/v1/staking/${address}/sco_reward`;
    const { data } = await axios.get<IApiScoRewardsResponse>(url);
    return data.result?.rewards;
  } catch (error) {
    return undefined;
  }
};

export const getWalletProfile = async (addresses: string[]) => {
  try {
    const urls = [
      `${heyAlfieApiEndpoint}/api/v1/tokens/balances`,
      `${heyAlfieApiEndpoint}/api/v1/staking`,
      `${heyAlfieApiEndpoint}/api/v1/pools`,
      `${heyAlfieApiEndpoint}/api/v1/wallets/wallet-info`,
    ];
    const postParams = JSON.stringify({
      bech32_addresses: addresses,
    });
    const data = await axios.all(
      urls.map((url) =>
        axios.post<any>(url, postParams, {
          headers: {
            "Content-Type": "application/json",
          },
        })
      )
    );

    const walletInfo = data[3].data.result;

    return {
      ...walletInfo,
      walletTokenList: data[0].data.result,
      stakingList: data[1].data.result,
      liquidityPoolList: data[2].data.result,
    };
  } catch (error) {}
};

export const getWalletsInfo = async (addresses: string[]) => {
  try {
    const url = `${heyAlfieApiEndpoint}/api/v1/wallets/wallet-info`;
    const postParams = JSON.stringify({
      bech32_addresses: addresses,
    });
    const data = await axios.post<any>(url, postParams, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    const walletInfo = data.data.result;
    const wallets: IWallet[] = [];
    if (!walletInfo && !walletInfo.wallets) {
      return wallets;
    }

    walletInfo.wallets.forEach((element: any) => {
      wallets.push({
        ...element,
        zilAddress: element.bech32,
        followers: element.followers,
        followings: element.followings,
      });
    });
    return wallets;
  } catch (error) {}
};

export const getWalletByUuid = async (uuid: string) => {
  try {
    const url = `${heyAlfieApiEndpoint}/api/v1/wallets/wallet-by-uuid`;
    const postParams = JSON.stringify({
      uuid: uuid,
    });
    const data = await axios.post<any>(url, postParams, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    const walletInfo = data.data.result;

    return walletInfo;
  } catch (error) {}
};

export const getWalletsMemberClub = async (addresses: string[]) => {
  try {
    const url = `${heyAlfieApiEndpoint}/api/v1/wallets/wallet-memberclub`;
    const postParams = JSON.stringify({
      bech32_addresses: addresses,
    });
    const data = await axios.post<any>(url, postParams, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    const walletInfo = data.data.result;
    const wallets: IWallet[] = [];
    if (!walletInfo && !walletInfo.wallets) {
      return wallets;
    }

    walletInfo.wallets.forEach((element: any) => {
      wallets.push({
        ...element,
        zilAddress: element.bech32,
        followers: element.followers,
        followings: element.followings,
      });
    });
    return wallets;
  } catch (error) {}
};

export const updateWallet = async (
  address: string,
  name?: string,
  avatar?: string,
  banner?: string
): Promise<any> => {
  try {
    const requestForm = JSON.stringify({
      bech32_address: address,
      name: name,
      avatar: avatar,
      banner: banner,
    });

    const url = `${heyAlfieApiEndpoint}/api/v1/wallets/update-wallet`;

    await axios.post<any>(url, requestForm, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (error) {}
};

export const getSavedWalletsFromDatabase = async (mainWallet: string) => {
  try {
    const url = `${heyAlfieApiEndpoint}/api/v1/wallets/saved-wallets`;

    const postParams = JSON.stringify({
      main_address: mainWallet,
    });

    const { data } = await axios.post<any>(url, postParams, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return {
      mainAddress: data.result.wallet.mainWallet,
      subAddresses: JSON.parse(data.result.wallet.subWallets),
    };
  } catch (error) {}
};

export const syncSavedWallet = async (
  mainWallet: string,
  subWallets: string[]
) => {
  try {
    const url = `${heyAlfieApiEndpoint}/api/v1/wallets/update-saved-wallets`;

    const postParams = JSON.stringify({
      main_address: mainWallet,
      sub_addresses: subWallets,
    });

    const { data } = await axios.post<any>(url, postParams, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (error) {}
};

export const followWallet = async (myBech32: string, otherBech32: string) => {
  try {
    const url = `${heyAlfieApiEndpoint}/api/v1/wallets/follow-wallet`;

    const postParams = JSON.stringify({
      myBech32: myBech32,
      otherBech32: otherBech32,
    });

    const { data } = await axios.post<any>(url, postParams, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (error) {}
};

export const unFollowWallet = async (myBech32: string, otherBech32: string) => {
  try {
    const url = `${heyAlfieApiEndpoint}/api/v1/wallets/unfollow-wallet`;

    const postParams = JSON.stringify({
      myBech32: myBech32,
      otherBech32: otherBech32,
    });

    const { data } = await axios.post<any>(url, postParams, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (error) {}
};
export const searchWallet = async (searchText: string) => {
  try {
    const url = `${heyAlfieApiEndpoint}/api/v1/wallets/search-wallet`;

    const postParams = JSON.stringify({
      searchText: searchText,
    });

    const { data } = await axios.post<any>(url, postParams, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!data.result.wallets) {
      return;
    }
    const newWallets = data.result.wallets.map((wallet: any) => {
      return {
        ...wallet,
        zilAddress: wallet.bech32,
      };
    });

    return newWallets;
  } catch (error) {}
};

export const getHistory = async (address: string) => {
  try {
    const url = `${heyAlfieApiEndpoint}/api/v1/wallets/transaction`;
    const postParams = JSON.stringify({
      bech32: address,
    });

    const { data } = await axios.post<any>(url, postParams, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (error) {
    throw error;
  }
};
