import { useToast } from "@chakra-ui/react";
import React from "react";
import { TransactionResultType } from "src/constants/enum";
import { BoxTransaction } from "src/molecules";
import { ObservedTx, TxStatus, TxReceipt } from "zilswap-sdk";

const useTransaction = () => {
  const toast = useToast();
  const toastTransaction = (type: TransactionResultType, TranId?: string) => {
    const id = type + TranId;

    if (toast.isActive(id)) {
      return;
    } else {
      toast.closeAll();
    }

    const duration = type === "inprogress" ? null : 5000;

    toast({
      id: id,
      render: () => (
        <BoxTransaction
          type={type}
          onClose={() => toast.close(id)}
          TranID={TranId}
        />
      ),
      duration: duration,
    });
  };

  const printResults = (
    tx: ObservedTx,
    status: TxStatus,
    receipt?: TxReceipt
  ) => {
    if (!receipt) {
      console.error(`\ntx ${tx.hash} failed with ${status}!\n`);
      toastTransaction(TransactionResultType.failed, tx.hash);
    } else if (status !== "confirmed") {
      console.error(`\ntx ${tx.hash} failed with ${status}!\ntx receipt: \n`);
      toastTransaction(TransactionResultType.failed, tx.hash);
      console.error(JSON.stringify(receipt, null, 2));
    } else {
      toastTransaction(TransactionResultType.confirmed, tx.hash);
      console.log(`\ntx ${tx.hash} confirmed.`);
    }
  };

  return { printResults };
};

export default useTransaction;
