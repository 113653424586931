import React from "react";
import Icon from "@chakra-ui/icon";
import { SvgIconProps } from "src/constants/type";

const SvgComponent: React.FC<SvgIconProps> = ({
  size,
  ...props
}: SvgIconProps) => {
  return (
    <Icon {...props} w={size} h={size} viewBox="0 0 24 24">
      <path
        d="M4 18H21V16H4V18ZM3 6V8H21V6H3ZM6 13H21V11H6V13Z"
        fill="currentColor"
      />
    </Icon>
  );
};

const IconMenu = React.memo(SvgComponent);
export default IconMenu;
