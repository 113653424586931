import React from "react";
import {
  InputGroup,
  Input,
  InputRightElement,
  useDisclosure,
  Modal,
  ModalContent,
  ModalOverlay,
  Flex,
  Box,
} from "@chakra-ui/react";
import { IconRecipientAddress, IconArrowRight } from "src/assets/icons";
import { useAppColor } from "src/hooks";
import ModalSendAddress from "./ModalSendAddress";
import { useTranslation } from "react-i18next";

interface AddressInputProps {
  targetAddress: string;
  setTargetAddress: (address: string) => void;
}

const AddressInput: React.FC<AddressInputProps> = ({
  targetAddress,
  setTargetAddress,
}) => {
  const { text3, main2 } = useAppColor();
  const { onOpen, isOpen, onClose } = useDisclosure();
  const { t } = useTranslation("send");
  return (
    <Box w="full">
      <InputGroup>
        <Input
          placeholder={t("address")}
          bgColor={main2}
          value={targetAddress}
          onChange={(event) => setTargetAddress(event.target.value)}
        />
        <InputRightElement
          w="max-content"
          px="2"
          cursor="pointer"
          onClick={onOpen}
        >
          <Flex alignItems="center" mt="2">
            <IconRecipientAddress size="6" color={text3} />
            <IconArrowRight size="5" />
          </Flex>
        </InputRightElement>
      </InputGroup>
      <Modal size={"xl"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalSendAddress onClose={onClose} setAddress={setTargetAddress} />
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default AddressInput;
