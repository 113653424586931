import united_state from "./flag/image_united_states.png";
import united_kingdom from "./flag/image_united_kingdom.png";
import china from "./flag/image_china.png";
import japan from "./flag/image_japan.png";
import vietnam from "./flag/image_vietnam.png";
import myanmar from "./flag/image_myanmar.png";
import hong_kong from "./flag/image_hong_kong.png";
import european_union from "./flag/image_european_union.png";
import malaysia from "./flag/image_malaysia.png";
import indonesia from "./flag/image_indonesia.png";
import singapore from "./flag/image_singapore.png";
import australia from "./flag/image_australia.png";
import newZealand from "./flag/image_new_zealand.png";
import norway from "./flag/image_norway.png";
import southKorea from "./flag/image_south_korea.png";
import argentina from "./flag/image_argentina.png";
import bahrain from "./flag/image_bahrain.png";
import bangladesh from "./flag/image_bangladesh.png";
import bermuda from "./flag/image_bermuda.png";
import brazil from "./flag/image_brazil.png";
import unitedArabEmirates from "./flag/image_united_arab_emirates.png";
import india from "./flag/image_india.png";
import canada from "./flag/image_canada.png";

import logo from "./image_logo.png";
import avatar0 from "./avatar_0.png";
import avatar2 from "./avatar_2.png";
import avatar3 from "./avatar_3.png";
import avatar4 from "./avatar_4.png";
import avatar5 from "./avatar_5.png";
import avatar6 from "./avatar_6.png";
import avatar7 from "./avatar_7.png";
import king from "./king.png";
import avatar1 from "./avatar_1.png";
import zilpay from "./image_zilpay.png";
import boltx from "./image_boltx.png";
import ledgex from "./image_ledgex.png";
import xsgd from "./image_xsgd.png";
import zilswap from "./image_zilswap.png";
// import zil from "./image_zil.png";
// import zilwatch from "./image_zilwatch.png";
import carbon from "./image_carbon.png";
import cex from "./image_cex.png";
import ezil from "./image_ezil.png";
import moonlet from "./image_moonlet.png";
import pancake from "./image_pancake.png";
import nft1 from "./image_nft_1.png";
import nft2 from "./image_nft_2.png";
import nft3 from "./image_nft_3.png";
import nft4 from "./image_nft_4.png";
import nft5 from "./image_nft_5.png";
import limited from "./image_limited.png";
import bannerAlfie from "./banner-alfie.png";
import ledger from "./image_ledger.png";
import tutorialGif from "./tutorial.gif";
import membershipGif from "./membership.gif";
import wallet from "./image_wallet.png";
import zillet from "./image_zillet.png";
import binance from "./image_binance.png";
import coinbase from "./image_coinbase.png";
import gemini from "./image_gemini.png";
import port from "./image_port.png";
import cuteDog from "./image_cute_dog.png";
import avatar from "./image_avatar.png";
import transak from "./image_transak.png";
import godZilliqa from "./image_godzilliqa.png";
import nftDuck from "./image_nft_duck.png";
import beanterra from "./image_beanterra.png";
import bannerNft from "./image_banner_nft_07042022.png";
import firework from "./image_firework.png";
import zilApe from "./image_zil_ape.png";
import soulless from "./image_soulless.png";
import graph from "./image_graph.png";
import bitcoin from "./image_bitcoin.png";

export const Images = {
  // FLAG
  united_state: united_state,
  united_kingdom: united_kingdom,
  china: china,
  japan: japan,
  vietnam: vietnam,
  myanmar: myanmar,
  hong_kong: hong_kong,
  european_union: european_union,
  malaysia: malaysia,
  indonesia: indonesia,
  singapore: singapore,
  australia: australia,
  southKorea: southKorea,
  newZealand: newZealand,
  norway: norway,
  argentina: argentina,
  bahrain: bahrain,
  bangladesh: bangladesh,
  bermuda: bermuda,
  brazil: brazil,
  unitedArabEmirates: unitedArabEmirates,
  india: india,
  canada: canada,

  // NFT Avatar
  godZilliqa: godZilliqa,
  nftDuck: nftDuck,
  beanterra: beanterra,
  zilApe: zilApe,
  soulless: soulless,

  // IMAGE
  logo: logo,
  avatar0: avatar0,
  avatar1: avatar1,
  avatar2: avatar2,
  avatar3: avatar3,
  avatar4: avatar4,
  avatar5: avatar5,
  avatar6: avatar6,
  avatar7: avatar7,
  zilpay: zilpay,
  boltx: boltx,
  ledgex: ledgex,
  zil: "https://meta.viewblock.io/ZIL/logo",
  zilswap: zilswap,
  xsgd: xsgd,
  carbon: carbon,
  cex: cex,
  ezil: ezil,
  moonlet: moonlet,
  pancake: pancake,
  nft1: nft1,
  nft2: nft2,
  nft3: nft3,
  nft4: nft4,
  nft5: nft5,
  limited: limited,
  bannerAlfie: bannerAlfie,
  king: king,
  ledger: ledger,
  tutorialGif: tutorialGif,
  membershipGif: membershipGif,
  wallet: wallet,
  zillet: zillet,
  binance: binance,
  coinbase: coinbase,
  gemini: gemini,
  port: port,
  cuteDog: cuteDog,
  avatar: avatar,
  transak: transak,
  bannerNft: bannerNft,
  firework: firework,
  graph: graph,
  defaultBanner:
    "https://heyalfienftdata.sgp1.digitaloceanspaces.com/1653533675441_kU0JJnwqSKKTpXYhHdq86_origin_image.jpg",
  defaultAvatar:
    "https://heyalfienftdata.sgp1.digitaloceanspaces.com/1653533639469__6FSIiDxhtCPV_X2l4moS_origin_image.jpg",
  bitcoin: bitcoin,
};
