import React from "react";
import {
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { useToast } from "@chakra-ui/toast";
import ModalBanner from "./ModalBanner";
import { useAppSelector } from "src/redux/hooks";
import { loginSelector } from "src/redux/slices/overviewSlice";
// import { handleGoLink } from "src/utils";

interface BannerNftProps {
  image: string;
}

const BannerNft: React.FC<BannerNftProps> = ({ image }) => {
  // const dispatch = useAppDispatch();
  const toast = useToast();
  const { currentWallet } = useAppSelector(loginSelector);

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Image
        onClick={() => {
          // handleGoLink(
          //   "medium.com/@heyalfie.io/mint-an-alfie-win-an-exodus-8b47145c7130"
          // );

          if (currentWallet && currentWallet.isZilPay) {
             onOpen();
          } else {
            toast({
              id: "connect",
              description: "Please connect your wallet",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          }
        }}
        cursor="pointer"
        fit="cover"
        src={image}
        h="160px"
        rounded={8}
        w="full"
      />
      <Modal isCentered onClose={onClose} isOpen={isOpen} size="md">
        <ModalOverlay />
        <ModalContent>
          <ModalBanner onClose={onClose} />
        </ModalContent>
      </Modal>
    </>
  );
};

export default BannerNft;
