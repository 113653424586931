import React from "react";
import Icon from "@chakra-ui/icon";
import { SvgIconProps } from "src/constants/type";

const SvgComponent: React.FC<SvgIconProps> = ({
  size,
  ...props
}: SvgIconProps) => {
  return (
    <Icon {...props} w={size} h={size} viewBox="0 0 16 16">
      <path d="M6.35406 0.678658C6.25291 0.577508 6.12648 0.526934 5.9874 0.526934C5.84832 0.526934 5.72188 0.577508 5.62073 0.678658C5.41843 0.880957 5.41843 1.20969 5.62073 1.41199L12.069 7.86027L5.62073 14.3085C5.41843 14.5108 5.41843 14.8396 5.62073 15.0419C5.82303 15.2442 6.15176 15.2442 6.35406 15.0419L13.1817 8.22694C13.3839 8.02464 13.3839 7.6959 13.1817 7.4936L6.35406 0.678658Z" />
    </Icon>
  );
};

const IconRightArrow = React.memo(SvgComponent);
export default IconRightArrow;
