import React from "react";
import Icon from "@chakra-ui/icon";
import { SvgIconProps } from "src/constants/type";

const SvgComponent: React.FC<SvgIconProps> = ({
  size,
  ...props
}: SvgIconProps) => {
  return (
    <Icon {...props} w={size} h={size} viewBox="0 0 12 12">
      <path
        d="m6 9.13-3.526 1.974.787-3.964L.294 4.396l4.013-.476L6 .25l1.693 3.67 4.014.476L8.739 7.14l.788 3.964L6 9.13Z"
        fill="#000"
      />
    </Icon>
  );
};

const IconStar = React.memo(SvgComponent);
export default IconStar;
