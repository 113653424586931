import React from "react";
import { Box, Checkbox, Flex, Td, Text, Tr } from "@chakra-ui/react";
import { fromBech32Address } from "@zilliqa-js/zilliqa";
import BigNumber from "bignumber.js";
import { groupBy } from "lodash";
import { DistributionWithStatus } from "src/api/zilswapApi";
import { TokenLogo } from "src/atoms";
import { IPoolInfo, IUnclaimedReward } from "src/constants/interfaces";
import { useAppColor } from "src/hooks";
import { formatToken, formatUsdt } from "src/lib/formatCurrency";
import { useAppSelector } from "src/redux/hooks";
import { loginSelector } from "src/redux/slices/loginSlice";
import { overviewSelector } from "src/redux/slices/overviewSlice";
import { handleGoLink } from "src/utils";

interface LPZwapRewardProps {
  poolInfo: IPoolInfo;
}

const LPZwapReward: React.FC<LPZwapRewardProps> = ({ poolInfo }) => {
  const { name, link, logo, distributors, distributions, subName } = poolInfo;

  const { exchangeRate, isHidden } = useAppSelector(loginSelector);
  const { tokens, tokenRates, zilPriceInUsd } =
    useAppSelector(overviewSelector);

  const { text1, text2, text3, text6 } = useAppColor();

  const [totalReward, setTotalReward] = React.useState<number>(0);

  const [distributionsGroupByToken, setDistributionsGroup] =
    React.useState<any>();

  const [unclaimedReward, setUnclaimedReward] = React.useState<
    IUnclaimedReward[]
  >([
    {
      token: tokens.find((token) => token.symbol === "ZWAP"),
      reward: 0,
    },
  ]);

  React.useEffect(() => {
    let unclaimedReward = [] as IUnclaimedReward[];
    if (!distributionsGroupByToken) {
      return;
    }
    let rewardTotal = 0;
    Object.keys(distributionsGroupByToken).forEach((distribution) => {
      let totalReward = 0;
      distributionsGroupByToken[distribution].forEach((list: any) => {
        totalReward += new BigNumber(list.info.amount)
          .div(Math.pow(10, list.rewardToken.decimals))
          .toNumber();
      });
      unclaimedReward.push({
        reward: totalReward,
        token: distributionsGroupByToken[distribution][0].rewardToken,
      });
      const tokenRate = tokenRates.find(
        (tokenRate) =>
          tokenRate.token.symbol ===
          distributionsGroupByToken[distribution][0].rewardToken.symbol
      );
      let rate = 1;
      if (tokenRate) {
        rate = tokenRate.rate;
      }
      rewardTotal += totalReward * rate;
    });
    setTotalReward(rewardTotal);
    setUnclaimedReward(unclaimedReward);
  }, [distributionsGroupByToken, tokenRates]);

  React.useEffect(() => {
    if (!distributions || !distributors || !tokens) {
      return;
    }

    const distributionsWithToken = distributions
      .filter((distribution) => distribution.readyToClaim)
      .flatMap((d: DistributionWithStatus) => {
        const rewardDistributor = distributors.find((distributor) => {
          return distributor.distributor_address_hex === d.info.distrAddr;
        });

        if (!rewardDistributor) {
          return [];
        }

        const rewardToken = tokens.find((token) => {
          return (
            fromBech32Address(token.address_bech32).toLowerCase() ===
            rewardDistributor.reward_token_address_hex.toLowerCase()
          );
        });

        return [{ rewardToken, rewardDistributor, ...d }];
      })
      .filter((r) => !!r);

    const tokenGroupDistributions = groupBy(
      distributionsWithToken,
      (distribution) => {
        return distribution.rewardDistributor.reward_token_symbol;
      }
    );
    setDistributionsGroup(tokenGroupDistributions);
  }, [distributions, distributors, tokens]);

  return (
    <Tr>
      <Td>
        <Checkbox />
      </Td>
      <Td>
        <Flex alignItems="center">
          <TokenLogo src={logo} size="sm" mr="2" />
          <Box>
            <Text color={text2} fontSize="sm" fontWeight="semibold">
              {name}
            </Text>
            <Text
              color={text6}
              fontSize="xs"
              cursor="pointer"
              onClick={() => handleGoLink(link)}
            >
              {subName}
            </Text>
          </Box>
        </Flex>
      </Td>
      <Td isNumeric>
        <Flex
          w="400px"
          alignItems="center"
          justifyContent="flex-end"
          color={text1}
          fontSize="sm"
          fontWeight="semibold"
        >
          {unclaimedReward &&
            unclaimedReward.map((reward, rIndex) => {
              if (rIndex > 1) {
                return <div key={rIndex} />;
              }
              return (
                <React.Fragment key={rIndex}>
                  <Text ml="1">
                    {rIndex !== 0 && " + "}
                    {formatToken(
                      reward.reward,
                      reward && reward.token ? reward.token.symbol : "ZWAP",
                      isHidden
                    )}
                  </Text>
                  <TokenLogo size="2xs" src={reward.token?.icon} ml="1" />
                </React.Fragment>
              );
            })}
          {unclaimedReward && unclaimedReward.length > 2 && (
            <Text ml="2">...</Text>
          )}
        </Flex>
        <Text color={text3} fontSize="xs">
          {formatUsdt(totalReward * zilPriceInUsd, exchangeRate, isHidden)}
        </Text>
      </Td>
    </Tr>
  );
};

export default LPZwapReward;
