import React from "react";
import { Flex, SimpleGrid, Text, Box } from "@chakra-ui/layout";
import { PageLayout } from "src/templates";
import { NFTItem } from "src/atoms";
import { useAppColor, useRouter } from "src/hooks";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { ICollection, ISmartContract } from "src/constants/interfaces";
import { IconArrowLeft } from "src/assets/icons";
import { InputGroup, InputLeftElement, Input } from "@chakra-ui/react";
import { BsSearch } from "react-icons/bs";
import { useTranslation } from "react-i18next";
interface NFTPageProps {
  title: string;
}

export const NFTPage: React.FC<NFTPageProps> = ({ title }) => {
  const { main2, text2, text3 } = useAppColor();
  const router = useRouter();
  const [collection] = React.useState<ICollection>(
    router.location.state as ICollection
  );
  const { t } = useTranslation("nfts");

  const [searchText, setSearchText] = React.useState<string>("");
  const [searchResult, setSearchResult] = React.useState<ISmartContract[]>(
    collection.smartContracts
  );

  const [limit, setLimit] = React.useState<number>(30);
  const [mobileLimit, setMobileLimit] = React.useState<number>(5);

  const onSwiperChange = React.useCallback(
    (swiper) => {
      if (swiper.activeIndex === mobileLimit - 1) {
        setMobileLimit(mobileLimit + 1);
      }
    },
    [mobileLimit]
  );

  const onLoadMore = React.useCallback(() => {
    if (limit > collection.smartContracts.length) {
      return;
    }
    setLimit(limit + 5);
  }, [limit, collection]);

  const onSearchChange = React.useCallback(
    (text: string) => {
      setSearchText(text);
      if (text === "" || text === null || text === undefined) {
        setSearchResult(collection.smartContracts);
      } else {
        let data = [];
        for (let i = 0; i < collection.smartContracts.length; i++) {
          if (
            collection.smartContracts[i].id
              .toLowerCase()
              .includes(text.toLowerCase())
          ) {
            data.push(collection.smartContracts[i]);
          }
          setSearchResult(data);
        }
      }
    },
    [collection]
  );

  return (
    <PageLayout title={title} isGoBack onScrollToBottom={onLoadMore}>
      <Flex justifyContent="center" mb="6">
        <InputGroup w={{ base: "100%", md: "60%", lg: "40%", xl: "30%" }}>
          <InputLeftElement color="gray.400" pointerEvents="none">
            <BsSearch />
          </InputLeftElement>
          <Input
            bg={main2}
            placeholder={t("searchById")}
            w="full"
            onChange={(event) => onSearchChange(event.target.value)}
            value={searchText}
          />
        </InputGroup>
      </Flex>
      {collection && (
        <React.Fragment>
          <Flex
            cursor="pointer"
            aria-label="back"
            justifyContent="flex-start"
            alignItems="center"
            onClick={router.history.goBack}
            mb="16px"
          >
            <IconArrowLeft
              display={{ base: "block", lg: "none" }}
              w="24px"
              h="24px"
              fill={text2}
            />
            <Text
              fontSize="lg"
              lineHeight="7"
              fontWeight="semibold"
              color={text3}
              ml="2"
            >
              {collection.title}
            </Text>
          </Flex>
          <Box display={{ base: "none", lg: "block" }}>
            <SimpleGrid columns={5} spacing="4">
              {searchResult &&
                searchResult.map((i, idx) => {
                  if (idx > limit - 1) {
                    return <React.Fragment key={idx} />;
                  }
                  return <NFTItem smartContract={i} key={idx} w="full" />;
                })}
            </SimpleGrid>
          </Box>
          <Box display={{ base: "block", lg: "none" }}>
            <Swiper
              onSlideChange={onSwiperChange}
              slidesPerView={1}
              spaceBetween={20}
              width={220}
            >
              {searchResult &&
                searchResult.map((i, idx) => {
                  if (idx > mobileLimit - 1) {
                    return <React.Fragment key={idx} />;
                  }
                  return (
                    <SwiperSlide key={idx}>
                      <NFTItem smartContract={i} w="full" />
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </Box>
        </React.Fragment>
      )}
    </PageLayout>
  );
};

export default NFTPage;
